import { Component, NgZone, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import {
  EventBusService,
  EventType,
  STWEventData
} from '../services/eventbus.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatus, SyncComponent } from '../sync/sync.component';
import {
  LocalStorageService,
  ReferenceCode,
  CurrentUserTable,
  ReportTable
} from '../services/local-storage.service';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { UtilService } from '../util/util.service';
import { FlexObject, AuthService } from 'csx-flex-ng';

import { Observable } from 'rxjs/Rx';

@Component({
  selector: 'app-login-team',
  templateUrl: './login-team.component.html',
  styleUrls: ['./login-team.component.scss', '../sync/sync.component.scss']
})
export class LoginTeamComponent extends SyncComponent implements OnInit {
  teamNameOptions: SelectItem[];
  teamChangeOptions: SelectItem[];
  jobTraxTeamName: string;
  teamChangeObj: Object = {};
  teamName: string;
  teamChangeReason: string;
  disableSyncButton: boolean;
  disableSync: boolean;
  userName: string;
  hideReason: boolean;
  referenceList = 'TEAM,TCR';
  currentUser: CurrentUserTable;
  currentUserTeam: string;
  currentUserExists = false;
  changeTeamForUser = false;
  disableNewSyncButton: boolean;
  userId: string;
  submittedReportsCount: number;
  showSyncComponent: boolean;

  // test: any;

  constructor(
    protected ds: DataService,
    protected localstorage: LocalStorageService,
    protected evenBus: EventBusService,
    protected confirmService: ConfirmationService,
    protected route: Router,
    private activeRoute: ActivatedRoute,
    protected ngzone: NgZone,
    protected utilservice: UtilService,
    protected authService: AuthService
  ) {
    super(
      ds,
      localstorage,
      evenBus,
      confirmService,
      route,
      ngzone,
      utilservice
    );
    this.Flex = FlexObject.getInstance();
    this.userName = this.Flex.user.firstName + '   ' + this.Flex.user.lastName;
    this.userId = this.Flex.user.id;
    this.loadParam();
    this.loadDropdown().then(async val => {
      console.log('loaddropdown', val);
    });
    this.assignTeam();
    // this.init();
    this.checkSubmittedReports();
  }

  async checkSubmittedReports() {
    this.submittedReportsCount = 0;
    await this.localstorage
      .getReportsForSync()
      .then((reportList: Array<ReportTable>) => {
        if (reportList) {
          for (let p of reportList) {
            let s = JSON.parse(JSON.stringify(p.reportObj)).status;
            if (s !== null && s === FormStatus.SUBMITTED) {
              this.submittedReportsCount++;
            }
          }
        }
      });
  }

  loadDropdown(): Promise<any> {
    return new Promise((resolve, reject) => {
      let c = this.localstorage.reftable.count();
      console.log(c);
      c.then(c1 => {
        console.log('count::::', c1);
        if (c1 > 0) {
          resolve(2);
          this.updateDropdownPromise().then(() => {});
        } else {
          let o: Observable<any> = Observable.create(obs => {
            this.ds.getRefSync(this.referenceList).then((refResult: any) => {
              this.localstorage.reftable.count().then(p => {
                console.log('2nd count', p);
                if (p > 0) {
                  this.updateDropdownPromise().then(() => {
                    obs.next(1);
                    obs.complete();
                    resolve(1);
                  });
                } else {
                  console.log('in else', p);
                }
              });
            });
          }); // .prototype.retry(2);
          o.subscribe(
            (ref: any) => {},
            e => {},
            () => {
              resolve(1);
            }
          );
        }
      });
    });
  }

  async updateTeamOptions(refObj: string) {
    this.teamNameOptions = this.utilservice
      .hack(refObj, 'TeamName')
      .sort((a: SelectItem, b: SelectItem) =>
        a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      );
  }

  async updateTeamChangeOptions(refObj: string) {
    this.teamChangeOptions = this.utilservice
      .hack(refObj, 'TeamChange')
      .sort((a: SelectItem, b: SelectItem) => a.label.localeCompare(b.label));
  }

  private updateDropdown(resolve: (value?: any) => void) {
    console.log('update dropdown');
    this.localstorage
      .getAllRefereces()
      .then(async (refs: Array<ReferenceCode>) => {
        let refData = refs;
        for (let p of refData) {
          if (p.refType === 'TEAM') {
            await this.updateTeamOptions(p.refObj);
            console.log(this.teamNameOptions);
          }
          if (p.refType === 'TCR') {
            await this.updateTeamChangeOptions(p.refObj);
            console.log(this.teamChangeOptions);
          }
        }
        resolve(1);
      });
  }

  private updateDropdownPromise(): Promise<number> {
    console.log('updateDropdownPromise dropdown');
    return new Promise((resolve, reject) => {
      this.localstorage
        .getAllRefereces()
        .then(async (refs: Array<ReferenceCode>) => {
          console.log('updateDropdownPromise dropdown', refs);
          for (let p of refs) {
            if (p.refType === 'TEAM') {
              await this.updateTeamOptions(p.refObj);
              console.log(this.teamNameOptions);
            }
            if (p.refType === 'TCR') {
              await this.updateTeamChangeOptions(p.refObj);
              console.log(this.teamChangeOptions);
            }
          }
        });
    });
  }

  ngOnInit() {
    console.log('In ngOnInit');
    this.login();
  }

  loadParam(): void {
    this.activeRoute.params.subscribe(params => {
      this.changeTeamForUser = params.changeTeam;
    });
  }

  async getJoxTraxTeamName() {
    await this.ds
      .getUserData(this.Flex.user.id)
      .then(async res => {
        await this.localstorage.getUser(this.Flex.user.id).then(async res1 => {
          let userData = await JSON.parse(JSON.stringify(res1.userObj));
          this.currentUserTeam = userData.teamName;
          console.log('teamnotchanged', this.currentUserTeam);
          this.jobTraxTeamName = userData.teamName;
        });
      })
      .catch(e => {
        if (
          this.Flex.user.groups
            .join(',')
            .match(new RegExp('(?:^|,)' + 'eng.stw.admin' + '(?:,|$)'))
        ) {
          console.log('Admin token found');
        }
        if (
          this.Flex.user.groups
            .join(',')
            .match(new RegExp('(?:^|,)' + 'eng.stw.developer' + '(?:,|$)'))
        ) {
          console.log('Developer token found');
        }
        if (
          this.Flex.user.groups
            .join(',')
            .match(new RegExp('(?:^|,)' + 'eng.stw.user' + '(?:,|$)'))
        ) {
          console.log('User token found');
        }
        console.log(e);
      });
  }

  async login() {
    try {
      if (!this.ds.loginFlag) {
        await this.ds.loginUser();
      }
    } catch (e) {
      console.error(e);
    }
  }

  logOut() {
    this.authService.logout();
  }

  assignTeam() {
    this.localstorage.currentUserTable.count((n: number) => {
      if (n > 0 && !this.changeTeamForUser) {
        this.route.navigate([`/project_view`]);
      } else {
        this.ngzone.run(async () => {
          await this.getJoxTraxTeamName();
          await this.localstorage
            .getCurrentUser('SYSTEM')
            .then((u: CurrentUserTable) => {
              if (u === undefined) {
                console.log('assignTeam no current user found');
              } else {
                console.log('assignTeam currentUser::::', u);
                this.currentUser = u;
                this.teamName = u.teamName;
                this.currentUserTeam = u.teamName;
                this.currentUserExists = true;
                this.ds.team = u.teamName;
                this.team = u.teamName;
                this.evenBus.fireEvent(
                  EventType.PROXY_CHANGE_EVENT,
                  new STWEventData('data', () => '')
                );
              }
            });
          if (this.teamName === null || this.teamName === undefined) {
            this.teamName = this.jobTraxTeamName;
            this.currentUserTeam = this.jobTraxTeamName;
          }
          await this.hideReasonCode();
          await this.currentTeam();
          console.log('sdgfd', this.disableNewSyncButton);
          if (this.currentUserExists && !this.changeTeamForUser) {
            this.route.navigate([`/project_view`]);
          }
        });
      }
    });
  }

  async currentTeam() {
    if (this.changeTeamForUser && this.teamName === this.currentUserTeam) {
      this.disableNewSyncButton = true;
    } else {
      this.disableNewSyncButton = false;
    }
    if (this.disableSyncButton || this.disableNewSyncButton) {
      this.disableSync = true;
    } else {
      this.disableSync = false;
    }
  }

  async cleanPreviousTeamData() {
    this.localstorage.projectTable.clear();
    this.localstorage.jobTable.clear();
    this.localstorage.reportTable.clear();
    this.localstorage.roadCrossingTable.clear();
  }

  async startSync() {
    await this.updateCurrentUser();
    await this.cleanPreviousTeamData();
    await this.ds
      .postLogDetails(JSON.parse(JSON.stringify(this.teamChangeObj)))
      .then(res => {
        console.log('postLogDetails', res);
      })
      .catch(e => {
        console.log('postLogDetails error', e);
      });
    this.startFullSync();
  }

  async updateCurrentUser() {
    let updatedUserInfo: CurrentUserTable = {
      racf: 'SYSTEM',
      teamName: this.teamName,
      teamReason: this.teamChangeReason,
      deviceSpecificLastSync: null
    };
    this.localstorage.addCurrentUser(updatedUserInfo).then(() => {
      this.ds.team = this.teamName;
      this.team = this.teamName;
    });
    this.teamChangeObj['source'] = 'STW Mobile';
    this.teamChangeObj['message'] = this.teamName + '|' + this.teamChangeReason;
    this.teamChangeObj['messageType'] = 'Team Change';
  }

  async hideReasonCode() {
    if (
      this.currentUserTeam !== undefined &&
      this.jobTraxTeamName !== undefined &&
      this.jobTraxTeamName === this.teamName
    ) {
      this.hideReason = true;
      this.disableSyncButton = false;
    } else {
      this.hideReason = false;
      this.disableSyncButton = true;
    }
  }

  async changeTeam($event, newTeam) {
    if (this.teamName === 'Select') {
      this.hideReason = true;
      this.disableSyncButton = true;
      this.teamChangeReason = null;
    } else {
      if (
        this.jobTraxTeamName !== undefined &&
        this.jobTraxTeamName === this.teamName
      ) {
        this.hideReason = true;
        this.disableSyncButton = false;
        this.teamChangeReason = 'Select';
      } else {
        this.hideReason = false;
        this.disableSyncButton = true;
        this.teamChangeReason = 'Select';
      }
    }
    await this.currentTeam();
  }

  onChangeTeamReason($event, newReason: string) {
    if (this.teamChangeReason !== 'Select') {
      this.disableSyncButton = false;
    } else {
      this.disableSyncButton = true;
    }
    this.currentTeam();
  }

  onDialogClose(event) {
    this.display = event;
  }

  routerNavigate() {
    if (this.progress_value > 99) {
      this.route.navigate([`/project_view`]);
    } else {
      this.route.navigate([this.route.url]);
    }
  }

  onSyncDialogOpen() {
    this.showSyncComponent = true;
  }

  onSyncDialogClose() {
    this.showSyncComponent = false;
  }
}
