export class TrackDisturbanceModel {
  formId = null;
  jobType = null;
  workDate = null;
  createdBy = null;
  railWeight = null;
  railType = null;
  ballastSufficient = null;
  trackClearedTime = null;
  teamName = null;
  comment = null;
  speedRestrictionType = null;
  speedValue = null;
  neaturalTemp = null;
  railTemp = null;
  adjustedNeautralTemp = null;
  openJoints = null;
  totalRailToRemove = null;
  railRemoved = null;
  railLength = null;
  railSide = null;
  trackType = null;
  trackName = null;
  prefix = null;
  beginMP = null;
  endMP = null;

  constructor() {
    this.formId = null;
    this.jobType = null;
    this.workDate = null;
    this.createdBy = null;
    this.railWeight = null;
    this.railType = null;
    this.ballastSufficient = null;
    this.trackClearedTime = null;
    this.teamName = null;
    this.comment = null;
    this.speedRestrictionType = null;
    this.speedValue = null;
    this.neaturalTemp = null;
    this.railTemp = null;
    this.adjustedNeautralTemp = null;
    this.openJoints = null;
    this.totalRailToRemove = null;
    this.railRemoved = null;
    this.railLength = null;
    this.railSide = null;
    this.trackType = null;
    this.trackName = null;
    this.prefix = null;
    this.beginMP = null;
    this.endMP = null;
  }
}
