import {Component, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'add-reference',
    templateUrl: './add-reference.component.html',
})
export class AddReferenceComponent {

    @Output() addReference = new EventEmitter<Object>();

    refType = '';
    refObj = '';

    onAddReference() {
        this.addReference.emit({refType: this.refType, refObj: JSON.parse(this.refObj)});
    }

}
