import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'jobWorkStatus'
})
export class JobWorkStatusPipe implements PipeTransform {
    transform(value: any, jobWorkStatus: any): any {
        let result = '';
        if (jobWorkStatus === 'mat-dlvry') {
            if (value.materialDelivered >= 0 && value.materialTotal >= 0 ) {
                if (value.materialDelivered && value.materialTotal) {
                    const perc = (value.materialDelivered / value.materialTotal) * 100;
                    if (perc === 0) {
                        result = 'csx-common_ban text-gray';
                    } else if (perc > 0 && perc < 100) {
                        result = 'csx-common_in_progress text-orange';
                    } else if (perc === 100) {
                        result = 'csx-common_check text-green';
                    }
                }
            }
        } else if (jobWorkStatus === 'sptWork') {
            const val = parseFloat(value);
            if (val === 0) {
                result = 'csx-common_ban text-gray';
            } else if (val > 0 && val < 100) {
                result = 'csx-common_in_progress text-orange';
            } else if (val >= 100) {
                result = 'csx-common_check text-green';
            } else {
                result = 'csx-common_ban text-gray';
            }
        }
        return result;
    }
}
