import { SpeedRestrictionModel } from '../td-speed-restriction/models/speed-restriction.model';
import { LocationModel } from '../td-speed-restriction/models/location.model';

export class JobModel {
  jobId?: number;
  job?: number;
  projectId: number;
  prefix: string;
  lowMp: number;
  highMp: number;
  startMP: number;
  endMP: number;
  trackType: string;
  jobTypeCode: string;
  division: string;
  subdivision: string;
  sequenceId?: number;
  masterTrackList?: Array<LocationModel>;
  tieTrackList?: Array<LocationModel>;
  trackDisturbanceDetails?: Array<SpeedRestrictionModel>;
  speedTrackClassList?: Array<LocationModel>;
  isSwitchTieJob?: boolean;

  constructor(
    jobId: number,
    projectId: number,
    prefix: string,
    lowMp: number,
    highMp: number,
    trackType: string,
    jobTypeCode: string,
    division: string,
    subdivision: string,
    sequenceId: number,
    isSwitchTieJob: boolean
  ) {
    this.jobId = jobId;
    this.projectId = projectId;
    this.prefix = prefix;
    this.lowMp = lowMp;
    this.highMp = highMp;
    this.trackType = trackType;
    this.jobTypeCode = jobTypeCode;
    this.division = division;
    this.subdivision = subdivision;
    this.sequenceId = sequenceId;
    this.isSwitchTieJob = isSwitchTieJob;
  }
}
