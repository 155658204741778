import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConditionallyValidateService } from 'ng-conditionally-validate';
import { DataService } from '../../services/data.service';
import { LocalStorageService, ReportTable } from '../../services/local-storage.service';
import { OperationMode } from '../../shared/enums/operation-mode.enum';
import { ReportNavigationService } from '../../shared/services/report-navigation.service';
import { UtilService } from '../../util/util.service';
import { TrackUtilizationComponent } from '../track-utilization.component';
import { AppConstant } from '../../shared/constant/constants';
import { ConfirmationService } from '../../../../node_modules/primeng/primeng';

@Component({
  selector: 'app-track-utilization-edit',
  templateUrl: '../track-utilization.component.html',
  styleUrls: ['../track-utilization.component.scss']
})
export class TrackUtilizationEditComponent extends TrackUtilizationComponent implements OnInit {
  isTDEditable: Boolean;
  isNotesPresent = false;

  constructor(public fb: FormBuilder,
    public cv: ConditionallyValidateService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    public dataService: DataService,
    public localStorage: LocalStorageService,
    public utilService: UtilService,
    public editConfirmationService: ConfirmationService,
    private reportNavigationService: ReportNavigationService) {
    super(fb, cv, router, activeRoute, dataService, localStorage, utilService);
  }
  ngOnInit() {
    this.isTDEditable = this.activeRoute.snapshot.queryParams.isTdEditable === 'true' ? true : false;
    this.operationMode = OperationMode.EDIT;
    super.ngOnInit();
    this.localStorage.getCurrentUser('SYSTEM').then(data => {
      const teamName = data ? data.teamName : null;
      this.localStorage.getNotes(teamName).then(noteData => {
        if (noteData !== null && noteData !== undefined) {
          this.isNotesPresent = true;
        } else {
          this.isNotesPresent = false;
        }
      });
    });
  }

  get editMode() {
    return this.operationMode === OperationMode.EDIT;
  }

  protected setOperationMode() {
    return this.operationMode === OperationMode.EDIT;
  }

  protected saveReportToIndexDbMethod(reportObject: any) {
    const report: ReportTable = {
      formId: Number(reportObject.formId),
      jobId: Number(reportObject.jobId),
      projectId: Number(reportObject.projectId),
      reportObj: reportObject
    };
    return this.dataService.addEditedReport(report);
  }

  protected getReportMethod() {
    return this.dataService.getEditReportTrackUtil(Number(this.formId));
  }

  protected reportfill(response: any) {
    super.reportfill(response);
    this.disableReportFields();
  }

  private disableReportFields() {
    if (!this.isTDEditable) {
      this.formGroup.get('reportWindows').controls.forEach((reportwindow, index) => {
        reportwindow.controls['windowEnds'].disable();
        reportwindow.get('windowEnds').clearValidators();
      });
    }

  }

  protected backIconNavigate() {
    this.reportNavigationService.navigateToJobView(this.projectId, this.formId);
  }

  protected navigateToBackPage(navigateTo: string) {
    this.router.navigate([`/${navigateTo}/edit/${this.projectId}/${this.jobId}/${this.formId}`], {
      queryParams: {
        isTdEditable: this.isTDEditable
      }
    });
  }

  protected saveReportToDb(reportData: any) {
    if (Number(this.formId) < 0) {
      // Edit in case of a submitted report will behave same as create report
      super.saveReportToDb(reportData);
    } else {
      console.log('Save edited synced report', this.reportData);
      if (navigator.onLine) {
        let message = 'Are you sure you want to edit this report?';
        if (this.isNotesPresent) {
          message += '<br/><strong>On Edit, any saved notes will be discarded.</strong>';
        }
        this.editConfirmationService.confirm({
          key: AppConstant.EDIT_KEY,
          message: message,
          accept: () => {
            this.loading = true;
            this.reportData.reportType = this.utilService.determineReportType(this.reportData);
            this.dataService.submitEditedReport(this.reportData, this.reportData.formId,
              this.isTDEditable ? AppConstant.YES : AppConstant.NO, this.reasonForEdit)
              .then(res => {
                this.loading = false;
                if (res.statusCode !== 200 || (res.errorNumber && res.errorNumber < 0)) {
                  this.showErrorComponent = true;
                  this.errorDialogData = {
                    dialogHeader: AppConstant.ERROR_HEADING,
                    dialogBody: this.getErrorMessage(res)
                  };
                } else {
                  this.localStorage.deleteEditedReportByFormId(this.reportData.formId).then(() => {
                    console.log('deleting this row from indexdb', this.formId);
                  });
                  this.showSyncComponent = true;
                }
              }).catch(e => {
                this.loading = false;
                this.showErrorComponent = true;
                this.errorDialogData = {
                  dialogHeader: AppConstant.ERROR_HEADING,
                  dialogBody: e
                };
              });
          },
          reject: () => {
            this.reasonError = false;
          }
        });
      } else {
        this.showErrorComponent = true;
      }
    }
  }

  protected onDialogClose(event: boolean): void {
    super.onDialogClose(event);
    super.navigateToJobView();
  }

  protected onReasonChange() {
    if (this.reasonError && this.reasonForEdit) {
      this.reasonError = false;
    }
  }

  validate(cd: any) {
    if (!this.reasonForEdit) {
      this.reasonError = true;
      return;
    }
    cd.accept();
  }

  private getErrorMessage(res: any): string {
    if (res && res.errorName) {
      return res.errorName;
    }
    if (res && res.invocationResult) {
      return res.invocationResult.errorName;
    }
    return null;
  }
}
