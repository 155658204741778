import { Turnout } from './../model/turnout.model';
import { LocalStorageService, CurrentUserTable, ReportTable } from './../services/local-storage.service';
import { KeyValue } from '@angular/common';
import { SelectItem, ConfirmationService } from 'primeng/primeng';
import { FormGroup, FormBuilder, FormArray, Validators, AbstractControl, ValidatorFn, FormControl } from '@angular/forms';
import { DataService } from './../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UtilService } from '../util/util.service';
import { TDWorkType } from '../td-speed-restriction/enums/td-work-type.enum';
import { SpeedRestrictionModel } from '../td-speed-restriction/models/speed-restriction.model';
import { ReferenceCode } from '../services/local-storage.service';
import { TDSpeedType } from '../td-speed-restriction/enums/td-speed-types.enum';
import { forkJoin } from '../../../node_modules/rxjs';
import { ErrorDialog } from '../model/error-dialog.model';
import { AppConstant } from '../shared/constant/constants';
import { ReportDataService } from '../shared/services/report-data.service';
import { WorkDirection } from '../enums/work-direction.enum';
import { SwitchTieReportDetailsModel, SurfacingDetailsModel, SwitchTieDetailsModel } from './models/switch-tie-report-details.model';
import { WorkType } from '../enums/work-type.enum';
import { OperationMode } from '../shared/enums/operation-mode.enum';

@Component({
  selector: 'app-switch-tie-report',
  templateUrl: './switch-tie-report.component.html',
  styleUrls: ['./switch-tie-report.component.scss']
})
export class SwitchTieReportComponent implements OnInit {
  operationMode: OperationMode = OperationMode.CREATE;
  jobReports: Array<any>;
  projectReports: Array<any>;
  jobData: any;
  formGroup: FormGroup;
  isFormSubmitted: boolean;
  maxOnDutyDate: string;
  minOnDutyDate: string;
  directionList: SelectItem[];
  workedOnList: SelectItem[];
  surfacingFormGroup: FormGroup;
  switchTiesFormGroup: FormGroup;
  tdFormGroup: FormGroup;
  tdSpeedRestrictionTypes: Array<KeyValue<TDWorkType, Array<KeyValue<number, string>>>> = [];
  tieTypeOptions: Array<KeyValue<string, string>> = [];
  newRelayOptions: Array<KeyValue<string, string>> = [];
  trackDisturbances: Array<SpeedRestrictionModel>;
  showDuplicateError: boolean;
  errorDialogData: ErrorDialog = {
    dialogHeader: AppConstant.DUPLICATE_REPORT_TITLE,
    dialogBody: AppConstant.DUPLICATE_REPORT_MSG
  };
  teamName: string;
  WorkTypeTab = WorkTypeTab;
  switchTieReportDetails: SwitchTieReportDetailsModel;
  private lastestReport: any;

  constructor(
    protected formBuilder: FormBuilder,
    protected dataService: DataService,
    protected localStorage: LocalStorageService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected utilService: UtilService,
    protected reportDataService: ReportDataService,
    protected acknowledgeService: ConfirmationService
  ) { }

  ngOnInit() {
    this.initializeData();
  }

  get projectId() {
    return Number(this.route.snapshot.paramMap.get('project'));
  }

  get jobId() {
    return Number(this.route.snapshot.paramMap.get('job'));
  }

  get formId() {
    return Number(this.route.snapshot.paramMap.get('form'));
  }

  backClick() {
    // Save draft report if not view mode
    if (this.viewMode) {
      this.router.navigate([`/job_view/${this.projectId}`]);
    } else {
      let reportData = this.transformReportData();
      reportData = this.getDefaultFromSameDayReport(reportData);
      this.saveReportToIndexDbMethod(reportData)
        .then(() => {
          this.router.navigate([`/job_view/${this.projectId}`]);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  get editMode() {
    return this.operationMode === OperationMode.EDIT;
  }

  getDefaultFromSameDayReport(reportData: any): any {
    if (reportData.status !== 'D' && this.lastestReport && !this.viewMode) {
      reportData.delayIssuesRemark = this.lastestReport.delayIssuesRemark;
      reportData.issuesDesc = this.lastestReport.issuesDesc;
      reportData.tieUpLocation = this.lastestReport.tieUpLocation;
      reportData.nextDayLocation = this.lastestReport.nextDayLocation;
      reportData.plannedStart = this.lastestReport.plannedStart;
      reportData.plannedEnd = this.lastestReport.plannedEnd;
      reportData.actualEmployeesCount = this.lastestReport.actualEmployeesCount;
      reportData.greenSignalTime = this.lastestReport.greenSignalTime;
      reportData.fullBlockReceived = this.lastestReport.fullBlockReceived;
      reportData.trackReturnLate = this.lastestReport.trackReturnLate;
      reportData.trackReturnLateRemarks = this.lastestReport.trackReturnLateRemarks;
      if (this.lastestReport.reportWindows) {
        const modifiedWindows: any[] = [];
        this.lastestReport.reportWindows.forEach((window: any, i: any) => {
          window.unitsInstalled = null;
          window.unitPerHour = null;
          modifiedWindows.push(window);
        });
        reportData.reportWindows = modifiedWindows;
      }
    } else if (!this.lastestReport) {
      if (!this.editMode) {
        this.localStorage.getNotes(this.teamName).then((notesData: any) => {
          if (notesData && notesData.notesObj && this.formId < 0) {
            const notes = JSON.parse(JSON.stringify(notesData));
            reportData.plannedStart = notes.notesObj.plannedStart ? notes.notesObj.plannedStart : null;
            reportData.plannedEnd = notes.notesObj.plannedEnd ? notes.notesObj.plannedEnd : null;
            reportData.greenSignalTime = notes.notesObj.greenSignalTime ? notes.notesObj.greenSignalTime : null;
            if (notesData.notesObj.reportWindows) {
              reportData.reportWindows = notes.notesObj.reportWindows;
            }
          }
        });
      }
    }
    return reportData;
  }

  workedOnChange() {
    this.isFormSubmitted = false;
    if (this.formGroup.controls.workedOn.value === WorkTypeTab.SWITCHTIE) {
      this.switchTieReportDetails.surfacing = [];
      this.surfacingFormGroup = this.formBuilder.group({
        surfacing: new FormArray([])
      });
    } else if (this.formGroup.controls.workedOn.value === WorkTypeTab.SURFACE) {
      this.switchTieReportDetails.switchTies = [];
      this.switchTiesFormGroup = this.formBuilder.group({
        switchTies: new FormArray([])
      });
    }
  }

  getSelectedTurnouts() {
    if (this.formGroup && this.switchTiesFormGroup &&
      (this.formGroup.controls.workedOn.value === WorkTypeTab.SWITCHTIESURFACE ||
        this.formGroup.controls.workedOn.value === WorkTypeTab.SWITCHTIE) &&
      this.switchTiesFormGroup.controls.switchTies.value.length > 0) {
      return this.switchTiesFormGroup.controls.switchTies.value.map((switchTie: SwitchTieDetailsModel) => switchTie.turnout);
    } else {
      return [];
    }
  }

  formSubmit() {
    if (this.viewMode) {
      this.navigateToPlannedActual();
    } else {
      if (this.isFormInvalid()) {
        this.isFormSubmitted = true;
      } else {
        this.isFormSubmitted = false;
        let reportData = this.transformReportData();
        reportData = this.getDefaultFromSameDayReport(reportData);
        this.reportDataService.validateDuplicateReports(reportData).then((isDuplicate: boolean) => {
          this.showDuplicateError = isDuplicate;
          if (!isDuplicate) {
            this.saveReportToIndexDbMethod(reportData)
              .then(response => {
                console.log(response);
                this.navigateToPlannedActual();
              })
              .catch(reason => {
                console.log(reason);
              });
          }
        });
      }
    }
  }

  onDutyDateTimeChange(flag: boolean) {
    if (!this.viewMode) {
      const onDutyWorkDate = this.formGroup.controls.workday.value ? new Date(this.formGroup.controls.workday.value) : new Date();
      this.reportDataService.getLatestProdReportFromAllProjects(this.teamName, onDutyWorkDate.getTime()).subscribe(latestReportObj => {
        this.lastestReport = latestReportObj;
        if (latestReportObj !== undefined && this.switchTieReportDetails.status !== 'D') {
          this.formGroup.controls.remarks.patchValue(latestReportObj.remarks);
          this.formGroup.controls.railTemp1.patchValue(latestReportObj.railTemp1);
          this.formGroup.controls.railTemp2.patchValue(latestReportObj.railTemp2);
          this.formGroup.controls.railTemp3.patchValue(latestReportObj.railTemp3);
          if (!flag) {
            this.formGroup.controls.workday.patchValue(this.utilService.convertTimeStampTOdate(latestReportObj.workday));
          }
        } else {
          this.localStorage.getCurrentUser('SYSTEM').then(data => {
            this.teamName = data ? data.teamName : null;
            this.localStorage.getNotes(this.teamName).then((notesData: any) => {
              if (notesData && notesData.notesObj.onDutyTime) {
                const notes = JSON.parse(JSON.stringify(notesData));
                if (!this.formGroup.get('workday').value) {
                  this.formGroup.controls.workday.patchValue(notes.notesObj.onDutyTime);
                }
              }
            });
          });
        }
      });
    }
  }

  isFormInvalid() {
    this.formGroup.updateValueAndValidity();
    this.switchTiesFormGroup.updateValueAndValidity();
    this.surfacingFormGroup.updateValueAndValidity();
    this.tdFormGroup.updateValueAndValidity();
    return (
      this.formGroup.invalid ||
      this.switchTiesFormGroup.invalid ||
      ((this.formGroup.controls.workedOn.value === WorkTypeTab.SWITCHTIESURFACE ||
        this.formGroup.controls.workedOn.value === WorkTypeTab.SWITCHTIE) &&
        this.switchTiesFormGroup.controls.switchTies.value.length === 0) ||
      this.surfacingFormGroup.invalid ||
      this.tdFormGroup.invalid
    );
  }

  getTrackTypesWhereWorkIsDone() {
    const tracksWhereWorkIsDone = [];
    (this.surfacingFormGroup.controls.surfacing as FormArray).controls.forEach((surfacingFormGroup: FormGroup) => {
      if ((surfacingFormGroup.valid || surfacingFormGroup.disabled) && surfacingFormGroup.controls.crossingSurfaced.value !== null) {
        tracksWhereWorkIsDone.push((<SurfacingDetailsModel>surfacingFormGroup.getRawValue()).trackDetails.trackType);
      }
    });
    (this.switchTiesFormGroup.controls.switchTies as FormArray).controls.forEach((switchTieFormGroup: FormGroup) => {
      if (switchTieFormGroup.valid || switchTieFormGroup.disabled) {
        const turnout: Turnout = (<SwitchTieDetailsModel>switchTieFormGroup.getRawValue()).turnout;
        tracksWhereWorkIsDone.push(turnout.trackName + '-' + turnout.milepost);
      }
    });
    return tracksWhereWorkIsDone;
  }

  private initializeData() {
    forkJoin(
      this.localStorage.getAllRefereces(),
      this.localStorage.getJobReports(this.jobId),
      this.localStorage.getJob(this.jobId),
      this.localStorage.getCurrentUser('SYSTEM'),
      this.getReportMethod(),
      this.localStorage.getProjectReports(this.projectId)
    ).subscribe(([references, jobReports, job, team, report, projectReports]:
      [Array<ReferenceCode>, Array<ReportTable>, any, CurrentUserTable, any, Array<ReportTable>]) => {
      this.checkReportOperationMode(report);
      this.setDropdownsDataFromReferences(references);
      this.jobReports = jobReports;
      this.projectReports = projectReports;
      this.jobData = job.jobObj;
      this.teamName = team.teamName;
      this.switchTieReportDetails = report ? report.reportObj : new SwitchTieReportDetailsModel();
      this.trackDisturbances = this.switchTieReportDetails.trackDisturbanceDetails;
      if (Number(this.formId) > 0 && this.switchTieReportDetails.switchTies && this.switchTieReportDetails.switchTies.length > 0) {
        let switchTiesCount = this.switchTieReportDetails.switchTies.length;
        for (const switchTie of this.switchTieReportDetails.switchTies) {
          const beginMp = switchTie.turnout.milepost || switchTie.turnout.milepost === 0 ?
            switchTie.turnout.milepost : this.jobData.startMP - 5;
          const endMp = switchTie.turnout.milepost || switchTie.turnout.milepost === 0 ?
            switchTie.turnout.milepost : this.jobData.endMP + 5;
          const prefix = switchTie.turnout.prefix ? switchTie.turnout.prefix : this.jobData.prefix;
          this.dataService.getTurnouts(this.projectId, prefix, beginMp, endMp)
            .then((turnoutList: Array<Turnout>) => {
              const currentTurnout = turnoutList.filter((turnout: Turnout) => turnout.turnoutId === switchTie.turnout.turnoutId);
              switchTie.turnout = currentTurnout.length > 0 ? currentTurnout[0] : null;
              switchTiesCount--;
              if (switchTiesCount === 0) {
                this.setDefaultData();
                this.initializeForm();
                this.onDutyDateTimeChange(false);
                this.handleViewMode();
              }
            });
        }
      } else {
        this.setDefaultData();
        this.initializeForm();
        this.onDutyDateTimeChange(false);
        this.handleViewMode();
      }
    });
  }

  private setDefaultData() {
    this.maxOnDutyDate = this.utilService.getCurrentDateTime();
    this.minOnDutyDate = this.utilService.getMinDateTime();
    this.directionList = [
      { label: 'Increasing', value: WorkDirection.INCREASING },
      { label: 'Decreasing', value: WorkDirection.DECREASING }
    ];
    this.workedOnList = [
      { label: 'Switch Tie & Surface', value: WorkTypeTab.SWITCHTIESURFACE },
      { label: 'Only Switch Tie', value: WorkTypeTab.SWITCHTIE }
    ];
    const reports = this.jobReports.filter(report => report.reportObj.status !== 'D');
    if (reports.length > 0) {
      this.workedOnList.push({ label: 'Only Surface', value: WorkTypeTab.SURFACE });
    }
  }

  private checkIfRestdayReportExists() {
    return (control: AbstractControl) => {
      if (
        control.value === null ||
        control.value === undefined ||
        control.invalid ||
        !control.parent ||
        !control.parent.get('workday').value
      ) {
        return null;
      } else {
        if (!control.value && control.parent) {
          return { required: true };
        }
        let isRestOnSameWorkDay = false;
        this.projectReports.forEach(report => {
          if (report.reportObj && report.reportObj.status !== 'D' && report.reportObj.teamName === this.teamName) {
            if (this.reportDataService.formatDate(report.reportObj.workday) ===
              this.reportDataService.formatDate(new Date(this.formGroup.controls.workday.value).getTime())
              && report.reportObj.noProductionCode === 'RST') {
              isRestOnSameWorkDay = true;
            }
          }
        });
        if (isRestOnSameWorkDay) {
          return { restDayExists: true };
        }
      }
    };
  }

  protected initializeForm() {
    this.formGroup = this.formBuilder.group({
      roadMaster: [
        {
          value: this.setRoadmasterData(),
          disabled: true
        }
      ],
      workday: [
        this.switchTieReportDetails.workday ? this.utilService.getFormattedDateTime(new Date(this.switchTieReportDetails.workday)) : null,
        [Validators.required, this.checkIfRestdayReportExists(), this.maxDateValidator(new Date())]
      ],
      direction: this.switchTieReportDetails.direction ? this.switchTieReportDetails.direction : WorkDirection.INCREASING,
      workedOn: this.getWorkedOnTab(),
      railTemp1: [this.switchTieReportDetails.railTemp1, [Validators.required, Validators.min(1), Validators.max(150)]],
      railTemp2: [this.switchTieReportDetails.railTemp2, [Validators.required, Validators.min(1), Validators.max(150)]],
      railTemp3: [this.switchTieReportDetails.railTemp3, [Validators.required, Validators.min(1), Validators.max(150)]],
      remarks: this.switchTieReportDetails.remarks
    });
    this.initializeChildFormGroups();
  }

  async setRoadmasterData() {
   if (this.jobData.roadmasters.length > 0 ) {
     let supervisor = null;
     supervisor = await this.utilService.mapRoadMasterDetails(this.jobData.roadmasters[0].rl_c);
       this.formGroup.controls.roadMaster.patchValue(supervisor ? supervisor.userObj.fullName : 'System Error');
   } else {
    this.formGroup.controls.roadMaster.patchValue('System Error');
   }
  }

  private maxDateValidator(date: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const forbidden = new Date(control.value).getTime() > date.getTime();
        return forbidden ? { maxDate: true } : null;
      } else {
        return null;
      }
    };
  }

  private handleViewMode() {
    if (this.viewMode) {
      this.formGroup.disable();
      this.tdFormGroup.disable();
      this.switchTiesFormGroup.disable();
      this.surfacingFormGroup.disable();
    }
  }

  private getWorkedOnTab(): WorkTypeTab {
    if (this.switchTieReportDetails.surfacing.length > 0 && this.switchTieReportDetails.switchTies.length > 0) {
      return WorkTypeTab.SWITCHTIESURFACE;
    } else if (this.switchTieReportDetails.surfacing.length > 0 && this.switchTieReportDetails.switchTies.length === 0) {
      return WorkTypeTab.SURFACE;
    } else if (this.switchTieReportDetails.surfacing.length === 0 && this.switchTieReportDetails.switchTies.length > 0) {
      return WorkTypeTab.SWITCHTIE;
    }
    return WorkTypeTab.SWITCHTIESURFACE;
  }

  private initializeChildFormGroups() {
    this.tdFormGroup = this.formBuilder.group({
      speedRestrictions: new FormArray([])
    });
    this.surfacingFormGroup = this.formBuilder.group({
      surfacing: new FormArray([])
    });
    this.switchTiesFormGroup = this.formBuilder.group({
      switchTies: new FormArray([])
    });
  }

  protected getPreviousReport() {
    let reportDetailsModel: SwitchTieReportDetailsModel;
    if (this.switchTieReportDetails.status === 'D') {
      reportDetailsModel = this.switchTieReportDetails;
    } else {
      reportDetailsModel = new SwitchTieReportDetailsModel();
    }
    return reportDetailsModel;
  }

  private transformReportData() {
    const reportDetailsModel = this.getPreviousReport();
    reportDetailsModel.workTypeCode = WorkType.SWITCHTIE;
    reportDetailsModel.projectId = this.projectId.toString();
    reportDetailsModel.jobId = this.jobId.toString();
    reportDetailsModel.formId = this.formId.toString();
    reportDetailsModel.teamName = this.teamName;
    reportDetailsModel.jobType = this.jobData.jobTypeCode;
    reportDetailsModel.rl = this.jobData.roadmasters.length > 0 ? this.jobData.roadmasters[0].rl_c : null;
    reportDetailsModel.prefix = this.jobData.prefix;
    reportDetailsModel.trackType = this.jobData.trackType;
    reportDetailsModel.divisionCode = this.jobData.division;
    reportDetailsModel.subdivCode = this.jobData.subdivision;

    reportDetailsModel.roadMaster = this.formGroup.controls.roadMaster.value;
    reportDetailsModel.workday = new Date(this.formGroup.controls.workday.value).getTime();
    reportDetailsModel.direction = this.formGroup.controls.direction.value;
    reportDetailsModel.railTemp1 = this.formGroup.controls.railTemp1.value;
    reportDetailsModel.railTemp2 = this.formGroup.controls.railTemp2.value;
    reportDetailsModel.railTemp3 = this.formGroup.controls.railTemp3.value;
    reportDetailsModel.remarks = this.formGroup.controls.remarks.value;
    reportDetailsModel.trackDisturbanceDetails = this.getTdValue();
    reportDetailsModel.switchTies = this.switchTiesFormGroup.get('switchTies').value;
    reportDetailsModel.workedOn = this.formGroup.controls.workedOn.value;
    reportDetailsModel.totalTiesInstalled = this.getTotalTiesInsatlled(reportDetailsModel.switchTies);
    reportDetailsModel.totalUnitInstalled = reportDetailsModel.totalTiesInstalled;

    const surfacingList: Array<SurfacingDetailsModel> = this.getSurfacingValue();
    reportDetailsModel.surfacing = surfacingList.filter((surface: SurfacingDetailsModel) => surface.crossingSurfaced !== null);
    if (reportDetailsModel.surfacing.length === 0) {
      reportDetailsModel.highMp = this.jobData.endMP;
      reportDetailsModel.lowMp = this.jobData.startMP;
    } else {
      let lowMp, highMp;
      for (const surface of reportDetailsModel.surfacing) {
        for (const details of surface.surfaceDetails) {
          if (lowMp && highMp) {
            lowMp = lowMp > details.beginMP ? details.beginMP : lowMp;
            highMp = highMp < details.endMP ? details.endMP : highMp;
          } else {
            lowMp = details.beginMP;
            highMp = details.endMP;
          }
        }
      }
      reportDetailsModel.lowMp = lowMp;
      reportDetailsModel.highMp = highMp;
    }
    return reportDetailsModel;
  }

  private getTotalTiesInsatlled(switchTies: SwitchTieDetailsModel[]): number {
    let totalTiesInstalled = 0;
    switchTies.forEach(switchTie => {
      switchTie.switchTiesSizes.forEach(size => {
        if (size.noOfTies >= 1) {
          totalTiesInstalled += size.noOfTies;
        }
      });
    });
    return totalTiesInstalled;
  }

  private setDropdownsDataFromReferences(references: ReferenceCode[]) {
    for (const reference of references) {
      if (reference.refType === TDSpeedType.SURFACING) {
        this.tdSpeedRestrictionTypes = [...this.utilService.getWorkAndSpeedRestrictionTypes(reference.refObj, TDSpeedType.SURFACING)];
      }
      if (reference.refType === TDSpeedType.TIMBERING) {
        this.tdSpeedRestrictionTypes.push(...this.utilService.getWorkAndSpeedRestrictionTypes(reference.refObj, TDSpeedType.TIMBERING));
      }
      if (reference.refType === 'TNR') {
        Object.keys(reference.refObj).forEach((objectKey: string) => {
          this.newRelayOptions.push({
            key: reference.refObj[objectKey],
            value: objectKey
          });
        });
      }
      if (reference.refType === 'STT') {
        Object.keys(reference.refObj).forEach((objectKey: string) => {
          this.tieTypeOptions.push({
            key: reference.refObj[objectKey],
            value: objectKey
          });
        });
      }
    }
  }

  get viewMode() {
    return this.operationMode === OperationMode.VIEW;
  }

  protected saveReportToIndexDbMethod(repObj: any) {
    return this.dataService.saveDraftReport(JSON.stringify(repObj));
  }

  protected navigateToPlannedActual() {
    this.router.navigate([`/planned_actual/${this.projectId}/${this.jobId}/${this.formId}`]);
  }

  protected getReportMethod() {
    return this.dataService.getReport(Number(this.formId));
  }

  protected getTdValue() {
    return this.tdFormGroup.enabled ? this.tdFormGroup.get('speedRestrictions').value : [];
  }

  protected checkReportOperationMode(response: any) {
    if (response) {
      if (response.reportObj.status === 'D') {
        this.operationMode = OperationMode.CREATE;
      } else if (response.reportObj.status === 'S' || response.reportObj.formId > 0) {
        this.operationMode = OperationMode.VIEW;
      }
    }
  }

  protected getSurfacingValue() {
    return this.surfacingFormGroup.get('surfacing').value;
  }
}

enum WorkTypeTab {
  'SWITCHTIESURFACE' = 'ST',
  'SWITCHTIE' = 'T',
  'SURFACE' = 'S'
}
