import Dexie from 'dexie';

export class DexieService extends Dexie {
  constructor() {
    super('DexieService');
    this.version(1).stores({
      references: 'refType',
      projects: 'project',
      jobs: 'jobId,projectId',
      reports: 'formId,jobId,projectId',
      roadCrossings:
        '[roadCrossingId+projectId],roadCrossingId,jobId,projectId',
      turnouts: '[turnoutId+projectId],turnoutId,jobId,projectId',
      equipments: 'equipmentId,division,team',
      user: 'racf',
      sync: 'racf',
      currentUser: 'racf',
      editedReports: 'formId,jobId,projectId',
      notes: 'teamName',
      supervisorRacfs: 'rlCode'
    });
  }
}
