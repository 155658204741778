import { KeyValue } from '@angular/common';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { CurrentUserTable, ReferenceCode } from './../../services/local-storage.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TsProgressComponent } from '../ts-progress/ts-progress.component';
import { SelectItem, ConfirmationService } from 'primeng/primeng';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { UtilService } from '../../util/util.service';
import { ReportDataService } from '../../shared/services/report-data.service';

import {
  JobTable,
  ReportTable,
  LocalStorageService
} from '../../services/local-storage.service';
import { ErrorDialog } from '../../model/error-dialog.model';
import { AppConstant } from '../../shared/constant/constants';
import { TDWorkType } from '../../td-speed-restriction/enums/td-work-type.enum';
import { SpeedRestrictionModel } from '../../td-speed-restriction/models/speed-restriction.model';
import { TDSpeedType } from '../../td-speed-restriction/enums/td-speed-types.enum';
import { ReportNavigationService } from '../../shared/services/report-navigation.service';
import { OperationMode } from '../../shared/enums/operation-mode.enum';

@Component({
  selector: 'app-ts-info',
  templateUrl: './ts-info.component.html',
  styleUrls: ['./ts-info.component.scss']
})
export class TsInfoComponent implements OnInit {
  operationMode: OperationMode = OperationMode.CREATE;
  @ViewChild(TsProgressComponent)
  tsProgress: TsProgressComponent;
  railType: SelectItem[];
  curveStackedOptions: SelectItem[];
  remarks: string;
  roadMaster: string;
  onDutyTime: string = null;
  surfaceTeamIn: string = null;
  surfacingMilesBehind: number = null;
  surfacingSchedulePlan: string = null;
  railTemp1: number = null;
  railTemp2: number = null;
  railTemp3: number = null;
  job: number;
  jobData: any;
  rlCode: string;
  surfacingSchedulePlanlimiterror: boolean;
  railTemp1limiterror: boolean;
  railTemp2limiterror: boolean;
  railTemp3limiterror: boolean;
  railTemp1NumberError: boolean;
  railTemp2NumberError: boolean;
  railTemp3NumberError: boolean;
  railTemp1EmptyError: boolean;
  railTemp2EmptyError: boolean;
  railTemp3EmptyError: boolean;
  // reportObject: ReportObject [] = [];
  railTemp1ErrorMessage: string;
  railTemp1Error: boolean;
  railTemp2ErrorMessage: string;
  railTemp2Error: boolean;
  railTemp3ErrorMessage: string;
  railTemp3Error: boolean;
  public maxDate: string;
  public minDate: string;
  dateTimeWarningMessage: string;
  dateTimeWarning: boolean;
  surfacingMilesBehindWarningMessage: string;
  surfacingMilesBehindWarning: boolean;
  surfaceTeamInWarning: boolean;
  surfaceTeamInWarningMessage: string;
  surfacingSchedulePlanlimiterrorMessage: string;
  remarkslimiterror: boolean;
  remarkslimiterrorMessage: string;
  surfacingFieldEnableFlag = true;
  projectData: string;
  projectId: number;
  jobId: number;
  formId: number;
  errorCount: number;
  progressData: object[] = [];
  reportNotvalid = false;
  curveStacked: string = null;
  reportObj: object = {};
  prepopulateData: object[] = [];
  projectReports: any[] = [];
  jobStartMp: number;
  jobEndMp: number;
  jobType: string;
  prefix: string;
  trackType: string;
  subdivision: string;
  division: string;
  totalTies: number;
  sideLineTrack: string;
  tieTrack: any;
  lowMp = null;
  highMp = null;
  tieMilepost: any = [];
  direction: string;
  sideLineMilepost: any = [];
  errorDialogData: ErrorDialog = {
    dialogHeader: AppConstant.DUPLICATE_REPORT_TITLE,
    dialogBody: AppConstant.DUPLICATE_REPORT_MSG
  };
  showErrorComponent: Boolean = false;
  private teamName: string;
  lastestReport: any;
  tdFormGroup: FormGroup;
  tdSpeedRestrictionTypes: Array<KeyValue<TDWorkType, Array<KeyValue<number, string>>>> = [];
  isFormSubmitted: boolean;
  trackDisturbances: Array<SpeedRestrictionModel>;
  speedRestrictionErrorMessage: string;
  constructor(
    public formBuilder: FormBuilder,
    public dataService: DataService,
    public ls: LocalStorageService,
    public router: Router,
    public Activateroute: ActivatedRoute,
    public utilService: UtilService,
    public reportDataService: ReportDataService,
    public acknowledgeService: ConfirmationService,
    public reportNavigationService: ReportNavigationService
  ) {
    this.ls.getCurrentUser('SYSTEM').then((c: CurrentUserTable) => {
      this.teamName = c.teamName;
    });
    this.curveStackedOptions = [];
    this.curveStackedOptions.push({ label: 'Yes', value: 'Y' });
    this.curveStackedOptions.push({ label: 'No', value: 'N' });

    this.ls.getAllRefereces().then((refs: Array<ReferenceCode>) => {
      const refList = refs;
      for (const p of refList) {
        if (p.refType === TDSpeedType.SURFACING) {
          this.tdSpeedRestrictionTypes = [...this.utilService.getWorkAndSpeedRestrictionTypes(p.refObj, TDSpeedType.SURFACING)];
        }
        if (p.refType === TDSpeedType.TIMBERING) {
          this.tdSpeedRestrictionTypes.push(...this.utilService.getWorkAndSpeedRestrictionTypes(p.refObj, TDSpeedType.TIMBERING));
        }
      }
    });
    this.loadParam();
  }

  ngOnInit() {
    this.getJobObj();
    this.getTimeLimits();
    this.surfacingFieldEnable();
    this.loadReport();
    this.getProjectReports();
  }

  get viewMode() {
    return this.operationMode === OperationMode.VIEW;
  }

  passTsData(tsprogressData) {
    this.reportNotvalid = false;
    this.progressData = tsprogressData;
    console.log('this.progressData', this.progressData);
    this.surfacingFieldEnable();
  }

  passTieTrackData(tieTrack) {
    this.tieTrack = tieTrack;
    console.log('this.tieTrack', this.tieTrack);
  }

  loadReport() {
    this.getReportMethod().then(response => {
      console.log('response', response);
      if (response) {
        this.checkReportOperationMode(response);
        this.trackDisturbances = response.reportObj.trackDisturbanceDetails;
        this.railTemp1 = response.reportObj.railTemp1;
        this.railTemp2 = response.reportObj.railTemp2;
        this.railTemp3 = response.reportObj.railTemp3;
        this.surfacingMilesBehind = response.reportObj.surfacingMilesBehind;
        this.surfacingSchedulePlan = response.reportObj.surfacingSchedulePlan;
        this.curveStacked = response.reportObj.curvesStaked;
        this.remarks = response.reportObj.remarks;
        this.onDutyTime = this.utilService.convertTimeStampTOdate(
          response.reportObj.workday
        );
        if (response.reportObj.teamInTime != null) {
          this.surfaceTeamIn = this.utilService.convertTimeStampTOdate(
            response.reportObj.teamInTime
          );
        }
        console.log('prepopulate data::', response.reportObj);
        this.prepopulateData[0] = response.reportObj;
      } else {
        this.prepopulateData = null;
        if (this.onDutyTime !== undefined || this.onDutyTime !== null) {

          this.onDutyDateTimeChange(null);
        }
      }
    });
  }

  protected getReportMethod() {
    return this.dataService.getReport(Number(this.formId));
  }

  protected checkReportOperationMode(reportData: any) {
    if (reportData.reportObj.status === 'S' || reportData.reportObj.formId > 0) {
      this.operationMode = OperationMode.VIEW;
    } else if (reportData.reportObj.status === 'D') {
      this.operationMode = OperationMode.CREATE;
    }
  }

  private setViewOnlyModeValues(response: any) {
    if (response.reportObj.trackType !== 'YD') {
      const tieProgressObj = response.reportObj.tsProgress;
      console.log('tieProgressObj.tieProgress', tieProgressObj);
      let isworkedOnSet = false;
      for (const key in tieProgressObj.tieProgress) {
        if (tieProgressObj.tieProgress.hasOwnProperty(key)) {
          const tieProgress = tieProgressObj.tieProgress[key];
          if (
            tieProgress !== null &&
            tieProgress.trackName === 'MAINLINE TRACKS'
          ) {
            isworkedOnSet = true;
            tieProgressObj.workedOn = tieProgress.workedOn;
            tieProgressObj.direction = tieProgress.direction;
          }
        }
      }
      if (!isworkedOnSet) {
        for (const key in tieProgressObj.surfaceProgress) {
          if (tieProgressObj.tieProgress.hasOwnProperty(key)) {
            const surfaceProgress = tieProgressObj.tieProgress[key];
            if (
              surfaceProgress !== null &&
              surfaceProgress.trackName === 'MAINLINE TRACKS'
            ) {
              isworkedOnSet = true;
              tieProgressObj.workedOn = surfaceProgress.workedOn;
              tieProgressObj.direction = surfaceProgress.direction;
            }
          }
        }
        response.reportObj['tsProgress'] = tieProgressObj;
      }
    } else {
      const tieProgressYard = response.reportObj.tsProgress;
      let tCount = 0;
      let sCount = 0;
      let isDirectionSet = false;
      let workDirection: string;
      for (const key of tieProgressYard.tieProgress) {
        const tProgress = tieProgressYard.tieProgress[key];
        workDirection = tProgress.direction;
        isDirectionSet = true;
        tCount++;
      }
      if (!isDirectionSet) {
        for (const key of tieProgressYard.surfaceProgress) {
          const sProgress = tieProgressYard.surfaceProgress[key];
          workDirection = sProgress.direction;
          sCount++;
        }
      }
      if (tCount !== 0 && sCount !== 0) {
        tieProgressYard['workedOn'] = 'TS';
      } else if (sCount === 0) {
        tieProgressYard['workedOn'] = 'T';
      } else {
        tieProgressYard['workedOn'] = 'S';
      }
      response.reportObj['tsProgress'] = tieProgressYard;
    }
  }

  pushdata() {
    this.reportObj['workTypeCode'] = 'TS';
    this.reportObj['railTemp1'] = this.railTemp1;
    this.reportObj['railTemp2'] = this.railTemp2;
    this.reportObj['railTemp3'] = this.railTemp3;
    this.reportObj['curvesStaked'] = this.curveStacked;
    if (this.onDutyTime !== null && this.onDutyTime !== '') {
      this.reportObj['workday'] = new Date(this.onDutyTime).getTime();
    }
    if (this.surfaceTeamIn != null) {
      this.reportObj['teamInTime'] = new Date(this.surfaceTeamIn).getTime();
    }
    this.reportObj['surfacingMilesBehind'] = this.surfacingMilesBehind;
    this.reportObj['surfacingSchedulePlan'] = this.surfacingSchedulePlan;
    this.reportObj['jobId'] = this.jobId;
    this.reportObj['projectId'] = this.projectData;
    this.reportObj['formId'] = this.formId;
    this.reportObj['rl'] = this.rlCode;
    this.reportObj['roadMaster'] = this.roadMaster;
    this.reportObj['remarks'] = this.remarks;
    this.reportObj['tsProgress'] = this.tsProgress.tsProgressData;
    this.reportObj['jobType'] = this.jobType;
    this.reportObj['prefix'] = this.prefix;
    this.reportObj['trackType'] = this.trackType;
    this.reportObj['divisionCode'] = this.division;
    this.reportObj['subdivCode'] = this.subdivision;
    this.reportObj['showDisplay'] = this.tsProgress.sidingFlag;
    this.reportObj['totalTiesInstalled'] = this.totalTies;
    this.reportObj['lowMp'] = this.lowMp;
    this.reportObj['highMp'] = this.highMp;
    this.reportObj['direction'] = this.direction;
    this.reportObj['wpRange'] = this.sideLineMilepost;
    this.reportObj['teamName'] = this.dataService.team;
    this.reportObj['noProductionCode'] = null;
    this.reportObj['tieTrack'] = this.tieTrack;

    if (this.lastestReport) {
      this.reportObj['delayIssuesRemark'] = this.lastestReport.delayIssuesRemark;
      this.reportObj['issuesDesc'] = this.lastestReport.issuesDesc;
      this.reportObj['tieUpLocation'] = this.lastestReport.tieUpLocation;
      this.reportObj['nextDayLocation'] = this.lastestReport.nextDayLocation;
      this.reportObj['plannedStart'] = this.lastestReport.plannedStart;
      this.reportObj['plannedEnd'] = this.lastestReport.plannedEnd;
      this.reportObj['actualEmployeesCount'] = this.lastestReport.actualEmployeesCount;
      this.reportObj['greenSignalTime'] = this.lastestReport.greenSignalTime;
      this.reportObj['fullBlockReceived'] = this.lastestReport.fullBlockReceived;
      this.reportObj['trackReturnLate'] = this.lastestReport.trackReturnLate;
      this.reportObj['trackReturnLateRemarks'] = this.lastestReport.trackReturnLateRemarks;
      this.reportObj['pickupRemainCount'] = this.lastestReport.pickupRemainCount;
      if (this.lastestReport.reportWindows) {
        const modifiedWindows: any[] = [];
        this.lastestReport.reportWindows.forEach((window: any, i: any) => {
          window.unitsInstalled = null;
          window.unitPerHour = null;
          modifiedWindows.push(window);
        });
        this.reportObj['reportWindows'] = modifiedWindows;
      }
    } else if (!this.lastestReport) {
      if (!this.editMode) {
        this.ls.getNotes(this.teamName).then((notesData: any) => {
          if (notesData && notesData.notesObj && this.formId < 0) {
            this.reportObj['plannedStart'] = notesData.notesObj.plannedStart ?
              notesData.notesObj.plannedStart : null;
            this.reportObj['plannedEnd'] = notesData.notesObj.plannedEnd ?
              notesData.notesObj.plannedEnd : null;
            this.reportObj['greenSignalTime'] = notesData.notesObj.greenSignalTime ?
              notesData.notesObj.greenSignalTime : null;
            if (notesData.notesObj.reportWindows) {
              this.reportObj['reportWindows'] = notesData.notesObj.reportWindows;
            }
          }
        });
      }
    }
    if (this.prepopulateData !== null && this.prepopulateData[0]) {
      Object.assign(this.prepopulateData[0], this.reportObj);
      this.reportObj = this.prepopulateData[0];
    }
  }

  getJobObj() {
    this.dataService.getJobInfo(Number(this.jobId)).then(async (job1: JobTable) => {
      this.jobData = JSON.parse(JSON.stringify(job1.jobObj));
      if (this.jobData.roadmasters.length > 0) {
        const roadMasterRl = this.jobData.roadmasters[0].rl_c;
            let supervisor = null;
            supervisor = await this.utilService.mapRoadMasterDetails(roadMasterRl);
              this.roadMaster = supervisor ? supervisor.userObj.fullName : 'System Error';
              this.rlCode = this.jobData.roadmasters[0].rl_c;
      } else {
        this.roadMaster = 'System Error';
      }
      this.jobStartMp = this.jobData.startMP;
      this.jobEndMp = this.jobData.endMP;
      this.jobType = this.jobData.jobTypeCode;
      this.prefix = this.jobData.prefix;
      this.trackType = this.jobData.trackType;
      this.division = this.jobData.division;
      this.subdivision = this.jobData.subdivision;
      this.initializeSpeedRestrictionTD();
    });
  }

  private initializeSpeedRestrictionTD() {
    this.tdFormGroup = this.formBuilder.group({
      speedRestrictions: new FormArray([])
    });
  }

  surfacingFieldEnable() {
    this.surfacingFieldEnableFlag = true;
    let surfaced = false;
    if (this.progressData['surfaceProgress']) {
      if (this.progressData['surfaceProgress']['0'] !== undefined) {
        surfaced = true;
      }
      if (!surfaced) {
        this.surfacingFieldEnableFlag = false;
        this.surfaceTeamInWarning = false;
        this.surfacingMilesBehindWarning = false;
        this.surfacingSchedulePlanlimiterror = false;
      }
    }
  }

  validateField(field, event?: any) {
    this.errorCount = 0;
    this.reportNotvalid = false;
    if (field === 'surfacingSchedulePlan' && this.surfacingFieldEnableFlag) {
      this.surfacingSchedulePlanlimiterror = this.utilService.fieldLimit(
        this.surfacingSchedulePlan,
        200
      );
      if (this.surfacingSchedulePlanlimiterror) {
        this.surfacingSchedulePlanlimiterrorMessage =
          'cannot be >than 200 characters';
      }
    }
    if (field === 'remarks') {
      this.remarkslimiterror = this.utilService.fieldLimit(this.remarks, 200);
      if (this.remarkslimiterror) {
        this.remarkslimiterrorMessage = 'cannot be >than 200 characters';
      }
    }

    if (field === 'railTemp1') {
      if (this.railTemp1) {
        if (this.railTemp1 > 150) {
          this.railTemp1limiterror = true;
        } else {
          this.railTemp1limiterror = false;
        }
        this.railTemp1NumberError = !this.utilService.isFloat(this.railTemp1);
        if (this.railTemp1limiterror) {
          this.railTemp1Error = true;
          this.railTemp1ErrorMessage = 'Temp 1 cannot be > 150';
        } else if (this.railTemp1NumberError) {
          this.railTemp1Error = true;
          this.railTemp1ErrorMessage = 'Enter Numeric value';
        } else if (this.railTemp1 < 0) {
          this.railTemp1ErrorMessage = 'Temp 1 should be > 0';
          this.railTemp1Error = true;
        } else {
          this.railTemp1Error = false;
          this.railTemp1ErrorMessage = '';
        }
      } else {
        if (this.railTemp1 === 0) {
          this.railTemp1ErrorMessage = 'Temp 1 should be > 0';
        } else {
          this.railTemp1ErrorMessage = 'Enter Temp 1';
        }
        this.railTemp1limiterror = false;
        this.railTemp1NumberError = false;
        this.railTemp1Error = true;
      }
    }
    if (field === 'railTemp2') {
      if (this.railTemp2) {
        if (this.railTemp2 > 150) {
          this.railTemp2limiterror = true;
        } else {
          this.railTemp2limiterror = false;
        }
        this.railTemp2NumberError = !this.utilService.isFloat(this.railTemp2);
        if (this.railTemp2limiterror) {
          this.railTemp2Error = true;
          this.railTemp2ErrorMessage = 'Temp 2 cannot be > 150';
        } else if (this.railTemp2NumberError) {
          this.railTemp2Error = true;
          this.railTemp2ErrorMessage = 'Enter Numeric value';
        } else if (this.railTemp2 < 0) {
          this.railTemp2ErrorMessage = 'Temp 2 should be > 0';
          this.railTemp2Error = true;
        } else {
          this.railTemp2Error = false;
          this.railTemp2ErrorMessage = '';
        }
      } else {
        if (this.railTemp2 === 0) {
          this.railTemp2ErrorMessage = 'Temp 2 should be > 0';
        } else {
          this.railTemp2ErrorMessage = 'Enter Temp 2';
        }
        this.railTemp2limiterror = false;
        this.railTemp2NumberError = false;
        this.railTemp2Error = true;
      }
    }
    if (field === 'railTemp3') {
      if (this.railTemp3) {
        if (this.railTemp3 > 150) {
          this.railTemp3limiterror = true;
        } else {
          this.railTemp3limiterror = false;
        }
        this.railTemp3NumberError = !this.utilService.isFloat(this.railTemp3);
        if (this.railTemp3limiterror) {
          this.railTemp3Error = true;
          this.railTemp3ErrorMessage = 'Temp 3 cannot be > 150';
        } else if (this.railTemp3NumberError) {
          this.railTemp3Error = true;
          this.railTemp3ErrorMessage = 'Enter Numeric value ';
        } else if (this.railTemp3 < 0) {
          this.railTemp3ErrorMessage = 'Temp 1 should be > 0';
          this.railTemp3Error = true;
        } else {
          this.railTemp3Error = false;
          this.railTemp3ErrorMessage = '';
        }
      } else {
        if (this.railTemp3 === 0) {
          this.railTemp3ErrorMessage = 'Temp 3 should be > 0';
        } else {
          this.railTemp3ErrorMessage = 'Enter Temp 3';
        }
        this.railTemp3limiterror = false;
        this.railTemp3NumberError = false;
        this.railTemp3Error = true;
      }
    }
    if (field === 'onDutyTime') {
      this.getTimeLimits();
      if (this.onDutyTime) {
        this.dateTimeWarning = false;
        this.dateTimeWarningMessage = '';
        if (this.surfaceTeamIn) {
          this.validateField('surfaceTeamIn');
        }
        if (this.checkReportsOnDutyDate()) {
          this.dateTimeWarning = true;
          this.dateTimeWarningMessage = 'Rest day report exists on this day.';
        }
      } else {
        this.dateTimeWarning = true;
        this.dateTimeWarningMessage = 'Select On Duty Date-Time';
      }
      if (!this.dateTimeWarning && event) {
        this.onDutyDateTimeChange(event);
      }
    }
    if (field === 'surfacingMilesBehind' && this.surfacingFieldEnableFlag) {
      if (this.surfacingMilesBehind) {
        if (this.surfacingMilesBehind <= this.jobEndMp - this.jobStartMp + 4) {
          this.surfacingMilesBehindWarning = false;
          this.surfacingMilesBehindWarningMessage = ' ';
        } else {
          this.surfacingMilesBehindWarning = true;
          this.surfacingMilesBehindWarningMessage =
            'Exceeds Total TSC job miles';
        }
      } else {
        this.surfacingMilesBehindWarning = this.utilService.emptyCheck(
          this.surfacingMilesBehind
        );
        this.surfacingMilesBehindWarningMessage =
          ' Enter Surfacing Miles Behind';
      }
    }
    if (field === 'surfaceTeamIn' && this.surfacingFieldEnableFlag) {
      if (this.surfaceTeamIn) {
        if (this.surfaceTeamIn < this.onDutyTime) {
          this.surfaceTeamInWarning = true;
          this.surfaceTeamInWarningMessage = 'Should be >= On Duty Date-Time';
        } else {
          this.surfaceTeamInWarning = false;
          this.surfaceTeamInWarningMessage = '';
        }
      } else {
        this.surfaceTeamInWarning = true;
        this.surfaceTeamInWarningMessage = 'Select Surface Time In';
      }
    }
  }

  getMilepost() {
    const tieProgress = Object.keys(this.progressData['tieProgress']).map(
      k => this.progressData['tieProgress'][k]
    );
    console.log('tieDetails', tieProgress);
    for (let j = 0; j < tieProgress.length; j++) {
      if (
        tieProgress[j]['trackName'] === 'MAINLINE TRACKS' ||
        this.trackType === 'SD' ||
        this.trackType === 'YD'
      ) {
        this.direction = tieProgress[j]['direction'];
        this.tieMilepost.push(
          tieProgress[j]['beginMilepost'],
          tieProgress[j]['endMilepost']
        );
      }
    }
    console.log('milepost', this.tieMilepost);
    this.lowMp = Math.min(...this.tieMilepost);
    if (this.lowMp === Infinity) {
      this.lowMp = 0;
    }
    this.highMp = Math.max(...this.tieMilepost);
    if (this.highMp === -Infinity) {
      this.highMp = 0;
    }
    const groups = [];
    for (let l = 0; l < tieProgress.length; l++) {
      const track = tieProgress[l]['trackName'];
      if (!groups[track]) {
        groups[track] = [];
      }
      groups[track].push(
        tieProgress[l]['beginMilepost'],
        tieProgress[l]['endMilepost']
      );
    }
    this.sideLineMilepost = [];
    for (const track in groups) {
      if (groups) {
        this.sideLineMilepost.push({ track: track, milepost: groups[track] });
      }
    }
    for (let x = 0; x < tieProgress.length; x++) {
      this.sideLineMilepost.forEach((item, index) => {
        if (item.track === tieProgress[x]['trackName']) {
          this.sideLineMilepost[index]['lowMp'] = Math.min(...item['milepost']);
          this.sideLineMilepost[index]['highMp'] = Math.max(
            ...item['milepost']
          );
          this.sideLineMilepost[index]['direction'] =
            tieProgress[x]['direction'];
        }
      });
    }
    for (let i = 0; i < this.sideLineMilepost.length; i++) {
      delete this.sideLineMilepost[i]['milepost'];
    }
    console.log('final milepost0', this.sideLineMilepost);
  }

  calTotalTies() {
    this.totalTies = 0;
    //  let crossingTies = 0;
    for (let i = 0; this.progressData['tieProgress'][i] !== undefined; i++) {
      const tiesCount = this.progressData['tieProgress'][i].tiePlateCount;
      this.totalTies = this.totalTies + tiesCount;
    }
  }

  getTimeLimits() {
    this.maxDate = this.utilService.getCurrentDateTime();
    this.minDate = this.utilService.getMinDateTime();
  }

  loadParam(): void {
    this.Activateroute.params.subscribe(params => {
      this.projectData = params.project;
      if (this.projectData) {
        this.projectId = Number(this.projectData.split('-')[0]);
      }
      this.jobId = params.job;
      this.formId = params.form;
    });
  }

  onDutyDateTimeChange(event: any) {
    const onDutyWorkDate = this.onDutyTime ? new Date(this.onDutyTime) : new Date();
    this.reportDataService.getLatestProdReportFromAllProjects(this.teamName, onDutyWorkDate.getTime()).subscribe(
      lastestReportObj => {
        this.lastestReport = lastestReportObj;
        if (lastestReportObj === null || lastestReportObj === undefined) {
          this.ls.getCurrentUser('SYSTEM').then(data => {
            this.teamName = data ? data.teamName : null;
            this.ls.getNotes(this.teamName).then((notesData: any) => {
              if (notesData && notesData.notesObj.onDutyTime) {
                const notes = JSON.parse(JSON.stringify(notesData));
                this.onDutyTime = notes.notesObj.onDutyTime ? notes.notesObj.onDutyTime : this.onDutyTime;
              }
            });
          });
          this.remarks = null;
          this.railTemp1 = null;
          this.railTemp2 = null;
          this.railTemp3 = null;
        } else {
          this.remarks = lastestReportObj.remarks;
          this.railTemp1 = lastestReportObj.railTemp1;
          this.railTemp2 = lastestReportObj.railTemp2;
          this.railTemp3 = lastestReportObj.railTemp3;
          // event null is for prepopulating first time
          if (event === null) {
            this.onDutyTime = this.utilService.convertTimeStampTOdate(lastestReportObj.workday);
          }
        }
      },
      error => { },
      () => { }
    );
  }


  /** While user click back button and save the data into IndexDB * */
  async backClick(event) {
    if (this.viewMode) {
      this.router.navigate([`/job_view/${this.projectData}`]);
    } else {
      if (this.onDutyTime === null) {
        this.onDutyTime = this.utilService.getCurrentDateTime();
      }
      // if(this.surfaceTeamIn === null){
      //     this.surfaceTeamIn = this.utilService.getCurrentDateTime();
      // }
      this.calTotalTies();
      this.getMilepost();
      this.pushdata();
      this.saveReportToIndexDbMethod(this.reportObj)
        .then(response => {
          this.router.navigate([`/job_view/${this.projectData}`]);
        })
        .catch(reason => { });
    }
  }

  get editMode() {
    return this.operationMode === OperationMode.EDIT;
  }
  async formSubmit(event) {
    this.speedRestrictionErrorMessage = '';
    if (this.viewMode) {
      this.navigateToPlannedActual();
    } else {
      let isTsProgressValid: boolean;
      await this.tsProgress.validateTsProgress().then(valid => {
        isTsProgressValid = valid;
      });
      let trackDisturbancesInvalid = false;
      if (this.tdFormGroup.invalid) {
        this.isFormSubmitted = true;
        trackDisturbancesInvalid = true;
      } else {
        this.reportObj['trackDisturbanceDetails'] = this.getTdValue();
      }

      if (!this.surfacingFieldEnableFlag) {
        this.surfaceTeamIn = null;
        this.surfacingMilesBehind = null;
        this.surfacingSchedulePlan = null;
      }

      this.calTotalTies();
      this.getMilepost();

      const reportObjecttest = [
        'onDutyTime',
        'surfaceTeamIn',
        'surfacingMilesBehind',
        'railTemp1',
        'railTemp2',
        'railTemp3'
      ];
      this.pushdata();
      reportObjecttest.forEach(obj => {
        this.validateField(obj);
      });
      this.errorCount = 0;
      this.reportNotvalid = false;
      if (
        this.railTemp1Error ||
        this.railTemp2Error ||
        this.railTemp3Error ||
        this.surfacingMilesBehindWarning ||
        this.surfaceTeamInWarning ||
        this.dateTimeWarning ||
        !isTsProgressValid ||
        trackDisturbancesInvalid
      ) {
        this.reportNotvalid = true;
      }

      if (!this.reportNotvalid) {
        console.log('obj', this.reportObj);
        if (this.isSpeedRestrictionValid()) {
          this.reportObj['railSide'] = null;
          this.reportDataService.validateDuplicateReports(this.reportObj).then((isDuplicate: boolean) => {
            this.showErrorComponent = isDuplicate;
            if (!isDuplicate) {
              this.saveReportToIndexDbMethod(this.reportObj)
                .then(response => {
                  this.navigateToPlannedActual();
                })
                .catch(reason => {
                  console.log(reason);
                });
            }
          });
        }
      }
    }
  }

  protected getTdValue() {
    return this.tdFormGroup.enabled ? this.tdFormGroup.get('speedRestrictions').value : [];
  }

  getTrackTypesWhereWorkIsDone() {
    // added for back navigation
    const exsistingData: any = this.prepopulateData && this.prepopulateData.length ? this.prepopulateData[0] : null;
    if (exsistingData) {
      const tsDetails = [];
      Object.keys(exsistingData.tsProgress.tieProgress).forEach((t: any) => {
        if (exsistingData.tsProgress.tieProgress[t].tiePlateCount !== null) {
          tsDetails.push(exsistingData.tsProgress.tieProgress[t].trackType);
        }
      });
      Object.keys(exsistingData.tsProgress.surfaceProgress).forEach((t: any) => {
        if (exsistingData.tsProgress.surfaceProgress[t].tiePlateCount !== null) {
          tsDetails.push(exsistingData.tsProgress.surfaceProgress[t].trackType);
        }
      });
      return tsDetails;
    } else {
      const tieDetails = this.tsProgress.tieMainComponent.tiesDetails.filter(t =>
        t.tiePlateCount !== null);
      const surfaceDetails = this.tsProgress.surfaceMainComponent.details.filter(t =>
        t.surfaced !== null);
      const tieDetailsCheck = this.tsProgress.tieTrack ?
        this.tsProgress.tieTrack.filter((t, i) => this.tsProgress.sideTieValid[i]
          && this.tsProgress.sideSurfaceValid[i]) : [];
      const tsDetails = [...tieDetails, ...surfaceDetails, ...tieDetailsCheck];
      return tsDetails.map(item => item.trackType);
    }
  }

  protected navigateToPlannedActual() {
    this.router.navigate([`/planned_actual/${this.projectId}/${this.jobId}/${this.formId}`]);
  }

  protected saveReportToIndexDbMethod(repObj: any) {
    return this.dataService.saveDraftReport(JSON.stringify(repObj));
  }

  isSpeedRestrictionValid() {
    const speedRestriction = this.tdFormGroup.get('speedRestrictions').value;
    const trackNames = [];
    let isValid = false;
    if (this.tsProgress.tieTrack && this.tsProgress.tieTrack.length) {
      const tieDetails = this.tsProgress.tieTrack.filter((t, i) => this.tsProgress.sideTieValid[i]
        && this.tsProgress.sideSurfaceValid[i]);
      if (tieDetails.length) {
        tieDetails.forEach(item => {
          if (item.trackType !== 'O' && item.trackType !== 'Y' && item.trackType !== 'YD'
            && (item.trackType.slice(0, 2) !== 'XO') && (item.trackType !== 'I')
            && (item.trackType !== 'F')) {
            if (speedRestriction.findIndex(i => (i.trackTypeCode.split('-')[1] === item.trackName)) === -1) {
              trackNames.push(item.trackName);
            }
          }
        });
        if (trackNames.length >= 1) {
          isValid = true;
        }
      }
    }
    if ((this.tsProgress.tieMainComponent.tiesDetails &&
      this.tsProgress.tieMainComponent.tiesDetails.length) ||
      (this.tsProgress.surfaceMainComponent.details && this.tsProgress.surfaceMainComponent.details.length)) {
      const tsDetails = [];
      const tieDetails = this.tsProgress.tieMainComponent.tiesDetails;
      const surfaceDetails = this.tsProgress.surfaceMainComponent.details;
      if (tieDetails.length && this.tsProgress.tieWorkedFlag === true) {
        tsDetails.push(...tieDetails);
      }
      if (surfaceDetails.length && this.tsProgress.surfaceWorkedFlag === true) {
        tsDetails.push(...surfaceDetails);
      }
      if (tsDetails.length) {
        tsDetails.forEach(item => {

          if (((item.trackType !== 'O') && (item.trackType.slice(0, 2) !== 'XO') && (item.trackType !== 'YD') && (item.trackType !== 'I')
            && (item.trackType !== 'F') && (item.tiePlateCount && item.tiePlateCount > 0)) &&
            speedRestriction.findIndex(i => i.trackTypeCode === item.trackType) === -1) {
            isValid = true;
            if (trackNames.indexOf(item.trackName) === -1) {
              trackNames.push(item.trackName);
            }
          }
        });
      }
    }
    if (isValid) {
      this.speedRestrictionErrorMessage =
        'Work Type and Speed Restriction section is missing ' + trackNames.join(', ') + ' ' + ' Track Types';
      return false;
    }
    return true;
  }


  onErrorDialogClose(): void {
    this.showErrorComponent = false;
  }

  getProjectReports(): void {
    this.ls
      .getProjectReports(Number(this.projectId))
      .then((reportList: Array<ReportTable>) => {
        for (const p of reportList) {
          const js: any = JSON.parse(JSON.stringify(p.reportObj));
          this.projectReports.push(js);
        }
      });
  }

  checkReportsOnDutyDate(): boolean {
    let match = false;
    const onDuty = this.onDutyTime.split('T')[0];
    for (const report of this.projectReports) {
      const compare = this.convertEpochToDate(report.workday);
      let reportType = '';
      if (report.hasOwnProperty('noProductionCode')) {
        if (report['noProductionCode'] === 'RST') {
          reportType = 'REST';
        } else {
          reportType = 'NO PRODUCTION';
        }
      } else {
        reportType = 'PRODUCTION';
      }
      if (reportType === 'REST' && compare === onDuty && this.teamName === report.teamName) {
        match = true;
        break;
      }
    }
    return match;
  }

  convertEpochToDate(epoch: number) {
    if (epoch == null) {
      return null;
    }
    let date = new Date(epoch).toLocaleDateString().replace(/\//g, '-');
    const dateArray = date.split('-');
    date =
      dateArray[2] +
      '-' +
      this.convertSingleDigitToDouble(dateArray[0]) +
      '-' +
      this.convertSingleDigitToDouble(dateArray[1]);
    return date;
  }

  convertSingleDigitToDouble(value: string): string {
    if (value.length === 1) {
      return '0' + value;
    } else {
      return value;
    }
  }
}
