import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  LocalStorageService,
  ReferenceCode
} from '../../services/local-storage.service';

import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data.service';
import { SelectItem } from 'primeng/primeng';
import { UtilService } from '../../util/util.service';
import { MasterTrack } from '../../model/track.model';
import { AppConstant } from '../../shared/constant/constants';
import { OperationMode } from '../../shared/enums/operation-mode.enum';

@Component({
  selector: 'app-tie-progress',
  templateUrl: './tie-progress.component.html',
  styleUrls: ['./tie-progress.component.scss']
})

/**
 * @export
 * @class TieProgressComponent
 * @implements {OnInit}
 * @implements {CrossingTiesTimbered}
 */
export class TieProgressComponent implements OnInit {
  isSiding: boolean;
  @Input()
  jobBeginMp: number;
  @Input()
  jobEndMp: number;
  @Input()
  trackType: string;
  @Input()
  direction: string;
  @Input()
  sidingObject: Siding[];
  @Input()
  trackName: string;
  @Input()
  workedOn: string;
  @Input()
  loadTiedata: object = {};
  @Input()
  prepopulateTie: TiesDetails[] = [];
  @Output()
  tieData: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  reportLoad: boolean;
  @Input()
  subSequentReport: boolean;
  @Input()
  previousTrackMp: any[];

  @Input() operationMode: OperationMode;
  projectData: string;
  projectId: number;
  jobId: number;
  formId: number;
  crossingTiesTimbered: boolean;
  crossingTimberedOptions: CrossingTiesTimbered[];
  tiesCrossingDetails: any[] = [];
  tiesDetails: TiesDetails[] = [];
  beginmplimits: { low: any; high: any };
  tiePlateTypesOptions: SelectItem[];
  tiePlateTypesOptions1: string[];
  isYard: boolean;
  crossingTimbered: boolean;
  /** Front End Validation Error Messages */
  tieEndMPOutOfJobLimitError: string;
  tieBeginMPError: string[] = [];
  tieEndMPError: string[] = [];
  tiePlateTypesError: string[] = [];
  tiePlateTypeCountError: string[] = [];
  tieCountErrorMsg: string[] = [];
  trackNameError: string[] = [];
  sidingTrackNameError: string[] = [];
  beginMpEmptyCheckError: boolean[] = [];
  endMpEmptyCheckrror: boolean[] = [];
  beginMPOutOfLimitError: boolean[] = [];
  endMPOutOfLimitError: boolean[] = [];
  endMPNextWholeMpError: boolean[] = [];
  tieDetailsInvalid: boolean;
  isValidationSuccess: boolean;
  tieCountError: boolean;
  errorString: string;
  addString: string;
  tieBeginMPFieldError: boolean[] = [];
  tieEndMPFieldError: boolean[] = [];
  tiePlateTypesFieldError: boolean[] = [];
  tiePlateTypeCountFieldError: boolean[] = [];
  trackNameFieldError: boolean[] = [];
  sidingTrackNameFieldError: boolean[] = [];
  crossingTiesTimberedFieldError: boolean;
  trackNameOptions: TrackName[] = [];
  prepopulateEndMp: number;
  prepopulateStartMp: number;
  hasPreviousTrack: boolean;
  railTypeOptions: SelectItem[];
  railTypeFieldError: boolean[] = [];
  railTypeError: string[] = [];
  masterTrackList: MasterTrack[] = [];
  twoDecimalPattern = new RegExp('(^(0{0,1}|([1-9][0-9]*))(.[0-9]{1,2})?$)');

  constructor(
    public utilService: UtilService,
    public dataService: DataService,
    public activeRoute: ActivatedRoute,
    public localStorage: LocalStorageService
  ) {
    this.activeRoute.params.subscribe(params => {
      this.projectId = Number(params.project);
      this.jobId = Number(params.job);
      this.formId = Number(params.form);
    });
    this.utilService
      .getMasterTrack(this.jobId)
      .then((masterTrackList: MasterTrack[]) => {
        this.masterTrackList = masterTrackList;
        console.log('master track list', masterTrackList);
      });
    this.loadReferenceData();
    this.railTypeOptions = [];
    this.railTypeOptions.push({ label: 'CWR', value: 'C' });
    this.railTypeOptions.push({ label: '	Jointed Rail', value: 'J' });
    this.crossingTimberedOptions = [
      { label: 'Yes', code: true },
      { label: 'No', code: false }
    ];
    this.crossingTiesTimbered = null;
    setTimeout(() => {
      this.getTrack();
      this.prepopulateData();
    }, 500);
  }

  ngOnInit() { }

  get viewMode() {
    return this.operationMode === OperationMode.VIEW;
  }

  get editMode() {
    return this.operationMode === OperationMode.EDIT;
  }

  crossing() {
    this.crossingTiesTimbered = JSON.parse(
      this.crossingTiesTimbered.toString()
    );
    if (this.crossingTiesTimbered !== null) {
      if (this.tiesDetails) {
        for (let i = 0; i < this.tiesDetails.length; i++) {
          this.tiesDetails[i][
            'crossingTiesTimbered'
          ] = this.crossingTiesTimbered;
          this.passTieData();
        }
      }
    }
  }

  prepopulateData() {
    if (this.prepopulateTie[0] !== undefined) {
      this.tiesDetails = [];
      for (let i = 0; this.prepopulateTie[i] !== undefined; i++) {
        if (
          this.trackName === this.prepopulateTie[i]['trackName'] ||
          (this.trackType === 'SD' && !this.sidingObject)
        ) {
          this.crossingTiesTimbered = this.prepopulateTie[
            i
          ].crossingTiesTimbered;
          this.tiesDetails.push(
            new TiesDetails(
              this.prepopulateTie[i]['beginMilepost'],
              this.prepopulateTie[i]['endMilepost'],
              this.prepopulateTie[i]['tiePlateType'],
              this.prepopulateTie[i]['tiePlateCount'],
              this.prepopulateTie[i]['trackName'],
              this.prepopulateTie[i]['trackType'],
              this.prepopulateTie[i]['crossingTiesTimbered'],
              this.prepopulateTie[i]['direction'],
              this.prepopulateTie[i]['workedOn'],
              this.prepopulateTie[i]['railType']
            )
          );
        } else if (this.prepopulateTie[i]['trackType'] === 'YD') {
          this.crossingTiesTimbered = this.prepopulateTie[
            i
          ].crossingTiesTimbered;
          this.tiesDetails.push(
            new TiesDetails(
              this.prepopulateTie[i]['beginMilepost'],
              this.prepopulateTie[i]['endMilepost'],
              this.prepopulateTie[i]['tiePlateType'],
              this.prepopulateTie[i]['tiePlateCount'],
              this.prepopulateTie[i]['trackName'],
              this.prepopulateTie[i]['trackType'],
              this.prepopulateTie[i]['crossingTiesTimbered'],
              this.prepopulateTie[i]['direction'],
              this.prepopulateTie[i]['workedOn'],
              this.prepopulateTie[i]['railType']
            )
          );
          //  this.passTieData();
        }
      }
      this.passTieData();
      if (this.tiesDetails.length === 0) {
        this.prepopulateMilepost(null, null, false);
      }
    } else if (this.reportLoad) {
      // setTimeout(() => {
      // this.prepopulateMilepost();

      if (this.tiesDetails.length === 0) {
        this.prepopulateMilepost(null, null, false);
      }
      // }, 200);
    }
  }

  removeValidation() {
    for (let i = 0; i < this.tiesDetails.length; i++) {
      this.removeValidationatIndex(i);
    }
  }

  removeValidationatIndex(i) {
    this.trackNameError[i] = '';
    this.crossingTiesTimberedFieldError = false;
    this.errorString = '';
    this.trackNameFieldError[i] = false;
    this.tiePlateTypesFieldError[i] = false;
    this.tiePlateTypesError[i] = '';
    this.tiePlateTypeCountFieldError[i] = false;
    this.tiePlateTypeCountError[i] = '';
    this.tieBeginMPFieldError[i] = false;
    this.tieBeginMPError[i] = '';
    this.tieEndMPFieldError[i] = false;
    this.tieEndMPError[i] = '';
    this.sidingTrackNameFieldError[i] = false;
    this.sidingTrackNameError[i] = '';
    this.railTypeError[i] = '';
    this.railTypeFieldError[i] = false;
  }

  async prepopulateMilepost(direction, workedOn, wipe) {
    this.hasPreviousTrack = false;
    if (workedOn) {
      this.workedOn = workedOn;
    }
    if (direction) {
      this.direction = direction;
    }
    let previousBeginMp = 0;
    let prepopulateEndMp = 0;
    // previousBeginMp = this.jobBeginMp;
    // if (this.direction === 'I') {
    //     previousBeginMp = this.jobBeginMp;
    // } else {
    //     previousBeginMp = this.jobEndMp;
    // }
    // if (!this.subSequentReport || this.sidingObject['previousLowMp'] === undefined) {
    //     // previousBeginMp = this.jobBeginMp;
    //     if (this.direction === 'I') {
    //         previousBeginMp = this.jobBeginMp;
    //     } else {
    //         previousBeginMp = this.jobEndMp;
    //     }
    // } else
    if (this.previousTrackMp && this.subSequentReport) {
      for (let i = 0; i < this.previousTrackMp.length; i++) {
        if (this.previousTrackMp[i].track === this.trackName) {
          this.hasPreviousTrack = true;
          if (this.direction === this.previousTrackMp[i].direction) {
            if (this.direction === 'D') {
              previousBeginMp = this.previousTrackMp[i].lowMp;
            } else {
              previousBeginMp = this.previousTrackMp[i].highMp;
            }
          } else {
            wipe = true;
          }
        }
      }
    }
    if (!this.hasPreviousTrack) {
      if (this.direction === 'I') {
        previousBeginMp = this.jobBeginMp;
      } else {
        previousBeginMp = this.jobEndMp;
      }
    }
    if (this.direction === 'I') {
      prepopulateEndMp = Math.trunc(previousBeginMp) + 1;
      if (prepopulateEndMp > this.jobEndMp) {
        prepopulateEndMp = this.jobEndMp;
      }
      if (
        previousBeginMp === this.jobEndMp &&
        prepopulateEndMp === this.jobEndMp
      ) {
        previousBeginMp = null;
        prepopulateEndMp = null;
      }
      if (previousBeginMp === this.jobEndMp) {
        previousBeginMp = null;
      }
    } else {
      prepopulateEndMp = Math.trunc(previousBeginMp);
      if (previousBeginMp === prepopulateEndMp) {
        prepopulateEndMp = prepopulateEndMp - 1;
      }
      if (prepopulateEndMp < this.jobBeginMp) {
        prepopulateEndMp = this.jobBeginMp;
      }
    }
    if (previousBeginMp === prepopulateEndMp) {
      prepopulateEndMp = null;
    }

    if (wipe && this.subSequentReport && this.hasPreviousTrack) {
      this.tiesDetails.push(
        new TiesDetails(
          null,
          null,
          AppConstant.TIE_PLATE_TYPE_VALUE.MAINLINE_WOOD,
          null,
          this.trackName,
          this.trackType,
          this.crossingTiesTimbered,
          this.direction,
          this.workedOn,
          null
        )
      );
    } else {
      if (this.trackType === 'YD') {
        this.tiesDetails.push(
          new TiesDetails(
            previousBeginMp,
            prepopulateEndMp,
            AppConstant.TIE_PLATE_TYPE_VALUE.MAINLINE_WOOD,
            null,
            this.trackName,
            this.trackType,
            this.crossingTiesTimbered,
            this.direction,
            this.workedOn,
            null
          )
        );
      } else {
        this.tiesDetails.push(
          new TiesDetails(
            previousBeginMp,
            prepopulateEndMp,
            AppConstant.TIE_PLATE_TYPE_VALUE.MAINLINE_WOOD,
            null,
            this.trackName,
            this.trackType,
            this.crossingTiesTimbered,
            this.direction,
            this.workedOn,
            null
          )
        );
      }
    }
  }

  validateMilepost(index: number) {
    this.validateBeginMilePost(index);
    this.validateEndMilePost(index);
    this.passTieData();
  }

  loadReferenceData(): void {
    // *******  this is used to add values to field components
    this.localStorage.getAllRefereces().then((refs: Array<ReferenceCode>) => {
      const refList = refs;
      for (const p of refList) {
        if (p.refType === 'TTT') {
          this.tiePlateTypesOptions = this.utilService.hack(
            p.refObj,
            'tiePlateType'
          );
        }
      }
      if (!this.viewMode) {
        this.tiePlateTypesOptions = this.tiePlateTypesOptions.filter(refObj => refObj.value !== AppConstant.TIE_PLATE_TYPE_VALUE.WOOD);
      }
    });
  }

  /**
   * Add new Ties Details for Mainline or Side tracks
   * @memberof TieProgressComponent
   */
  async addTiesDetail(index: number) {
    const validTrack = this.validateAll(false);
    this.tieDetailsInvalid = false;
    let jobEndLimit = Number(this.jobEndMp);
    let jobStartLimit = Number(this.jobBeginMp);
    if (!this.isSiding) {
      jobEndLimit = jobEndLimit + 2;
      jobStartLimit = jobStartLimit - 2;
    }
    if (validTrack) {
      if (
        (this.tiesDetails[index].endMilepost === jobEndLimit &&
          this.direction === 'I') ||
        (this.tiesDetails[index].endMilepost === jobStartLimit &&
          this.direction === 'D')
      ) {
        this.prepopulateStartMp = null;
      } else {
        if (!this.isSiding) {
          console.log(
            'this.utilService.validStartEndMp(this.direction, this.jobId)',
            this.utilService.validStartEndMp(this.direction, this.jobId)
          );
          if (
            Number(
              this.utilService.validStartEndMp(this.direction, this.jobId)
            ) === this.tiesDetails[index].endMilepost
          ) {
            this.prepopulateStartMp = null;
          } else {
            this.prepopulateStartMp = this.tiesDetails[index].endMilepost;
          }
        } else {
          this.prepopulateStartMp = this.tiesDetails[index].endMilepost;
        }
      }
      if (this.direction === 'I') {
        this.prepopulateEndMp =
          Math.trunc(this.tiesDetails[index].endMilepost) + 1;
        if (this.prepopulateEndMp > jobEndLimit && this.prepopulateStartMp) {
          this.prepopulateEndMp = jobEndLimit;
        } else if (this.prepopulateStartMp === jobStartLimit) {
          this.prepopulateStartMp = null;
        } else if (this.prepopulateStartMp === null) {
          this.prepopulateEndMp = null;
        }
      } else if (
        this.tiesDetails[index].endMilepost ===
        Math.trunc(this.tiesDetails[index].endMilepost)
      ) {
        this.prepopulateEndMp = this.tiesDetails[index].endMilepost - 1;
        if (this.prepopulateEndMp < jobStartLimit) {
          this.prepopulateEndMp = jobStartLimit;
        }
        if (this.prepopulateStartMp === jobStartLimit) {
          this.prepopulateStartMp = null;
        }
      } else {
        if (this.prepopulateStartMp === null) {
          this.prepopulateEndMp = null;
        } else {
          this.prepopulateEndMp = Math.trunc(
            this.tiesDetails[index].endMilepost
          );
        }
      }
      let isSiding = false;
      if (this.isYard === false && this.sidingObject) {
        isSiding = true;
      }
      if (
        this.utilService.verifyMPOutSideJobLimit(
          this.jobBeginMp,
          this.jobEndMp,
          this.prepopulateEndMp,
          this.direction,
          isSiding
        )
      ) {
        this.prepopulateEndMp = null;
      }
      if (!this.isSiding && this.prepopulateEndMp) {
        if (
          !this.utilService.isMilePostWithInChainFeet(
            this.prepopulateEndMp,
            this.jobId,
            this.trackType,
            this.trackName
          )
        ) {
          this.prepopulateEndMp = this.utilService.validStartEndMp(
            this.direction,
            this.jobId
          );
        }
      }
      if (this.prepopulateStartMp === null) {
        this.prepopulateEndMp = null;
      }
      setTimeout(() => {
        if (this.trackType === 'YD') {
          if (
            this.tiesDetails[index].trackName !== null &&
            !this.trackNameFieldError[index] &&
            this.tiesDetails[index].tiePlateType !== null &&
            !this.tiePlateTypesFieldError[index] &&
            this.tiesDetails[index].tiePlateCount !== null &&
            !this.tiePlateTypeCountFieldError[index]
          ) {
            this.tieDetailsInvalid = false;
            if (this.direction === 'I') {
              this.tiesDetails.push(
                new TiesDetails(
                  this.prepopulateStartMp,
                  this.prepopulateEndMp,
                  AppConstant.TIE_PLATE_TYPE_VALUE.MAINLINE_WOOD,
                  null,
                  this.trackName,
                  this.trackType,
                  this.crossingTiesTimbered,
                  this.direction,
                  this.workedOn,
                  null
                )
              );
            } else {
              this.tiesDetails.push(
                new TiesDetails(
                  this.prepopulateStartMp,
                  this.prepopulateEndMp,
                  AppConstant.TIE_PLATE_TYPE_VALUE.MAINLINE_WOOD,
                  null,
                  this.trackName,
                  this.trackType,
                  this.crossingTiesTimbered,
                  this.direction,
                  this.workedOn,
                  null
                )
              );
            }
          } else {
            this.tieDetailsInvalid = true;
          }
        } else {
          if (
            this.tiesDetails[index].beginMilepost !== null &&
            !this.tieBeginMPFieldError[index] &&
            this.tiesDetails[index].endMilepost !== null &&
            !this.tieEndMPFieldError[index] &&
            this.tiesDetails[index].tiePlateType !== null &&
            !this.tiePlateTypesFieldError[index] &&
            this.tiesDetails[index].tiePlateCount !== null &&
            !this.tiePlateTypeCountFieldError[index] &&
            this.tiesDetails[index].railType !== null &&
            !this.railTypeFieldError[index]
          ) {
            this.tieDetailsInvalid = false;
            if (this.direction === 'I') {
              this.tiesDetails.push(
                new TiesDetails(
                  this.prepopulateStartMp,
                  this.prepopulateEndMp,
                  AppConstant.TIE_PLATE_TYPE_VALUE.MAINLINE_WOOD,
                  null,
                  this.trackName,
                  this.trackType,
                  this.crossingTiesTimbered,
                  this.direction,
                  this.workedOn,
                  null
                )
              );
            } else {
              this.tiesDetails.push(
                new TiesDetails(
                  this.prepopulateStartMp,
                  this.prepopulateEndMp,
                  AppConstant.TIE_PLATE_TYPE_VALUE.MAINLINE_WOOD,
                  null,
                  this.trackName,
                  this.trackType,
                  this.crossingTiesTimbered,
                  this.direction,
                  this.workedOn,
                  null
                )
              );
            }
          } else {
            this.tieDetailsInvalid = true;
          }
        }
      }, 250);
      // this.validateAll();
      this.passTieData();
    }
  }

  /**
   * Remove new Ties Details for Mainline or Side tracks
   * @param {number} index
   * @memberof TieProgressComponent
   */
  async removeTiesDetail(index: number) {
    if (this.tiesDetails.length > 1) {
      if (index > -1) {
        this.tiesDetails.splice(index, 1);
        this.beginMPOutOfLimitError.splice(index, 1);
        this.endMPOutOfLimitError.splice(index, 1);
        this.endMPNextWholeMpError.splice(index, 1);
        this.removeValidationatIndex(index);
      }
    }

    console.log(' this.tiesDetails', this.tiesDetails);
    this.passTieData();
  }

  validateCrossingTimberedOptions(crossingTiesTimbered: boolean) {
    this.tieDetailsInvalid = false;
    if (crossingTiesTimbered === null) {
      this.crossingTiesTimberedFieldError = true;
      this.errorString = 'Make a selection.';
    } else {
      this.crossingTiesTimberedFieldError = false;
      this.errorString = '';
    }
    // this.passTieData();
  }

  /**
   *  Get Ties details - beginMilepost and validate
   * @param {number} index
   * @memberof TieProgressComponent
   */
  validateBeginMilePost(index: number) {
    this.tieDetailsInvalid = false;
    this.tieBeginMPError[index] = '';
    if (
      this.tiesDetails[index].beginMilepost === undefined ||
      this.tiesDetails[index].beginMilepost === null
    ) {
      this.tieBeginMPError[index] = 'Enter MP';
      this.tieBeginMPFieldError[index] = true;
    } else if (!this.twoDecimalPattern.test(this.tiesDetails[index].beginMilepost.toString())) {
      this.tieBeginMPFieldError[index] = true;
      this.tieBeginMPError[index] = '2 decimal places allowed';
    } else if (this.trackType === 'YD') {
      if (this.sidingObject) {
        this.sidingObject.forEach((values, index1) => {
          this.tiesDetails[index].beginMilepost = this.sidingObject[
            index
          ].beginMp;
        });
      }
      this.beginMPOutOfLimitError[index] = this.verifyMpWithinYard(
        this.tiesDetails[index].beginMilepost,
        this.trackName
      );
      if (this.beginMPOutOfLimitError[index]) {
        this.tieBeginMPError[index] = 'Milepost is outside of yard limits.';
        this.tieBeginMPFieldError[index] = true;
      }
    } else {
      this.isSiding = false;
      if (this.isYard === false && this.sidingObject) {
        this.isSiding = true;
      }
      this.beginMPOutOfLimitError[
        index
      ] = this.utilService.verifyMPOutSideJobLimit(
        this.jobBeginMp,
        this.jobEndMp,
        this.tiesDetails[index].beginMilepost,
        this.direction,
        this.isSiding
      );
      //   let tieTimbered = this.utilService.isMilePostWithInChainFeet(
      //     this.tiesDetails[index].beginMilepost,
      //     this.jobId,
      //     this.trackType,
      //     this.trackName
      //   );
      const isValidMilePost = this.utilService.isMilePostWithinMasterTrack(
        this.tiesDetails[index].beginMilepost,
        this.masterTrackList
      );

      if (!isValidMilePost) {
        this.tieBeginMPError[index] = 'Invalid Milepost.';
        this.tieBeginMPFieldError[index] = true;
      } else {
        if (this.beginMPOutOfLimitError[index]) {
          if (this.isSiding) {
            this.tieBeginMPError[index] = 'Outside of SD limits';
          } else {
            this.tieBeginMPError[index] = 'Milepost is outside of job limits.';
          }
          this.tieBeginMPFieldError[index] = true;
        } else {
          this.tieBeginMPFieldError[index] = false;
          this.tieBeginMPError[index] = '';
        }
      }

      console.log('milepost valid', isValidMilePost);
    }
    if (this.tiesDetails[index].tiePlateCount !== null) {
      this.validateTiePlateCount(index);
    }
    this.checkOverlap(index);
  }

  checkOverlap(index) {
    if (this.direction === 'D') {
      this.tiesDetails.forEach((values, i) => {
        if (
          this.tiesDetails[index].beginMilepost <= values.beginMilepost &&
          this.tiesDetails[index].beginMilepost > values.endMilepost &&
          values.beginMilepost != null &&
          values.endMilepost != null &&
          i !== index &&
          this.tiesDetails[index].beginMilepost
        ) {
          this.tieBeginMPError[index] = 'Mile post Overlap ';
          this.tieBeginMPFieldError[index] = true;
        }
        if (
          this.tiesDetails[index].endMilepost < values.beginMilepost &&
          this.tiesDetails[index].endMilepost > values.endMilepost &&
          values.beginMilepost != null &&
          values.endMilepost != null &&
          i !== index
        ) {
          this.tieEndMPFieldError[index] = true;
          this.tieEndMPError[index] = 'Mile post Overlap';
        }
      });
    } else {
      this.tiesDetails.forEach((values, i) => {
        if (
          this.tiesDetails[index].beginMilepost >= values.beginMilepost &&
          this.tiesDetails[index].beginMilepost < values.endMilepost &&
          values.beginMilepost != null &&
          values.endMilepost != null &&
          i !== index
        ) {
          this.tieBeginMPError[index] = 'Mile post Overlap ';
          this.tieBeginMPFieldError[index] = true;
        }
        if (
          this.tiesDetails[index].endMilepost > values.beginMilepost &&
          this.tiesDetails[index].endMilepost <= values.endMilepost &&
          values.beginMilepost != null &&
          values.endMilepost != null &&
          i !== index
        ) {
          this.tieEndMPFieldError[index] = true;
          this.tieEndMPError[index] = 'Mile post Overlap';
        }
      });
    }
    this.checkValidationOverlapMp();
  }

  checkValidationOverlapMp() {
    for (let i = 0; i < this.tieEndMPError.length; i++) {
      if (this.tieEndMPError[i] === 'Mile post Overlap') {
        for (let j = i + 1; j < this.tieEndMPFieldError.length; j++) {
          if (
            this.tiesDetails[i].beginMilepost ===
            this.tiesDetails[j].beginMilepost ||
            this.tiesDetails[i].endMilepost === this.tiesDetails[j].endMilepost
          ) {
            this.tieEndMPError[i] = '';
            this.tieEndMPFieldError[i] = false;
            this.tieEndMPError[j] = 'Mile post Overlap';
            this.tieEndMPFieldError[j] = true;
            this.tieBeginMPError[j] = 'Mile post Overlap ';
            this.tieBeginMPFieldError[j] = true;
            this.tieBeginMPError[i] = ' ';
            this.tieBeginMPFieldError[i] = false;
          }
        }
      }
    }
  }

  /**
   *  Get Ties details - endMilepost and validate
   * @param {number} index
   * @memberof TieProgressComponent
   */
  validateEndMilePost(index: number) {
    this.tieDetailsInvalid = false;
    if (
      this.tiesDetails[index].endMilepost === undefined ||
      this.tiesDetails[index].endMilepost === null
    ) {
      this.tieEndMPFieldError[index] = true;
      this.tieEndMPError[index] = 'Enter MP';
    } else if (!this.twoDecimalPattern.test(this.tiesDetails[index].endMilepost.toString())) {
      this.tieEndMPFieldError[index] = true;
      this.tieEndMPError[index] = '2 decimal places allowed';
    } else if (
      (this.direction === 'I' &&
        this.tiesDetails[index].beginMilepost >
        this.tiesDetails[index].endMilepost) ||
      (this.direction === 'D' &&
        this.tiesDetails[index].beginMilepost <
        this.tiesDetails[index].endMilepost)
    ) {
      this.tieEndMPError[index] = 'Invalid milepost based on work direction.';
      this.tieEndMPFieldError[index] = true;
    } else if (this.trackType === 'YD') {
      if (this.sidingObject) {
        this.sidingObject.forEach((values, index1) => {
          this.tiesDetails[index].endMilepost = this.sidingObject[index].endMP;
        });
      }
      this.endMPOutOfLimitError[index] = this.verifyMpWithinYard(
        this.tiesDetails[index].endMilepost,
        this.trackName
      );
      if (this.endMPOutOfLimitError[index]) {
        this.tieEndMPFieldError[index] = true;
        this.tieEndMPError[index] = 'Milepost is outside of yard limits.';
      }
    } else {
      this.isSiding = false;
      if (this.isYard === false && this.sidingObject) {
        this.isSiding = true;
      }
      this.endMPOutOfLimitError[
        index
      ] = this.utilService.verifyMPOutSideJobLimit(
        this.jobBeginMp,
        this.jobEndMp,
        this.tiesDetails[index].endMilepost,
        this.direction,
        this.isSiding
      );
      this.endMPNextWholeMpError[
        index
      ] = this.utilService.verifyMPNextWholeMpJobLimit(
        this.tiesDetails[index].beginMilepost,
        this.tiesDetails[index].endMilepost,
        this.direction
      );
      if (this.endMPNextWholeMpError[index]) {
        this.tieEndMPFieldError[index] = true;
        this.tieEndMPError[index] = 'End MP > next whole MP';
      } else {
        const isValidMilePost = this.utilService.isMilePostWithinMasterTrack(
          this.tiesDetails[index].endMilepost,
          this.masterTrackList
        );

        if (!isValidMilePost) {
          this.tieEndMPError[index] = 'Invalid Milepost.';
          this.tieEndMPFieldError[index] = true;
        } else {
          if (this.endMPOutOfLimitError[index]) {
            this.tieEndMPFieldError[index] = true;
            if (this.isSiding) {
              this.tieEndMPError[index] = 'Outside of SD limits';
            } else {
              this.tieEndMPError[index] = 'Milepost is outside of job limits.';
            }
          } else {
            this.tieEndMPFieldError[index] = false;
            this.tieEndMPError[index] = '';
          }
        }
      }
    }
    if (this.tiesDetails[index].tiePlateCount !== null) {
      this.validateTiePlateCount(index);
    }

    if (
      this.tiesDetails[index].beginMilepost &&
      this.tiesDetails[index].beginMilepost ===
      this.tiesDetails[index].endMilepost
    ) {
      this.tieEndMPFieldError[index] = true;
      if (this.direction === 'I') {
        this.tieEndMPError[index] = 'should be > beginMp';
      } else {
        this.tieEndMPError[index] = 'should be < beginMp';
      }
    }
    this.checkOverlap(index);
  }

  tiePlate(index) {
    this.validateTiePlateType(index);
    this.passTieData();
  }

  validateTiePlateType(index: number) {
    this.tieDetailsInvalid = false;
    if (
      this.tiesDetails[index].tiePlateType === undefined ||
      this.tiesDetails[index].tiePlateType === null
    ) {
      this.tiePlateTypesFieldError[index] = true;
      this.tiePlateTypesError[index] = 'Make a selection';
    } else {
      this.tiePlateTypesFieldError[index] = false;
      this.tiePlateTypesError[index] = '';
    }
  }

  tiePlateCount(index) {
    this.validateTiePlateCount(index);
    this.passTieData();
  }

  railType(index) {
    this.validateRailType(index);
    this.passTieData();
  }

  validateRailType(index) {
    this.tieDetailsInvalid = false;
    if (
      this.tiesDetails[index].railType === undefined ||
      this.tiesDetails[index].railType === null
    ) {
      this.railTypeFieldError[index] = true;
      this.railTypeError[index] = 'Make a selection';
    } else {
      this.railTypeFieldError[index] = false;
      this.railTypeError[index] = '';
    }
  }

  validateTiePlateCount(index: number) {
    this.tieDetailsInvalid = false;
    if (
      this.tiesDetails[index].tiePlateCount === undefined ||
      this.tiesDetails[index].tiePlateCount === null
    ) {
      this.tiePlateTypeCountFieldError[index] = true;
      this.tiePlateTypeCountError[index] = 'Enter Tie Count';
    } else {
      if (this.tiesDetails[index].tiePlateCount <= 0) {
        this.tiePlateTypeCountFieldError[index] = true;
        this.tiePlateTypeCountError[index] = 'Invalid Entry';
      } else if (
        this.tiesDetails[index].tiePlateCount.toString().includes('.')
      ) {
        this.tiePlateTypeCountFieldError[index] = true;
        this.tiePlateTypeCountError[index] = 'Enter Numeric value';
      } else {
        this.tiePlateTypeCountFieldError[index] = false;
        this.tiePlateTypeCountError[index] = '';
        if (this.trackType === 'YD') {
          if (this.sidingObject) {
            this.sidingObject.forEach((values, index1) => {
              if (
                this.sidingObject[index1].trackName ===
                this.tiesDetails[index].trackName
              ) {
                if (
                  this.tiesDetails[index].tiePlateCount.toString().length > 8
                ) {
                  this.tiePlateTypeCountFieldError[index] = true;
                  this.tiePlateTypeCountError[index] =
                    'Exceeds Max Ties  for entered footage';
                } else {
                  this.tiePlateTypeCountFieldError[index] = false;
                  this.tiePlateTypeCountError[index] = '';
                }
              }
            });
          }
        } else {
          this.tieCountError = this.isValidTiesPerMile(
            this.tiesDetails[index].tiePlateCount,
            this.tiesDetails[index].beginMilepost,
            this.tiesDetails[index].endMilepost
          );
          if (!this.tieCountError) {
            this.tiePlateTypeCountFieldError[index] = true;
            this.tiePlateTypeCountError[index] =
              'Exceeds Max Ties  for entered footage';
          } else {
            this.tiePlateTypeCountFieldError[index] = false;
            this.tiePlateTypeCountError[index] = '';
          }
        }
      }
    }
  }

  isValidTiesPerMile(
    tieCount: number,
    beginMP: number,
    endMP: number
  ): boolean {
    let validTiesPerMile = true;
    if (tieCount === null || tieCount === 0) {
      validTiesPerMile = false;
    } else {
      let maxTiesForSpan;
      if (this.direction === 'I') {
        maxTiesForSpan = (endMP - beginMP) * 3250;
      } else {
        maxTiesForSpan = (beginMP - endMP) * 3250;
      }
      if (tieCount > 0) {
        if (tieCount > maxTiesForSpan) {
          validTiesPerMile = false;
        }
      }
    }
    return validTiesPerMile;
  }

  validateAll(submit: boolean): boolean {
    this.validateCrossingTimberedOptions(this.crossingTiesTimbered);
    let errorCount = 0;
    for (let i = 0; i < this.tiesDetails.length; i++) {
      if (this.trackType === 'YD') {
        this.validateTieTrackName(i);
        this.validateTiePlateType(i);
        this.validateTiePlateCount(i);
        if (
          this.tiePlateTypesError[i] !== '' ||
          this.tiePlateTypeCountError[i] !== '' ||
          this.trackNameError[i] !== ''
        ) {
          errorCount++;
        }
      } else if (this.trackType === 'SD' && !this.sidingObject) {
        this.validateRailType(i);
        this.validateBeginMilePost(i);
        this.validateEndMilePost(i);
        this.validateTiePlateType(i);
        this.validateTiePlateCount(i);
        this.validateSidingTrackName(i);
        if (
          this.tieBeginMPError[i] !== '' ||
          this.tieEndMPError[i] !== '' ||
          this.tiePlateTypesError[i] !== '' ||
          this.tiePlateTypeCountError[i] !== '' ||
          this.sidingTrackNameError[i] !== '' ||
          this.railTypeError[i] !== ''
        ) {
          errorCount++;
        }
      } else {
        this.validateRailType(i);
        this.validateBeginMilePost(i);
        this.validateEndMilePost(i);
        this.validateTiePlateType(i);
        this.validateTiePlateCount(i);
        if (
          this.tieBeginMPError[i] !== '' ||
          this.tieEndMPError[i] !== '' ||
          this.tiePlateTypesError[i] !== '' ||
          this.tiePlateTypeCountError[i] !== '' ||
          this.railTypeError[i] !== ''
        ) {
          errorCount++;
        }
      }
    }
    if (submit && this.errorString !== '') {
      errorCount++;
    }
    if (errorCount > 0) {
      return false;
    } else {
      return true;
    }
  }

  getTrack() {
    if (this.trackType === 'YD') {
      this.isYard = true;
      this.addString = '+ Add another Tie - Yard Track Detail';
      if (this.sidingObject) {
        this.trackNameOptions.push({ label: 'Select', code: 'YARD TRACK' });
        for (let i = 0; i < this.sidingObject.length; i++) {
          this.trackNameOptions.push({
            label:
              this.sidingObject[i].trackName +
              ':' +
              this.sidingObject[i].beginMp +
              '-' +
              this.sidingObject[i].endMP,
            code: this.sidingObject[i].trackName
          });
        }
      }
    } else {
      this.isYard = false;
      if (this.sidingObject || this.trackType === 'SD') {
        this.addString = '+ Add another Tie - Siding Detail';
      } else {
        this.addString = '+ Add another Tie - Mainline Detail';
      }
    }
  }

  validateTieTrackName(index: number) {
    if (
      this.tiesDetails[index].trackName === undefined ||
      this.tiesDetails[index].trackName === null ||
      this.tiesDetails[index].trackName === 'MAINLINE TRACKS' ||
      this.tiesDetails[index].trackName === 'YARD TRACK'
    ) {
      this.trackNameError[index] = 'Make a selection.';
      this.trackNameFieldError[index] = true;
      this.tieDetailsInvalid = true;
    } else {
      this.trackNameError[index] = '';
      this.tieDetailsInvalid = false;
      if (this.trackType === 'YD') {
        if (this.sidingObject) {
          this.sidingObject.forEach((value, index1) => {
            if (
              this.sidingObject[index1].trackName ===
              this.tiesDetails[index].trackName
            ) {
              this.tiesDetails[index].beginMilepost = value.beginMp;
              this.tiesDetails[index].endMilepost = value.endMP;
            }
          });
        }
        if (
          this.compareSelectedTracknames(
            this.tiesDetails[index].trackName,
            index
          )
        ) {
          this.trackNameError[index] = 'Select a different track.';
          this.trackNameFieldError[index] = true;
        } else {
          this.trackNameError[index] = '';
          this.trackNameFieldError[index] = false;
        }
      }
    }
    this.checkValidationOverlapTieTrack();
  }

  checkValidationOverlapTieTrack() {
    for (let i = 0; i < this.trackNameFieldError.length; i++) {
      if (this.trackNameFieldError[i] === true) {
        for (let j = i + 1; j < this.trackNameFieldError.length; j++) {
          if (this.tiesDetails[i].trackName === this.tiesDetails[j].trackName) {
            this.trackNameError[i] = '';
            this.trackNameFieldError[i] = false;
            this.trackNameError[j] = 'Select a different track.';
            this.trackNameFieldError[j] = true;
          }
        }
      }
    }
  }

  compareSelectedTracknames(trackname: string, index: number): boolean {
    for (let i = 0; i < this.tiesDetails.length; i++) {
      if (i !== index && this.tiesDetails[i].trackName === trackname) {
        return true;
      }
    }
    return false;
  }

  sidingTrackName(index: number) {
    this.validateSidingTrackName(index);
    this.passTieData();
  }

  validateSidingTrackName(index: number) {
    console.log(this.tiesDetails[index].trackName);
    if (this.tiesDetails[index].trackName) {
      this.sidingTrackNameError[index] = '';
      this.sidingTrackNameFieldError[index] = false;
      // this.passTieData();
    } else {
      this.sidingTrackNameError[index] = 'Enter Track Name';
      this.sidingTrackNameFieldError[index] = true;
    }
  }

  verifyMpWithinYard(milepost: number, trackName: string): boolean {
    const str = trackName.split(',');
    const track = str[0];
    const siding = this.sidingObject.find(i => i.trackName === track);
    if (milepost >= siding.beginMp && milepost <= siding.endMP) {
      return false;
    } else {
      return true;
    }
  }

  passTieData() {
    this.tieData.emit(this.tiesDetails);
  }
}

/** End Class TieProgressComponent */
/**
 * @interface CrossingTiesTimbered
 */
interface CrossingTiesTimbered {
  label: string;
  code: boolean;
}

interface TrackName {
  label: string;
  code: string;
}

/**
 * @interface TiePlateTypes
 */
interface TiePlateTypes {
  label: string;
  code: string;
}

/**
 * @export TieProgressComponent
 * @class TiesDetails
 * @implements {TiePlateTypes}
 */
export class TiesDetails {
  beginMilepost: number;
  endMilepost: number;
  tiePlateType: string;
  tiePlateCount: number;
  trackName: string;
  trackType: string;
  crossingTiesTimbered: boolean;
  direction: string;
  workedOn: string;
  railType: string;

  constructor(
    beginMilepost: number,
    endMilepost: number,
    tiePlateType: string,
    tiePlateCount: number,
    trackName: string,
    trackType: string,
    crossingTiesTimbered: boolean,
    direction: string,
    workedOn: string,
    railType: string
  ) {
    this.beginMilepost = beginMilepost;
    this.endMilepost = endMilepost;
    this.tiePlateType = tiePlateType;
    this.tiePlateCount = tiePlateCount;
    this.trackName = trackName;
    this.trackType = trackType;
    this.crossingTiesTimbered = crossingTiesTimbered;
    this.direction = direction;
    this.workedOn = workedOn;
    this.railType = railType;
  }
}

export class Siding {
  trackId: number;
  trackName: string;
  beginMp: number;
  endMP: number;
  tieType: string;
  trackType: string;

  constructor(
    trackId: number,
    trackName: string,
    beginMp: number,
    endMP: number,
    tieType: string,
    trackType: string
  ) {
    this.trackId = trackId;
    this.trackName = trackName;
    this.beginMp = beginMp;
    this.endMP = endMP;
    this.tieType = tieType;
    this.trackType = trackType;
  }
}
