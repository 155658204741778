import { GenerateTrackDisturbanceComponent } from './admin/generate-track-disturbance/generate-track-disturbance.component';
import { RouterModule, Routes } from '@angular/router';

import { AdapterComponent } from './adapter/adapter.component';
import { GaugingInfoComponent } from './gauging-info/gauging-info.component';
import { JobContainerComponent } from './job_view/job-container/job-container.component';
import { LoginTeamComponent } from './login-team/login-team.component';
import { NoProductionReportComponent } from './no-production-report/no-production-report.component';
import { NoProductionRestdayComponent } from './no-production-restday/no-production-restday.component';
import { PlannedActual2Component } from './planned-actual2/planned-actual2.component';
import { ProjectContainerComponent } from './project_view/project-container/project-container.component';
import { RailInfoComponent } from './rail-info/rail-info.component';
import { ReferenceComponent } from './reference/reference.component';
import { RoadCrossingComponent } from './road-crossing-view/road-crossing/road-crossing.component';
import { RoadCrossingListComponent } from './road-crossing-view/road-crossing-list/road-crossing-list.component';
import { SurfacingInfoComponent } from './surfacing-info/surfacing-info.component';
import { TrackUtilWindowsComponent } from './track-util-windows/track-util-windows.component';
import { TrackUtilizationComponent } from './track-utilization/track-utilization.component';
import { TsInfoComponent } from './tie-surface/ts-info/ts-info.component';
import { TurnoutContainerComponent } from './turnout/turnout-container/turnout-container.component';
import { HomeComponent } from './home/home.component';
import { SwitchTieReportComponent } from './switch-tie-report/switch-tie-report.component';
import { RailReportEditComponent } from './rail-info/rail-report-edit/rail-report-edit.component';
import { PlannedActualEditComponent } from './planned-actual2/planned-actual-edit/planned-actual-edit.component';
import { TrackUtilizationEditComponent } from './track-utilization/track-utilization-edit/track-utilization-edit.component';
import { CanDeactivateGaurdService } from './shared/services/can-deactivate-gaurd.service';
import { RoadCrossingEditComponent } from './road-crossing-view/road-crossing/road-crossing-edit/road-crossing-edit.component';
import { SurfacingReportEditComponent } from './surfacing-info/surfacing-report-edit/surfacing-report-edit-component';
import { TurnoutContainerEditComponent } from './turnout/turnout-container/turnout-container-edit/turnout-container-edit.component';
import { GaugingInfoEditComponent } from './gauging-info/gauging-info-edit/gauging-info-edit.component';
import { NoProductionRestdayEditComponent } from './no-production-restday/no-production-restday-edit/no-production-restday-edit.component';
import { NoProductionReportEditComponent } from './no-production-report/no-production-report-edit/no-production-report-edit.component';
import { SwitchTieReportEditComponent } from './switch-tie-report/switch-tie-report-edit/switch-tie-report-edit-component';
import { TsInfoEditComponent } from './tie-surface/ts-info/ts-info-edit/ts-info-edit.component';

// Main route has to be called 'project_view' for now.
// See example below
const appRoutes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    component: HomeComponent
  },
  { path: 'info', component: HomeComponent },
  { path: 'adapter', component: AdapterComponent },
  { path: 'main', component: LoginTeamComponent },
  { path: 'main/:changeTeam', component: LoginTeamComponent },
  { path: 'project_view', component: ProjectContainerComponent },
  { path: 'job_view/:project', component: JobContainerComponent },
  { path: 'rail_info/:project/:job/:form', component: RailInfoComponent },
  { path: 'switch_tie/:project/:job/:form', component: SwitchTieReportComponent },
  { path: 'switch_tie/edit/:project/:job/:form', component: SwitchTieReportEditComponent, canDeactivate: [CanDeactivateGaurdService] },
  {
    path: 'track_util_window/:project/:job',
    component: TrackUtilWindowsComponent
  },
  {
    path: 'track_utilization/:project/:job/:form',
    component: TrackUtilizationComponent
  },
  { path: 'reference_code', component: ReferenceComponent },
  {
    path: 'road_crossing/:project/:job/:form',
    component: RoadCrossingComponent
  },
  { path: 'road_crossing_list', component: RoadCrossingListComponent },
  {
    path: 'no_production_report/:project/:job/:form',
    component: NoProductionReportComponent
  },
  {
    path: 'no_production_report/edit/:project/:job/:form',
    component: NoProductionReportEditComponent,
    canDeactivate: [CanDeactivateGaurdService]
  },
  { path: 'gauging_info/:project/:job/:form', component: GaugingInfoComponent },
  { path: 'ts_info/:project/:job/:form', component: TsInfoComponent },
  {
    path: 'surfacing_info/:project/:job/:form',
    component: SurfacingInfoComponent
  },
  {
    path: 'planned_actual/:project/:job/:form',
    component: PlannedActual2Component
  },
  { path: 'login-team', component: LoginTeamComponent },
  {
    path: 'app-no-production-restday/:project/:job/:form',
    component: NoProductionRestdayComponent
  },
  {
    path: 'app-no-production-restday/edit/:project/:job/:form',
    component: NoProductionRestdayEditComponent,
    canDeactivate: [CanDeactivateGaurdService]
  },
  { path: 'turnout/:project/:job/:form', component: TurnoutContainerComponent },
  { path: 'turnout/edit/:project/:job/:form', component: TurnoutContainerEditComponent, canDeactivate: [CanDeactivateGaurdService] },
  { path: 'admin/td/create', component: GenerateTrackDisturbanceComponent },

  { path: 'rail_info/edit/:project/:job/:form', component: RailReportEditComponent, canDeactivate: [CanDeactivateGaurdService] },
  { path: 'surfacing_info/edit/:project/:job/:form', component: SurfacingReportEditComponent, canDeactivate: [CanDeactivateGaurdService] },
  { path: 'planned_actual/edit/:project/:job/:form', component: PlannedActualEditComponent, canDeactivate: [CanDeactivateGaurdService] },
  { path: 'road_crossing/edit/:project/:job/:form', component: RoadCrossingEditComponent, canDeactivate: [CanDeactivateGaurdService] },
  {
    path: 'track_utilization/edit/:project/:job/:form',

    component: TrackUtilizationEditComponent,
    canDeactivate: [CanDeactivateGaurdService]
  },
  {
    path: 'gauging_info/edit/:project/:job/:form',
    component: GaugingInfoEditComponent, canDeactivate: [CanDeactivateGaurdService]

  },
  {
    path: 'ts_info/edit/:project/:job/:form',
    component: TsInfoEditComponent, canDeactivate: [CanDeactivateGaurdService]

  }
];

export const Routing = RouterModule.forRoot(appRoutes);
