import {Component, OnInit, Input} from '@angular/core';
import {NgZone, ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs/Observable';

import {AdapterService} from '../services/adapter.service';
import {RestURLBuilder} from 'rest-url-builder';
import {HttpMethod} from '../services/http-method.enum';
import {DataService} from '../services/data.service';


@Component({
    selector: 'app-adapter',
    templateUrl: './adapter.component.html',
    styleUrls: ['./adapter.component.css']
})


export class AdapterComponent implements OnInit {


    test: string;

    private userRestUrl = '/userSTW?mac=:mac&racf=:racf';
    a: number;

    feed: Observable<any[]>;
    @Input() index;

    constructor(public router: Router, private zone: NgZone,
                private changeDetector: ChangeDetectorRef, private sw: AdapterService, private ds: DataService) {
        this.a = 5;

    }

    ngOnInit() {
    }

    navBack() {
        history.back();
    }

    createUserURL(): string {
        let urlBuilder = new RestURLBuilder();
        urlBuilder.buildRestURL(this.userRestUrl);
        urlBuilder.setQueryParameter('mac', '879087sdf');
        urlBuilder.setQueryParameter('racf', 'S4690');
        return urlBuilder.get();
    }

    getSTWUser() {
        console.log('I am here');
        /*this.sw.executeService(this.createUserURL(), "GET", '{}')
          .then(response => { this.test = JSON.stringify(response); });
        console.log("I am here");*/
 


        console.log('I am here');


    }


}
