import {
  Component,
  NgZone,
  ChangeDetectorRef,
  ElementRef,
  OnInit,
  HostListener
} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/from';
import { CsxheaderComponent } from '../shared/csxheader/csxheader.component';
import { DataService } from '../services/data.service';
import { Configuration } from '../services/dataservice.constants';
import {
  LocalStorageService,
  ReportTable
} from '../services/local-storage.service';
import { EventBusService, EventType } from '../services/eventbus.service';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { FormStatus } from '../sync/sync.component';
import { ConfirmationService } from 'primeng/primeng';
import { AuthService } from 'csx-flex-ng';

@Component({
  selector: 'app-stw-header',
  templateUrl: './stw-headers.component.html',
  providers: [ConfirmationService],
  styleUrls: ['./stw-headers.component.scss']
})
export class StwHeadersComponent extends CsxheaderComponent
  implements OnInit, OnDestroy {
  showMenu: boolean;
  loginName: string;
  teamName: String;
  userData: any = {};
  private proxySub: Subscription;
  private teamSub: Subscription;
  syncComponentFlag = false;
  showSyncComponent: boolean;
  // Report Information
  unsyncReports = [];
  draftReports = [];
  confirmationMsg = '';
  showConfirmation = false;
  showErrorComponent: boolean;
  disableClearCacheButton: boolean;

  constructor(
    authService: AuthService,
    public router: Router,
    public zone: NgZone,
    public changeDetectors: ChangeDetectorRef,
    private locations: Location,
    public eventBus: EventBusService,
    public elements: ElementRef,
    private ls: LocalStorageService,
    public ds: DataService,
    private conf: Configuration,
    private confirmationService: ConfirmationService,
    private logOutconfirmationService: ConfirmationService
  ) {
    super(authService, router, zone, changeDetectors, locations, elements);
  }

  ngOnInit() {
    if (this.Flex.user.id === undefined || this.Flex.user.id === '') {
      this.Flex.user.id = 'S4690';
      this.Flex.user.firstName = 'Dale';
      this.Flex.user.lastName = 'Richer';
    }
    this.appLongName = 'System Track Works';
    this.appShortName = 'STW';
    this.needsSignal = true;
    setTimeout(() => {
      this.loginName = this.userName.slice(0, -8);
    }, 1);
    this.proxySub = this.eventBus
      .getEventBus(EventType.PROXY_CHANGE_EVENT)
      .subscribe(response => {
        // this.getUserInfo();
      });
    this.ls.getCurrentUser('SYSTEM').then(c => {
      this.teamName = c.teamName;
    });
    this.teamSub = this.eventBus
      .getEventBus(EventType.PROJECTS_UPDATED_EVENT)
      .subscribe(response => {
        if (this.ds.team) {
          this.teamName = this.ds.team;
        }
      });
    if (this.router.url.includes('login-team') || this.router.url.includes('main')) {
      this.disableClearCacheButton = true;
    }
  }

  ngOnDestroy(): void {
    if (this.proxySub) {
      this.proxySub.unsubscribe();
    }
    if (this.teamSub) {
      this.teamSub.unsubscribe();
    }
  }


  public loadData(): void {
    this.conf.loadOfflineDataForDevelopers();
  }

  public showClearCacheDialog(): void {
    this.showMenu = false;
    if (window.navigator.onLine) {
      this.confirmationService.confirm({
        key: 'clearCacheConfirmation',
        message:
          '"Clear Cache" will delete all un-synced reports. Are you sure you want to "Clear Cache"?',
        accept: () => {
          this.clearTeamData().then(() => {
            if (window.navigator.onLine) {
              this.onSyncDialogOpen();
            } else {
              this.showErrorComponent = true;
            }
          });
        }
      });
    } else {
      this.showErrorComponent = true;
    }
  }

  public onSyncComplete(isCompleted: boolean): void {
    if (isCompleted) {
      this.showSyncComponent = false;
      this.router.navigate([`/project_view`]);
    }
  }

  public toggleMenu(action): void {
    if (action === 'close') {
      this.showMenu = false;
    } else {
      this.showMenu = !this.showMenu;
    }
  }

  async updateUnsynceReports() {
    this.ls
      .getReportsForSync()
      .then(async (reportList: Array<ReportTable>) => {
        console.log(reportList);
        this.unsyncReports = [];
        this.draftReports = [];
        if (reportList) {
          for (const p of reportList) {
            if (p.reportObj['workTypeCode'] === 'RA') {
              p.reportObj['workTypeDesc'] = 'Rail';
            }
            const s = JSON.parse(JSON.stringify(p.reportObj)).status;
            if (s !== null && s === FormStatus.SUBMITTED) {
              this.unsyncReports.push(p.reportObj);
            }
            if (s === FormStatus.DRAFT) {
              this.draftReports.push(Number(p.formId));
            }
          }
        }
        await this.confirm();
      })
      .catch(err => {
        console.error(err);
      });
  }

  async getStatus(p: ReportTable) {
    return await JSON.parse(JSON.stringify(p.reportObj)).status;
  }

  isAdmin() {
    return this.ds.stwUserRole === 'Admin';
  }

  navigateToTDCreate() {
    this.router.navigate(['admin/td/create']);
  }

  logout(event: any): boolean {
    // event.stopPropagation();
    console.log('navigator.onLine', navigator.onLine);
    if (navigator.onLine) {
      this.updateUnsynceReports();
      return false;
    } else {
      return true;
    }
  }

  confirm() {
    const me = this;

    this.showConfirmation = true;
    if (this.unsyncReports.length > 0 && this.draftReports.length > 0) {
      this.confirmationMsg =
        'You have UnSynced Reports and UnSubmitted Drafts .<br>' +
        '<center>UnSubmitted Drafts will be deleted upon Sync</center> ' +
        '<center> Do you still want to proceed with sync?</center>';
    } else if (this.unsyncReports.length > 0) {
      this.confirmationMsg =
        'You have UnSynced Reports.<br>' +
        '<p><i class="fa fa-fw"></i>Please Sync before you logout.</p>';
    } else if (this.draftReports.length > 0) {
      this.confirmationMsg =
        'UnSubmitted Draft Reports will be deleted upon Sync.<br></br> ' +
        '<center> Do you still want to proceed with sync?</center>';
    } else {
      this.showConfirmation = false;
    }

    if (this.showConfirmation) {
      this.logOutconfirmationService.confirm({
        key: 'logoutConfirmation',
        message: this.confirmationMsg,
        accept: () => {
          if (navigator.onLine) {
          } else {
          }
        },
        reject: () => {
          // this.onClose();
        }
      });
    } else {
      this.pvtLogout();
    }
  }

  pvtLogout() {
    // window.location.href = "#";
    this.logOutClick();
  }

  onSyncDialogOpen() {
    this.showSyncComponent = true;
  }

  onSyncDialogClose() {
    this.showSyncComponent = false;
  }

  onCnfmDialogClose(event) {
    this.showConfirmation = event;
  }

  onDialogClose(event) {
    this.showErrorComponent = event;
  }

  private async clearTeamData() {
    await this.ls.clearAllData();
  }
}
