import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelMenuModule, OverlayPanelModule } from 'primeng/primeng';
import { CsxheaderComponent } from './csxheader.component';

@NgModule({
    imports: [
        CommonModule,
        PanelMenuModule,
        OverlayPanelModule
    ],
    declarations: [
        CsxheaderComponent
    ],
    exports: [
        CsxheaderComponent
    ]
})

export class CsxHeaderModule { }