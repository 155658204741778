import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EventBusService, EventType, STWEventData } from '../../services/eventbus.service';
import { TurnoutFormService } from './turnout-form.service';
import { TurnoutForm } from '../turnout-card/model/turnout-form.model';
import { Turnout } from '../../model/turnout.model';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService, ReportTable, TurnoutTable } from '../../services/local-storage.service';
import { OperationMode } from '../../shared/enums/operation-mode.enum';

@Component({
  selector: 'app-turnout-container',
  templateUrl: './turnout-container.component.html',
  styleUrls: ['./turnout-container.component.scss'],
  providers: [TurnoutFormService]
})
export class TurnoutContainerComponent implements OnInit, OnDestroy {
  formInvalid = false;
  turnoutContainerForm: FormGroup;
  turnoutFormSub: Subscription;
  operationMode: OperationMode = OperationMode.CREATE;
  turnouts: FormArray;
  formSubmitAttempt = false;
  report: ReportTable;

  private readonly TRACK_UTIL_PAGE = 'track_utilization';
  private readonly JOB_VIEW = 'job_view';
  private readonly ROAD_CROSSING_PAGE = 'road_crossing';
  private readonly PLANNED_ACTUAL_PAGE = 'planned_actual';
  constructor(
    protected eventBus: EventBusService,
    protected turnoutListFormService: TurnoutFormService,
    protected activeRoute: ActivatedRoute,
    protected ls: LocalStorageService,
    protected router: Router
  ) {}

  ngOnInit() {
    this.setReportObject();

    this.turnoutFormSub = this.turnoutListFormService.turnoutsContainerForm$.subscribe(turnoutContainerForm => {
      this.turnouts = turnoutContainerForm.get('turnoutForms') as FormArray;
      this.turnoutContainerForm = turnoutContainerForm;
    });
    this.eventBus.getEventBus(EventType.TURNOUT_DIALOG_APPLY_EVENT).subscribe((event: STWEventData) => {
      console.log('in apply', event.data);
      if (event.data && event.data.length > 0) {
        event.data.forEach(async to => {
          console.log('Turnout ::', to);
          console.log('Before Add Form Array ::', this.turnouts.value);
          const c: TurnoutForm[] = this.turnouts.value;
          let i = c.findIndex(e => e.turnoutId === to.turnoutId);
          console.log('find index ', i);
          if (i < 0) {
            await this.turnoutListFormService.addTurnout(to, Number(this.report.projectId));
          }
          console.log('After Add Form Array ::', this.turnouts.value);
        });
      }
    });
  }

  private setReportObject() {
    this.activeRoute.params.subscribe((params: any) => {
      let formId = Number(params.form);
      this.getReportMethod(formId).then((report: ReportTable) => {
        this.report = report;
        let reportObject = JSON.parse(JSON.stringify(this.report.reportObj));
        let status: string = reportObject.status;
        this.checkReportOperationMode();
        if (this.viewMode || this.editMode) {
          let turnoutList: Turnout[] = reportObject.reportTurnoutList;

          if (turnoutList) {
            turnoutList.forEach(t => {
              this.turnoutListFormService.addTurnout(t, Number(this.report.projectId), this.viewMode || this.editMode);
            });

            console.log('set report', turnoutList);
          }
        } else {
          let turnoutList: Turnout[] = reportObject.reportTurnoutList;
          if (turnoutList) {
            turnoutList.forEach(t => {
              this.turnoutListFormService.addTurnout(t, Number(this.report.projectId));
            });
            console.log('set report', turnoutList);
          }
        }
      });
    });
  }

  ngOnDestroy() {
    this.turnoutFormSub.unsubscribe();
  }

  openDialog() {
    let turnoutData: Turnout[] = [];
    this.turnouts.value.forEach(t => {
      let t1: Turnout = new Turnout();
      t1.turnoutId = t.turnoutId;
      t1.prefix = t.prefix;
      t1.milepost = t.milepost;
      t1.trackType = t.trackType;
      t1.trackName = t.trackName;
      t1.direction = t.direction;
      t1.railWeight = t.railWeight;
      t1.f_t = t.f_t;
      t1.isSelected = t.isSelected;
      turnoutData.push(t1);
    });
    console.log('openDialog sending::', turnoutData);
    this.formSubmitAttempt = false;
    const data = new STWEventData(turnoutData);
    this.eventBus.fireEvent(EventType.TURNOUT_DIALOG_OPEN_EVENT, data);
  }

  removeTurnout(turnId: number) {
    console.log('turnid', turnId);
    this.turnoutListFormService.deleteTurnoutById(turnId);
  }

  submitData(event) {
    if (this.viewMode) {
      this.navigate(this.TRACK_UTIL_PAGE);
    } else {
      if (this.formSubmitAttempt) {
        this.formSubmitAttempt = false;
      }
      this.formSubmitAttempt = true;
      if (this.turnoutContainerForm.valid) {
        this.saveAndNavigate(true, this.TRACK_UTIL_PAGE);
        console.log('form submitted');
      } else {
        // this.validateAllFormFields(this.turnoutContainerForm);
      }
    }
  }

  protected saveAndNavigate(save: boolean, page: string) {
    let reportObj = JSON.parse(JSON.stringify(this.report.reportObj));
    let isDraft: boolean = reportObj.status === 'D';
    reportObj['reportTurnoutList'] = this.turnouts.value;
    reportObj['submittedReportDate'] = new Date().getTime();
    this.report.reportObj = reportObj;
    console.log('form submitted', this.report);
    if (save && isDraft) {
      this.ls.addReport(this.report).then(() => {
        this.router.navigate([`/${page}/${this.report.projectId}/${this.report.jobId}/${this.report.formId}`]);
      });
    } else {
      this.router.navigate([`/${page}/${this.report.projectId}/${this.report.jobId}/${this.report.formId}`]);
    }
  }

  private saveAndNavigateJobView(save: boolean, page: string) {
    let reportObj = JSON.parse(JSON.stringify(this.report.reportObj));
    let isDraft: boolean = reportObj.status === 'D';
    reportObj['reportTurnoutList'] = this.turnouts.value;
    reportObj['submittedReportDate'] = new Date().getTime();
    this.report.reportObj = reportObj;
    console.log('form submitted', this.report);
    if (save && isDraft) {
      this.ls.addReport(this.report).then(() => {
        this.router.navigate([`/${page}/${this.report.projectId}`]);
      });
    } else {
      this.router.navigate([`/${page}/${this.report.projectId}`]);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field); // {3}
      if (control instanceof FormControl) {
        console.log(field, control);
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control); // {6}
      } else if (control instanceof FormArray) {
        control.controls.forEach(element => {
          if (element instanceof FormGroup) {
            this.validateAllFormFields(element);
          }
        });
      }
    });
  }

  backNavigate(event) {
    let reportData = JSON.parse(JSON.stringify(this.report.reportObj));
    let workType = reportData.workTypeCode;
    console.log('workType', workType);
    if (workType === 'TS') {
      let tieProgress = Object.keys(reportData.tsProgress.tieProgress).map(i => reportData.tsProgress.tieProgress[i]);
      let surfaceProgress = Object.keys(reportData.tsProgress.surfaceProgress).map(i => reportData.tsProgress.surfaceProgress[i]);
      let count = 0;
      for (let i = 0; i < tieProgress.length; i++) {
        if (tieProgress[i]['crossingTiesTimbered'] === true) {
          count++;
          console.log('count', count);
        }
      }
      for (let i = 0; i < surfaceProgress.length; i++) {
        if (surfaceProgress[i]['crossingSurfaced'] === true) {
          count++;
          console.log('count', count);
        }
      }
      if (count > 0) {
        this.saveAndNavigate(!this.viewMode, this.ROAD_CROSSING_PAGE);
      } else {
        this.saveAndNavigate(!this.viewMode, this.PLANNED_ACTUAL_PAGE);
      }
    } else {
      this.saveAndNavigate(!this.viewMode, this.ROAD_CROSSING_PAGE);
    }
  }

  navigate(page) {
    this.router.navigate([`/${page}/${this.report.projectId}/${this.report.jobId}/${this.report.formId}`]);
  }

  backIcon(event) {
    this.saveAndNavigateJobView(true, this.JOB_VIEW);
  }

  get viewMode() {
    return this.operationMode === OperationMode.VIEW;
  }

  get editMode() {
    return this.operationMode === OperationMode.EDIT;
  }

  protected getReportMethod(formId: any) {
    return this.ls.getReport(Number(formId));
  }

  protected getqueryParams() {
    return {};
  }

  protected checkReportOperationMode() {
    if (this.report.reportObj.status === 'S' || this.report.reportObj.formId > 0) {
      this.operationMode = OperationMode.VIEW;
    } else if (this.report.reportObj.status === 'D') {
      this.operationMode = OperationMode.CREATE;
    }
  }
}
