import { ProjectRowComponent } from '../project_view/project-row/project-row.component';
import { DataService } from '../services/data.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserTable, LocalStorageService } from '../services/local-storage.service';
import { EventBusService, EventType, STWEventData } from '../services/eventbus.service';
import { FlexObject } from 'csx-flex-ng';

@Component({
    selector: 'proxy-user',
    templateUrl: './proxyuser.component.html',
    styleUrls: ['./proxyuser.component.scss'],
    providers: [ProjectRowComponent]
})
export class ProxyUserComponent implements OnInit {
    userId: string;
    team: string;
    updateVisible: boolean;
    userObj: string;
    status: string;
    proxyExists: boolean;
    Flex: FlexObject;

    ngOnInit(): void {
        this.proxyExists = this.dataService.proxyFlag;
    }
    constructor(public dataService: DataService, public router: Router, private prc: ProjectRowComponent,
        public eventBus: EventBusService, private ls: LocalStorageService) {
        this.proxyExists = this.dataService.proxyFlag;
        this.Flex = FlexObject.getInstance();
    }

    async updateUser(userId: string, team: string) {
        try {
            await this.dataService.getUser(userId).then((user: UserTable) => {
                this.Flex.user.id = userId;
                this.dataService.racf = userId;
                this.dataService.team = team;
                this.dataService.proxyFlag = true;
                this.updateVisible = false;
                this.status = null;
                this.proxyExists = true;
                this.eventBus.fireEvent(EventType.PROXY_CHANGE_EVENT, new STWEventData('data', () => ''));
            }).catch(
                (e) => {
                    console.error(e);
                    this.status = 'Failed to update proxy.';
                    throw e;
                });
        } catch (error) {
            console.error(error);
            this.updateVisible = true;
            this.status = 'Failed to update proxy.';
            this.proxyExists = false;
            if (this.router.url.indexOf('main') > 0) {
                this.eventBus.fireEvent(EventType.PROJECTS_UPDATED_EVENT, new STWEventData('data', () => ''));
            } else {
                this.router.navigate(['/main']);
            }
        } finally {

            if (this.router.url.indexOf('main') > 0) {
                this.eventBus.fireEvent(EventType.PROJECTS_UPDATED_EVENT, new STWEventData('data', () => ''));
            } else {
                this.router.navigate(['/main']);
            }
        }
    }

    async deleteCache() {
        await this.ls.clearAllData();
        this.eventBus.fireEvent(EventType.PROJECTS_UPDATED_EVENT, new STWEventData('data', () => ''));
    }
}
