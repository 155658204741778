import { WorkType } from './../enums/work-type.enum';
import * as moment from 'moment';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JobTable, LocalStorageService, ProjectTable, ReportTable, CurrentUserTable } from '../services/local-storage.service';

import { DataService } from '../services/data.service';
import { UtilService } from '../util/util.service';
import { Report } from '../model/report.user';
import { ReportDataService } from '../shared/services/report-data.service';
import { OperationMode } from '../shared/enums/operation-mode.enum';

const JOB_SUBMITTED = 'S';
const JOB_TYPE_TS = 'TS';
const JOB_TYPE_SO = 'SO';
const JOB_TYPE_CP = 'CP';
const JOB_TYPE_GA = 'GA';
const JOB_TYPE_PR = 'PR';
const JOB_TYPE_OF = 'OF';
@Component({
  selector: 'app-planned-actual2',
  templateUrl: './planned-actual2.component.html',
  styleUrls: ['./planned-actual2.component.scss']
})
export class PlannedActual2Component implements OnInit, OnDestroy {
  operationMode: OperationMode = OperationMode.CREATE;
  public formGroup: FormGroup;
  public formSubmitAttempt = false;
  public reportData: any = [];
  public rowData: any;
  public projectData: any;
  public pageNav: string;
  public projectId: number;
  public jobId: number;
  public formId: number;
  public reportobject: any;
  public projectObject: any = [];
  public jobObject: any = [];
  public unitsInstalled: number;
  public workDay1: string;
  public reportstatus = false;
  public showInvalidEntry = false;
  public isValidationError = false;
  public showInvalidSawCutsTimeError: string;
  public showInvalidfirstSpikedPulledError: string;
  public showInvalidfirstWeldTimeError: string;
  public showInvalidSpikeDrivenTimeError: string;
  public showInvalidLastSpikeTimeError: string;
  public uniquesDates = 0;
  teamName: string;
  submitReportTotalProjectUnits = 0;
  jobType: any;
  tsFlag: boolean;
  soFlag: boolean;
  remainingToString: string;
  totalUnitsErrorString: string;
  totalDaysErrorString: string;
  totalCrossingErrorString: string;
  totalUnitsFieldError: boolean;
  totalDaysFieldError: boolean;
  totalCrossingFieldError: boolean;
  minDate: string;
  maxDate: string;
  unitRemainWarningMessage: string;
  daysToCompleteWarningMessage: string;
  public addWorkDayandReportCode: any = [];
  projectReports = [];

  /**
   * Creates an instance of PlannedActualComponent.
   * @param {FormBuilder} fb
   * @param {Router} router
   * @param {ActivatedRoute} activeRoute
   * @param {DataService} dataService
   * @param {LocalStorageService} localStorage
   * @memberof PlannedActualComponent
   */
  constructor(
    protected fb: FormBuilder,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected dataService: DataService,
    protected localStorage: LocalStorageService,
    public utilService: UtilService,
    protected reportDataService: ReportDataService
  ) {
  }

  /** ng OnInit * */
  ngOnInit() {
    this.localStorage.getCurrentUser('SYSTEM').then((c: CurrentUserTable) => {
      this.teamName = c.teamName;
      this.form();
      this.loadParam();
    });
  }

  get viewMode() {
    return this.operationMode === OperationMode.VIEW;
  }

  get createMode() {
    return this.operationMode === OperationMode.CREATE;
  }

  async setDefaultValues() {
    if (this.workDay1 === null || this.workDay1 === undefined) {
      await this.getReportMethod().then((res: ReportTable) => {
        this.reportData = res.reportObj;
        this.workDay1 = this.utilService.convertTimeStampTOdate(this.reportData.workday);
        this.minDate = this.workDay1;
        this.maxDate = this.getMaxTime();
      });
    } else {
      this.minDate = this.workDay1;
      this.maxDate = this.getMaxTime();
    }
  }

  getTimeDifference(m1: any, m2: any): number {
    const a1 = moment(m1).toDate();
    const b1 = moment(m2).toDate();
    let min = b1.getTime() - a1.getTime();
    if (min === 0) {
      min = -1;
    }
    return min;
  }

  public getMaxTime() {
    const currentD = new Date();
    const maxD = new Date(this.workDay1);
    maxD.setHours(maxD.getHours() + 18);
    let returnDate;
    if (currentD < maxD) {
      returnDate = this.utilService.convertTimeStampTOdate(currentD.getTime());
    } else {
      returnDate = this.utilService.convertTimeStampTOdate(maxD.getTime());
    }
    return returnDate;
  }

  form(): void {
    console.log('Planned vs Actual form called');
    if (this.reportData === null) {
      this.reportData = {
        totalPlannedProjectUnits: '',
        totalPlannedProjectDays: '',
        totalActualProjectUnits: '',
        plannedCrossingCount: 0,
        unitsRemaining: '',
        daysToComplete: '',
        firstSpikedPulled: '',
        sawCutsTime: '',
        firstSpikeDrivenTime: '',
        firstWeldTime: '',
        lastSpikeDriven: '',
        pickupRemainCount: ''
      };
    }
    this.formGroup = this.fb.group({
      formId: this.formId,
      jobId: this.jobId,
      projectId: this.projectId,
      totalPlannedProjectUnits: [this.reportData.totalPlannedProjectUnits, Validators.required],
      totalPlannedProjectDays: [this.reportData.totalPlannedProjectDays, Validators.required],
      totalActualProjectUnits: [this.reportData.totalActualProjectUnits],
      plannedCrossingCount: [this.reportData.plannedCrossingCount, Validators.required],
      unitsRemaining: [this.reportData.unitsRemaining],
      daysToComplete: [this.reportData.daysToComplete],
      firstSpikedPulled: [this.reportData.firstSpikedPulled, Validators.required],
      sawCutsTime: [this.reportData.sawCutsTime, Validators.required],
      firstSpikeDrivenTime: [this.reportData.firstSpikeDrivenTime, Validators.required],
      firstWeldTime: [this.reportData.firstWeldTime, Validators.required],
      lastSpikeDriven: [this.reportData.lastSpikeDriven, Validators.required],
      pickupRemainCount: [this.reportData.pickupRemainCount, [Validators.pattern('^[0-9][0-9]*$')]]
    });

    if (this.viewMode) {
      this.formGroup.clearValidators();
    }
  }

  /**
   *
   * @memberof PlannedActualComponent
   */
  loadParam(): void {
    this.activeRoute.params.subscribe(params => {
      this.projectData = params.project;
      if (this.projectData) {
        this.projectId = Number(this.projectData.split('-')[0]);
      }
      this.jobId = params.job;
      this.formId = params.form;
      this.getReportsData();
    });
  }

  getReportsData(): void {
    this.localStorage.getProjectReports(Number(this.projectId)).then((reportList: Array<ReportTable>) => {
      for (const p of reportList) {
        const js: any = JSON.parse(JSON.stringify(p.reportObj));
        this.projectReports.push(js);
      }
      this.getReports();
    });
  }

  /**
   * @memberof PlannedActualComponent
   */
  getJobReports(): void {
    this.localStorage.getJobReports(Number(this.jobId)).then((reportList: Array<ReportTable>) => {
      for (const p of reportList) {
        const js: any = JSON.parse(JSON.stringify(p.reportObj));
        if (js.status === JOB_SUBMITTED) {
          this.reportstatus = true;
        }
      }
    });
  }

  private setDataFromLatestReport() {
    if (this.reportData.pickupRemainCount) {
      this.formGroup.controls['pickupRemainCount'].patchValue(this.reportData.pickupRemainCount);
    }
  }
  /**
   * Retrive the reports data's from index DB.
   * @memberof PlannedActualComponent
   */
  getReports(): void {
    this.getReportMethod().then((res: ReportTable) => {
      this.reportData = res.reportObj;
      this.jobType = this.reportData.jobType;
      this.setOperationMode();
      if (this.createMode) {
        this.addWorkDayandReportCode.push({
          workday: this.reportData.workday,
          noProdRest: this.reportData.noProductionCode
        });
        this.localStorage.getCurrentUser('SYSTEM').then(data => {
          this.teamName = data ? data.teamName : null;
          this.localStorage.getNotes(this.teamName).then((notesData: any) => {
            if (notesData && notesData.notesObj && this.formId < 0) {
              if (this.reportData.lastSpikeDriven === null || this.reportData.lastSpikeDriven === undefined ||
                this.reportData.lastSpikeDriven === 0) {
                this.reportData.lastSpikeDriven = notesData.notesObj.lastSpikeDriven;
              } if (this.reportData.firstSpikedPulled === null || this.reportData.firstSpikedPulled === undefined ||
                this.reportData.firstSpikedPulled === 0) {
                this.reportData.firstSpikedPulled = notesData.notesObj.firstSpikedPulled;
              } if (this.reportData.firstSpikeDrivenTime === null || this.reportData.firstSpikeDrivenTime === undefined
                || this.reportData.firstSpikeDrivenTime === 0) {
                this.reportData.firstSpikeDrivenTime = notesData.notesObj.firstSpikeDrivenTime;
              }
              if (this.jobType === JOB_TYPE_CP || this.jobType === JOB_TYPE_GA || this.jobType === JOB_TYPE_PR ||
                this.jobType === JOB_TYPE_OF) {
                if (this.reportData.sawCutsTime === null || this.reportData.sawCutsTime === undefined
                  || this.reportData.sawCutsTime === 0
                ) {
                  this.reportData.sawCutsTime = notesData.notesObj.sawCutsTime;
                } if (this.reportData.firstWeldTime === null || this.reportData.firstWeldTime === undefined ||
                  this.reportData.firstWeldTime === 0) {
                  this.reportData.firstWeldTime = notesData.notesObj.firstWeldTime;
                }
              }
            }
            if (this.jobType === JOB_TYPE_CP || this.jobType === JOB_TYPE_GA || this.jobType === JOB_TYPE_PR ||
              this.jobType === JOB_TYPE_OF) {
              if (this.reportData.firstWeldTime !== null && this.reportData.firstWeldTime !== undefined &&
                this.reportData.firstWeldTime !== 0) {
                this.formGroup.controls['firstWeldTime'].patchValue(this.utilService.convertTimeStampTOdate(this.reportData.firstWeldTime));
              }
              if (this.reportData.sawCutsTime !== null && this.reportData.sawCutsTime !== undefined &&
                this.reportData.sawCutsTime !== 0) {
                this.formGroup.controls['sawCutsTime'].patchValue(this.utilService.convertTimeStampTOdate(this.reportData.sawCutsTime));
              }
            }
            if (this.reportData.firstSpikedPulled !== null && this.reportData.firstSpikedPulled !== undefined &&
              this.reportData.firstSpikedPulled !== 0) {
              this.formGroup.controls['firstSpikedPulled'].
                patchValue(this.utilService.convertTimeStampTOdate(this.reportData.firstSpikedPulled));
            }
            if (this.reportData.firstSpikeDrivenTime !== null && this.reportData.firstSpikeDrivenTime !== undefined &&
              this.reportData.firstSpikeDrivenTime !== 0) {
              this.formGroup.controls['firstSpikeDrivenTime']
                .patchValue(this.utilService.convertTimeStampTOdate(this.reportData.firstSpikeDrivenTime));
            }
            if (this.reportData.lastSpikeDriven !== null && this.reportData.lastSpikeDriven !== undefined &&
              this.reportData.lastSpikeDriven !== 0) {
              this.formGroup.controls['lastSpikeDriven'].patchValue(this.utilService.convertTimeStampTOdate(
                this.reportData.lastSpikeDriven));
            }
          });
        });
        this.getJobReports();
        this.setDefaultValues();
        this.setDataFromLatestReport();
      } else {
        this.formGroup.controls['unitsRemaining'].patchValue(this.reportData.unitsRemaining);
        this.formGroup.controls['daysToComplete'].patchValue(this.reportData.daysToComplete);
      }
      if (this.jobType === JOB_TYPE_TS || this.jobType === JOB_TYPE_SO
        || this.reportData.workTypeCode === WorkType.SWITCHTIE) {
        this.tsFlag = true;
        this.remainingToString = 'Scrap Ties Remaining to be Picked Up';
        this.formGroup.controls['sawCutsTime'].clearValidators();
        this.formGroup.controls['firstWeldTime'].clearValidators();
        this.formGroup.controls['sawCutsTime'].updateValueAndValidity();
        this.formGroup.controls['firstWeldTime'].updateValueAndValidity();
        if (this.reportData.workTypeCode === WorkType.SWITCHTIE) {
          this.soFlag = true;
        }
        if (this.jobType === JOB_TYPE_SO) {
          if (this.reportData.workTypeCode === WorkType.SWITCHTIE) {
            this.reportData.totalUnitInstalled = this.reportData.totalTiesInstalled;
          } else {
            this.soFlag = true;
            this.reportData.totalUnitInstalled = this.reportData.totalSurfacedFeet;
          }
        } else {
          this.reportData.totalUnitInstalled = this.reportData.totalTiesInstalled;
        }
      } else {
        this.tsFlag = false;
        this.remainingToString = 'Scrap Rail Remaining to be Picked Up';
        this.reportData.totalUnitInstalled = this.reportData.railLength;
      }
      this.unitsInstalled = this.reportData.totalUnitInstalled;
      // Override pre-populated value if data available from Index DB
      if (
        this.reportData.totalPlannedProjectUnits ||
        this.reportData.totalPlannedProjectDays ||
        this.reportData.plannedCrossingCount ||
        !this.createMode
      ) {
        this.reportfill();
      } else {
        // Auto populate fields from TSC - Project grid
        this.projectId = Number(this.projectData.split('-')[0]);
        this.dataService.getProjectInfo(this.projectId).then(
          (project1: ProjectTable) => {
            this.projectObject = JSON.parse(JSON.stringify(project1.projectObj));
            const pastReport = this.reportDataService.getLatestReportBeforePastDay(
              this.teamName,
              this.formId,
              this.projectReports,
              this.reportData.workday
            );
            if (this.isPastDayReport()) {
              if (pastReport) {
                this.formGroup.controls['totalPlannedProjectUnits'].patchValue(pastReport.totalPlannedProjectUnits);
                this.formGroup.controls['totalPlannedProjectDays'].patchValue(pastReport.totalPlannedProjectDays);
                this.formGroup.controls['plannedCrossingCount'].patchValue(
                  pastReport.plannedCrossingCount
                    ? pastReport.plannedCrossingCount
                    : this.projectObject.plannedCrossingCount
                      ? this.projectObject.plannedCrossingCount
                      : 0
                );
                this.reportData.totalActualProjectUnits = pastReport.totalPlannedProjectUnits - pastReport.unitsRemaining;
                this.formGroup.controls['totalActualProjectUnits'].patchValue(this.reportData.totalActualProjectUnits);
              } else {
                this.formGroup.controls['plannedCrossingCount'].patchValue(
                  this.projectObject.initialPlannedCrossing ? this.projectObject.initialPlannedCrossing : 0
                );
                this.reportData.totalActualProjectUnits = 0;
                this.formGroup.controls['totalActualProjectUnits'].patchValue(this.reportData.totalActualProjectUnits);
              }
            } else {
              if (pastReport) {
                this.formGroup.controls['totalPlannedProjectUnits'].patchValue(this.projectObject.totalPlannedProjectUnits);
                this.formGroup.controls['totalPlannedProjectDays'].patchValue(this.projectObject.totalPlannedProjectDays);
              }
              this.formGroup.controls['plannedCrossingCount'].patchValue(
                this.projectObject.plannedCrossingCount ? this.projectObject.plannedCrossingCount : 0
              );
              this.reportData.totalActualProjectUnits = this.projectObject.totalActualProjectUnits;
              this.formGroup.controls['totalActualProjectUnits'].patchValue(this.projectObject.totalActualProjectUnits);
            }
          },
          err => {
            console.log(err);
          }
        );
      }

      if (!this.viewMode) {
        setTimeout(() => {
          this.calculateProjectUnitsAndDays();
        }, 100);
      }
    });
  }

  protected getReportMethod() {
    return this.dataService.getReport(Number(this.formId));
  }

  protected setOperationMode() {
    if (this.reportData.status === 'D') {
      this.operationMode = OperationMode.CREATE;
    } else {
      this.operationMode = OperationMode.VIEW;
    }
  }

  private isPastDayReport(): boolean {
    const yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    yesterdayDate.setHours(23, 59, 59, 999);
    return this.reportData.workday <= yesterdayDate.getTime();
  }
  convertEpochToDate(epoch: number) {
    if (epoch == null) {
      return null;
    }
    let date = new Date(epoch).toLocaleDateString().replace(/\//g, '-');
    const dateArray = date.split('-');
    date = dateArray[2] + '-' + this.convertSingleDigitToDouble(dateArray[0]) + '-' + this.convertSingleDigitToDouble(dateArray[1]);
    return date;
  }

  convertSingleDigitToDouble(value: string): string {
    if (value.length === 1) {
      return '0' + value;
    } else {
      return value;
    }
  }

  /**
   * Calculate Project Units / Days for the submitted/Draft
   * reports along with existing units/Days from DB.
   * @memberof PlannedActualComponent
   */
  async calculateProjectUnitsAndDays1() {
    let totalActualProjectsUnits = 0;
    const totalProjectDays = [];
    await this.localStorage.getProjectJobs(Number(this.projectId)).then((jobList: Array<JobTable>) => {
      jobList.forEach(async (app, index) => {
        await this.localStorage.getJobReports(app.jobId).then((reportList: Array<ReportTable>) => {
          if (reportList.length > 0) {
            let length = 0;
            for (const p of reportList) {
              const js: any = JSON.parse(JSON.stringify(p.reportObj));
              // ******* by using reportstatus we are checking all reports submitted or not
              if (js.status === JOB_SUBMITTED && js.noProductionCode === null) {
                if (this.jobType === JOB_TYPE_SO) {
                  if (this.reportData.workTypeCode === WorkType.SWITCHTIE) {
                    totalActualProjectsUnits = totalActualProjectsUnits + js['totalTiesInstalled'];
                  } else {
                    totalActualProjectsUnits = totalActualProjectsUnits + js['totalSurfacedFeet'];
                  }
                } else if (this.jobType === JOB_TYPE_TS || this.reportData.workTypeCode === WorkType.SWITCHTIE) {
                  totalActualProjectsUnits = totalActualProjectsUnits + js['totalTiesInstalled'];
                } else {
                  if (js['railLength']) {
                    totalActualProjectsUnits = totalActualProjectsUnits + js['railLength'];
                    length++;
                  }
                }
              }
              if (js.status === JOB_SUBMITTED || (js.formId > 0 && this.teamName === js.teamName)) {
                if (js.workday > 1) {
                  totalProjectDays.push(js.workday);
                  this.addWorkDayandReportCode.push({
                    workday: js.workday,
                    noProdRest: js.noProductionCode
                  });
                }
              }
            }
          }
        });
        totalProjectDays.push(this.utilService.getWorkDay(this.reportData));

        this.submitReportTotalProjectUnits = this.reportData.totalActualProjectUnits + totalActualProjectsUnits;
        if (index === jobList.length - 1) {
          this.calDayCount(totalProjectDays);
          this.calculateUnitRemaining();
        }
      });
    });
  }

  async calculateProjectUnitsAndDays() {
    let totalActualProjectsUnits = 0;
    const totalProjectDays = [];
    await this.dataService.getProjectReportsFromLocal(Number(this.projectId)).then((reportList: Report[]) => {
      if (reportList.length > 0) {
        let length = 0;
        for (const js of reportList) {
          // ******* by using reportstatus we are checking all reports submitted or not
          if (js.status === JOB_SUBMITTED && js.noProductionCode === null) {
            if (this.jobType === JOB_TYPE_SO) {
              if (this.reportData.workTypeCode === WorkType.SWITCHTIE) {
                totalActualProjectsUnits = totalActualProjectsUnits + js.totalTiesInstalled;
              } else {
                totalActualProjectsUnits = totalActualProjectsUnits + js.totalSurfacedFeet;
              }
            } else if (this.jobType === JOB_TYPE_TS || this.reportData.workTypeCode === WorkType.SWITCHTIE) {
              totalActualProjectsUnits = totalActualProjectsUnits + js.totalTiesInstalled;
            } else {
              if (js.railLength) {
                totalActualProjectsUnits = totalActualProjectsUnits + js.railLength;
                length++;
              }
            }
          }
          console.log('team:::::::::::', this.teamName);

          if (js.status === JOB_SUBMITTED || (js.formId > 0 && this.teamName === js.teamName)) {
            if (js.workday > 1) {
              totalProjectDays.push(js.workday);
              this.addWorkDayandReportCode.push({
                workday: js.workday,
                noProdRest: js.noProductionCode
              });
            }
          }
        }

        totalProjectDays.push(this.utilService.getWorkDay(this.reportData));
        this.submitReportTotalProjectUnits = this.reportData.totalActualProjectUnits + totalActualProjectsUnits;
        this.calDayCount(totalProjectDays);
        this.calculateUnitRemaining();
        this.totalUnitsErrorString = this.totalDaysErrorString = '';
        this.totalUnitsFieldError = this.totalDaysFieldError = false;
      }
    });
  }

  /**
   * Calculate Project Days based on submitted reports
   * @param {any} col
   * @memberof PlannedActualComponent
   */
  calDayCount(col) {
    const uDates = [];
    for (let i = 0; i < col.length; i++) {
      for (let x = 0; x < this.addWorkDayandReportCode.length; x++) {
        if (this.addWorkDayandReportCode[x].noProdRest !== 'RST' && col[i] === this.addWorkDayandReportCode[x].workday) {
          if (
            !this.isDateInArray(new Date(col[i]), uDates) &&
            (col[i] <= this.reportData.workday || this.convertEpochToDate(col[i]) === this.convertEpochToDate(this.reportData.workday))
          ) {
            uDates.push(new Date(col[i]));
          }
        }
      }
    }
    this.uniquesDates = uDates.length > 0 ? uDates.length : 1;
    this.calculateDaysToComplete();
  }

  isDateInArray(list, key): boolean {
    console.log('isDateInArray ', list, key);
    for (let i = 0; i < key.length; i++) {
      if (list.getDate() === key[i].getDate() && list.getMonth() === key[i].getMonth()) {
        return true;
      }
    }
    return false;
  }
  /**
   * Display/Populate data's from Index-DB
   * @param reports
   */
  protected reportfill(): void {
    this.formGroup.controls['totalPlannedProjectUnits'].patchValue(this.reportData.totalPlannedProjectUnits);
    this.formGroup.controls['totalPlannedProjectDays'].patchValue(this.reportData.totalPlannedProjectDays);
    this.formGroup.controls['plannedCrossingCount'].patchValue(
      this.reportData.plannedCrossingCount ? this.reportData.plannedCrossingCount : 0
    );
    this.formGroup.controls['totalActualProjectUnits'].patchValue(this.reportData.totalActualProjectUnits);
    this.formGroup.controls['sawCutsTime'].patchValue(
      this.reportData.sawCutsTime ? this.utilService.convertTimeStampTOdate(this.reportData.sawCutsTime) : null
    );
    this.formGroup.controls['firstWeldTime'].patchValue(
      this.reportData.firstWeldTime ? this.utilService.convertTimeStampTOdate(this.reportData.firstWeldTime) : null
    );
    this.formGroup.controls['firstSpikedPulled'].patchValue(
      this.reportData.firstSpikedPulled ? this.utilService.convertTimeStampTOdate(this.reportData.firstSpikedPulled) : null
    );
    this.formGroup.controls['firstSpikeDrivenTime'].patchValue(
      this.reportData.firstSpikeDrivenTime ? this.utilService.convertTimeStampTOdate(this.reportData.firstSpikeDrivenTime) : null
    );
    this.formGroup.controls['lastSpikeDriven'].patchValue(
      this.reportData.lastSpikeDriven ? this.utilService.convertTimeStampTOdate(this.reportData.lastSpikeDriven) : null
    );
    // Convert Milliseconds to Date (Ex: 1525978661000 to 14:57)
    if (this.reportData.pickupRemainCount) {
      this.formGroup.controls['pickupRemainCount'].patchValue(this.reportData.pickupRemainCount);
    }
    this.workDay1 = this.utilService.getWorkDay(this.reportData);

    if (!this.viewMode) {
      this.validateTimeFileds(null);
    }
  }

  /**
   * Validate the data and  Navigate to next screen.
   * @param {*} event
   * @memberof PlannedActualComponent
   */
  async formSubmit(event: any) {
    if (this.viewMode) {
      if (this.jobType === JOB_TYPE_CP) {
        this.routeToTrackUtil();
      } else if (this.jobType === JOB_TYPE_TS && this.reportData.workTypeCode === WorkType.TIEANDSURFACE) {
        const tieProgress = Object.keys(this.reportData.tsProgress.tieProgress).map(i => this.reportData.tsProgress.tieProgress[i]);
        const surfaceProgress = Object.keys(this.reportData.tsProgress.surfaceProgress).map(
          i => this.reportData.tsProgress.surfaceProgress[i]
        );
        let count = 0;
        if (tieProgress.length > 0) {
          for (let i = 0; i < tieProgress.length; i++) {
            if (tieProgress[i]['crossingTiesTimbered'] === true) {
              count++;
            }
          }
        }

        if (surfaceProgress != null) {
          for (let i = 0; i < surfaceProgress.length; i++) {
            if (surfaceProgress[i]['crossingSurfaced'] === true) {
              count++;
            }
          }
        }
        this.navigateToRoadCrossingOrTurnout(count > 0 ? true : false);
      } else {
        this.navigateToRoadCrossingOrTurnout(true);
      }
    } else {
      this.formSubmitAttempt = true;
      let control;
      Object.keys(this.formGroup.controls).forEach(field => {
        control = this.formGroup.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      if (!this.formGroup.controls['totalPlannedProjectUnits'].value) {
        this.totalUnitsErrorString = 'Enter Project Units.';
      }
      if (!this.formGroup.controls['totalPlannedProjectDays'].value) {
        this.totalDaysErrorString = 'Enter Project Days.';
      }
      this.validateCrossingInput();
      await this.validateTimeFileds(null);

      if (this.totalUnitsErrorString !== '' || this.totalDaysErrorString !== '' || this.totalCrossingErrorString !== '') {
        this.isValidationError = true;
        this.formGroup.markAsPending({ onlySelf: true });
      }

      if (this.formGroup.valid) {
        this.isValidationError = false;
        this.pageNav = 'next';
        this.saveToInMemoryDB(this.pageNav);
      } else {
        this.isValidationError = true;
        this.formGroup.markAsPending({ onlySelf: true });
      }
    }
  }

  protected navigateToRoadCrossingOrTurnout(navigateToXing: boolean) {
    if (navigateToXing) {
      this.router.navigate([`/road_crossing/${this.projectId}/${this.jobId}/${this.formId}`]);
    } else {
      this.router.navigate([`/turnout/${this.projectId}/${this.jobId}/${this.formId}`]);
    }
  }

  protected saveReportToIndexDbMethod(repObj: any) {
    return this.dataService.saveDraftReport(JSON.stringify(repObj));
  }

  /***************************************************************************
   * Method 'saveToInMemoryDB' will save the Data in to Index DB while user
   * click "back", "back Icon" and "Next" Button And WorkDay setup for
   * timeOnly field and etc.
   **************************************************************************/
  protected saveToInMemoryDB(pageNav: string): void {
    this.reportData.totalPlannedProjectUnits = this.formGroup.get('totalPlannedProjectUnits').value;
    this.reportData.totalPlannedProjectDays = this.formGroup.get('totalPlannedProjectDays').value;
    this.reportData.plannedCrossingCount = this.formGroup.get('plannedCrossingCount').value;
    this.reportData.totalActualProjectUnits = this.formGroup.get('totalActualProjectUnits').value;
    this.reportData.unitsRemaining = this.formGroup.get('unitsRemaining').value;
    this.reportData.daysToComplete = this.formGroup.get('daysToComplete').value;
    this.reportData.pickupRemainCount = this.formGroup.get('pickupRemainCount').value;
    if (this.jobType !== 'TS' || this.jobType !== 'SO') {
      this.reportData.sawCutsTime = new Date(this.formGroup.get('sawCutsTime').value).getTime();
      this.reportData.firstWeldTime = new Date(this.formGroup.get('firstWeldTime').value).getTime();
    } else {
      this.reportData.sawCutsTime = null;
      this.reportData.firstWeldTime = null;
    }
    this.reportData.firstSpikedPulled = new Date(this.formGroup.get('firstSpikedPulled').value).getTime();
    this.reportData.firstSpikeDrivenTime = new Date(this.formGroup.get('firstSpikeDrivenTime').value).getTime();
    this.reportData.lastSpikeDriven = new Date(this.formGroup.get('lastSpikeDriven').value).getTime();

    if (pageNav === 'next') {
      this.saveReportToIndexDbMethod(this.reportData)
        .then(response => {
          if (this.jobType === JOB_TYPE_CP) {
            this.routeToTrackUtil();
          } else if (this.jobType === JOB_TYPE_TS && this.reportData.workTypeCode === WorkType.TIEANDSURFACE) {
            const tieProgress = Object.keys(this.reportData.tsProgress.tieProgress).map(i => this.reportData.tsProgress.tieProgress[i]);
            const surfaceProgress = Object.keys(this.reportData.tsProgress.surfaceProgress).map(
              i => this.reportData.tsProgress.surfaceProgress[i]
            );
            let count = 0;
            if (tieProgress.length > 0) {
              for (let i = 0; i < tieProgress.length; i++) {
                if (tieProgress[i]['crossingTiesTimbered'] === true) {
                  count++;
                }
              }
            }

            if (surfaceProgress != null) {
              for (let i = 0; i < surfaceProgress.length; i++) {
                if (surfaceProgress[i]['crossingSurfaced'] === true) {
                  count++;
                }
              }
            }
            this.navigateToRoadCrossingOrTurnout(count > 0 ? true : false);
          } else {
            this.navigateToRoadCrossingOrTurnout(true);
          }
        })
        .catch(reason => {
          console.log('Dixie error while saving draft' + reason.stack);
        });
    } else if (pageNav === 'back') {
      this.bottomBackButtonNavigation();
    } else if (pageNav === 'backicon') {
      this.topBackButtonNavigation();
    }
  }

  protected routeToTrackUtil() {
    this.router.navigate([`/track_utilization/${this.projectId}/${this.jobId}/${this.formId}`]);
  }

  protected bottomBackButtonNavigation() {
    this.dataService
      .saveDraftReport(JSON.stringify(this.reportData))
      .then(response => {
        if (this.reportData.workTypeCode === WorkType.SWITCHTIE) {
          this.router.navigate([`/switch_tie/${this.projectId}/${this.jobId}/${this.formId}`]);
        } else {
          if (this.jobType === 'PR' || this.jobType === 'OF') {
            this.router.navigate([`/rail_info/${this.projectId}/${this.jobId}/${this.formId}`]);
          } else if (this.jobType === JOB_TYPE_GA || this.jobType === JOB_TYPE_CP) {
            this.router.navigate([`/gauging_info/${this.projectId}/${this.jobId}/${this.formId}`]);
          } else if (this.jobType === JOB_TYPE_TS) {
            this.router.navigate([`/ts_info/${this.projectId}/${this.jobId}/${this.formId}`]);
          } else if (this.jobType === JOB_TYPE_SO) {
            this.router.navigate([`/surfacing_info/${this.projectId}/${this.jobId}/${this.formId}`]);
          }
        }
      })
      .catch(reason => {
        console.log(reason);
      });
  }

  protected topBackButtonNavigation() {
    this.dataService
      .saveDraftReport(JSON.stringify(this.reportData))
      .then(response => {
        console.log('road', response);
        this.router.navigate([`/job_view/${this.projectId}`]);
      })
      .catch(reason => {
        console.log(reason);
      });
  }

  /**
   * Dynamically calculate 'unitRemaining'.
   */
  async calculateUnitRemaining() {
    this.unitRemainWarningMessage = null;
    let unitsRemian = 0;
    if (this.isPastDayReport()) {
      unitsRemian =
        this.formGroup.controls['totalPlannedProjectUnits'].value -
        (this.getTotalActualProjectUnits() + this.unitsInstalled);
    } else {
      unitsRemian =
        this.formGroup.controls['totalPlannedProjectUnits'].value - (this.unitsInstalled + this.getTotalSubmittedProjectUnits());
    }
    this.reportData.unitsRemaining = unitsRemian;
    let control;
    Object.keys(this.formGroup.controls).forEach(field => {
      control = this.formGroup.get('unitsRemaining');
      control.markAsTouched({ onlySelf: true });
    });

    if (unitsRemian < 0) {
      unitsRemian = 0;
      const repUnit = this.unitsInstalled + this.getTotalSubmittedProjectUnits();
      const punit = this.formGroup.controls['totalPlannedProjectUnits'].value
        ? this.formGroup.controls['totalPlannedProjectUnits'].value
        : 0;
      this.unitRemainWarningMessage = 'Reported Units(' + repUnit + ') exceeds Planned Units (' + punit + ')';
    }
    this.formGroup.controls['unitsRemaining'].patchValue(unitsRemian);
    await this.validateTotalProjectUnits();
  }

  protected getTotalSubmittedProjectUnits() {
    return this.submitReportTotalProjectUnits;
  }

  protected getTotalActualProjectUnits() {
    return this.formGroup.controls['totalActualProjectUnits'].value;
  }

  async validateTotalProjectUnits() {
    if (
      this.formGroup.controls['totalPlannedProjectUnits'].value === undefined ||
      this.formGroup.controls['totalPlannedProjectUnits'].value === null
    ) {
      this.totalUnitsErrorString = ' Enter Project Units.';
      this.totalUnitsFieldError = true;
    } else if (this.formGroup.controls['totalPlannedProjectUnits'].value === 0) {
      this.totalUnitsErrorString = 'Should be > 0.';
      this.totalUnitsFieldError = true;
    } else if (this.formGroup.controls['totalPlannedProjectUnits'].value < 0) {
      this.totalUnitsErrorString = 'Cannot be a negative number.';
      this.totalUnitsFieldError = true;
    } else if (
      this.formGroup.controls['totalPlannedProjectUnits'].value !== null &&
      this.formGroup.controls['totalPlannedProjectUnits'].value.toString().includes('.')
    ) {
      this.totalUnitsErrorString = 'Enter Numeric Value.';
      this.totalUnitsFieldError = true;
    } else {
      this.totalUnitsErrorString = '';
      this.totalUnitsFieldError = false;
    }
  }

  /**
   * Dynamically calculate 'daysToComplete'.
   */
  calculateDaysToComplete(): void {
    this.daysToCompleteWarningMessage = null;
    this.validateTotalProjectDays();
    let daysToRemain = this.formGroup.controls['totalPlannedProjectDays'].value - this.uniquesDates;
    if (daysToRemain < 0) {
      daysToRemain = 0;
      const pDay = this.formGroup.controls['totalPlannedProjectDays'].value ? this.formGroup.controls['totalPlannedProjectDays'].value : 0;
      this.daysToCompleteWarningMessage = 'Reported Days(' + this.uniquesDates + ') exceeds Planned Days(' + pDay + ')';
    }
    this.reportData.daysToComplete = daysToRemain;
    let control;
    Object.keys(this.formGroup.controls).forEach(field => {
      control = this.formGroup.get('daysToComplete');
      control.markAsTouched({ onlySelf: true });
    });
    this.formGroup.controls['daysToComplete'].patchValue(daysToRemain);
  }

  validateTotalProjectDays() {
    if (
      this.formGroup.controls['totalPlannedProjectDays'].value === undefined ||
      this.formGroup.controls['totalPlannedProjectDays'].value === null
    ) {
      this.totalDaysErrorString = 'Enter Project Days.';
      this.totalDaysFieldError = true;
    } else if (this.formGroup.controls['totalPlannedProjectDays'].value === 0) {
      this.totalDaysErrorString = 'Should be > 0.';
      this.totalDaysFieldError = true;
    } else if (this.formGroup.controls['totalPlannedProjectDays'].value < 0) {
      this.totalDaysErrorString = 'Cannot be a negative number.';
      this.totalDaysFieldError = true;
    } else if (
      this.formGroup.controls['totalPlannedProjectDays'].value !== null &&
      this.formGroup.controls['totalPlannedProjectDays'].value.toString().includes('.')
    ) {
      this.totalDaysErrorString = 'Enter Numeric Value.';
      this.totalDaysFieldError = true;
    } else {
      this.totalDaysErrorString = '';
      this.totalDaysFieldError = false;
    }
  }

  validateCrossingInput(): void {
    if (
      this.formGroup.controls['plannedCrossingCount'].value === undefined ||
      this.formGroup.controls['plannedCrossingCount'].value === null
    ) {
      this.totalCrossingErrorString = 'Enter Crossings';
      this.totalCrossingFieldError = true;
    } else if (this.formGroup.controls['plannedCrossingCount'].value < 0) {
      this.totalCrossingErrorString = 'Cannot be a negative number.';
      this.totalCrossingFieldError = true;
    } else if (
      this.formGroup.controls['totalPlannedProjectDays'].value !== null &&
      this.formGroup.controls['plannedCrossingCount'].value.toString().includes('.')
    ) {
      this.totalCrossingErrorString = 'Enter Numeric Value.';
      this.totalCrossingFieldError = true;
    } else if (this.formGroup.controls['plannedCrossingCount'].value === 0) {
      this.totalCrossingErrorString = '';
      this.totalCrossingFieldError = false;
    } else {
      this.totalCrossingErrorString = '';
      this.totalCrossingFieldError = false;
    }
  }

  async validateTimeFileds(fieldName?: string, wDay1?: Date) {
    this.showInvalidfirstSpikedPulledError = null;
    this.showInvalidSawCutsTimeError = null;
    this.showInvalidfirstWeldTimeError = null;
    this.showInvalidLastSpikeTimeError = null;
    this.showInvalidSpikeDrivenTimeError = null;
    const sawCut = moment(this.formGroup.get('sawCutsTime').value);
    const firstSpikePulled = moment(this.formGroup.get('firstSpikedPulled').value);
    const weldTime = moment(this.formGroup.get('firstWeldTime').value);
    const firstSprikeDriven = moment(this.formGroup.get('firstSpikeDrivenTime').value);
    const lastSpikeDriven = moment(this.formGroup.get('lastSpikeDriven').value);
    const wDay = moment(this.workDay1 ? this.workDay1 : wDay1);
    let workType: string;
    if (this.jobType === 'TS') {
      workType = 'TS';
    } else if (this.jobType === 'SO') {
      workType = 'SU';
    } else {
      workType = 'RA';
    }

    if (!this.lessThanCurrentDate(workType, sawCut, firstSpikePulled, weldTime, firstSprikeDriven, lastSpikeDriven)) {
      this.isValidationError = true;
      this.formGroup.markAsPending({ onlySelf: true });
      return;
    }

    // check all times with on duty date  difference not greter than 18 hrs
    let timeFlag = false;
    await this.validateLessThan18Hours(workType, wDay, sawCut, firstSpikePulled, weldTime, firstSprikeDriven, lastSpikeDriven).then(val => {
      timeFlag = val;
    });
    if (timeFlag) {
      this.isValidationError = true;
      this.formGroup.markAsPending({ onlySelf: true });
      return;
    }

    if (workType === 'RA') {
      // m2 -m1
      if (
        this.getTimeDifference(sawCut, firstSpikePulled) < 0 &&
        this.getTimeDifference(firstSpikePulled, weldTime) < 0 &&
        this.getTimeDifference(weldTime, firstSprikeDriven) < 0 &&
        this.getTimeDifference(firstSprikeDriven, lastSpikeDriven) < 0
      ) {
        await this.validateGreaterThanOnDuty(workType, wDay, sawCut, firstSpikePulled, weldTime, firstSprikeDriven, lastSpikeDriven);
      } else {
        await this.validateRailTimeFields(fieldName, wDay, sawCut, firstSpikePulled, weldTime, firstSprikeDriven, lastSpikeDriven);
      }

      if (
        this.showInvalidSawCutsTimeError ||
        this.showInvalidfirstSpikedPulledError ||
        this.showInvalidSpikeDrivenTimeError ||
        this.showInvalidfirstWeldTimeError ||
        this.showInvalidLastSpikeTimeError
      ) {
        this.isValidationError = true;
        this.formGroup.markAsPending({ onlySelf: true });
      } else {
        this.isValidationError = false;
      }
    } else {
      if (
        this.getTimeDifference(firstSpikePulled, firstSprikeDriven) > 0 &&
        this.getTimeDifference(firstSprikeDriven, lastSpikeDriven) > 0
      ) {
        await this.validateGreaterThanOnDuty(workType, wDay, sawCut, firstSpikePulled, weldTime, firstSprikeDriven, lastSpikeDriven);
      } else {
        await this.validateTieAndSurfacingTimes(fieldName, wDay, firstSpikePulled, firstSprikeDriven, lastSpikeDriven);
      }
      if (this.showInvalidfirstSpikedPulledError || this.showInvalidSpikeDrivenTimeError || this.showInvalidLastSpikeTimeError) {
        this.isValidationError = true;
        this.formGroup.markAsPending({ onlySelf: true });
      } else {
        this.isValidationError = false;
      }
    }
  }

  lessThanCurrentDate(workType, sawCut, firstSpikePulled, weldTime, firstSprikeDriven, lastSpikeDriven) {
    let isValid = true;
    if (workType === 'RA') {
      if (sawCut.valueOf() > moment(new Date()).valueOf()) {
        this.showInvalidSawCutsTimeError = 'Should be < Current Date Time';
        isValid = false;
      }
      if (weldTime.valueOf() > moment(new Date()).valueOf()) {
        this.showInvalidfirstWeldTimeError = 'Should be < Current Date Time';
        isValid = false;
      }
    }
    if (firstSpikePulled.valueOf() > moment(new Date()).valueOf()) {
      this.showInvalidfirstSpikedPulledError = 'Should be < Current Date Time';
      isValid = false;
    }
    if (firstSprikeDriven.valueOf() > moment(new Date()).valueOf()) {
      this.showInvalidSpikeDrivenTimeError = 'Should be < Current Date Time';
      isValid = false;
    }
    if (lastSpikeDriven.valueOf() > moment(new Date()).valueOf()) {
      this.showInvalidLastSpikeTimeError = 'Should be < Current Date Time';
      isValid = false;
    }
    return isValid;
  }

  async validateLessThan18Hours(
    workType: string,
    wDay: any,
    sawCut: any,
    firstSpikePulled: any,
    weldTime: any,
    firstSprikeDriven: any,
    lastSpikeDriven: any
  ) {
    let timeFlag = false;

    if (workType === 'RA') {
      if (sawCut.isValid() && !this.utilService.timeDifferenceGreaterThan18Hours(sawCut, wDay)) {
        this.showInvalidSawCutsTimeError = 'Should be < 18 hrs from On Duty time';
        timeFlag = true;
      }
      if (weldTime.isValid() && !this.utilService.timeDifferenceGreaterThan18Hours(weldTime, wDay)) {
        this.showInvalidfirstWeldTimeError = 'Should be <  18 hrs from On Duty time';
        timeFlag = true;
      }
    }

    if (firstSpikePulled.isValid() && !this.utilService.timeDifferenceGreaterThan18Hours(firstSpikePulled, wDay)) {
      this.showInvalidfirstSpikedPulledError = 'Should be < 18 hrs from On Duty time';
      timeFlag = true;
    }

    if (firstSprikeDriven.isValid() && !this.utilService.timeDifferenceGreaterThan18Hours(firstSprikeDriven, wDay)) {
      this.showInvalidSpikeDrivenTimeError = 'Should be < 18 hrs from On Duty time';
      timeFlag = true;
    }
    if (lastSpikeDriven.isValid() && !this.utilService.timeDifferenceGreaterThan18Hours(lastSpikeDriven, wDay)) {
      this.showInvalidLastSpikeTimeError = 'Should be <  18 hrs from On Duty time';
      timeFlag = true;
    }
    if (this.utilService.timeDifferenceGreaterThan14Hours(lastSpikeDriven, firstSprikeDriven)) {
      this.showInvalidLastSpikeTimeError = 'Should be < 14 hrs from First Spike Driven';
      timeFlag = true;
    }
    return timeFlag;
  }

  async validateGreaterThanOnDuty(
    workType: string,
    wDay: any,
    sawCut: any,
    firstSpikePulled: any,
    weldTime: any,
    firstSprikeDriven: any,
    lastSpikeDriven: any
  ) {
    if (this.getTimeDifference(wDay, sawCut) <= 0) {
      this.showInvalidSawCutsTimeError = 'Should be > On Duty Time';
    }
    if (workType === 'RA') {
      if (this.getTimeDifference(wDay, weldTime) <= 0) {
        this.showInvalidfirstWeldTimeError = 'Should be > On Duty Time';
      }
      if (this.getTimeDifference(wDay, firstSpikePulled) <= 0) {
        this.showInvalidfirstSpikedPulledError = 'Should be > On Duty Time';
      }
    }
    if (this.getTimeDifference(wDay, firstSprikeDriven) <= 0) {
      this.showInvalidSpikeDrivenTimeError = 'Should be > On Duty Time';
    }
    if (this.getTimeDifference(wDay, lastSpikeDriven) <= 0) {
      this.showInvalidLastSpikeTimeError = 'Should be > On Duty Time';
    }
  }

  async validateSawCut(wDay: any, sawCut: any, firstSpikePulled: any, weldTime: any, firstSprikeDriven: any, lastSpikeDriven: any) {
    if (this.getTimeDifference(wDay, sawCut) <= 0) {
      this.showInvalidSawCutsTimeError = 'Should be > On Duty Time';
    }
  }

  async validateFirstSpikePulled(
    wDay: any,
    sawCut: any,
    firstSpikePulled: any,
    weldTime: any,
    firstSprikeDriven: any,
    lastSpikeDriven: any,
    workType: string
  ) {
    if (this.getTimeDifference(wDay, firstSpikePulled) <= 0) {
      this.showInvalidfirstSpikedPulledError = 'Should be > On Duty Time';
    }
  }
  async validateFirstWeldPulled(
    wDay: any,
    sawCut: any,
    firstSpikePulled: any,
    weldTime: any,
    firstSprikeDriven: any,
    lastSpikeDriven: any
  ) {
    if (this.getTimeDifference(wDay, weldTime) <= 0) {
      this.showInvalidfirstWeldTimeError = 'Should be > On Duty Time';
    }
  }
  async validateFirstSpikeDriven(
    wDay: any,
    sawCut: any,
    firstSpikePulled: any,
    weldTime: any,
    firstSprikeDriven: any,
    lastSpikeDriven: any,
    workType: string
  ) {
    if (this.getTimeDifference(wDay, firstSprikeDriven) <= 0) {
      this.showInvalidSpikeDrivenTimeError = 'Should be > On Duty Time';
    } else if (workType !== 'RA' && this.getTimeDifference(firstSpikePulled, firstSprikeDriven) <= 0) {
      this.showInvalidSpikeDrivenTimeError = 'Should be > First Spike Pulled';
    }
  }
  async validateLastSpikeDriven(
    wDay: any,
    sawCut: any,
    firstSpikePulled: any,
    weldTime: any,
    firstSprikeDriven: any,
    lastSpikeDriven: any,
    workType: string
  ) {
    if (this.getTimeDifference(wDay, lastSpikeDriven) <= 0) {
      this.showInvalidLastSpikeTimeError = 'Should be > On Duty Time';
    } else if (this.getTimeDifference(firstSprikeDriven, lastSpikeDriven) <= 0) {
      this.showInvalidLastSpikeTimeError = 'Should be > First Spike Driven';
    }
  }

  async validateRailTimeFields(
    fieldName: string,
    wDay: any,
    sawCut: any,
    firstSpikePulled: any,
    weldTime: any,
    firstSprikeDriven: any,
    lastSpikeDriven: any
  ) {
    switch (fieldName) {
      case 'sawCutsTime': {
        this.validateSawCut(wDay, sawCut, firstSpikePulled, weldTime, firstSprikeDriven, lastSpikeDriven);
        break;
      }
      case 'firstSpikedPulled': {
        this.validateFirstSpikePulled(wDay, sawCut, firstSpikePulled, weldTime, firstSprikeDriven, lastSpikeDriven, 'RA');
        break;
      }
      case 'firstWeldTime': {
        this.validateFirstWeldPulled(wDay, sawCut, firstSpikePulled, weldTime, firstSprikeDriven, lastSpikeDriven);
        break;
      }
      case 'firstSpikeDrivenTime': {
        this.validateFirstSpikeDriven(wDay, sawCut, firstSpikePulled, weldTime, firstSprikeDriven, lastSpikeDriven, 'RA');
        break;
      }
      case 'lastSpikeDriven': {
        this.validateLastSpikeDriven(wDay, sawCut, firstSpikePulled, weldTime, firstSprikeDriven, lastSpikeDriven, 'RA');
        break;
      }
      default: {
        this.validateSawCut(wDay, sawCut, firstSpikePulled, weldTime, firstSprikeDriven, lastSpikeDriven);
        this.validateFirstSpikePulled(wDay, sawCut, firstSpikePulled, weldTime, firstSprikeDriven, lastSpikeDriven, 'RA');
        this.validateFirstWeldPulled(wDay, sawCut, firstSpikePulled, weldTime, firstSprikeDriven, lastSpikeDriven);
        this.validateFirstSpikeDriven(wDay, sawCut, firstSpikePulled, weldTime, firstSprikeDriven, lastSpikeDriven, 'RA');
        this.validateLastSpikeDriven(wDay, sawCut, firstSpikePulled, weldTime, firstSprikeDriven, lastSpikeDriven, 'RA');
        break;
      }
    }
  }

  async validateTieAndSurfacingTimes(fieldName: string, wDay: any, firstSpikePulled: any, firstSprikeDriven: any, lastSpikeDriven: any) {
    switch (fieldName) {
      case 'firstSpikedPulled': {
        this.validateFirstSpikePulled(wDay, null, firstSpikePulled, null, firstSprikeDriven, lastSpikeDriven, 'TS');
        break;
      }
      case 'firstSpikeDrivenTime': {
        this.validateFirstSpikeDriven(wDay, null, firstSpikePulled, null, firstSprikeDriven, lastSpikeDriven, 'TS');
        break;
      }
      case 'lastSpikeDriven': {
        this.validateLastSpikeDriven(wDay, null, firstSpikePulled, null, firstSprikeDriven, lastSpikeDriven, 'TS');
        break;
      }
      default: {
        this.validateFirstSpikePulled(wDay, null, firstSpikePulled, null, firstSprikeDriven, lastSpikeDriven, 'TS');
        this.validateFirstSpikeDriven(wDay, null, firstSpikePulled, null, firstSprikeDriven, lastSpikeDriven, 'TS');
        this.validateLastSpikeDriven(wDay, null, firstSpikePulled, null, firstSprikeDriven, lastSpikeDriven, 'TS');
        break;
      }
    }
  }

  /** While user click back button and save the data into IndexDB * */
  backNavigate(event): void {
    if (this.viewMode) {
      if (this.reportData.workTypeCode === WorkType.SWITCHTIE) {
        this.router.navigate([`/switch_tie/${this.projectId}/${this.jobId}/${this.formId}`]);
      } else {
        if (this.jobType === 'PR' || this.jobType === 'OF') {
          this.router.navigate([`/rail_info/${this.projectId}/${this.jobId}/${this.formId}`]);
        } else if (this.jobType === JOB_TYPE_GA || this.jobType === JOB_TYPE_CP) {
          this.router.navigate([`/gauging_info/${this.projectId}/${this.jobId}/${this.formId}`]);
        } else if (this.jobType === JOB_TYPE_TS) {
          this.router.navigate([`/ts_info/${this.projectId}/${this.jobId}/${this.formId}`]);
        } else if (this.jobType === JOB_TYPE_SO) {
          this.router.navigate([`/surfacing_info/${this.projectId}/${this.jobId}/${this.formId}`]);
        }
      }
    } else {
      this.pageNav = 'back';
      this.saveToInMemoryDB(this.pageNav);
    }
  }

  /**
   * While user click back 'ICON' from Page header and save the data into
   * IndexDB *
   */
  backIcon(event): void {
    if (this.viewMode) {
      this.router.navigate([`/job_view/${this.projectData}`]);
    } else {
      this.pageNav = 'backicon';
      this.saveToInMemoryDB(this.pageNav);
    }
  }

  ngOnDestroy() {
    console.log('ngOnDestroy() called');
  }
}
