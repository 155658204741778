import { ReportNavigationService } from './report-navigation.service';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGaurdService implements CanDeactivate<CanComponentDeactivate> {

  constructor(private reportNavigationService: ReportNavigationService) { }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.reportNavigationService.confirmBeforeLeavingPage();
  }
}

export interface CanComponentDeactivate {
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean;
}
