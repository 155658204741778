import { LocationModel } from './../../td-speed-restriction/models/location.model';
import { Turnout } from './../../model/turnout.model';
import { ReportDetailsModel } from './../../model/report-details.model';
import { AppConstant } from './../../shared/constant/constants';

export class SwitchTieReportDetailsModel extends ReportDetailsModel {
  surfacing: Array<SurfacingDetailsModel>;
  switchTies: Array<SwitchTieDetailsModel>;
  plannedStart?: any;
  plannedEnd?: any;
  greenSignalTime?: any;
  reportWindows?: any;

  constructor(reportDetails?: SwitchTieReportDetailsModel) {
    if (reportDetails) {
      super(reportDetails);
      this.surfacing = reportDetails.surfacing.map((surfacing: SurfacingDetailsModel) => new SurfacingDetailsModel(surfacing));
      this.switchTies = reportDetails.switchTies.map((switchTie: SwitchTieDetailsModel) => new SwitchTieDetailsModel(switchTie));
    } else {
      super();
      this.surfacing = [];
      this.switchTies = [];
    }
  }
}

export class SwitchTieDetailsModel {
  tieType: string;
  newRelay: string;
  noOfApproachTies: number;
  noOfBridgeApproachTies: number;
  switchTiesSizes: Array<SwitchTieSizeModel>;
  turnout: Turnout;
  workProgressId?: number;

  constructor(swithTieDetails?: SwitchTieDetailsModel) {
    if (swithTieDetails) {
      this.tieType = swithTieDetails.tieType;
      this.newRelay = swithTieDetails.newRelay;
      this.noOfApproachTies = swithTieDetails.noOfApproachTies;
      this.noOfBridgeApproachTies = swithTieDetails.noOfBridgeApproachTies;
      this.switchTiesSizes = swithTieDetails.switchTiesSizes.map((size: SwitchTieSizeModel) => new SwitchTieSizeModel(size));
      this.turnout = swithTieDetails.turnout;
      this.workProgressId = swithTieDetails.workProgressId;
    } else {
      this.tieType = AppConstant.TIE_PLATE_TYPE_VALUE.WOOD;
      this.newRelay = AppConstant.NEW_RELAY.NEW;
      this.noOfApproachTies = null;
      this.noOfBridgeApproachTies = null;
      this.switchTiesSizes = [];
      this.turnout = null;
      this.workProgressId = null;
    }
  }
}

export class SwitchTieSizeModel {
  size: number;
  noOfTies: number;

  constructor(switchTieSize?: SwitchTieSizeModel) {
    if (switchTieSize) {
      this.size = switchTieSize.size;
      this.noOfTies = switchTieSize.noOfTies;
    } else {
      this.size = null;
      this.noOfTies = null;
    }
  }
}

export class SurfacingDetailsModel {
  crossingSurfaced: boolean;
  surfaceDetails: Array<SurfacingSegmentModel>;
  trackDetails: LocationModel;

  constructor(surfacingDetails?: SurfacingDetailsModel) {
    if (surfacingDetails) {
      this.crossingSurfaced = surfacingDetails.crossingSurfaced;
      this.trackDetails = surfacingDetails.trackDetails;
      this.surfaceDetails = surfacingDetails.surfaceDetails.map((surface: SurfacingSegmentModel) => new SurfacingSegmentModel(surface));
    } else {
      this.crossingSurfaced = null;
      this.trackDetails = null;
      this.surfaceDetails = [];
    }
  }
}

export class SurfacingSegmentModel {
  beginMP: number;
  endMP: number;
  surfaced: number;

  constructor(segment?: SurfacingSegmentModel) {
    if (segment) {
      this.beginMP = segment.beginMP;
      this.endMP = segment.endMP;
      this.surfaced = segment.surfaced;
    } else {
      this.beginMP = null;
      this.endMP = null;
      this.surfaced = null;
    }
  }
}
