import { Injectable } from '@angular/core';
import {
  EventBusService,
  EventType,
  STWEventData
} from '../services/eventbus.service';
import { FlexObject } from 'csx-flex-ng';

@Injectable()
export class AdapterService {
  private Flex: FlexObject;

  constructor(private eventBus: EventBusService) {
    this.Flex = FlexObject.getInstance();
  }

  executeService(path: string, method: string, bodyContent: string) {
    console.log('path::::', path);
    const deviceId = this.Flex.deviceInfo.uuid;
    let invocationData = {
      adapter: 'stw_http_adapter',
      procedure: 'executeSystemTrackService',
      parameters: [method, path, bodyContent, deviceId]
    };
    let options = {
      onSuccess: response => this.handleRESTResponse(response),
      onFailure: response => this.handleAdapterFailure(path, response),
      timeout: 200000
    };
    return this.Flex.invokeLambda(invocationData, options);
  }

  private parseStringMessage(status: string, message: string): string {
    return 'Response Status ' + status + ': ' + message;
  }

  private handleRESTResponse(response: any): any {
    if (response.invocationResult.statusCode !== 200) {
      console.warn(response);
      this.eventBus.fireEvent(
        EventType.NOTIFY_FAILURE_EVENT,
        new STWEventData(response.invocationData, () => {
          let message: string = '';
          if (response.invocationResult.message !== undefined) {
            message = response.invocationResult.message;
          } else {
            message = response.invocationResult.statusReason;
          }
          return this.parseStringMessage(
            response.invocationResult.statusCode,
            message
          );
        })
      );
    }
    return response.invocationResult;
  }

  private handleAdapterFailure(path: string, response: any): any {
    console.warn(response);

    if (path.indexOf('submitTrackDisturbance') <= 0) {
      this.eventBus.fireEvent(
        EventType.NOTIFY_FAILURE_EVENT,
        new STWEventData(response.invocationData, () =>
          this.parseStringMessage(
            response.status,
            'The system is unreachable please contact help desk'
          )
        )
      );
    }
    if (
      response.errorCode !== undefined &&
      response.errorCode === 'REQUEST_TIMEOUT'
    ) {
      this.Flex.online = true;
    }
    return response;
  }
}
