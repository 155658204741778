import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/of';

@Injectable()
export class EquipmentDetailsService {
    public equipmentData = [{
        equipmentId: 'AARR200501 ',
        equipmentType: 'Surfacing Machine',
        failureStatus: 'false',
        failureReason: '',
        detailReason: ' ',
    }, {
        equipmentId: 'AARR200523 ',
        equipmentType: 'Surfacing Machine',
        failureStatus: ' ',
        failureReason: '',
        detailReason: ' ',
    }, {
        equipmentId: 'AARR200521 ',
        equipmentType: 'Surfacing Machine',
        failureStatus: 'false',
        failureReason: '',
        detailReason: ' ',
    }, {
        equipmentId: 'AARR200509 ',
        equipmentType: 'Surfacing Machine',
        failureStatus: 'false',
        failureReason: '',
        detailReason: ' ',
    }, {
        equipmentId: 'AARR200534',
        equipmentType: 'Surfacing Machine',
        failureStatus: 'false',
        failureReason: '',
        detailReason: ' ',
    }
    ];

    constructor(public http: HttpClient) {
    }

    loadJobData(): Observable<any> {
        return Observable.of(this.equipmentData);
// return this.http.get('ap/url');
    }
}
