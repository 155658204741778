import { TDSpeedType } from './../td-speed-restriction/enums/td-speed-types.enum';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem, ConfirmationService } from 'primeng/primeng';
import { DataService } from '../services/data.service';
import { JobTable, LocalStorageService, ReferenceCode, ReportTable } from '../services/local-storage.service';
import { ReportDataService } from '../shared/services/report-data.service';
import { UtilService } from '../util/util.service';
import { CurrentUserTable } from './../services/local-storage.service';
import { ErrorDialog } from '../model/error-dialog.model';
import { AppConstant } from '../shared/constant/constants';
import { KeyValue } from '@angular/common';
import { TDWorkType } from '../td-speed-restriction/enums/td-work-type.enum';
import { SpeedRestrictionModel } from '../td-speed-restriction/models/speed-restriction.model';
import { OperationMode } from '../shared/enums/operation-mode.enum';

const NUMERIC_ERROR_MESSAGE = 'Enter Numeric value';
const RAILTYPE_ERROR_MESSAGE = 'Make a selection.';
const SURFACETYPE_ERROR_MESSAGE = 'Make a selection.';
const PASSES_ERROR_MESSAGE = 'Enter #Passes.';
const DATE_ERROR_MESSAGE = 'Select On Duty Date-Time';
const MILE_POST_REQUIRED = 'Enter MP.';
const MILE_POST_OUTSIDE_JOB_LIMITS = 'Milepost is outside of job limits.';

@Component({
  selector: 'app-surfacing-info',
  templateUrl: './surfacing-info.component.html',
  styleUrls: ['./surfacing-info.component.scss']
})
export class SurfacingInfoComponent implements OnInit {
  operationMode: OperationMode = OperationMode.CREATE;
  totalSurfacedFeetIncludePasses: number;
  beginMp: number;
  endMp: number;
  roadMaster: string;
  onDutyTime: string = null;
  surfacingDetails: SurfacingDetail2[] = [];
  railTemp1: number = null;
  railTemp2: number = null;
  railTemp3: number = null;
  curveStacked: string = null;
  remarks: string;
  direction = 'I';
  previousDirection = 'I';
  totalSurfacedFeet: number;
  surfaceTypeOptions: SelectItem[];
  railTypeOptions: SelectItem[];
  projectReports: any[] = [];

  curveStackedOptions: SelectItem[];
  directionList: SelectItem[];
  reportObj: object = {};
  prepopulateData: object[] = [];
  rlCode: string;
  maxDate: string;
  minDate: string;
  projectData: string;
  projectId: number;
  job: number;
  jobData: any;
  jobId: number;
  jobStartMp: number;
  jobEndMp: number;
  jobType: string;
  prefix: string;
  trackType: string;
  subdivision: string;
  division: string;
  formId: number;
  errorCount: number;
  reportNotvalid: boolean;
  dateTimeWarningMessage: string;
  dateTimeWarning: boolean;
  railTemp1ErrorMessage: string;
  railTemp1Error: boolean;
  railTemp2ErrorMessage: string;
  railTemp2Error: boolean;
  railTemp3ErrorMessage: string;
  railTemp3Error: boolean;
  remarkslimiterror: boolean;
  remarkslimiterrorMessage: string;
  beginMPError: string[] = [];
  endMPError: string[] = [];
  detailsInvalid: boolean;
  validationErrors: boolean;
  beginMPOutOfLimitError: boolean[] = [];
  endMPOutOfLimitError: boolean[] = [];
  endMPNextWholeMpError: boolean[] = [];
  surfacingPassesError: string[] = [];
  surfacingRailTypeError: string[] = [];
  surfacingTypeError: string[] = [];
  errorDialogData: ErrorDialog = {
    dialogHeader: AppConstant.DUPLICATE_REPORT_TITLE,
    dialogBody: AppConstant.DUPLICATE_REPORT_MSG
  };
  showErrorComponent: Boolean = false;
  //   beingMp: any;
  twoDecimalPattern = new RegExp('(^(0{0,1}|([1-9][0-9]*))(.[0-9]{1,2})?$)');

  private readonly OUT_OF_FACE = 'OF';
  private teamName: string;
  reportobject: any;
  subSequentReport: boolean;
  private lastestReport: any;
  tdFormGroup: FormGroup;
  tdSpeedRestrictionTypes: Array<KeyValue<TDWorkType, Array<KeyValue<number, string>>>> = [];
  isFormSubmitted: boolean;
  trackDisturbances: Array<SpeedRestrictionModel>;

  constructor(
    protected formBuilder: FormBuilder,
    protected ds: DataService,
    protected ls: LocalStorageService,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected utilService: UtilService,
    protected reportDataService: ReportDataService,
    protected acknowledgeService: ConfirmationService
  ) { }

  ngOnInit() {
    this.intializeReport();
    this.getJobObj();
    this.loadReport();
    this.getTimeLimits();
    this.caculateSurfacedFeet(-1, -1, -1);
    this.getProjectReports();
  }

  intializeReport() {
    this.curveStackedOptions = [];
    this.curveStackedOptions.push({ label: 'Yes', value: 'Y' });
    this.curveStackedOptions.push({ label: 'No', value: 'N' });
    this.directionList = [
      { label: 'Increasing', value: 'I' },
      { label: 'Decreasing', value: 'D' }
    ];

    this.railTypeOptions = [];
    this.railTypeOptions.push({ label: 'CWR', value: 'C' });
    this.railTypeOptions.push({ label: '	Jointed Rail', value: 'J' });

    this.ls.getCurrentUser('SYSTEM').then((c: CurrentUserTable) => {
      this.teamName = c.teamName;
    });

    this.ls.getAllRefereces().then((refs: Array<ReferenceCode>) => {
      const refList = refs;
      for (const p of refList) {
        if (p.refType === 'SFT') {
          this.surfaceTypeOptions = this.utilService.hack(p.refObj, 'surfacingRailType');
          console.log(this.surfaceTypeOptions);
        }
        if (p.refType === TDSpeedType.SURFACING) {
          this.tdSpeedRestrictionTypes = [...this.utilService.getWorkAndSpeedRestrictionTypes(p.refObj, TDSpeedType.SURFACING)];
        }
      }
    });
    this.loadParam();
  }

  async getMilePostFromPreviousReport() {
    let milePost;
    if (this.direction === 'I') {
      milePost = this.jobStartMp;
    } else {
      milePost = this.jobEndMp;
    }

    await this.ls.getJobReports(Number(this.jobId)).then((reportList: Array<ReportTable>) => {
      // *******  this list is to get the previous report
      if (reportList.length > 0) {
        reportList.sort((x, y) => {
          return x.reportObj['workday'] < y.reportObj['workday'] ? -1 : 1;
        });
        let reportcount = reportList.length;
        let previousReport = false;
        while (!previousReport) {
          this.reportobject = JSON.parse(JSON.stringify(reportList[reportcount - 1].reportObj));
          if (this.reportobject.formId !== this.formId && this.reportobject.noProductionCode === null) {
            previousReport = true;
          } else if (reportcount === 1) {
            previousReport = true;
          } else {
            reportcount--;
          }
        }

        for (const p of reportList) {
          const js: any = JSON.parse(JSON.stringify(p.reportObj));
          // ******* by using reportstatus we are checking all reports submitted or not
          if ((js.status === 'S' || js.formId > 0) && js.jobType === 'SO' && js.noProductionCode === null) {
            this.subSequentReport = true;
            if (js.formId === this.formId && reportList.length === 1) {
              this.subSequentReport = false;
            }
          }
        }
        console.log('previous report', this.reportobject);
        if (this.subSequentReport) {
          this.direction = this.reportobject.direction;
          this.previousDirection = this.direction;
          if (this.reportobject.direction === 'I') {
            milePost = this.reportobject.highMp;
          } else {
            milePost = this.reportobject.lowMp;
          }
        }
      }
    });
    return milePost;
  }

  getProjectReports(): void {
    this.ls.getProjectReports(Number(this.projectId)).then((reportList: Array<ReportTable>) => {
      for (const p of reportList) {
        const js: any = JSON.parse(JSON.stringify(p.reportObj));
        this.projectReports.push(js);
      }
    });
  }

  get editMode() {
    return this.operationMode === OperationMode.EDIT;
  }

  async directionChange() {
    if (this.direction !== this.previousDirection) {
      this.previousDirection = this.direction;
      this.surfacingDetails = [];
      let prepopulationBegin = 0;
      if (this.reportobject) {
        if (this.direction === 'I') {
          prepopulationBegin = this.reportobject.highMp;
        } else {
          prepopulationBegin = this.reportobject.lowMp;
        }
        if (this.subSequentReport && this.reportobject.direction !== this.direction) {
          this.beginMp = null;
          this.surfacingDetails.push(new SurfacingDetail2(this.trackType, null, null, null, null, null, null, this.OUT_OF_FACE));
        } else {
          this.beginMp = prepopulationBegin;
          this.surfacingDetails.push(
            new SurfacingDetail2(this.trackType, null, prepopulationBegin, null, null, null, null, this.OUT_OF_FACE)
          );
        }
      } else {
        if (this.direction === 'I') {
          prepopulationBegin = this.jobStartMp;
        } else {
          prepopulationBegin = this.jobEndMp;
        }

        this.beginMp = prepopulationBegin;
        this.surfacingDetails.push(
          new SurfacingDetail2(this.trackType, null, prepopulationBegin, null, null, null, null, this.OUT_OF_FACE)
        );
      }
    }
  }

  async addSurfacingDetail(index: number) {
    let b: boolean;
    await this.validateSurfacingDetails().then(res => {
      b = res;
    });
    if (
      this.surfacingDetails[index].beginMP !== null &&
      this.surfacingDetails[index].endMP !== null &&
      this.surfacingDetails[index].surfaced !== null &&
      this.surfacingDetails[index].passes !== null &&
      this.surfacingDetails[index].railType !== null &&
      !b
    ) {
      this.detailsInvalid = false;
      setTimeout(() => {
        if (this.trackType === 'YD') {
          this.surfacingDetails.push(new SurfacingDetail2(null, null, null, null, null, null, null, this.OUT_OF_FACE));
        } else {
          const nextBeginMp = this.validateBeginMp(this.surfacingDetails[index].endMP);
          this.surfacingDetails.push(new SurfacingDetail2(this.trackType, null, nextBeginMp, null, null, null, null, this.OUT_OF_FACE));
        }
      }, 200);
    } else {
      this.detailsInvalid = true;
    }
  }

  validateBeginMp(previousEndMp) {
    let prepopulateStartMp = null;
    if (Number(this.utilService.validStartEndMp(this.direction, this.jobId)) === previousEndMp) {
      prepopulateStartMp = null;
    } else {
      console.log(Number(this.utilService.validStartEndMp(this.direction, this.jobId)));
      prepopulateStartMp = previousEndMp;
    }
    if (this.direction === 'I' && previousEndMp === this.jobEndMp + 2) {
      prepopulateStartMp = null;
    } else if (this.direction === 'D' && previousEndMp === this.jobStartMp - 2) {
      prepopulateStartMp = null;
    }
    return prepopulateStartMp;
  }

  async validateSurfacingDetails() {
    this.beginMPOutOfLimitError = [];
    this.endMPOutOfLimitError = [];
    this.endMPNextWholeMpError = [];
    this.surfacingPassesError = [];
    this.surfacingRailTypeError = [];
    this.surfacingTypeError = [];
    this.beginMPError = [];
    this.endMPError = [];
    for (let i = 0; i < this.surfacingDetails.length; i++) {
      await this.validateBeginMilePost(i, this.surfacingDetails[i].beginMP, this.surfacingDetails[i].trackName);
      await this.validateEndMilePost(
        i,
        this.surfacingDetails[i].beginMP,
        this.surfacingDetails[i].endMP,
        this.surfacingDetails[i].trackName
      );
      await this.validatePasses(i, this.surfacingDetails[i].passes);
      await this.validateRailType(i, this.surfacingDetails[i].railType);
      await this.validateSurfaceType(i, this.surfacingDetails[i].surfaceType);
    }
    if (
      this.beginMPOutOfLimitError.indexOf(true) > -1 ||
      this.endMPOutOfLimitError.indexOf(true) > -1 ||
      this.endMPNextWholeMpError.indexOf(true) > -1 ||
      this.surfacingPassesError.indexOf(PASSES_ERROR_MESSAGE) > -1 ||
      this.surfacingPassesError.indexOf('Should be > 0') > -1 ||
      this.surfacingPassesError.indexOf('Should be < 10') > -1 ||
      this.surfacingPassesError.indexOf(NUMERIC_ERROR_MESSAGE) > -1 ||
      this.surfacingRailTypeError.indexOf(RAILTYPE_ERROR_MESSAGE) > -1 ||
      this.surfacingTypeError.indexOf(SURFACETYPE_ERROR_MESSAGE) > -1
    ) {
      this.validationErrors = true;
    } else {
      this.validationErrors = false;
    }
    console.log('surfacing validation errors exists : ', this.validationErrors);
    return this.validationErrors;
  }

  validatePasses(index: number, passes: number) {
    if (passes === null) {
      this.surfacingPassesError[index] = PASSES_ERROR_MESSAGE;
    } else if (passes != null && !this.utilService.isFloat(passes)) {
      this.surfacingPassesError[index] = NUMERIC_ERROR_MESSAGE;
      this.validationErrors = true;
    } else if (passes > 9) {
      this.surfacingPassesError[index] = 'Should be < 10';
      this.validationErrors = true;
    } else if (passes <= 0) {
      this.surfacingPassesError[index] = 'Should be > 0';
      this.validationErrors = true;
    } else {
      this.surfacingPassesError[index] = null;
    }
  }

  validateRailType(index: number, railType: string) {
    if (railType === null) {
      this.surfacingRailTypeError[index] = RAILTYPE_ERROR_MESSAGE;
    } else {
      this.surfacingRailTypeError[index] = null;
    }
  }

  validateSurfaceType(index: number, type: string) {
    if (type === null) {
      this.surfacingTypeError[index] = SURFACETYPE_ERROR_MESSAGE;
    } else {
      this.surfacingTypeError[index] = null;
    }
  }

  onChangeBeginMP(beginMP: number, endMP: number, trackName: string, index: number) {
    this.validateBeginMilePost(index, beginMP, trackName);
    this.caculateSurfacedFeet(beginMP, endMP, index);
  }

  onChangeEndMP(beginMP: number, endMP: number, trackName: string, index: number) {
    this.validateEndMilePost(index, beginMP, endMP, trackName);
    this.caculateSurfacedFeet(beginMP, endMP, index);
  }

  removeSurfacingDetail(index: number) {
    if (this.surfacingDetails.length > 1) {
      if (index > -1) {
        this.surfacingDetails.splice(index, 1);
        this.beginMPOutOfLimitError.splice(index, 1);
        this.endMPOutOfLimitError.splice(index, 1);
        this.endMPNextWholeMpError.splice(index, 1);
      }
    }
  }

  validateBeginMilePost(index: number, beginMp: number, trackName: string) {
    this.beginMPOutOfLimitError[index] = false;
    this.beginMPError[index] = ' ';

    if (beginMp === null) {
      this.beginMPError[index] = MILE_POST_REQUIRED;
      this.surfacingDetails[index].surfaced = null;
      this.beginMPOutOfLimitError[index] = true;
    } else {
      console.log(this.twoDecimalPattern.test(beginMp.toString()));
      if (!this.twoDecimalPattern.test(beginMp.toString())) {
        this.beginMPOutOfLimitError[index] = true;
        this.beginMPError[index] = '2 decimal places allowed';
      } else {
        this.beginMPOutOfLimitError[index] = this.utilService.verifyMPOutSideJobLimit(
          this.jobStartMp,
          this.jobEndMp,
          beginMp,
          this.direction,
          false
        );

        this.utilService.isMilePostValid(beginMp, this.jobId).then((isValid: boolean) => {
          console.log('is valid', isValid);
          if (!isValid) {
            this.beginMPOutOfLimitError[index] = true;
            this.beginMPError[index] = 'Invalid Milepost';
            this.surfacingDetails[index].surfaced = null;
          } else {
            if (this.beginMPOutOfLimitError[index]) {
              this.beginMPError[index] = MILE_POST_OUTSIDE_JOB_LIMITS;
              this.surfacingDetails[index].surfaced = null;
            }
          }
          if (!this.beginMPOutOfLimitError[index]) {
            this.surfacingDetails.forEach((details, surfacingIndex) => {
              if (index !== surfacingIndex && ((details.beginMP < beginMp && beginMp < details.endMP) || details.beginMP === beginMp)) {
                this.beginMPOutOfLimitError[index] = true;
                this.beginMPError[index] = 'Mile post Overlap';
              }
            });
          }
        });
      }
    }
  }

  validateEndMilePost(index: number, beginMp: number, endMp: number, trackName: string) {
    this.endMPError[index] = '';
    this.endMPOutOfLimitError[index] = false;
    if (endMp === null) {
      this.endMPError[index] = MILE_POST_REQUIRED;
      this.surfacingDetails[index].surfaced = null;
      this.endMPOutOfLimitError[index] = true;
      this.endMPNextWholeMpError[index] = false;
    } else if (!this.twoDecimalPattern.test(endMp.toString())) {
      this.endMPError[index] = '2 decimal places allowed';
      this.endMPOutOfLimitError[index] = true;
    } else if ((this.direction === 'I' && beginMp >= endMp) || (this.direction === 'D' && beginMp <= endMp)) {
      this.endMPError[index] = 'Should be' + (this.direction === 'I' ? ' > Begin MP' : ' < Begin MP');
      this.surfacingDetails[index].surfaced = null;
      this.endMPOutOfLimitError[index] = true;
      this.endMPNextWholeMpError[index] = false;
    } else {
      this.endMPOutOfLimitError[index] = this.utilService.verifyMPOutSideJobLimit(
        this.jobStartMp,
        this.jobEndMp,
        endMp,
        this.direction,
        false
      );

      this.utilService.isMilePostValid(endMp, this.jobId).then((isValid: boolean) => {
        if (!isValid) {
          this.endMPOutOfLimitError[index] = true;
          this.endMPError[index] = 'Invalid Milepost';
        } else {
          if (this.endMPOutOfLimitError[index]) {
            this.endMPError[index] = MILE_POST_OUTSIDE_JOB_LIMITS;
            this.surfacingDetails[index].surfaced = null;
          }
        }
        if (!this.endMPOutOfLimitError[index]) {
          this.surfacingDetails.forEach((details, surfacingIndex) => {
            if (index !== surfacingIndex && ((details.beginMP < endMp && endMp < details.endMP) || details.endMP === endMp)) {
              this.endMPOutOfLimitError[index] = true;
              this.endMPError[index] = 'Mile post Overlap';
            }
          });
        }
      });
    }
  }

  async caculateSurfacedFeet(beginMP: number, endMP: number, index: number) {
    if (
      beginMP !== null &&
      endMP !== null &&
      !this.beginMPOutOfLimitError[index] &&
      !this.endMPOutOfLimitError[index] &&
      !this.endMPNextWholeMpError[index] &&
      this.surfacingDetails[index]
    ) {
      let surfaced = 0;

      await this.utilService.getSurfacedFeet(beginMP, endMP, this.jobId).then((val: any) => {
        console.log('feet calculation ', val);
        surfaced = Number(val[0]);
      });

      this.surfacingDetails[index].surfaced = Math.round(Number(surfaced));
    } else if (beginMP === -1 && endMP === -1 && index === -1) {
      await this.utilService.getSurfacedFeet(beginMP, endMP, this.jobId);
    }
  }

  async loadReport() {
    this.getReportMethod().then(async response => {
      console.log('response', response);
      if (response) {
        this.checkReportOperationMode(response);
        this.trackDisturbances = response.reportObj.trackDisturbanceDetails;
        this.railTemp1 = response.reportObj.railTemp1;
        this.railTemp2 = response.reportObj.railTemp2;
        this.railTemp3 = response.reportObj.railTemp3;

        this.curveStacked = response.reportObj.curvesStaked;
        this.remarks = response.reportObj.remarks;

        this.onDutyTime = this.utilService.convertTimeStampTOdate(response.reportObj.workday);
        if (response.reportObj.surfacingDetails != null) {
          this.surfacingDetails = response.reportObj.surfacingDetails;
          this.direction = response.reportObj.direction;
          this.previousDirection = this.direction;
        } else {
          await this.getMilePostFromPreviousReport().then(v => {
            this.beginMp = v;
          });
        }
        this.prepopulateData = response.reportObj;
      } else {
        this.prepopulateData = null;
        if (this.onDutyTime !== undefined || this.onDutyTime !== null) {
          this.onDutyDateTimeChange(null);
        }
        await this.getMilePostFromPreviousReport().then(v => {
          this.beginMp = v;
        });
        this.surfacingDetails.push(new SurfacingDetail2(this.trackType, null, this.beginMp, null, null, null, null, this.OUT_OF_FACE));
      }
    });
  }

  protected getReportMethod() {
    return this.ds.getReport(Number(this.formId));
  }

  protected checkReportOperationMode(response: any) {
    if (response.reportObj.status === 'D') {
      this.operationMode = OperationMode.CREATE;
    } else if (response.reportObj.status === 'S' || response.reportObj.formId > 0) {
      this.operationMode = OperationMode.VIEW;
    }
  }

  pushdata() {
    this.reportObj['teamName'] = this.teamName;
    this.reportObj['workTypeCode'] = 'SU';
    this.reportObj['railTemp1'] = this.railTemp1;
    this.reportObj['railTemp2'] = this.railTemp2;
    this.reportObj['railTemp3'] = this.railTemp3;
    this.reportObj['curvesStaked'] = this.curveStacked;
    if (this.onDutyTime !== null && this.onDutyTime !== '') {
      this.reportObj['workday'] = new Date(this.onDutyTime).getTime();
    }
    this.reportObj['jobId'] = this.jobId;
    this.reportObj['projectId'] = this.projectData;
    this.reportObj['formId'] = this.formId;
    this.reportObj['rl'] = this.rlCode;
    this.reportObj['roadMaster'] = this.roadMaster;
    this.reportObj['remarks'] = this.remarks;
    this.reportObj['jobType'] = this.jobType;
    this.reportObj['prefix'] = this.prefix;
    this.reportObj['trackType'] = this.trackType;
    this.reportObj['divisionCode'] = this.division;
    this.reportObj['subdivCode'] = this.subdivision;
    this.reportObj['direction'] = this.direction;
    this.reportObj['totalUnitInstalled'] = this.totalSurfacedFeetIncludePasses;
    this.reportObj['totalSurfacedFeet'] = this.totalSurfacedFeet;
    this.reportObj['surfacingDetails'] = this.surfacingDetails;
    // if(this.direction === 'I') {
    this.reportObj['lowMp'] = this.beginMp;
    this.reportObj['highMp'] = this.endMp;
    // }else{
    //     this.reportObj['lowMp'] = this.endMp;
    //     this.reportObj['highMp'] = this.beginMp;
    // }
    this.reportObj['teamName'] = this.ds.team;
    this.reportObj['noProductionCode'] = null;

    console.log('this.reportObject', this.reportObj);

    if (this.lastestReport) {
      this.reportObj['delayIssuesRemark'] = this.lastestReport.delayIssuesRemark;
      this.reportObj['issuesDesc'] = this.lastestReport.issuesDesc;
      this.reportObj['tieUpLocation'] = this.lastestReport.tieUpLocation;
      this.reportObj['nextDayLocation'] = this.lastestReport.nextDayLocation;
      this.reportObj['plannedStart'] = this.lastestReport.plannedStart;
      this.reportObj['plannedEnd'] = this.lastestReport.plannedEnd;
      this.reportObj['actualEmployeesCount'] = this.lastestReport.actualEmployeesCount;
      this.reportObj['greenSignalTime'] = this.lastestReport.greenSignalTime;
      this.reportObj['fullBlockReceived'] = this.lastestReport.fullBlockReceived;
      this.reportObj['trackReturnLate'] = this.lastestReport.trackReturnLate;
      this.reportObj['trackReturnLateRemarks'] = this.lastestReport.trackReturnLateRemarks;
      if (this.lastestReport.reportWindows) {
        const modifiedWindows: any[] = [];
        this.lastestReport.reportWindows.forEach((window: any, i: any) => {
          window.unitsInstalled = null;
          window.unitPerHour = null;
          modifiedWindows.push(window);
        });
        this.reportObj['reportWindows'] = modifiedWindows;
      }
    } else if (!this.lastestReport) {
      if (!this.editMode) {
        this.ls.getNotes(this.teamName).then((notesData: any) => {
          if (notesData && notesData.notesObj && this.formId < 0) {
            this.reportObj['plannedStart'] = notesData.notesObj.plannedStart ?
              notesData.notesObj.plannedStart : null;
            this.reportObj['plannedEnd'] = notesData.notesObj.plannedEnd ? notesData.notesObj.plannedEnd : null;
            this.reportObj['greenSignalTime'] = notesData.notesObj.greenSignalTime ? notesData.notesObj.greenSignalTime :
              null;
            this.reportObj['reportWindows'] = notesData.notesObj.reportWindows;
          }
        });
      }
    }
    // console.log('this.prepopulateData', this.prepopulateData.length);
    if (this.prepopulateData) {
      Object.assign(this.prepopulateData, this.reportObj);
      this.reportObj = this.prepopulateData;
    }
  }

  async getJobObj() {
    await this.ds.getJobInfo(Number(this.jobId)).then(async (job1: JobTable) => {
      this.jobData = JSON.parse(JSON.stringify(job1.jobObj));
      console.log('Job Info::: ', this.jobData);
      if (this.jobData.roadmasters.length > 0) {
        const roadMasterRl = this.jobData.roadmasters[0].rl_c;
            let supervisor = null;
            supervisor = await this.utilService.mapRoadMasterDetails(roadMasterRl);
           this.roadMaster = supervisor ? supervisor.userObj.fullName : 'System Error';
           this.rlCode = this.jobData.roadmasters[0].rl_c;
      } else {
        this.roadMaster = 'System Error';
      }
      this.jobStartMp = this.jobData.startMP;
      this.jobEndMp = this.jobData.endMP;
      this.jobType = this.jobData.jobTypeCode;
      this.prefix = this.jobData.prefix;
      this.trackType = this.jobData.trackType;
      this.division = this.jobData.division;
      this.subdivision = this.jobData.subdivision;
      this.initializeSpeedRestrictionTD();
    });
  }

  private initializeSpeedRestrictionTD() {
    this.tdFormGroup = this.formBuilder.group({
      speedRestrictions: new FormArray([])
    });
  }

  async validateField(field, event?: any) {
    this.errorCount = 0;
    this.reportNotvalid = false;
    console.log('validate field', field);
    if (field === 'remarks') {
      this.remarkslimiterror = this.utilService.fieldLimit(this.remarks, 200);
      if (this.remarkslimiterror) {
        this.remarkslimiterrorMessage = 'Should be < 200 characters';
      }
    }
    if (field === 'railTemp1') {
      this.railTemp1ErrorMessage = '';
      this.railTemp1Error = false;
      if (this.railTemp1 || this.railTemp1 === 0) {
        this.railTemp1ErrorMessage = this.railTempValid(this.railTemp1, 1);
        if (this.railTemp1ErrorMessage !== '') {
          this.railTemp1Error = true;
        } else {
          this.railTemp1Error = false;
        }
      }
    }
    if (field === 'railTemp2') {
      this.railTemp2ErrorMessage = '';
      this.railTemp2Error = false;
      if (this.railTemp2 || this.railTemp2 === 0) {
        this.railTemp2ErrorMessage = this.railTempValid(this.railTemp2, 2);
        if (this.railTemp2ErrorMessage !== '') {
          this.railTemp2Error = true;
        } else {
          this.railTemp2Error = false;
        }
      }
    }
    if (field === 'railTemp3') {
      this.railTemp3ErrorMessage = '';
      this.railTemp3Error = false;
      if (this.railTemp3 || this.railTemp3 === 0) {
        this.railTemp3ErrorMessage = this.railTempValid(this.railTemp3, 3);
        if (this.railTemp3ErrorMessage !== '') {
          this.railTemp3Error = true;
        } else {
          this.railTemp3Error = false;
        }
      }
    }
    if (field === 'onDutyTime') {
      this.getTimeLimits();
      if (this.onDutyTime) {
        this.dateTimeWarning = false;
        this.dateTimeWarningMessage = '';
        if (this.checkReportsOnDutyDate()) {
          this.dateTimeWarning = true;
          this.dateTimeWarningMessage = 'Rest day report exists on this day.';
        }
      } else {
        this.dateTimeWarning = true;
        this.dateTimeWarningMessage = DATE_ERROR_MESSAGE;
      }
      if (!this.dateTimeWarning && event) {
        this.onDutyDateTimeChange(event);
      }
    }
  }

  private railTempValid(railTempVar: number, index: number): string {
    let errorMessage: string;
    if (railTempVar || railTempVar === 0) {
      console.log('railTempValid', railTempVar);
      if (!this.utilService.isFloat(railTempVar)) {
        errorMessage = NUMERIC_ERROR_MESSAGE;
      } else if (railTempVar > 150) {
        errorMessage = 'Temp ' + index + ' cannot be > 150';
      } else if (railTempVar < 1) {
        errorMessage = 'Temp ' + index + ' should be > 0';
      } else {
        errorMessage = '';
      }
    } else {
      errorMessage = 'Enter Temp ' + index;
    }
    return errorMessage;
  }

  async calculateTotalSurfacedFeetIncludePasses() {
    this.totalSurfacedFeetIncludePasses = 0;
    for (let index = 0; index < this.surfacingDetails.length; index++) {
      const e = this.surfacingDetails[index];
      await this.utilService
        .getSurfacedFeet(Math.min(...[e.beginMP, e.endMP]), Math.max(...[e.beginMP, e.endMP]), this.jobId)
        .then((val: any) => {
          console.log('feet calculation ', val);
          this.totalSurfacedFeetIncludePasses = this.totalSurfacedFeetIncludePasses + Number(val[0] * e.passes);
        });
    }
  }

  checkReportsOnDutyDate(): boolean {
    let match = false;
    const onDuty = this.onDutyTime.split('T')[0];
    for (const report of this.projectReports) {
      const compare = this.convertEpochToDate(report.workday);
      let reportType = '';
      if (report.hasOwnProperty('noProductionCode')) {
        if (report['noProductionCode'] === 'RST') {
          reportType = 'REST';
        } else {
          reportType = 'NO PRODUCTION';
        }
      } else {
        reportType = 'PRODUCTION';
      }
      if (reportType === 'REST' && compare === onDuty && this.teamName === report.teamName) {
        match = true;
        break;
      }
    }
    return match;
  }

  async calculateTotalSurfacedFeet() {
    let p: number[][];
    p = [];
    const orderedDetails: SurfacingDetail2[] = [];
    orderedDetails.push(...this.surfacingDetails);
    orderedDetails.sort((a: SurfacingDetail2, b: SurfacingDetail2) => a.beginMP - b.beginMP);
    let c = 0;
    orderedDetails.forEach((value1, i1) => {
      if (value1.beginMP !== null && value1.endMP !== null) {
        if (i1 === 0) {
          p[c] = [value1.beginMP, value1.endMP];
        } else if (
          (value1.beginMP >= Math.min(...p[c]) && value1.beginMP <= Math.max(...p[c])) ||
          (value1.endMP >= Math.min(...p[c]) && value1.endMP <= Math.max(...p[c]))
        ) {
          p[c].push(value1.beginMP);
          p[c].push(value1.endMP);
        } else {
          c++;
          p[c] = [value1.beginMP, value1.endMP];
        }
      }
    });
    this.totalSurfacedFeet = 0;
    for (let i = 0; i < p.length; i++) {
      await this.utilService.getSurfacedFeet(Math.min(...p[i]), Math.max(...p[i]), this.jobId).then((val: any) => {
        console.log('feet calculation ', val);
        this.totalSurfacedFeet = this.totalSurfacedFeet + Number(val[0]);
      });
      if (i === 0) {
        this.beginMp = Math.min(...p[i]);
      }
      if (i === p.length - 1) {
        this.endMp = Math.max(...p[i]);
      }
    }
    if (this.beginMp === undefined || this.beginMp === null || this.endMp === null || this.endMp === undefined) {
      if (this.surfacingDetails[0] !== undefined) {
        if (this.direction === 'I') {
          this.endMp = null;
          this.beginMp = this.surfacingDetails[0].beginMP;
        } else {
          this.beginMp = null;
          this.endMp = this.surfacingDetails[0].beginMP;
        }
      }
    }
    // if (p.length === 0 && !this.subSequentReport) {
    //     if (this.direction === 'I') {
    //         this.beginMp = this.jobStartMp;
    //     } else {
    //         this.endMp = this.jobEndMp;
    //     }
    //
    // }
    console.log('total surfaced feet', this.totalSurfacedFeet, this.beginMp, this.endMp);
  }

  getTimeLimits() {
    this.maxDate = this.utilService.getCurrentDateTime();
    this.minDate = this.utilService.getMinDateTime();
  }

  loadParam(): void {
    this.activeRoute.params.subscribe(params => {
      this.projectData = params.project;
      if (this.projectData) {
        this.projectId = Number(this.projectData.split('-')[0]);
      }
      this.jobId = params.job;
      this.formId = params.form;
    });
  }

  onDutyDateTimeChange(event: any) {
    const onDutyWorkDate = this.onDutyTime ? new Date(this.onDutyTime) : new Date();
    this.reportDataService.getLatestProdReportFromAllProjects(this.teamName, onDutyWorkDate.getTime()).subscribe(
      lastestReportObj => {
        this.lastestReport = lastestReportObj;
        if (lastestReportObj === null || lastestReportObj === undefined) {
          this.ls.getCurrentUser('SYSTEM').then(data => {
            this.teamName = data ? data.teamName : null;
            this.ls.getNotes(this.teamName).then((notesData: any) => {
              if (notesData && notesData.notesObj.onDutyTime) {
                const notes = JSON.parse(JSON.stringify(notesData));
                this.onDutyTime = this.onDutyTime ? this.onDutyTime : notes.notesObj.onDutyTime;
              }
            });
          });
          this.remarks = null;
          this.railTemp1 = null;
          this.railTemp2 = null;
          this.railTemp3 = null;
        } else {
          this.remarks = lastestReportObj.remarks;
          this.railTemp1 = lastestReportObj.railTemp1;
          this.railTemp2 = lastestReportObj.railTemp2;
          this.railTemp3 = lastestReportObj.railTemp3;
          // event null is for prepopulating first time
          if (event === null) {
            this.onDutyTime = this.utilService.convertTimeStampTOdate(lastestReportObj.workday);
          }
        }
      },
      error => { },
      () => { }
    );
  }

  /** While user click back button and save the data into IndexDB * */
  async backClick(event) {
    if (this.viewMode) {
      this.router.navigate([`/job_view/${this.projectData}`]);
    } else {
      // let todayDate = new Date();
      // let timestampdate = new Date(todayDate).toISOString();
      // let currenttime = new Date(timestampdate);
      if (!this.onDutyTime) {
        this.onDutyTime = this.utilService.getCurrentDateTime();
      }
      // if(this.surfaceTeamIn === null){
      //     this.surfaceTeamIn = this.utilService.getCurrentDateTime();
      // }
      await this.calculateTotalSurfacedFeet();
      await this.calculateTotalSurfacedFeetIncludePasses();
      await this.pushdata();
      this.ds
        .saveDraftReport(JSON.stringify(this.reportObj))
        .then(response => {
          this.router.navigate([`/job_view/${this.projectData}`]);
        })
        .catch(reason => {
          console.log(reason);
        });
    }
  }
  get viewMode() {
    return this.operationMode === OperationMode.VIEW;
  }

  protected getTdValue() {
    return this.tdFormGroup.get('speedRestrictions').value;
  }

  async formSubmit(event) {
    if (this.viewMode) {
      this.router.navigate([`/planned_actual/${this.projectData}/${this.jobId}/${this.formId}`]);
    } else {
      let trackDisturbancesInvalid = false;
      if (this.tdFormGroup.invalid) {
        this.isFormSubmitted = true;
        trackDisturbancesInvalid = true;
      } else {
        this.reportObj['trackDisturbanceDetails'] = this.getTdValue();
      }
      const fieldsToValidate = ['onDutyTime', 'railTemp1', 'railTemp2', 'railTemp3'];
      await this.calculateTotalSurfacedFeet();
      await this.calculateTotalSurfacedFeetIncludePasses();
      this.pushdata();
      for (let index = 0; index < fieldsToValidate.length; index++) {
        await this.validateField(fieldsToValidate[index]);
      }
      this.errorCount = 0;
      this.reportNotvalid = false;
      await this.validateSurfacingDetails();
      setTimeout(() => {
        if (
          this.railTemp1Error ||
          this.railTemp2Error ||
          this.railTemp3Error ||
          this.dateTimeWarning ||
          this.validationErrors ||
          this.beginMPOutOfLimitError.includes(true) ||
          this.endMPOutOfLimitError.includes(true) ||
          trackDisturbancesInvalid
        ) {
          this.reportNotvalid = true;
        }
        if (!this.reportNotvalid) {
          this.reportObj['railSide'] = null;
          this.reportDataService.validateDuplicateReports(this.reportObj).then((isDuplicate: boolean) => {
            this.showErrorComponent = isDuplicate;
            if (!isDuplicate) {
              this.saveReportToIndexDbMethod(this.reportObj)
                .then(response => {
                  console.log(response);
                  this.navigateToPlannedActual();
                })
                .catch(reason => {
                  console.log(reason);
                });
            }
          });
        }
      }, 500);
    }
  }

  protected saveReportToIndexDbMethod(repObj: any) {
    return this.ds.saveDraftReport(JSON.stringify(repObj));
  }

  protected navigateToPlannedActual() {
    this.router.navigate([`/planned_actual/${this.projectId}/${this.jobId}/${this.formId}`]);
  }

  onErrorDialogClose(): void {
    this.showErrorComponent = false;
  }

  // formEditNext($event) {
  //     this.router.navigate([`/planned_actual/${this.projectData}/${this.jobId}/${this.formId}`]);

  // }

  onKeydown(event: KeyboardEvent) {
    console.log(event);
    const focusableElements: Element[] = [].slice.call(document.querySelectorAll('input,select,button,textarea'));
    const currentInput = event.srcElement;
    const currentIndex = focusableElements.indexOf(currentInput);
    if (event.keyCode === 13) {
      this.focus(focusableElements, currentIndex + 1);
    }
  }

  focus(elements, index) {
    if (elements[index]) {
      elements[index].focus();
    }
  }

  convertEpochToDate(epoch: number) {
    if (epoch == null) {
      return null;
    }
    let date = new Date(epoch).toLocaleDateString().replace(/\//g, '-');
    const dateArray = date.split('-');
    date = dateArray[2] + '-' + this.convertSingleDigitToDouble(dateArray[0]) + '-' + this.convertSingleDigitToDouble(dateArray[1]);
    return date;
  }

  convertSingleDigitToDouble(value: string): string {
    if (value.length === 1) {
      return '0' + value;
    } else {
      return value;
    }
  }
}

export class SurfacingDetail2 {
  trackType: string;
  trackName: string;
  beginMP: number;
  endMP: number;
  surfaced: number;
  passes: number;
  railType: string;
  surfaceType: string;

  constructor(
    trackType: string,
    trackName: string,
    beginMP: number,
    endMP: number,
    surfaced: number,
    passes: number,
    railType: string,
    surfaceType: string
  ) {
    this.trackType = trackType;
    this.trackName = trackName;
    this.beginMP = beginMP;
    this.endMP = endMP;
    this.surfaced = surfaced;
    this.passes = passes;
    this.railType = railType;
    this.surfaceType = surfaceType;
  }
}
