import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Turnout } from '../../model/turnout.model';
import { SelectItem } from 'primeng/primeng';

@Component({
  selector: 'app-turnout-card',
  templateUrl: './turnout-card.component.html',
  styleUrls: ['./turnout-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TurnoutCardComponent implements OnInit {
  @Input() turnoutForm: FormGroup;
  @Input() formSubmitAttempt: boolean;
  @Input() viewMode: boolean;
  @Input() editMode: boolean;
  public turnout: Turnout;
  yesOrNoOptions: SelectItem[] = [];

  @Output() remove: EventEmitter<number> = new EventEmitter();
  constructor() {
    this.yesOrNoOptions.push({ label: 'Yes', value: true });
    this.yesOrNoOptions.push({ label: 'No', value: false });
  }

  ngOnInit() {
    this.turnout = this.turnoutForm.value;
  }

  removeTurnout() {
    this.remove.emit(this.turnout.turnoutId);
  }

  get isSurfaced() {
    return this.turnoutForm.get('isSurfaced');
  }

  get feetPerPass() {
    return this.turnoutForm.get('feetPerPass');
  }

  get noOfPasses() {
    return this.turnoutForm.get('noOfPasses');
  }

  get disabled() {
    if (!this.viewMode) {
      return false;
    }
    return true;
  }

  getFieldInvalidClass(field: string) {
    return {
      'field-error': this.isFieldValid(field)
    };
  }

  isFieldValid(field: string) {
    return (
      (!this.turnoutForm.get(field).valid && this.turnoutForm.get(field).touched) ||
      (this.turnoutForm.get(field).untouched && this.formSubmitAttempt && !this.turnoutForm.get(field).valid)
    );
  }
}
