export class Report {
  projectId: number;
  jobId: number;
  formId: number;
  workday: any;
  teamName: string;
  status: string;
  workTypeCode: string;
  noProductionCode: string;
  totalSurfacedFeet: number;
  totalTiesInstalled: number;
  railLength: number;

  constructor(
    projectId: number,
    jobId: number,
    formId: number,
    workday: any,
    teamName: string,
    status: string,
    workTypeCode: string,
    noProductionCode: string,
    totalSurfacedFeet: number,
    totalTiesInstalled: number,
    railLength: number
  ) {
    this.projectId = projectId;
    this.jobId = jobId;
    this.formId = formId;
    this.workday = workday;
    this.teamName = teamName;
    this.status = status;
    this.workTypeCode = workTypeCode;
    this.noProductionCode = noProductionCode;
    this.totalSurfacedFeet = totalSurfacedFeet;
    this.totalTiesInstalled = totalTiesInstalled;
    this.railLength = railLength;
  }
}
