import { SwitchTieDetailsModel } from './../switch-tie-report/models/switch-tie-report-details.model';
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
  NgZone
} from '@angular/core';
import { DataService } from '../services/data.service';
import {
  EventBusService,
  EventType,
  STWEventData
} from '../services/eventbus.service';
import {
  UserTable,
  ProjectTable,
  SyncTable,
  CurrentUserTable
} from '../services/local-storage.service';
import { Router } from '@angular/router';
import {
  LocalStorageService,
  ReportTable
} from '../services/local-storage.service';
import { ConfirmationService } from 'primeng/primeng';
import { Subscription } from 'rxjs';
import { UtilService } from '../util/util.service';
import { FlexObject } from 'csx-flex-ng';

export enum FormStatus {
  SUBMITTED = <any>'S',
  DRAFT = <any>'D'
}

@Component({
  selector: 'app-sync',
  templateUrl: './sync.component.html',
  styleUrls: ['./sync.component.scss']
})
export class SyncComponent implements OnInit, OnDestroy {
  fullSync: boolean;
  @Input()
  display = false;
  @Input()
  forceAutoSync: boolean;
  @Input()
  showConfirmation: boolean;
  @Input()
  startAutosync: boolean;
  @Output()
  syncCompleted: EventEmitter<boolean> = new EventEmitter();
  @Output()
  displayChange = new EventEmitter();
  showProgress = false;
  progress_value = 0;
  countPassed = 0;
  countFailed = 0;
  syncJobData = [];
  submittedReports = [];
  cutRailObj: any[];
  unSyncReports = 0;
  syncStatus: string;
  syncComplete = false;
  last_sync_date;
  hide_not_synced = true;
  hide_unsynced_reports = true;
  syncLog = [];
  workTypeDesc: string;
  showErrorComponent = false;
  syncLogDialog = false;
  syncLabel;
  team: string;
  TD_STATUS_FAILED = 'Track Disturbance cannot be generated.';
  TD_STATUS_SUCCESS = 'Track Disturbance generated.';
  td_error_count = 0;
  td_responseMsg: string;
  length: number;
  tieTrack: string;
  surfaceTrack: string;
  surfaceMilepost: any = [];
  sideSurfaceMilepost: any = [];
  direction: string;
  yardMilepost: any = [];
  private tdRequired = false;
  private proxySub: Subscription;
  private failureSub: Subscription;
  private refList =
    'RWT,SRT,SPT,RMT,REQ,RGR,TPG,TNR,TBD,TZZ,TRK,DTP,RSE,XSR,STW,TTT,SFT,INCHES,FRACTIONS,TEAM,TCR,TDS,SST,TSS,STT,RTT';
  private locationList = 'DIV,SUB';
  private draftIds = [];

  private projectToSync = [];
  private jobsToSync = [];
  private deletedReportsToSync = [];
  private refDataToSync = false;
  protected Flex: FlexObject;
  syncBtnDisabled = false;
  isNotesPresent = false;

  ngOnInit() {
    this.Flex = FlexObject.getInstance();
    this.syncLabel = 'Start Sync';
    this.proxySub = this.eventBus
      .getEventBus(EventType.PROXY_CHANGE_EVENT)
      .subscribe(
        response => {
          this.team = this.dataService.team;
          this.displayChange.emit(true);
          this.ngZone.run(() => {
            //  this.showDialog();
          });
        },
        e => {
          console.log(e);
          this.displayChange.emit(true);
          this.showDialog();
        },
        () => { }
      );
    this.failureSub = this.eventBus
      .getEventBus(EventType.NOTIFY_FAILURE_EVENT)
      .subscribe(
        response => {
          console.log(response);
          if (this.showProgress) {
            this.syncComplete = true;
            this.showProgress = false;
            this.syncLabel = 'Sync Again';
            this.syncLog.push(response);
            this.updateProgress(0);
          }
        },
        e => {
          console.log(e);
        },
        () => { }
      );

    this.ls.getCurrentUser('SYSTEM').then(data => {
      const teamName = data ? data.teamName : null;
      this.ls.getNotes(teamName).then(noteData => {
        if (noteData !== null && noteData !== undefined) {
          this.isNotesPresent = true;
        } else {
          this.isNotesPresent = false;
        }
      });
    });
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    if (this.forceAutoSync || this.startAutosync) {
      this.init().then(() => {
        this.confirm();
      });
    }
  }

  ngOnDestroy() {
    if (this.displayChange) {
      this.displayChange.unsubscribe();
    }
    if (this.proxySub) {
      this.proxySub.unsubscribe();
    }
    if (this.failureSub) {
      this.failureSub.unsubscribe();
    }
  }

  constructor(
    private dataService: DataService,
    private ls: LocalStorageService,
    private eventBus: EventBusService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private ngZone: NgZone,
    public Util: UtilService
  ) { }

  setProgressStyles() {
    const styles = {
      width: this.progress_value + '%'
    };
    return styles;
  }

  confirm() {
    this.syncBtnDisabled = true;
    // Remove all reports from edited reports table
    this.dataService.deleteAllEditDraftReports();
    if (this.draftIds.length > 0 && !this.showConfirmation) {
      this.confirmationService.confirm({
        message:
          'Un-Submitted Draft Reports will be deleted upon Sync.<br></br> ' +
          '<center> Do you still want to proceed with sync?</center>',
        accept: () => {
          if (navigator.onLine) {
            this.showErrorComponent = false;
            this.syncNew();
          } else {
            this.showErrorComponent = true;
          }
        },
        reject: () => {
          // this.onClose();
        }
      });
    } else {
      if (navigator.onLine) {
        this.showErrorComponent = false;
        this.syncNew();
      } else {
        this.showErrorComponent = true;
      }
    }
  }

  onShow() {
    this.init()
      .then(() => {
        console.log('sync init');
      })
      .catch(e => {
        console.error(e);
      });
  }

  syncNew() {
    if (this.fullSync || this.forceAutoSync) {
      this.startFullSync();
    } else {
      this.startDeltaSync();
    }
  }

  async getTeamInfo() {
    await this.ls
      .getCurrentUser('SYSTEM')
      .then((user: CurrentUserTable) => {
        if (user) {
          this.team = user.teamName;
        }
      })
      .catch(e => {
        console.error(e);
        this.team = null;
      });
    this.ngZone.run(() => {
      this.team = this.team;
    });
  }

  async getlastUpdateTS() {
    await this.ls
      .getUser(this.Flex.user.id)
      .then((user: UserTable) => {
        if (user) {
          // this.team = JSON.parse(JSON.stringify(user.userObj)).teamName;
          const syncDateObj = JSON.parse(JSON.stringify(user.userObj))
            .lastSyncDate;
          if (syncDateObj !== null && syncDateObj !== undefined) {
            this.last_sync_date =
              'Last synced on ' + new Date(syncDateObj).toLocaleString();
            this.hide_not_synced = false;
          } else {
            this.last_sync_date = '';
            this.hide_not_synced = false;
          }
        }
      })
      .catch(e => {
        console.error(e);
        this.last_sync_date = 'Not Synced';
        this.hide_not_synced = true;
      });
    this.ngZone.run(() => {
      this.last_sync_date = this.last_sync_date;
      this.hide_not_synced = this.hide_not_synced;
    });
  }

  async getLastSyncInfo() {
    await this.ls
      .getSyncInfo(this.Flex.user.id)
      .then((syncData: SyncTable) => {
        if (syncData && syncData.syncObj) {
          this.fullSync = false;
          const syncJson = JSON.parse(JSON.stringify(syncData.syncObj));
          this.refDataToSync = syncJson.refData;
          if (
            syncJson.projectList !== null &&
            syncJson.projectList !== undefined
          ) {
            this.projectToSync = syncJson.projectList.split(',');
          }
          if (syncJson.jobList !== null && syncJson.jobList !== undefined) {
            this.jobsToSync = syncJson.jobList.split(',');
          }
          if (
            syncJson.deletedReportsList !== null &&
            syncJson.deletedReportsList !== undefined
          ) {
            this.deletedReportsToSync = syncJson.deletedReportsList.split(',');
          }
        } else {
          this.fullSync = true;
        }
      })
      .catch(e => {
        console.error(e);
        this.fullSync = true;
      });

    if (this.fullSync) {
      this.last_sync_date = 'Not Synced';
      this.hide_not_synced = true;
    }
  }

  async init(): Promise<any> {
    this.syncLabel = 'Start Sync';

    await this.getTeamInfo();

    await this.getlastUpdateTS();
    await this.updateUnsynceReports();
    await this.getLastSyncInfo();

    await this.ls.getAllProjects().then((plist: Array<ProjectTable>) => {
      if (plist.length === 0) {
        this.fullSync = true;
      }
    });

    await this.ls
      .getReportsForSync()
      .then((reportList: Array<ReportTable>) => {
        this.syncJobData = [];
        this.draftIds = [];
        if (reportList) {
          let index = 0;
          for (const p of reportList) {
            p.reportObj['reportType'] = this.Util.determineReportType(
              JSON.parse(JSON.stringify(p.reportObj))
            );
            this.length = 0;
            this.surfaceMilepost = [];
            this.tieTrack = null;
            this.surfaceTrack = null;
            if (p.reportObj['workTypeCode'] === 'RA') {
              p.reportObj['workTypeDesc'] = 'Rail';
            }
            if (p.reportObj['workTypeCode'] === 'TS') {
              p.reportObj['workTypeDesc'] = 'Tie & Surface';
              if (p.reportObj['reportType'] === this.Util.production) {
                const tieProgress = this.mapToArry(
                  p.reportObj['tsProgress']['tieProgress']
                );
                for (let i = 0; i < tieProgress.length; i++) {
                  if (tieProgress[i]['trackName'] === 'MAINLINE TRACKS') {
                    this.tieTrack = tieProgress[i]['trackName'];
                  }
                }
                const surfaceProgress = this.mapToArry(
                  p.reportObj['tsProgress']['surfaceProgress']
                );
                for (let j = 0; j < surfaceProgress.length; j++) {
                  if (surfaceProgress[j]['trackName'] === 'MAINLINE TRACKS') {
                    this.surfaceTrack = surfaceProgress[j]['trackName'];
                    this.surfaceMilepost.push(
                      surfaceProgress[j].beginMP,
                      surfaceProgress[j].endMP
                    );
                    this.direction = surfaceProgress[j].direction;
                  }
                  const group = {};
                  if (
                    surfaceProgress &&
                    surfaceProgress[j]['trackName'] !== 'MAINLINE TRACKS'
                  ) {
                    const track = surfaceProgress[j]['trackName'];
                    if (!group[track]) {
                      group[track] = [];
                    }
                    group[track].push(
                      surfaceProgress[j]['beginMP'],
                      surfaceProgress[j]['endMP']
                    );
                  }
                  this.sideSurfaceMilepost = [];
                  for (const track in group) {
                    if (group) {
                      this.sideSurfaceMilepost.push({
                        track: track,
                        milepost: group[track]
                      });
                    }
                  }
                  this.sideSurfaceMilepost.forEach((item, index1) => {
                    if (item.track === surfaceProgress[j]['trackName']) {
                      this.sideSurfaceMilepost[index1]['beginMp'] = Math.min(
                        ...item['milepost']
                      );
                      this.sideSurfaceMilepost[index1]['endMp'] = Math.max(
                        ...item['milepost']
                      );
                      this.sideSurfaceMilepost[index1]['direction'] =
                        surfaceProgress[j]['direction'];
                    }
                  });
                  if (
                    this.tieTrack === null &&
                    surfaceProgress[j].workedOn === 'S'
                  ) {
                    p.reportObj['direction'] = this.direction;
                    if (p.reportObj['trackType'] === 'SD') {
                      p.reportObj[
                        'direction'
                      ] = this.sideSurfaceMilepost[0].direction;
                      p.reportObj[
                        'lowMp'
                      ] = this.sideSurfaceMilepost[0].beginMp;
                      p.reportObj['highMp'] = this.sideSurfaceMilepost[0].endMp;
                    } else if (p.reportObj['trackType'] === 'YD') {
                      this.sideSurfaceMilepost.forEach((item, index2) => {
                        this.yardMilepost.push(item.beginMp, item.endMp);
                        this.direction = item.direction;
                      });
                      p.reportObj['direction'] = this.direction;
                      p.reportObj['lowMp'] = Math.min(...this.yardMilepost);
                      if (p.reportObj['lowMp'] === Infinity) {
                        p.reportObj['lowMp'] = 0;
                      }
                      p.reportObj['highMp'] = Math.max(...this.yardMilepost);
                      if (p.reportObj['highMp'] === -Infinity) {
                        p.reportObj['highMp'] = 0;
                      }
                    } else {
                      p.reportObj['lowMp'] = Math.min(...this.surfaceMilepost);
                      if (p.reportObj['lowMp'] === Infinity) {
                        p.reportObj['lowMp'] = 0;
                      }
                      p.reportObj['highMp'] = Math.max(...this.surfaceMilepost);
                      if (p.reportObj['highMp'] === -Infinity) {
                        p.reportObj['highMp'] = 0;
                      }
                    }
                  }
                }
              }
            }
            if (p.reportObj['workTypeCode'] === 'SU') {
              p.reportObj['workTypeDesc'] = 'Surfacing';
            }
            if (p.reportObj['workTypeCode'] === 'XX') {
              p.reportObj['workTypeDesc'] = 'Switch Tie & Surfacing';
            }
            console.log('reportdata', p.reportObj);
            const s = JSON.parse(JSON.stringify(p.reportObj)).status;
            if (s !== null && s === FormStatus.SUBMITTED) {
              this.syncJobData.push(p.reportObj);
            }
            if (s === FormStatus.DRAFT) {
              this.draftIds.push(Number(p.formId));
            } else {
              p.reportObj['reportType'] = this.Util.determineReportType(
                p.reportObj
              );
            }
            index++;
          }
          if (this.syncJobData.length > 0) {
            this.hide_unsynced_reports = false;
            this.syncJobData = this.syncJobData.sort((r1, r2) => {
              const workDate1 = new Date(r1.workday);
              const workDate2 = new Date(r2.workday);
              if (
                workDate1.getDate() === workDate2.getDate() &&
                workDate1.getMonth() === workDate2.getMonth()
              ) {
                return r1.submittedReportDate - r2.submittedReportDate < 0
                  ? -1
                  : 1;
              } else {
                return r1.workday - r2.workday < 0 ? -1 : 1;
              }
            });
          }
        }
      })
      .catch(err => {
        console.error(err);
      });
    this.ngZone.run(() => {
      this.unSyncReports = this.syncJobData.length;
      this.last_sync_date = this.last_sync_date;
    });
    return new Promise((resolve, reject) => {
      this.unSyncReports = this.syncJobData.length;
      resolve(this.syncJobData);
    });
  }

  showDialog() {
    this.display = true;
    this.team = this.dataService.team;
    console.log(this.dataService.team);
  }

  onClose() {
    this.showProgress = false;
    this.countPassed = 0;
    this.countFailed = 0;
    this.progress_value = 0;
    this.display = false;
    this.syncComplete = false;
    this.ngZone.run(() => {
      setTimeout(() => this.displayChange.emit(false), 500);
    });
    this.eventBus.fireEvent(
      EventType.PROJECTS_UPDATED_EVENT,
      new STWEventData('data', () => '')
    );
    this.router.navigate([this.router.url]);
  }

  mapToArry(objectMap: any) {
    let arrayOut: any = [];

    if (objectMap != null) {
      arrayOut = Object.keys(objectMap).map(k => objectMap[k]);
    }
    return arrayOut;
  }

  reportSummaryHeader(sd) {
    let outString = ''; // display nothing for sidetracks only

    if (sd.lowMp != null && sd.highMp != null) {
      // does not display for sidetracks only
      if (sd.direction === 'I') {
        outString =
          sd.prefix + ' ' + sd.lowMp + ' - ' + sd.highMp + ' ' + sd.trackType;
      } else if (sd.direction === 'D') {
        outString =
          sd.prefix + ' ' + sd.highMp + ' - ' + sd.lowMp + ' ' + sd.trackType;
      }
    }

    return outString;
  }

  getTieCount(tieDetails: SwitchTieDetailsModel) {
    let tieCount = 0;
    for (const tie of tieDetails.switchTiesSizes) {
      if (tie.noOfTies && tie.noOfTies > 0) {
        tieCount += tie.noOfTies;
      }
    }
    return tieCount;
  }

  reportDisplayTrackInfo(sd) {
    let trackString = sd.trackType; // display track type for mainline
    if (
      (sd.trackType === 'SD' && sd.trackName != null) ||
      sd.trackType === 'YD' ||
      sd.trackName !== 'MAINLINE TRACKS'
    ) {
      trackString = sd.trackName;
    }
    return trackString;
  }

  async processSubmittedReports() {
    this.syncStatus = 'started production data push.';
    this.dataService.deleteAllNotes();
    for (const sd of this.syncJobData) {
      this.syncStatus = 'processing ' + sd.formId;
      this.syncLog.push(this.syncStatus);
      console.log('processing json :::', sd);
      const sObj = JSON.parse(JSON.stringify(sd));
      if (!sObj.teamName) {
        sd['teamName'] = this.team;
      }
      if (
        Number(sd.formId) > 0 ||
        sd.teamName === null ||
        sd.teamName === undefined ||
        sd.teamName === ''
      ) {
        this.syncLog.push(
          'cannont process the form',
          sd.formId,
          'or team is null'
        );
      } else {
        await this.dataService
          .processReport(sd, sd.formId)
          .then(res => {
            console.log(res);
            this.syncLog.push(res);
            if (res.errorName === 'SUCCESS') {
              this.countPassed++;
              this.unSyncReports--;
              this.submittedReports.push(Number(sd.formId));
              sd.formId = res.result;
              sd.submitStatus = 'Synced';
              if (sd.noProductionCode === null) {
                this.createTrackDist(sd);
              }
            } else if (res.errorName === 'DUP') {
              this.countPassed++;
              this.unSyncReports--;
              sd.submitStatus = 'Duplicate report. Will be deleted.';
              this.dataService.deleteReport(Number(sd.formId)).then(() => {
                console.log('Duplicate report with form id ' + sd.formId + ' deleted successfully.');
              });
            } else {
              sd.submitStatus = 'Failed';
              this.countFailed++;
            }
          })
          .catch(e => {
            console.log('eerrr');
            sd.submitStatus = 'Failed';
            this.countFailed++;
            this.syncLog.push(sd.formId + ' report submit failed');
            this.syncLog.push(e);
          });

      }
    }
    this.syncStatus = 'completed production data push.';
    this.syncLog.push(this.syncStatus);
  }

  onDialogClose(event) {
    this.display = event;
  }

  async createTrackDist(sd: any) {
    if (Number(sd.formId) > 0) {
      sd.formId = Number(sd.formId);
      await this.dataService
        .getTrackDisturbance(sd)
        .then(res => {
          console.log('TrackDisturbance response::', res);
          if (res.errorName === 'SUCCESS') {
            this.processTDResult(sd, res);
          } else {
            for (const cr of sd.reportCutRail) {
              cr.trackDisturbaceStatus =
                'Technical Error: Failed To create Track Disturbance.';
              if (!cr.isWorkSkipped) {
                this.td_error_count++;
              }
            }
          }
        })
        .catch(e => {
          for (const cr of sd.reportCutRail) {
            cr.trackDisturbaceStatus =
              'Technical Error: Failed To create Track Disturbance.';
            if (!cr.isWorkSkipped) {
              this.td_error_count++;
            }
          }
          this.syncLog.push(sd.formId + ' TrackDisturbance failed');
          this.syncLog.push(e);
        });
    }
  }

  async processTDResult(sd: any, res: any) {
    const syncTDStausObject = [];
    for (const td of res.result) {
      if (sd.workTypeCode === 'TS' || sd.workTypeCode === 'XX') {
        syncTDStausObject.push({ message: td.responseMsg, status: td.outErrorNum === 0 ? true : false });
        sd.syncTDStausObject = syncTDStausObject;
      } else if (sd.workTypeCode === 'SU') {
        syncTDStausObject.push({ message: td.responseMsg, status: td.outErrorNum === 0 ? true : false });
        sd.syncTDStausObject = syncTDStausObject;
      } else if (sd.workTypeCode === 'RA') {
        sd.reportCutRail[td.sequenceI].trackDisturbaceStatus = td.responseMsg;
        sd.reportCutRail[td.sequenceI].tdGenerated =
          td.outErrorNum === 0 ? true : false;
      }
    }
  }

  async deleteReports() {
    this.syncLog.push('Start deleting the draft and processed reports reports');
    const deleteList = this.draftIds.concat(this.submittedReports);
    console.log(deleteList);
    if (deleteList.length > 0) {
      await this.ls.removeBulkReport(deleteList).then(
        val => {
          this.syncLog.push(
            'Deleted draft and processed reports from local storage',
            val
          );
          this.draftIds = [];
        },
        reason => {
          this.syncLog.push('Deleting the draft reports failed', reason);
        }
      );
    }
  }

  async startDeltaSync() {
    try {
      console.log('delta sync::::');
      this.ngZone.run(() => {
        this.progress_value = 0;
        this.syncComplete = false;
        this.syncStatus = 'sync process started';
        this.syncLog.push(this.syncStatus);
        this.showProgress = true;
        this.display = true;
        this.td_error_count = 0;
        this.countFailed = 0;
        this.countPassed = 0;
      });

      if (this.syncLabel === 'Sync Again') {
        await this.updateUnsynceReports();
      }

      try {
        await this.processSubmittedReports();
        this.updateProgress(10);
      } catch (e) {
        this.syncLog.push('processing reports error' + e);
        console.error(e);
      }

      if (!this.syncComplete) {
        try {
          await this.deleteReports();
          this.updateProgress(10);
        } catch (e) {
          this.syncLog.push('delete reports error' + e);
          console.error(e);
        }
        this.syncStatus = 'started maintenence data pull.';
        this.syncLog.push(this.syncStatus);

        try {
          if (
            this.dataService.stwUserRole !== 'Admin' &&
            this.dataService.stwUserRole !== 'STW Manager' &&
            this.dataService.stwUserRole !== 'Developer'
          ) {
            await this.dataService
              .getSyncInfo(this.Flex.user.id, 'N', this.team)
              .then(() => {
                this.getLastSyncInfo();
              })
              .catch(e => console.error(e));
          }
        } catch (e) {
          this.syncLog.push('last sync error::' + e);
          this.ngZone.run(() => {
            this.progress_value = this.progress_value;
          });
        }

        await this.dataService
          .getProjectsSync()
          .then(p => {
            console.log(p);
          })
          .catch(e => {
            this.syncLog.push('projects pull error' + e);
          });

        if (this.refDataToSync) {
          try {
            await this.dataService.getRefData(this.refList);
            this.updateProgress(5);
          } catch (e) {
            this.syncLog.push('reference data error' + e);
            console.error(e);
            this.updateProgress(5);
          }
        } else {
          this.updateProgress(5);
        }
        // try {
        //     await this.dataService.getLocationData(this.locationList);
        //     this.updateProgress(5);
        // } catch (e) {
        //     this.syncLog.push('location data error' + e);
        //     console.error(e);
        //     this.updateProgress(5);
        // }
        this.updateProgress(5);
        this.syncStatus = 'completed  maintenence data pull.';
        this.syncLog.push(this.syncStatus);
        this.syncStatus = 'started project data pull.';
        this.syncLog.push(this.syncStatus);

        this.syncStatus = 'started job/reports/roadcrossing data pull.';
        this.syncLog.push(this.syncStatus);
        this.updateProgress(10);
        let count = 0;
        const current_progress = this.progress_value;

        if (this.projectToSync.length === 0) {
          this.updateProgress(40);
        }

        for (const project of this.projectToSync) {
          count++;
          this.syncLog.push('project :::', project);

          // await this.dataService.getProjectSync(project)
          //     .then(p => {
          //         console.log(p);
          //     })
          //     .catch(e => {
          //         this.syncLog.push('projects pull error' + e);
          //     });

          try {
            await this.dataService.getJobsSync(Number(project)).catch(e => {
              this.syncLog.push('jobs pull error' + e);
            });
            this.syncLog.push(project + ' jobs synced');
          } catch (e) {
            this.syncLog.push(project + ' jobs error::' + e);
            console.error(e);
          }

          try {
            await this.dataService.getReportsSync(Number(project)).catch(e => {
              this.syncLog.push('reports pull error' + e);
            });
            this.syncLog.push(project + ' reports synced');
          } catch (e) {
            this.syncLog.push(project + ' reports error::' + e);
            console.error(e);
          }

          const p_val = Math.round((count / this.projectToSync.length) * 40);
          this.ngZone.run(() => {
            this.progress_value = current_progress + p_val;
          });
        }
        if (this.deletedReportsToSync.length > 0) {
          await this.ls.removeBulkReport(this.deletedReportsToSync).then(
            val => {
              this.syncLog.push(
                'Deleted draft and processed reports from local storage',
                val
              );
            },
            reason => {
              this.syncLog.push('Deleting the draft reports failed', reason);
            }
          );
        }

        try {
          if (
            this.dataService.stwUserRole !== 'Admin' &&
            this.dataService.stwUserRole !== 'STW Manager' &&
            this.dataService.stwUserRole !== 'Developer'
          ) {
            await this.dataService
              .getSyncInfo(this.Flex.user.id, 'Y', this.team)
              .then(() => {
                this.getLastSyncInfo();
              })
              .catch(e => console.error(e));
          }
        } catch (e) {
          this.syncLog.push('last sync error::' + e);
          this.ngZone.run(() => {
            this.progress_value = this.progress_value;
          });
        }

        this.updateProgress(20);
      }

      this.syncStatus = 'completed sync process.';
      this.eventBus.fireEvent(
        EventType.PROJECTS_UPDATED_EVENT,
        new STWEventData('data', () => '')
      );
      this.syncLog.push(this.syncStatus);
      this.syncStatus = '';
      this.syncComplete = true;
      this.hide_not_synced = false;
      this.deviceLastSyncUpdate();
    } catch (e) {
      console.error(e);
      this.syncLog.push(' sync failed error::' + e);
      this.syncComplete = true;
      this.syncStatus = e.message;
      this.syncLabel = 'Sync Again';
      this.updateProgress(0);
    } finally {
      this.syncLabel = 'Sync Again';
      await this.dataService
        .getUser(this.Flex.user.id)
        .then(() => {
          this.getlastUpdateTS();
        })
        .catch(e => console.error(e));

      this.updateProgress(0);
      this.syncBtnDisabled = false;
    }
  }

  async startFullSync() {
    this.Flex.heartbeat()
      .then(() => {
        this.completeFullSync();
      })
      .catch(() => {
        alert(
          'Your session has been timed out due to inactivity. For security, please login again.'
        );
      });
  }

  async completeFullSync() {
    try {
      console.log('full sync::::');
      this.ngZone.run(() => {
        this.progress_value = 0;
        this.syncComplete = false;
        this.syncStatus = 'sync process started';
        this.syncLog.push(this.syncStatus);
        this.showProgress = true;
        this.display = true;
        this.td_error_count = 0;
        this.countFailed = 0;
        this.countPassed = 0;
      });

      try {
        await this.processSubmittedReports();
        this.updateProgress(10);
      } catch (e) {
        this.syncLog.push('processing reports error' + e);
        console.error(e);
      }

      if (!this.syncComplete) {
        try {
          await this.deleteReports();
          this.updateProgress(10);
        } catch (e) {
          this.syncLog.push('delete reports error' + e);
          console.error(e);
        }
        this.syncStatus = 'started maintenence data pull.';
        this.syncLog.push(this.syncStatus);
        try {
          await this.dataService.getRefData(this.refList);
          this.updateProgress(5);
        } catch (e) {
          this.syncLog.push('reference data error' + e);
          console.error(e);
        }
        try {
          await this.dataService.getLocationData(this.locationList);
          this.updateProgress(5);
        } catch (e) {
          this.syncLog.push('location data error' + e);
          console.error(e);
        }

        try {
          await this.dataService.getSupervisory();
        } catch (e) {
          this.syncLog.push('Supervisory data error' + e);
          console.log(e);
        }

        this.syncStatus = 'completed  maintenence data pull.';
        this.syncLog.push(this.syncStatus);
        this.syncStatus = 'started project data pull.';
        this.syncLog.push(this.syncStatus);
        let projects: number[];

        await this.dataService
          .getProjectsSync()
          .then(p => {
            projects = p;
          })
          .catch(e => {
            this.syncLog.push('projects pull error' + e);
          });


        this.syncStatus = 'started job/reports/roadcrossing data pull.';
        this.syncLog.push(this.syncStatus);
        this.updateProgress(10);
        let count = 0;
        const current_progress = this.progress_value;
        for (const project of projects) {
          count++;
          this.syncLog.push('project :::', project);
          try {
            await this.dataService.getJobsSync(project).catch(e => {
              this.syncLog.push('jobs pull error' + e);
            });
            this.syncLog.push(project + ' jobs synced');
          } catch (e) {
            this.syncLog.push(project + ' jobs error::' + e);
            console.error(e);
          }
          try {
            await this.dataService.getReportsSync(project).catch(e => {
              this.syncLog.push('reports pull error' + e);
            });
            this.syncLog.push(project + ' reports synced');
          } catch (e) {
            this.syncLog.push(project + ' reports error::' + e);
            console.error(e);
          }

          try {
            await this.dataService.getRoadCrossingDataSync(project).catch(e => {
              this.syncLog.push('roadcrossings pull error' + e);
            });
            this.syncLog.push(project + ' roadcrossings synced');
          } catch (e) {
            this.syncLog.push(project + ' roadcrossing error::' + e);
            console.error(e);
          }
          try {
            await this.dataService.getTurnoutDataSync(project).catch(e => {
              this.syncLog.push('turnouts pull error' + e);
            });
            this.syncLog.push(project + ' turnouts synced');
          } catch (e) {
            this.syncLog.push(project + ' turnouts error::' + e);
            console.error(e);
          }

          const p_val = Math.round((count / projects.length) * 60);
          this.ngZone.run(() => {
            this.progress_value = current_progress + p_val;
          });
        }
        try {
          if (
            this.dataService.stwUserRole !== 'Admin' &&
            this.dataService.stwUserRole !== 'STW Manager' &&
            this.dataService.stwUserRole !== 'Developer'
          ) {
            await this.dataService
              .getSyncInfo(this.Flex.user.id, 'Y', this.team)
              .then(() => {
                this.getLastSyncInfo();
              })
              .catch(e => console.error(e));
          }
        } catch (e) {
          this.syncComplete = true;
          this.syncLog.push('last sync error::' + e);
          this.ngZone.run(() => {
            this.progress_value = this.progress_value;
          });
        }
      }

      if (this.syncLabel === 'Sync Again') {
        await this.updateUnsynceReports();
      }

      this.syncStatus = 'completed sync process.';
      this.eventBus.fireEvent(
        EventType.PROJECTS_UPDATED_EVENT,
        new STWEventData('data', () => '')
      );
      this.syncLog.push(this.syncStatus);
      this.syncStatus = '';
      this.syncComplete = true;
      this.hide_not_synced = false;
      this.deviceLastSyncUpdate();
    } catch (e) {
      console.error(e);
      this.syncLog.push(' sync failed error::' + e);
      this.syncComplete = true;
      this.syncStatus = e.message;
      this.syncLabel = 'Sync Again';
      this.updateProgress(0);
    } finally {
      this.syncLabel = 'Sync Again';
      await this.dataService
        .getUser(this.Flex.user.id)
        .then(() => {
          this.getlastUpdateTS();
        })
        .catch(e => console.error(e));

      this.updateProgress(0);
      this.syncBtnDisabled = false;
    }
  }

  deviceLastSyncUpdate() {
    this.ls.getCurrentUser('SYSTEM').then((userObject: CurrentUserTable) => {
      userObject.deviceSpecificLastSync = new Date().getTime();
      this.ls.addCurrentUser(userObject);
      this.syncCompleted.emit(true);
    });
  }

  async updateUnsynceReports() {
    await this.ls
      .getReportsForSync()
      .then((reportList: Array<ReportTable>) => {
        console.log(reportList);
        this.syncJobData = [];
        this.draftIds = [];
        if (reportList) {
          for (const p of reportList) {
            if (p.reportObj['workTypeCode'] === 'RA') {
              p.reportObj['workTypeDesc'] = 'Rail';
            } else if (p.reportObj['workTypeCode'] === 'TS') {
              p.reportObj['workTypeDesc'] = 'Tie & Surface';
            } else if (p.reportObj['workTypeCode'] === 'SU') {
              p.reportObj['workTypeDesc'] = 'Surfacing';
            } else if (p.reportObj['workTypeCode'] === 'XX') {
              p.reportObj['workTypeDesc'] = 'Switch Tie & Surfacing';
            }
            const s = JSON.parse(JSON.stringify(p.reportObj)).status;
            if (s !== null && s === FormStatus.SUBMITTED) {
              this.syncJobData.push(p.reportObj);
            }
            if (s === FormStatus.DRAFT) {
              this.draftIds.push(Number(p.formId));
            }
          }
          if (this.syncJobData.length > 0) {
            this.hide_unsynced_reports = false;
          }
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  updateProgress(n: number) {
    this.ngZone.run(() => {
      if (!this.syncComplete) {
        this.progress_value = this.progress_value + n;
      } else {
        this.showProgress = false;
      }
    });
  }

  viewLog() {
    this.syncLogDialog = true;
  }
}
