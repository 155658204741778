import { OperationMode } from './../shared/enums/operation-mode.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { JobTable, ProjectTable } from '../services/local-storage.service';

import { DataService } from '../services/data.service';
import { ReferencePipe } from '../pipes/reference.pipe';
import { WorkType } from '../enums/work-type.enum';
import { AppConstant } from '../shared/constant/constants';

@Component({
  selector: 'app-report-header',
  templateUrl: './report-header.component.html',
  styleUrls: ['./report-header.component.scss']
})
export class ReportHeaderComponent implements OnInit, OnChanges {
  @Input() operationMode?: OperationMode;
  @Input() displayEditRestrictionBanner?: boolean;
  @Output()
  backclick = new EventEmitter();
  projectData: string = null;
  jobData: String = null;
  job: number = null;
  projectId: number;
  formId: number;
  projectName: string = null;
  jobName: string = null;
  mpData: String = null;
  divisionData: string = null;
  workday;
  pageName: string;
  pageNav: any; // info
  pageNav1: any; // planned
  pageNav2: any; // road crossing
  pageNav3: any; // turnout
  pageNav4: any; // trackutil
  jobType: string;
  workType: string;
  operationModeTitle: string;
  currentRoute: string;
  modeUrl: String;
  jobDetails: any;

  @Input()
  reportType: string;
  @Input()
  hideJobHeader: boolean;
  @Input() restDayReport;
  constructor(
    private activeRoute: ActivatedRoute,
    private dataService: DataService,
    private reference: ReferencePipe,
    public router: Router
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['operationMode']) {
      this.modeUrl = this.operationMode === OperationMode.EDIT ? 'edit/' : '';
      this.setFirstPagePaths();
      this.setOtherPagePaths();
    }
  }

  ngOnInit() {
    this.currentRoute = this.router.url.split('?')[0];
    this.operationModeTitle = this.operationMode === OperationMode.EDIT ? 'EDIT REPORT' : '';
    this.reference.reloadRef();
    this.activeRoute.params.subscribe(params => {
      this.projectData = params.project;
      if (this.projectData) {
        this.projectId = Number(this.projectData.split('-')[0]);
      }
      this.jobData = params.job;
      if (this.jobData) {
        this.job = Number(this.jobData.split('-')[0]);
      }

      this.formId = params.form;
      this.dataService
        .getProjectInfo(this.projectId)
        .then((project1: ProjectTable) => {
          const po = JSON.parse(JSON.stringify(project1.projectObj));
          this.projectName = po.projectType;
          this.getWorkTypeCode(po);
        });

      this.dataService.getJobInfo(this.job).then((jobId1: JobTable) => {
        const job = JSON.parse(JSON.stringify(jobId1.jobObj));
        this.jobDetails = job;
        this.jobType = job.jobTypeCode;
        this.jobName = this.reference.transform(job.jobTypeCode, 'REQ');
        this.mpData = job.prefix + ' ' + job.startMP + '-' + job.endMP;
        this.divisionData =
          this.reference.transform(job.division, 'DIV') +
          ',' +
          this.reference.transform(job.subdivision, 'SUB');

        this.setFirstPagePaths();
      });
      this.dataService.getReport(Number(this.formId)).then((res: any) => {
        if (res) {
          this.workType = res.reportObj.workTypeCode;
          const date = new Date(res.reportObj.workday);
          this.workday =
            [
              ('00' + (date.getMonth() + 1)).slice(-2),
              ('00' + date.getDate()).slice(-2),
              date.getFullYear()
            ].join('/') +
            ' - ' +
            [
              ('00' + date.getHours()).slice(-2),
              ('00' + date.getMinutes()).slice(-2)
            ].join(':');
        }
      });
      this.setOtherPagePaths();
    });
  }

  private setFirstPagePaths() {
    if (this.jobDetails) {
      if (this.jobDetails.isSwitchTieJob) {
        this.pageNav = `/switch_tie/${this.modeUrl}${this.projectId}/${this.job}/${this.formId}`;
        this.pageName = 'SWITCH TIE & SURFACING';
      } else {
        if (this.jobDetails.jobTypeCode === 'PR' || this.jobDetails.jobTypeCode === 'OF') {
          this.pageNav = `/rail_info/${this.modeUrl}${this.projectId}/${this.job}/${this.formId}`;
          this.pageName = 'RAIL';
        } else if (this.jobDetails.jobTypeCode === 'GA' || this.jobDetails.jobTypeCode === 'CP') {
          this.pageNav = `/gauging_info/${this.modeUrl}${this.projectId}/${this.job}/${this.formId}`;
          if (this.jobDetails.jobTypeCode === 'GA') {
            this.pageName = 'GAUGING';
          } else if (this.jobDetails.jobTypeCode) {
            this.pageName = 'CONCRETE PAD';
          }
        } else if (this.jobDetails.jobTypeCode === 'TS') {
          this.pageNav = `/ts_info/${this.modeUrl}${this.projectId}/${this.job}/${this.formId}`;
          this.pageName = 'TIE & SURFACE';
        } else if (this.jobDetails.jobTypeCode === 'SO') {
          this.pageNav = `/surfacing_info/${this.modeUrl}${this.projectId}/${this.job}/${this.formId}`;
          this.pageName = 'SURFACING';
        }
      }
    }
  }

  private setOtherPagePaths() {
    this.pageNav1 = `/planned_actual/${this.modeUrl}${this.projectId}/${this.job}/${this.formId}`;
    this.pageNav2 = `/road_crossing/${this.modeUrl}${this.projectId}/${this.job}/${this.formId}`;
    this.pageNav3 = `/turnout/${this.modeUrl}${this.projectId}/${this.job}/${this.formId}`;
    this.pageNav4 = `/track_utilization/${this.modeUrl}${this.projectId}/${this.job}/${this.formId}`;
  }

  private getWorkTypeCode(po: any) {
    if (po.projectTypeCode === AppConstant.PROJECT_TYPE_CODE.SWITCHTIES) {
      this.workType = WorkType.SWITCHTIE;
    } else if (po.projectTypeCode === AppConstant.PROJECT_TYPE_CODE.TIE_SURFACING) {
      this.workType = WorkType.TIEANDSURFACE;
    } else if (po.projectTypeCode === AppConstant.PROJECT_TYPE_CODE.SURFACING) {
      this.workType = WorkType.SURFACE;
    } else {
      this.workType = WorkType.RAIL;
    }
  }

  navigateback() {
    this.backclick.emit('');
  }

  checkWorkType(): boolean {
    return (this.workType === WorkType.RAIL) || (this.workType === WorkType.SWITCHTIE) ? true : false;
  }
}
