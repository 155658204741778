import { WorkDirection } from '../enums/work-direction.enum';
import { SpeedRestrictionModel } from '../td-speed-restriction/models/speed-restriction.model';

export class ReportDetailsModel {
  projectId: string;
  jobId: string;
  formId: string;
  direction: WorkDirection;
  divisionCode: string;
  highMp: number;
  jobType: string;
  lowMp: number;
  prefix: string;
  railTemp1: number;
  railTemp2: number;
  railTemp3: number;
  remarks: string;
  rl: string;
  roadMaster: string;
  status: string;
  subdivCode: string;
  teamName: string;
  tieTrack?: string; // Will have in case multiple tracks e.g. SG, SD are part of same job
  trackType: string;
  workTypeCode: string;
  workday: number;
  trackDisturbanceDetails: Array<SpeedRestrictionModel>;
  noProductionCode?: string;
  workedOn?: string;
  totalUnitInstalled: number;
  totalTiesInstalled: number;

  constructor(reportDetails?: ReportDetailsModel) {
    if (reportDetails) {
      this.projectId = reportDetails.projectId;
      this.jobId = reportDetails.jobId;
      this.formId = reportDetails.formId;
      this.direction = reportDetails.direction;
      this.divisionCode = reportDetails.divisionCode;
      this.highMp = reportDetails.highMp;
      this.jobType = reportDetails.jobType;
      this.lowMp = reportDetails.lowMp;
      this.prefix = reportDetails.prefix;
      this.railTemp1 = reportDetails.railTemp1;
      this.railTemp2 = reportDetails.railTemp2;
      this.railTemp3 = reportDetails.railTemp3;
      this.remarks = reportDetails.remarks;
      this.rl = reportDetails.rl;
      this.roadMaster = reportDetails.roadMaster;
      this.status = reportDetails.status;
      this.subdivCode = reportDetails.subdivCode;
      this.teamName = reportDetails.teamName;
      this.tieTrack = reportDetails.tieTrack; // Will have in case multiple tracks e.g. SG, SD are part of same job
      this.trackType = reportDetails.trackType;
      this.workTypeCode = reportDetails.workTypeCode;
      this.workday = reportDetails.workday;
      this.trackDisturbanceDetails = reportDetails.trackDisturbanceDetails;
      this.noProductionCode = reportDetails.noProductionCode;
      this.workedOn = reportDetails.workedOn;
      this.totalUnitInstalled = reportDetails.totalUnitInstalled;
      this.totalTiesInstalled = reportDetails.totalTiesInstalled;
    } else {
      this.projectId = null;
      this.jobId = null;
      this.formId = null;
      this.direction = null;
      this.divisionCode = null;
      this.highMp = null;
      this.jobType = null;
      this.lowMp = null;
      this.prefix = null;
      this.railTemp1 = null;
      this.railTemp2 = null;
      this.railTemp3 = null;
      this.remarks = null;
      this.rl = null;
      this.roadMaster = null;
      this.status = null;
      this.subdivCode = null;
      this.teamName = null;
      this.tieTrack = null; // Will have in case multiple tracks e.g. SG, SD are part of same job
      this.trackType = null;
      this.workTypeCode = null;
      this.workday = null;
      this.trackDisturbanceDetails = [];
      this.noProductionCode = null;
      this.workedOn = null;
      this.totalUnitInstalled = null;
      this.totalTiesInstalled = null;
    }
  }
}
