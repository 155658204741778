import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { EventBusService, EventType } from '../../services/eventbus.service';
import { JobTable, LocalStorageService, ReportTable } from '../../services/local-storage.service';

import { DataService } from '../../services/data.service';
import { UtilService } from '../../util/util.service';
import { ReportDataService } from '../../shared/services/report-data.service';

@Component({
  selector: 'app-job-row',
  templateUrl: './job-row.component.html',
  styleUrls: ['./job-row.component.scss']
})
export class JobRowComponent implements OnInit, OnDestroy {
  jobSub: any;
  infoIndex: any;
  jobRowData: any = [];
  reportData: any[];
  latestUnsyncedReport: any;
  latestSyncedReport: any;
  currentIndex: any;
  projectData: string;
  formId: number;
  jobformId: number;
  projectId: number;
  jobId: number;
  reportstatus: boolean;
  display: boolean;
  active: boolean;
  isDialogDelete: boolean;
  totalActualJobUnits: number;
  jobType: any;
  showMenu: boolean;
  showIndex: number;
  showReportTable: boolean[] = [];
  type: string;
  showSyncComponent: boolean;
  isSwitchTieJob: boolean;
  showNotesComponent: boolean;
  onDutyDate: any;

  @Output()
  latestReportChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private dataService: DataService,
    private ls: LocalStorageService,
    private activeRoute: ActivatedRoute,
    private Route: Router,
    private eventBus: EventBusService,
    private Util: UtilService,
    private reportDataService: ReportDataService
  ) { }

  ngOnInit() {
    this.jobSub = this.eventBus.getEventBus(EventType.PROJECTS_UPDATED_EVENT).subscribe(
      response => {
        console.log('in job');
        this.getProjectJobs();
        this.dataService.getFormIdForDraft().subscribe(
          res => {
            this.jobformId = res;
          },
          err => { },
          () => { }
        );
      },
      e => {
        console.log(e);
        this.getProjectJobs();
        this.dataService.getFormIdForDraft().subscribe(
          res => {
            this.jobformId = res;
          },
          err => { },
          () => { }
        );
      },
      () => { }
    );

    this.activeRoute.params.subscribe((params: any) => {
      this.projectData = params.project;
      if (this.projectData) {
        this.projectId = Number(this.projectData.split('-')[0]);
      }
      this.jobId = params.job;
    });
    this.getProjectJobs();
    this.dataService.getFormIdForDraft().subscribe(
      res => {
        this.jobformId = res;
      },
      err => { },
      () => { }
    );
  }

  ngOnDestroy() {
    if (this.jobSub) {
      this.jobSub.unsubscribe();
    }
  }

  getProjectJobs() {
    this.ls.getProjectJobs(this.projectId).then((jobList: Array<JobTable>) => {
      this.jobRowData = [];
      for (const p of jobList) {
        const jo: any = JSON.parse(JSON.stringify(p.jobObj));
        this.ls.getJobReports(p.jobId).then((reportList: Array<ReportTable>) => {
          this.totalActualJobUnits = 0;
          this.reportData = reportList;
          let reportCount = 0;
          for (const l of reportList) {
            const js: any = JSON.parse(JSON.stringify(l.reportObj));
            if (this.Util.getFormattedDate(new Date(js.workday))
              === this.Util.getFormattedDate(new Date()) && (js.status === 'S' || js.formId > 0)) {
              this.onDutyDate = js.workday;
            }
            // ******* by using reportstatus we are checking all reports submitted or not
            if (js.status === 'S' || js.formId > 0) {
              reportCount++;
            }
            if (js.status === 'S') {
              if (jo.isSwitchTieJob) {
                if (js['totalTiesInstalled']) {
                  this.totalActualJobUnits += js['totalTiesInstalled'];
                }
              } else {
                if (js['jobType'] === 'TS') {
                  if (js['totalTiesInstalled']) {
                    this.totalActualJobUnits += js['totalTiesInstalled'];
                  }
                } else if (js['jobType'] === 'SO') {
                  if (js['totalSurfacedFeet']) {
                    this.totalActualJobUnits += js['totalSurfacedFeet'];
                  }
                } else {
                  if (js['railLength']) {
                    this.totalActualJobUnits += js['railLength'];
                    console.log('after calculation totalActualJobUnits', this.totalActualJobUnits);
                  }
                }
              }
            }
          }
          p.jobObj['reports'] = reportCount;
          if (p.jobObj['railSide'] === 'L') {
            p.jobObj['railSide'] = 'Left';
          } else if (p.jobObj['railSide'] === 'R') {
            p.jobObj['railSide'] = 'Right';
          } else if (p.jobObj['railSide'] === 'H') {
            p.jobObj['railSide'] = 'High';
          } else if (p.jobObj['railSide'] === 'W') {
            p.jobObj['railSide'] = 'Low';
          }
          if (jo.plannedUnits !== null && jo.plannedUnits !== undefined && jo.plannedUnits > 0 && reportList.length > 0) {
            const percentageJobComplete = ((jo.reportedUnits + this.totalActualJobUnits) / jo.plannedUnits) * 100;
            p.jobObj['installed'] = percentageJobComplete.toFixed(2);
          } else {
            p.jobObj['installed'] = 0;
          }
          if (p.jobObj['remarks'] === null) {
            p.jobObj['remarks'] = 'Data Not Found.';
          }
        });
        this.jobRowData.push(p.jobObj);
        this.jobRowData.sort((a, b) => {
          return a.prefix < b.prefix
            ? -1
            : a.prefix > b.prefix
              ? 1
              : a.startMP < b.startMP
                ? -1
                : a.startMP > b.startMP
                  ? 1
                  : a.endMP < b.endMP
                    ? -1
                    : a.endMP > b.endMP;
        });
      }
    });

    this.reportDataService.getLatestUnsyncedReport(this.projectId).subscribe((report: any) => {
      this.latestUnsyncedReport = report;
    });

    this.reportDataService.getLatestSyncedReport(this.projectId).subscribe((report: any) => {
      this.latestSyncedReport = report;
    });
  }

  // private noProduction = 'No Production';
  //
  // private production = 'Production';

  navigaterailinfo() {
    if (this.type === this.Util.noProduction) {
      const url = '/no_production_report/' + this.projectId + '/' + this.jobId + '/' + this.jobformId;
      this.Route.navigateByUrl(url);
    } else if (this.type === this.Util.rest) {
      const url = '/app-no-production-restday/' + this.projectId + '/' + this.jobId + '/' + this.jobformId;
      this.Route.navigateByUrl(url);
    } else if (this.type === this.Util.production) {
      if (this.isSwitchTieJob) {
        const url = '/switch_tie/' + this.projectId + '/' + this.jobId + '/' + this.jobformId;
        this.Route.navigateByUrl(url);
      } else {
        if (this.jobType === 'PR' || this.jobType === 'OF') {
          const url = '/rail_info/' + this.projectId + '/' + this.jobId + '/' + this.jobformId;
          this.Route.navigateByUrl(url);
        } else if (this.jobType === 'GA' || this.jobType === 'CP') {
          const url = '/gauging_info/' + this.projectId + '/' + this.jobId + '/' + this.jobformId;
          this.Route.navigateByUrl(url);
        } else if (this.jobType === 'TS') {
          const url = '/ts_info/' + this.projectId + '/' + this.jobId + '/' + this.jobformId;
          this.Route.navigateByUrl(url);
        } else if (this.jobType === 'SO') {
          const url = '/surfacing_info/' + this.projectId + '/' + this.jobId + '/' + this.jobformId;
          this.Route.navigateByUrl(url);
        }
      }
    }
  }

  deleteDraft() {
    this.isDialogDelete = !this.isDialogDelete;
    this.active = false;
    this.display = false;
    this.dataService.deleteReport(Number(this.jobformId)).then(response => {
      this.active = true;
      this.getProjectJobs();
    });
  }

  addreport(job) {
    this.jobId = job.job;
    this.jobType = job.jobTypeCode;
    this.isSwitchTieJob = job.isSwitchTieJob;
    this.reportstatus = false;
    this.dataService.getFormIdForDraft().subscribe(
      res => {
        this.jobformId = res;
      },
      err => { },
      () => {
        this.ls.getJobReports(this.jobId).then((reportList: Array<ReportTable>) => {
          for (const p of reportList) {
            const js: any = JSON.parse(JSON.stringify(p.reportObj));
            if (js.status === 'D') {
              this.reportstatus = true;
              this.jobformId = js.formId;
            }
          }
          if (this.reportstatus) {
            this.display = true;
          } else {
            if (this.isSwitchTieJob) {
              const url = '/switch_tie/' + this.projectId + '/' + this.jobId + '/' + this.jobformId;
              this.Route.navigateByUrl(url);
            } else {
              if (this.jobType === 'PR' || this.jobType === 'OF') {
                const url = '/rail_info/' + this.projectId + '/' + this.jobId + '/' + this.jobformId;
                this.Route.navigateByUrl(url);
              } else if (this.jobType === 'GA' || this.jobType === 'CP') {
                const url = '/gauging_info/' + this.projectId + '/' + this.jobId + '/' + this.jobformId;
                this.Route.navigateByUrl(url);
              } else if (this.jobType === 'TS') {
                const url = '/ts_info/' + this.projectId + '/' + this.jobId + '/' + this.jobformId;
                this.Route.navigateByUrl(url);
              } else if (this.jobType === 'SO') {
                const url = '/surfacing_info/' + this.projectId + '/' + this.jobId + '/' + this.jobformId;
                this.Route.navigateByUrl(url);
              }
            }
          }
        });
      }
    );
    this.checkDraftType();
  }

  addNoProduction(job) {
    this.jobId = job.job;
    this.jobType = job.jobTypeCode;
    this.isSwitchTieJob = job.isSwitchTieJob;
    this.reportstatus = false;
    this.dataService.getFormIdForDraft().subscribe(
      res => {
        this.jobformId = res;
      },
      err => { },
      () => {
        this.ls.getJobReports(this.jobId).then((reportList: Array<ReportTable>) => {
          for (const p of reportList) {
            const js: any = JSON.parse(JSON.stringify(p.reportObj));
            if (js.status === 'D') {
              this.reportstatus = true;
              this.jobformId = js.formId;
            }
          }
          if (this.reportstatus) {
            this.display = true;
          } else {
            const url = '/no_production_report/' + this.projectId + '/' + this.jobId + '/' + this.jobformId;
            this.Route.navigateByUrl(url);
          }
        });
      }
    );
    this.checkDraftType();
  }

  addRestDayReport(job) {
    this.jobId = job.job;
    this.jobType = job.jobTypeCode;
    this.isSwitchTieJob = job.isSwitchTieJob;
    this.reportstatus = false;
    this.dataService.getFormIdForDraft().subscribe(
      res => {
        this.jobformId = res;
      },
      err => { },
      () => {
        this.ls.getJobReports(this.jobId).then((reportList: Array<ReportTable>) => {
          for (const p of reportList) {
            const js: any = JSON.parse(JSON.stringify(p.reportObj));
            if (js.status === 'D') {
              this.reportstatus = true;
              this.jobformId = js.formId;
            }
          }
          if (this.reportstatus) {
            this.display = true;
          } else {
            const url = '/app-no-production-restday/' + this.projectId + '/' + this.jobId + '/' + this.jobformId;
            this.Route.navigateByUrl(url);
          }
        });
      }
    );
    this.checkDraftType();
  }
  async checkDraftType() {
    await this.ls.getJobReports(this.jobId).then((reportList: Array<ReportTable>) => {
      for (const p of reportList) {
        const js: any = JSON.parse(JSON.stringify(p.reportObj));
        if (js.status === 'D') {
          this.type = this.Util.determineReportType(js);
        }
      }
    });
  }

  checkReportType(index) {
    this.showReportTable[index] = true;
    this.showIndex = index === this.showIndex ? undefined : index;
  }

  toggleReportInfo(jobId, index) {
    this.active = true;
    this.showMenu = false;
    this.showReportTable[index] = false;
    this.currentIndex = index === this.currentIndex ? undefined : index;
  }

  toggledelete() {
    this.isDialogDelete = !this.isDialogDelete;
  }

  getColor(value) {
    let result = 'text-gray';
    const perc = (value.materialDelivered / value.materialTotal) * 100;
    if (perc === 0) {
      result = 'text-gray';
    } else if (perc > 0 && perc < 100) {
      result = 'text-orange';
    } else if (perc === 100) {
      result = 'text-green';
    }
    return result;
  }

  togglePanel(action, index) {
    if (action === 'close') {
      this.showMenu = false;
    } else {
      this.showMenu = true;
    }
    this.infoIndex = index === this.infoIndex ? undefined : index;
  }

  onLatestReportChanged(isSyncedReport: boolean): void {
    if (isSyncedReport) {
      this.showSyncComponent = true;
    } else {
      this.getProjectJobs();
      this.latestReportChange.emit();
    }
  }

  onSyncComplete(isCompleted: boolean): void {
    if (isCompleted) {
      this.showSyncComponent = false;
      this.latestReportChange.emit();
    }
    this.getProjectJobs();
  }

  onSyncDialogClose(): void {
    this.showSyncComponent = false;
  }

  onNotesOpen(jobId): void {
    this.jobId = jobId;
    this.showNotesComponent = true;
  }

  onNotesClose(): void {
    this.showNotesComponent = false;
  }
}
