import { Component, OnInit } from '@angular/core';
import { Turnout } from '../../model/turnout.model';
import { SelectItem } from 'primeng/primeng';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../../services/local-storage.service';
import { JobModel } from '../../model/job.model';
import { EventBusService, EventType, STWEventData } from '../../services/eventbus.service';
import { Project } from '../../model/project.model';
import { LocationDetail } from '../../model/location.model';
import { FormGroup, FormBuilder, Validators, FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-turnout-dialog',
  templateUrl: './turnout-dialog.component.html',
  styleUrls: ['./turnout-dialog.component.scss']
})
export class TurnoutDialogComponent implements OnInit {
  public showDialog: boolean;
  public allTurnouts: Turnout[] = [];
  turnouts: Turnout[] = [];
  searchForm: FormGroup;
  project: Project;
  prefixes: SelectItem[] = [];
  projectLocations: LocationDetail[] = [];
  projectId: number;
  constructor(
    private eventBus: EventBusService,
    private dataService: DataService,
    private ls: LocalStorageService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.activeRoute.params.subscribe((params: any) => {
      this.projectId = Number(params.project);
    });
    this.initForm();
    this.getPrefixesForProject();
    this.getProjectLocations();
    this.getProject();
  }

  private initForm() {
    let jobId: number;
    let formId: number;
    ({ jobId, formId } = this.getParametersAndInitialize(jobId, formId));
    this.searchForm = this.formBuilder.group({
      prefix: new FormControl(null, [Validators.required]),
      lowMp: new FormControl(null, [Validators.required, this.validateMP()]),
      highMp: new FormControl(null, [Validators.required, this.validateMP()])
    });
    this.dataService.getOneJobFromLocal(jobId).then((j: JobModel) => {
      this.searchForm.get('prefix').patchValue(j.prefix);
      this.searchForm.get('lowMp').patchValue(j.lowMp);
      this.searchForm.get('highMp').patchValue(j.highMp);
      this.search();
    });
  }

  private getProject() {
    this.dataService.getOneProjectFromLocal(this.projectId).then((project: Project) => {
      this.project = project;
    });
  }

  validateMP(): ValidatorFn {
    return (c: FormControl): ValidationErrors => {
      console.log('validateLowMp', c.value);

      let isValidMp = false;
      if (this.projectLocations !== undefined && this.projectLocations.length > 0 && c.value !== null && c.value !== undefined) {
        this.projectLocations.forEach(e => {
          if (e.prefix === this.prefix.value && c.value >= e.lowMp - 5 && c.value <= e.highMp + 5) {
            isValidMp = true;
          }
        });
        if (isValidMp) {
          return null;
        } else {
          return { milePost: true };
        }
      } else {
        if (this.projectLocations.length === 0) {
          this.getProjectLocations().then(() => {});
        }
        return null;
      }
    };
  }

  private getPrefixesForProject() {
    this.dataService
      .getJobsFromLocal(this.projectId)
      .then((jobList: JobModel[]) => {
        new Promise((resolve, reject) => {
          let prefixSet: Set<string> = new Set(jobList.map(job => job.prefix));
          prefixSet.forEach((prefix: string) => this.prefixes.push({ label: prefix, value: prefix }));
          resolve(prefixSet);
        });
      })
      .then(() => {
        this.prefixes.sort((a, b) => (a.value < b.value ? -1 : 1));
      });
  }

  getProjectLocations() {
    let prefixMap = new Map<string, Array<number>>();
    return new Promise((resolve, reject) => {
      this.dataService.getJobsFromLocal(this.projectId).then((jobList: JobModel[]) => {
        jobList.forEach(job => {
          console.log('prefixMap.get(job.prefix)', prefixMap.get(job.prefix));
          if (prefixMap.get(job.prefix) !== null && prefixMap.get(job.prefix) !== undefined) {
            let array: number[] = prefixMap.get(job.prefix);
            array.push(...[job.lowMp, job.highMp]);
            prefixMap.set(job.prefix, array);
          } else {
            prefixMap.set(job.prefix, [job.lowMp, job.highMp]);
          }
        });
        prefixMap.forEach((value: number[], key: string) => {
          this.projectLocations.push(new LocationDetail(key, Math.min(...value), Math.max(...value)));
        });
        resolve(prefixMap);
      });
    });
  }

  private getParametersAndInitialize(jobId: number, formId: number) {
    this.activeRoute.params.subscribe((params: any) => {
      jobId = Number(params.job);
      formId = Number(params.form);
    });
    return { jobId, formId };
  }

  ngOnInit() {
    this.eventBus.getEventBus(EventType.TURNOUT_DIALOG_OPEN_EVENT).subscribe((event: STWEventData) => {
      if (event.data && event.data.length > 0) {
        this.turnouts = event.data;
      } else {
        this.turnouts = [];
      }
      this.search();
      this.allTurnouts.forEach(element => {
        element.isSelected = false;
      });
      this.showDialog = true;
    });
  }

  onCheckboxChange(turnout: Turnout) {
    if (turnout.isSelected) {
      this.turnouts.push(turnout);
    } else {
      const t1 = this.turnouts.filter((item: Turnout) => item.turnoutId !== turnout.turnoutId);
      this.turnouts = t1;
    }
  }

  clearFilter() {
    this.searchForm.get('lowMp').patchValue(null);
    // this.searchForm.get('lowMp').setErrors(null);
    this.searchForm.get('highMp').patchValue(null);
    // this.searchForm.get('highMp').setErrors(null);

    this.allTurnouts = [];
  }

  search() {
    if (this.searchForm.valid) {
      this.dataService
        .getTurnouts(
          this.projectId,
          this.searchForm.get('prefix').value,
          this.searchForm.get('lowMp').value,
          this.searchForm.get('highMp').value
        )
        .then((turnoutList: Turnout[]) => {
          this.allTurnouts =
            this.turnouts && this.turnouts.length > 0
              ? turnoutList.filter((turnout: Turnout) => !this.isTurnoutInTheList(turnout, this.turnouts))
              : turnoutList;
        });
    } else {
      console.log('validation failed');
    }
  }

  apply() {
    this.showDialog = !this.showDialog;
    const newTurnouts = new STWEventData(this.turnouts);
    this.eventBus.fireEvent(EventType.TURNOUT_DIALOG_APPLY_EVENT, newTurnouts);
  }

  private isTurnoutInTheList(turnout: Turnout, turnoutList: Turnout[]): boolean {
    console.log('', this.turnouts);
    const result = turnoutList.findIndex((item: Turnout) => item.turnoutId === turnout.turnoutId) >= 0;
    return result;
  }

  get prefix() {
    return this.searchForm.get('prefix');
  }

  get lowMp() {
    return this.searchForm.get('lowMp');
  }

  get highMp() {
    return this.searchForm.get('highMp');
  }

  getFieldInvalidClass(field: string) {
    return {
      'field-error': this.isFieldValid(field)
    };
  }

  isFieldValid(field: string) {
    return !this.searchForm.get(field).valid && this.searchForm.get(field).touched;
  }

  isTurnOutSelected() {
    const selectedTurnouts = this.allTurnouts.filter((turnout: Turnout) => {
      return turnout.isSelected === true;
    });
    if (selectedTurnouts && selectedTurnouts.length > 0) {
      return true;
    }
    return false;
  }
}
