import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/primeng';
import { BaseRailReportEditMixin } from '../../base-report/base-rail-report-edit-mixin';
import { BaseReportEditMixin } from '../../base-report/base-report-edit-mixin';
import { GaugingInfoComponent } from '../gauging-info.component';
import { LocalStorageService } from '../../services/local-storage.service';
import { DataService } from '../../services/data.service';
import { UtilService } from '../../util/util.service';
import { ReportDataService } from '../../shared/services/report-data.service';
import { ReportNavigationService } from '../../shared/services/report-navigation.service';

const BaseRailReportEdit = BaseRailReportEditMixin(BaseReportEditMixin(GaugingInfoComponent));

@Component({
  selector: 'app-gauging-info-edit',
  templateUrl: '../gauging-info.component.html',
  styleUrls: ['../../rail-info/rail-info.component.scss']
})
export class GaugingInfoEditComponent extends BaseRailReportEdit {

  constructor(
    public fb: FormBuilder,
    public dataService: DataService,
    public ls: LocalStorageService,
    public router: Router,
    public Activateroute: ActivatedRoute,
    public utilService: UtilService,
    public reportDataServcie: ReportDataService,
    public acknowledgeService: ConfirmationService,
    public reportNavigationService: ReportNavigationService
  ) {
    super(fb, dataService, ls, router, Activateroute, utilService, reportDataServcie, acknowledgeService, reportNavigationService);
  }


  protected disableCutRailFields(cutRailFormGroup: FormGroup) {
    super.disableCutRailFields(cutRailFormGroup);
    cutRailFormGroup.controls.isRailCut.disable();
  }
}
