import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'workStatus'
})
export class WorkStatusPipe implements PipeTransform {

    transform(value: any, workStatus: any): any {
        let result = '';
        if (workStatus === 'mat-dlvry') {
            if (value.materialDelivered >= 0 && value.materialTotal >= 0 ) {
                if (value.materialDelivered && value.materialTotal) {
                    const perc = (value.materialDelivered / value.materialTotal) * 100;
                    if (perc === 0) {
                        result = 'csx-common_ban text-gray';
                    } else if (perc > 0 && perc < 100) {
                        result = 'csx-common_in_progress text-orange';
                    } else if (perc === 100) {
                        result = 'csx-common_check text-green';
                    }
                }
            }
        } else {
            if (workStatus === 'preWork' || workStatus === 'postWork' || workStatus === 'preTrip') {
                if (value === 'true') {
                    result = 'csx-common_check';
                } else if (value === 'false') {
                    result = 'csx-common_in_progress';
                } else if (value === '-1') {
                    result = 'csx-common_remove';
                }
            } else if (workStatus === 'percentWorkComplete') {
                const val = parseFloat(value);
                if (val >= 0) {
                if (val === 0) {
                    result = 'csx-common_ban text-gray';
                } else if (val > 0 && val < 100) {
                    result = 'csx-common_in_progress text-orange';
                } else if (val >= 100) {
                    result = 'csx-common_check text-green';
                }
            } else {
                    result = 'csx-common_ban text-gray';
                }
            }
        }
        return result;
    }

}