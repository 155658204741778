import {Component,  Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-addjob',
  templateUrl: './addjob.component.html',
  styleUrls: ['./addjob.component.scss']
})
export class AddjobComponent implements OnInit {
    jobType = [];
    track = [];
    selectedJobType = 'None';
    selectedTrack = 'None';
    @Input() jobDisplay = false;
       constructor() {

           this.jobType = [
               {label: 'Patch Rail', value: 'Patch Rail'},
               {label: 'Out of Face', value: 'Out of Face'},
               {label: 'Gauging', value: 'Gauging'},
               {label: 'Concrete Pad', value: 'Concrete Pad'},
               {label: 'Concrete', value: 'Concrete'}
           ];

           this.track = [
               {label: 'Option1', value: 'Option1'},
               {label: 'Option2', value: 'Option2'},
               {label: 'Option3', value: 'Option3'},
               {label: 'Option4', value: 'Option4'}
           ];
    }

    ngOnInit() {
     /*   this.CrossingData.loadJobData().subscribe((response) => {
            console.log('Project row from service', response);
            this.CrossingData = response;
        }, (error) => {
            console.log('Error while reading project row', error);
        });*/
    }
    public showJobDialog() {
        this.jobDisplay = true;
    }
}