export class LocationModel {
  beginMp: number;
  endMp: number;
  prefix: string;
  trackType: string;
  trackName: string;
  trackClass?: string;
  isPassengerFlag?: string;
  title?: string;
}
