import { ConfirmationService } from 'primeng/primeng';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { NoProductionRestdayComponent } from './../no-production-restday.component';
import { Component } from '@angular/core';
import { BaseReportEditMixin } from '../../base-report/base-report-edit-mixin';
import { DataService } from '../../services/data.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { UtilService } from '../../util/util.service';
import { ReportDataService } from '../../shared/services/report-data.service';
import { ReportNavigationService } from '../../shared/services/report-navigation.service';
import { AppConstant } from '../../shared/constant/constants';

@Component({
  selector: 'app-no-production-restday-edit',
  templateUrl: '../no-production-restday.component.html',
  styleUrls: ['../no-production-restday.component.scss']
})
export class NoProductionRestdayEditComponent extends BaseReportEditMixin(NoProductionRestdayComponent) {
  loading = false;
  showErrorComponent = false;
  errorDialogData: any;
  showSyncComponent = false;
  reasonError = false;
  reasonForEdit: string;

  constructor(
    public fb: FormBuilder,
    public dataService: DataService,
    public ls: LocalStorageService,
    public router: Router,
    public Activateroute: ActivatedRoute,
    public utilService: UtilService,
    public reportDataServcie: ReportDataService,
    public acknowledgeService: ConfirmationService,
    public reportNavigationService: ReportNavigationService,
    private editConfirmationService: ConfirmationService
  ) {
    super(fb, dataService, ls, router, Activateroute, utilService, reportDataServcie, acknowledgeService, reportNavigationService);
  }

  protected handleFormSubmission() {
    this.setReportDataAndSubmit();
    this.submitReport();
  }

  onSyncDialogClose(): void {
    this.showSyncComponent = false;
  }

  onDialogClose(event: boolean): void {
    this.showErrorComponent = false;
    this.errorDialogData = null;
  }

  onSyncComplete(isCompleted: boolean): void {
    if (isCompleted) {
      this.showSyncComponent = false;
      this.router.navigate([`/job_view/${this.projectData}`]);
    }
  }

  onReasonChange() {
    if (this.reasonError && this.reasonForEdit) {
      this.reasonError = false;
    }
  }

  editReport(cd: any) {
    if (!this.reasonForEdit) {
      this.reasonError = true;
    } else {
      cd.accept();
    }
  }

  closeEditConfirmationPopup(cd: any) {
    this.reasonError = false;
    this.reasonForEdit = null;
    cd.reject();
  }

  calDayCount() {
    this.startRestDayDate = this.reportObj.workday;
    super.calDayCount();
  }

  private submitReport() {
    if (Number(this.formId) < 0) {
      // Edit in case of a submitted report will behave same as create report
      super.submitReportData(this.reportObj);
      this.parentSubject.next('form valid');
    } else {
      console.log('Save edited synced report', this.reportObj);
      if (navigator.onLine) {
        this.editConfirmationService.confirm({
          key: AppConstant.EDIT_KEY,
          message: 'Are you sure you want to edit this report',
          accept: () => {
        this.loading = true;
        this.reportObj.reportType = this.utilService.determineReportType(this.reportObj);
        this.dataService.submitEditedReport(this.reportObj, this.reportObj.formId, AppConstant.NO, this.reasonForEdit)
          .then(res => {
            this.loading = false;
            if (res.statusCode !== 200 || (res.errorNumber && res.errorNumber < 0)) {
              this.showErrorComponent = true;
              this.errorDialogData = {
                dialogHeader: AppConstant.ERROR_HEADING,
                dialogBody: this.getErrorMessage(res)
              };
            } else {
              this.localStorage.deleteEditedReportByFormId(this.reportObj.formId).then(() => {
                console.log('deleting this row from indexdb', this.formId);
              });
              this.showSyncComponent = true;
            }
          }).catch(e => {
            this.loading = false;
            this.showErrorComponent = true;
            this.errorDialogData = {
              dialogHeader: AppConstant.ERROR_HEADING,
              dialogBody: e
            };
          });
        },
        reject: () => {
         this.reasonError = false;
        }
      });
      } else {
        this.showErrorComponent = true;
      }
    }
  }

  private getErrorMessage(res: any): string {
    if (res && res.errorName) {
      return res.errorName;
    }
    if (res && res.invocationResult) {
      return res.invocationResult.errorName;
    }
    return null;
  }
}
