import { OperationMode } from './../shared/enums/operation-mode.enum';
import { Component, OnInit } from '@angular/core';
import { SelectItem, ConfirmationService } from 'primeng/primeng';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DataService } from '../services/data.service';
import { ReferenceCode, LocalStorageService } from '../services/local-storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { JobTable, ReportTable, CurrentUserTable } from '../services/local-storage.service';
import { UtilService } from '../util/util.service';
import { ReportDataService } from '../shared/services/report-data.service';
import { ErrorDialog } from '../model/error-dialog.model';
import { AppConstant } from '../shared/constant/constants';
import * as moment from 'moment';

@Component({
  selector: 'app-rail-info',
  templateUrl: './rail-info.component.html',
  styleUrls: ['./rail-info.component.scss']
})
export class RailInfoComponent implements OnInit {
  operationMode: OperationMode = OperationMode.CREATE;
  warningMessageRailType: boolean;
  warningMessageRailSide: boolean;
  warningMessageCutRail: boolean;
  cutRailZeroError: number;
  showminCutRailErrorflag = false;
  warningMessageGrade = false;
  public newVal: any;
  tiePlateOrgVal: any;
  railTypeOrgVal: any;
  railSideOrgVal: any;
  railGradeOrgVal: any;
  railWeightOrgVal: any;
  tempchangenanflag: boolean;
  tiePlateTypeflag: boolean;
  railGradeflag: boolean;
  railWeightflag: boolean;
  public showminCutRailError = false;
  public currentDate: string;
  public maxdate = new Date();
  todayDate: any;
  firstDay: any;
  sumbitedFormLength: any;
  selectedType: string;
  endMpFinal: any;
  selectedMpDir: string[] = [];
  selectedRailSide: string[] = [];
  selectedRailWeight: string[] = [];
  selectedGrade: string[] = [];
  selectedRailType: string[] = [];
  selectedTiePlateType: string[] = [];
  selectedTieOpenJoints: string[] = [];
  direction: SelectItem[];
  railSide: SelectItem[];
  railWeight: SelectItem[];
  designedRailLayingTemp: Array<SelectItem[]> = [];
  designedRailTemp: SelectItem[];
  designedTunnelTemp: SelectItem[];
  railGrade: SelectItem[];
  railType: SelectItem[];
  tiePlateType: SelectItem[];
  railSideDisc: SelectItem[];
  isWorkSkipped: SelectItem[];
  curveStacked: SelectItem[];
  date: Date;
  unitsBoundary: any;
  railLimitError = false;
  projectReports: any[] = [];
  dateTimeWarningMessage: string;
  dateTimeWarning: boolean;
  onDutyTime: string = null;
  errorDialogData: ErrorDialog = {
    dialogHeader: AppConstant.DUPLICATE_REPORT_TITLE,
    dialogBody: AppConstant.DUPLICATE_REPORT_MSG
  };
  showErrorComponent = false;
  private teamName: string;
  public maxRailLength = 1600;

  ballastSufficientOptions = [];
  tunnelOptions = [];

  public timeValue: String;
  public ritime: Date;
  public formGroup: FormGroup;

  private lastestReport: any;
  public tablekeys: any = [
    'actualExpandMeasure',
    'changeTempMeasure',
    'coldRailTemp',
    'designedLayTemp',
    'railNeutralTemp',
    'requiredExpandMeasure',
    'showTempBg',
    'stringId',
    'tempChangeMeasure',
    'tempDiff',
    'isBallastSufficient'
  ];
  public railInfo: any = {
    ' tiePlateCount': null,
    workTypeCode: 'RA',
    divisionCode: null,
    subdivCode: null,
    formId: null,
    workday: null,
    isWorkSkipped: null,
    teamName: null,
    jobId: null,
    reportWindows: [],
    reportProgress: null,
    reportMachineFailure: [],
    reportUnit: null,
    reportTransportation: null,
    railWeight: null,
    railGrade: null,
    railType: null,
    welds: 0,
    iJoints: 0,
    transitions: 0,
    curveStacked: null,
    tiePlateType: null,
    teamLeaderName: null,
    lowMp: null,
    remarks: null,
    direction: null,
    railSide: null,
    railLength: null,
    totalOpenJoints: null,
    roadMaster: null,
    railSideDisc: null,
    cutRailHeated: null,
    supervisor: null,
    reportAsset: [],
    reportCutRail: []
  };
  public formSubmitAttempt = false;
  public showField = false;
  public beginmplimits: any;
  public showOutOfLimitError = false;
  public showRLOutOfLimitError = false;
  public showCutRailOutLimitError = false;
  public showCutRailSkipError = false;
  public commentLength = 200;
  public showCommentOutLimitError = false;
  public showRemainLengthError = false;
  reportRoadCrossingList: FormArray | any = [];
  cutRailArray: FormArray;
  public remainLength: any;
  public totalOpenJoints = 0;
  public showTable = false;
  public newIndex = 0;
  public enableflag = false;
  jobmpinfo: any;
  prefix: string;
  isRowDelete = false;
  public showAddMore = false;
  disabledRows: boolean[] = [];
  jobName: any;
  railSideJob: any;
  jobSubName: any;
  projectData: any;
  reportData: any;
  projectId: number;
  jobId: number;
  formId: number;
  directionflag = false;
  railtypeflag = false;
  railsideflag = false;
  railsidediscflag = false;
  Supervisorflag = false;
  reportobject: any;
  errorjobmessage = false;
  railLengthFlag: boolean;
  errorjobmessageflag = false;
  roadMasterflag = true;
  warningMessage: boolean;
  newvalues: any;
  reportstatus = false;
  roadMaster: any;
  rlCode: any;
  startUtil: any;
  totalCutRail = 0;
  cutRailFlag: boolean;
  spaceError: boolean;

  constructor(
    public fb: FormBuilder,
    public dataService: DataService,
    public ls: LocalStorageService,
    public router: Router,
    public Activateroute: ActivatedRoute,
    public utilService: UtilService,
    public reportDataServcie: ReportDataService,
    public acknowledgeService: ConfirmationService
  ) { }

  ngOnInit() {
    this.initializeReport();
  }

  get viewMode() {
    return this.operationMode === OperationMode.VIEW;
  }

  initializeReport() {
    this.Activateroute.params.subscribe(params => {
      this.projectId = Number(params.project);
      this.jobId = Number(params.job);
      this.formId = Number(params.form);
      this.ls.getCurrentUser('SYSTEM').then((c: CurrentUserTable) => {
        this.teamName = c.teamName;
        this.ls.getJobReports(Number(this.jobId)).then((reportList: Array<ReportTable>) => {
          // *******  this list is to get the previous report
          if (reportList.length > 0) {
            reportList.sort((x, y) => {
              return x.reportObj['workday'] < y.reportObj['workday'] ? -1 : 1;
            });
            let currentReport = null;
            let prevReport = null;
            console.log('all reports', reportList);
            for (let i = 0; i < reportList.length; i++) {
              if (Number(reportList[i].reportObj['formId']) === Number(this.formId)) {
                currentReport = reportList[i].reportObj;
                break;
              } else if (reportList[i].reportObj['noProductionCode'] === null) {
                prevReport = reportList[i].reportObj;
              }
            }
            this.reportobject = currentReport ? currentReport : prevReport ? prevReport : reportList[0].reportObj;
            this.ls.getProjectJobs(this.projectId).then((jobList: Array<JobTable>) => {
              jobList.map((obj, i) => {
                if (obj.jobId === this.jobId) {
                  this.reportobject.desiredNeutralTemp = obj.jobObj['desiredNeutralTemp'];
                }
              });
            });
            console.log('inside prepopulation', this.reportobject);
            if (this.reportobject.noProductionCode === null) {
              for (const p of reportList) {
                const js: any = JSON.parse(JSON.stringify(p.reportObj));
                console.log('current report ', js);
                // ******* by using reportstatus we are checking all reports submitted or not
                if ((js.status === 'S' || js.formId > 0) &&
                  (js.jobType === AppConstant.JOB_TYPE.PATCH_RAIL || js.jobType === AppConstant.JOB_TYPE.CONCRETE_PAD) &&
                  js.noProductionCode === null) {
                  this.reportstatus = true;
                  if (js.formId === this.formId && reportList.length === 1) {
                    this.reportstatus = false;
                  }
                }
              }
              // ******* we are assigning old report values to the rail info object
              const railInfokeys = [
                'direction',
                'railWeight',
                'railGrade',
                'railType',
                'tiePlateCount',
                'tiePlateType',
                'railSide',
                'railSideDisc',
                'supervisor',
                'trackType'
              ];
              railInfokeys.forEach(Obj => {
                if (this.reportobject[Obj]) {
                  this.railInfo[Obj] = this.reportobject[Obj];
                }
              });
              this.showField = this.reportobject.railSideDisc ? true : false;
              this.jobName = this.reportobject.jobType;
              if (this.jobName === AppConstant.JOB_TYPE.CONCRETE_PAD) {
                this.maxRailLength = 9999;
              }
              if (!this.reportobject.direction) {
                this.railInfo.direction = 'I';
              }
              if (this.reportobject.direction === 'D') {
                this.railInfo.beginMp = this.reportobject.lowMp;
              } else {
                this.railInfo.beginMp = this.reportobject.highMp;
              }
              // ******* this is used disable the top fields where atleast one report is submitted
              if (this.reportobject.jobType === AppConstant.JOB_TYPE.GAUGING) {
                this.railInfo.tiePlateType = null;
              }
              this.railsideflag = this.railInfo.railSide && this.reportstatus ? true : false;
              this.directionflag = this.railInfo.direction && this.reportstatus ? true : false;
              this.railtypeflag = this.railInfo.railType && this.reportstatus ? true : false;
              this.Supervisorflag = this.railInfo.supervisor && this.reportstatus ? true : false;
              this.railWeightflag = this.railInfo.railWeight && this.reportstatus ? true : false;
              this.railGradeflag = this.railInfo.railGrade && this.reportstatus ? true : false;
              this.tiePlateTypeflag = this.railInfo.tiePlateType && this.reportstatus ? true : false;
              this.railsidediscflag = this.railInfo.railSideDisc && this.reportstatus ? true : false;
              this.assignLowHighMilepost();
              this.form();
            } else {
              this.prepopulateFromJob();
            }
          } else {
            this.prepopulateFromJob();
          }
          this.setReportForm();
        });

        this.direction = [];
        this.direction.push({ label: 'Increasing', value: 'I' });
        this.direction.push({ label: 'Decreasing', value: 'D' });
        this.railSide = [];
        this.railSide.push({ label: 'High', value: 'H' });
        this.railSide.push({ label: 'Low', value: 'W' });
        this.railType = [];
        this.isWorkSkipped = [];
        this.isWorkSkipped.push({ label: 'Installed', value: false });
        this.isWorkSkipped.push({ label: 'Skipped', value: true });
        // *******  this is used to add values to field components
        this.ls.getAllRefereces().then((refs: Array<ReferenceCode>) => {
          const refList = refs;
          for (const p of refList) {
            if (p.refType === 'RWT') {
              const railWeightArray = this.utilService.hack(p.refObj, 'railWeight');
              this.railWeight = railWeightArray.reverse();
            }
            if (p.refType === 'RGR') {
              this.railGrade = this.utilService.hack(p.refObj, 'railGrade');
            }
            if (p.refType === 'TBD') {
              this.railSideDisc = this.utilService.hack(p.refObj, 'railSideDisc');
            }
            if (p.refType === 'TPG') {
              this.tiePlateType = this.utilService.hack(p.refObj, 'tiePlateType');
            }
            if (p.refType === 'DTP') {
              this.designedRailTemp = this.utilService.hack(p.refObj, 'designedRailTemp');
            }
            if (p.refType === 'RTT') {
              this.designedTunnelTemp = this.utilService.hack(p.refObj, 'designedTunnelTemp');
            }
            if (p.refType === 'TNR') {
              this.railType = this.utilService.hack(p.refObj, 'railType');
            }
          }
        });
        this.remainLength = this.railInfo.railLength;
        this.getProjectReports();
        this.ballastSufficientOptions = [
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ];
        this.tunnelOptions = [
          { label: 'Yes', value: 'Y' },
          { label: 'No', value: 'N' }
        ];
      });
    });
  }

  protected form() {
    // *******  adding values to the formgroup intially
    this.formGroup = this.fb.group({
      workTypeCode: this.railInfo.workTypeCode,
      rl: this.rlCode,
      divisionCode: this.railInfo.divisionCode,
      subdivCode: this.railInfo.subdivCode,
      trackType: this.railInfo.trackType,
      reportRoadCrossingList: this.fb.array([]),
      formId: this.formId,
      teamName: this.teamName,
      projectId: this.projectId,
      jobId: this.jobId,
      jobType: this.jobName,
      prefix: this.prefix,
      noProductionCode: null,
      beginMp: [this.railInfo.beginMp, Validators.required],
      endMp: this.railInfo.endMp,
      lowMp: this.railInfo.lowMp,
      highMp: this.railInfo.highMp,
      totalOpenJoints: this.totalOpenJoints,
      direction: [!this.railInfo.direction ? 'I' : this.railInfo.direction, Validators.required],
      railGrade: [this.railInfo.railGrade, Validators.required],
      railType: [this.railInfo.railType, Validators.required],
      tiePlateType: [this.railInfo.tiePlateType, Validators.required],
      roadMaster: this.roadMaster,
      railSide: [this.railInfo.railSide, Validators.required],
      date: [this.railInfo.date, Validators.required],
      workday: this.railInfo.date,
      railLength: [this.railInfo.railLength, [Validators.pattern('^[0-9][0-9]*$'), Validators.required]],
      cutRailArray: this.cutRailArray ? this.cutRailArray : this.fb.array([this.createCutRailGroup()]),
      welds: [this.railInfo.welds, [Validators.pattern('^[0-9][0-9]*$'), Validators.required]],
      iJoints: [this.railInfo.iJoints, [Validators.pattern('^[0-9][0-9]*$'), Validators.required]],
      transitions: [this.railInfo.transitions, [Validators.pattern('^[0-9][0-9]*$'), Validators.required]],
      remarks: this.railInfo.remarks,
      railWeight: [this.railInfo.railWeight, Validators.required],
      railSideDisc: new FormControl(
        { value: this.railInfo.railSideDisc, disabled: this.railsidediscflag },
        null,
        (control: FormControl) => {
          return new Promise((resolve, reject) => {
            if (!this.showField) {
              resolve(null);
            }
            if (this.railInfo.railSideDisc) {
              resolve(null);
            } else {
              resolve({ railSideDiscExists: true });
            }
          });
        }
      ),
      supervisor: new FormControl({ value: this.railInfo.supervisor, disabled: this.Supervisorflag }, null, (control: FormControl) => {
        return new Promise((resolve, reject) => {
          if (!this.showField) {
            resolve(null);
          }
          if (this.railInfo.supervisor) {
            resolve(null);
          } else {
            resolve({ supervisorExists: true });
          }
        });
      }),

    });
  }

  prepopulateFromJob() {
    // *******  this is used to get the  record from job List
    this.ls.getProjectJobs(Number(this.projectId)).then((jobList: Array<JobTable>) => {
      console.log('first job data ', jobList);
      console.log(jobList);
      jobList.forEach((app, index) => {
        if (app.jobId === Number(this.jobId)) {
          console.log('inside job data');
          this.reportobject = app.jobObj;
          this.railInfo.railWeight = this.reportobject.railWeight;
          console.log('rail info');
          console.log(this.railInfo.railWeight);
          this.railInfo.railGrade = this.reportobject.railGrade;
          this.railInfo.trackType = this.reportobject.trackType;
          this.railInfo.railType = this.reportobject.railType;
          this.railInfo.tiePlateType = this.reportobject.tiePlateType;
          this.railInfo.divisionCode = this.reportobject.division;
          this.railInfo.subdivCode = this.reportobject.subdivision;
          this.railInfo.railSide = this.reportobject.blueBookRailside;
          this.railInfo.railSideDisc = this.reportobject.railSideDisc;
          this.railInfo.supervisor = this.reportobject.supervisor;
          this.jobName = this.reportobject.jobTypeCode;
          if (this.jobName === AppConstant.JOB_TYPE.CONCRETE_PAD) {
            this.maxRailLength = 9999;
          }
          this.showField = this.reportobject.railSideDisc ? true : false;
          this.railInfo.lowMp = this.reportobject.startMP;
          this.railInfo.beginMp = this.reportobject.startMP;
          this.showTable = false;
          this.form();
          this.errorjobmessageflag = true;
          console.log('railinfo in prepopulation', this.railInfo);
        }
      });
    });
  }

  setReportForm() {
    this.form();
    window.scrollTo(0, 0);
    this.todayDate =
      this.maxdate.getFullYear() +
      '-' +
      ('0' + (this.maxdate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + this.maxdate.getDate()).slice(-2) +
      'T' +
      ('0' + this.maxdate.getHours()).slice(-2) +
      ':' +
      ('0' + this.maxdate.getMinutes()).slice(-2);
    this.firstDay = new Date().getFullYear() + '-01-01T00:00';
    this.formGroup.controls['welds'].patchValue(0);
    this.formGroup.controls['iJoints'].patchValue(0);
    this.formGroup.controls['transitions'].patchValue(0);
    // *****   this is used to get the   job information from the route params
    this.Activateroute.params.subscribe(params => {
      const jobData = params.job;
      this.projectData = params.project;
      if (jobData) {
        const job = Number(jobData.split('-')[0]);
        this.dataService.getJobInfo(job).then(async (jobId1: JobTable) => {
          this.jobmpinfo = JSON.parse(JSON.stringify(jobId1.jobObj));
          console.log('works', this.jobmpinfo);
          this.jobName = this.jobmpinfo.jobTypeCode;
          if (this.jobName === AppConstant.JOB_TYPE.CONCRETE_PAD) {
            this.maxRailLength = 9999;
          }
          // this.jobName = "OF"
          this.formGroup.controls['jobType'].patchValue(this.jobName);
          this.prefix = this.jobmpinfo.prefix;
          this.railSideJob = this.jobmpinfo.railSide;
          console.log('prefix', this.prefix);
          if (this.jobName === AppConstant.JOB_TYPE.PATCH_RAIL && (this.railSideJob === 'H' || this.railSideJob === 'W')) {
            this.railSide = [];
            this.railSide.push({ label: 'High', value: 'H' });
            this.railSide.push({ label: 'Low', value: 'W' });
          } else {
            this.railSide = [];
            this.railSide.push({ label: 'Left', value: 'L' });
            this.railSide.push({ label: 'Right', value: 'R' });
          }
          if (this.jobName === AppConstant.JOB_TYPE.GAUGING) {
            this.isWorkSkipped = [];
            this.isWorkSkipped.push({ label: 'Gauged', value: false });
            this.isWorkSkipped.push({ label: 'Skipped', value: true });
          }
          if (this.jobName === AppConstant.JOB_TYPE.CONCRETE_PAD) {
            this.isWorkSkipped = [];
            this.isWorkSkipped.push({ label: 'Repaired', value: false });
            this.isWorkSkipped.push({ label: 'Skipped', value: true });
          }
          this.jobSubName = this.jobmpinfo.jobTypeCode;
          this.beginmplimits = {
            low: this.jobmpinfo.startMP,
            high: this.jobmpinfo.endMP
          };
          this.formGroup.controls['prefix'].patchValue(this.prefix);
          if (this.jobmpinfo.roadmasters.length > 0) {
            const roadMasterRl = this.jobmpinfo.roadmasters[0].rl_c;
            let supervisor = null;
            supervisor = await this.utilService.mapRoadMasterDetails(roadMasterRl);
            this.formGroup.controls['roadMaster'].patchValue(supervisor ? supervisor.userObj.fullName : 'System Error');
            this.formGroup.controls['rl'].patchValue(this.jobmpinfo.roadmasters[0].rl_c);
            this.rlCode = this.jobmpinfo.roadmasters[0].rl_c;
            this.roadMaster = supervisor ? supervisor.userObj.fullName : 'System Error';
          } else {
            this.roadMaster = 'System Error';
          }
          console.log('this.roadMaster', this.roadMaster);
          this.railInfo.trackType = this.jobmpinfo.trackType;
          this.railInfo.divisionCode = this.jobmpinfo.division;
          this.railInfo.subdivCode = this.jobmpinfo.subdivision;
          if (this.jobName === AppConstant.JOB_TYPE.PATCH_RAIL || this.jobName === AppConstant.JOB_TYPE.OUT_OF_FACE_RAIL) {
            this.railWeightOrgVal = this.jobmpinfo.railWeight;
            this.railGradeOrgVal = this.jobmpinfo.railGrade;
            this.railTypeOrgVal = this.jobmpinfo.railType;
            this.tiePlateOrgVal = this.jobmpinfo.tiePlateType;
            this.warningFieldGrade();
            this.warningFieldRailType();
            this.warningFields();
          }
          this.verifyMp(this.railInfo.beginMp);
          this.railSideOrgVal = this.jobmpinfo.blueBookRailside;
          this.updateRailSide(this.formGroup.controls['railSide'].value);
          this.form();
        }).then(() => {
          this.getReportMethod().then(response => {
            console.log(' FORM ID 1', response);
            if (response) {
              this.reportfill(response.reportObj);
            } else {
              this.onDutyDateTimeChange(null);
            }
          })
            .catch(reason => {
              console.log(reason);
            });
        });

      }
    });
  }

  protected getReportMethod() {
    return this.dataService.getReport(Number(this.formId));
  }

  protected reportfill(response: any) {
    const reportData = response;
    try {
      this.checkReportOperationMode(reportData);
      const keys = [];
      // tslint:disable-next-line:forin
      for (const k in reportData) {
        keys.push(k);
      }
      console.log('keys');
      console.log(keys);
      // *****   this rail keys is used to keep track of all records keys using in rail info
      const railkeys = [
        'formId',
        'projectId',
        'jobId',
        'beginMp',
        'direction',
        'railGrade',
        'tiePlateType',
        'railType',
        'roadMaster',
        'rl',
        'railSide',
        'workday',
        'railLength',
        'welds',
        'iJoints',
        'transitions',
        'remarks',
        'railWeight',
        'railSideDisc',
        'supervisor',
        'highMp',
        'lowMp',
        'trackType',
        'endMp',
        'curvesStaked',
        'tiePlateCount'
      ];
      const newvaluesarray = this.utilService.differenceOf2Arrays(keys, railkeys);
      const anothObject = {};
      newvaluesarray.forEach(function (eachObj) {
        anothObject[eachObj] = reportData[eachObj];
      });
      // *****this new values are key values which are out of rail info component //
      // we are storing in this new values object and appending  back to to the final report on submit
      this.newvalues = anothObject;
    } catch (error) {
      console.log('on adding other values');
      console.log(error);
    }
    const reportkeys = [
      'welds',
      'beginMp',
      'iJoints',
      'transitions',
      'transitions',
      'transitions',
      'direction',
      'transitions',
      'rl',
      'railWeight',
      'railGrade',
      'railType',
      'railLength',
      'roadMaster',
      'remarks',
      'tiePlateType',
      'railSide',
      'lowMp',
      'highMp',
      'endMp',
      'curvesStaked',
      'tiePlateCount'
    ];
    reportkeys.forEach(Obj => {
      if (this.formGroup.controls[Obj]) {
        this.formGroup.controls[Obj].patchValue(reportData[Obj]);
      }
    });
    if (reportData.railSideDisc) {
      this.showField = true;
    } else {
      this.showField = false;
    }
    this.warningFieldGrade();
    this.warningFieldRailType();
    this.warningFields();
    this.updateRailSide(reportData.railSide);
    this.recalculateCutRaillength();
    this.verifyMp(reportData.beginMp);
    this.railInfo.railSideDisc = reportData.railSideDisc;
    this.formGroup.controls['supervisor'].patchValue(reportData.supervisor);
    // ***** we are changing back timestamp to iso format which date input value will allow
    reportData.workday = reportData.workday === 0 ? new Date() : reportData.workday;
    const timestampdate = new Date(reportData.workday).toISOString();

    this.ritime = new Date(timestampdate);
    this.formGroup.controls['workday'].patchValue(this.ritime);
    if (reportData.workday !== 0) {
      this.formGroup.controls['date'].patchValue(
        this.ritime.getFullYear() +
        '-' +
        ('0' + (this.ritime.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + this.ritime.getDate()).slice(-2) +
        'T' +
        ('0' + this.ritime.getHours()).slice(-2) +
        ':' +
        ('0' + this.ritime.getMinutes()).slice(-2)
      );
      this.onDutyTime = this.utilService.convertTimeStampTOdate(reportData.workday);
      this.railInfo.date = this.onDutyTime ? this.onDutyTime : null;
    }

    this.cutRailArray = <FormArray>this.formGroup.controls['cutRailArray'];
    // ***** to  check and  clear the cut rail array if already added and patching report data cut rail to formgroup
    if (this.cutRailArray && reportData.reportCutRail) {
      this.cutRailArray.removeAt(0);
    }
    reportData.reportCutRail.forEach((app, index) => {
      this.disabledRows[index] = app.isWorkSkipped;
      const fb = this.createCutRailGroup();
      this.showTable = true;
      fb.patchValue(app);
      fb.patchValue({
        cutRailLength: app.cutRailLength,
        openJoints: app.openJoints ? app.openJoints : 0,
        isWorkSkipped: app.isWorkSkipped,
        isTunnel: app.isTunnel
      });
      if (app.cutRailLength && !app.isWorkSkipped) {
        Object.keys(fb.controls).forEach(field => {
          const control = fb.get(field);
          console.log(control);
          control.markAsTouched({ onlySelf: true });
        });
      }
      this.cutRailArray.push(fb);
      this.showAddMore = app.cutRailLength != null ? true : false;
      this.enableflag = true;
      this.sumbitedFormLength = this.cutRailArray.length;
      this.ls.getProjectJobs(this.projectId).then((jobList: Array<JobTable>) => {
        jobList.map((obj, i) => {
          if (obj.jobId === this.jobId) {
            this.reportobject.desiredNeutralTemp = obj.jobObj['desiredNeutralTemp'];
            if (this.editMode) {
              this.calculatetempDiff(index);
            }
            this.tunnelChange(index);
            fb['controls']['designedLayTemp'].patchValue(app.designedLayTemp);
          }
        });
      });
      console.log('this.cutRailArray');
      console.log(this.cutRailArray);
      if (this.cutRailArray) {
        if (reportData.reportCutRail.length === index + 1) {
          this.calTotalOpenJoints(index - 1);
        }
        this.recalculateCutRail(index);
      }
    });
    this.cutRailArray.controls.forEach((formGroup: FormGroup, i: any) => {
      if (formGroup.controls['isWorkSkipped'].value === true) {
        this.tablekeys.forEach(Obj => {
          formGroup.controls[Obj].disable();
        });
      } else if (formGroup.controls['isWorkSkipped'].value === false) {
        this.tablekeys.forEach(Obj => {
          formGroup.controls[Obj].enable();
          if (!formGroup.controls[Obj].value || formGroup.controls[Obj].value === null || formGroup.controls[Obj].value === '') {
            if (formGroup.controls[Obj].value !== 0) {
              formGroup.controls[Obj].markAsUntouched();
            }
          }
        });
      }
    });
    this.shouldDisable(0);
  }

  protected checkReportOperationMode(reportData: any) {
    if (reportData.status === 'S' || reportData.formId > 0) {
      this.operationMode = OperationMode.VIEW;
    } else if (reportData.status === 'D') {
      this.operationMode = OperationMode.CREATE;
    }
  }

  get editMode() {
    return this.operationMode === OperationMode.EDIT;
  }

  shouldDisable(index: number) {
    let val;
    if (this.disabledRows[index] === undefined) {
      val = false;
    } else {
      val = this.disabledRows[index];
    }
    return val;
  }

  createCutRailGroup(): FormGroup {
    return this.fb.group({
      cutRailLength: [this.railInfo.cutRailLength, [Validators.pattern('^[0-9][0-9]*$'), Validators.required]],
      openJoints: [this.railInfo.openJoints || 0, [Validators.pattern('^[0-9][0-9]*$'), Validators.required]],
      isWorkSkipped: [
        {
          value: this.railInfo.isWorkSkipped,
          disabled: this.railInfo.cutRailLength && this.showCutRailOutLimitError === true
        },
        Validators.required
      ],
      stringId: [null, Validators.required],
      designedLayTemp: [null, Validators.required],
      coldRailTemp: [null, [Validators.pattern('^[0-9][0-9]*$'), Validators.required]],
      changeTempMeasure: [null, Validators.required],
      requiredExpandMeasure: [null, Validators.required],
      actualExpandMeasure: [null, Validators.required],
      tempChangeMeasure: [null, Validators.required],
      railNeutralTemp: [null, Validators.required],
      cutRailIndex: null,
      tempDiff: null,
      cutRailBeginMp: null,
      cutRailEndMp: null,
      showTempBg: false,
      isBallastSufficient: [null, Validators.required],
      isTunnel: [null, Validators.required]
    });
  }

  toogleDirection(direction: string) {
    if (this.formGroup.controls['direction'].value && (this.formGroup.controls['direction'].value !== direction)) {
      this.acknowledgeService.confirm({
        key: 'updateConfirmation',
        message: AppConstant.DIRECTION_CHANGE_CONFIRM_MSG,
        accept: () => {
          this.errorjobmessage = true;
          this.formGroup.controls['railSide'].patchValue(null);
          this.formGroup.controls['beginMp'].patchValue(null);
          this.recalculateCutRaillength();
          this.verifyMp(this.formGroup.controls['beginMp'].value);
        },
        reject: () => {
          const revertValue = this.formGroup.controls['direction'].value === 'I' ? 'D' : 'I';
          this.formGroup.controls['direction'].patchValue(revertValue);
        }
      });
    }
  }

  beginMPWarning() {
    this.utilService.isMilePostValid(this.formGroup.controls['beginMp'].value, this.jobId).then((isValid: boolean) => {
      if (isValid) {
        if (this.formGroup.controls['direction'].value && this.formGroup.controls['direction'].value === 'I') {
          if (this.formGroup.controls['beginMp'].value >= this.reportobject.endMP &&
            this.formGroup.controls['beginMp'].value <= this.reportobject.endMP + 0.2) {
            this.beginMPWarningConfirmation();
          }
        } else if (this.formGroup.controls['direction'].value && this.formGroup.controls['direction'].value === 'D') {
          if (this.formGroup.controls['beginMp'].value <= this.reportobject.startMP &&
            this.formGroup.controls['beginMp'].value >= this.reportobject.startMP - 0.2) {
            this.beginMPWarningConfirmation();
          }
        }
      }
    });
  }

  private beginMPWarningConfirmation() {
    this.acknowledgeService.confirm({
      key: 'warningConfirmation',
      message: AppConstant.MILEPOST_WARNING_MSG,
      accept: () => {
      },
      reject: () => {
        this.formGroup.controls['beginMp'].patchValue(null);
        this.recalculateCutRaillength();
        this.verifyMp(this.formGroup.controls['beginMp'].value);
      }
    });
  }

  addTable(index: any, labelObj: any, currentControl: any, raillen: boolean, isRailCut?: any) {
    this.formSubmitAttempt = false;
    if (this.cutRailArray.length - 1 === index) {
      raillen ? (this.showAddMore = true) : (this.showAddMore = false);
    }
    this.cutRailArray = this.formGroup.get('cutRailArray') as FormArray;
    this.showCutRailSkipError = false;
    this.disabledRows[index] = labelObj.value;
    this.cutRailArray.controls.forEach((formGroup: FormGroup, i: any) => {
      formGroup.controls['cutRailIndex'].patchValue(i);
      if (i === index) {
        this.tablekeys.forEach(Obj => {
          formGroup.controls[Obj].enable();
        });
      }
    });
    if (labelObj.value === false) {
      if (this.jobName === AppConstant.JOB_TYPE.CONCRETE_PAD) {
        if (isRailCut.value === 'Y') {
          this.showTable = true;
          this.enableflag = true;
        } else {
          this.addTableWhenSkipped(index);
          this.disabledRows[index] = true;
        }
      } else {
        this.showTable = true;
        this.enableflag = true;
      }
    } else {
      this.addTableWhenSkipped(index);
    }
    this.recalculateCutRail(index);
    this.calTotalOpenJoints(index);
  }

  private addTableWhenSkipped(index: any) {
    this.enableflag = true;
    const val = this.cutRailArray.controls[index].value;
    console.log(' inside skipped', val);
    val['openJoints'] = 0;
    this.cutRailArray.controls[index].patchValue(val);
    this.cutRailArray.controls.forEach((formGroup: FormGroup, i: any) => {
      console.log(formGroup);
      if (i === index) {
        console.log(' after adding ');
        console.log(formGroup);
        this.tablekeys.forEach(Obj => {
          formGroup.controls[Obj].disable();
        });
      }
    });
  }

  addCutRail(): void {
    this.formSubmitAttempt = false;
    this.showAddMore = false;
    this.cutRailArray = this.formGroup.get('cutRailArray') as FormArray;
    this.cutRailArray.push(this.createCutRailGroup());
  }

  calTotalOpenJoints(index: any) {
    this.showCutRailSkipError = false;
    let totalopenJointsValue = 0;
    this.cutRailArray = this.formGroup.get('cutRailArray') as FormArray;
    this.cutRailArray.controls.forEach((frmgrp: FormGroup, i: any) => {
      const OpenJointsValue = frmgrp.controls['openJoints'].value;
      if (OpenJointsValue != null) {
        totalopenJointsValue += parseInt(OpenJointsValue, 10);
        this.totalOpenJoints = totalopenJointsValue;
      }
    });
  }

  calculateEndMp(totalCutRailValue) {
    if (this.formGroup.controls['railLength'].value) {
      this.utilService.isMilePostValid(this.formGroup.controls['beginMp'].value, this.jobId).then((isValid: boolean) => {
        if (isValid) {
          this.utilService
            .getEndMilePost(
              this.jobId,
              this.formGroup.controls['beginMp'].value,
              totalCutRailValue,
              this.formGroup.controls['direction'].value
            )
            .then(result => {
              console.log(result);
              if (result === true) {
                this.railLimitError = true;
                this.formGroup.controls['endMp'].patchValue('--');
              } else {
                this.railLimitError = false;
                this.endMpFinal = result;
                this.formGroup.controls['endMp'].patchValue(this.endMpFinal);
              }
            });
        } else {
          this.railLimitError = true;
          this.formGroup.controls['endMp'].patchValue('--');
        }
      });
    }
  }

  recalculateCutRaillength() {
    this.railLimitError = false;
    console.log('inside rail length', this.getFormValues());
    this.recalculateCutRail(0);
  }

  recalculateCutRail(index: any) {
    this.showminCutRailError = false;
    this.railLimitError = false;
    this.formSubmitAttempt = false;
    this.totalCutRail = 0;
    let cutRailValue;
    this.cutRailFlag = false;
    let enableCount = 0;
    this.showTable = false;
    let totalCutRailValue = 0;
    this.cutRailArray = this.formGroup.get('cutRailArray') as FormArray;
    this.cutRailArray.controls.forEach((frmgrp: FormGroup, j: any) => {
      cutRailValue = frmgrp.controls['cutRailLength'].value;
      totalCutRailValue = cutRailValue + totalCutRailValue;
      const isWorkSkipped = frmgrp.controls['isWorkSkipped'].value;
      if (cutRailValue && cutRailValue > 0 && !isWorkSkipped) {
        if (this.jobName === AppConstant.JOB_TYPE.CONCRETE_PAD) {
          if (frmgrp.controls['isRailCut'].value === 'Y') {
            enableCount++;
            this.showminCutRailError = false;
            this.onColdTempChange(index);
          }
        } else {
          enableCount++;
          this.showminCutRailError = false;
          this.onColdTempChange(index);
        }
        this.totalCutRail += parseInt(cutRailValue, 10);
      }
      if (isWorkSkipped === false) {
        if (this.jobName === AppConstant.JOB_TYPE.CONCRETE_PAD) {
          if (frmgrp.controls['isRailCut'].value === 'Y') {
            this.showTable = true;
          }
        } else {
          this.showTable = true;
        }
      }
      if (cutRailValue > this.maxRailLength) {
        this.cutRailFlag = true;
      }
      if (enableCount === 0) {
        this.showTable = false;
      }
    });
    this.remainLength = 0;
    console.log('totalCutRailValue', totalCutRailValue);
    this.formGroup.controls['railLength'].patchValue(this.totalCutRail);
    if (totalCutRailValue > 0 && Number.isInteger(totalCutRailValue)) {
      this.calculateEndMp(totalCutRailValue);
    }
  }

  isRowDisabled(index: number) {
    console.log('index');
    console.log(index);
    const disabled: boolean = this.disabledRows[index];
    return disabled;
  }

  removeCutRail(index: any) {
    this.showAddMore = true;
    this.cutRailArray = this.formGroup.get('cutRailArray') as FormArray;
    this.cutRailArray.removeAt(index);
    this.recalculateCutRail(0);
    this.calTotalOpenJoints(index);
    this.disabledRows.splice(index, 1);
  }

  warningFields() {
    this.formSubmitAttempt = false;
    this.newVal = Number(this.formGroup.controls['railWeight'].value);
    if (this.newVal !== this.railWeightOrgVal && this.newVal) {
      console.log(' if newVal this.railWeightOrgVal', this.newVal, this.railWeightOrgVal);
      this.warningMessage = true;
    } else {
      console.log(' else newVal', this.newVal, this.railWeightOrgVal);
      this.warningMessage = false;
    }
    console.log('newVal', this.newVal, this.railWeightOrgVal);
  }

  warningFieldGrade() {
    this.formSubmitAttempt = false;
    this.newVal = this.formGroup.controls['railGrade'].value;
    if (this.newVal !== this.railGradeOrgVal && this.newVal) {
      this.warningMessageGrade = true;
      console.log(' if newVal', this.newVal, this.railGradeOrgVal);
    } else {
      console.log(' else newVal', this.newVal, this.railGradeOrgVal);
      this.warningMessageGrade = false;
    }
  }

  warningFieldRailType() {
    this.formSubmitAttempt = false;
    this.newVal = this.formGroup.controls['railType'].value;
    if (this.newVal !== this.railTypeOrgVal && this.newVal) {
      this.warningMessageRailType = true;
      console.log(' if newVal', this.newVal, this.railTypeOrgVal);
    } else {
      console.log(' else newVal', this.newVal, this.railTypeOrgVal);
      this.warningMessageRailType = false;
    }
  }

  toogleFields(railSide: any) {
    if (this.formGroup.controls['railSide'].value && railSide !== this.formGroup.controls['railSide'].value
      && (
        this.formGroup.controls['jobType'].value === AppConstant.JOB_TYPE.OUT_OF_FACE_RAIL ||
        this.formGroup.controls['jobType'].value === AppConstant.JOB_TYPE.GAUGING
      )
    ) {
      this.acknowledgeService.confirm({
        key: 'updateConfirmation',
        message: AppConstant.SIDE_CHANGE_CONFIRM_MSG,
        accept: () => {
          this.updateRailSide(railSide);
          this.formGroup.controls['beginMp'].patchValue(null);
        },
        reject: () => {
          const revertValue = railSide === 'L' ? 'R' : 'L';
          this.formGroup.controls['railSide'].patchValue(revertValue);
        }
      });
    } else {
      this.updateRailSide(railSide);
    }
  }

  calculatetempDiff(index: any) {
    this.cutRailArray = this.formGroup.get('cutRailArray') as FormArray;
    this.cutRailArray.controls.forEach((formGroup: FormGroup, i: any) => {
      if (index >= 0 && index === i) {
        if (formGroup.controls['designedLayTemp'].value !== null) {
          const tempDiff = formGroup.controls['designedLayTemp'].value - formGroup.controls['coldRailTemp'].value;
          formGroup.controls['changeTempMeasure'].patchValue(tempDiff);
        }
      }
    });
  }

  calculateMwiTempChange(index: any) {
    this.cutRailArray = this.formGroup.get('cutRailArray') as FormArray;
    this.cutRailArray.controls.forEach((formGroup: FormGroup, i: any) => {
      if (index >= 0 && index === i) {
        console.log('form group :', [formGroup]);
        if (formGroup.controls['actualExpandMeasure'].invalid) {
          formGroup.controls['tempChangeMeasure'].reset();
        } else {
          let changeTemp = 0;
          changeTemp = Math.round(
            Number(formGroup.controls['actualExpandMeasure'].value) / 12 / (0.0000065 * Number(formGroup.controls['cutRailLength'].value))
          );
          formGroup.controls['tempChangeMeasure'].patchValue(changeTemp);
          console.log('actual expansion 123', changeTemp);
        }
      }
    });
  }

  caluculateRailNeutralTemp(index: any) {
    this.cutRailArray = this.formGroup.get('cutRailArray') as FormArray;
    this.cutRailArray.controls.forEach((formGroup: FormGroup, i: any) => {
      if (index >= 0 && index === i) {
        if (formGroup.controls['actualExpandMeasure'].invalid) {
          formGroup.controls['showTempBg'].reset();
          formGroup.controls['tempDiff'].reset();
          formGroup.controls['railNeutralTemp'].reset();
        } else {
          const tempChange = Math.round(
            Number(formGroup.controls['coldRailTemp'].value) +
            Number(formGroup.controls['actualExpandMeasure'].value) / 12 / (Number(formGroup.controls['cutRailLength'].value) * 0.0000065)
          );
          const designRailTemp = Number(formGroup.controls['designedLayTemp'].value);
          formGroup.controls['showTempBg'].patchValue(true);
          const diff = designRailTemp - Number(tempChange);
          formGroup.controls['tempDiff'].patchValue(diff);
          formGroup.controls['railNeutralTemp'].patchValue(tempChange);
        }
      }
    });
  }

  verifyMp(beginValue) {
    this.assignLowHighMilepost();
    const jobLimitHigh = this.beginmplimits.high + 0.2;
    const jobLimitLow = this.beginmplimits.low - 0.2;
    if (jobLimitHigh < jobLimitLow) {
      this.showOutOfLimitError = beginValue < jobLimitHigh || beginValue > jobLimitLow;
    } else {
      this.showOutOfLimitError = beginValue < jobLimitLow || beginValue > jobLimitHigh;
    }
    if (!this.showOutOfLimitError && beginValue !== undefined) {
      this.utilService.isMilePostValid(beginValue, this.jobId).then((isValid: boolean) => {
        if (!isValid) {
          this.showOutOfLimitError = true;
        } else {
          this.showOutOfLimitError = false;
        }
      });
    }
    this.recalculateCutRaillength();
  }

  verifyCutRail(cutRailValue) {
    if (cutRailValue <= this.maxRailLength && cutRailValue > 0) {
      this.showCutRailOutLimitError = false;
    } else {
      this.showCutRailOutLimitError = true;
    }
  }

  updateCharCount(commentText) {
    this.commentLength = 200 - commentText.length;
    if (this.commentLength < 0) {
      this.showCommentOutLimitError = true;
    } else {
      this.showCommentOutLimitError = false;
    }
  }

  roundToNearest(index: any) {
    this.cutRailArray = this.formGroup.get('cutRailArray') as FormArray;
    this.cutRailArray.controls.forEach((formGroup: FormGroup, i: any) => {
      if (index >= 0 && index === i) {
        const expansionInches = (
          Math.round(
            12 *
            (formGroup.controls['cutRailLength'].value * 0.0000065) *
            (formGroup.controls['designedLayTemp'].value - formGroup.controls['coldRailTemp'].value) *
            8
          ) / 8
        ).toFixed(3);
        console.log('expansion result', expansionInches);
        formGroup.controls['requiredExpandMeasure'].patchValue(Number(expansionInches));
      }
    });
  }

  onActualExpansionChange(value: any, index: any) {
    this.cutRailArray = this.formGroup.get('cutRailArray') as FormArray;
    this.cutRailArray.controls.forEach((formGroup: FormGroup, i: any) => {
      if (index >= 0 && index === i) {
        if (value === null) {
          formGroup.controls['tempChangeMeasure'].patchValue(null);
          formGroup.controls['actualExpandMeasure'].patchValue(null);
          formGroup.controls['railNeutralTemp'].patchValue(null);
          formGroup.controls['showTempBg'].patchValue(null);
          formGroup.updateValueAndValidity();
        } else {
          formGroup.controls['actualExpandMeasure'].patchValue(value);
          this.formSubmitAttempt = false;
          index = Number(index);
          console.log('index', index);
          console.log('value', value);
          this.roundToNearest(index);
          this.calculateMwiTempChange(index);
          this.caluculateRailNeutralTemp(index);
        }
      }
    });
  }

  hasWhiteSpace(s) {
    s = s.toString();
    return s.indexOf(' ') >= 0;
  }

  onColdTempChange(index: any) {
    this.formSubmitAttempt = false;
    this.calculatetempDiff(index);
    this.roundToNearest(index);
    this.cutRailArray.controls.forEach((formGroup: FormGroup, i: any) => {
      if (index >= 0 && index === i && formGroup.controls['actualExpandMeasure'].value != null) {
        this.calculateMwiTempChange(index);
        this.caluculateRailNeutralTemp(index);
      }
    });
  }

  /** Based on the direction of work, sets lowMP and highMP in report object */
  assignLowHighMilepost() {
    if (this.formGroup) {
      if (this.formGroup.controls['direction'].value === undefined || this.formGroup.controls['direction'].value === 'I') {
        this.formGroup.controls['lowMp'].patchValue(this.formGroup.controls['beginMp'].value);
        this.formGroup.controls['highMp'].patchValue(this.formGroup.controls['endMp'].value);
      } else {
        this.formGroup.controls['highMp'].patchValue(this.formGroup.controls['beginMp'].value);
        this.formGroup.controls['lowMp'].patchValue(this.formGroup.controls['endMp'].value);
      }
    }
  }

  calculateTiePlateCount(totalCutRail: number): number {
    return Math.round((totalCutRail * (12 / 20)));
  }

  async formSubmit() {
    if (this.viewMode) {
      this.navigateToPlannedActual();
    } else {
      const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
      this.formGroup.controls['totalOpenJoints'].patchValue(this.totalOpenJoints);
      this.assignLowHighMilepost();
      this.warningMessageCutRail = this.formGroup.controls.cutRailArray.valid;
      console.log('Form submitted', this.formGroup.controls.cutRailArray);
      if (this.totalCutRail > 0 && this.jobName === AppConstant.JOB_TYPE.GAUGING) {
        const tiePlateCount = this.calculateTiePlateCount(this.totalCutRail);
        this.formGroup.controls['tiePlateCount'].patchValue(tiePlateCount);
      }
      try {
        this.formGroup.controls.railSideDisc.value || this.showField
          ? this.formGroup.controls.railSideDisc.enable()
          : this.formGroup.controls.railSideDisc.disable();
        this.formGroup.controls.supervisor.value || this.showField
          ? this.formGroup.controls.supervisor.enable()
          : this.formGroup.controls.supervisor.disable();
        this.getFormValues().cutRailArray = this.getFormValues().cutRailArray.filter((data, val) => {
          return data.cutRailLength !== this.createCutRailGroup().value.cutRailLength;
        });
      } catch (error) {
        console.log('submit rail side catch block' + error);
      }
      this.formSubmitAttempt = true;

      // ****  to make all fields touched and validate
      Object.keys(this.formGroup.controls).forEach(field => {
        const control = this.formGroup.get(field);
        control.markAsTouched({ onlySelf: true });
      });

      console.log(this.formGroup);
      console.log(this.formGroup.valid);
      console.log(this.formGroup.status);
      this.tempchangenanflag = false;
      this.showminCutRailErrorflag = false;
      this.cutRailZeroError = 0;
      this.cutRailArray.controls.forEach((frmgrp: FormGroup, j: any) => {
        const isInstalled = frmgrp.controls['isWorkSkipped'].value;
        if (frmgrp.controls['cutRailLength'].value === 0) {
          this.cutRailZeroError++;
        }
        if (
          isInstalled == null &&
          frmgrp.controls['cutRailLength'].valid &&
          frmgrp.controls['openJoints'].valid &&
          frmgrp.controls['cutRailLength'].value > 0 &&
          frmgrp.controls['openJoints'].value >= 0
        ) {
          this.showCutRailSkipError = true;
        }
        if (!isInstalled && frmgrp.controls['cutRailLength'].valid) {
          this.showminCutRailErrorflag = true;
        }
        if (
          this.cutRailArray.controls.length - 1 === j &&
          this.showminCutRailErrorflag === false &&
          frmgrp.controls['cutRailLength'].valid &&
          frmgrp.controls['cutRailLength'].value > 0
        ) {
          this.showminCutRailError = true;
        }
      });

      await delay(1000);
      if (
        this.formGroup.valid &&
        this.remainLength === 0 &&
        !this.showCutRailOutLimitError &&
        !this.tempchangenanflag &&
        !this.showminCutRailError &&
        !this.railLimitError &&
        this.cutRailZeroError === 0 &&
        !this.cutRailFlag
      ) {
        let count = 0;
        let cutRailCount = this.getFormValues().cutRailArray.length;
        for (const j of this.getFormValues().cutRailArray) {
          cutRailCount--;
          count++;
          if (count === 1) {
            this.startUtil = this.formGroup.controls['beginMp'].value;
          }
          await this.utilService
            .getEndMilePost(this.jobId, this.startUtil, j.cutRailLength, this.formGroup.controls['direction'].value)
            .then(result => {
              if (result === true) {
                this.railLimitError = true;
              } else {
                this.railLimitError = false;
                j.cutRailBeginMp = Number(this.startUtil);
                j.cutRailEndMp = Number(result);
                const fg = <FormGroup>this.cutRailArray.at(count - 1);
                fg.controls['cutRailBeginMp'].patchValue(Number(this.startUtil));
                fg.controls['cutRailEndMp'].patchValue(Number(result));
                this.startUtil = result;
                if ((cutRailCount = 1)) {
                  this.formGroup.controls['endMp'].patchValue(Number(result));
                  this.assignLowHighMilepost();
                }
              }
            });
        }
        if (!this.railLimitError) {
          console.log('form is valid');
          let repObj = this.getFormValues();
          console.log(JSON.stringify(repObj));
          // **** here we are assigning  the other values  not related to the rail info page
          console.log('last repObj');
          if (this.newvalues) {
            Object.assign(repObj, this.newvalues);
          }
          repObj = this.formatData(repObj);
          console.log('saving the report obj in indexdb', repObj);
          this.reportDataServcie.validateDuplicateReports(repObj).then((isDuplicate: boolean) => {
            this.showErrorComponent = isDuplicate;
            if (!isDuplicate) {
              this.saveReportToIndexDbMethod(repObj)
                .then(response => {
                  console.log(response);
                  this.navigateToPlannedActual();
                })
                .catch(reason => {
                  console.log(reason);
                });
            }
          });
        }
      } else {
        console.log('Form is invalid');
      }
    }
  }

  protected getFormValues() {
    return this.formGroup.value;
  }

  protected saveReportToIndexDbMethod(repObj: any) {
    return this.dataService.saveDraftReport(JSON.stringify(repObj));
  }

  protected navigateToPlannedActual() {
    this.router.navigate([`/planned_actual/${this.projectId}/${this.jobId}/${this.formId}`]);
  }

  public onErrorDialogClose(): void {
    this.showErrorComponent = false;
  }

  public onValueChange() {
    this.formSubmitAttempt = false;
  }

  public onSupervisorValueChange(value: any) {
    this.formSubmitAttempt = false;
    this.formGroup.controls['supervisor'].patchValue(value);
  }

  public onRSDValueChange(value: any) {
    this.formSubmitAttempt = false;
    this.formGroup.controls['railSideDisc'].patchValue(value);
    console.log(this.formGroup);
  }

  public formatData(temp: any) {
    try {
      const date: Date = new Date(temp.date);
      const time: Date = new Date(temp.date);

      // **** converting date iso to timestamp
      time.setDate(date.getDate());
      time.setMonth(date.getMonth());
      time.setFullYear(date.getFullYear());
      time.setTime(date.getTime());
      temp.workday = time.getTime();

      if (this.railInfo.railSideDisc) {
        temp.railSideDisc = this.railInfo.railSideDisc;
      }
      if (this.railInfo.supervisor) {
        temp.supervisor = this.railInfo.supervisor;
      }
      console.log('before try');
      console.log('check', temp);
      let arr = [];

      if (temp.cutRailArray) {
        arr = temp.cutRailArray;
        const len = arr.length;

        for (let i = 0; i < len; i++) {
          if (!(!arr[i]['isWorkSkipped'] && (temp.cutRailHeated === 'Y' || !temp.cutRailHeated))) {
            const remainfields = {
              stringId: null,
              designedLayTemp: null,
              coldRailTemp: null,
              changeTempMeasure: null,
              requiredExpandMeasure: null,
              actualExpandMeasure: null,
              tempChangeMeasure: null,
              railNeutralTemp: null,
              tempDiff: null,
              showTempBg: false
            };
            arr[i] = Object.assign(arr[i], remainfields);
          }
        }
        console.log('array temp rail');
        console.log(arr);
      }
      console.log('after loop');
      console.log(temp.cutRailArray);
      /**** we are formatting and putting cutrail array and rail temp into array as cutarray */
      console.log(arr);
      temp = Object.assign(temp, { reportCutRail: arr });
    } catch (error) {
      console.log('final' + error);
      temp = Object.assign(temp, { reportCutRail: temp.cutRailArray });
    }


    if (this.lastestReport) {
      temp['delayIssuesRemark'] = !temp.delayIssuesRemark ? this.lastestReport.delayIssuesRemark : temp.delayIssuesRemark;
      temp['issuesDesc'] = !temp.issuesDesc ? this.lastestReport.issuesDesc : temp.issuesDesc;
      temp['tieUpLocation'] = !temp.tieUpLocation ? this.lastestReport.tieUpLocation : temp.tieUpLocation;
      temp['nextDayLocation'] = !temp.nextDayLocation ? this.lastestReport.nextDayLocation : temp.nextDayLocation;
      temp['plannedStart'] = !temp.plannedStart ? this.lastestReport.plannedStart : temp.plannedStart;
      temp['plannedEnd'] = !temp.plannedEnd ? this.lastestReport.plannedEnd : temp.plannedEnd;
      temp['actualEmployeesCount'] = !temp.actualEmployeesCount ? this.lastestReport.actualEmployeesCount : temp.actualEmployeesCount;
      temp['greenSignalTime'] = !temp.greenSignalTime ? this.lastestReport.greenSignalTime : temp.greenSignalTime;
      temp['fullBlockReceived'] = !temp.fullBlockReceived ? this.lastestReport.fullBlockReceived : temp.fullBlockReceived;
      temp['pickupRemainCount'] = !temp.pickupRemainCount ? this.lastestReport.pickupRemainCount : temp.pickupRemainCount;
      temp['trackReturnLate'] = !temp.trackReturnLate ? this.lastestReport.trackReturnLate : temp.trackReturnLate;
      temp['trackReturnLateRemarks'] =
        !temp.trackReturnLateRemarks ? this.lastestReport.trackReturnLateRemarks : temp.trackReturnLateRemarks;
      if (this.lastestReport.reportWindows) {
        const modifiedWindows: any[] = [];
        this.lastestReport.reportWindows.forEach((window: any, i: any) => {
          window.unitsInstalled = null;
          window.unitPerHour = null;
          modifiedWindows.push(window);
        });
        temp['reportWindows'] = modifiedWindows;
      }
    } else if (!this.lastestReport) {
      if (!this.editMode) {
        this.ls.getNotes(this.teamName).then((notesData: any) => {
          if (notesData && notesData.notesObj && this.formId < 0) {
            temp['plannedStart'] = notesData.notesObj.plannedStart ? notesData.notesObj.plannedStart : temp.plannedStart;
            temp['plannedEnd'] = notesData.notesObj.plannedEnd ? notesData.notesObj.plannedEnd : temp.plannedEnd;
            temp['greenSignalTime'] = notesData.notesObj.greenSignalTime ? notesData.notesObj.greenSignalTime : temp.greenSignalTime;
            if (notesData.notesObj.reportWindows) {
              temp['reportWindows'] = notesData.notesObj.reportWindows;
            }
          }
        });
      }
    }
    // remove elements
    /**** here deleting all the unwanted object keys **/
    delete temp['cutRailArray'];
    delete temp['date'];
    delete temp['cutRailArray'];
    delete temp['railTempArray'];
    console.log('final' + temp);
    console.log(temp);
    return temp;
  }

  // remove elements
  /**** here deleting all the unwanted object keys **/
  public eventHandler(e, value, max) {
    const currentChar = parseInt(String.fromCharCode(e.keyCode), 10);
    console.log(value);
    if (!isNaN(currentChar)) {
      const toStringvalue = value == null ? '' : value.toString();
      const nextValue = toStringvalue + currentChar; // It's a string concatenation, not an addition
      if (parseInt(nextValue, 10) <= max) {
        return true;
      }
    }
    return false;
  }

  backClick(flag: boolean) {
    this.assignLowHighMilepost();
    if (this.viewMode) {
      this.router.navigate([`/job_view/${this.projectData}`]);
    } else {
      if (flag) {
        console.log('recieved back ');
        const todayDate = new Date();
        const timestampdate = new Date(todayDate).toISOString();
        const currenttime = new Date(timestampdate);
        if (this.formGroup.controls['date'].value < 1) {
          this.formGroup.controls['date'].patchValue(currenttime);
        }
        let repObj = this.getFormValues();
        console.log('back repoert', repObj);
        if (this.newvalues) {
          Object.assign(repObj, this.newvalues);
        }
        console.log(JSON.stringify(repObj));
        repObj = this.formatData(repObj);
        console.log('saving the report obj in indexdb', repObj);
        this.dataService
          .saveDraftReport(JSON.stringify(repObj))
          .then(response => {
            console.log(response);
          })
          .catch(reason => {
            console.log(reason);
          });
        this.router.navigate([`/job_view/${this.projectData}`]);
      }
    }
  }

  public onDutyDateTimeChange(event: any) {
    const onDutyWorkDate = this.onDutyTime ? new Date(this.onDutyTime) : new Date();
    this.reportDataServcie.getLatestProdReportFromAllProjects(this.teamName, onDutyWorkDate).subscribe(
      lastestReportObj => {
        console.log('latest report', lastestReportObj);
        this.lastestReport = lastestReportObj;
        if (!lastestReportObj) {
          if (lastestReportObj === null || lastestReportObj === undefined) {
            this.ls.getCurrentUser('SYSTEM').then(data => {
              this.teamName = data ? data.teamName : null;
              this.ls.getNotes(this.teamName).then((notesData: any) => {
                if (notesData && notesData.notesObj.onDutyTime) {
                  const notes = JSON.parse(JSON.stringify(notesData));
                  this.onDutyTime = this.onDutyTime ? this.onDutyTime : notes.notesObj.onDutyTime;
                  this.formGroup.get('date').patchValue(this.onDutyTime);
                }
              });
            });
            this.formGroup.patchValue({ remarks: null });
          }
        } else {
          if (event === null) {
            if (this.jobName === AppConstant.JOB_TYPE.GAUGING || this.jobName === AppConstant.JOB_TYPE.CONCRETE_PAD) {
              this.formGroup.controls['date'].patchValue(this.utilService.convertTimeStampTOdate(lastestReportObj.workday ||
                this.onDutyTime));
            } else {
              this.onDutyTime = this.utilService.convertTimeStampTOdate(lastestReportObj.workday);
            }
          }
          this.formGroup.patchValue({ remarks: lastestReportObj.remarks });
        }
      },
      error => { },
      () => { }
    );
  }
  validateOnDutyTime(event?: any) {
    if (this.checkReportsOnDutyDate()) {
      this.dateTimeWarning = true;
      this.dateTimeWarningMessage = 'Rest day report exists on this day.';
    } else {
      this.dateTimeWarning = false;
      this.dateTimeWarningMessage = '';
    }
    if (!this.dateTimeWarning && event) {
      this.onDutyDateTimeChange(event);
    }
  }

  getProjectReports(): void {
    this.ls.getProjectReports(Number(this.projectId)).then((reportList: Array<ReportTable>) => {
      for (const p of reportList) {
        const js: any = JSON.parse(JSON.stringify(p.reportObj));
        this.projectReports.push(js);
      }
    });
  }

  checkReportsOnDutyDate(): boolean {
    let match = false;
    const onDuty = this.onDutyTime.split('T')[0];
    for (const report of this.projectReports) {
      const compare = this.convertEpochToDate(report.workday);
      let reportType = '';
      if (report.hasOwnProperty('noProductionCode')) {
        if (report['noProductionCode'] === 'RST') {
          reportType = 'REST';
        } else {
          reportType = 'NO PRODUCTION';
        }
      } else {
        reportType = 'PRODUCTION';
      }
      if (reportType === 'REST' && compare === onDuty && this.teamName === report.teamName) {
        match = true;
        break;
      }
    }
    return match;
  }

  convertEpochToDate(epoch: number) {
    if (epoch == null) {
      return null;
    }
    let date = new Date(epoch).toLocaleDateString().replace(/\//g, '-');
    const dateArray = date.split('-');
    date = dateArray[2] + '-' + this.convertSingleDigitToDouble(dateArray[0]) + '-' + this.convertSingleDigitToDouble(dateArray[1]);
    return date;
  }

  convertSingleDigitToDouble(value: string): string {
    if (value.length === 1) {
      return '0' + value;
    } else {
      return value;
    }
  }

  tunnelChange(index: number) {
    const formgroup = this.cutRailArray['controls'][index]['controls'];
    if (formgroup['isTunnel'].value === 'Y') {
      if (this.reportobject.desiredNeutralTemp != null) {
        formgroup['designedLayTemp'].patchValue(this.reportobject.desiredNeutralTemp - 20);
        formgroup['designedLayTemp'].clearValidators();
      } else {
        formgroup['designedLayTemp'].patchValue(null);
        this.designedRailLayingTemp[index] = this.designedTunnelTemp;
      }
    } else if (formgroup['isTunnel'].value === 'N') {
      if (this.reportobject.desiredNeutralTemp != null) {
        formgroup['designedLayTemp'].patchValue(this.reportobject.desiredNeutralTemp);
        formgroup['designedLayTemp'].clearValidators();
      } else {
        formgroup['designedLayTemp'].patchValue(null);
        this.designedRailLayingTemp[index] = this.designedRailTemp;
      }
    }
    this.recalculateCutRail(index);
    this.formGroup.updateValueAndValidity();
  }

  private updateRailSide(railSide: string): void {
    this.formSubmitAttempt = false;
    if (this.jobName === AppConstant.JOB_TYPE.OUT_OF_FACE_RAIL || this.jobName === AppConstant.JOB_TYPE.GAUGING) {
      this.showField = false;
      this.warningMessageRailSide = false;
    } else {
      this.showField = this.railSideOrgVal !== railSide;
      this.showField ? this.formGroup.controls.railSideDisc.enable() : this.formGroup.controls.railSideDisc.disable();
      this.showField ? this.formGroup.controls.supervisor.enable() : this.formGroup.controls.supervisor.disable();
      this.showField ? this.railInfo.railSideDisc = this.reportobject && this.reportobject.railSideDisc : this.railInfo.railSideDisc = null;
      this.showField ? this.railInfo.supervisor = this.reportobject && this.reportobject.supervisor : this.railInfo.supervisor = null;
    }
    if (!this.showField) {
      this.formGroup.controls['railSideDisc'].patchValue(null);
      this.formGroup.controls['supervisor'].patchValue(null);
    }

    if (railSide !== this.railSideOrgVal &&
      (this.jobName === AppConstant.JOB_TYPE.PATCH_RAIL || this.jobName === AppConstant.JOB_TYPE.CONCRETE_PAD) && railSide) {
      this.warningMessageRailSide = true;
    } else {
      this.warningMessageRailSide = false;
    }
  }

  hasDesiredNeutralTemp(): boolean {
    return this.reportobject.desiredNeutralTemp != null ? true : false;
  }

  isBallastSufficentIsvalid(i: number): boolean {
    return this.formGroup['controls'].cutRailArray['controls'][i]['controls']['isBallastSufficient'].enabled &&
      ((!this.formGroup['controls'].cutRailArray['controls'][i]['controls']['isBallastSufficient'].valid &&
        this.formGroup['controls'].cutRailArray['controls'][i]['controls']['isBallastSufficient'].touched) ||
        (this.formGroup['controls'].cutRailArray['controls'][i]['controls']['isBallastSufficient'].untouched && this.formSubmitAttempt));
  }
}
