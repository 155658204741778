import { Observable } from 'rxjs/Observable';
import { LoggerService } from './logger.service';
import { Injectable, EventEmitter } from '@angular/core';
import { AdapterService } from '../services/adapter.service';
import {
  LocalStorageService,
  ReferenceCode,
  ProjectTable,
  JobTable,
  RoadCrossingTable,
  ReportTable,
  UserTable,
  SyncTable,
  NotesTable,
  SupervisorRacfsTable
} from './local-storage.service';
import { Configuration } from './dataservice.constants';
import Dexie from 'dexie';
import { CurrentUserTable, TurnoutTable } from './local-storage.service';
import { JobModel } from '../model/job.model';
import { Turnout } from '../model/turnout.model';
import { Project } from '../model/project.model';
import { ReportWindow } from '../model/reportWindow.model';
import { Report } from '../model/report.user';
import { FlexObject } from 'csx-flex-ng';

@Injectable()
export class DataService {
  currentUser: CurrentUserTable;
  currentUserExists = false;
  onlineStatus: string;
  status: boolean;
  mac: string;
  racf: string;
  team: string;
  loginFlag: boolean;
  proxyFlag: boolean;
  stwUserRole: string;
  userName: string;
  emitter = new EventEmitter<any>();
  Flex: FlexObject;

  constructor(
    private logger: LoggerService,
    private ad: AdapterService,
    private rs: LocalStorageService,
    private conf: Configuration
  ) {
    this.Flex = FlexObject.getInstance();
    this.onlineStatus = navigator.onLine ? 'app-online' : 'app-offline';
    this.getStatus();
    this.mac = 'NotAvailable';
    this.racf = this.Flex.user.id;
    this.rs.getCurrentUser('SYSTEM').then((currentUser: CurrentUserTable) => {
      this.team = currentUser.teamName;
    });

    this.userName =
      this.Flex.user.firstName +
      ' ' +
      this.Flex.user.lastName +
      ' (' +
      this.Flex.user.id +
      ')';
  }

  OnInit() {
    this.status = this.getStatus();
    this.onlineStatus = this.getOnlineStatus();
    this.logger.log(this.onlineStatus);
  }

  loginUser() {
    this.rs
      .getUser(this.Flex.user.id)
      .then(res => {
        this.loginFlag = true;
        const userData = JSON.parse(JSON.stringify(res.userObj));
        // this.team = userData.teamName;
        this.stwUserRole = userData.userRole;
        this.racf = this.Flex.user.id;
      })
      .catch(err => {
        console.log(err);
        this.getUser(this.Flex.user.id)
          .then(res => {
            this.loginFlag = true;
            this.rs.getUser(this.Flex.user.id).then(res1 => {
              const userData = JSON.parse(JSON.stringify(res1.userObj));
              // this.team = userData.teamName;
              this.stwUserRole = userData.userRole;
              this.racf = this.Flex.user.id;
            });
          })
          .catch(e => {
            let tokenFound = false;

            if (this.Flex.user.groups.join(',').match(new RegExp('eng.stw.admin'))) {
              console.log('Admin token found');
              tokenFound = true;
              this.stwUserRole = 'Admin';
            } else if (
              this.Flex.user.groups.join(',').match(new RegExp('eng.stw.developer'))
            ) {
              console.log('Developer token found');
              tokenFound = true;
              this.stwUserRole = 'Developer';
            } else if (
              this.Flex.user.groups.join(',').match(new RegExp('eng.stw.user'))
            ) {
              console.log('User token found');
              tokenFound = true;
              this.stwUserRole = 'STW Manager';
            }

            if (tokenFound) {
              const userData1 = [
                {
                  mac: 'Not Available',
                  teamName: '',
                  employeeID: '',
                  firstName: this.Flex.user.firstName,
                  lastName: this.Flex.user.lastName,
                  racf: this.Flex.user.id,
                  fullName: this.userName,
                  emailAddress: 'Not Yet Impelemented',
                  userRole: this.stwUserRole,
                  lastSyncDate: null,
                  projects: null
                }
              ];
              Object.keys(userData1).forEach(key => {
                const userVar = userData1[key];
                const userTableObj: UserTable = {
                  racf: userVar.racf,
                  userObj: userVar
                };
                this.rs.addUser(userTableObj);
              });
            }

            console.log(e);
          });
      });

    this.rs
      .getCurrentUser('SYSTEM')
      .then((currentUser: CurrentUserTable) => {
        this.team = currentUser.teamName;
      })
      .catch(e => {
        console.log('current table is empty');
      });
  }

  addCurrentUser(currentTableObj) {
    try {
      console.log('Add current user');
      return new Promise((resolve, reject) => {
        this.rs.addUser(currentTableObj).then(val => {
          this.currentUserExists = true;
          this.currentUser = currentTableObj;
          resolve('CURRENT USER ADDED.');
        });
      });
    } catch (e) {
      throw e;
    }
  }

  getStatus(): boolean {
    this.status = this.onlineStatus === 'app-online' ? true : false;
    this.onlineStatus =
      this.onlineStatus === 'app-online' ? 'app-online' : 'app-offline';
    this.logger.log(this.status);
    return this.status;
  }

  getOnlineStatus(): string {
    this.onlineStatus =
      this.onlineStatus === 'app-online' ? 'app-online' : 'app-offline';
    this.logger.log(this.onlineStatus);
    return this.onlineStatus;
  }

  getUserData(user: string) {
    return new Promise<number>(resolve => {
      const users = [];
      this.getUserNew(user).subscribe(
        r => {
          users.push(r);
        },
        e => {
          resolve(2);
        },
        () => {
          resolve(1);
          console.log('processing users completed....', users);
        }
      );
    }).catch(e => {
      console.log(e);
      throw e;
    });
  }

  getUserNew(newUser): Observable<number> {
    console.log('this.racf' + this.racf);
    console.log(' this.Flex.user.id' + this.Flex.user.id);
    if (this.racf !== this.Flex.user.id) {
      this.racf = this.Flex.user.id;
    }
    try {
      return Observable.create(observer => {
        if (this.status) {
          this.ad
            .executeService(
              this.conf.createUserURL(this.mac, newUser),
              'GET',
              '{}'
            )
            .then(response => {
              if (response.invocationResult.statusCode !== 200) {
                console.log('Adapter failed' + response.invocationResult);
                observer.error(response.invocationResult);
              }
              const resultProjectList: any[] = response.invocationResult.result;
              console.log('res length:::', resultProjectList.length);
              if (resultProjectList.length === 0) {
                observer.complete();
              } else {
                const user = [];
                new Promise((resolve, reject) => {
                  let count = resultProjectList.length;
                  const pObs = Observable.create(p => {
                    const resultList = response.invocationResult.result;
                    Object.keys(resultList).forEach(key => {
                      const userVar = resultList[key];
                      const userTableObj: UserTable = {
                        racf: userVar.racf,
                        userObj: userVar
                      };
                      count--;
                      observer.next(userVar.racf);
                      user.push(userTableObj);
                      if (count === 0) {
                        p.complete();
                        observer.complete();
                      }
                    });
                  });
                  pObs.subscribe(
                    r => { },
                    e => {
                      resolve();
                    },
                    () => {
                      resolve();
                    }
                  );
                })
                  .then(() => {
                    this.rs
                      .addBulkUser(user)
                      .then(function (lastKey) {
                        console.log('Done adding user', user.length);
                        console.log('Last user id was: ' + lastKey);
                        observer.next(user.length);
                        observer.complete();
                      })
                      .catch(Dexie.BulkError, function (e) {
                        observer.error(e);
                        console.error(
                          e.failures.length + ' user was added successfully'
                        );
                      });
                  })
                  .catch(e => {
                    observer.error(e);
                  });
              }
            });
        } else {
          // observer.next(361);
          observer.complete();
        }
      });
    } catch (e) {
      throw e;
    }
  }

  getUser(newUser: string): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          this.ad
            .executeService(
              this.conf.createUserURL(this.mac, newUser),
              'GET',
              '{}'
            )
            .then(response => {
              if (
                response.invocationResult.statusCode === 200 &&
                response.invocationResult.errorName === 'SUCCESS'
              ) {
                const resultList = response.invocationResult.result;
                Object.keys(resultList).forEach(key => {
                  const userVar = resultList[key];
                  const userTableObj: UserTable = {
                    racf: userVar.racf,
                    userObj: userVar
                  };
                  this.rs.addUser(userTableObj).then(val => {
                    resolve('USER FOUND.');
                  });
                });
              } else {
                reject(new Error('USER IS NOT FOUND.'));
              }
            });
        } else {
          reject(new Error('APP IS OFFLINE'));
        }
      });
    } catch (e) {
      throw e;
    }
  }

  getSyncInfo(racId: string, syncCompleted: string, team: string): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        this.ad
          .executeService(
            this.conf.createLastSyncURL(this.mac, racId, syncCompleted, team),
            'GET',
            '{}'
          )
          .then(response => {
            if (
              response.invocationResult.statusCode === 200 &&
              response.invocationResult.errorName === 'SUCCESS'
            ) {
              const resultList = response.invocationResult.result;
              Object.keys(resultList).forEach(key => {
                const syncVar = resultList[key];
                const syncTableObj: SyncTable = {
                  racf: syncVar.racf,
                  syncObj: syncVar
                };
                this.rs.addSyncInfo(syncTableObj).then(val => {
                  resolve('SYNC INFO FOUND.');
                });
              });
            } else {
              reject('SYNC INFO NOT FOUND.');
            }
          });
      });
    } catch (e) {
      throw e;
    }
  }

  getProjectInfo(project: number): Promise<any> {
    return this.rs.getProject(project);
  }

  getJobInfo(jobId: number): Promise<any> {
    return this.rs.getJob(jobId);
  }

  getSupervisoryData(): Observable<any> {
    try {
      return Observable.create(observer => {
        if (this.status) {
          this.ad.executeService(this.conf.createSupervisorDataUrl(), 'GET', '{}').then(res => {
            if (res.invocationResult.statusCode !== 200) {
              console.log('Adapter failed' + res.invocationResult);
              observer.error(res.invocationResult);
            } else if (res.invocationResult.errorNumber === 0) {
              const resultList: any[] = res.invocationResult.result;
              if (resultList.length === 0) {
                observer.complete();
              } else {
                const userData = [];
                new Promise((resolve, reject) => {
                  let count = resultList.length;
                  const userObj = Observable.create(u => {
                    Object.keys(resultList).forEach(key => {
                      const user = resultList[key];
                      if (user.rlCode[0] === null) {
                        user.rlCode[0] = 'XX';
                      }
                      const supervisorTable: SupervisorRacfsTable = {
                        rlCode: user.rlCode[0],
                        userObj: user
                      };
                      count--;
                      observer.next(user.project);
                      userData.push(supervisorTable);
                      if (count === 0) {
                        u.complete();
                        observer.complete();
                      }
                    });
                  });
                  userObj.subscribe(
                    r => { },
                    e => {
                      resolve();
                    },
                    () => {
                      resolve();
                    }
                  );
                })
                  .then(() => {
                    this.rs
                      .addBulkSupervisorydata(userData)
                      .then(function (lastKey) {
                        console.log('Done adding Supervisor data', userData.length);
                        console.log('Last Rlcode added  was: ' + lastKey);
                        observer.next(userData.length);
                        observer.complete();
                      })
                      .catch(Dexie.BulkError, function (e) {
                        observer.error(e);
                        console.error(
                          e.failures.length + ' SupervisoryData was added successfully'
                        );
                      });
                  })
                  .catch(e => {
                    observer.error(e);
                  });
              }
            } else {
              console.log('Error while fetchin Supervisory data', res.invocationResult.errorNumber);
              observer.complete();
            }
          });
        } else {
          observer.complete();
        }
      });
    } catch (e) {
      console.log('Error while adding Supervisory data', e);
    }
  }

  getSupervisory() {
    return new Promise<number[]>(resolve => {
      const userData = [];
      this.getSupervisoryData().subscribe(
        r => {
          userData.push(r);
        },
        e => {
          resolve(userData);
        },
        () => {
          resolve(userData);
          console.log('processing supervisory userdata completed....', userData);
        }
      );
    }).catch(e => {
      console.log(e);
    });
  }

  getProjectsNew(): Observable<number> {
    console.log('this.racf' + this.racf);
    console.log(' this.Flex.user.id' + this.Flex.user.id);
    if (this.racf !== this.Flex.user.id) {
      this.racf = this.Flex.user.id;
    }
    try {
      return Observable.create(observer => {
        if (this.status) {
          this.ad
            .executeService(
              this.conf.createProjectsURL(this.mac, this.racf, null, this.team),
              'GET',
              '{}'
            )
            .then(response => {
              if (response.invocationResult.statusCode !== 200) {
                console.log('Adapter failed' + response.invocationResult);
                observer.error(response.invocationResult);
              }
              const resultProjectList: any[] = response.invocationResult.result;
              console.log('res length:::', resultProjectList.length);
              if (resultProjectList.length === 0) {
                observer.complete();
              } else {
                const projects = [];
                new Promise((resolve, reject) => {
                  let count = resultProjectList.length;
                  const pObs = Observable.create(p => {
                    Object.keys(resultProjectList).forEach(key => {
                      const projectVar = resultProjectList[key];
                      const projectTableObj: ProjectTable = {
                        project: projectVar.project,
                        projectObj: projectVar
                      };
                      count--;
                      observer.next(projectVar.project);
                      projects.push(projectTableObj);
                      if (count === 0) {
                        p.complete();
                        observer.complete();
                      }
                    });
                  });
                  pObs.subscribe(
                    r => { },
                    e => {
                      resolve();
                    },
                    () => {
                      resolve();
                    }
                  );
                })
                  .then(() => {
                    this.rs
                      .addBulkProjects(projects)
                      .then(function (lastKey) {
                        console.log('Done adding projects', projects.length);
                        console.log('Last project id was: ' + lastKey);
                        observer.next(projects.length);
                        observer.complete();
                      })
                      .catch(Dexie.BulkError, function (e) {
                        observer.error(e);
                        console.error(
                          e.failures.length + ' projects was added successfully'
                        );
                      });
                  })
                  .catch(e => {
                    observer.error(e);
                  });
              }
            });
        } else {
          // observer.next(361);
          observer.complete();
        }
      });
    } catch (e) {
      throw e;
    }
  }

  getProject(project: number): Observable<number> {
    console.log('this.racf' + this.racf);
    console.log(' this.Flex.user.id' + this.Flex.user.id);
    if (this.racf !== this.Flex.user.id) {
      this.racf = this.Flex.user.id;
    }
    try {
      return Observable.create(observer => {
        if (this.status) {
          this.ad
            .executeService(
              this.conf.createProjectsURL(
                this.mac,
                this.racf,
                project + '',
                this.team
              ),
              'GET',
              '{}'
            )
            .then(response => {
              if (response.invocationResult.statusCode !== 200) {
                console.log('Adapter failed' + response.invocationResult);
                observer.error(response.invocationResult);
              }
              const resultProjectList: any[] = response.invocationResult.result;
              console.log('res length:::', resultProjectList.length);
              if (resultProjectList.length === 0) {
                observer.complete();
              } else {
                const projects = [];
                new Promise((resolve, reject) => {
                  let count = resultProjectList.length;
                  const pObs = Observable.create(p => {
                    Object.keys(resultProjectList).forEach(key => {
                      const projectVar = resultProjectList[key];
                      const projectTableObj: ProjectTable = {
                        project: projectVar.project,
                        projectObj: projectVar
                      };
                      count--;
                      observer.next(projectVar.project);
                      projects.push(projectTableObj);
                      if (count === 0) {
                        p.complete();
                        observer.complete();
                      }
                    });
                  });
                  pObs.subscribe(
                    r => { },
                    e => {
                      resolve();
                    },
                    () => {
                      resolve();
                    }
                  );
                })
                  .then(() => {
                    this.rs
                      .addBulkProjects(projects)
                      .then(function (lastKey) {
                        console.log('Done adding projects', projects.length);
                        console.log('Last project id was: ' + lastKey);
                        observer.next(projects.length);
                        observer.complete();
                      })
                      .catch(Dexie.BulkError, function (e) {
                        observer.error(e);
                        console.error(
                          e.failures.length + ' projects was added successfully'
                        );
                      });
                  })
                  .catch(e => {
                    observer.error(e);
                  });
              }
            });
        } else {
          // observer.next(361);
          observer.complete();
        }
      });
    } catch (e) {
      throw e;
    }
  }

  getJobsNew(project: number): Observable<JobTable[]> {
    console.log('step 1');
    try {
      return Observable.create(observer => {
        if (this.status) {
          this.ad
            .executeService(
              this.conf.createJobsURL(
                this.mac,
                this.racf,
                JSON.stringify(project),
                this.team
              ),
              'GET',
              '{}'
            )
            .then(response => {
              console.log(response);
              if (response.invocationResult.statusCode !== 200) {
                console.log('Adapter failed' + response.invocationResult);
                observer.error(response.invocationResult);
              } else {
                const resultJobList: any[] = response.invocationResult.result;
                console.log('res length:::', resultJobList.length);
                if (resultJobList.length === 0) {
                  console.log(project, '--jobs obs-complete');
                  observer.complete();
                } else {
                  const jobs = [];
                  let count = resultJobList.length;
                  new Promise((resolve, reject) => {
                    const jObs = Observable.create(p => {
                      Object.keys(resultJobList).forEach(key => {
                        const jobVar = resultJobList[key];
                        const jobVarTableObj: JobTable = {
                          jobId: jobVar.job,
                          projectId: jobVar.project,
                          jobObj: jobVar
                        };
                        jobs.push(jobVarTableObj);
                        count--;
                        if (count === 0) {
                          p.complete();
                        }
                      });
                    });
                    jObs.subscribe(
                      r => { },
                      e => {
                        resolve();
                      },
                      () => {
                        resolve();
                      }
                    );
                  })
                    .then(() => {
                      this.rs
                        .addBulkJobs(jobs)
                        .then(function (lastKey) {
                          console.log('Done adding jobs', jobs.length);
                          console.log('Last job id was: ' + lastKey);
                          observer.next(jobs.length);
                          observer.complete();
                        })
                        .catch(Dexie.BulkError, function (e) {
                          observer.error(e);
                          console.error(
                            e.failures.length + ' failed inserting the reports'
                          );
                        });
                    })
                    .catch(e => {
                      observer.error(e);
                    });
                }
              }
            });
          console.log('step 3');
        } else {
          observer.complete();
        }
      });
    } catch (e) {
      throw e;
    }
  }

  getReportsNew(projectId: number, jobId: number): Observable<ReportTable[]> {
    try {
      return Observable.create(observer => {
        if (this.status) {
          this.ad
            .executeService(
              this.conf.createReportsUrl(
                this.mac,
                this.racf,
                JSON.stringify(projectId),
                this.team
              ),
              'GET',
              '{}'
            )
            .then(response => {
              if (response.invocationResult.statusCode !== 200) {
                console.log('Adapter failed' + response.invocationResult);
                observer.error(response.invocationResult);
              } else {
                const resultReportList: any[] = response.invocationResult.result;
                console.log('res length:::', resultReportList.length);
                if (resultReportList.length === 0) {
                  console.log(projectId, '--reports obs-complete');
                  observer.complete();
                } else {
                  const reports = [];
                  let count = resultReportList.length;
                  new Promise((resolve, reject) => {
                    const rObs = Observable.create(p => {
                      Object.keys(resultReportList).forEach(key => {
                        const repVar = resultReportList[key];
                        repVar.status = null;
                        const varTableObj: ReportTable = {
                          formId: repVar.formId,
                          jobId: repVar.jobId,
                          projectId: projectId,
                          reportObj: repVar
                        };
                        reports.push(varTableObj);
                        count--;
                        if (count === 0) {
                          p.complete();
                        }
                      });
                    });
                    rObs.subscribe(
                      r => { },
                      e => {
                        resolve();
                      },
                      () => {
                        resolve();
                      }
                    );
                  }).then(() => {
                    this.rs
                      .addBulkReports(reports)
                      .then(function (lastKey) {
                        console.log('Done adding reports', reports.length);
                        console.log('Last report form id was: ' + lastKey);
                        observer.next(reports.length);
                        observer.complete();
                      })
                      .catch(Dexie.BulkError, function (e) {
                        observer.error(e);
                        console.error(
                          e.failures.length + ' failed inserting the reports'
                        );
                      });
                  });
                }
              }
            });
        } else {
          observer.complete();
        }
      });
    } catch (e) {
      throw e;
    }
  }

  getRoadCrossingDataNew(
    project: number,
    team: string,
    prefix: string,
    lowMP: string,
    highMP: string,
    dotNum: string,
    jobId: number
  ): Observable<RoadCrossingTable[]> {
    console.log(this.mac);
    console.log('this.racf', this.racf);
    try {
      return Observable.create(observer => {
        if (this.status) {
          this.ad
            .executeService(
              this.conf.createRoadCrossingURL(
                this.mac,
                this.racf,
                project,
                team,
                prefix,
                lowMP,
                highMP,
                dotNum
              ),
              'GET',
              '{}'
            )
            .then(response => {
              if (response.invocationResult.statusCode !== 200) {
                console.log('Adapter failed' + response.invocationResult);
                observer.error(response.invocationResult);
              } else {
                const resultList = response.invocationResult.result;
                if (resultList.length === 0) {
                  console.log(project, '--rx obs-complete');
                  observer.complete();
                } else {
                  const rx = [];
                  let count = resultList.length;
                  new Promise((resolve, reject) => {
                    const rObs = Observable.create(p => {
                      Object.keys(resultList).forEach(key => {
                        const resultVar = resultList[key];
                        const tObj: RoadCrossingTable = {
                          roadCrossingId: resultVar.dotNumber,
                          jobId: -1, // resultVar.jobId'',
                          projectId: project,
                          prefix: resultVar.prefix,
                          milePost: resultVar.milepost,
                          rxObj: resultVar
                        };
                        rx.push(tObj);
                        count--;
                        if (count === 0) {
                          p.complete();
                        }
                      });
                    });
                    rObs.subscribe(
                      r => { },
                      e => {
                        resolve();
                      },
                      () => {
                        resolve();
                      }
                    );
                  })
                    .then(() => {
                      this.rs
                        .addBulkRoadCrossings(rx)
                        .then(function (lastKey) {
                          console.log('Done adding road crossings', rx.length);
                          console.log('Last rx id was: ' + lastKey);
                          observer.next(rx.length);
                          observer.complete();
                        })
                        .catch(Dexie.BulkError, function (e) {
                          observer.error(e);
                          console.error(
                            e.failures.length +
                            ' failed inserting the roadcrossing'
                          );
                        });
                    })
                    .catch(e => {
                      console.error(e);
                      observer.error(e);
                    });
                }
              }
            })
            .catch(e => {
              console.error(e);
              observer.error(e);
            });
        } else {
          console.log('not online');
          observer.complete();
        }
      });
    } catch (e) {
      throw e;
    }
  }

  getReport(formId): Promise<any> {
    return this.rs.getReport(formId);
  }

  getNotes(teamName: String) {
    return this.rs.getNotes(teamName);
  }

  addEditedReport(reportData) {
    return this.rs.addEditedReport(reportData);
  }

  getEditedReportByFormId(formId) {
    return this.rs.getEditedReportByFormId(formId);
  }

  deleteEditedReportByFormId(formId) {
    return this.rs.deleteEditedReportByFormId(formId);
  }

  deleteAllEditDraftReports() {
    this.rs.deleteAllEditDraftReports().then(() => {
      console.log('Edit reports table cleared.');
    });
  }

  getReportTrackUtil(formId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.storeReportData(formId).then((report: ReportTable) => {
        this.passReportData(report);
        resolve(report);
      });
    });
  }

  getSupervisorUser(rlCode: string) {
    return this.rs.getSupervisorUser(rlCode);
  }

  protected storeReportData(formId: number) {
    return this.rs.getReport(formId);
  }

  getEditReportTrackUtil(formId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.rs.getEditedReportByFormId(formId).then((report: ReportTable) => {
        this.passReportData(report);
        resolve(report);
      });
    });
  }

  passReportData(report) {
    const reportObject = JSON.parse(JSON.stringify(report.reportObj));
    const delayTime: Date = reportObject.delayTime;
    const plannedStart: Date = reportObject.plannedStartTime;
    const plannedEnd: Date = reportObject.plannedEndTime;
    const reportWindows: ReportWindow[] = reportObject.reportWindows;

    if (delayTime != null) {
      report.reportObj['delayTime'] = this.convertTimeStampTOHHMM(
        delayTime
      );
    }
    if (plannedStart != null) {
      report.reportObj['plannedStart'] = this.convertTimeStampTOdate(
        plannedStart
      );
    }
    if (plannedEnd != null) {
      report.reportObj['plannedEnd'] = this.convertTimeStampTOdate(
        plannedEnd
      );
    }

    if (reportWindows !== undefined && reportWindows !== null) {
      reportWindows.forEach(window => {
        if (window.windowStart !== null) {
          window.windowStarts = this.convertTimeStampTOdate(
            window.windowStart
          );
          window.windowEnds = this.convertTimeStampTOdate(window.windowEnd);
        }
      });
      console.log('getReportTrackUtil reportWindows', reportWindows);
      report.reportObj['reportWindows'] = reportWindows;
    }
  }


  saveDraftReport(jobStr: any): Promise<any> {
    try {
      const jobObj = JSON.parse(jobStr);
      console.log('Report Object is', jobObj);
      jobObj.status = 'D';
      jobObj.submittedReportDate = new Date().getTime();
      const varTableObj: ReportTable = {
        formId: Number(jobObj.formId),
        jobId: Number(jobObj.jobId),
        projectId: Number(jobObj.projectId),
        reportObj: jobObj
      };
      return this.rs.addReport(varTableObj);
    } catch (err) {
      console.log(
        'Dixie error while saving draft report.',
        err.getErrorMessage()
      );
    }
  }

  submitReport(jobStr: any): Promise<any> {
    const jobObj = JSON.parse(jobStr);
    jobObj.status = 'S';
    jobObj['submittedReportDate'] = new Date().getTime();
    const varTableObj: ReportTable = {
      formId: Number(jobObj.formId),
      jobId: Number(jobObj.jobId),
      projectId: Number(jobObj.projectId),
      reportObj: jobObj
    };
    return this.rs.addReport(varTableObj);
  }

  submitNotes(notesStr: any): Promise<any> {
    try {
      const notesObj = JSON.parse(notesStr);
      const varTableObj: NotesTable = {
        teamName: notesObj.teamName,
        notesObj: notesObj
      };
      return this.rs.addNotes(varTableObj);
    } catch (err) {
      console.log(
        'Dixie error while saving notes report.',
        err.getErrorMessage()
      );
    }
  }

  public convertTimeStampTOdate(timeStamp) {
    const timestamp = new Date(new Date(timeStamp).toISOString());
    const date =
      timestamp.getFullYear() +
      '-' +
      ('0' + (timestamp.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + timestamp.getDate()).slice(-2) +
      'T' +
      ('0' + timestamp.getHours()).slice(-2) +
      ':' +
      ('0' + timestamp.getMinutes()).slice(-2);
    return date;
  }


  public convertTimeStampTOHHMM(timeStamp) {
    // let timestamp = new Date(new Date(timeStamp).toISOString());
    // let date =
    //   ('0' + timestamp.getHours()).slice(-2) +
    //   ':' +
    //   ('0' + timestamp.getMinutes()).slice(-2);

    return timeStamp.substr(0, 2) + ':' + timeStamp.substr(2);
  }
  async processReport(reportObj: string, formId: number): Promise<any> {
    if (this.status) {
      let res;
      await this.ad
        .executeService(
          this.conf.createSubmitReportsUrl(this.mac, this.racf),
          'POST',
          reportObj
        )
        .then(response => {
          res = response.invocationResult;

          if (res.errorName === 'SUCCESS') {
            const ro = JSON.parse(JSON.stringify(reportObj));
            console.log('SUCCESS', ro);
            ro.status = null;
            ro.formId = Number(res.result);
            const varTableObj: ReportTable = {
              formId: Number(res.result),
              jobId: Number(ro.jobId),
              projectId: Number(ro.projectId),
              reportObj: ro
            };
            this.rs.addReport(varTableObj);
          }

          console.log(
            formId,
            'process report response:::',
            res,
            'errorName',
            res.errorName
          );
        });
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    }
  }

  async submitEditedReport(reportData: any, formId: number, isTdEditable: string, editreason: string): Promise<any> {
    if (this.status) {
      let response;
      await this.ad.executeService(this.conf.createUpdateReportsUrl(this.mac, this.racf, isTdEditable,
        editreason), 'PUT', reportData).then(result => {
          response = result.invocationResult;
          if (response.errorName === 'SUCCESS') {
            const ro = JSON.parse(JSON.stringify(reportData));
            console.log('SUCCESS', ro);
            ro.status = null;
            const varTableObj: ReportTable = {
              formId: Number(ro.formId),
              jobId: Number(ro.jobId),
              projectId: Number(ro.projectId),
              reportObj: ro
            };
            this.rs.addReport(varTableObj);
          }
          console.log(formId, 'edit report response:::', response, 'errorName', response);
        });
      return new Promise((resolve, reject) => {
        resolve(response);
      });
    }
  }

  async postLogDetails(teamChangeObj: string): Promise<any> {
    let res;
    await this.ad
      .executeService(
        this.conf.createLogChangeUrl(this.mac, this.racf),
        'POST',
        teamChangeObj
      )
      .then(response => {
        res = response;
      });
  }

  async createManualTrackDisturbance(tdObject): Promise<any> {
    if (this.status) {
      let res;
      let statusCode;
      await this.ad
        .executeService(
          this.conf.createManualTrackDisturbanceUrl(
            this.mac,
            this.racf
          ),
          'POST',
          tdObject
        )
        .then(response => {
          res = response.invocationResult;
          statusCode = response.statusCode;
          console.log(
            tdObject.formId,
            'process report response:::',
            res,
            'errorName',
            res.errorName
          );
        });
      return new Promise((resolve, reject) => {
        resolve(statusCode);
      });
    }
  }

  async getTrackDisturbance(reportObject): Promise<any> {
    if (this.status) {
      let res;
      await this.ad
        .executeService(
          this.conf.createTrackDisturbanceUrl(
            this.mac,
            this.racf
          ),
          'POST',
          reportObject
        )
        .then(response => {
          res = response.invocationResult;
          console.log(
            reportObject.formId,
            'process report response:::',
            res,
            'errorName',
            res.errorName
          );
        });
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    }
  }

  deleteReport(formId: number) {
    return this.rs.removeReport(formId);
  }

  deleteNotes(teamName: String) {
    return this.rs.deleteNotes(teamName);
  }

  deleteAllNotes() {
    return this.rs.deleteAllNotes();
  }

  async deleteSyncedReport(formId: number, noProductionCode: string, trackType: string, deleteReason: string): Promise<any> {
    let res;
    await this.ad
      .executeService(
        this.conf.createDeleteSyncedReportUrl(
          this.mac,
          this.racf,
          formId.toString(),
          noProductionCode,
          trackType,
          deleteReason
        ),
        'DELETE',
        '{}'
      )
      .then(response => {
        res = response;
      });
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }

  async syncEditReport(formId: number, noProductionCode: string, trackType: string, deleteReason: string): Promise<any> {
    let res;
    await this.ad
      .executeService(
        this.conf.createDeleteSyncedReportUrl(
          this.mac,
          this.racf,
          formId.toString(),
          noProductionCode,
          trackType,
          deleteReason
        ),
        'DELETE',
        '{}'
      )
      .then(response => {
        res = response;
      });
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }

  async checkIfTdEditAllowed(formId: number): Promise<any> {
    let res;
    await this.ad.executeService(
      this.conf.createTDEditableCheckUrl(this.mac, this.racf, formId.toString()), 'GET', '{}').then(response => {
        res = response;
      });
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  }

  getFormIdForDraft(): Observable<any> {
    return Observable.create(observer => {
      this.rs.reportTable
        .where('formId')
        .between(-100, 0)
        .sortBy('formId')
        .then(value => {
          let formId: number;
          console.log(value);
          if (value.length === 0) {
            console.log('formid ::-1');
            formId = -1;
          } else {
            formId = --value[0].formId;
            console.log('formid::', formId);
          }
          console.log('formid::', formId);
          observer.next(formId);
          observer.complete();
        });
    });
  }
  getDraftFormIdForRestDay(): Promise<any> {
    return this.rs.reportTable
      .where('formId')
      .between(-100, 0)
      .sortBy('formId');
  }

  getFormIdForSubmit(): number {
    let formId = -this.rs.reportTable.where('formId').between(-100, -1000)
      .count;
    if (formId != null) {
      formId = -100;
    } else {
      formId = -100 + formId;
    }
    console.log('getFormIdForSubmit ', formId);
    return formId;
  }

  getReportsForSync(): Promise<any> {
    return this.rs.getReportsForSync();
  }

  getLocationData(locList: string): Promise<any> {
    if (this.status) {
      this.ad
        .executeService(
          this.conf.createLocationURL(this.mac, this.racf, locList),
          'GET',
          '{}'
        )
        .then(response => {
          if (response.invocationResult.statusCode === 200) {
            const resultRefList = response.invocationResult.result;
            Object.keys(resultRefList).forEach(key => {
              const refVar = resultRefList[key];
              const ref: ReferenceCode = {
                refType: refVar.refType,
                refObj: refVar.refList
              };
              this.rs.addReference(ref);
            });
          } else {
            console.error('reference data call failed' + response.invocationResult);
            throw new Error('reference data call failed');
          }
        });
    }
    return this.rs.getAllRefereces();
  }

  getRefSync(refList: string) {
    return new Promise<number[]>(resolve => {
      const references = [];
      this.getReferenceDataNew(refList).subscribe(
        r => {
          references.push(r);
        },
        e => {
          resolve(references);
        },
        () => {
          resolve(references);
          console.log('processing refereces completed....', references);
        }
      );
    }).catch(e => {
      console.log(e);
      throw e;
    });
  }

  getReferenceDataNew(refList: string): Observable<number> {
    console.log('this.racf' + this.racf);
    console.log(' this.Flex.user.id' + this.Flex.user.id);
    if (this.racf !== this.Flex.user.id) {
      this.racf = this.Flex.user.id;
    }
    try {
      return Observable.create(observer => {
        if (this.status) {
          this.ad
            .executeService(
              this.conf.createRefURL(this.mac, this.racf, refList),
              'GET',
              '{}'
            )
            .then(response => {
              if (response.invocationResult.statusCode !== 200) {
                console.log('Adapter failed' + response.invocationResult);
                observer.error(response.invocationResult);
              }
              const resultRefList: any[] = response.invocationResult.result;
              console.log('res length:::', resultRefList.length);
              if (resultRefList.length === 0) {
                observer.complete();
              } else {
                const references = [];
                new Promise((resolve, reject) => {
                  let count = resultRefList.length;
                  const pObs = Observable.create(p => {
                    Object.keys(resultRefList).forEach(key => {
                      const refVar = resultRefList[key];
                      const refTableObj: ReferenceCode = {
                        refType: refVar.refType,
                        refObj: refVar.refList
                      };
                      count--;
                      observer.next(refVar.refType);
                      references.push(refTableObj);
                      if (count === 0) {
                        p.complete();
                        observer.complete();
                      }
                    });
                  });
                  pObs.subscribe(
                    r => { },
                    e => {
                      resolve();
                    },
                    () => {
                      resolve();
                    }
                  );
                })
                  .then(() => {
                    this.rs
                      .addBulkReferences(references)
                      .then(function (lastKey) {
                        console.log(
                          'Done adding references',
                          references.length
                        );
                        console.log('Last ref id was: ' + lastKey);
                        observer.next(references.length);
                        observer.complete();
                      })
                      .catch(Dexie.BulkError, function (e) {
                        observer.error(e);
                        console.error(
                          e.failures.length +
                          ' references was added successfully'
                        );
                      });
                  })
                  .catch(e => {
                    observer.error(e);
                  });
              }
            });
        } else {
          // observer.next(361);
          observer.complete();
        }
      });
    } catch (e) {
      throw e;
    }
  }

  getRefData(refList: string): Promise<any> {
    if (this.status) {
      this.ad
        .executeService(
          this.conf.createRefURL(this.mac, this.racf, refList),
          'GET',
          '{}'
        )
        .then(response => {
          if (response.invocationResult.statusCode === 200) {
            const resultRefList = response.invocationResult.result;
            Object.keys(resultRefList).forEach(key => {
              const refVar = resultRefList[key];
              const ref: ReferenceCode = {
                refType: refVar.refType,
                refObj: refVar.refList
              };
              this.rs.addReference(ref);
            });
          } else {
            console.error('reference data call failed' + response.invocationResult);
            throw new Error('reference data call failed');
          }
        });
    }
    return this.rs.getAllRefereces();
  }

  getRefTypeData(refType: string): Promise<any> {
    if (this.status) {
      this.ad
        .executeService(
          this.conf.createRefURL(this.mac, this.racf, refType),
          'GET',
          '{}'
        )
        .then(response => {
          if (response.invocationResult.statusCode === 200) {
            const resultRefList = response.invocationResult.result;
            Object.keys(resultRefList).forEach(key => {
              const refVar = resultRefList[key];
              const ref: ReferenceCode = {
                refType: refVar.refType,
                refObj: refVar.refList
              };
              this.rs.addReference(ref);
            });
          } else {
            console.error(
              'refrence type data call failed' + response.invocationResult
            );
            throw new Error('refrence type data call failed');
          }
        });
    }
    return this.rs.getReference(refType);
  }

  getRoadCrossingData(project: number): Observable<RoadCrossingTable[]> {
    return this.getRoadCrossingDataNew(
      project,
      this.team,
      '',
      '',
      '',
      '',
      null
    );
  }

  getTurnoutDataNew(
    project: number,
    team: string,
    prefix?: string,
    lowMP?: number,
    highMP?: number,
    turnoutId?: number,
    jobId?: number
  ): Observable<TurnoutTable[]> {
    console.log(this.mac);
    console.log('this.racf', this.racf);
    try {
      return Observable.create(observer => {
        if (this.status) {
          this.ad
            .executeService(
              this.conf.createTurnoutsURL(this.mac, this.racf, project, team),
              'GET',
              '{}'
            )
            .then(response => {
              if (response.invocationResult.statusCode !== 200) {
                console.log('Adapter failed' + response.invocationResult);
                observer.error(response.invocationResult);
              } else {
                const resultList = response.invocationResult.result;
                if (resultList.length === 0) {
                  console.log(project, '--turnouts obs-complete');
                  observer.complete();
                } else {
                  const turnouts: TurnoutTable[] = [];
                  let count = resultList.length;
                  new Promise((resolve, reject) => {
                    const rObs = Observable.create(p => {
                      Object.keys(resultList).forEach(key => {
                        const resultVar = resultList[key];
                        const tObj: TurnoutTable = {
                          turnoutId: resultVar.turnoutId,
                          jobId: -1, // resultVar.jobId'',
                          projectId: project,
                          turnoutObj: resultVar
                        };
                        turnouts.push(tObj);
                        count--;
                        if (count === 0) {
                          p.complete();
                        }
                      });
                    });
                    rObs.subscribe(
                      r => { },
                      e => {
                        resolve();
                      },
                      () => {
                        resolve();
                      }
                    );
                  })
                    .then(() => {
                      this.rs
                        .addBulkTurnouts(turnouts)
                        .then(function (lastKey) {
                          console.log('Done adding turnouts', turnouts.length);
                          console.log('Last rx id was: ' + lastKey);
                          observer.next(turnouts.length);
                          observer.complete();
                        })
                        .catch(Dexie.BulkError, function (e) {
                          observer.error(e);
                          console.error(
                            e.failures.length +
                            ' failed inserting the roadcrossing'
                          );
                        });
                    })
                    .catch(e => {
                      console.error(e);
                      observer.error(e);
                    });
                }
              }
            })
            .catch(e => {
              console.error(e);
              observer.error(e);
            });
        } else {
          console.log('not online');
          observer.complete();
        }
      });
    } catch (e) {
      throw e;
    }
  }

  getTurnoutData(project: number): Observable<TurnoutTable[]> {
    return this.getTurnoutDataNew(project, this.team);
  }

  getProjectsSync() {
    return new Promise<number[]>(resolve => {
      const projects = [];
      this.getProjectsNew().subscribe(
        r => {
          projects.push(r);
        },
        e => {
          resolve(projects);
        },
        () => {
          resolve(projects);
          console.log('processing projects completed....', projects);
        }
      );
    }).catch(e => {
      console.log(e);
      throw e;
    });
  }

  getProjectSync(x: number) {
    return new Promise<number[]>(resolve => {
      const projects = [];
      this.getProject(x).subscribe(
        r => {
          projects.push(r);
        },
        e => {
          resolve(projects);
        },
        () => {
          resolve(projects);
          console.log('processing projects completed....', projects);
        }
      );
    }).catch(e => {
      console.log(e);
      throw e;
    });
  }

  getJobsSync(x: number) {
    return new Promise<number>(resolve => {
      this.getJobsNew(x).subscribe(
        r => { },
        e => {
          resolve(2);
        },
        () => {
          resolve(1);
          console.log('processing jobs completed....');
        }
      );
    }).catch(e => {
      console.log(e);
      throw e;
    });
  }

  getReportsSync(x: number) {
    return new Promise<number>(resolve => {
      this.getReportsNew(x, null).subscribe(
        r => { },
        e => {
          resolve(2);
        },
        () => {
          resolve(2);
          console.log('processing reports completed....');
        }
      );
    }).catch(e => {
      console.log(e);
      throw e;
    });
  }

  getRoadCrossingDataSync(x: number) {
    return new Promise<number>(resolve => {
      this.getRoadCrossingData(x).subscribe(
        r => { },
        e => {
          resolve(3);
        },
        () => {
          resolve(3);
          console.log('processing road crossing completed....');
        }
      );
    }).catch(e => {
      console.log(e);
      throw e;
    });
  }

  getTurnoutDataSync(x: number) {
    return new Promise<number>(resolve => {
      this.getTurnoutDataNew(x, this.team).subscribe(
        r => { },
        e => {
          resolve(3);
        },
        () => {
          resolve(3);
          console.log('processing Turnout completed....');
        }
      );
    }).catch(e => {
      console.log(e);
      throw e;
    });
  }

  getOneJobFromLocal(jobId: number): Promise<JobModel> {
    let job: JobModel;
    return new Promise((resolve, reject) => {
      this.rs
        .getJob(jobId)
        .then((j: JobTable) => {
          job = new JobModel(
            j.jobId,
            j.projectId,
            j.jobObj['prefix'],
            j.jobObj['startMP'],
            j.jobObj['endMP'],
            j.jobObj['trackType'],
            j.jobObj['jobTypeCode'],
            j.jobObj['division'],
            j.jobObj['subdivision'],
            j.jobObj['sequenceId'],
            j.jobObj['isSwitchTieJob']
          );
        })
        .then(() => {
          resolve(job);
        })
        .catch(err => { });
    });
  }

  getOneProjectFromLocal(projectId: number): Promise<Project> {
    let project: Project;
    return new Promise((resolve, reject) => {
      this.rs
        .getProject(projectId)
        .then((j: ProjectTable) => {
          project = new Project(
            j.project,
            j.projectObj['startMP'],
            j.projectObj['endMP'],
            j.projectObj['prefix'],
            null,
            null,
            j.projectObj['totalPlannedProjectUnits'],
            j.projectObj['totalActualProjectUnits'],
            j.projectObj['totalPlannedProjectDays'],
            j.projectObj['daysToComplete'],
            j.projectObj['unitsRemaining'],
            j.projectObj['initialPlannedCrossing'],
            j.projectObj['initialPlannedDays'],
            j.projectObj['initialPlannedProjectUnits']
          );
        })
        .then(() => {
          resolve(project);
        })
        .catch(err => { });
    });
  }

  getJobsFromLocal(projectId: number): Promise<JobModel[]> {
    let jobsList: JobModel[] = [];

    return new Promise((resolve, reject) => {
      this.rs
        .getProjectJobs(projectId)
        .then((jobTable: JobTable[]) => {
          jobsList = jobTable.map(
            j =>
              new JobModel(
                j.jobId,
                j.projectId,
                j.jobObj['prefix'],
                j.jobObj['startMP'],
                j.jobObj['endMP'],
                j.jobObj['trackType'],
                j.jobObj['jobTypeCode'],
                j.jobObj['division'],
                j.jobObj['subdivision'],
                j.jobObj['sequenceId'],
                j.jobObj['isSwitchTieJob']
              )
          );
        })
        .then(() => {
          resolve(jobsList);
        })
        .catch(err => { });
    });
  }

  getProjectReportsFromLocal(projectId: number): Promise<Report[]> {
    let reportList: Report[] = [];
    return new Promise((resolve, reject) => {
      this.rs
        .getProjectReports(projectId)
        .then((reportTable: ReportTable[]) => {
          reportList = reportTable.map(
            j =>
              new Report(
                j.projectId,
                j.jobId,
                j.formId,
                j.reportObj['workday'],
                j.reportObj['teamName'],
                j.reportObj['status'],
                j.reportObj['workTypeCode'],
                j.reportObj['noProductionCode'],
                j.reportObj['totalSurfacedFeet'],
                j.reportObj['totalTiesInstalled'],
                j.reportObj['railLength']
              )
          );
        })
        .then(() => {
          resolve(reportList);
        })
        .catch(err => { });
    });
  }

  getOneReportFromLocal(formId: number): Promise<Report> {
    let report: Report;
    return new Promise((resolve, reject) => {
      this.rs
        .getReport(formId)
        .then((j: ReportTable) => {
          report = new Report(
            j.projectId,
            j.jobId,
            j.formId,
            j.reportObj['workday'],
            j.reportObj['teamName'],
            j.reportObj['status'],
            j.reportObj['workTypeCode'],
            j.reportObj['noProductionCode'],
            j.reportObj['totalSurfacedFeet'],
            j.reportObj['totalTiesInstalled'],
            j.reportObj['railLength']
          );
        })
        .then(() => {
          resolve(report);
        })
        .catch(err => { });
    });
  }

  getTurnouts(
    projectId: number,
    prefix: string,
    lowMp: number,
    highMp: number
  ): Promise<Turnout[]> {
    return new Promise((resolve, reject) => {
      this.rs.getJobTurnouts(projectId).then((cList: Array<TurnoutTable>) => {
        const turnoutData: Turnout[] = cList.map(
          (turnoutTable: TurnoutTable) =>
            new Turnout(
              Number(turnoutTable.turnoutId),
              turnoutTable.turnoutObj['prefix'],
              turnoutTable.turnoutObj['milepost'],
              turnoutTable.turnoutObj['trackType'],
              turnoutTable.turnoutObj['trackName'],
              turnoutTable.turnoutObj['direction'],
              turnoutTable.turnoutObj['railWeight'],
              turnoutTable.turnoutObj['f_t'],
              turnoutTable.turnoutObj['turnoutLength'],
              turnoutTable.turnoutObj['turnoutSize'],
              turnoutTable.turnoutObj['endMp'],
              turnoutTable.turnoutObj['switchName'],
              turnoutTable.turnoutObj['trackClass'],
              turnoutTable.turnoutObj['isPassengerFlag']
            )
        );
        const filterTurnouts: Turnout[] = turnoutData.filter(
          (turnout: Turnout) =>
            (turnout.prefix === null || turnout.prefix === prefix) &&
            ((lowMp === null && highMp === null) ||
              (turnout.milepost >= lowMp && turnout.milepost <= highMp))
        );
        filterTurnouts.sort((x, y) =>
          x.prefix < y.prefix
            ? -1
            : x.prefix > y.prefix
              ? 1
              : x.milepost < y.milepost
                ? -1
                : 1
        );
        resolve(filterTurnouts);
      });
    });
  }
}
