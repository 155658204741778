import * as moment from 'moment';

import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { JobTable, ProjectTable, LocalStorageService, ReportTable, CurrentUserTable } from '../../services/local-storage.service';

import { DataService } from '../../services/data.service';
import { ReferencePipe } from '../../pipes/reference.pipe';
import { Router } from '@angular/router';
import { UtilService } from './../../util/util.service';
import { ReportDataService } from '../../shared/services/report-data.service';
import { ConfirmationService } from 'primeng/primeng';
import { AppConstant } from '../../shared/constant/constants';
import { ErrorDialog } from '../../model/error-dialog.model';
import { WorkType } from '../../enums/work-type.enum';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  providers: [ConfirmationService],
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {
  minMaxStartMp;
  reportData: any = [];
  @Input()
  project;
  @Input()
  jobId;
  @Input()
  formid;
  @Input()
  latestUnsyncedReport;
  @Input()
  latestSyncedReport;
  @Output()
  onLatestReportChange: EventEmitter<boolean> = new EventEmitter();
  reportstatus = false;
  displayData = [];
  rowCount = 0;
  showCount = 7;
  direction: string;
  jobType: any;
  tieMilepost: any = [];
  surfaceMilepost: any = [];
  tieCount: number;
  surfaceCount: number;
  sum: string;
  trackType: string;
  columnName: string;
  sideLineMilepost: any = [];
  sideSurfaceMilepost: any = [];
  previousTrack: number;
  sideTieCount: number;
  sideSurface: number;
  track: string;
  totalSurfaceUnits: number;
  yardMilepost: any = [];
  teamName: string;
  projectReports = [];
  showErrorComponent: boolean;
  errorDialogData: ErrorDialog;
  isSwitchTieJob: boolean;
  workTypeCode: String;
  WorkType = WorkType;
  reasonForDelete: string;
  reasonError: boolean;
  showReasonForDelete: boolean;
  loading: boolean;
  isNotesPresent = false;

  constructor(
    private dataService: DataService,
    private ls: LocalStorageService,
    private Route: Router,
    public reference: ReferencePipe,
    public Util: UtilService,
    private reportDataService: ReportDataService,
    private deleteConfirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.getUserTeam();
    this.getProjectReports();
    this.prepareReportList();
    this.checkReportWork();
    this.ls.getCurrentUser('SYSTEM').then(data => {
      const teamName = data ? data.teamName : null;
      this.ls.getNotes(teamName).then(noteData => {
        if (noteData !== null && noteData !== undefined) {
          this.isNotesPresent = true;
        } else {
          this.isNotesPresent = false;
        }
      });
    });
  }

  prepareReportList() {
    this.dataService.getJobInfo(this.jobId).then((jobId1: JobTable) => {
      const jo = JSON.parse(JSON.stringify(jobId1.jobObj));
      this.jobType = jo.jobTypeCode;
      this.trackType = jo.trackType;
      this.isSwitchTieJob = jo.isSwitchTieJob;
      this.getWorkTpeCode(jo);
    });
    this.reportstatus = false;
    this.ls.getJobReports(this.jobId).then((reportList: Array<ReportTable>) => {
      this.reportData = [];
      for (const p of reportList) {
        p.reportObj['reportType'] = this.Util.determineReportType(JSON.parse(JSON.stringify(p.reportObj)));
        if (p.reportObj['reportType'] === this.Util.production) {
          let passes = 0;
          this.sum = '';
          this.direction = p.reportObj['direction'];
          if (p.reportObj['status'] === 'D') {
            p.reportObj['status'] = 'Draft';
          } else if (p.reportObj['status'] === 'S') {
            p.reportObj['status'] = 'Submitted';
          }
          const js: any = JSON.parse(JSON.stringify(p.reportObj));
          this.reportData.push(js);
          this.reportData.sort(function (a, b) {
            return b.workday - a.workday;
          });
          if (this.jobType === 'SO' && !this.isSwitchTieJob) {
            js.surfacingDetails.forEach((item, index) => {
              passes += item.passes;
              js['passes'] = passes;
              js['surfaceType'] = this.reference.transform(item.surfaceType, 'SFT');
            });
          }
          let diff = 0;
          if (js.reportWindows) {
            js['reportWindows'].forEach(obj => {
              if (obj.windowEnd !== null && obj.windowStart !== null) {
                diff += moment.duration(moment(obj.windowEnd).diff(moment(obj.windowStart))).asMilliseconds();
              }
            });
            const hours = diff / (1000 * 60 * 60);
            const absoluteHours = Math.floor(hours);
            const h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;
            const minutes = (hours - absoluteHours) * 60;
            const absoluteMinutes = Math.floor(minutes);
            const m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;
            this.sum = h + ':' + m;
            js['windowDiff'] = this.sum;
          }
          this.reportData.map(obj => {
            return (obj.workday = new Date(obj.workday));
          });
        } else if (p.reportObj['reportType'] === this.Util.noProduction) {
          if (p.reportObj['status'] === 'D') {
            p.reportObj['status'] = 'Draft';
          } else if (p.reportObj['status'] === 'S') {
            p.reportObj['status'] = 'Submitted';
          }
          const js: any = JSON.parse(JSON.stringify(p.reportObj));
          this.reportData.push(js);
          let diff = 0;
          if (js.reportWindows) {
            let malformedWindows = false;
            js['reportWindows'].forEach(obj => {
              if (obj.windowEnd !== null && obj.windowStart !== null) {
                diff += moment.duration(moment(obj.windowEnd).diff(moment(obj.windowStart))).asMilliseconds();
              }
              if ((obj.windowStart === null || obj.windowStart === undefined) && (obj.windowEnd == null || obj.windowEnd === undefined)) {
                malformedWindows = true;
              }
            });
            if (!malformedWindows) {
              const hours = diff / (1000 * 60 * 60);
              const absoluteHours = Math.floor(hours);
              const h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;
              const minutes = (hours - absoluteHours) * 60;
              const absoluteMinutes = Math.floor(minutes);
              const m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;
              this.sum = h + ':' + m;
              js['windowDiff'] = this.sum;
            }
          }
          this.reportData.sort(function (a, b) {
            return b.workday - a.workday;
          });
          this.reportData.map(obj => {
            if (obj.workday !== null && obj.workday !== undefined) {
              return (obj.workday = new Date(obj.workday));
            } else {
              return null;
            }
          });
        } else if (p.reportObj['reportType'] === this.Util.rest) {
          if (p.reportObj['status'] === 'D') {
            p.reportObj['status'] = 'Draft';
          } else if (p.reportObj['status'] === 'S') {
            p.reportObj['status'] = 'Submitted';
          }
          const js: any = JSON.parse(JSON.stringify(p.reportObj));
          this.reportData.push(js);
          this.reportData.sort(function (a, b) {
            return b.workday - a.workday;
          });
          this.reportData.map(obj => {
            if (obj.workday !== null && obj.workday !== undefined) {
              return (obj.workday = new Date(obj.workday));
            } else {
              return null;
            }
          });
        }
      }
      this.tsProgressData();
      this.switchTieReportData();
      let highestValue = Number.NEGATIVE_INFINITY; // keep track of highest value
      let lowestValue = Number.POSITIVE_INFINITY;
      let prefix = '';
      for (let i = 0, len = this.reportData.length; i < len; i++) {
        if (this.reportData[i]['reportType'] === this.Util.production) {
          if (
            (this.workTypeCode === WorkType.TIEANDSURFACE &&
              (this.reportData[i]['track'] !== null || this.trackType === 'YD' || this.trackType === 'SD')) ||
            this.workTypeCode === WorkType.RAIL ||
            this.jobType === WorkType.SWITCHTIE ||
            this.workTypeCode === WorkType.SURFACE
          ) {
            const highMp = Number(this.reportData[i]['highMp']);
            prefix = this.reportData[i]['prefix'];
            if (highMp > highestValue) {
              highestValue = highMp;
            }
            if (this.reportData[i]['lowMp']) {
              const tmp = Number(this.reportData[i]['lowMp']);
              if (tmp < lowestValue) {
                lowestValue = tmp;
              }
            }
            lowestValue = isFinite(lowestValue) ? lowestValue : 0;
            highestValue = isFinite(highestValue) ? highestValue : 0;
            //  this.minMaxStartMp = prefix + ' ' + lowestValue + ' - ' + highestValue;
            if (this.reportData[i]['direction'] === 'I') {
              this.minMaxStartMp = prefix + ' ' + lowestValue + ' - ' + highestValue;
            } else if (this.reportData[i]['direction'] === 'D') {
              this.minMaxStartMp = prefix + ' ' + highestValue + ' - ' + lowestValue;
            }
          }
        }
      }
      this.loadMoreData();
    });
  }

  tsProgressData() {
    if (this.reportData && this.jobType === 'TS' && !this.isSwitchTieJob) {
      for (let i = 0; i < this.reportData.length; i++) {
        if (this.reportData[i].reportType === this.Util.production) {
          let tieProgress = [];
          this.tieCount = 0;
          this.track = null;
          this.sideTieCount = 0;
          setTimeout(() => {
            this.direction = this.reportData[i].tsProgress.direction;
          }, 100);
          console.log('tsprogress', this.reportData[i].tsProgress.tieProgress);
          if (this.reportData[i].tsProgress.tieProgress) {
            tieProgress = Object.keys(this.reportData[i].tsProgress.tieProgress).map(k => this.reportData[i].tsProgress.tieProgress[k]);
            console.log('tieDetails', tieProgress);
            for (let j = 0; j < tieProgress.length; j++) {
              if (tieProgress[j]['trackName'] === 'MAINLINE TRACKS') {
                this.track = tieProgress[j]['trackName'];
                this.tieCount += tieProgress[j]['tiePlateCount'];
                this.surfaceCount = null;
              }
              this.reportData[i].track = this.track;
              console.log('trackName', this.track);
              if (tieProgress.length > 0 && tieProgress[j]['trackName'] === 'MAINLINE TRACKS') {
                this.reportData[i].tieType = this.reference.transform(tieProgress[0]['tiePlateType'], 'TTT');
              }
              this.reportData[i].tieCount = this.tieCount;
              this.reportData[i].surfaceCount = this.surfaceCount;
              console.log('report', this.reportData);
            }
            const groups = {};
            for (let l = 0; l < tieProgress.length; l++) {
              if (tieProgress && tieProgress[l]['trackName'] !== 'MAINLINE TRACKS') {
                const track = tieProgress[l]['trackName'];
                if (!groups[track]) {
                  groups[track] = [];
                }
                groups[track].push(tieProgress[l]['beginMilepost'], tieProgress[l]['endMilepost']);
              }
            }
            this.sideLineMilepost = [];
            for (const track in groups) {
              if (groups) {
                this.sideLineMilepost.push({ track: track, milepost: groups[track] });
              }
            }
            console.log('sideLine', this.sideLineMilepost);
            for (let x = 0; x < tieProgress.length; x++) {
              this.sideLineMilepost.forEach((item, index) => {
                if (item.track === tieProgress[x]['trackName']) {
                  if (this.sideLineMilepost[index].track !== this.previousTrack) {
                    this.sideTieCount = 0;
                  }
                  this.previousTrack = item.track;
                  this.sideLineMilepost[index]['beginMp'] = Math.min(...item['milepost']);
                  this.sideLineMilepost[index]['endMp'] = Math.max(...item['milepost']);
                  this.sideTieCount += tieProgress[x]['tiePlateCount'];
                  this.sideLineMilepost[index]['tieCount'] = this.sideTieCount;
                  this.sideLineMilepost[index]['surfaceCount'] = null;
                  this.sideLineMilepost[index]['tieType'] = this.reference.transform(tieProgress[x]['tiePlateType'], 'TTT');
                  this.sideLineMilepost[index]['direction'] = tieProgress[x]['direction'];
                }
              });
            }
            if (this.trackType === 'SD' && this.sideLineMilepost.length) {
              this.reportData[i].highMp = this.sideLineMilepost[0]['endMp'];
              this.reportData[i].lowMp = this.sideLineMilepost[0]['beginMp'];
            }
            this.reportData[i]['sideLineMilePost'] = this.sideLineMilepost;
          }
          if (this.reportData[i].tsProgress.surfaceProgress) {
            this.totalSurfaceUnits = 0;
            this.surfaceMilepost = [];
            this.sideSurface = 0;
            const surfaceProgress = Object.keys(this.reportData[i].tsProgress.surfaceProgress).map(
              k => this.reportData[i].tsProgress.surfaceProgress[k]
            );
            for (let y = 0; y < surfaceProgress.length; y++) {
              this.surfaceCount = 0;
              if (surfaceProgress[y]['workedOn'] === 'S') {
                this.totalSurfaceUnits += surfaceProgress[y]['surfaced'];
                console.log('tieDetails', surfaceProgress);
                for (let j = 0; j < surfaceProgress.length; j++) {
                  if (surfaceProgress[j]['trackName'] === 'MAINLINE TRACKS' && surfaceProgress[j]['workedOn'] === 'S') {
                    this.track = surfaceProgress[j]['trackName'];
                    this.surfaceMilepost.push(surfaceProgress[j]['beginMP'], surfaceProgress[j]['endMP']);
                    this.tieCount = null;
                    this.surfaceCount += surfaceProgress[j]['surfaced'];
                    this.reportData[i].direction = surfaceProgress[j]['direction'];
                  }
                  this.reportData[i].track = this.track;
                  console.log('milepost', this.surfaceMilepost);
                  this.reportData[i].tieCount = this.tieCount;
                  this.reportData[i].surfaceCount = this.surfaceCount;
                  this.reportData[i].totalSurfaceUnits = this.totalSurfaceUnits;
                  this.reportData[i].lowMp = Math.min(...this.surfaceMilepost);
                  if (this.reportData[i].lowMp === Infinity) {
                    this.reportData[i].lowMp = 0;
                  }
                  this.reportData[i].highMp = Math.max(...this.surfaceMilepost);
                  if (this.reportData[i].highMp === -Infinity) {
                    this.reportData[i].highMp = 0;
                  }
                  console.log('report surface', this.reportData);
                }
                const group = {};
                for (let l = 0; l < surfaceProgress.length; l++) {
                  if (surfaceProgress && surfaceProgress[l]['trackName'] !== 'MAINLINE TRACKS') {
                    const track = surfaceProgress[l]['trackName'];
                    if (!group[track]) {
                      group[track] = [];
                    }
                    group[track].push(surfaceProgress[l]['beginMP'], surfaceProgress[l]['endMP']);
                  }
                }
                this.sideSurfaceMilepost = [];
                for (const track in group) {
                  if (group) {
                    this.sideSurfaceMilepost.push({ track: track, milepost: group[track] });
                  }
                }
                console.log('sideLine surface', this.sideSurfaceMilepost);
                for (let x = 0; x < surfaceProgress.length; x++) {
                  this.sideSurfaceMilepost.forEach((item, index) => {
                    if (item.track === surfaceProgress[x]['trackName']) {
                      if (this.sideSurfaceMilepost[index].track !== this.previousTrack) {
                        this.sideSurface = 0;
                      }
                      this.sideSurfaceMilepost[index]['beginMp'] = Math.min(...item['milepost']);
                      this.sideSurfaceMilepost[index]['endMp'] = Math.max(...item['milepost']);
                      this.sideSurfaceMilepost[index]['direction'] = surfaceProgress[x]['direction'];
                      this.sideSurface += surfaceProgress[x]['surfaced'];
                      this.sideSurfaceMilepost[index]['tieCount'] = null;
                      this.sideSurfaceMilepost[index]['surfaceCount'] = this.sideSurface;
                    }
                  });
                }
                if (this.trackType === 'SD') {
                  this.reportData[i]['sideLineMilePost'] = this.sideSurfaceMilepost;
                  this.reportData[i].highMp = this.sideSurfaceMilepost[0].endMp;
                  this.reportData[i].lowMp = this.sideSurfaceMilepost[0].beginMp;
                } else {
                  this.reportData[i]['sideSurfaceMilepost'] = this.sideSurfaceMilepost;
                }
                if (this.trackType === 'YD') {
                  this.sideSurfaceMilepost.forEach((item, index) => {
                    this.yardMilepost.push(item.beginMp, item.endMp);
                    this.direction = item.direction;
                  });
                  this.reportData[i].direction = this.direction;
                  this.reportData[i].lowMp = Math.min(...this.yardMilepost);
                  if (this.reportData[i].lowMp === Infinity) {
                    this.reportData[i].lowMp = 0;
                  }
                  this.reportData[i].highMp = Math.max(...this.yardMilepost);
                  if (this.reportData[i].highMp === -Infinity) {
                    this.reportData[i].highMp = 0;
                  }
                }
              }
            }
          }
        } else {
        }
      }
    }
  }

  private switchTieReportData(): void {
    if (this.reportData && this.workTypeCode === WorkType.SWITCHTIE && this.isSwitchTieJob) {
      this.reportData.forEach(report => {
        if (report.reportType === this.Util.production) {
          this.track = null;
          report.isOnlySurface = false;
          if (report.switchTies && report.switchTies.length > 0) {
            report.switchTies.forEach(switchTie => {
              switchTie.totalTieCount = this.getSwitchTieCount(switchTie);
              switchTie.tieTypeName = this.reference.transform(switchTie['tieType'], 'STT');
            });
          } else {
            this.totalSurfaceUnits = 0;
            const milepost: any = [];
            report.isOnlySurface = true;
            if (report.surfacing && report.surfacing.length > 0) {
              report.surfacing.forEach(surface => {
                this.totalSurfaceUnits += this.getSurfaceFeet(surface.surfaceDetails);
                report.totalSurfaceUnits = this.totalSurfaceUnits;
                let surfacefeet = 0;
                surface.surfaceDetails.forEach(sur => {
                  milepost.push(sur.beginMP, sur.endMP);
                  surfacefeet += sur.surfaced;
                });
                surface.totalFeetSurface = surfacefeet;
                surface.lowMP = Math.min(...milepost);
                surface.highMP = Math.max(...milepost);
              });
            }
          }
        }
      });
    }
  }

  private getSurfaceFeet(surfaceDetails: any): number {
    let surfacedFeet = 0;
    surfaceDetails.forEach(surface => {
      if (surface.surfaced > 0) {
        surfacedFeet += surface.surfaced;
      }
    });
    return surfacedFeet;
  }
  private getSwitchTieCount(switchTie: any): number {
    let switchTieCount = 0;
    switchTie.switchTiesSizes.forEach(size => {
      if (size.noOfTies > 0) {
        switchTieCount += size.noOfTies;
      }
    });
    return switchTieCount;
  }

  getColor(value) {
    let result = '';
    if (value.status === 'Draft') {
      result = 'text-red';
    } else if (value.status === 'Submitted') {
      if (value.noProductionCode) {
        result = 'text-orange';
      } else {
        result = 'text-green';
      }
    }
    return result;
  }

  calDayCount(col) {
    const uniqueDates = [];
    for (let i = 0; i < this.reportData.length; i++) {
      if (this.reportData[i][col]) {
        if (col === 'workday') {
          if (!this.isDateInArray(new Date(this.reportData[i]['workday']), uniqueDates)) {
            uniqueDates.push(this.reportData[i]['workday']);
          }
        }
      }
    }
    return uniqueDates.length;
  }

  calColumn(col) {
    let sum = 0;
    let timeDiff = '';
    let time = 0;
    if (this.isSwitchTieJob) {
      this.columnName = 'totalTiesInstalled';
    } else {
      if (this.jobType === 'SO') {
        this.columnName = 'totalSurfacedFeet';
      } else if (this.jobType === 'TS') {
        this.columnName = 'totalTiesInstalled';
      } else {
        this.columnName = 'railLength';
      }
    }
    for (let i = 0; i < this.reportData.length; i++) {
      if (this.reportData[i][col]) {
        if (col === 'workday') {
          sum += 1;
        } else if (col === 'windowDiff') {
          if (this.reportData[i]['reportWindows']) {
            this.reportData[i]['reportWindows'].forEach(item => {
              if (item.windowEnd !== null && item.windowStart !== null) {
                time += moment.duration(moment(item.windowEnd).diff(moment(item.windowStart))).asMilliseconds();
              }
            });
            const hours = time / (1000 * 60 * 60);
            const absoluteHours = Math.floor(hours);
            const h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;
            const minutes = (hours - absoluteHours) * 60;
            const absoluteMinutes = Math.floor(minutes);
            const m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;
            timeDiff = h + ':' + m;
          }
        } else if (col === this.columnName) {
          // tslint:disable-next-line:radix
          sum += Number.parseInt(this.reportData[i][col]);
        } else if (col === 'passes') {
          // tslint:disable-next-line:radix
          sum += Number.parseInt(this.reportData[i][col]);
        } else if (col === 'welds') {
          // tslint:disable-next-line:radix
          sum += Number.parseInt(this.reportData[i][col]);
        } else if (col === 'actualCrossingCount') {
          // tslint:disable-next-line:radix
          sum += Number.parseInt(this.reportData[i][col]);
        } else if (col === 'totalSurfaceUnits') {
          // tslint:disable-next-line:radix
          sum += Number.parseInt(this.reportData[i][col]);
        }
      }
    }

    if (sum === NaN) {
      sum = 0;
    }
    if (timeDiff === undefined) {
      timeDiff = '00:00';
    }
    return timeDiff || sum;
  }

  callArray(list) {
    list.forEach(obj => obj.endMilepost);
  }

  delete(formId: number) {
    this.dataService.deleteReport(formId).then(response => {
      console.log('deleting this row from indexdb', formId);
    });
  }

  isDateInArray(list, key) {
    for (let i = 0; i < key.length; i++) {
      if (list.getDate() === key[i].getDate() && list.getMonth() === key[i].getMonth()) {
        return true;
      }
    }
    return false;
  }

  loadMoreData() {
    this.displayData = [];
    this.rowCount = this.rowCount + this.showCount;
    this.displayData = this.reportData.slice(0, this.rowCount);
  }

  navigateToReportViewEdit(data, isEdit?: boolean, queryParam?: string) {
    const reportType = data.reportType;
    const projectId = data.projectId;
    const jobformId = data.formId;
    const jobId = data.jobId;
    const jobType = data.jobType;
    const editUrl = isEdit ? 'edit/' : '';
    const queryParamStr = queryParam ? queryParam : '';

    if (reportType === this.Util.production) {
      if (this.isSwitchTieJob) {
        const url = '/switch_tie/' + editUrl + projectId + '/' + jobId + '/' + jobformId + queryParamStr;
        this.Route.navigateByUrl(url);
      } else {
        if (jobType === 'PR' || jobType === 'OF') {
          const url = '/rail_info/' + editUrl + projectId + '/' + jobId + '/' + jobformId + queryParamStr;
          this.Route.navigateByUrl(url);
        } else if (jobType === 'GA' || jobType === 'CP') {
          const url = '/gauging_info/' + editUrl + projectId + '/' + jobId + '/' + jobformId + queryParamStr;
          this.Route.navigateByUrl(url);
        } else if (jobType === 'TS') {
          const url = '/ts_info/' + editUrl + projectId + '/' + jobId + '/' + jobformId + queryParamStr;
          this.Route.navigateByUrl(url);
        } else if (jobType === 'SO') {
          const url = '/surfacing_info/' + editUrl + projectId + '/' + jobId + '/' + jobformId + queryParamStr;
          this.Route.navigateByUrl(url);
        }
      }
    } else if (reportType === this.Util.noProduction) {
      const url = '/no_production_report/' + editUrl + projectId + '/' + jobId + '/' + jobformId;
      this.Route.navigateByUrl(url);
    } else if (reportType === this.Util.rest) {
      const url = '/app-no-production-restday/' + editUrl + projectId + '/' + jobId + '/' + jobformId;
      this.Route.navigateByUrl(url);
    }
  }

  onReasonChange() {
    if (this.reasonError && this.reasonForDelete) {
      this.reasonError = false;
    }
  }

  deleteConfirmationAccept(confirmDialog) {
    if (this.reasonForDelete) {
      confirmDialog.accept();
    } else {
      this.reasonError = true;
    }
  }

  editReport(reportData: any) {
    event.stopPropagation();
    if (navigator.onLine) {
      if (reportData.reportType === this.Util.rest || reportData.reportType === this.Util.noProduction) {
        this.checkDraftsAndNavigate(reportData, null, true);
      } else {
        this.loading = true;
        // First check if TD is editable
        this.dataService.checkIfTdEditAllowed(reportData.formId).then(response => {
          if (response.statusCode !== 200 || response.invocationResult.errorNumber < 0) {
            this.showErrorComponent = true;
            this.errorDialogData = {
              dialogHeader: AppConstant.ERROR_HEADING,
              dialogBody: response.invocationResult.errorName
            };
          } else {
            this.checkDraftsAndNavigate(reportData, response.invocationResult[0]);
          }
          this.loading = false;
        });
      }
    } else {
      this.showErrorComponent = true;
    }
  }

  private checkDraftsAndNavigate(reportData: any, isTdEditable: boolean, isRestOrNoProdReport?: boolean): void {
    const queryParam = isRestOrNoProdReport ? null : '?isTdEditable=' + isTdEditable;
    this.ls.getReportsForSync().then((reportList: ReportTable[]) => {
      const draftReportIds = reportList.filter(report => report.reportObj.status === 'D').map(val => Number(val.reportObj.formId));
      if (draftReportIds.length > 0) {
        this.deleteConfirmationService.confirm({
          key: AppConstant.DELETE_KEY,
          message: AppConstant.REPORT_UPDATE_DRAFT_DELETE_MSG,
          accept: () => {
            this.ls.removeBulkReport(draftReportIds).then(() => {
              this.navigateToReportEdit(reportData, true, queryParam);
            });
          }
        });
      } else {
        this.navigateToReportEdit(reportData, true, queryParam);
      }
    });
  }

  private navigateToReportEdit(reportData, isEdit?: boolean, queryParam?: string) {
    this.dataService.deleteEditedReportByFormId(Number(reportData.formId)).then(() => {
      this.navigateToReportViewEdit(reportData, true, queryParam);
    });
  }

  deleteReport(formId: number): void {
    this.reasonError = false;
    this.reasonForDelete = null;
    event.stopPropagation();
    if (this.latestSyncedReport && this.latestSyncedReport.formId === formId) {
      if (this.latestSyncedReport.status === 'S') {
        this.deleteUnSyncedReport(formId, this.latestSyncedReport);
      } else {
        this.showReasonForDelete = true;
        if (navigator.onLine) {
          this.ls.getReportsForSync().then((reportList: ReportTable[]) => {
            const draftReportIds = reportList.filter(report => report.reportObj.status === 'D').map(val => Number(val.reportObj.formId));
            let message = draftReportIds.length > 0 ? AppConstant.SYNCED_REPORT_DRAFT_DELETE_MSG : AppConstant.SYNCED_REPORT_DELETE_MSG;
            if (this.isNotesPresent) {
              message += '<br/><strong>on Delete, any saved notes will be discarded.</strong>';
            }
            this.deleteConfirmationService.confirm({
              key: AppConstant.DELETE_KEY,
              message: message,
              accept: () => {
                if (navigator.onLine) {
                  this.loading = true;
                  this.dataService
                    .deleteSyncedReport(
                      formId,
                      this.latestSyncedReport.noProductionCode,
                      this.latestSyncedReport.trackType,
                      this.reasonForDelete
                    )
                    .then(response => {
                      if (response.statusCode !== 200 || response.invocationResult.errorNumber < 0) {
                        this.showErrorComponent = true;
                        this.errorDialogData = {
                          dialogHeader: AppConstant.ERROR_HEADING,
                          dialogBody: response.invocationResult.errorName
                        };
                        this.loading = false;
                      } else {
                        this.ls.removeBulkReport(draftReportIds).then(() => {
                          // Submitted reports are also part of sync report list object now with Prod Synced reports delete implementation
                          const reportSyncStatus =
                            this.latestSyncedReport.status === 'S' ? AppConstant.REPORT.UNSYNCED : AppConstant.REPORT.SYNCED;
                          this.deleteSelectedReport(formId, this.latestSyncedReport, reportSyncStatus);
                          this.loading = false;
                        });
                      }
                      this.reasonError = false;
                      this.reasonForDelete = null;
                    });
                } else {
                  this.showErrorComponent = true;
                }
              }
            });
          });
        } else {
          this.showErrorComponent = true;
        }
      }
    } else if (this.latestUnsyncedReport && this.latestUnsyncedReport.formId === formId) {
      this.deleteUnSyncedReport(formId, this.latestUnsyncedReport);
    }
  }

  private deleteUnSyncedReport(formId: number, report) {
    this.showReasonForDelete = false;
    this.deleteConfirmationService.confirm({
      key: AppConstant.DELETE_KEY,
      message: AppConstant.UNSYNCED_REPORT_DELETE_MSG,
      accept: () => {
        this.deleteSelectedReport(formId, report, AppConstant.REPORT.UNSYNCED);
      }
    });
  }

  onDialogClose(event: boolean): void {
    this.showErrorComponent = false;
    this.errorDialogData = null;
  }

  getUserTeam() {
    if (this.teamName === undefined || this.teamName === null) {
      this.ls.getCurrentUser('SYSTEM').then((c: CurrentUserTable) => {
        this.teamName = c.teamName;
      });
    }
  }

  getProjectReports() {
    this.ls.getProjectReports(Number(this.project)).then((reportList: Array<ReportTable>) => {
      for (const p of reportList) {
        const js: any = JSON.parse(JSON.stringify(p.reportObj));
        this.projectReports.push(js);
      }
    });
  }

  private deleteSelectedReport(formId: number, report: any, reportType: string): void {
    this.dataService.deleteReport(Number(formId)).then(response => {
      this.dataService.getProjectInfo(Number(this.project)).then((p: ProjectTable) => {
        this.ls.getProjectReports(Number(this.project)).then((reportList: Array<ReportTable>) => {
          const pObj = JSON.parse(JSON.stringify(p.projectObj));
          if (!reportList || reportList.length === 0) {
            pObj.totalPlannedProjectUnits = pObj.initialPlannedProjectUnits;
            pObj.totalPlannedProjectDays = pObj.initialPlannedDays;
            pObj.plannedCrossingCount = pObj.initialPlannedCrossing;
          } else {
            const latestPastDayReport = this.reportDataService.getLatestReportBeforePastDay(
              this.teamName,
              formId,
              this.projectReports,
              report.workday
            );
            if (latestPastDayReport) {
              pObj.totalPlannedProjectUnits = latestPastDayReport.totalPlannedProjectUnits;
              pObj.totalPlannedProjectDays = latestPastDayReport.totalPlannedProjectDays;
              pObj.plannedCrossingCount = latestPastDayReport.plannedCrossingCount;
            }
          }
          const projectTableObj: ProjectTable = {
            project: pObj.project,
            projectObj: pObj
          };
          this.ls.addProject(projectTableObj).then(() => {
            this.prepareReportList();
            reportType === AppConstant.REPORT.UNSYNCED ? this.onLatestReportChange.emit(false) : this.onLatestReportChange.emit(true);
          });
        });
      });
    });
  }

  private getWorkTpeCode(jobObject: any): void {
    if (jobObject.isSwitchTieJob) {
      this.workTypeCode = WorkType.SWITCHTIE;
    } else {
      if (jobObject.jobTypeCode === AppConstant.JOB_TYPE.TIE_SURFACING) {
        this.workTypeCode = WorkType.TIEANDSURFACE;
      } else if (jobObject.jobTypeCode === AppConstant.JOB_TYPE.SURFACING) {
        this.workTypeCode = WorkType.SURFACE;
      } else {
        this.workTypeCode = WorkType.RAIL;
      }
    }
  }

  checkReportWork(): boolean {
    return this.workTypeCode === WorkType.TIEANDSURFACE || this.workTypeCode === WorkType.SWITCHTIE ? true : false;
  }
}
