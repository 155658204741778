import { WorkType } from './../enums/work-type.enum';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../util/util.service';
import { DataService } from '../services/data.service';
import { ReportDataService } from '../shared/services/report-data.service';
import {
  LocalStorageService,
  ReportTable,
  JobTable,
  ProjectTable
} from '../services/local-storage.service';
import { Subject } from 'rxjs/Subject';
import { OperationMode } from '../shared/enums/operation-mode.enum';
import { FormBuilder } from '../../../node_modules/@angular/forms';
import { ConfirmationService } from '../../../node_modules/primeng/primeng';
import { ReportNavigationService } from '../shared/services/report-navigation.service';
const PLANNED_PROJECT_NOTIFICATION = 'Date selection will display Total Project Units and Days';

@Component({
  selector: 'app-no-production-report',
  templateUrl: './no-production-report.component.html',
  styleUrls: ['./no-production-report.component.scss']
})
export class NoProductionReportComponent implements OnInit {
  operationMode: OperationMode = OperationMode.CREATE;
  unitsRemaining: number;
  public projectId: number;
  public jobId: number;
  public formId: number;
  public jobType: string;
  workTypeCode: string;
  public jobInfo: any;
  public projectInfo: any;
  public reportstatus = false;
  public reportData: any = {};
  projectReports: any[] = [];
  reports: any;
  parentSubject: Subject<any> = new Subject();
  reportObject: any;
  typeOptions: TypeOptions[];
  length: number;
  previousReport: any;
  // machineFailure: SelectItem[];

  // Report Fields
  noProdType: string;
  onDutyTime: any;
  numUnits: number;
  numDays: number;
  totalActualProjectUnits: number;
  remarks: string;
  plannedStart: any;
  plannedEnd: any;
  windows: Window[] = [];
  windowsToSubmit: Window[] = [];
  teamName: string;

  windowReceived: boolean;
  today: string;
  noProdTypeInvalid: boolean;
  onDutyTimeInvalid: boolean;
  onDutyTimeError: string;
  numUnitsInvalid: boolean;
  numUnitsError: string;
  numDaysInvalid: boolean;
  numDaysError: string;
  plannedStartInvalid: boolean;
  plannedStartError: string;
  plannedEndInvalid: boolean;
  plannedEndError: string;
  trackReceivedInvalid: boolean[] = [];
  trackReceivedError: string[] = [];
  trackReveivedFirstClick: boolean[] = [];
  trackClearedInvalid: boolean[] = [];
  trackClearedError: string[] = [];
  formInvalid: boolean;
  plannedEndMax: string;
  plannedStartMin: string;
  plannedStartMax: string;
  formSubmitted: Boolean = false;
  pastReport: any;
  numDaysPrev = null;
  numUnitsPrev = null;
  unitsRemainingPrev = null;
  windowRequiredNoProdType = ['TRA', 'TTR', 'WOA'];
  plannedProjectWarningMessage: string;
  firstReportInProject = true;

  @Input()
  reportType: string;
  @Input()
  hideJobHeader: boolean;

  constructor(
    public fb: FormBuilder,
    public ds: DataService,
    public ls: LocalStorageService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    public utilService: UtilService,
    public reportDataService: ReportDataService,
    public acknowledgeService: ConfirmationService,
    public reportNavigationService: ReportNavigationService

  ) {
  }

  ngOnInit() {
    this.intializeReport();
  }

  get viewMode() {
    return this.operationMode === OperationMode.VIEW;
  }

  get createMode() {
    return this.operationMode === OperationMode.CREATE;
  }

  intializeReport() {
    // this.ls.getAllRefereces().then((refs: Array<ReferenceCode>) => {
    //   let refList = refs;
    //   for (let p of refList) {
    //     if (p.refType === 'SFT') {
    //       this.surfaceTypeOptions = this.utilService.hack(p.refObj, 'surfacingRailType');
    //       console.log(this.surfaceTypeOptions);
    //     }
    //   }
    // });

    // Read from Ref Data ^
    this.typeOptions = [
      { label: 'Finished Project Early', code: 'FPE' },
      { label: 'Mid-week Move', code: 'MWM' },
      { label: 'No Track Time Received', code: 'NTT' },
      { label: 'Tramming', code: 'TRA' },
      { label: 'Prep Work', code: 'TTR' },
      { label: 'Work Other Than Assigned', code: 'WOA' },
      { label: 'Weather', code: 'WXX' },
      { label: 'Late Team Move', code: 'LTM'}
    ];
    this.reportType = 'No Production';
    this.hideJobHeader = true;

    this.today = this.utilService.getCurrentDateTime();

    this.loadParam();
    this.getJobData();
    this.getProjectData();
    // this.getJobReports();
    this.getProjectReports();
    this.loadReport();

    // this.machineFailure = [];
    // this.machineFailure.push({label: 'Yes', value: 'Yes'});
    // this.machineFailure.push({label: 'No', value: 'No'});
  }

  loadParam(): void {
    this.activeRoute.params.subscribe(params => {
      const projectData = params.project;
      if (projectData) {
        this.projectId = Number(projectData.split('-')[0]);
      }
      this.jobId = params.job;
      this.formId = params.form;
      if (this.ds.team) {
        this.teamName = this.ds.team;
      } else {
        this.ls.getCurrentUser('SYSTEM').then(c => {
          this.teamName = c.teamName;
        });
      }
    });
  }

  getJobReports(): void {
    let mostRecentReport = true;
    this.ls
      .getJobReports(Number(this.jobId))
      .then((reportList: Array<ReportTable>) => {
        this.reports = reportList;
        for (const p of reportList) {
          const js: any = JSON.parse(JSON.stringify(p.reportObj));
          if (js.status === 'S' && mostRecentReport) {
            this.previousReport = js;
            mostRecentReport = false;
            // this.numUnits = js.totalPlannedProjectUnits;
            // this.numDays = js.totalPlannedProjectDays;
            // this.unitsRemaining = js.unitsRemaining;
          }
        }
      });
  }

  getProjectReports(): void {
    this.ls
      .getProjectReports(Number(this.projectId))
      .then((reportList: Array<ReportTable>) => {
        for (const p of reportList) {
          const js: any = JSON.parse(JSON.stringify(p.reportObj));
          if (js.status !== 'D') {
            this.firstReportInProject = false;
          }
          this.projectReports.push(js);
        }
        if (this.firstReportInProject) {
          this.plannedProjectWarningMessage = null;
        } else {
          if (!this.onDutyTime) {
            this.plannedProjectWarningMessage = PLANNED_PROJECT_NOTIFICATION;
          }
        }
      });
  }

  getJobData() {
    this.ds.getJobInfo(Number(this.jobId)).then((jobId1: JobTable) => {
      this.jobInfo = JSON.parse(JSON.stringify(jobId1.jobObj));
      if (this.jobInfo.isSwitchTieJob) {
        this.workTypeCode = WorkType.SWITCHTIE;
      } else {
        if (this.jobInfo.jobTypeCode === 'TS') {
          this.workTypeCode = WorkType.TIEANDSURFACE;
        } else if (this.jobInfo.jobTypeCode === 'SO') {
          this.workTypeCode = WorkType.SURFACE;
        } else {
          this.workTypeCode = WorkType.RAIL;
        }
      }
    });
  }

  getProjectData() {
    this.ls.getProject(this.projectId).then((project1: ProjectTable) => {
      this.projectInfo = JSON.parse(JSON.stringify(project1.projectObj));
      // this.numUnits = this.projectInfo.totalPlannedProjectUnits;
      // this.numDays = this.projectInfo.totalPlannedProjectDays;
      this.unitsRemaining = this.projectInfo.unitsRemaining;
    });
  }

  loadReport() {
    this.plannedProjectWarningMessage = null;
    this.getReportMethod().then(response => {
      if (response) {
        this.reportObject = response.reportObj;
        this.setReportType();
        this.checkReportOperationMode();
        if (!this.viewMode) {
          this.prepopulateReport();
        } else if (
          this.reportObject.status === 'S' ||
          this.reportObject.formId > 0
        ) {
          this.prepopulateReport();
        }
      } else {
        this.reportObject = {};
        this.windowReceived = false;
        this.remarks = '';
        this.noProdType = null;
        this.onDutyTime = null;
        this.plannedStart = null;
        this.plannedEnd = null;
        this.windowsToSubmit = [];
      }
      this.calculateChar();
      this.noProdType = this.convertNoProductionCode(this.noProdType);
    });
  }

  protected setReportType() {}

  prepopulateReport() {
    this.noProdType = this.reportObject.noProductionCode;
    this.onDutyTime = this.convertEpochToDateTime(this.reportObject.workday);
    this.numUnits = this.reportObject.totalPlannedProjectUnits;
    this.numDays = this.reportObject.totalPlannedProjectDays;
    this.remarks = this.reportObject.noProductionDesc;
    if (this.reportObject.plannedStart) {
      this.plannedStart = this.utilService.convertTimeStampTOdate(
        this.reportObject.plannedStart
      );
    } else {
      this.plannedStart = null;
    }
    if (this.reportObject.plannedEnd) {
      this.plannedEnd = this.utilService.convertTimeStampTOdate(
        this.reportObject.plannedEnd
      );
    } else {
      this.plannedEnd = null;
    }
    // this.plannedStart = this.utilService.convertTimeStampTOdate(this.reportObject.plannedStart);
    // this.plannedEnd = this.utilService.convertTimeStampTOdate(this.reportObject.plannedEnd);
    this.windows = this.reportObject.reportWindows;
    this.windowsToSubmit = [];
    this.prepareWindowsForDisplay();
  }

  formReportObject() {
    this.reportObject['formId'] = this.formId;
    this.reportObject['jobId'] = this.jobId;
    this.reportObject['projectId'] = this.projectId;
    this.reportObject['noProductionCode'] =
      this.noProdType === null
        ? this.convertNoProductionCode(this.noProdType)
        : this.noProdType;
    this.reportObject['workday'] = this.convertDateTimeToEpoch(this.onDutyTime);
    this.reportObject['totalPlannedProjectUnits'] = this.numUnits;
    this.reportObject['totalPlannedProjectDays'] = this.numDays;
    this.reportObject['noProductionDesc'] = this.remarks;
    this.reportObject['plannedStart'] = new Date(this.plannedStart).getTime();
    this.reportObject['plannedEnd'] = new Date(this.plannedEnd).getTime();
    if (this.windowsToSubmit.length > 0) {
      this.reportObject['reportWindows'] = this.windowsToSubmit;
    } else {
      this.reportObject['reportWindows'] = this.windows;
    }
    this.reportObject['divisionCode'] = this.jobInfo.division;
    this.reportObject['subdivCode'] = this.jobInfo.subdivision;
    this.reportObject['workTypeCode'] = this.workTypeCode;
    this.reportObject['jobType'] = this.jobInfo.jobTypeCode;
    this.reportObject['roadMaster'] = null;
    this.reportObject['teamName'] = this.teamName;
    this.reportObject['daysToComplete'] = this.calculateDaysToComplete();
    this.reportObject['unitsRemaining'] = this.unitsRemaining;
    if (this.isPastDayReport()) {
      if (this.pastReport) {
        this.reportObject[
          'plannedCrossingCount'
        ] = this.pastReport.plannedCrossingCount;
      } else {
        this.reportObject[
          'plannedCrossingCount'
        ] = this.projectInfo.initialPlannedCrossing;
      }
    } else {
      this.reportObject[
        'plannedCrossingCount'
      ] = this.projectInfo.plannedCrossingCount;
    }
  }

  validateType() {
    this.noProdTypeInvalid = false;
    if (this.noProdType === null || this.noProdType === undefined) {
      this.noProdTypeInvalid = true;
    } else {
      if (!this.convertNoProductionCode(this.noProdType)) {
        this.noProdTypeInvalid = true;
      }
    }
  }

  onNoProdTypeChange() {
    this.windows = [];
    this.setWindowReceived();
    if (this.windowReceived) {
      this.windows.push(new Window(null, null, this.windows.length + 1, null));
    }
  }

  protected checkReportOperationMode() {
    if (this.reportObject.status === 'D') {
      this.operationMode = OperationMode.CREATE;
    } else {
      this.operationMode = OperationMode.VIEW;
    }
  }

  protected getReportMethod() {
    return this.ds.getReport(Number(this.formId));
  }

  private setWindowReceived() {
    if (this.windowRequiredNoProdType.indexOf(this.noProdType) > -1) {
      this.windowReceived = true;
    } else {
      this.windowReceived = false;
    }
  }

  validateOnDutyTime() {
    if (!this.firstReportInProject && !this.formSubmitted) {
      this.numDays = null;
      this.numUnits = null;
      this.numUnitsError = '';
      this.numDaysError = '';
      this.numDaysInvalid = false;
      this.numUnitsInvalid = false;
    }
    this.today = this.utilService.getCurrentDateTime();
    if (
      this.onDutyTime === null ||
      this.onDutyTime === undefined ||
      this.onDutyTime === ''
    ) {
      this.onDutyTimeInvalid = true;
      this.onDutyTimeError = 'Enter On Duty Time.';
      this.plannedProjectWarningMessage = PLANNED_PROJECT_NOTIFICATION;
      this.numUnitsInvalid = false;
      this.numUnitsError = '';
      this.numDaysError = '';
      this.numDaysInvalid = false;
      if (this.projectReports.length <= 1) {
        if (this.reportObject.status === 'D' || this.projectReports.length === 0) {
          this.plannedProjectWarningMessage = null;
        }
      }
    } else if (this.onDutyTime > this.today) {
      this.onDutyTimeInvalid = true;
      this.onDutyTimeError = 'On Duty Time cannot be in the future.';
    } else if (this.compareToCurrentYear()) {
      this.onDutyTimeInvalid = true;
      this.onDutyTimeError = 'On Duty Time must be this year.';
    } else if (this.checkReportsOnDutyDate()) {
      this.onDutyTimeInvalid = true;
      this.onDutyTimeError = 'Rest day report exists on this day.';
    } else {
      this.plannedProjectWarningMessage = null;
      this.plannedStartMin = this.onDutyTime;
      this.plannedStartMax = this.utilService.plannedStartMaxDateTime(
        this.onDutyTime
      );
      console.log('this.plannedStartMax', this.plannedStartMax);
      this.onDutyTimeInvalid = false;
      if (this.plannedStartError === 'Enter on Duty First') {
        this.plannedStartError = '';
        this.plannedStartInvalid = false;
      }
      if (this.plannedStart) {
        this.validatePlannedStart();
      }

      if (this.windowReceived && this.windows.length > 0) {
        for (let i = 0; i < this.windows.length; i++) {
          if (this.windows[i].windowStart) {
            this.validateTrackReceived(i);
          }
          if (this.windows[i].windowEnd) {
            this.validateTrackCleared(i);
          }
        }
      } else {
        this.trackReceivedInvalid.length = 0;
        this.trackClearedInvalid.length = 0;
      }

      if (!this.formSubmitted) {
        this.pastReport = this.reportDataService.getLatestReportBeforePastDay(
          this.teamName,
          this.formId,
          this.projectReports,
          new Date(this.onDutyTime).getTime()
        );
        this.numDays = this.pastReport.totalPlannedProjectDays;
        this.numUnits = this.pastReport.totalPlannedProjectUnits;
        this.unitsRemaining = this.pastReport
          ? this.pastReport.unitsRemaining
          : this.projectInfo.initialPlannedProjectUnits;
      }
    }
  }

  validateNumUnits() {
    if (this.numUnits === null || this.numUnits === undefined) {
      this.numUnitsInvalid = true;
      this.numUnitsError = 'Enter number of units.';
    } else if (this.numUnits === 0) {
      this.numUnitsInvalid = true;
      this.numUnitsError = 'Should be > 0.';
    } else {
      if (/[^0-9]/.test(this.numUnits.toString())) {
        this.numUnitsInvalid = true;
        this.numUnitsError = 'Invalid number of units.';
      } else {
        this.numUnitsInvalid = false;
        if (this.isPastDayReport()) {
          const pasPlannedUnits = this.pastReport
            ? this.pastReport.totalPlannedProjectUnits
            : this.projectInfo.initialPlannedProjectUnits;
            const pastUnitsRemaining = this.pastReport
            ? this.pastReport.unitsRemaining
            : this.projectInfo.initialPlannedProjectUnits;
          this.unitsRemaining =
            pastUnitsRemaining + (this.numUnits - pasPlannedUnits);
        }
      }
    }
  }

  validateNumDays() {
    if (this.numDays === null || this.numDays === undefined) {
      this.numDaysInvalid = true;
      this.numDaysError = 'Enter number of days.';
    } else if (this.numDays === 0) {
      this.numDaysError = 'Should be > 0.';
      this.numDaysInvalid = true;
    } else {
      if (/[^0-9]/.test(this.numDays.toString())) {
        this.numDaysInvalid = true;
        this.numDaysError = 'Invalid number of days.';
      } else {
        this.numDaysInvalid = false;
      }
    }
  }

  plannedStartCheck() {
    if (this.onDutyTime === null) {
      this.plannedStartInvalid = true;
      this.plannedStartError = 'Enter on Duty First';
    } else {
      this.validatePlannedStart();
    }
  }

  validatePlannedStart() {
    if (
      this.plannedStart === null ||
      this.plannedStart === undefined ||
      this.plannedStart === ''
    ) {
      this.plannedStartInvalid = true;
      this.plannedStartError = 'Enter Planned Start time.';
    } else {
      if (this.plannedStart < this.onDutyTime) {
        this.plannedStartInvalid = true;
        this.plannedStartError = 'Must be > On Duty Time.';
      } else if (this.plannedStart > this.plannedStartMax) {
        this.plannedStartInvalid = true;
        this.plannedStartError = 'Must be < 24hrs from On Duty Time.';
      } else {
        this.plannedStartInvalid = false;
        this.plannedEndMax = this.utilService.plannedEndMaxDateTime(
          this.plannedStart
        );
        if (this.plannedEndError === 'Enter Planned start time.') {
          this.plannedEndError = '';
          this.plannedEndInvalid = false;
        }
        if (this.plannedEnd) {
          this.validatePlannedEnd();
        }
      }
    }
  }

  plannedEndCheck() {
    if (this.plannedStart === null) {
      this.plannedEndInvalid = true;
      this.plannedEndError = 'Enter Planned start time.';
    } else {
      this.validatePlannedEnd();
    }
  }

  validatePlannedEnd() {
    if (
      this.plannedEnd === null ||
      this.plannedEnd === undefined ||
      this.plannedEnd === ''
    ) {
      this.plannedEndInvalid = true;
      this.plannedEndError = 'Enter Planned End time.';
    } else {
      if (this.plannedEnd <= this.plannedStart) {
        this.plannedEndInvalid = true;
        this.plannedEndError = 'Must be > Planned Start Time.';
        // } else if (this.getDateTimeDifference(this.plannedEnd, this.plannedStart) >= 14) {
      } else if (this.plannedEnd > this.plannedEndMax) {
        this.plannedEndInvalid = true;
        this.plannedEndError = 'Planned window time should be < 14 hours.';
      } else {
        this.plannedEndInvalid = false;
      }
    }
  }

  validateTrackReceived(index: number) {
    if (!this.windowReceived) {
      return;
    }
    if (
      this.windows[index].windowStart === null ||
      this.windows[index].windowStart === undefined ||
      this.windows[index].windowStart === ''
    ) {
      this.trackReceivedInvalid[index] = true;
      this.trackReceivedError[index] = 'Enter track received.';
    } else {
      const today = this.utilService.getCurrentDateTime();
      const onDutyTimeNextDay = this.utilService.plannedStartMaxDateTime(
        this.onDutyTime
      );
      if (this.windows[index].windowStart > today) {
        this.trackReceivedInvalid[index] = true;
        this.trackReceivedError[index] =
          'Track Recieved cannot be in the future.';
      } else if (this.windows[index].windowStart < this.onDutyTime) {
        this.trackReceivedInvalid[index] = true;
        this.trackReceivedError[index] = 'Must be >= On Duty Time.';
      } else if (
        this.onDutyTime < today &&
        !(
          this.windows[index].windowStart >= this.onDutyTime &&
          this.windows[index].windowStart <= onDutyTimeNextDay
        )
      ) {
        this.trackReceivedInvalid[index] = true;
        this.trackReceivedError[index] =
          'Should be < 24 hrs from On Duty Time.';
      } else if (index > 0) {
        if (
          this.windows[index].windowStart <= this.windows[index - 1].windowEnd
        ) {
          this.trackReceivedInvalid[index] = true;
          this.trackReceivedError[index] =
            'Must be > Previous window track cleared.';
        } else {
          this.trackReceivedInvalid[index] = false;
        }
      } else {
        this.trackReceivedInvalid[index] = false;
      }
    }
  }

  validateTrackCleared(index: number) {
    if (!this.windowReceived) {
      return;
    }
    if (
      this.windows[index].windowEnd === null ||
      this.windows[index].windowEnd === undefined ||
      this.windows[index].windowEnd === ''
    ) {
      this.trackClearedInvalid[index] = true;
      this.trackClearedError[index] = 'Enter track cleared.';
    } else {
      const today = this.utilService.getCurrentDateTime();
      const onDutyTimeNextDay = this.utilService.plannedStartMaxDateTime(
        this.onDutyTime
      );
      if (this.windows[index].windowEnd > today) {
        this.trackClearedInvalid[index] = true;
        this.trackClearedError[index] =
          'Track Cleared cannot be in the future.';
      } else if (this.windows[index].windowEnd < this.onDutyTime) {
        this.trackClearedInvalid[index] = true;
        this.trackClearedError[index] = 'Must be >= On Duty Time.';
      } else if (
        this.windows[index].windowStart >= this.windows[index].windowEnd
      ) {
        this.trackClearedInvalid[index] = true;
        this.trackClearedError[index] = 'Must be > Track Received.';
      } else if (
        this.onDutyTime < today &&
        !(
          this.windows[index].windowEnd >= this.onDutyTime &&
          this.windows[index].windowEnd <= onDutyTimeNextDay
        )
      ) {
        this.trackClearedInvalid[index] = true;
        this.trackClearedError[index] = 'Should be < 24 hrs from On Duty Time.';
      } else {
        this.trackClearedInvalid[index] = false;
      }
    }
  }

  // Returns true if any validation error exists.
  validateAll(): boolean {
    this.validateType();
    this.validateOnDutyTime();
    this.validateNumUnits();
    this.validateNumDays();
    this.validatePlannedStart();
    this.validatePlannedEnd();
    if (this.windowReceived) {
      for (let i = 0; i < this.windows.length; i++) {
        this.validateTrackReceived(i);
        this.validateTrackCleared(i);
      }
    } else {
      this.trackReceivedInvalid.length = 0;
      this.trackClearedInvalid.length = 0;
    }
    if (
      this.noProdTypeInvalid ||
      this.onDutyTimeInvalid ||
      this.numUnitsInvalid ||
      this.numDaysInvalid ||
      this.plannedStartInvalid ||
      this.plannedEndInvalid ||
      this.trackReceivedInvalid.indexOf(true) > -1 ||
      this.trackClearedInvalid.indexOf(true) > -1
    ) {
      return true;
    } else {
      return false;
    }
  }

  addWindow() {
    for (let i = 0; i < this.windows.length; i++) {
      this.validateTrackReceived(i);
      this.validateTrackCleared(i);
    }
    if (
      this.trackReceivedInvalid.indexOf(true) < 0 &&
      this.trackClearedInvalid.indexOf(true) < 0
    ) {
      this.windows.push(new Window(null, null, this.windows.length + 1, null));
    }
  }

  removeWindow(index: any): void {
    this.windows.splice(index, 1);
    this.trackReceivedInvalid.splice(index, 1);
    this.trackClearedInvalid.splice(index, 1);
  }

  fillOnDutyTime() {
    if (this.onDutyTime === null || this.onDutyTime === undefined) {
      const date = new Date().valueOf();
      this.onDutyTime = this.convertEpochToDateTime(date);
    }
  }

  fillTrackReceived(index: number) {
    if (
      this.trackReveivedFirstClick[index] ||
      this.trackReveivedFirstClick[index] == null ||
      this.trackReveivedFirstClick[index] === undefined
    ) {
      this.windows[index].windowStart = this.onDutyTime;
      this.trackReveivedFirstClick[index] = false;
    }
  }

  calculateDaysToComplete(): number {
    const previousReport = this.reportDataService.getLatestReportBeforePastDay(
      this.teamName,
      this.formId,
      this.projectReports,
      this.onDutyTime
    );
    if (previousReport) {
      if (
        this.convertEpochToDate(previousReport.workday) ===
        this.convertEpochToDate(this.convertDateTimeToEpoch(this.onDutyTime))
      ) {
        return (
          previousReport.daysToComplete +
          (this.numDays - previousReport.totalPlannedProjectDays)
        );
      } else {
        return (
          previousReport.daysToComplete +
          (this.numDays - previousReport.totalPlannedProjectDays) -
          1
        );
      }
    } else {
      return this.numDays - 1;
    }
  }

  convertNoProductionCode(value: string): string {
    if (value === null) {
      return 'No Code Selected';
    } else if (value === 'No Code Selected') {
      return null;
    } else {
      return value;
    }
  }

  formSubmit(event: any): void {
    this.formSubmitted = true;
    this.formInvalid = this.validateAll();
    if (!this.formInvalid) {
      this.windowsToSubmit = this.prepareWindowsForSubmission();
      this.formReportObject();
      if (!this.isPastDayReport() || !this.reportExistsAfterWorkDay()) {
        this.reportObject = this.utilService.updateProjectPlannedValues(
          this.reportObject,
          this.projectId,
          true
        );
      }
      this.submitReport();
      this.parentSubject.next('form valid');
    } else {
      this.formSubmitted = false;
    }
  }

  private isPastDayReport(): boolean {
    const yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    yesterdayDate.setHours(23, 59, 59, 999);
    return (
      this.convertDateTimeToEpoch(this.onDutyTime) <= yesterdayDate.getTime()
    );
  }

  private reportExistsAfterWorkDay(): boolean {
    let futureDayReportExist = false;
    this.projectReports.forEach(report => {
      if (
        report.teamName === this.teamName &&
        report.status !== 'D' &&
        report.formId.toString() !== this.formId.toString()
      ) {
        if (
          this.convertEpochToDate(report.workday) !==
          this.convertEpochToDate(
            this.convertDateTimeToEpoch(this.onDutyTime)
          ) &&
          report.workday > this.convertDateTimeToEpoch(this.onDutyTime)
        ) {
          futureDayReportExist = true;
          return;
        }
      }
    });
    console.log('future report exist : ' + futureDayReportExist);
    return futureDayReportExist;
  }

  protected backIcon(event) {
    if (this.onDutyTime === null) {
      this.onDutyTime = this.utilService.getCurrentDateTime();
    }
    if (this.reportObject.status === 'S' || this.reportObject.formId > 0) {
      this.router.navigate([`/job_view/${this.projectId}`]);
    } else {
      this.formReportObject();
      this.saveReport();
      this.router.navigate([`/job_view/${this.projectId}`]);
    }
  }

  protected backButtonNavigate() {
    this.formReportObject();
    this.saveReport();
    this.router.navigate([`/job_view/${this.projectId}`]);
  }

  saveReport() {
    this.ds
      .saveDraftReport(JSON.stringify(this.reportObject))
      .then(response => { })
      .catch(reason => {
        console.log(reason);
      });
  }

  submitReportData() {
    this.ds
      .submitReport(JSON.stringify(this.reportObject))
      .then(response => { })
      .catch(reason => {
        console.log(reason);
      });
  }
  protected submitReport() {
    this.submitReportData();
  }

  getDateTimeDifference(a: string, b: string): number {
    // a = this.onDutyTime.split('T')[0] + 'T' + a;
    // b = this.onDutyTime.split('T')[0] + 'T' + b;
    const date1 = new Date(a);
    const date2 = new Date(b);
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const diff = timeDiff / (1000 * 3600);
    console.log('diff', diff);
    return diff;
  }

  calculateChar() {
    this.length = 200 - (this.remarks ? this.remarks.length : 0);
  }

  compareToCurrentYear(): boolean {
    const currentYear = this.today.substring(0, 4);
    const compareYear = this.onDutyTime.substring(0, 4);
    return currentYear !== compareYear;
  }

  checkReportsOnDutyDate(): boolean {
    let match = false;
    const onDuty = this.onDutyTime.split('T')[0];
    for (const report of this.projectReports) {
      const compare = this.convertEpochToDate(report.workday);
      let reportType = '';
      if (report.hasOwnProperty('noProductionCode')) {
        if (report['noProductionCode'] === 'RST') {
          reportType = 'REST';
        } else {
          reportType = 'NO PRODUCTION';
        }
      } else {
        reportType = 'PRODUCTION';
      }
      if (
        reportType === 'REST' &&
        compare === onDuty &&
        this.teamName === report.teamName
      ) {
        match = true;
        break;
      }
    }
    return match;
  }

  convertTimeToDateTime(time: string): string {
    if (time == null) {
      return null;
    }
    const date = this.onDutyTime.split('T')[0];
    return date + 'T' + time;
  }

  convertDateTimeToEpoch(dateTime: string) {
    if (dateTime == null) {
      return null;
    }
    return new Date(dateTime).getTime();
  }

  convertEpochToTime(epoch: number) {
    if (epoch == null) {
      return null;
    }
    return new Date(epoch).toTimeString().substring(0, 5);
  }

  convertEpochToDateTime(epoch: number) {
    if (epoch == null) {
      return null;
    }
    let date = new Date(epoch).toLocaleDateString().replace(/\//g, '-');
    const dateArray = date.split('-');
    date =
      dateArray[2] +
      '-' +
      this.convertSingleDigitToDouble(dateArray[0]) +
      '-' +
      this.convertSingleDigitToDouble(dateArray[1]);
    return date + 'T' + new Date(epoch).toTimeString().substring(0, 5);
  }

  convertEpochToDate(epoch: number) {
    if (epoch == null) {
      return null;
    }
    let date = new Date(epoch).toLocaleDateString().replace(/\//g, '-');
    const dateArray = date.split('-');
    date =
      dateArray[2] +
      '-' +
      this.convertSingleDigitToDouble(dateArray[0]) +
      '-' +
      this.convertSingleDigitToDouble(dateArray[1]);
    return date;
  }

  convertSingleDigitToDouble(value: string): string {
    if (value.length === 1) {
      return '0' + value;
    } else {
      return value;
    }
  }

  prepareWindowsForSubmission(): Array<Window> {
    const result: Array<Window> = [];
    for (let i = 0; i < this.windows.length; i++) {
      const resultObj = new Window(
        this.convertDateTimeToEpoch(this.windows[i].windowStart),
        this.convertDateTimeToEpoch(this.windows[i].windowEnd),
        this.windows[i].windowNumber,
        this.windows[i].unitsInstalled
      );
      result.push(resultObj);
    }

    return result;
  }

  prepareWindowsForDisplay() {
    this.setWindowReceived();
    if (this.windowReceived) {
      for (let i = 0; i < this.windows.length; i++) {
        this.windows[i].windowStart = this.convertEpochToDateTime(
          this.windows[i].windowStart
        );
        this.windows[i].windowEnd = this.convertEpochToDateTime(
          this.windows[i].windowEnd
        );
      }
    }
  }
}

interface TypeOptions {
  label: string;
  code: string;
}

export class Window {
  windowStart: any;
  windowEnd: any;
  windowNumber: number;
  unitsInstalled: number;

  constructor(
    windowStart: any,
    windowEnd: any,
    windowNumber: number,
    unitsInstalled: number
  ) {
    this.windowStart = windowStart;
    this.windowEnd = windowEnd;
    this.windowNumber = windowNumber;
    this.unitsInstalled = unitsInstalled;
  }
}
