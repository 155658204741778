import { Component } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { RailInfoComponent } from '../rail-info/rail-info.component';
import { DataService } from '../services/data.service';
import { JobTable, LocalStorageService } from '../services/local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../util/util.service';
import { ReportDataService } from '../shared/services/report-data.service';
import { ConfirmationService } from 'primeng/primeng';
import { AppConstant } from '../shared/constant/constants';
import { OperationMode } from '../shared/enums/operation-mode.enum';

@Component({
  selector: 'app-gauging-info',
  templateUrl: './gauging-info.component.html',
  styleUrls: ['../rail-info/rail-info.component.scss']
})
export class GaugingInfoComponent extends RailInfoComponent {
  cutRailHeated = [];
  jobType: string;
  cutheatedflag = true;
  enableCount: number;
  railCutOptions = [];

  constructor(
    public fb: FormBuilder,
    public dataService: DataService,
    public ls: LocalStorageService,
    public router: Router,
    public Activateroute: ActivatedRoute,
    public utilService: UtilService,
    public reportDataServcie: ReportDataService,
    public acknowledgeService: ConfirmationService
  ) {
    super(fb, dataService, ls, router, Activateroute, utilService, reportDataServcie, acknowledgeService);
  }

  form() {
    super.form();
    this.jobType = this.formGroup.controls['jobType'].value;
    if (this.jobType === 'CP') {
      this.formGroup.addControl('cutRailHeated', new FormControl('Y', []));
      this.formGroup.removeControl('tiePlateType');
      this.railCutOptions = [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' }
      ];
    } else {
      this.formGroup.addControl(
        'tiePlateCount',
        new FormControl(this.railInfo.tiePlateCount, [Validators.pattern('^[0-9][0-9]*$'), Validators.required])
      );
    }
    this.formGroup.controls['railWeight'].clearValidators();
    this.formGroup.removeControl('railType');
    this.formGroup.removeControl('railGrade');
  }

  createCutRailGroup(): FormGroup {
    const cutRailFormGroup = super.createCutRailGroup();
    cutRailFormGroup.addControl('isRailCut', new FormControl('Y'));
    return cutRailFormGroup;
  }

  railCutChange(index: number) {
    this.formGroup['controls'].cutRailArray['controls'][index]['controls']['isWorkSkipped'].reset();
    this.formGroup.updateValueAndValidity();
    this.recalculateCutRail(index);
  }

  showTableRow(i: number): boolean {
    if (this.formGroup['controls'].cutRailArray['controls'][i].enabled &&
      this.formGroup['controls'].cutRailArray['controls'][i]['controls']['isWorkSkipped'].value === false) {
      if (this.jobType === AppConstant.JOB_TYPE.CONCRETE_PAD) {
        if (this.formGroup['controls'].cutRailArray['controls'][i]['controls']['isRailCut'].value === 'Y') {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  reportfill(response: any) {
    console.log(this.formGroup);
    const reportData = response;
    try {
      this.checkReportOperationMode(reportData);
      const keys = [];
      for (const k in reportData) {
        if (k) {
          keys.push(k);
        }
      }
      console.log('keys');
      console.log(keys);
      // *****   this rail keys is used to keep track of all records keys using in rail info
      const railkeys = [
        'formId',
        'projectId',
        'jobId',
        'beginMp',
        'direction',
        'tiePlateType',
        'roadMaster',
        'rl',
        'railSide',
        'workday',
        'railLength',
        'welds',
        'iJoints',
        'transitions',
        'tiePlateCount',
        'railWeight',
        'remarks',
        'highMp',
        'lowMp',
        'trackType',
        'endMp',
        'cutRailHeated',
        'railSideDisc',
        'supervisor'
      ];
      const newvaluesarray = this.utilService.differenceOf2Arrays(keys, railkeys);
      const anothObject = {};
      newvaluesarray.forEach(function (eachObj) {
        anothObject[eachObj] = reportData[eachObj];
      });
      // *****this new values are key values which are out of rail info component //
      // we are storing in this new values object and appending  back to to the final report on submit
      this.newvalues = anothObject;
    } catch (error) {
      console.log('on adding other values');
      console.log(error);
    }
    const reportkeys = [
      'welds',
      'beginMp',
      'iJoints',
      'transitions',
      'tiePlateCount',
      'direction',
      'railType',
      'railLength',
      'rl',
      'railWeight',
      'roadMaster',
      'remarks',
      'tiePlateType',
      'railSide',
      'lowMp',
      'highMp',
      'endMp',
      'cutRailHeated',
      'railSideDisc',
      'supervisor'
    ];
    reportkeys.forEach(Obj => {
      if (this.formGroup.controls[Obj]) {
        this.formGroup.controls[Obj].patchValue(reportData[Obj]);
      }
    });

    if (reportData.railSideDisc) {
      this.showField = true;
    } else {
      this.showField = false;
    }
    this.verifyMp(reportData.beginMp);
    this.toogleFields(reportData.railSide);
    this.railInfo.railSideDisc = reportData.railSideDisc;
    this.formGroup.controls['supervisor'].patchValue(reportData.supervisor ? reportData.supervisor : 'System Error');
    this.verifyMp(reportData.beginMp);
    // ***** we are changing back timestamp to iso format which date input value will allow
    reportData.workday = reportData.workday === 0 ? new Date() : reportData.workday;
    const timestampdate = new Date(reportData.workday).toISOString();
    this.ritime = new Date(timestampdate);
    this.formGroup.controls['workday'].patchValue(this.ritime);
    if (reportData.workday !== 0) {
      this.formGroup.controls['date'].patchValue(
        this.ritime.getFullYear() +
        '-' +
        ('0' + (this.ritime.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + this.ritime.getDate()).slice(-2) +
        'T' +
        ('0' + this.ritime.getHours()).slice(-2) +
        ':' +
        ('0' + this.ritime.getMinutes()).slice(-2)
      );
    }

    this.cutRailArray = <FormArray>this.formGroup.controls['cutRailArray'];
    // ***** to  check and  clear the cut rail array if already added and patching report data cut rail to formgroup
    if (this.cutRailArray && reportData.reportCutRail) {
      this.cutRailArray.removeAt(0);
    }
    reportData.reportCutRail.forEach((app, index) => {
      this.disabledRows[index] = app.isWorkSkipped;
      const fb = this.createCutRailGroup();
      fb.patchValue(app);
      fb.patchValue({
        cutRailLength: app.cutRailLength,
        openJoints: app.openJoints || 0,
        isWorkSkipped: app.isWorkSkipped
      });
      if (this.jobName === AppConstant.JOB_TYPE.CONCRETE_PAD) {
        if (app.stringId) {
          fb.patchValue({ isRailCut: 'Y' });
        } else {
          fb.patchValue({ isRailCut: 'N' });
          this.disabledRows[index] = true;
        }
      }
      if (app.cutRailLength && !app.isWorkSkipped) {
        Object.keys(fb.controls).forEach(field => {
          const control = fb.get(field);
          control.markAsTouched({ onlySelf: true });
        });
      }

      this.cutRailArray.push(fb);
      this.showAddMore = app.cutRailLength != null ? true : false;
      this.enableflag = true;
      this.sumbitedFormLength = this.cutRailArray.length;
      console.log('this.cutRailArray in reportfill');
      this.ls.getProjectJobs(this.projectId).then((jobList: Array<JobTable>) => {
        jobList.map((obj, i) => {
          if (obj.jobId === this.jobId) {
            this.reportobject.desiredNeutralTemp = obj.jobObj['desiredNeutralTemp'];
            this.tunnelChange(index);
            fb['controls']['designedLayTemp'].patchValue(app.designedLayTemp);
          }
        });
      });
      console.log(this.cutRailArray);
      if (reportData.reportCutRail.length === index + 1) {
        this.calTotalOpenJoints(index - 1);
      }
      if (app.cutRailLength && !app.isWorkSkipped) {
        if (this.jobName === AppConstant.JOB_TYPE.CONCRETE_PAD) {
          if (app.stringId) {
            this.recalculateCutRail(index);
          }
        } else {
          this.recalculateCutRail(index);
        }
      }
    });
    this.cutRailArray.controls.forEach((formGroup: FormGroup, i: any) => {
      if (formGroup.controls['isWorkSkipped'].value === true || !formGroup.controls['stringId'].value) {
        this.tablekeys.forEach(Obj => {
          formGroup.controls[Obj].disable();
        });
      } else if (formGroup.controls['isWorkSkipped'].value === false && formGroup.controls['stringId'].value) {
        this.tablekeys.forEach(Obj => {
          formGroup.controls[Obj].enable();
          if (!formGroup.controls[Obj].value || formGroup.controls[Obj].value === null || formGroup.controls[Obj].value === '') {
            if (formGroup.controls[Obj].value !== 0) {
              formGroup.controls[Obj].markAsUntouched();
            }
          }
        });
      }
    });
    this.shouldDisable(0);
  }
}
