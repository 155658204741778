import { CurrentUserTable } from './../services/local-storage.service';
import * as moment from 'moment';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService, ReportTable } from '../services/local-storage.service';

import { ConditionallyValidateService } from 'ng-conditionally-validate';
import { DataService } from '../services/data.service';
import { Observable } from 'rxjs/Rx';
import { SelectItem } from 'primeng/primeng';
import { Subject } from 'rxjs/Subject';
import { TrackUtilization } from './track-utilization.component.constant';
import { UtilService } from '../util/util.service';
import { WorkType } from '../enums/work-type.enum';
import { OperationMode } from '../shared/enums/operation-mode.enum';
import { ErrorDialog } from '../model/error-dialog.model';

/**
 * @export
 * @class TrackUtilizationComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-track-utilization',
  templateUrl: './track-utilization.component.html',
  styleUrls: ['./track-utilization.component.scss']
})
export class TrackUtilizationComponent implements OnInit {
  operationMode: OperationMode = OperationMode.CREATE;
  windowCount: number;
  trainPassCountError: boolean;
  validCount: number;
  minDate: any;
  trackWindowValid = false;
  trackWindowValidIndex: boolean[] = [];
  trackReceivedFirstClick: boolean[] = [];
  trackClearedFirstClick: boolean[] = [];
  yardCharLength = 15;
  public nextDay: string;
  public firstDay: string;
  public todayDate: string;
  public formGroup: FormGroup | any;
  public reportWindows: FormArray | any;
  public formSubmitAttempt = false;
  public showYardName = false;
  public showMachineFailure = false;
  public showNameOfOfficer = false;
  public commentLength = 200;
  public DelayIssuesLength = 200;
  public workDescriptionLength = 200;
  public showCommentOutLimitError = false;
  public delayIssuesTextOutLimitError = false;
  public workDescriptionTextOutLimitError = false;
  public maxTieLocationLength = 120;
  public projectData: any;
  public pageNav: string;
  public isValidationError = false;
  public isPlannedEndInvalidTime = false;
  public showInvalidDelayTimeError = false;
  public isUnitsNotMatchWithCutRailLength = false;
  public OnDutyDate: Date;
  public unitsInstalled: number;
  public totalUnitInstalled: any;
  public reportstatus = false;
  public validateNameOfYard: Observable<boolean>;
  public validateNameOfOfficer: Observable<boolean>;
  public validateComments: Observable<boolean>;
  public machineFailureSeq: SelectItem[];
  public transOfficerAval: SelectItem[];
  public workAtYard: SelectItem[];
  public trackReturnLate: SelectItem[];
  public fullBlockReceivedOptions: SelectItem[] = [];
  public minWindowStart: any;
  public futureGreenSignalError = false;
  public reportData: any = {
    workAtYard: 'N',
    machineFailureSeq: 'N',
    unitsInstalled: ''
  };
  public timeOnlyPattern = '^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$';
  public projectId: number;
  public jobId: number;
  public formId: number;
  public jobType: string;
  public workType: string;
  public isPlannedStartInvalidTime = false;
  public inputPlannedStartTime: any;
  public checkOnDutyPlannedStartTime = false;
  public inputPlannedEndTime: any;
  public checkOnDutyPlannedEndTime = false;
  public exceedPlannedWindowTime = false;
  public exceedPlannedEndTime = false;
  public systemTime: string;
  public surfacingOnlyFlag: boolean;
  public surfacingSum: number;
  plannedEndMax: Date;
  plannedStartMin: Date;
  plannedEndInvalid: boolean;
  plannedEndError: string;
  trackReceivedFlag: any = [];
  trackReceivedError: any = [];
  trackClearedFlag: any = [];
  trackClearedError: any = [];

  parentSubject: Subject<any> = new Subject();
  // Front End error messages.
  workedAtYardError: any = {
    isworkedAtYardError: false,
    workedAtYardErrorMessage: ''
  };
  nameOfOfficerError: any = {
    isNameOfOfficerError: false,
    nameOfOfficerErrorMessage: ''
  };
  commentsError: any = { isCommentsError: false, commentsErrorMessage: '' };
  officerAtOnsiteError: any = {
    isOfficerAtOnsiteError: false,
    officerAtOnsiteErrorMessage: ''
  };
  trackedreturnOntimeError: any = {
    isTrackedreturnOntimeError: false,
    trackedreturnOntimeErrorMessage: ''
  };
  addWindowError: boolean;
  projectReports = [];
  teamName: any;

  showErrorComponent: boolean;
  errorDialogData: ErrorDialog;
  showSyncComponent: boolean;
  loading: boolean;
  reasonError: boolean;
  reasonForEdit: string;
  /**
   * Creates an instance of TrackUtilizationComponent.
   * @param {FormBuilder} fb
   * @param {ConditionallyValidateService} cv
   * @param {Router} router
   * @param {ActivatedRoute} activeRoute
   * @param {DataService} dataService
   * @param {LocalStorageService} localStorage
   * @memberof TrackUtilizationComponent
   */
  constructor(
    public fb: FormBuilder,
    public cv: ConditionallyValidateService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    public dataService: DataService,
    public localStorage: LocalStorageService,
    public utilService: UtilService
  ) { }

  /**
   * @memberof TrackUtilizationComponent
   */
  ngOnInit() {
    console.log('TrackUtilizationComponent - ngOnInit() called');
    this.intializeReport();
    this.workAtYard = [];
    this.workAtYard.push({ label: 'Yes', value: 'Y' });
    this.workAtYard.push({ label: 'No', value: 'N' });
    this.machineFailureSeq = [];
    this.machineFailureSeq.push({ label: 'Yes', value: 'Y' });
    this.machineFailureSeq.push({ label: 'No', value: 'N' });
    this.transOfficerAval = [];
    this.transOfficerAval.push({ label: 'Yes', value: 'Y' });
    this.transOfficerAval.push({ label: 'No', value: 'N' });
    this.trackReturnLate = [];
    this.trackReturnLate.push({ label: 'Yes', value: 'Y' });
    this.trackReturnLate.push({ label: 'No', value: 'N' });
    this.fullBlockReceivedOptions.push({ label: 'Yes', value: 'Y' });
    this.fullBlockReceivedOptions.push({ label: 'No', value: 'N' });
    this.getReportMethod().then((res: ReportTable) => {
      console.log('reports', res.formId, res.reportObj);
      this.reportData = res.reportObj;
      this.jobType = this.reportData.jobType;
      this.workType = this.reportData.workTypeCode;


      this.setOperationMode();

      if (this.reportData.status === 'D') {
        this.setDataFromLatestReport();
      }
      if (this.reportData.workTypeCode === WorkType.SWITCHTIE) {
        this.surfacingSum = this.reportData.totalTiesInstalled;
        this.reportData.totalUnitInstalled = this.reportData.totalTiesInstalled;
      } else {
        if (this.reportData.jobType === 'TS') {
          this.reportData.totalUnitInstalled = this.reportData.totalTiesInstalled;
        } else if (this.reportData.jobType === 'SO') {
          let sum = 0;
          for (let i = 0; i < this.reportData.surfacingDetails.length; i++) {
            sum += parseInt(this.reportData.surfacingDetails[i].surfaced, 10) * this.reportData.surfacingDetails[i].passes;
          }
          this.surfacingSum = sum;
          this.reportData.totalUnitInstalled = this.surfacingSum;
        } else {
          this.reportData.totalUnitInstalled = this.reportData.railLength;
        }
      }
      this.unitsInstalled = this.reportData.totalUnitInstalled;
      console.log('Load Report Data', this.reportData);
      this.setTrackWindowDate(this.reportData);
      if (
        this.reportData &&
        (this.workType === WorkType.TIEANDSURFACE || this.workType === WorkType.SWITCHTIE) &&
        this.reportData.totalTiesInstalled <= 0
      ) {
        this.surfacingOnlyFlag = true;
      } else {
        this.surfacingOnlyFlag = false;
      }
      // Override pre-populated value if data available from Index DB
      if (this.reportData.workAtYard || this.viewMode) {
        this.reportfill(this.reportData);
      } else {
        this.reportWindows = <FormArray>this.formGroup.controls['reportWindows'];
        console.log('###########################', this.reportData.totalUnitInstalled);
        this.reportWindows.controls.forEach((app, i) => {
          this.reportWindows.removeAt(0);
          if (this.reportData.reportWindows) {
            this.reportData.reportWindows.forEach(window => {
              const fb = this.trackUtilReportWindow(window, this.reportData.reportWindows.length);
              this.reportWindows.push(fb);
            });
          } else {
            const fb = this.trackUtilWindowGroup();
            fb.patchValue({
              unitsInstalled: this.reportData.totalUnitInstalled,
              unitPerHour: app.unitPerHour,
              windowStarts: this.reportData.windowStarts,
              windowEnds: this.reportData.windowEnds
            });
            this.reportWindows.push(fb);
          }
          console.log(this.reportWindows.controls);
          this.trackWindow(i, '');
        });
      }
    });
  }
  get viewMode() {
    return this.operationMode === OperationMode.VIEW;
  }

  trackUtilReportWindow(reportWindow: any, length: Number) {
    let form: any = {
      windowNumber: [reportWindow.windowNumber, Validators.required],
      windowStarts: [reportWindow.windowStarts, Validators.required],
      windowEnds: [reportWindow.windowEnds, Validators.required],
      windowStart: [reportWindow.windowStart],
      windowEnd: [reportWindow.windowEnd]
    };
    if (this.surfacingOnlyFlag) {
      if (length === 1) {
        form = {
          unitsInstalled: [this.reportData.totalUnitInstalled],
          unitPerHour: [this.reportData.unitPerHour],
          ...form
        };
      } else {
        form = {
          unitsInstalled: [null],
          unitPerHour: [null],
          ...form
        };
      }
    } else {
      if (length === 1) {
        form = {
          unitsInstalled: [this.reportData.totalUnitInstalled, Validators.required],
          unitPerHour: [this.reportData.unitPerHour, Validators.required],
          ...form
        };
      } else {
        form = {
          unitsInstalled: [null, Validators.required],
          unitPerHour: [null, Validators.required],
          ...form
        };
      }

    }
    return this.fb.group(form);
  }

  intializeReport() {
    console.log('TrackUtilizationComponent - Intializing report');
    this.activeRoute.params.subscribe((params: any) => {
      this.projectData = params.project;
      if (this.projectData) {
        this.projectId = Number(this.projectData.split('-')[0]);
      }
      this.jobId = params.job;
      this.formId = params.form;
    });
    this.localStorage.getJobReports(Number(this.jobId)).then((reportList: Array<ReportTable>) => {
      if (reportList.length >= 1) {
        this.reportData.workAtYard = 'N';
        this.reportData.machineFailureSeq = 'N';
      }
      for (const p of reportList) {
        const js: any = JSON.parse(JSON.stringify(p.reportObj));
        console.log('Job Reports ', js);
        if (js.status === 'S') {
          this.reportstatus = true;
        }
      }
    });
    this.setInitialData();
    this.form();
  }

  setInitialData(): void {
    this.localStorage.getProjectReports(Number(this.projectId)).then((reportList: Array<ReportTable>) => {
      for (const p of reportList) {
        const js: any = JSON.parse(JSON.stringify(p.reportObj));
        this.projectReports.push(js);
      }
    });
  }

  protected getReportMethod() {
    return this.dataService.getReportTrackUtil(Number(this.formId));
  }


  protected updateWindowGroup(reportData?: any): FormGroup {
    const form: any = {
      windowNumber: [reportData.windowNumber, Validators.required],
      windowStarts: [reportData.windowStarts, Validators.required],
      windowEnds: [reportData.windowEnds, Validators.required],
      windowStart: [reportData.windowStarts],
      windowEnd: [reportData.windowEnds]
    };
    return this.fb.group(form);
  }

  form() {
    this.formGroup = this.fb.group({
      plannedStart: [this.reportData.plannedStart, Validators.required],
      plannedEnd: [this.reportData.plannedEnd, Validators.required],
      workAtYard: this.reportData.workAtYard,
      yardName: [this.reportData.yardName],
      greenSignalTime: [this.reportData.greenSignalTime, Validators.required],
      delayIssuesRemark: [this.reportData.delayIssuesRemark],
      issuesDesc: [this.reportData.issuesDesc],
      machineFailureSeq: [this.reportData.machineFailureSeq, Validators.required],
      transOfficerAval: [this.reportData.transOfficerAval, Validators.required],
      transOfficerName: [this.reportData.transOfficerName],
      trackReturnLate: [this.reportData.trackReturnLate, Validators.required],
      trackReturnLateRemarks: [this.reportData.trackReturnLateRemarks],
      trainsPassedCount: [this.reportData.trainsPassedCount, Validators.min(0)],
      delayTime: [this.reportData.delayTime],
      actualEmployeesCount: [this.reportData.actualEmployeesCount, Validators.required],
      fullBlockReceived: [this.reportData.fullBlockReceived, Validators.required],
      tieUpLocation: [this.reportData.tieUpLocation, Validators.required],
      nextDayLocation: [this.reportData.nextDayLocation, Validators.required],
      reportWindows: this.fb.array([this.trackUtilWindowGroup()])
    });
  }

  protected setOperationMode() {
    if (this.reportData.status === 'S' || this.reportData.formId > 0) {
      this.operationMode = OperationMode.VIEW;
    } else if (this.reportData.status === 'D') {
      this.operationMode = OperationMode.CREATE;
    }
  }

  protected trackUtilWindowGroup(): FormGroup {
    let form: any = {
      windowNumber: [this.reportData.windowNumber, Validators.required],
      windowStarts: [this.reportData.windowStarts, Validators.required],
      windowEnds: [this.reportData.windowEnds, Validators.required],
      windowStart: [this.reportData.windowStart],
      windowEnd: [this.reportData.windowEnd]
    };
    if (this.surfacingOnlyFlag) {
      form = {
        unitsInstalled: [this.reportData.unitsInstalled],
        unitPerHour: [this.reportData.unitPerHour],
        ...form
      };
    } else {
      form = {
        unitsInstalled: [this.reportData.unitsInstalled, Validators.required],
        unitPerHour: [this.reportData.unitPerHour, Validators.required],
        ...form
      };
    }
    return this.fb.group(form);
  }

  private setDataFromLatestReport() {
    this.localStorage.getCurrentUser('SYSTEM').then((c: CurrentUserTable) => {
      this.teamName = c.teamName;
    });

    this.formGroup.controls['delayIssuesRemark'].patchValue(this.reportData.delayIssuesRemark);
    if (this.reportData.delayIssuesRemark) {
      this.DelayIssuesLength = this.DelayIssuesLength - this.reportData.delayIssuesRemark.length;
    }
    this.formGroup.controls['issuesDesc'].patchValue(this.reportData.issuesDesc);
    if (this.reportData.issuesDesc) {
      this.workDescriptionLength = this.workDescriptionLength - this.reportData.issuesDesc.length;
    }
    if (this.reportData.plannedStart) {
      this.formGroup.controls['plannedStart'].patchValue(this.utilService.convertTimeStampTOdate(this.reportData.plannedStart));
    }
    if (this.reportData.plannedEnd) {
      this.formGroup.controls['plannedEnd'].patchValue(this.utilService.convertTimeStampTOdate(this.reportData.plannedEnd));
    }
    if (this.reportData.greenSignalTime) {
      this.formGroup.controls['greenSignalTime'].patchValue(this.utilService.convertTimeStampTOdate(this.reportData.greenSignalTime));
    }
    if (this.reportData.delayTime) {
      this.formGroup.controls['delayTime'].patchValue(this.reportData.delayTime);
    }
    if (this.reportData.actualEmployeesCount) {
      this.formGroup.controls['actualEmployeesCount'].patchValue(this.reportData.actualEmployeesCount);
    }
    if (this.reportData.fullBlockReceived) {
      this.formGroup.controls['fullBlockReceived'].patchValue(this.reportData.fullBlockReceived);
    }
    if (this.reportData.tieUpLocation) {
      this.formGroup.controls['tieUpLocation'].patchValue(this.reportData.tieUpLocation);
    }
    if (this.reportData.nextDayLocation) {
      this.formGroup.controls['nextDayLocation'].patchValue(this.reportData.nextDayLocation);
    }
    if (this.reportData.trackReturnLate) {
      this.formGroup.controls['trackReturnLate'].patchValue(this.reportData.trackReturnLate);
    }
    if (this.reportData.trackReturnLateRemarks) {
      this.formGroup.controls['trackReturnLateRemarks'].patchValue(this.reportData.trackReturnLateRemarks);
    }
  }
  /**
   * @private
   * @param {*} reportData
   * @memberof TrackUtilizationComponent
   */
  protected reportfill(reportData: any) {
    this.formGroup.controls['workAtYard'].patchValue(this.reportData.workAtYard);
    if (this.reportData.workAtYard === 'Y') {
      this.showYardName = true;
    }
    this.formGroup.controls['yardName'].patchValue(this.reportData.yardName);
    if (this.reportData.yardName !== null) {
      this.formGroup.controls['yardName'].markAsTouched(true);
    }
    this.formGroup.controls['delayIssuesRemark'].patchValue(this.reportData.delayIssuesRemark);
    if (this.reportData.delayIssuesRemark) {
      this.DelayIssuesLength = this.DelayIssuesLength - this.reportData.delayIssuesRemark.length;
    }
    this.formGroup.controls['issuesDesc'].patchValue(this.reportData.issuesDesc);
    if (this.reportData.issuesDesc) {
      this.workDescriptionLength = this.workDescriptionLength - this.reportData.issuesDesc.length;
    }
    this.formGroup.controls['machineFailureSeq'].patchValue(this.reportData.machineFailureSeq);
    if (this.reportData.machineFailureSeq === 'Y') {
      this.showMachineFailure = true;
    }
    this.formGroup.controls['transOfficerAval'].patchValue(this.reportData.transOfficerAval);
    if (this.reportData.transOfficerAval === 'Y') {
      this.showNameOfOfficer = true;
    }
    this.formGroup.controls['transOfficerName'].patchValue(this.reportData.transOfficerName);
    if (this.reportData.transOfficerName !== null) {
      this.formGroup.controls['transOfficerName'].markAsTouched(true);
    }
    this.formGroup.controls['trackReturnLate'].patchValue(this.reportData.trackReturnLate);
    if (this.reportData.trackReturnLate !== null) {
      this.formGroup.controls['trackReturnLate'].markAsTouched(true);
    }
    this.formGroup.controls['trackReturnLateRemarks'].patchValue(this.reportData.trackReturnLateRemarks);
    if (this.reportData.trackReturnLateRemarks !== null) {
      this.commentLength = this.commentLength - this.reportData.trackReturnLateRemarks.length;
      this.formGroup.controls['trackReturnLateRemarks'].markAsTouched(true);
    }
    this.formGroup.controls['trainsPassedCount'].patchValue(this.reportData.trainsPassedCount);
    if (this.reportData.plannedStart) {
      this.formGroup.controls['plannedStart'].patchValue(this.utilService.convertTimeStampTOdate(this.reportData.plannedStart));
      this.validateStartTime();
    }
    if (this.reportData.plannedEnd) {
      this.formGroup.controls['plannedEnd'].patchValue(this.utilService.convertTimeStampTOdate(this.reportData.plannedEnd));
    }
    if (this.reportData.greenSignalTime) {
      this.formGroup.controls['greenSignalTime'].patchValue(this.utilService.convertTimeStampTOdate(this.reportData.greenSignalTime));
    }
    if (this.reportData.delayTime) {
      console.log('Delay Time ', this.reportData.delayTime);
      this.formGroup.controls['delayTime'].patchValue(this.reportData.delayTime);
    }
    if (this.reportData.actualEmployeesCount) {
      this.formGroup.controls['actualEmployeesCount'].patchValue(this.reportData.actualEmployeesCount);
    }
    if (this.reportData.fullBlockReceived) {
      this.formGroup.controls['fullBlockReceived'].patchValue(this.reportData.fullBlockReceived);
    }
    if (this.reportData.tieUpLocation) {
      this.formGroup.controls['tieUpLocation'].patchValue(this.reportData.tieUpLocation);
    }
    if (this.reportData.nextDayLocation) {
      this.formGroup.controls['nextDayLocation'].patchValue(this.reportData.nextDayLocation);
    }
    this.reportWindows = <FormArray>this.formGroup.controls['reportWindows'];
    reportData.reportWindows.forEach((app, i) => {
      this.reportWindows.removeAt(i);
    });
    this.windowCount = reportData.reportWindows.length;
    console.log('###########################', this.reportData.totalUnitInstalled);
    reportData.reportWindows.forEach((app, i) => {
      const fb = this.trackUtilWindowGroup();
      fb.patchValue({
        unitsInstalled:
          this.reportData.totalUnitInstalled !== app.unitsInstalled && i === 0 && app.windowNumber === 1 && !(this.windowCount > 1)
            ? this.reportData.totalUnitInstalled
            : app.unitsInstalled,
        unitPerHour: app.unitPerHour,
        windowStarts: app.windowStarts,
        windowEnds: app.windowEnds
      });
      this.reportWindows.push(fb);
      this.trackWindow(i, '');
    });
    this.validateTrackWindow();
    if (this.reportData.yardName) {
      this.yardCharLength = this.yardCharLength - this.reportData.yardName.length;
    }

  }

  /**
   *  Method 'setTrackUtilWindowDate' restrict the user to select WorkDay + 1
   * from calendar component
   * @private
   * @memberof TrackUtilizationComponent
   */
  protected setTrackWindowDate(reportData: any): void {
    const timestampdate = new Date(this.utilService.getWorkDay(this.reportData)).toISOString();
    this.setWindowValidation(timestampdate);
  }

  protected setWindowValidation(timestampdate: any) {
    console.log('Work Day1', timestampdate);
    const currenttime = new Date(timestampdate);
    console.log('Work Day2', currenttime);
    this.todayDate =
      currenttime.getFullYear() +
      '-' +
      ('0' + (currenttime.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + currenttime.getDate()).slice(-2) +
      'T' +
      ('0' + currenttime.getHours()).slice(-2) +
      ':' +
      ('0' + currenttime.getMinutes()).slice(-2);
    console.log('WorkDate is', this.todayDate);

    this.minWindowStart = this.todayDate;
    this.firstDay = new Date().getFullYear() + '-01-01T00:00';
    const nextDayDate = moment(currenttime).add(1, 'days');
    const frmDate =
      moment(nextDayDate).year() +
      '-' +
      ('0' + (moment(nextDayDate).month() + 1)).slice(-2) +
      '-' +
      ('0' + moment(nextDayDate).date()).slice(-2) +
      'T' +
      ('0' + currenttime.getHours()).slice(-2) +
      ':' +
      ('0' + currenttime.getMinutes()).slice(-2);
    this.nextDay = frmDate;

    console.log('this.fromDate', frmDate);
    console.log('nextDay workDate is', this.nextDay);
    this.firstDay = new Date().getFullYear() + '-01-01T00:00';
  }

  /**
   * While user click back button and save the data into IndexDB.
   * @memberof TrackUtilizationComponent
   */
  protected backButtonNavigate(): void {
    if (this.viewMode) {
      this.routing();
    } else {
      this.pageNav = 'back';
      this.saveToInMemoryDB(this.pageNav);
      if (this.workType === 'RA') {
        if (this.jobType !== 'CP') {
          this.navigateToBackPage('road_crossing');
        } else {
          this.navigateToBackPage('planned_actual');
        }
      } else {
        if (this.workType === WorkType.SWITCHTIE) {
          this.navigateToBackPage('road_crossing');
        } else {
          this.navigateToBackPage('turnout');
        }
      }
    }
  }


  protected navigateToBackPage(navigateTo: string) {
    this.router.navigate([`/${navigateTo}/${this.projectId}/${this.jobId}/${this.formId}`]);
  }
  closebackButtonNavigate(): void {
    if (this.viewMode) {
      // this.saveToInMemoryDB(this.pageNav);
      this.router.navigate([`/job_view/${this.projectData}`]);
    }
  }

  routing() {
    if (this.jobType === 'CP') {
      this.router.navigate([`/planned_actual/${this.projectId}/${this.jobId}/${this.formId}`]);
    } else if (this.workType === WorkType.TIEANDSURFACE || this.workType === WorkType.SURFACE) {
      this.router.navigate([`/turnout/${this.projectId}/${this.jobId}/${this.formId}`]);
    } else {
      this.router.navigate([`/road_crossing/${this.projectId}/${this.jobId}/${this.formId}`]);
    }
  }

  /**
   * While user click back 'ICON' from Page header and save the data into IndexDB.
   * @param {any} event
   * @memberof TrackUtilizationComponent
   */
  protected backIconNavigate(event): void {
    if (this.reportData.status === 'S' || this.formId > 0) {
      this.router.navigate([`/job_view/${this.projectData}`]);
    } else {
      this.pageNav = 'backIcon';
      this.saveToInMemoryDB(this.pageNav);
    }
  }

  /**
   * Method 'saveToInMemoryDB' will save the Data in to Index DB
   * while user click "back", "back Icon" and "Next" Button
   * And WorkDay setup for timeOnly field  and etc.
   * @private
   * @param {string} pageNav
   * @memberof TrackUtilizationComponent
   **/
  private saveToInMemoryDB(pageNav: string): void {
    // Build Setter process to save data into IndexDB.
    this.setReportData(this.reportData);
    this.reportData.reportWindows = this.formGroup.controls['reportWindows'].value as FormArray;
    this.saveAction(pageNav, this.reportData);
  }

  /**
   * @memberof TrackUtilizationComponent
   */
  setReportData(reportData: any): void {
    reportData.delayIssuesRemark = this.formGroup.get('delayIssuesRemark').value;
    reportData.issuesDesc = this.formGroup.get('issuesDesc').value;
    reportData.machineFailureSeq = this.formGroup.get('machineFailureSeq').value;
    reportData.transOfficerAval = this.formGroup.get('transOfficerAval').value;
    reportData.transOfficerName = this.formGroup.get('transOfficerName').value;
    reportData.trackReturnLate = this.formGroup.get('trackReturnLate').value;
    reportData.trackReturnLateRemarks = this.formGroup.get('trackReturnLateRemarks').value;
    const passedCount = this.formGroup.get('trainsPassedCount').value;
    reportData.trainsPassedCount = passedCount;
    reportData.workAtYard = this.formGroup.get('workAtYard').value;
    reportData.yardName = this.formGroup.get('yardName').value;
    reportData.actualEmployeesCount = Number(this.formGroup.get('actualEmployeesCount').value);
    reportData.fullBlockReceived = this.formGroup.get('fullBlockReceived').value;
    reportData.tieUpLocation = this.formGroup.get('tieUpLocation').value;
    reportData.nextDayLocation = this.formGroup.get('nextDayLocation').value;
    this.setReportWindows(reportData);
  }

  /**
   * @memberof TrackUtilizationComponent
   */
  setReportWindows(reportData: any): void {
    reportData.reportWindows = this.formGroup.controls['reportWindows'] as FormArray;
    reportData.reportWindows.controls.forEach((formGroup: FormGroup, i: any) => {
      formGroup.controls['windowStart'].patchValue(Date.parse(formGroup.controls['windowStarts'].value));
      formGroup.controls['windowEnd'].patchValue(Date.parse(formGroup.controls['windowEnds'].value));
    });
  }

  /**
   * @param {string} pageNav
   * @memberof TrackUtilizationComponent
   */
  saveAction(pageNav: string, reportData: any): void {
    console.log('Report Data is 1', reportData);
    if (pageNav === 'submit') {
      if (!this.isPastDayReport() || !this.reportExistsAfterWorkDay()) {
        reportData = this.utilService.updateProjectPlannedValues(reportData, this.projectId);
      }
      if (this.jobType === 'TS') {
        delete reportData.tieTrack;
      }
      console.log('Report Data is 2', JSON.stringify(reportData));
      this.saveReportToDb(reportData);
      this.reportData = reportData;
      this.parentSubject.next('form valid');
    } else if (pageNav === 'back') {
      console.log('Back Button ', reportData);
      this.saveReportToIndexDbMethod(reportData)
        .then(response => {
          console.log(response);
          if (this.jobType === 'CP') {
            this.navigateToBackPage('planned_actual');
          } else if (this.workType === WorkType.TIEANDSURFACE || this.workType === WorkType.SURFACE) {
            this.navigateToBackPage('turnout');
          } else {
            this.navigateToBackPage('road_crossing');
          }
        })
        .catch(reason => {
          console.log(reason);
        });
    } else if (pageNav === 'backIcon') {
      this.dataService
        .saveDraftReport(JSON.stringify(reportData))
        .then(response => {
          console.log('road', response);
          this.router.navigate([`/job_view/${this.projectId}`]);
        })
        .catch(reason => {
          console.log(reason);
        });
    }
  }

  protected saveReportToDb(reportData: any) {
    this.dataService
      .submitReport(JSON.stringify(reportData))
      .then(response => {
        console.log(response);
      })
      .catch(reason => {
        console.log(reason);
      });
  }

  protected saveReportToIndexDbMethod(reportData: any) {
    return this.dataService.saveDraftReport(JSON.stringify(reportData));
  }

  isPastDayReport() {
    const yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    yesterdayDate.setHours(23, 59, 59, 999);
    return this.reportData.workday <= yesterdayDate.getTime();
  }

  private reportExistsAfterWorkDay(): boolean {
    let futureDayReportExist = false;
    this.projectReports.forEach(report => {
      if (report.teamName === this.teamName && report.status !== 'D' && report.formId.toString() !== this.formId.toString()) {
        if (
          this.convertEpochToDate(report.workday) !== this.convertEpochToDate(this.reportData.workday) &&
          report.workday > this.reportData.workday
        ) {
          futureDayReportExist = true;
          return;
        }
      }
    });
    console.log('future report exist : ' + futureDayReportExist);
    return futureDayReportExist;
  }

  private convertEpochToDate(epoch: number) {
    if (epoch == null) {
      return null;
    }
    let date = new Date(epoch).toLocaleDateString().replace(/\//g, '-');
    const dateArray = date.split('-');
    date = dateArray[2] + '-' + this.convertSingleDigitToDouble(dateArray[0]) + '-' + this.convertSingleDigitToDouble(dateArray[1]);
    return date;
  }

  private convertSingleDigitToDouble(value: string): string {
    if (value.length === 1) {
      return '0' + value;
    } else {
      return value;
    }
  }

  /**
   * First time user not enter any value for field plannedStart, this validation occur
   * @memberof TrackUtilizationComponent
   */
  validateStartTime() {
    const plannedStartTime = this.formGroup.controls['plannedStart'].value;
    const plannedStartMax = new Date(this.reportData.workday + (24 * 60 * 60 - 60) * 1000);
    if (new Date(plannedStartTime).getTime() > plannedStartMax.getTime()) {
      this.formGroup.controls['plannedStart'].setErrors({ max: true });
    }
    this.plannedStartMin = new Date(this.reportData.workday);
    this.checkOnDutyPlannedStartTime = false;
    if (plannedStartTime) {
      this.reportData.plannedStart = new Date(plannedStartTime).getTime();
      this.validateEndTime();
      if (new Date(plannedStartTime).getTime() < new Date(this.plannedStartMin).getTime()) {
        this.checkOnDutyPlannedStartTime = true;
      } else {
        this.checkOnDutyPlannedStartTime = false;
      }
    }
  }

  /**
   * Plan Start Time Roll Over logic for next day
   * @param {boolean} isRollOver
   * @param {*} updatePlannedStartTime
   * @param {*} nextPlannedStartDateTime
   * @param {boolean} isMoreThan18Hours
   * @returns {*}
   * @memberof TrackUtilizationComponent
   */

  /**
   * First time user not enter any value for field plannedEnd, this validation occur
   * @memberof TrackUtilizationComponent
   */
  validateEndTime() {
    const plannedEndTime = this.formGroup.controls['plannedEnd'].value;
    const plannedStartTime = this.formGroup.controls['plannedStart'].value;
    this.plannedEndMax = new Date(new Date(plannedStartTime).getTime() + 14 * 60 * 60 * 1000);
    this.plannedEndInvalid = false;
    if (plannedStartTime === null) {
      this.plannedEndInvalid = true;
      this.plannedEndError = 'Enter Planned start time.';
    } else {
      if (plannedEndTime) {
        this.reportData.plannedEnd = new Date(plannedEndTime).getTime();
        if (plannedEndTime <= plannedStartTime) {
          this.plannedEndInvalid = true;
          this.plannedEndError = 'Must be > Planned Start Time.';
          // } else if (this.getDateTimeDifference(this.plannedEnd, this.plannedStart) >= 14) {
        } else if (new Date(plannedEndTime).getTime() >= new Date(this.plannedEndMax).getTime()) {
          this.plannedEndInvalid = true;
          this.plannedEndError = 'Planned window time should be < 14 hours.';
        } else {
          this.plannedEndInvalid = false;
        }
      }
    }
  }

  /**
   *  Plan End time Roll Over logic for next day
   * @param {boolean} isRollOver
   * @param {*} updatePlannedEndTime
   * @param {*} nextPlannedEndDateTime
   * @param {boolean} isMoreThan18Hours
   * @returns {*}
   * @memberof TrackUtilizationComponent
   */
  plannedEndRollOver(isRollOver: boolean, updatePlannedEndTime: any, nextPlannedEndDateTime: any, isMoreThan18Hours: boolean): any {
    if (!isRollOver && !moment(new Date(this.utilService.getWorkDay(this.reportData))).isAfter(updatePlannedEndTime)) {
      this.formGroup.controls['plannedEnd'].patchValue(moment(updatePlannedEndTime).format('HH:mm'));
      console.log('plannedEnd - WorkDay Date', updatePlannedEndTime);
      return moment(updatePlannedEndTime).valueOf();
    } else if (moment(new Date(this.utilService.getWorkDay(this.reportData))).isAfter(updatePlannedEndTime)) {
      if (isMoreThan18Hours) {
        this.formGroup.controls['plannedEnd'].patchValue(moment(nextPlannedEndDateTime).format('HH:mm'));
        console.log('plannedEnd - NextDay ', nextPlannedEndDateTime);
        return moment(nextPlannedEndDateTime).valueOf();
      } else {
        console.log('plannedEnd > Duty time - work day');
        this.formGroup.controls['plannedEnd'].patchValue(moment(updatePlannedEndTime).format('HH:mm'));
        return moment(updatePlannedEndTime).valueOf();
      }
    } else {
      this.formGroup.controls['plannedEnd'].patchValue(moment(updatePlannedEndTime).format('HH:mm'));
      return moment(updatePlannedEndTime).valueOf();
    }
  }

  /**
   * Toggle the field Work at Yard
   *  @param event
   **/
  toogleWorkAtYard(event: any): void {
    if (event.option.value !== 'N') {
      this.showYardName = true;
    } else {
      this.showYardName = false;
    }
  }

  /**
   * Toggle the field Machine at Failure
   *  @param event
   **/
  toogleMachineFailure(event: any): void {
    if (event.option.value !== 'N') {
      this.showMachineFailure = true;
    } else {
      this.showMachineFailure = false;
    }
  }

  /**
   * Toggle the field Transportation Officier on Site?
   * @param event
   **/
  toogleNameOfOfficerFields(event: any): void {
    if (event.option.value !== 'N') {
      this.showNameOfOfficer = true;
    } else {
      this.showNameOfOfficer = false;
    }
  }

  /**
   * Validate the field comments by 200 max length
   * @param {any} text
   * @memberof TrackUtilizationComponent
   */
  CommentsCharCount(text): void {
    this.commentLength = 200 - text.length;
    if (this.commentLength < 0) {
      this.showCommentOutLimitError = true;
    } else {
      this.showCommentOutLimitError = false;
    }
  }

  /**
   *Validate the field Work Description by 200 max length
   * @param {any} text
   * @memberof TrackUtilizationComponent
   */
  workDescriptionCharCount(text): void {
    this.workDescriptionLength = 200 - text.length;
    if (this.workDescriptionLength < 0) {
      this.workDescriptionTextOutLimitError = true;
    } else {
      this.workDescriptionTextOutLimitError = false;
    }
  }

  /**
   * Validate the field Delay Issues by 200 max length
   * @param {any} text
   * @memberof TrackUtilizationComponent
   */
  DelayIssuesCharCount(text): void {
    this.DelayIssuesLength = 200 - text.length;
    if (this.DelayIssuesLength < 0) {
      this.delayIssuesTextOutLimitError = true;
    } else {
      this.delayIssuesTextOutLimitError = false;
    }
  }

  /**
   * First time user not enter any value for field Comments, this validation occur
   * @param {*} event
   * @memberof TrackUtilizationComponent
   */
  validatefieldComments(event: any): void {
    if (this.formGroup.get('trackReturnLate').value === 'N') {
      this.commentsError = {
        isCommentsError: true,
        commentsErrorMessage: TrackUtilization.COMMENTS_REQUIRED
      };
    } else {
      this.commentsError = {
        isCommentsError: false,
        commentsErrorMessage: TrackUtilization.COMMENTS_REQUIRED
      };
    }
  }

  /**
   *  Validate Green signal time
   * @memberof TrackUtilizationComponent
   */
  validateGreenSignalTime() {
    const greenSignalT = this.formGroup.controls['greenSignalTime'].value;
    if (!isNaN(greenSignalT)) {
      console.log('Green Signal Time is 1', this.reportData.greenSignalTime);
    } else {
      this.reportData.greenSignalTime = new Date(this.formGroup.controls['greenSignalTime'].value).getTime();
      console.log('Green Signal Time is 2', this.reportData.greenSignalTime);
    }
  }

  /**
   *  Roll Over logic for next day green singnal time.
   * @param {boolean} isRollOver
   * @param {*} updateGreenSignalTime
   * @param {*} nextGreenSignalTime
   * @param {boolean} isMoreThan18Hours
   * @returns {*}
   * @memberof TrackUtilizationComponent
   */
  rollOverGreenSignalTime(isRollOver: boolean, updateGreenSignalTime: any, nextGreenSignalTime: any, isMoreThan18Hours: boolean): any {
    if (moment(new Date(this.utilService.getWorkDay(this.reportData))).isAfter(updateGreenSignalTime)) {
      if (isMoreThan18Hours) {
        this.formGroup.controls['greenSignalTime'].patchValue(moment(nextGreenSignalTime).format('HH:mm'));
        console.log('GreenSignalTime - NextDay ', nextGreenSignalTime);
        return moment(nextGreenSignalTime).valueOf();
      } else {
        console.log('updateGreenSignalTime > Duty time - work day');
        this.formGroup.controls['greenSignalTime'].patchValue(moment(updateGreenSignalTime).format('HH:mm'));
        return moment(updateGreenSignalTime).valueOf();
      }
    } else {
      this.formGroup.controls['greenSignalTime'].patchValue(moment(updateGreenSignalTime).format('HH:mm'));
      return moment(updateGreenSignalTime).valueOf();
    }
  }

  validateTrainPassCount() {
    if (this.formGroup.controls['trainsPassedCount'].value && this.formGroup.controls['trainsPassedCount'].value % 1 !== 0) {
      this.trainPassCountError = true;
    } else {
      this.trainPassCountError = false;
    }
  }

  validateTrackReceived(index) {
    let trackReceivedTime = this.formGroup.controls.reportWindows.controls[index].controls['windowStarts'].value;
    if (new Date(trackReceivedTime).getTime() >= new Date(this.nextDay).getTime()) {
      this.trackReceivedFlag[index] = true;
      this.trackReceivedError[index] = 'Should be < 24 hrs from On Duty Time.';
    } else if (new Date(trackReceivedTime).getTime() < new Date(this.todayDate).getTime()) {
      this.trackReceivedFlag[index] = true;
      this.trackReceivedError[index] = 'Should be >= On-Duty  Date-Time.';
    } else if (new Date(trackReceivedTime).getTime() > new Date(this.systemTime).getTime()) {
      this.trackReceivedFlag[index] = true;
      this.trackReceivedError[index] = 'Should not be > Current Time.';
    } else {
      this.trackReceivedFlag[index] = false;
    }
  }

  validateTrackCleared(index) {
    let trackClearedTime = this.formGroup.controls.reportWindows.controls[index].controls['windowEnds'].value;
    let trackRecivedTime = this.formGroup.controls.reportWindows.controls[index].controls['windowStarts'].value;
    if (new Date(trackClearedTime).getTime() >= new Date(this.nextDay).getTime()) {
      this.trackClearedFlag[index] = true;
      this.trackClearedError[index] = 'Should be < 24 hrs from On Duty Time.';
    } else if (new Date(trackClearedTime).getTime() > new Date(this.systemTime).getTime()) {
      this.trackClearedFlag[index] = true;
      this.trackClearedError[index] = 'Should not be > Current Time.';
    } else if (new Date(trackClearedTime).getTime() < new Date(this.todayDate).getTime()) {
      this.trackClearedFlag[index] = true;
      this.trackClearedError[index] = 'Should be >= On-Duty  Date-Time.';
    } else if (new Date(trackClearedTime).getTime() <= new Date(trackRecivedTime).getTime()) {
      this.trackClearedFlag[index] = true;
      this.trackClearedError[index] = 'Should be > Track Received Date-Time.';
    } else {
      this.trackClearedFlag[index] = false;
    }
  }

  /**
   * @param {*} event
   * @memberof TrackUtilizationComponent
   */
  validateDelayTime(event: any) {
    this.showInvalidDelayTimeError = false;
    let delayT = this.formGroup.controls['delayTime'].value;
    if (!delayT.match(this.timeOnlyPattern)) {
      this.showInvalidDelayTimeError = true;
    } else {
      this.showInvalidDelayTimeError = false;
    }
    let str = delayT.split(':');
    this.reportData.delayTime = str[0] + str[1];
    console.log('setDelayTime', this.reportData.delayTime);
  }

  /**
   * Add new track window  and validate the window.
   * @memberof TrackUtilizationComponent
   */
  addTrackUtilWindow(): void {
    this.addWindowError = false;
    this.validateCutRailLengthCount('addwindow');
    this.formSubmitAttempt = false;
    this.reportData.reportWindows = this.formGroup.get('reportWindows') as FormArray;
    console.log('reportWindows' + this.reportData.reportWindows.value);
    this.trackWindowValid = true;
    this.validateTrackWindow();
    this.validateSequence();

    this.reportData.reportWindows.controls.forEach((formGroup: FormGroup, i: any) => {
      if (
        i === 0 &&
        this.reportData.reportWindows.length === 1 &&
        formGroup.controls['unitsInstalled'].valueChanges &&
        formGroup.controls['unitsInstalled'].value === this.reportData.totalUnitInstalled
      ) {
        formGroup.controls['unitsInstalled'].patchValue('');
        formGroup.controls['unitPerHour'].patchValue('');
      }
    });
    if (this.trackWindowValid && this.validCount === 0) {
      this.minWindowStart = this.minDate;
      this.reportData.reportWindows.push(this.trackUtilWindowGroup());
    } else {
      this.addWindowError = true;
    }
  }

  /** Validate Track window OnDuty Time, Track Received / Track Cleared window.
   * @memberof TrackUtilizationComponent
   */
  protected validateTrackWindow() {
    this.setTrackWindowDate(this.reportData);
    this.validateWindow();
  }

  protected validateWindow() {
    this.validCount = 0;
    this.reportData.reportWindows.controls.forEach((formGroup: FormGroup, i: any) => {
      const iWindowStart = formGroup.controls['windowStarts'].value;
      const iWindowEnd = formGroup.controls['windowEnds'].value;
      this.minDate = iWindowEnd;
      if ((iWindowEnd === undefined || iWindowEnd === null)
        || iWindowStart === undefined || iWindowStart === null) {
        this.trackWindowValid = false;
        this.trackWindowValidIndex[i] = false;
      } else if (
        iWindowStart &&
        iWindowEnd &&
        iWindowStart < iWindowEnd &&
        iWindowStart >= this.todayDate &&
        iWindowStart < this.nextDay &&
        iWindowEnd > this.todayDate &&
        iWindowEnd <= this.nextDay &&
        iWindowEnd > iWindowStart
      ) {
        this.trackWindowValid = true;
        this.trackWindowValidIndex[i] = true;
      } else {
        this.validCount++;
        this.trackWindowValid = false;
        this.trackWindowValidIndex[i] = false;
      }
    });
  }

  /**
   *  To remove track util window based on Index
   * @param {*} index
   * @memberof TrackUtilizationComponent
   */
  removeAddWindow(index: any): void {
    this.addWindowError = false;
    console.log('Remove Add Window Index: ' + index);
    this.reportData.reportWindows = this.formGroup.get('reportWindows') as FormArray;
    this.reportData.reportWindows.removeAt(index);
    this.validateTrackWindow();
    this.validateSequence();
  }

  /**
   * Dynamically calculate unitsInstalled and UnitPerHour based on Track util fields,
   * And set values for windowStarts, windowEnds and  WindowNumber fields.
   * @param {*} index
   * @param {string} text.
   * @memberof TrackUtilizationComponent
   */
  protected convertTrackReceived(index: any, text: string): void {
    this.formSubmitAttempt = false;
    this.addWindowError = false;
    this.trackWindow(index, text);
    this.validateTrackReceived(index);
    this.validateTrackWindow();
  }

  /**
   * Dynamically calculate unitsInstalled and UnitPerHour based on Track util fields,
   * And set values for windowStarts, windowEnds and  WindowNumber fields.
   * @param {*} index
   * @param {string} text
   * @memberof TrackUtilizationComponent
   */
  protected convertTrackCleared(index: any, text: string): void {
    this.formSubmitAttempt = false;
    this.addWindowError = false;
    this.trackWindow(index, text);
    this.validateTrackCleared(index);
    this.validateTrackWindow();
  }

  /**  **/
  /**
   * Dynamically calculate unitsInstalled and UnitPerHour based on Track util fields,
   * And set values for windowStarts, windowEnds and  WindowNumber fields
   * @param {*} index
   * @param {string} text
   * @memberof TrackUtilizationComponent
   */
  changeUnitsInstalled(index: any, text: string): void {
    this.trackWindow(index, text);
    this.validateCutRailLengthCount('change');
  }

  /**
   * Dynamically calculate unitsInstalled and UnitPerHour based on Track util fields,
   * And set values for windowStarts, windowEnds and  WindowNumber fields.
   * @param {*} index
   * @param {string} text
   * @memberof TrackUtilizationComponent
   */
  calculateUnitPerHour(index: any, text: string): void {
    this.trackWindow(index, text);
  }

  /**
   * Dynamically calculate unitsInstalled and UnitPerHour based on Track util fields,
   * And set values for windowStarts, windowEnds and  WindowNumber fields.
   * @param {any} index
   * @param {any} text
   * @memberof TrackUtilizationComponent
   */
  protected trackWindow(index, text): void {
    this.reportData.reportWindows = this.formGroup.controls['reportWindows'] as FormArray;
    this.reportData.reportWindows.controls.forEach((formGroup: FormGroup, i: any) => {
      let windowNum = i + 1;
      let unitInstall = formGroup.controls['unitsInstalled'].value;
      let windowStarts = formGroup.controls['windowStarts'].value;
      let windowEnds = formGroup.controls['windowEnds'].value;
      console.log('Window start', windowStarts);
      console.log('Window end', windowEnds);
      // On Duty Date and Time Check.
      if (
        windowStarts < this.todayDate ||
        windowEnds < this.todayDate ||
        windowStarts > this.getCurrentTime() ||
        windowEnds > this.getCurrentTime()
      ) {
        this.formGroup.markAsPending(true);
      }
      formGroup.controls['windowNumber'].patchValue(windowNum);
      if (windowStarts !== null && windowStarts !== '' && windowEnds !== null && windowEnds !== '' && unitInstall !== null) {
        let units = unitInstall / ((Date.parse(windowEnds) - Date.parse(windowStarts)) / (1000 * 60 * 60));
        console.log('==== UNIT PER HOURS =====> ' + units);
        if (units || units === 0) {
          formGroup.controls['unitPerHour'].patchValue(units.toFixed(2));
        }
      } else {
        formGroup.controls['unitPerHour'].patchValue('');
      }
      if (formGroup.controls['unitPerHour'].value === 'Infinity' || Math.sign(formGroup.controls['unitPerHour'].value) === -1) {
        formGroup.controls['unitPerHour'].patchValue(0);
        this.formGroup.markAsPending(true);
      }
    });
  }

  /**
   * @returns {*}
   * @memberof TrackUtilizationComponent
   */
  getOnDutyDateAndTime(): any {
    let timestampdate = new Date(this.utilService.getWorkDay(this.reportData)).toISOString();
    console.log('Work Day1', timestampdate);
    let currenttime = new Date(timestampdate);
    console.log('Work Day2', currenttime);
    this.todayDate =
      currenttime.getFullYear() +
      '-' +
      ('0' + (currenttime.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + currenttime.getDate()).slice(-2) +
      'T' +
      ('0' + currenttime.getHours()).slice(-2) +
      ':' +
      ('0' + currenttime.getMinutes()).slice(-2);
    console.log('WorkDate is', this.todayDate);
  }

  /** User dynamically change the fields values then corresponding validation occurs **/
  onChanges() {
    this.reportData.reportWindows = this.formGroup.controls['reportWindows'] as FormArray;
    this.formGroup.get('trackReturnLate').valueChanges.subscribe(input => {
      if (
        this.formGroup.get('trackReturnLate').value === 'undefined' ||
        this.formGroup.get('trackReturnLate').value === '' ||
        this.formGroup.get('trackReturnLate').value === null ||
        (this.formGroup.get('trackReturnLate').untouched && this.formSubmitAttempt) ||
        (this.formGroup.get('trackReturnLate').touched && this.formGroup.get('trackReturnLate') === null) ||
        (!this.formGroup.get('trackReturnLate').valid && this.formGroup.get('trackReturnLate').touched) ||
        (this.formGroup.get('trackReturnLate') === null && this.formSubmitAttempt) ||
        (this.formGroup.get('trackReturnLate').touched && this.formGroup.get('trackReturnLate').value === '')
      ) {
        this.trackedreturnOntimeError = {
          isTrackedreturnOntimeError: true,
          trackedreturnOntimeErrorMessage: TrackUtilization.SELECT_TRACKED_RETURN_ONTIME
        };
      } else {
        this.trackedreturnOntimeError = {
          isTrackedreturnOntimeError: false,
          trackedreturnOntimeErrorMessage: TrackUtilization.SELECT_TRACKED_RETURN_ONTIME
        };
      }
    });
    this.formGroup.get('transOfficerName').valueChanges.subscribe(input => {
      if (
        this.formGroup.get('transOfficerName').value === 'undefined' ||
        this.formGroup.get('transOfficerName').value === '' ||
        this.formGroup.get('transOfficerName').value === null ||
        (this.formGroup.get('transOfficerName').untouched && this.formSubmitAttempt) ||
        (this.formGroup.get('transOfficerName').touched && this.formGroup.get('transOfficerAval') === null) ||
        (!this.formGroup.get('transOfficerName').valid && this.formGroup.get('transOfficerAval').touched) ||
        (this.formGroup.get('transOfficerName') === null && this.formSubmitAttempt) ||
        (this.formGroup.get('transOfficerName').touched && this.formGroup.get('transOfficerName').value === '')
      ) {
        this.nameOfOfficerError = {
          isNameOfOfficerError: true,
          nameOfOfficerErrorMessage: TrackUtilization.ENTER_NAME_OF_OFFICER
        };
      } else {
        this.nameOfOfficerError = {
          isNameOfOfficerError: false,
          nameOfOfficerErrorMessage: TrackUtilization.ENTER_NAME_OF_OFFICER
        };
      }
    });
  }

  /**
   * Track util winodw should be sequencial order
   * And Window overlap not allowed based on fields
   * Track Received / Track Cleared.
   * @memberof TrackUtilizationComponent
   */
  validateSequence() {
    let numOfWindow = this.reportData.reportWindows.length;
    this.reportData.reportWindows.controls.forEach((formGroup: FormGroup, i: any) => {
      let iWindowStart = formGroup.controls['windowStarts'].value;
      let iWindowEnd = formGroup.controls['windowEnds'].value;

      this.reportData.reportWindows.controls.forEach((formGroup2: FormGroup, j: any) => {
        let jWindowStart = formGroup2.controls['windowStarts'].value;
        let jWindowEnd = formGroup2.controls['windowEnds'].value;
        // Window Sequence
        if (numOfWindow > 1 && j === i + 1) {
          if (jWindowStart <= iWindowStart && jWindowStart < iWindowEnd) {
            this.isValidationError = true;
            this.formGroup.markAsPending(true);
            this.validCount++;
          } else {
            this.isValidationError = false;
          }
          if (jWindowEnd < iWindowStart && jWindowEnd < iWindowEnd) {
            this.isValidationError = true;
            this.formGroup.markAsPending(true);
            this.validCount++;
          } else {
            this.isValidationError = false;
          }
        }
        // Window Overlap
        if (i !== j) {
          if (jWindowStart > iWindowStart && jWindowStart < iWindowEnd) {
            this.isValidationError = true;
            this.formGroup.markAsPending(true);
            this.validCount++;
          } else {
            this.isValidationError = false;
          }
          if (jWindowEnd >= iWindowStart && jWindowEnd < iWindowEnd) {
            this.isValidationError = true;
            this.formGroup.markAsPending(true);
            this.validCount++;
          } else {
            this.isValidationError = false;
          }
        }
      });
    });
  }

  /**
   * Form Submit(Next Button) validations for Track Utilization.
   **/
  protected formSubmit(event: any): void {
    const plannedStartTime = this.formGroup.controls['plannedStart'].value;
    const plannedEndTime = this.formGroup.controls['plannedEnd'].value;
    /*Next Button Validation for transOfficerName  */
    if (
      (!this.formGroup.get('transOfficerName').valid && this.formGroup.get('transOfficerName').touched) ||
      (this.formGroup.get('transOfficerName').untouched && this.formSubmitAttempt)
    ) {
      this.nameOfOfficerError = {
        isNameOfOfficerError: true,
        nameOfOfficerErrorMessage: TrackUtilization.ENTER_NAME_OF_OFFICER
      };
    }
    /** The field nameOfYard  Mandatory if user select 'workAtYard' as Yes **/
    this.validateNameOfYard = this.cv
      .validate(this.formGroup, 'yardName')
      .using(Validators.required)
      .when('workAtYard')
      .is('Y');
    /** The field Name of Officer  Mandatory if user select 'transOfficerAval' as Yes **/
    this.validateNameOfOfficer = this.cv
      .validate(this.formGroup, 'transOfficerName')
      .using(Validators.required)
      .when('transOfficerAval')
      .is('Y');
    /** The field comments  Mandatory if user select 'trackedreturnOntime' as Yes **/
    this.validateComments = this.cv
      .validate(this.formGroup, 'trackReturnLateRemarks')
      .using(Validators.required)
      .when('trackReturnLate')
      .is('N');
    /*Next Button Validation for trackedreturnOntime  */
    if (
      (!this.formGroup.get('trackReturnLate').valid && this.formGroup.get('trackReturnLate').touched) ||
      (this.formGroup.get('trackReturnLate').untouched && this.formSubmitAttempt)
    ) {
      this.trackedreturnOntimeError = {
        isTrackedreturnOntimeError: true,
        trackedreturnOntimeErrorMessage: TrackUtilization.TRACKED_ON_TIME_REQUIRED
      };
    }
    this.formSubmitAttempt = true;
    console.log('Form submitted: ', this.formGroup.value);
    console.log('Form Submitted JSON' + JSON.stringify(this.formGroup.value));
    this.reportData.reportWindows = this.formGroup.controls['reportWindows'] as FormArray;
    if (plannedStartTime !== null) {
      this.validateStartTime();
    }
    if (plannedEndTime !== null) {
      this.validateEndTime();
    }
    this.validateCutRailLengthCount('submit');
    this.validateSequence();
    this.validateTrackWindow();
    const trainPassCount = this.formGroup.controls['trainsPassedCount'].value;
    if (trainPassCount) {
      this.validateTrainPassCount();
    }
    console.log('this.isPlannedStartInvalidTime', this.isPlannedStartInvalidTime);
    console.log('this.isPlannedEndInvalidTime', this.isPlannedEndInvalidTime);
    console.log('this.checkOnDutyPlannedStartTime', this.checkOnDutyPlannedStartTime);
    console.log('this.checkOnDutyPlannedEndTime', this.exceedPlannedWindowTime);
    console.log('this.exceedPlannedEndTime', this.exceedPlannedEndTime);
    console.log('this.isUnitsNotMatchWithCutRailLength', this.isUnitsNotMatchWithCutRailLength);
    console.log('this.trackWindowValid', this.trackWindowValid);
    console.log('this.futureGreenSignalError', this.futureGreenSignalError);
    if (
      this.isPlannedStartInvalidTime ||
      this.isPlannedEndInvalidTime ||
      this.checkOnDutyPlannedStartTime ||
      this.checkOnDutyPlannedEndTime ||
      this.exceedPlannedWindowTime ||
      this.plannedEndInvalid ||
      (this.isUnitsNotMatchWithCutRailLength && !this.surfacingOnlyFlag) ||
      !this.trackWindowValid ||
      this.validCount > 0 ||
      this.futureGreenSignalError ||
      this.trainPassCountError
    ) {
      this.isValidationError = true;
      this.formGroup.markAsPending(true);
    } else {
      this.isValidationError = false;
    }
    if (this.formGroup.valid) {
      console.log('Form is valid');
      this.isValidationError = false;
      this.pageNav = 'submit';
      this.saveToInMemoryDB(this.pageNav);

    } else {
      console.log('Form is invalid');
      this.isValidationError = true;
    }
  }

  /* Form Submit end  */

  /**
   *  Plan start < On Duty Date and Time.
   * @memberof TrackUtilizationComponent
   */
  checkPlannedStartTime(): void {
    if (this.inputPlannedStartTime < new Date(this.reportData.workday)) {
      this.checkOnDutyPlannedStartTime = true;
    } else {
      this.checkOnDutyPlannedStartTime = false;
    }
  }

  /**
   * Plan start < On Duty Date and Time.
   * @memberof TrackUtilizationComponent
   */
  checkOnDutyTime(): void {
    if (this.inputPlannedEndTime <= new Date(this.reportData.workday)) {
      this.checkOnDutyPlannedEndTime = true;
    } else {
      this.checkOnDutyPlannedEndTime = false;
    }
  }

  /**
   * Plan start / end time difference
   * @memberof TrackUtilizationComponent
   */
  checkPlannedWindowTime(): void {
    const isMorethan14Hours = this.utilService.timeDifferenceGreaterThan14Hours(
      moment(this.reportData.plannedEnd),
      this.inputPlannedStartTime
    );
    if (isMorethan14Hours) {
      this.exceedPlannedWindowTime = true;
      console.log('planned start/end time difference is more than 14 hours');
    } else {
      this.exceedPlannedWindowTime = false;
    }
  }

  /**
   * Plan End < Plan Start.
   * @memberof TrackUtilizationComponent
   */
  validatePlannedEndTime(): void {
    if (this.inputPlannedEndTime <= this.inputPlannedStartTime) {
      this.plannedEndInvalid = true;
      console.log('planned end time <  Planned Start time');
    } else {
      this.plannedEndInvalid = false;
    }
  }

  /**
   *  Unit installed track util windows should match with Cut Rail length.
   * @memberof TrackUtilizationComponent
   */
  validateCutRailLengthCount(text: string) {
    this.reportWindows = <FormArray>this.formGroup.controls['reportWindows'];
    console.log('Total Units Installed ', this.reportData.totalUnitInstalled);
    if (this.reportData.jobType === 'SO') {
      let installedLength = 0;
      this.reportData.reportWindows.controls.forEach((formGroup: FormGroup, i: any) => {
        const units = formGroup.controls['unitsInstalled'].value;
        installedLength = installedLength + units;
        if (installedLength === this.surfacingSum) {
          this.isUnitsNotMatchWithCutRailLength = false;
        } else {
          if (text === 'submit' && !this.surfacingOnlyFlag) {
            this.isUnitsNotMatchWithCutRailLength = true;
          } else if (text === 'change') {
            this.isUnitsNotMatchWithCutRailLength = false;
          } else if (text === 'addwindow') {
            this.isUnitsNotMatchWithCutRailLength = false;
          }
        }
      });
    } else {
      let cutRailLength = 0;
      this.reportData.reportWindows.controls.forEach((formGroup: FormGroup, i: any) => {
        const units = formGroup.controls['unitsInstalled'].value;
        cutRailLength = cutRailLength + units;
        if (cutRailLength === this.reportData.totalUnitInstalled) {
          this.isUnitsNotMatchWithCutRailLength = false;
        } else {
          if (text === 'submit' && !this.surfacingOnlyFlag) {
            this.isUnitsNotMatchWithCutRailLength = true;
          } else if (text === 'change') {
            this.isUnitsNotMatchWithCutRailLength = false;
          } else if (text === 'addwindow') {
            this.isUnitsNotMatchWithCutRailLength = false;
          }
        }
      });
    }
  }

  validateStartEndTime() {
    this.validateStartTime();
    this.validateEndTime();
  }

  public onValueChange(): void {
    this.formSubmitAttempt = false;
  }

  /**
   * Current System time to check not allow future time for the report.
   * @returns {string}
   * @memberof TrackUtilizationComponent
   */
  public getCurrentTime(): string {
    const currentTime = new Date();
    const currentTimeDate = new Date(currentTime).toISOString();
    console.log('SystemTime 1', currentTimeDate);
    const timeNow = new Date(currentTimeDate);
    console.log('SystemTime 2', timeNow);
    this.systemTime =
      timeNow.getFullYear() +
      '-' +
      ('0' + (timeNow.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + timeNow.getDate()).slice(-2) +
      'T' +
      ('0' + timeNow.getHours()).slice(-2) +
      ':' +
      ('0' + timeNow.getMinutes()).slice(-2);
    console.log('SystemTime', this.systemTime);
    return this.systemTime;
  }

  protected fillTrackReceived(index: number, form?: FormGroup) {
    if (this.trackReceivedFirstClick[index] == null || this.trackReceivedFirstClick[index] === true) {
      const reportWindows: any = form ? form.get('reportWindows') : this.reportData.reportWindows;
      reportWindows.controls.forEach((formGroup: FormGroup, i: any) => {
        if (!formGroup.controls['windowStarts'].value && i === index) {
          const today = new Date();
          const onDutyTime = new Date(this.reportData.workday);
          if (
            onDutyTime.getFullYear() < today.getFullYear() ||
            onDutyTime.getMonth() < today.getMonth() ||
            (onDutyTime.getDay() < today.getDay() && onDutyTime.getMonth() === today.getMonth())
          ) {
            const onDutyTimeFormatted =
              onDutyTime.getFullYear() +
              '-' +
              ('0' + (onDutyTime.getMonth() + 1)).slice(-2) +
              '-' +
              ('0' + onDutyTime.getDate()).slice(-2) +
              'T' +
              ('0' + onDutyTime.getHours()).slice(-2) +
              ':' +
              ('0' + onDutyTime.getMinutes()).slice(-2);
            formGroup.controls['windowStarts'].patchValue(onDutyTimeFormatted);
          } else {
            const todayFormatted =
              today.getFullYear() +
              '-' +
              ('0' + (today.getMonth() + 1)).slice(-2) +
              '-' +
              ('0' + today.getDate()).slice(-2) +
              'T' +
              ('0' + today.getHours()).slice(-2) +
              ':' +
              ('0' + today.getMinutes()).slice(-2);
            formGroup.controls['windowStarts'].patchValue(todayFormatted);
          }
        }
      });
      this.trackReceivedFirstClick[index] = false;
    }
  }

  protected fillTrackCleared(index: number, form?: FormGroup) {
    if (this.trackClearedFirstClick[index] == null || this.trackClearedFirstClick[index] === true) {
      const reportWindows: any = form ? form.get('reportWindows') : this.reportData.reportWindows;
      reportWindows.controls.forEach((formGroup: FormGroup, i: any) => {
        if (!formGroup.controls['windowEnds'].value && i === index) {
          const today = new Date();
          const onDutyTime = new Date(this.reportData.workday);
          if (
            onDutyTime.getFullYear() < today.getFullYear() ||
            onDutyTime.getMonth() < today.getMonth() ||
            (onDutyTime.getDay() < today.getDay() && onDutyTime.getMonth() === today.getMonth())
          ) {
            const onDutyTimeFormatted =
              onDutyTime.getFullYear() +
              '-' +
              ('0' + (onDutyTime.getMonth() + 1)).slice(-2) +
              '-' +
              ('0' + onDutyTime.getDate()).slice(-2) +
              'T' +
              ('0' + onDutyTime.getHours()).slice(-2) +
              ':' +
              ('0' + onDutyTime.getMinutes()).slice(-2);
            formGroup.controls['windowEnds'].patchValue(onDutyTimeFormatted);
          } else {
            const todayFormatted =
              today.getFullYear() +
              '-' +
              ('0' + (today.getMonth() + 1)).slice(-2) +
              '-' +
              ('0' + today.getDate()).slice(-2) +
              'T' +
              ('0' + today.getHours()).slice(-2) +
              ':' +
              ('0' + today.getMinutes()).slice(-2);
            formGroup.controls['windowEnds'].patchValue(todayFormatted);
          }
        }
      });
      this.trackClearedFirstClick[index] = false;
    }
  }

  isFieldInvalid(field: string): boolean {
    return (
      this.formGroup.controls[field].invalid &&
      (this.formGroup.controls[field].touched || (this.formGroup.controls[field].untouched && this.formSubmitAttempt))
    );
  }
  calculateYardCharLength() {
    this.yardCharLength = 15 - this.formGroup.controls['yardName'].value.length;
    this.yardCharLength = this.yardCharLength > 0 ? this.yardCharLength : 0;
  }

  protected onDialogClose(event: boolean): void {
    this.showErrorComponent = false;
    this.errorDialogData = null;
  }

  onSyncComplete(isCompleted: boolean): void {
    if (isCompleted) {
      this.showSyncComponent = false;
      this.navigateToJobView();
    }
  }
  navigateToJobView(): any {
    this.router.navigate([`/job_view/${this.projectData}`]);
  }

  onSyncDialogClose(): void {
    this.showSyncComponent = false;
  }


  protected onReasonChange() {
  }
}
