import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { JobTable, LocalStorageService, ReportTable } from '../../services/local-storage.service';
import { SurfaceProgressComponent, SurfacingDetail } from '../surface-progress/surface-progress.component';
import { TieProgressComponent, TiesDetails } from '../tie-progress/tie-progress.component';
import { ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/primeng';
import { UtilService } from '../../util/util.service';
import { OperationMode } from '../../shared/enums/operation-mode.enum';

@Component({
  selector: 'app-ts-progress',
  templateUrl: './ts-progress.component.html',
  styleUrls: ['./ts-progress.component.scss']
})
export class TsProgressComponent implements OnInit, AfterViewInit {
  @ViewChild('mainTieLine') tieMainComponent: TieProgressComponent;
  @ViewChildren('sideTieLine') tieSideComponents: QueryList<TieProgressComponent>;
  @ViewChild('sideTieLine') tieSideComponent: TieProgressComponent;
  @ViewChild('mainSurfaceLine') surfaceMainComponent: SurfaceProgressComponent;
  @ViewChild('sideSurfaceLine') surfaceSideComponent: SurfaceProgressComponent;
  @ViewChildren('sideSurfaceLine') surfaceSideComponents: QueryList<SurfaceProgressComponent>;
  @Input() prepopulateData: any;
  @Input() operationMode: OperationMode;
  @Output() tsData: EventEmitter<any> = new EventEmitter<any>();
  @Output() tieTrackData: EventEmitter<any> = new EventEmitter<any>();
  directionList: SelectItem[];
  mainLineTie: object[] = [];
  sideLineTie: object[] = [];
  mainLIneSurface: object[] = [];
  sideLineSurface: object[] = [];
  tieProgress: {};
  surfaceProgress: {};
  tsProgressData: object = {};
  surfaceData: any[];
  tieData: any[];
  validateTie: any;
  workedOn: SelectItem[];
  direction: string;
  selectWorked: string;
  tieWorkedFlag: boolean;
  surfaceWorkedFlag: boolean;
  job: number;
  formId: number;
  jobData: object;
  trackName: string;
  tieTrack: any[];
  tieTrackPrevious: any[];
  sidingIndex: number;
  trackType: string;
  lowMp: number;
  reportStatus: boolean;
  highMp: number;
  mainTieValid: boolean;
  sideTieValid: boolean[] = [];
  mainSurfaceValid: boolean;
  sideSurfaceValid: boolean[] = [];
  prepopluateTiedata: object = {};
  prepopulateMainLineSurface: object[] = [];
  prepopulateSideLineSurface: object[] = [];
  prepopulateMainLineTie: TiesDetails[] = [];
  prepopulateSideLineTie: TiesDetails[] = [];
  previousSelectWorked = 'TS';
  previousSidingWorked = 'TS';
  previousDirection = 'I';
  previousSidingDirection = 'I';
  sidingFlag: boolean;
  tieComponent: any[] = [];
  surfaceComponent: any[] = [];
  previoustieTrack: any;
  check: boolean;
  milePost: number;
  reportLoad: boolean;
  previousReportMpRange = [];
  reportobject: any;
  previousTrackMp: any = [];

  constructor(private activeRoute: ActivatedRoute, public utilService: UtilService, private ls: LocalStorageService) {
    this.directionList = [{ label: 'Increasing', value: 'I' }, { label: 'Decreasing', value: 'D' }];
    this.workedOnOption(false);
    this.direction = 'I';
    this.selectWorked = 'TS';
    // this.getComponent(null);
  }

  workedOnOption(status) {
    if (status) {
      this.workedOn = [{ label: 'Tie & Surface', value: 'TS' }, { label: 'Only Tie', value: 'T' }, { label: 'Only Surface', value: 'S' }];
    } else {
      this.workedOn = [{ label: 'Tie & Surface', value: 'TS' }, { label: 'Only Tie', value: 'T' }];
    }
  }

  ngAfterViewInit() {
    let i = 0;
    this.tieSideComponents.changes.subscribe(() => {
      this.tieSideComponents.toArray().forEach(el => {
        this.tieComponent[i] = el;
        i++;
      });
    });
    let j = 0;
    this.surfaceSideComponents.changes.subscribe(() => {
      this.surfaceSideComponents.toArray().forEach(el => {
        this.surfaceComponent[j] = el;
        j++;
      });
    });
  }

  directionChange() {
    if (this.direction !== this.previousDirection) {
      this.previousDirection = this.direction;
      this.tieMainComponent.crossingTiesTimbered = null;
      this.surfaceMainComponent.crossingSurfaced = null;
      this.surfaceMainComponent.details = [];
      if (this.trackType === 'SD') {
        this.surfaceMainComponent.details.push(
          new SurfacingDetail(
            this.trackType,
            null,
            null,
            null,
            null,
            this.surfaceMainComponent.crossingSurfaced,
            this.surfaceMainComponent.direction,
            this.surfaceMainComponent.workedOn,
            null
          )
        );
      } else {
        this.surfaceMainComponent.details.push(
          new SurfacingDetail(
            this.trackType,
            this.trackName,
            null,
            null,
            null,
            this.surfaceMainComponent.crossingSurfaced,
            this.surfaceMainComponent.direction,
            this.surfaceMainComponent.workedOn,
            null
          )
        );
      }
      this.tieMainComponent.tiesDetails = [];
      const maintrackExists = this.previousTrackMp.findIndex(x => x.track === this.trackName);
      if (this.reportStatus && maintrackExists > -1) {
        if (this.direction === this.previousTrackMp[maintrackExists].direction) {
          this.tieMainComponent.prepopulateMilepost(this.direction, null, false);
        } else {
          this.tieMainComponent.prepopulateMilepost(this.direction, null, true);
        }
      } else {
        this.tieMainComponent.prepopulateMilepost(this.direction, null, true);
      }
      this.tieMainComponent.removeValidation();
      this.surfaceMainComponent.removeValidation();
      this.removeTrackdetialsMain();
    }
  }

  async workedOnChange() {
    if (this.selectWorked !== this.previousSelectWorked) {
      this.previousSelectWorked = this.selectWorked;
      this.tieMainComponent.crossingTiesTimbered = null;
      this.tieMainComponent.removeValidation();
      this.tieMainComponent.tiesDetails = [];
      this.tieMainComponent.workedOn = this.selectWorked;
      this.surfaceMainComponent.workedOn = this.selectWorked;
      if (this.selectWorked !== 'S') {
        if (this.reportStatus) {
          this.tieMainComponent.prepopulateMilepost(this.direction, null, null);
        } else {
          this.tieMainComponent.direction = this.direction;
          this.tieMainComponent.prepopulateMilepost(this.direction, null, null);
        }
      }
      this.surfaceMainComponent.removeValidation();
      this.surfaceMainComponent.crossingSurfaced = null;
      this.surfaceMainComponent.details = [];
      if (this.selectWorked !== 'T') {
        if (this.trackType === 'SD') {
          this.surfaceMainComponent.details.push(
            new SurfacingDetail(
              this.trackType,
              null,
              null,
              null,
              null,
              this.surfaceMainComponent.crossingSurfaced,
              this.direction,
              this.selectWorked,
              null
            )
          );
        } else {
          this.surfaceMainComponent.details.push(
            new SurfacingDetail(
              this.trackType,
              this.trackName,
              null,
              null,
              null,
              this.surfaceMainComponent.crossingSurfaced,
              this.direction,
              this.selectWorked,
              null
            )
          );
        }
      }
      this.removeTrackdetialsMain();
    }
  }

  removeTrackdetialsMain() {
    this.mainLIneSurface = [];
    this.mainLineTie = [];
    this.tsProgress();
  }

  disbleOnEdit(tie: any): boolean {
    const exsistingData: any = this.prepopulateData && this.prepopulateData.length ? this.prepopulateData[0] : null;
     if (exsistingData && this.editMode) {
       const tieSurfaceData = Object.keys(exsistingData.tsProgress.tieProgress).some((t: any) =>
       exsistingData.tsProgress.tieProgress[t].trackName === tie.trackName) ||
        Object.keys(exsistingData.tsProgress.surfaceProgress).some((t: any) =>
        exsistingData.tsProgress.surfaceProgress[t].trackName === tie.trackName);
        if (tieSurfaceData) {
          tie.showDisplay = true;
        }
        return tieSurfaceData;
     }
     return true;
  }

  async workedOnSidingChange() {
    if (this.tieTrack) {
      await this.tieTrack.forEach((value, index) => {
        if (value.selectSidingWorked !== value.previousSelectSidingWorked) {
          value.previousSelectSidingWorked = value.selectSidingWorked;
          this.tieComponent[index].crossingTiesTimbered = null;
          this.tieComponent[index].tiesDetails = [];
          if (value.selectSidingWorked !== 'S') {
            // this.tieComponent[index].wipeData(this.tieTrack[index]['direction'], null);
            this.tieComponent[index].prepopulateMilepost(this.tieTrack[index]['direction'], null, null);
          }
          this.tieComponent[index].removeValidation();
          this.surfaceComponent[index].removeValidation();
          this.surfaceComponent[index].crossingSurfaced = null;
          this.surfaceComponent[index].details = [];
          this.surfaceComponent[index].details.push(
            new SurfacingDetail(
              value.trackType,
              value.trackName,
              null,
              null,
              null,
              value.crossingSurfaced,
              value.direction,
              value.workedOn,
              null
            )
          );
          this.removeTrackDetailsSiding(value.trackName);
          console.log(' this.sideLineTie   this.sideLineSurface ', this.sideLineSurface, this.sideLineTie);
        }
      });
    }
    console.log(' this.sideLineTie   this.sideLineSurface ', this.sideLineSurface, this.sideLineTie);
    // this.tsProgress();
    this.passTieTrackData();
  }

  removeTrackDetailsSiding(trackName) {
    for (let i = 0; i < this.sideLineSurface.length; i++) {
      if (this.sideLineSurface[i]['trackName'] === trackName) {
        this.sideLineSurface.splice(i, 1);
      }
    }
    for (let i = 0; i < this.sideLineTie.length; i++) {
      if (this.sideLineTie[i]['trackName'] === trackName) {
        this.sideLineTie.splice(i, 1);
      }
    }
    this.tsProgress();
  }

  async directionSidingChange() {
    if (this.tieTrack) {
      await this.tieTrack.forEach((value, index) => {
        if (this.tieTrack[index]['direction'] !== this.tieTrack[index]['previousDirection']) {
          this.tieTrack[index]['previousDirection'] = this.tieTrack[index]['direction'];
          this.tieComponent[index].crossingTiesTimbered = null;
          this.surfaceComponent[index].crossingSurfaced = null;
          this.surfaceComponent[index].details = [];
          this.surfaceComponent[index].details.push(
            new SurfacingDetail(
              value.trackType,
              value.trackName,
              null,
              null,
              null,
              this.surfaceComponent[index].crossingSurfaced,
              this.surfaceComponent[index].direction,
              this.surfaceComponent[index].workedOn,
              null
            )
          );
          this.tieComponent[index].tiesDetails = [];
          const trackExists = this.previousTrackMp.findIndex(x => x.track === value.trackName);
          if (this.reportStatus && trackExists > -1) {
            if (value.direction === this.previousTrackMp[trackExists].direction) {
              this.tieComponent[index].prepopulateMilepost(this.tieTrack[index]['direction'], null, false);
            } else {
              this.tieComponent[index].prepopulateMilepost(this.tieTrack[index]['direction'], null, true);
            }
          } else {
            this.tieComponent[index].prepopulateMilepost(this.tieTrack[index]['direction'], null, true);
          }
          this.tieComponent[index].removeValidation();
          this.surfaceComponent[index].removeValidation();
          this.removeTrackDetailsSiding(value.trackName);
        }
      });
    }
    this.passTieTrackData();
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params: any) => {
      this.job = Number(params.job.split('-')[0]);
      this.formId = params.form;
    });

    this.workedOnFlag();
    setTimeout(() => {
      this.jobObject();
    }, 150);
    this.tsProgress();
    this.passTsData();
  }

  get viewMode() {
    return this.operationMode === OperationMode.VIEW;
  }

  get editMode() {
    return this.operationMode === OperationMode.EDIT;
  }

  async jobObject() {
    await this.ls.getJob(this.job).then((job1: JobTable) => {
      this.jobData = JSON.parse(JSON.stringify(job1.jobObj));
      this.previoustieTrack = this.jobData['tieTrackList'];
      this.tieTrack = this.jobData['tieTrackList'];
      this.trackType = this.jobData['trackType'];
      this.lowMp = this.jobData['startMP'];
      this.highMp = this.jobData['endMP'];
      if (this.tieTrack) {
        for (let i = 0; i < this.tieTrack.length; i++) {
          this.tieTrack[i].selectSidingWorked = 'TS';
          this.tieTrack[i].previousSelectSidingWorked = 'TS';
          this.tieTrack[i].direction = 'I';
          this.tieTrack[i].previousDirection = 'I';
        }
      }
      this.workedOnSiding();
    });
    this.checkReports();
    this.trackLabel();
  }

  async checkReports() {
    await this.ls.getJobReports(Number(this.job)).then((reportList: Array<ReportTable>) => {
      if (reportList.length > 0) {
        reportList.sort(function (x, y) {
          return x.reportObj['workday'] < y.reportObj['workday'] ? -1 : 1;
        });
        let reportcount = reportList.length;
        let previousReport = false;
        while (!previousReport) {
          this.reportobject = JSON.parse(JSON.stringify(reportList[reportcount - 1].reportObj));
          if (this.reportobject.formId !== this.formId && this.reportobject.noProductionCode === null) {
            previousReport = true;
          } else if (reportcount === 1) {
            previousReport = true;
          } else {
            reportcount--;
          }
        }
        if (this.reportobject && this.reportobject.direction && (!this.prepopulateData || (this.prepopulateData[0] &&
          this.prepopulateData[0].status !== 'D'))) {
          this.direction = this.reportobject.direction;
        }
        console.log('inside prepopulation', this.reportobject);
        for (const p of reportList) {
          const js: any = JSON.parse(JSON.stringify(p.reportObj));
          // this.groupPreviousReports(js);
          console.log('asdasdasd', reportList);
          console.log('jss ', js);
          // ******* by using reportstatus we are checking all reports submitted or not
          if ((js.status === 'S' || js.formId > 0) && js.jobType === 'TS' && js.noProductionCode === null) {
            this.groupPreviousReports(js);
            this.reportStatus = true;
            if (js.formId === this.formId && reportList.length === 1) {
              this.reportStatus = false;
            }
          }
        }
      }
    });
    this.setPreviousReport();
    this.workedOnOption(this.reportStatus);
    console.log('previous report 1', this.tieTrack, this.previousTrackMp);
  }

  setPreviousReport() {
    console.log('previous report ', this.tieTrack, this.previousTrackMp);
    if (this.tieTrack && this.previousTrackMp) {
      for (let i = 0; i < this.tieTrack.length; i++) {
        for (let j = 0; j < this.previousTrackMp.length; j++) {
          if (this.tieTrack[i].trackName === this.previousTrackMp[j].track) {
            this.tieTrack[i].previousDirection = this.previousTrackMp[j].direction;
            this.tieTrack[i].direction = this.previousTrackMp[j].direction;
            // this.tieTrack[i].previousRepDir = this.previousReportMpRange[j].direction;
          }
        }
      }
    }
    const maintrackExists = this.previousTrackMp.findIndex(x => x.track === this.trackName);
    if (this.reportStatus && maintrackExists > -1 && this.prepopulateData && !this.prepopulateData[0]) {
      this.direction = this.previousTrackMp[maintrackExists].direction;
      this.previousDirection = this.direction;
    }
  }

  loadReport() {
    if (this.prepopulateData && this.prepopulateData[0]) {
      this.reportLoad = true;
      this.direction = this.prepopulateData[0].tsProgress.direction;
      this.previousDirection = this.direction;
      this.selectWorked = this.prepopulateData[0].tsProgress.workedOn;
      this.previousSelectWorked = this.selectWorked;
      const surfacedata = this.prepopulateData[0].tsProgress.surfaceProgress;
      const tieData = this.prepopulateData[0].tsProgress.tieProgress;
      if (this.prepopulateData[0].tieTrack !== undefined) {
        this.tieTrack = this.prepopulateData[0].tieTrack;
      }
      for (let i = 0; surfacedata[i] !== undefined; i++) {
        if (this.trackName === surfacedata[i].trackName || this.trackName === 'YARD TRACK' ||
          (this.trackType === 'SD' && surfacedata[i].trackType === 'SD')) {
          if (i === 0) {
            this.prepopulateMainLineSurface = [];
          }
          this.prepopulateMainLineSurface.push(surfacedata[i]);
        } else {
          if (i === 0) {
            this.prepopulateSideLineSurface = [];
          }
          this.prepopulateSideLineSurface.push(surfacedata[i]);
        }
        if (this.tieTrack) {
          this.tieTrack.forEach((value, index) => {
            if (value.trackName === surfacedata[i].trackName) {
              this.tieTrack[index]['selectSidingWorked'] = surfacedata[i].workedOn;
              this.tieTrack[index].previousSelectSidingWorked = surfacedata[i].workedOn;
              this.tieTrack[index]['direction'] = surfacedata[i].direction;
              this.tieTrack[index].previousDirection = surfacedata[i].direction;
            }
          });
        }
      }
      for (let i = 0; tieData[i] !== undefined; i++) {
        if (this.trackName === tieData[i].trackName || this.trackName === 'YARD TRACK' ||
          (this.trackType === 'SD' && tieData[i].trackType === 'SD')) {
          if (i === 0) {
            this.prepopulateMainLineTie = [];
          }
          this.prepopulateMainLineTie.push(tieData[i]);
        } else {
          if (i === 0) {
            this.prepopulateSideLineTie = [];
          }
          this.prepopulateSideLineTie.push(tieData[i]);
        }

        if (this.tieTrack) {
          this.tieTrack.forEach((value, index) => {
            if (value.trackName === tieData[i].trackName) {
              this.tieTrack[index]['selectSidingWorked'] = tieData[i].workedOn;
              this.tieTrack[index].previousSelectSidingWorked = tieData[i].workedOn;
              this.tieTrack[index]['direction'] = tieData[i].direction;
              this.tieTrack[index].previousDirection = tieData[i].direction;
            }
          });
        }
      }
      this.workedOnSiding();
      this.workedOnFlag();
    } else {
      this.reportLoad = true;
      console.log('new report');
    }
    this.tsProgress();
    this.passTieTrackData();
  }

  workedOnSiding() {
    if (this.tieTrack) {
      this.tieTrack.forEach((value, index) => {
        if (this.tieTrack[index]['selectSidingWorked'] === 'TS') {
          this.tieTrack[index]['tieSideWorked'] = true;
          this.tieTrack[index]['surfaceSideWorked'] = true;
        } else if (this.tieTrack[index]['selectSidingWorked'] === 'T') {
          this.tieTrack[index]['tieSideWorked'] = true;
          this.tieTrack[index]['surfaceSideWorked'] = false;
        } else if (this.tieTrack[index]['selectSidingWorked'] === 'S') {
          this.tieTrack[index]['tieSideWorked'] = false;
          this.tieTrack[index]['surfaceSideWorked'] = true;
        }
      });
    }
  }

  togglePanel(tie, index) {
    this.sidingIndex = index === this.sidingIndex ? undefined : index;
    if (this.sidingIndex === index) {
      this.tieTrack.forEach(() => {
        this.sidingFlag = this.tieTrack[index]['showDisplay'] = true;
      });
      this.workedOnSiding();
    } else {
      this.tieTrack.forEach(() => {
        this.sidingFlag = this.tieTrack[index]['showDisplay'] = false;
      });
      this.workedOnSiding();
    }
  }

  workedOnFlag() {
    if (this.selectWorked === 'TS') {
      this.tieWorkedFlag = true;
      this.surfaceWorkedFlag = true;
    } else if (this.selectWorked === 'T') {
      this.tieWorkedFlag = true;
      this.surfaceWorkedFlag = false;
    } else if (this.selectWorked === 'S') {
      this.tieWorkedFlag = false;
      this.surfaceWorkedFlag = true;
    }
  }

  async trackLabel() {
    if (this.trackType === 'YD') {
      this.trackName = 'YARD TRACK';
    } else {
      if (this.trackType === 'SD') {
        this.trackName = null;
      } else {
        this.trackName = 'MAINLINE TRACKS';
      }
    }
    this.loadReport();
  }

  async validateTsProgress() {
    let sideTrackValidCount = 0;
    let sidingTrackCount = 0;
    if (
      (this.sideLineTie.length === 0 && this.sideLineSurface.length === 0) ||
      this.mainLineTie.length > 0 ||
      this.mainLIneSurface.length > 0
    ) {
      if (this.selectWorked === 'TS') {
        this.mainTieValid = this.tieMainComponent.validateAll(true);
        this.mainSurfaceValid = this.surfaceMainComponent.validateAll();
      }
      if (this.selectWorked === 'S') {
        this.mainSurfaceValid = this.surfaceMainComponent.validateAll();
        // this.sideTieValid = true;
        this.mainTieValid = true;
      }
      if (this.selectWorked === 'T') {
        this.mainTieValid = this.tieMainComponent.validateAll(true);
        this.mainSurfaceValid = true;
        // this.sideSurfaceValid = true;
      }
    } else {
      this.tieMainComponent.removeValidation();
      this.surfaceMainComponent.removeValidation();
    }
    if (this.tieSideComponent || this.surfaceSideComponent) {
      if (this.tieTrack) {
        this.tieTrack.forEach((value, index) => {
          if (this.sideLineTie['0'] || this.sideLineSurface['0']) {
            this.check = false;
            if (value.selectSidingWorked === 'T' && this.sideLineTie.length > 0) {
              this.validateTieSide(value, index);
              this.sideSurfaceValid[index] = true;
            } else if (value.selectSidingWorked === 'S' && this.sideLineSurface.length > 0) {
              this.validateSurfaceSide(value, index);
              this.sideTieValid[index] = true;
            } else if (this.sideLineTie.length > 0 || this.sideLineSurface.length > 0) {
              this.validateTieSurface(value, index);
            }
            if (this.sideSurfaceValid[index] && this.sideTieValid[index]) {
              if (this.mainLineTie.length === 0 && this.mainLIneSurface.length === 0) {
                this.mainSurfaceValid = true;
                this.mainTieValid = true;
              }
              sideTrackValidCount++;
            }
            if (this.sideSurfaceValid[index] === false || this.sideTieValid[index] === false) {
              this.tieTrack[index].showDisplay = true;
            }
            console.log(' track exists', this.check);
            if (this.check) {
              sidingTrackCount++;
            }
          }
        });
      }
    }
    if (this.sideLineTie.length === 0 && this.sideLineSurface.length === 0) {
    }
    if (this.mainSurfaceValid && this.mainTieValid && sideTrackValidCount === sidingTrackCount) {
      return true;
    }
    return false;
  }

  validateTieSurface(value, index) {
    for (let i = 0; i < this.sideLineTie.length; i++) {
      if (this.sideLineTie[i]['trackName'] === value.trackName) {
        this.sideTieValid[index] = this.tieComponent[index].validateAll();
        this.sideSurfaceValid[index] = this.surfaceComponent[index].validateAll();
        this.check = true;
      }
      for (let j = 0; j < this.sideLineSurface.length; j++) {
        if (this.sideLineSurface[j]['trackName'] === value.trackName) {
          this.sideTieValid[index] = this.tieComponent[index].validateAll();
          this.sideSurfaceValid[index] = this.surfaceComponent[index].validateAll();
          this.check = true;
        }
      }
    }
  }

  validateTieSide(value, index) {
    for (let i = 0; i < this.sideLineTie.length; i++) {
      if (this.sideLineTie[i]['trackName'] === value.trackName) {
        this.sideTieValid[index] = this.tieComponent[index].validateAll();
        this.check = true;
      }
    }
  }

  validateSurfaceSide(value, index) {
    for (let i = 0; i < this.sideLineSurface.length; i++) {
      if (this.sideLineSurface[i]['trackName'] === value.trackName) {
        this.sideSurfaceValid[index] = this.surfaceComponent[index].validateAll();
        this.check = true;
      }
    }
  }

  async passData(data) {
    console.log('surfacdatae', data);
    this.surfaceData = JSON.parse(JSON.stringify(data));
    await this.surfaceData.forEach((value, index) => {
      if (this.trackName === value.trackName || this.trackName === 'YARD TRACK' || (this.trackType === 'SD' && value.trackType === 'SD')) {
        if (index === 0) {
          this.mainLIneSurface = [];
        }
        this.mainLIneSurface.push(value);
      } else {
        for (let i = 0; i < this.sideLineSurface.length; i++) {
          if (this.sideLineSurface[i]['trackName'] === value.trackName) {
            this.sideLineSurface.splice(i, 1);
            i--;
          }
        }
      }
    });
    await this.surfaceData.forEach((value, index) => {
      if (value.trackName !== this.trackName && value.trackType !== 'YD' && !(this.trackType === 'SD' && value.trackType === 'SD')) {
        this.tieTrack.forEach((value1, index1) => {
          if (value1.trackName === value.trackName) {
            value.workedOn = value1.selectSidingWorked;
            value.direction = value1.direction;
            this.sideLineSurface.push(value);
          }
        });
      }
    });
    this.tsProgress();
  }

  async passTieData(data) {
    console.log('tie data', data);
    this.tieData = JSON.parse(JSON.stringify(data));
    await this.tieData.forEach((value, index) => {
      if (this.trackName === value.trackName || this.trackName === 'YARD TRACK' || (this.trackType === 'SD' && value.trackType === 'SD')) {
        if (index === 0) {
          this.mainLineTie = [];
        }
        this.mainLineTie.push(value);
      } else {
        for (let i = 0; i < this.sideLineTie.length; i++) {
          if (this.sideLineTie[i]['trackName'] === value.trackName) {
            this.sideLineTie.splice(i, 1);
            i--;
          }
        }
      }
    });

    await this.tieData.forEach((value, index) => {
      if (value.trackName !== this.trackName && value.trackType !== 'YD' && !(this.trackType === 'SD' && value.trackType === 'SD')) {
        this.tieTrack.forEach((value1, index1) => {
          if (value1.trackName === value.trackName) {
            value.workedOn = value1.selectSidingWorked;
            value.direction = value1.direction;
            this.sideLineTie.push(value);
          }
        });
      }
    });
    this.tsProgress();
  }

  passTsData() {
    this.tsData.emit(this.tsProgressData);
    this.passTieTrackData();
  }

  passTieTrackData() {
    this.tieTrackData.emit(this.tieTrack);
  }

  tsProgress() {
    this.tsProgressData = {};
    this.tieProgress = {};
    this.surfaceProgress = {};
    let tielength = 0;
    let surfaceLength = 0;
    if (this.mainLineTie.length > 0) {
      this.mainLineTie.forEach((value, index) => {
        this.tieProgress[index] = value;
        tielength++;
      });
    }

    if (this.sideLineTie.length > 0) {
      this.sideLineTie.forEach((value, index) => {
        this.tieProgress[tielength] = value;
        tielength++;
      });
    }
    if (this.mainLIneSurface.length > 0) {
      this.mainLIneSurface.forEach((value, index) => {
        this.surfaceProgress[index] = value;
        surfaceLength++;
      });
    }
    if (this.sideLineSurface.length > 0) {
      this.sideLineSurface.forEach((value, index) => {
        this.surfaceProgress[surfaceLength] = value;
        surfaceLength++;
      });
    }
    this.tsProgressData['crossingTiesTimbered'] = this.tieMainComponent.crossingTiesTimbered;
    this.tsProgressData['crossingSurfaced'] = this.surfaceMainComponent.crossingSurfaced;
    if (this.tieSideComponent) {
      this.tsProgressData['sidingCrossingTimbered'] = this.tieSideComponent.crossingTiesTimbered;
    }
    if (this.surfaceSideComponent) {
      this.tsProgressData['sidingCrossingSurfaced'] = this.surfaceSideComponent.crossingSurfaced;
    }
    if (this.sidingFlag) {
      if (this.tieTrack) {
        this.tieTrack.forEach((value, index) => {
          if (this.sidingIndex === index) {
            this.tsProgressData['sidingDirection'] = value.direction;
            this.tsProgressData['sidingWorkedOn'] = value.selectSidingWorked;
          }
        });
      }
    }
    this.tsProgressData['tieProgress'] = this.tieProgress;
    this.tsProgressData['surfaceProgress'] = this.surfaceProgress;
    this.tsProgressData['direction'] = this.direction;
    this.tsProgressData['workedOn'] = this.selectWorked;
    this.passTsData();
  }

  groupPreviousReports(previousData) {
    const mainindex = previousData.wpRange.findIndex(x => x.track === this.trackName);
    const trackMainPresent = this.previousTrackMp.findIndex(x => x.track === this.trackName);
    if (mainindex > -1 && trackMainPresent === -1) {
      // this.previousTrackMp.push(previousData.wpRange[mainindex]);
      this.previousTrackMp.push(previousData.wpRange[mainindex]);
    }
    if (this.tieTrack) {
      for (let i = 0; i < this.tieTrack.length; i++) {
        const index = previousData.wpRange.findIndex(x => x.track === this.tieTrack[i].trackName);
        const trackPresent = this.previousTrackMp.findIndex(x => x.track === this.tieTrack[i].trackName);
        if (index > -1 && trackPresent === -1) {
          this.previousTrackMp.push(previousData.wpRange[index]);
        }
      }
    }
    console.log('this.previousTrackMp', this.previousTrackMp);
  }
}
