import { SwitchTieSizeModel } from './../../models/switch-tie-report-details.model';
import { Component, OnInit, Input } from '@angular/core';
import {FormGroup, FormControl, FormArray, Validators, AbstractControl} from '@angular/forms';
import { KeyValue } from '@angular/common';
import { SwitchTieDetailsModel } from '../../models/switch-tie-report-details.model';

@Component({
  selector: 'app-switch-tie-card',
  templateUrl: './switch-tie-card.component.html',
  styleUrls: ['./switch-tie-card.component.scss']
})
export class SwitchTieCardComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() isFormSubmitted: boolean;
  @Input() newRelayOptions: Array<KeyValue<string, string>>;
  @Input() tieTypeOptions: Array<KeyValue<string, string>>;
  @Input() swithTieDetails: SwitchTieDetailsModel;
  @Input() viewMode: boolean;
  switchTiesLabels: Array<string> = ['9', '10', '11', '12', '13', '14', '15', '16', '16-6', '23'];
  private switchTiesSizes: Array<number> = [9, 10, 11, 12, 13, 14, 15, 16, 16.5, 23];
  private numberPattern = '^\\d+$';

  constructor() {}

  ngOnInit() {
    this.initializeFormGroup();
    if (this.viewMode) {
      this.formGroup.disable();
    }
  }

  get switchSizeFormArray(): FormArray {
    return this.formGroup.controls.switchTiesSizes as FormArray;
  }

  onChangeOfApproachTies() {
    if (this.formGroup.controls.noOfApproachTies.value > 0) {
       this.formGroup.controls.switchTiesSizes.setValidators([]);
     } else {
       this.formGroup.controls.switchTiesSizes.setValidators([
         this.validateMinimumSwitchTieRequired(1)]);
     }
     this.formGroup.controls.switchTiesSizes.updateValueAndValidity();
   }

  private initializeFormGroup() {
    const switchTieDetails: SwitchTieDetailsModel = new SwitchTieDetailsModel(this.swithTieDetails);
    this.formGroup.addControl('tieType', new FormControl(switchTieDetails.tieType, [Validators.required]));
    this.formGroup.addControl('newRelay', new FormControl(switchTieDetails.newRelay, [Validators.required]));
    this.formGroup.addControl(
      'noOfApproachTies',
      new FormControl(switchTieDetails.noOfApproachTies, [Validators.required, Validators.min(0), Validators.pattern(this.numberPattern)])
    );
    this.formGroup.addControl(
      'noOfBridgeApproachTies',
      new FormControl(switchTieDetails.noOfBridgeApproachTies, [Validators.min(0), Validators.pattern(this.numberPattern)])
    );
    this.formGroup.addControl('switchTiesSizes', this.getSwitchTieSizesFormArray(switchTieDetails));
  }

  private getSwitchTieSizesFormArray(switchTieDetails: SwitchTieDetailsModel) {
    const formArray = new FormArray([], this.validateMinimumSwitchTieRequired(1));
    for (const size of this.switchTiesSizes) {
      const switchTieSize = switchTieDetails.switchTiesSizes.find((switchTie: SwitchTieSizeModel) => switchTie.size === size);
      formArray.push(this.getSwitchTieSizesFormGroup(size, switchTieSize));
    }
    return formArray;
  }

  private getSwitchTieSizesFormGroup(size: number, switchTieSize: SwitchTieSizeModel) {
    return new FormGroup({
      size: new FormControl(size),
      noOfTies: new FormControl(switchTieSize && switchTieSize.noOfTies ? switchTieSize.noOfTies : null,
          [
        Validators.min(0),
        Validators.pattern(this.numberPattern)
      ])
    });
  }

  private validateMinimumSwitchTieRequired(min: number) {
      return (control: AbstractControl) => {
        if (!(control instanceof FormArray)) {
          return;
        }
        let count = 0;
        for (const formGroup of control.controls) {
          if ((<FormGroup>formGroup).controls.noOfTies.valid && (<FormGroup>formGroup).controls.noOfTies.value) {
              count++;
          } if (this.formGroup.controls.noOfApproachTies.value > 0) {
            return;
          }
        }
        return count >= min ? null : { atleastOneRequired: true };
      };
    }
}
