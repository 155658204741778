import { Component, OnInit } from "@angular/core";
import { AuthService } from "csx-flex-ng";

@Component({
  selector: "main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"]
})
export class MainComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit() {}

  logOut() {
    this.authService.logout();
    event.preventDefault();
  }
}
