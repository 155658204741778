import { DataService } from "../../services/data.service";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "csx-flex-ng";

@Component({
  selector: "app-project-container",
  templateUrl: "./project-container.component.html",
  styleUrls: ["./project-container.component.scss"]
})
export class ProjectContainerComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    console.log("In login");
    this.login();
  }

  login() {
    try {
      console.log("In login");
      if (!this.dataService.loginFlag) {
        this.dataService.loginUser();
      }
    } catch (e) {
      console.error(e);
    }
  }

  logOut() {
    this.authService.logout();
  }
}
