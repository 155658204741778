import { Directive, HostListener, Input, ElementRef } from '@angular/core';
import { NgControl } from '../../../../node_modules/@angular/forms';

@Directive({
  selector: '[maxAllowedChars]'
})
export class MaxAllowedCharsDirective {
  @Input('maxAllowedChars') maxAllowedChars: number;
  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef, private control: NgControl) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('blur', ['$event.target.value'])
  @HostListener('keyup', ['$event.target.value'])
  onEvent(value) {
    if (value && value.length > this.maxAllowedChars) {
      this.el.value = value.substr(0, this.maxAllowedChars);
      this.control.control.patchValue(this.el.value);
    }
  }
}
