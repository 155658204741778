import {Component, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-report-footer',
    templateUrl: './report-footer.component.html',
    styleUrls: ['./report-footer.component.scss']
})
export class ReportFooterComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

    @Output() routingmap = new EventEmitter();
    @Output() backclick = new EventEmitter();

    navigate() {
        this.routingmap.emit('');

    }
    navigateback() {
        this.backclick.emit('');
    }
}