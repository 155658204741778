import { Component } from '@angular/core';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { ReportDataService } from '../../shared/services/report-data.service';
import { UtilService } from '../../util/util.service';
import { BaseReportEditMixin } from '../../base-report/base-report-edit-mixin';
import { ConfirmationService } from 'primeng/primeng';
import { ReportNavigationService } from '../../shared/services/report-navigation.service';
import { OperationMode } from '../../shared/enums/operation-mode.enum';
import { SwitchTieReportComponent } from '../switch-tie-report.component';
import { SurfacingDetailsModel, SwitchTieDetailsModel } from '../models/switch-tie-report-details.model';
import { Turnout } from '../../model/turnout.model';

@Component({
  selector: 'app-switch-tie-report-edit',
  templateUrl: '../switch-tie-report.component.html',
  styleUrls: ['../switch-tie-report.component.scss']
})
export class SwitchTieReportEditComponent extends BaseReportEditMixin(SwitchTieReportComponent) {
  constructor(
    public formBuilder: FormBuilder,
    public ds: DataService,
    public ls: LocalStorageService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    public utilService: UtilService,
    public reportDataService: ReportDataService,
    public acknowledgeService: ConfirmationService,
    public reportNavigationService: ReportNavigationService
  ) {
    super(formBuilder, ds, ls, router, activeRoute, utilService, reportDataService, acknowledgeService, reportNavigationService);
  }

  get editMode() {
    return this.operationMode === OperationMode.EDIT;
  }

  getDefaultFromSameDayReport(reportData){
    return reportData;
  }

  getTrackTypesWhereWorkIsDone() {
    const tracksWhereWorkIsDone = [];
    (this.surfacingFormGroup.controls.surfacing as FormArray).controls.forEach((surfacingFormGroup: FormGroup) => {
      if (surfacingFormGroup.controls.crossingSurfaced && surfacingFormGroup.controls.crossingSurfaced.value !== null) {
        tracksWhereWorkIsDone.push((<SurfacingDetailsModel>surfacingFormGroup.getRawValue()).trackDetails.trackType);
      }
    });
    (this.switchTiesFormGroup.controls.switchTies as FormArray).controls.forEach((switchTieFormGroup: FormGroup) => {
      if (switchTieFormGroup.getRawValue() && switchTieFormGroup.getRawValue().turnout) {
        const turnout: Turnout = (<SwitchTieDetailsModel>switchTieFormGroup.getRawValue()).turnout;
        tracksWhereWorkIsDone.push(turnout.trackName + '-' + turnout.milepost);
      }
    });
    return tracksWhereWorkIsDone;
  }

  protected getPreviousReport() {
    return this.switchTieReportDetails;
  }

  protected getTdValue() {
    return this.tdFormGroup.get('speedRestrictions').getRawValue().filter(data => data.workTypeCode !== null );
  }

  protected getSurfacingValue() {
    return this.surfacingFormGroup.get('surfacing').getRawValue();
  }

  protected initializeForm() {
    super.initializeForm();
    this.disableReportFields();
  }

  protected disableReportFields() {
    this.formGroup.controls.roadMaster.disable();
    this.formGroup.controls.workday.disable();
    this.formGroup.controls.direction.disable();
    this.formGroup.controls.workedOn.disable();
  }

}
