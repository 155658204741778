import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '../../../../../node_modules/@angular/router';
import { EventBusService } from '../../../services/eventbus.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { OperationMode } from '../../../shared/enums/operation-mode.enum';
import { ReportNavigationService } from '../../../shared/services/report-navigation.service';
import { TurnoutContainerComponent } from '../turnout-container.component';
import { TurnoutFormService } from '../turnout-form.service';

@Component({
  selector: 'app-turnout-container-edit',
  templateUrl: '../turnout-container.component.html',
  styleUrls: ['../turnout-container.component.scss'],
  providers: [TurnoutFormService]
})
export class TurnoutContainerEditComponent extends TurnoutContainerComponent implements OnInit {
  isTDEditable: Boolean;

  constructor(
    protected eventBus: EventBusService,
    protected turnoutListFormService: TurnoutFormService,
    protected activeRoute: ActivatedRoute,
    protected ls: LocalStorageService,
    protected router: Router,
    protected reportNavigationService: ReportNavigationService
  ) {
    super(eventBus, turnoutListFormService, activeRoute, ls, router);
  }

  ngOnInit() {
    this.isTDEditable = this.activeRoute.snapshot.queryParams.isTdEditable === 'true' ? true : false;
    this.operationMode = OperationMode.EDIT;
    super.ngOnInit();
  }

  protected checkReportOperationMode() {
    this.operationMode = OperationMode.EDIT;
  }

  protected getReportMethod(formId: any) {
    return this.ls.getEditedReportByFormId(formId);
  }

  backIcon() {
    this.reportNavigationService.navigateToJobView(this.report.projectId, this.report.formId);
  }

  protected saveAndNavigate(save: boolean, page: string) {
    let reportObj = JSON.parse(JSON.stringify(this.report.reportObj));
    reportObj['reportTurnoutList'] = this.turnouts.value;
    this.report.reportObj = reportObj;
    console.log('form submitted', this.report);
    if (save) {
      this.ls.addEditedReport(this.report).then(() => {
        this.navigateToNextPage(page);
      });
    } else {
      this.navigateToNextPage(page);
    }
  }

  private navigateToNextPage(page: string) {
    return this.router.navigate([`/${page}/edit/${this.report.projectId}/${this.report.jobId}/${this.report.formId}`], {
      queryParams: {
        isTdEditable: this.isTDEditable
      }
    });
  }
}
