

export class NotesModel {
  teamName: string;
  onDutyTime: Date;
  sawCutsTime: Date;
  firstWeldTime: Date;
  firstSpikedPulled: Date;
  firstSpikeDrivenTime: Date;
  lastSpikeDriven: Date;
  plannedStart: Date;
  plannedEnd: any;
  reportWindows: Window[] = [];
  greenSignalTime: Date;
  workday: Date;

  constructor(notesModel?: NotesModel) {
    if (notesModel) {
      this.teamName = notesModel.teamName;
      this.onDutyTime = notesModel.onDutyTime;
      this.sawCutsTime = notesModel.sawCutsTime;
      this.firstWeldTime = notesModel.firstWeldTime;
      this.firstSpikeDrivenTime = notesModel.firstSpikeDrivenTime;
      this.firstSpikedPulled = notesModel.firstSpikedPulled;
      this.lastSpikeDriven = notesModel.lastSpikeDriven;
      this.plannedStart = notesModel.plannedStart;
      this.plannedEnd = notesModel.plannedEnd;
      this.reportWindows = notesModel.reportWindows;
      this.greenSignalTime = notesModel.greenSignalTime;
      this.workday = notesModel.onDutyTime;
    } else {
      this.teamName = null;
      this.onDutyTime = null;
      this.sawCutsTime = null;
      this.firstWeldTime = null;
      this.firstSpikeDrivenTime = null;
      this.firstSpikedPulled = null;
      this.lastSpikeDriven = null;
      this.plannedStart = null;
      this.plannedEnd = null;
      this.reportWindows = null;
      this.greenSignalTime = null;
      this.workday = null;
    }
  }
}

export class Window {
  windowStarts: Date;
  windowEnds: Date;
  windowStart?: Date;
  windowEnd?: Date;
  windowNumber: number;
  unitsInstalled: Number;
  unitPerHour: Number;

  constructor(
    windowStarts: Date,
    windowEnds: Date,
    windowStart: Date,
    windowEnd: Date,
    windowNumber: number,
    unitsInstalled: Number,
    unitPerHour: Number,
  ) {
    this.windowStarts = windowStarts;
    this.windowEnds = windowEnds;
    this.windowStart = windowStart;
    this.windowEnd = windowEnd;
    this.windowNumber = windowNumber;
    this.unitsInstalled = unitsInstalled;
    this.unitPerHour = unitPerHour;
  }
}
