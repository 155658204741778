import { DataService } from '../../services/data.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatus } from '../../sync/sync.component';
import { ProjectTable, LocalStorageService, ReportTable, CurrentUserTable } from '../../services/local-storage.service';
import { ConfirmationService } from 'primeng/primeng';


@Component({
  selector: 'app-project-header',
  templateUrl: './project-header.component.html',
  styleUrls: ['./project-header.component.scss']
})
export class ProjectHeaderComponent implements OnInit {
  showMenu: boolean;
  projectId: number = null;
  projectName: string = null;
  display = false;
  jobDisplay = false;
  showSyncComponent = false;
  showErrorComponent = false;
  forceAutoSync = false;
  locationArray: Array<any>;
  project: any = [];
  projDiv;
  unsyncReports = [];
  draftReports = [];

  confirmationMsg = '';
  showConfirmation = false;

  constructor(private activeRoute: ActivatedRoute,
    private ds: DataService,
    private ls: LocalStorageService, public router: Router, private logOutconfirmationService: ConfirmationService) {
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((params: any) => {
      let projectData = params.project;
      if (projectData) {
        this.projectId = Number(projectData.split('-')[0]);
      }
    });
    if (this.projectId !== null && this.projectId !== undefined) {
      this.ls.getProject(this.projectId).then((project1: ProjectTable) => {
        let po = JSON.parse(JSON.stringify(project1.projectObj));
        this.projectName = (po).projectType + ' - ' + (po).prefix + ' ' + (po).startMP + '-' + (po).endMP;
        let formatedobj: any = [];
        if (po.hasOwnProperty('projectLocation')) {
          this.locationArray = this.groupby(po['projectLocation'], 'division');
          let object = { 'projectLocation': this.locationArray };
          formatedobj = Object.assign(po, object);
          this.projDiv = formatedobj.projectLocation;
        } else {
          this.projDiv = formatedobj.projectLocation;
        }
      });
    }
    this.ls.getCurrentUser('SYSTEM').then((userObject: CurrentUserTable) => {
      if (userObject.deviceSpecificLastSync) {
        this.forceAutoSync = !this.compareDates(new Date(userObject.deviceSpecificLastSync));
        if (this.forceAutoSync) {
          this.showDialog();
        }
      }
    });
  }

  compareDates(compareDate: Date): boolean {
    const date = new Date();
    if (compareDate.getDate() === date.getDate() && compareDate.getMonth() === date.getMonth() && compareDate.getFullYear() === date.getFullYear()) {
      return true;
    } else {
      return false;
    }
  }

  groupby(collection: any, property: string) {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if (!collection) {
      return null;
    }
    const groupedCollection = collection.reduce((previous, current) => {
      if (!previous[current[property]]) {
        previous[current[property]] = [current];
      } else {
        previous[current[property]].push(current);
      }
      return previous;
    }, {});
    // this will return an array of objects, each object containing a group of objects
    return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
  }

  public toggleMenu(action): void {
    this.updateUnsynceReports();
    if (action === 'close') {
      this.showMenu = false;
    } else {
      this.showMenu = !this.showMenu;
    }
  }

  showDialog() {
    if (navigator.onLine) {
      this.showSyncComponent = true;
      this.showErrorComponent = false;
    } else {
      this.showErrorComponent = true;
      this.showSyncComponent = false;
    }
  }

  onDialogClose(event) {
    this.display = event;
  }

  public showJobDialog() {
    this.jobDisplay = true;
  }

  public workOffLine() {
    this.ds.status = !this.ds.status;
  }




  async updateUnsynceReports() {
    this.ls
      .getReportsForSync()
      .then(async (reportList: Array<ReportTable>) => {
        console.log(reportList);
        this.unsyncReports = [];
        this.draftReports = [];
        if (reportList) {
          for (let p of reportList) {
            if (p.reportObj["workTypeCode"] === "RA") {
              p.reportObj["workTypeDesc"] = "Rail";
            }
            let s = JSON.parse(JSON.stringify(p.reportObj)).status;
            if (s !== null && s === FormStatus.SUBMITTED) {
              this.unsyncReports.push(p.reportObj);
            }
            if (s === FormStatus.DRAFT) {
              this.draftReports.push(Number(p.formId));
            }
          }
        }
      })
      .catch(err => {
        console.error(err);
      });
  }






  async confirm() {
    // await this.updateUnsynceReports();
    const me = this;
    // this.showConfirmation = true;
    if (this.draftReports.length > 0) {
      this.confirmationMsg =
        "UnSubmitted Draft Reports will be deleted upon Sync. " +
        " Do you still want to proceed with change team?";
      this.showConfirmation = true;
    } else {
      //alert(this.draftReports.length);
      this.router.navigate(['/main/true']);
    }
  }

  onCnfmDialogClose(event) {
    this.showConfirmation = event;
  }


}
