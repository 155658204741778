import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PlannedActual2Component } from './../planned-actual2.component';
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { LocalStorageService, ReportTable, ProjectTable } from '../../services/local-storage.service';
import { UtilService } from '../../util/util.service';
import { ReportDataService } from '../../shared/services/report-data.service';
import { OperationMode } from '../../shared/enums/operation-mode.enum';
import { ReportNavigationService } from '../../shared/services/report-navigation.service';
import { WorkType } from '../../enums/work-type.enum';

@Component({
  selector: 'app-planned-actual-edit',
  templateUrl: '../planned-actual2.component.html',
  styleUrls: ['../planned-actual2.component.scss']
})
export class PlannedActualEditComponent extends PlannedActual2Component implements OnInit {
  isTDEditable: Boolean;
  private originalReport: any;

  constructor(
    protected fb: FormBuilder,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected dataService: DataService,
    protected localStorage: LocalStorageService,
    public utilService: UtilService,
    protected reportDataService: ReportDataService,
    private reportNavigationService: ReportNavigationService
  ) {
    super(fb, router, activeRoute, dataService, localStorage, utilService, reportDataService);
  }

  ngOnInit() {
    this.isTDEditable = this.activeRoute.snapshot.queryParams.isTdEditable === 'true' ? true : false;
    this.operationMode = OperationMode.EDIT;
    this.dataService.getReport(Number(this.activeRoute.snapshot.params.form)).then(report => {
      this.originalReport = report ? report.reportObj : null;
      super.ngOnInit();
    });
  }

  protected setOperationMode() {
    return this.operationMode === OperationMode.EDIT;
  }

  protected reportfill() {
    this.dataService.getProjectInfo(this.projectId).then((project: ProjectTable) => {
      this.projectObject = JSON.parse(JSON.stringify(project.projectObj));
      this.reportData.totalActualProjectUnits = this.projectObject.totalActualProjectUnits;
      this.formGroup.controls['totalActualProjectUnits'].patchValue(this.projectObject.totalActualProjectUnits);
      super.reportfill();
    });
  }

  protected saveReportToIndexDbMethod(reportObject: any) {
    const report: ReportTable = {
      formId: Number(reportObject.formId),
      jobId: Number(reportObject.jobId),
      projectId: Number(reportObject.projectId),
      reportObj: reportObject
    };
    return this.dataService.addEditedReport(report);
  }

  protected getReportMethod() {
    return this.dataService.getEditedReportByFormId(Number(this.formId));
  }

  protected getTotalSubmittedProjectUnits() {
    // Subtract units from original report since that is part of project units after sync/submit otherwise getting counted twice
    return this.submitReportTotalProjectUnits - this.getOriginalReportUnitsInstalled();
  }

  protected getTotalActualProjectUnits() {
    // Subtract units from original report since that is part of project units after sync/submit otherwise getting counted twice
    return this.formGroup.controls['totalActualProjectUnits'].value - this.getOriginalReportUnitsInstalled();
  }

  private getOriginalReportUnitsInstalled() {
    let unitsInstalled = 0;
    if (this.originalReport) {
      if (this.originalReport.workTypeCode === WorkType.SWITCHTIE) {
        unitsInstalled = this.originalReport.totalTiesInstalled ? this.originalReport.totalTiesInstalled : 0;
      } else if (this.originalReport.workTypeCode === WorkType.RAIL) {
        unitsInstalled = this.originalReport.railLength ? this.originalReport.railLength : 0;
      } else if (this.originalReport.workTypeCode === WorkType.TIEANDSURFACE) {
        unitsInstalled = this.originalReport.totalTiesInstalled ? this.originalReport.totalTiesInstalled : 0;
      } else if (this.originalReport.workTypeCode === WorkType.SURFACE) {
        unitsInstalled = this.originalReport.totalSurfacedFeet ? this.originalReport.totalSurfacedFeet : 0;
      }
    }
    return unitsInstalled;
  }

  protected navigateToRoadCrossingOrTurnout(navigateToXing: boolean) {
    if (navigateToXing) {
      this.router.navigate([`/road_crossing/edit/${this.projectId}/${this.jobId}/${this.formId}`], {
        queryParams: {
          isTdEditable: this.isTDEditable
        }
      });
    } else {
      this.router.navigate([`/turnout/edit/${this.projectId}/${this.jobId}/${this.formId}`], {
        queryParams: {
          isTdEditable: this.isTDEditable
        }
      });
    }
  }

  protected topBackButtonNavigation() {
    this.reportNavigationService.navigateToJobView(this.projectId, this.formId);
  }

  protected bottomBackButtonNavigation() {
    this.saveReportToIndexDbMethod(this.reportData).then(() => {
      if (this.reportData.workTypeCode === WorkType.SWITCHTIE) {
        this.router.navigate([`/switch_tie/edit/${this.projectId}/${this.jobId}/${this.formId}`], {
          queryParams: {
            isTdEditable: this.isTDEditable
          }
        });
      } else {
        if (this.jobType === 'PR' || this.jobType === 'OF') {
          this.router.navigate([`/rail_info/edit/${this.projectId}/${this.jobId}/${this.formId}`], {
            queryParams: {
              isTdEditable: this.isTDEditable
            }
          });
        } else if (this.jobType === 'GA' || this.jobType === 'CP') {
          this.router.navigate([`/gauging_info/edit/${this.projectId}/${this.jobId}/${this.formId}`], {
            queryParams: {
              isTdEditable: this.isTDEditable
            }
          });
        } else if (this.jobType === 'TS') {
          this.router.navigate([`/ts_info/edit/${this.projectId}/${this.jobId}/${this.formId}`], {
            queryParams: {
              isTdEditable: this.isTDEditable
            }
          });
        } else if (this.jobType === 'SO') {
          this.router.navigate([`/surfacing_info/edit/${this.projectId}/${this.jobId}/${this.formId}`], {
            queryParams: {
              isTdEditable: this.isTDEditable
            }
          });
        }
        }
    });
  }

  protected routeToTrackUtil() {
    this.router.navigate([`/track_utilization/edit/${this.projectId}/${this.jobId}/${this.formId}`], {
      queryParams: {
        isTdEditable: this.isTDEditable
      }
    });
  }
}
