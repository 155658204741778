import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ProjectTable, LocalStorageService, JobTable, ReportTable } from '../../services/local-storage.service';
import { EventBusService, EventType } from '../../services/eventbus.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-project-row',
  templateUrl: './project-row.component.html',
  styleUrls: ['./project-row.component.scss']

})
export class ProjectRowComponent implements OnInit, OnDestroy {
  public projectData: any = [];
  private projectSub: Subscription;
  public locationArray: Array<any>;
  public syncflag = true;

  constructor(public dataService: DataService, private ngZone: NgZone,
    private ls: LocalStorageService, private eventBus: EventBusService) {

  }

  ngOnInit() {
    this.init();
    console.log('caling project row service');
    this.projectSub = this.eventBus.getEventBus(EventType.PROJECTS_UPDATED_EVENT).subscribe(response => {
      this.init();
    },
      (e) => {
        console.log(e);
        this.init();
      },
      () => {
      }
    );

  }

  ngOnDestroy(): void {
    if (this.projectSub) {
      this.projectSub.unsubscribe();
    }
  }

  init() {
    this.projectData = [];
    this.ls.getAllProjects().then((projectList: Array<ProjectTable>) => {
      let formatedobj = {};
      let projectCount = projectList.length;
      console.log('projectcount', projectCount);
      for (const p of projectList) {

        this.ngZone.run(() => {
          this.syncflag = false;
        });
        if (p.projectObj.hasOwnProperty('projectLocation')) {
          this.locationArray = this.groupby(p.projectObj['projectLocation'], 'divisionX');
          const object = { 'projectLocation': this.locationArray };
          console.log('object');
          formatedobj = Object.assign(p.projectObj, object);
          console.log(formatedobj);
          this.projectData.push(Object.assign(p.projectObj, formatedobj));
          this.projectData.sort((x, y) => x.startDate - y.startDate);
          console.log('project data', this.projectData);

        } else {
          this.projectData.push(Object.assign(p.projectObj, formatedobj));
          this.projectData.sort((x, y) => x.startDate - y.startDate);
        }

        const projectId = p.projectObj['project'];
        let totalActualProjectsUnits = 0;
        const latestReportByTeam = {};
        this.ls.getProjectJobs(Number(projectId)).then((jobList: Array<JobTable>) => {
          console.log('job data ', jobList);
          if (jobList.length === 0) {
            projectCount--;
          }
          console.log(jobList);
          jobList.forEach((app, index) => {
            this.ls.getJobReports(app.jobId).then((reportList: Array<ReportTable>) => {
              if (reportList.length > 0) {
                for (const p1 of reportList) {
                  const js: any = JSON.parse(JSON.stringify(p1.reportObj));
                  // ******* by using reportstatus we are checking all reports submitted or not
                  if (js.status !== 'D') {
                    console.log('report : ', js);
                    if (app.jobObj['isSwitchTieJob']) {
                      if (js['totalTiesInstalled']) {
                        totalActualProjectsUnits = totalActualProjectsUnits + js['totalTiesInstalled'];
                      }
                    } else {
                      if (js['jobType'] === 'TS') {
                        if (js['totalTiesInstalled']) {
                          totalActualProjectsUnits = totalActualProjectsUnits + js['totalTiesInstalled'];
                        }
                      } else if (js['jobType'] === 'SO') {
                        if (js['totalSurfacedFeet']) {
                          totalActualProjectsUnits = totalActualProjectsUnits + js['totalSurfacedFeet'];
                        }
                      } else {
                        if (js['railLength']) {
                          totalActualProjectsUnits = totalActualProjectsUnits + js['railLength'];
                        }
                      }
                    }
                    if (latestReportByTeam.hasOwnProperty(js.teamName)) {
                      if (this.convertEpochToDate(js.workday) ===
                        this.convertEpochToDate(latestReportByTeam[js.teamName].workday)) {
                        if (Number(js.formId) < Number(latestReportByTeam[js.teamName].formId)) {
                          latestReportByTeam[js.teamName] = js;
                        }
                      } else if (js.workday > latestReportByTeam[js.teamName].workday) {
                        latestReportByTeam[js.teamName] = js;
                      }
                    } else {
                      latestReportByTeam[js.teamName] = js;
                    }
                  }
                }
              }
              if (index === jobList.length - 1) {
                let totalPlannedUnitsForAllTeams = 0;
                console.log(latestReportByTeam);
                for (const team in latestReportByTeam) {
                  if (latestReportByTeam.hasOwnProperty(team)) {
                    totalPlannedUnitsForAllTeams +=
                      latestReportByTeam[team].totalPlannedProjectUnits ?
                        latestReportByTeam[team].totalPlannedProjectUnits : 0;
                  }
                }
                if (totalPlannedUnitsForAllTeams === 0) {
                  totalPlannedUnitsForAllTeams = p.projectObj['totalPlannedProjectUnits'];
                }
                console.log('Total actual units: ' + totalActualProjectsUnits);
                console.log('Total planned units: ' + totalPlannedUnitsForAllTeams);
                console.log('project planned units: ' + p.projectObj['totalPlannedProjectUnits']);
                if (totalActualProjectsUnits > 0 && totalPlannedUnitsForAllTeams > 0) {
                  const percentWorkComplete = totalActualProjectsUnits / totalPlannedUnitsForAllTeams * 100;
                  p.projectObj['percentWorkComplete'] = percentWorkComplete.toFixed(2);
                } else {
                  p.projectObj['percentWorkComplete'] = 0;
                }

                projectCount--;
                if (projectCount === 0) {
                  this.ngZone.run(() => {
                    this.syncflag = true;
                  });
                  console.log(' iteration completed ');
                }
              }

            });
          });
        });

      }
    }
    ).catch(e => {
      console.error(e);
      this.syncflag = true;
    });
  }


  toUpper(str: String) {

    return str
      .toLowerCase()
      .split(' ')
      .map(function (word) {

        return word[0].toUpperCase() + word.substr(1);
      })
      .join(' ');
  }

  convertEpochToDate(epoch: number) {
    if (epoch == null) {
      return null;
    }
    let date = new Date(epoch).toLocaleDateString().replace(/\//g, '-');
    let dateArray = date.split('-');
    date =
      dateArray[2] +
      '-' +
      this.convertSingleDigitToDouble(dateArray[0]) +
      '-' +
      this.convertSingleDigitToDouble(dateArray[1]);
    return date;
  }

  convertSingleDigitToDouble(value: string): string {
    if (value.length === 1) {
      return '0' + value;
    } else {
      return value;
    }
  }

  getColor(value) {
    let result = 'text-gray';
    let perc = (value.materialDelivered / value.materialTotal) * 100;
    if (perc === 0) {
      result = 'text-gray';
    } else if (perc > 0 && perc < 100) {
      result = 'text-orange';
    } else if (perc === 100) {
      result = 'text-green';
    }
    return result;
  };

  groupby(collection: any, property: string) {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if (!collection) {
      return null;
    }

    const groupedCollection = collection.reduce((previous, current) => {
      if (!previous[current[property]]) {
        previous[current[property]] = [current];
      } else {
        previous[current[property]].push(current);
      }

      return previous;
    }, {});

    // this will return an array of objects, each object containing a group of objects
    return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
  }

}
