import { Injectable } from '@angular/core';

/**
 * Author : O3184
 */
@Injectable()
export class AppUser{
    userId : string;
    firstName:string;
    lastName:string;
    emailAddress:string;
    title:string;
    userType:string;
    groups:any;
    fullName:string;
    //all security groups from LDAP & ADFS
    allGroups:Object;
    // groups specific to Application
    appTokens : Object;

    //This method is to demontrate the client side authentication process.
    //Everyone in technology should have a group - 'CSX-Technology' ,for RailAppDemo purposes user is authenticated if this group is found in allGroups.
    isAuthenticated(){
        var authenticated = false;

        //1. Authenticate using Hardcoded way
        /**if((this.allGroups) && this.allGroups['CSX-Technology'] === 'CSX-Technology') {
         **    authenticated = true;
         **}
         **/

        //2. Authenticate using groups listed in Properties file.
        for(var i1 in this.appTokens){
          var appToken = this.appTokens[i1];
          for(var i2 in this.allGroups){
               if(appToken ===  this.allGroups[i2]){
                    authenticated =  true;
                    break;
               }
            }
        }
        return authenticated;
    }

    public toString = () : string => {
        return '[UserId : '+(this.userId) + '], [Name : '+this.fullName +
            '], [User Tokens : '+JSON.stringify(this.allGroups) +'] , [App Tokens : '+JSON.stringify(this.appTokens) +']';
    }

}
