import {
  AccordionModule,
  ButtonModule,
  CalendarModule,
  CarouselModule,
  CheckboxModule,
  ConfirmDialogModule,
  DataListModule,
  DataScrollerModule,
  DataTableModule,
  DialogModule,
  DropdownModule,
  InputMaskModule,
  InputTextareaModule,
  MessageModule,
  MultiSelectModule,
  PaginatorModule,
  ProgressBarModule,
  ProgressSpinnerModule,
  SelectButtonModule,
  StepsModule
} from 'primeng/primeng';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AdapterComponent } from './adapter/adapter.component';
import { AdapterService } from './services/adapter.service';
import { AddReferenceComponent } from './reference/add-reference.component';
import { AddjobComponent } from './project_view/addjob/addjob.component';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { ClickOutsideModule } from 'ng4-click-outside';
import { ConditionallyValidateModule } from 'ng-conditionally-validate';
import { Configuration } from './services/dataservice.constants';
import { ConfirmationService } from 'primeng/primeng';
import { CsxHeaderModule } from './shared/csxheader/csxheader.module';
import { DataService } from './services/data.service';
import { DexieService } from './core/dexie.service';
import { DisableControlDirective } from './disable-control.directive';
import { EquipmentDetailsService } from './services/equipment-details.service';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { EventBusService } from './services/eventbus.service';
import { FocusDirective } from './sync/focus.directive';
import { GaugingInfoComponent } from './gauging-info/gauging-info.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { JobContainerComponent } from './job_view/job-container/job-container.component';
import { JobRowComponent } from './job_view/job-row/job-row.component';
import { JobWorkStatusPipe } from './pipes/job-work-status.pipe';
import { LocalStorageService } from './services/local-storage.service';
import { LoggerService } from './services/logger.service';
import { LoginTeamComponent } from './login-team/login-team.component';
import { MainComponent } from './main/main.component';
import { MessagesComponent } from './shared/messages/messages.component';
import { MobileModule } from 'csx-flex-ng';
import { NoProductionReportComponent } from './no-production-report/no-production-report.component';
import { NoProductionRestdayComponent } from './no-production-restday/no-production-restday.component';
import { PlannedActual2Component } from './planned-actual2/planned-actual2.component';
import { ProjectContainerComponent } from './project_view/project-container/project-container.component';
import { ProjectHeaderComponent } from './project_view/project-header/project-header.component';
import { ProjectRowComponent } from './project_view/project-row/project-row.component';
import { ProxyUserComponent } from './proxyuser/proxyuser.component';
import { RailInfoComponent } from './rail-info/rail-info.component';
import { ReferenceComponent } from './reference/reference.component';
import { ReferenceListComponent } from './reference/reference-list.component';
import { ReferencePipe } from './pipes/reference.pipe';
import { ReportFooterComponent } from './report-footer/report-footer.component';
import { ReportHeaderComponent } from './report-header/report-header.component';
import { ReportListComponent } from './job_view/report-list/report-list.component';
import { RoadCrossingComponent } from './road-crossing-view/road-crossing/road-crossing.component';
import { RoadCrossingListComponent } from './road-crossing-view/road-crossing-list/road-crossing-list.component';
import { Routing } from './app.routing';
import { StwHeadersComponent } from './stwheaders/stw-headers.component';
import { StwMaxLengthDirective } from './util/stw-max-length.directive';
import { SubmitConfirmationComponent } from './submit-confirmation/submit-confirmation.component';
import { SurfaceProgressComponent } from './tie-surface/surface-progress/surface-progress.component';
import { SurfacingInfoComponent } from './surfacing-info/surfacing-info.component';
import { SyncComponent } from './sync/sync.component';
import { TieProgressComponent } from './tie-surface/tie-progress/tie-progress.component';
import { TrackUtilWindowsComponent } from './track-util-windows/track-util-windows.component';
import { TrackUtilizationComponent } from './track-utilization/track-utilization.component';
import { TsInfoComponent } from './tie-surface/ts-info/ts-info.component';
import { TsProgressComponent } from './tie-surface/ts-progress/ts-progress.component';
import { UtilService } from './util/util.service';
import { ReportDataService } from './shared/services/report-data.service';
import { WorkStatusPipe } from './pipes/work-status.pipe';
import { TurnoutContainerComponent } from './turnout/turnout-container/turnout-container.component';
import { TurnoutCardComponent } from './turnout/turnout-card/turnout-card.component';
import { TurnoutDialogComponent } from './turnout/turnout-dialog/turnout-dialog.component';
import { TurnoutFormService } from './turnout/turnout-container/turnout-form.service';
import { MaxAllowedCharsDirective } from './shared/directives/max-allowed-chars.directive';
import { ExpansionMeasureComponent } from './shared/components/expansion-measure/expansion-measure.component';
import { DecimalToFractionPipe } from './shared/pipes/decimal-to-fraction.pipe';
import { HomeComponent } from './home/home.component';
import { TdSpeedRestrictionComponent } from './td-speed-restriction/td-speed-restriction.component';
import { GenerateTrackDisturbanceComponent } from './admin/generate-track-disturbance/generate-track-disturbance.component';
import { SwitchTieReportComponent } from './switch-tie-report/switch-tie-report.component';
import { SwitchTieComponent } from './switch-tie-report/switch-tie/switch-tie.component';
import { SwitchTieCardComponent } from './switch-tie-report/switch-tie/switch-tie-card/switch-tie-card.component';
import { SwitchTieSurfacingComponent } from './switch-tie-report/switch-tie/switch-tie-surfacing/switch-tie-surfacing.component';
import { RailReportEditComponent } from './rail-info/rail-report-edit/rail-report-edit.component';
import { PlannedActualEditComponent } from './planned-actual2/planned-actual-edit/planned-actual-edit.component';
import { ReportNavigationService } from './shared/services/report-navigation.service';
import { RoadCrossingEditComponent } from './road-crossing-view/road-crossing/road-crossing-edit/road-crossing-edit.component';
import { TrackUtilizationEditComponent } from './track-utilization/track-utilization-edit/track-utilization-edit.component';
import { SurfacingReportEditComponent } from './surfacing-info/surfacing-report-edit/surfacing-report-edit-component';
import { TurnoutContainerEditComponent } from './turnout/turnout-container/turnout-container-edit/turnout-container-edit.component';
import { GaugingInfoEditComponent } from './gauging-info/gauging-info-edit/gauging-info-edit.component';
import { NoProductionRestdayEditComponent } from './no-production-restday/no-production-restday-edit/no-production-restday-edit.component';
import { NoProductionReportEditComponent } from './no-production-report/no-production-report-edit/no-production-report-edit.component';
import { SwitchTieReportEditComponent } from './switch-tie-report/switch-tie-report-edit/switch-tie-report-edit-component';
import { TsInfoEditComponent } from './tie-surface/ts-info/ts-info-edit/ts-info-edit.component';
import { NotesComponent } from './job_view/notes/notes.component';
import { TrackUtilNotesComponent } from './job_view/track-util-notes/track-util-notes.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    Routing,
    ButtonModule,
    StepsModule,
    MobileModule,
    CsxHeaderModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
    ClickOutsideModule,
    InputMaskModule,
    SelectButtonModule,
    AccordionModule,
    ReactiveFormsModule,
    MessageModule,
    DropdownModule,
    InputTextareaModule,
    CalendarModule,
    DataScrollerModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    DataTableModule,
    CheckboxModule,
    CarouselModule,
    DialogModule,
    ConfirmDialogModule,
    ConditionallyValidateModule,
    MultiSelectModule,
    PaginatorModule,
    DataListModule
  ],
  declarations: [
    AppComponent,
    MainComponent,
    AdapterComponent,
    ProjectHeaderComponent,
    JobRowComponent,
    ProjectRowComponent,
    ProjectContainerComponent,
    WorkStatusPipe,
    JobContainerComponent,
    JobWorkStatusPipe,
    ReportListComponent,
    RailInfoComponent,
    StwHeadersComponent,
    MessagesComponent,
    ReportHeaderComponent,
    ReportFooterComponent,
    RoadCrossingComponent,
    TrackUtilWindowsComponent,
    TrackUtilizationComponent,
    RoadCrossingListComponent,
    ReferenceComponent,
    AddReferenceComponent,
    ReferenceListComponent,
    SyncComponent,
    AddjobComponent,
    NoProductionReportComponent,
    ProxyUserComponent,
    SubmitConfirmationComponent,
    CapitalizePipe,
    ReferencePipe,
    FocusDirective,
    ErrorDialogComponent,
    GaugingInfoComponent,
    DisableControlDirective,
    TieProgressComponent,
    TsInfoComponent,
    TsProgressComponent,
    SurfaceProgressComponent,
    SurfacingInfoComponent,
    PlannedActual2Component,
    StwMaxLengthDirective,
    MaxAllowedCharsDirective,
    NoProductionRestdayComponent,
    LoginTeamComponent,
    TurnoutContainerComponent,
    TurnoutCardComponent,
    TurnoutDialogComponent,
    ExpansionMeasureComponent,
    DecimalToFractionPipe,
    HomeComponent,
    TdSpeedRestrictionComponent,
    GenerateTrackDisturbanceComponent,
    SwitchTieReportComponent,
    SwitchTieSurfacingComponent,
    SwitchTieComponent,
    SwitchTieCardComponent,
    RailReportEditComponent,
    PlannedActualEditComponent,
    RoadCrossingEditComponent,
    TrackUtilizationEditComponent,
    GaugingInfoEditComponent,
    NoProductionRestdayEditComponent,
    SurfacingReportEditComponent,
    TurnoutContainerEditComponent,
    NoProductionReportEditComponent,
    GaugingInfoEditComponent,
    SwitchTieReportEditComponent,
    TsInfoEditComponent,
    NotesComponent,
    TrackUtilNotesComponent
  ],
  providers: [
    TurnoutFormService,
    ConfirmationService,
    AdapterService,
    DataService,
    EventBusService,
    LoggerService,
    DexieService,
    LocalStorageService,
    Configuration,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    EquipmentDetailsService,
    ReferencePipe,
    UtilService,
    ReportDataService,
    NoProductionRestdayComponent,
    ReportNavigationService
  ],
  bootstrap: [AppComponent],
  exports: [DisableControlDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
