import {
  Component,
  OnInit,
  AfterContentChecked,
  Input,
  DoCheck,
  HostListener,
  NgZone,
  ChangeDetectorRef,
  ViewEncapsulation,
  ViewChild,
  ElementRef
} from '@angular/core';
import { AppUser } from './app.user';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { MenuItem, OverlayPanel, Menubar } from 'primeng/primeng';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/from';
import { FlexObject, AuthService} from 'csx-flex-ng';

@Component({
  selector: 'csxheader',
  templateUrl: './csxheader.component.html',
  styleUrls: ['./csxheader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CsxheaderComponent implements OnInit, AfterContentChecked, DoCheck {
  @Input() appUser: AppUser;
  @Input() appLongName: string = 'Application';
  @Input() appPageName: string = '';
  @Input() appShortName: string = 'CSX';
  @Input() alert: string = 'csx-common_notification';
  @Input() back: string = 'csx-arrows_chevron_left';
  @Input() csxmenu: string = 'csx-layout_drawer_menu';
  @Input() icon: string = 'csx-logo_csx_notagline';
  @Input() logOut: string = 'csx-arrows_logout';
  @Input() signal: string = 'csx-device_wifi';
  @Input() menuItems: MenuItem[];
  @Input() mobileAppUser: string = 'John Doe';
  @Input() needsBack: boolean = false;
  @Input() needsSignal: boolean = false;
  @Input() needsAlert: boolean = false;
  @Input() minified: boolean = false;
  @Input() showLogoutBtn: boolean = false;
  @Input() showUserRole: boolean = false;
  @Input() showUserRacf: boolean = false;

  alertCount: any = 98;
  isLogin = false;
  isInfo = false;
  isMobile = window.innerWidth < 701;
  isMenuActive = false;
  isAlertCountHigh = false;
  isAlertTooHigh = false;
  menuSub: any;
  onlineStatus = navigator.onLine ? 'app-online' : 'app-offline';
  menuList: any[];
  userName = 'John Doe';
  userRole: string = 'Admin: ';
  racf: string = 'Z9999';
  Flex: FlexObject;

  @ViewChild(OverlayPanel) menu: OverlayPanel;

  constructor(
    private authService: AuthService,
    private _router?: Router,
    public zone?: NgZone,
    public changeDetector?: ChangeDetectorRef,
    private location?: Location,
    private element?: ElementRef

  ) {
    this.Flex = FlexObject.getInstance();
    Observable.from(_router.events)
      .filter(event => event instanceof NavigationEnd)
      .subscribe(val => {
        const path = this.location.path();
        // this.menu.hide();

        if (path === '/login') {
          this.isLogin = true;
        } else if (path === '/info') {
          this.isInfo = true;
          this.isLogin = false;
        } else {
          this.isLogin = false;
          this.isInfo = false;
        }
        this.appPageName = this.transformPath(path);
        this.changeDetector.markForCheck();
        this.zone.run(() => function() {});
      });
  }

  @HostListener('window:online') onOnline() {
    console.log('App is online');
    if (typeof this.Flex !== 'undefined') {
      this.Flex.online = true;
    }
    this.detectOnlineStatus('app-online');
  }
  @HostListener('window:offline') onOffline() {
    console.log('App is offline');
    if (typeof this.Flex !== 'undefined') {
      this.Flex.online = false;
    }
    this.detectOnlineStatus('app-offline');
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    if (event.target.innerWidth < 701) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  ngOnInit() {
    if (this.menuItems) {
      // setting up menu
      this.menuItems.push({ label: 'Logout', routerLink: ['/login'] });
      this.menuList = [{ label: this.userName }];
      for (let i = 0; i < this.menuItems.length; i++) {
        this.menuList.push(this.menuItems[i]);
      }
    }
  }

  ngAfterContentChecked() {
    // Submenu check
    this.menuSub = this.element.nativeElement.querySelector('.ui-menu-list');
  }

  ngDoCheck() {
    if (typeof this.Flex !== 'undefined' && this.Flex.user.id !== this.userName) {
      this.userName = this.Flex.user.firstName + ' ' + this.Flex.user.lastName + ' (' + this.Flex.user.id + ')';
      this.changeDetector.markForCheck();
      this.zone.run(() => function() {});
    } else {
      this.userName = this.appUser.fullName;
    }
    if (this.alertCount !== '!') {
      this.isAlertCountHigh = this.alertCount > 9 && this.alertCount < 100;
      if (this.alertCount > 99) {
        this.isAlertTooHigh = true;
        this.alertCount = '!';
      } else {
        this.isAlertTooHigh = false;
      }
    }
  }

  logOutClick() {
    if (typeof this.Flex !== 'undefined') {
      this.authService.logout();
    }
  }

  navBack() {
    history.back();
  }

  detectOnlineStatus(className) {
    this.onlineStatus = className;
    this.changeDetector.markForCheck();
    this.zone.run(() => function() {});
  }

  transformPath(value: string): string {
    let n = value.lastIndexOf('/');
    let result = value.substring(n + 1);
    let newValue =
      result
        .replace(/\//g, '')
        .charAt(0)
        .toUpperCase() + result.slice(1);
    return `${newValue}`;
  }
}
