import { Component, OnInit } from '@angular/core';
import { NoProductionReportComponent } from '../no-production-report.component';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { LocalStorageService } from '../../services/local-storage.service';
import { DataService } from '../../services/data.service';
import { UtilService } from '../../util/util.service';
import { ReportDataService } from '../../shared/services/report-data.service';
import { OperationMode } from '../../shared/enums/operation-mode.enum';
import { ReportNavigationService } from '../../shared/services/report-navigation.service';
import { ConfirmationService } from '../../../../node_modules/primeng/primeng';
import { AppConstant } from '../../shared/constant/constants';
import { BaseReportEditMixin } from '../../base-report/base-report-edit-mixin';
import { FormBuilder } from '../../../../node_modules/@angular/forms';

@Component({
  selector: 'app-no-production-report-edit',
  templateUrl: '../no-production-report.component.html',
  styleUrls: ['../no-production-report.component.scss']
})
export class NoProductionReportEditComponent extends BaseReportEditMixin(NoProductionReportComponent) implements OnInit {
  loading: boolean;
  showSyncComponent: boolean;
  reasonForEdit: string;
  reasonError: boolean;
  showErrorComponent = false;
  errorDialogData: any;

  constructor(
    public fb: FormBuilder,
    public ds: DataService,
    public ls: LocalStorageService,
    public router: Router,
    public Activateroute: ActivatedRoute,
    public utilService: UtilService,
    public reportDataServcie: ReportDataService,
    public acknowledgeService: ConfirmationService,
    public reportNavigationService: ReportNavigationService,
    private editConfirmationService: ConfirmationService
  ) {
    super(fb, ds, ls, router, Activateroute, utilService, reportDataServcie, acknowledgeService, reportNavigationService);
  }

  ngOnInit() {

    // For first time on the edit screen, set current report from Reports table in index db as default values for edited report
    // If coming back from planned vs actual page, use report from Edited Reports table in index DB
    const formId = Number(this.activeRoute.snapshot.params.form);
    this.ds.getEditedReportByFormId(formId).then(editedReport => {
      if (!editedReport) {
        this.ds.getReport(formId).then((report: any) => {
          this.ds
            .addEditedReport(report)
            .then(() => console.log('Form ID ' + formId + ' added to edited report table'))
            .catch(error => console.log(error));
          super.ngOnInit();
        });
      } else {
        console.log('Form ID ' + formId + ' already exists in edited report table');
        super.ngOnInit();
      }
    });
  }

  protected setReportType() {
    this.reportObject.reportType = this.utilService.determineReportType(this.reportData);
    console.log('this.reportType', this.reportType);
  }

  get editMode() {
    return this.operationMode === OperationMode.EDIT;
  }

  get isWindowAddRemoveDisabled() {
    return this.reportObject && this.reportObject.reportWindows.length > 0 ? true : false;
  }

  protected getReportMethod() {
    return this.ds.getEditedReportByFormId(Number(this.formId));
  }

  protected checkReportOperationMode() {
    this.operationMode = OperationMode.EDIT;
  }

  protected backIcon() {
    this.navigateToJobScreen();
  }

  protected backButtonNavigate() {
    this.navigateToJobScreen();
  }

  protected submitReport() {
    if (Number(this.formId) < 0) {
      // Edit in case of a submitted report will behave same as create report
      super.submitReportData();
    } else {
      console.log('Save edited synced report', this.reportObj);
      if (navigator.onLine) {
        this.editConfirmationService.confirm({
          key: AppConstant.EDIT_KEY,
          message: 'Are you sure you want to edit this report',
          accept: () => {
            this.loading = true;
            this.ds.submitEditedReport(this.reportObject, this.reportObject.formId,
              AppConstant.NO, this.reasonForEdit)
              .then(res => {
                this.loading = false;
                if (res.statusCode !== 200 || (res.errorNumber && res.errorNumber < 0)) {
                  this.showErrorComponent = true;
                  this.errorDialogData = {
                    dialogHeader: AppConstant.ERROR_HEADING,
                    dialogBody: this.getErrorMessage(res)
                  };
                } else {
                  this.ls.deleteEditedReportByFormId(this.reportObject.formId).then(() => {
                    console.log('deleting this row from indexdb', this.formId);
                  });
                  this.showSyncComponent = true;
                }
              }).catch(e => {
                this.loading = false;
                this.showErrorComponent = true;
                this.errorDialogData = {
                  dialogHeader: AppConstant.ERROR_HEADING,
                  dialogBody: e
                };
              });
          },
          reject: () => {
            this.reasonError = false;
            this.reasonForEdit = null;
          }
        });
      } else {
        this.showErrorComponent = true;
      }
    }
  }

  private getErrorMessage(res: any): string {
    if (res && res.errorName) {
      return res.errorName;
    }
    if (res && res.invocationResult) {
      return res.invocationResult.errorName;
    }
    return null;
  }

  private navigateToJobScreen() {
    this.reportNavigationService.navigateToJobView(this.reportObject.projectId, this.reportObject.formId);
  }

  protected onReasonChange() {
    if (this.reasonError && this.reasonForEdit) {
      this.reasonError = false;
    }
  }

  validate(cd: any) {
    if (!this.reasonForEdit) {
      this.reasonError = true;
      return;
    }
    cd.accept();
  }

  onSyncDialogClose() {
    this.showSyncComponent = false;
  }

  onSyncComplete(isCompleted: boolean): void {
    if (isCompleted) {
      this.showSyncComponent = false;
      this.router.navigate([`/job_view/${this.projectId}`]);
    }
  }

}
