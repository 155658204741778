import { FormBuilder } from '@angular/forms';
import { OperationMode } from './../shared/enums/operation-mode.enum';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import {
  JobTable,
  LocalStorageService,
  ReportTable
} from '../services/local-storage.service';
import { DataService } from '../services/data.service';
import { Subject } from 'rxjs/Subject';
import { UtilService } from '../util/util.service';
import { JobModel } from '../model/job.model';
import { CurrentUserTable } from '../services/local-storage.service';
import { Project } from '../model/project.model';
import { ReportDataService } from '../shared/services/report-data.service';
import { WorkType } from '../enums/work-type.enum';

const START_REST_DAY_ERROR = 'Select From Date.';
const END_REST_DAY_ERROR = 'Select To Date.';
const PLANNED_PROJECT_NOTIFICATION = 'Date selection will display Total Project Units and Days';
const JOB_SUBMITTED = 'S';

@Component({
  selector: 'app-no-production-restday',
  templateUrl: './no-production-restday.component.html',
  styleUrls: ['./no-production-restday.component.scss']
})
export class NoProductionRestdayComponent implements OnInit {
  operationMode: OperationMode = OperationMode.CREATE;
  status: any;
  remarkError: boolean;
  remarkErrorString: string;
  public restDaysFormIdsRange: any = [];
  workday: any;
  totalActualUnits: number;
  daysToCompleteWarningMessage: any;
  unitsInstalled: number;
  teamName: any;
  dateRange: any = [];
  restDayAllowedDate1: string;
  restDayReportInvalid: boolean;
  jobInfo: JobModel;
  projectObject: any = [];
  hideToDate: any;
  fromDate: string;
  projectId: number;
  jobId: number;
  formId: number;
  jobType: string;
  projectData: any;
  reportstatus = false;
  startRestDayDate: string;
  endRestDayDate: string;
  totalPlannedProjectUnits: number;
  totalPlannedProjectDays: number;
  daysToComplete: number;
  unitsRemaining: number;
  noProductionDesc: string;
  startRestDayFieldError: boolean;
  endRestDayFieldError: boolean;
  totalUnitsFieldError: boolean;
  totalDaysFieldError: boolean;
  daysRemainingError: boolean;
  startRestDayErrorString: string;
  endRestDayErrorString: string;
  totalUnitsErrorString: string;
  totalDaysErrorString: string;
  daysRemainingErrorString: string;
  restDayRemarksLength = 200;
  toMaxDate: string;
  isValidationFailure: boolean;
  noProductionCode: string;
  uniquesDates = 0;
  currentDate = new Date();
  parentSubject: Subject<any> = new Subject();
  reportObj: any = {};
  reports: any = [];
  totalProjectDays: any = [];
  addWorkDayandReportCode: any = [];
  object: any = {};
  divisionCode: string;
  subdivCode: string;
  public workTypeCode: string;
  projectReports = [];
  projectInfo: any;
  formSubmitted: Boolean = false;
  pastReport: any;
  totalPlannedProjectDaysPrev = null;
  totalPlannedProjectUnitsPrev = null;
  unitsRemainingPrev = null;
  daysToCompletePrev = null;
  plannedProjectWarningMessage: string;
  firstReportInProject = true;

  @Input()
  reportType: string;
  @Input()
  restDayReport;
  @Input()
  hideJobHeader;

  reportExist: number;

  constructor(
    public fb: FormBuilder,
    public dataService: DataService,
    public localStorage: LocalStorageService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    public utilService: UtilService,
    public reportDataService: ReportDataService
  ) {
  }

  ngOnInit() {
    this.localStorage.getCurrentUser('SYSTEM').then((c: CurrentUserTable) => {
      this.teamName = c.teamName;
    });
    this.loadParam();
    this.loadReport();
    this.restDayReport = true;
    this.reportType = 'Rest Day';
    this.hideJobHeader = true;
    this.hideToDate = true;
  }

  get viewMode() {
    return this.operationMode === OperationMode.VIEW;
  }

  get createMode() {
    return this.operationMode === OperationMode.CREATE;
  }

  getJobInfo() {
    this.dataService.getOneJobFromLocal(Number(this.jobId)).then((job: JobModel) => {
      this.jobInfo = job;
      if (job.isSwitchTieJob) {
        this.workTypeCode = WorkType.SWITCHTIE;
      } else {
        if (job.jobTypeCode === 'TS') {
          this.workTypeCode = WorkType.TIEANDSURFACE;
        } else if (job.jobTypeCode === 'SO') {
          this.workTypeCode = WorkType.SURFACE;
        } else {
          this.workTypeCode = WorkType.RAIL;
        }
      }
      console.log('Job Data', this.jobInfo, this.workTypeCode);
    });
  }

  getAllJobReports() {
    this.localStorage
      .getJobReports(Number(this.jobId))
      .then((reportList: Array<ReportTable>) => {
        // *******  this list is to get the previous report
        if (reportList.length === 0) {
          this.getProjectData();
        } else {
          console.log(' Last Report in Array', reportList[0]);
          const reports =
            reportList[reportList.length - reportList.length].reportObj;
          this.totalPlannedProjectUnits = reports['totalPlannedProjectUnits'];
          this.totalPlannedProjectDays = reports['totalPlannedProjectDays'];
          this.daysToComplete = reports['daysToComplete'];
          this.unitsRemaining = reports['unitsRemaining'];
        }
      })
      .then(() => {
        this.totalActualUnits =
          this.totalPlannedProjectUnits - this.unitsRemaining;
      });
  }

  setFromDate() {
    const nextDay = moment(this.currentDate, 'YYYY-MM-DD').add(1, 'days');
    const frmDate =
      this.currentDate.getFullYear() +
      '-' +
      ('0' + (moment(nextDay).month() + 1)).slice(-2) +
      '-' +
      ('0' + moment(nextDay).date());
    this.fromDate = moment(frmDate).format('YYYY-MM-DD');
    console.log('this.fromDate', this.fromDate);
  }

  loadParam(): void {
    this.activeRoute.params.subscribe(params => {
      this.projectData = params.project;
      if (this.projectData) {
        this.projectId = Number(this.projectData.split('-')[0]);
      }
      this.jobId = params.job;
      this.formId = params.form;
      this.getProjectAndReportsData();
    });
  }

  getProjectAndReportsData(): void {
    this.localStorage
      .getProjectReports(Number(this.projectId))
      .then((reportList: Array<ReportTable>) => {
        for (const p of reportList) {
          const js: any = JSON.parse(JSON.stringify(p.reportObj));
          if (js.status !== 'D') {
            this.firstReportInProject = false;
          }
          this.projectReports.push(js);
        }
        if (this.firstReportInProject) {
          this.plannedProjectWarningMessage = null;
        } else {
          if (!this.startRestDayDate && this.createMode) {
            this.plannedProjectWarningMessage = PLANNED_PROJECT_NOTIFICATION;
          }
        }
      });
    this.localStorage.getProject(this.projectId).then((project) => {
      this.projectInfo = JSON.parse(JSON.stringify(project.projectObj));
    });
  }

  protected checkReportOperationMode() {
    if (this.reportObj.status === 'D') {
      this.operationMode = OperationMode.CREATE;
    } else if (this.reportObj.status === 'S' || this.reportObj.formId > 0) {
      this.operationMode = OperationMode.VIEW;
    }
  }

  protected getReportMethod() {
    return this.dataService.getReport(Number(this.formId));
  }

  // Retrive the reports data's from index DB.
  loadReport() {
    this.getReportMethod().then(response => {
      if (response) {
        this.reportObj = response.reportObj;
        this.checkReportOperationMode();
        if (!this.viewMode) {
          this.populateReport();
          this.setFromDate();
          this.getJobInfo();
          // this.getAllJobReports();
          this.getProjectData();
          this.calculateProjectUnitsAndDays();
        } else if (
          this.reportObj.status === 'S' ||
          this.reportObj.formId > 0
        ) {
          this.populateReport();
        }
      } else {
        this.totalDaysErrorString = '';
        this.startRestDayErrorString = '';
        this.endRestDayErrorString = '';
        this.setFromDate();
        this.getJobInfo();
        // this.getAllJobReports();
        this.getProjectData();
        this.calculateProjectUnitsAndDays();
      }
    })
    .catch(e => {
      this.setFromDate();
      this.getJobInfo();
      // this.getAllJobReports();
      this.getProjectData();
      this.calculateProjectUnitsAndDays();
    });
  }

  getProjectData() {
    // Auto populate fields from TSC - Project grid
    this.projectId = Number(this.projectData.split('-')[0]);
    this.dataService.getOneProjectFromLocal(this.projectId).then(
      (project1: Project) => {
        this.totalActualUnits = project1.totalActualProjectUnits;
        this.unitsRemaining = project1.unitsRemaining;
      },
      err => {
        console.log(err);
      }
    );
  }

  populateReport() {
    if (this.reportObj.startRestDayDate) {
      this.startRestDayDate = moment(this.reportObj.startRestDayDate).format(
        'YYYY-MM-DD'
      );
    } else {
      this.startRestDayDate = null;
    }
    if (this.reportObj.endRestDayDate) {
      this.hideToDate = false;
      this.endRestDayDate = moment(this.reportObj.endRestDayDate).format(
        'YYYY-MM-DD'
      );
    } else {
      this.endRestDayDate = null;
    }
    this.totalPlannedProjectUnits = this.reportObj.totalPlannedProjectUnits;
    this.totalPlannedProjectDays = this.reportObj.totalPlannedProjectDays;
    this.daysToComplete = this.reportObj.daysToComplete;

    this.workday = moment(this.reportObj.workday).format('YYYY-MM-DD');
    this.noProductionCode = this.reportObj.noProductionCode;
    if (this.startRestDayDate < moment(this.currentDate).format('YYYY-MM-DD')) {
      this.hideToDate = true;
    } else {
      this.hideToDate = false;
      this.toMaxDate = this.reportObj.toMaxDate;
    }
    if (this.reportObj.noProductionDesc) {
      this.restDayRemarksLength =
        this.restDayRemarksLength - this.reportObj.noProductionDesc.length;
      this.noProductionDesc = this.reportObj.noProductionDesc;
    }
  }

  // Dynamically calculate 'unitRemaining'.
  async calculateUnitRemaining() {
    this.isValidationFailure = false;
    await this.validateTotalProjectUnits();
  }

  // Dynamically calculate 'daysToComplete'.
  async calDaysToComplete() {
    this.isValidationFailure = false;
    this.daysToCompleteWarningMessage = null;
    console.log('Actual Days to complete', this.daysToComplete);
    // let daysToRemain = this.daysToComplete - this.uniquesDates;
    let daysToRemain;
    daysToRemain = this.totalPlannedProjectDays - this.uniquesDates;
    if (daysToRemain < 0) {
      daysToRemain = 0;
      const pDay = this.daysToComplete ? this.daysToComplete : 0;
      this.daysRemainingErrorString =
        'Reported Days(' +
        this.uniquesDates +
        ') exceeds Planned Days(' +
        pDay +
        ')';
    } else {
      this.daysRemainingErrorString = '';
    }
    this.daysToComplete = daysToRemain;
    await this.validateTotalProjectDays();
  }

  async validateTotalProjectUnits() {
    console.log('calculateUnitRemaining called ');
    if (
      this.totalPlannedProjectUnits === undefined ||
      this.totalPlannedProjectUnits === null
    ) {
      this.totalUnitsErrorString = ' Enter Project Units.';
      this.totalUnitsFieldError = true;
    } else if (this.totalPlannedProjectUnits === 0) {
      this.totalUnitsErrorString = 'Should be > 0.';
      this.totalUnitsFieldError = true;
    } else if (this.totalPlannedProjectUnits < 0) {
      this.totalUnitsErrorString = 'Cannot be a negative number.';
      this.totalUnitsFieldError = true;
    } else if (
      this.totalPlannedProjectUnits !== null &&
      this.totalPlannedProjectUnits.toString().includes('.')
    ) {
      this.totalUnitsErrorString = 'Enter Numeric Value.';
      this.totalUnitsFieldError = true;
    } else {
      this.totalUnitsErrorString = '';
      this.totalUnitsFieldError = false;
    }
  }

  async validateTotalProjectDays() {
    if (
      this.totalPlannedProjectDays === undefined ||
      this.totalPlannedProjectDays === null
    ) {
      this.totalDaysErrorString = ' Enter number of days.';
      this.totalDaysFieldError = true;
    } else if (this.totalPlannedProjectDays === 0) {
      this.totalDaysErrorString = 'Should be > 0.';
      this.totalDaysFieldError = true;
    } else if (this.totalPlannedProjectDays < 0) {
      this.totalDaysErrorString = 'Cannot be a negative number.';
      this.totalDaysFieldError = true;
    } else if (
      this.totalPlannedProjectDays !== null &&
      this.totalPlannedProjectDays.toString().includes('.')
    ) {
      this.totalDaysErrorString = 'Enter Numeric Value.';
      this.totalDaysFieldError = true;
    } else {
      this.totalDaysErrorString = '';
      this.totalDaysFieldError = false;
    }
  }

  validateStartDate() {
    if (this.createMode) {
      if (!this.firstReportInProject && !this.formSubmitted) {
        this.totalPlannedProjectDays = null;
        this.totalPlannedProjectUnits = null;
        this.unitsRemaining = null;
        this.daysToComplete = null;
        this.totalUnitsErrorString = '';
        this.totalUnitsFieldError = false;
      }
      this.isValidationFailure = false;
      this.endRestDayFieldError = false;
      this.endRestDayErrorString = '';
      console.log('Start Rest Day is ', this.startRestDayDate);
      if (
        this.startRestDayDate === undefined ||
        this.startRestDayDate === null ||
        this.startRestDayDate === ''
      ) {
        this.startRestDayFieldError = true;
        this.startRestDayErrorString = START_REST_DAY_ERROR;
        this.plannedProjectWarningMessage = PLANNED_PROJECT_NOTIFICATION;
        this.totalUnitsFieldError = false;
        this.totalUnitsErrorString = '';
        this.totalDaysErrorString = '';
        this.totalDaysFieldError = false;
        this.daysRemainingErrorString = '';
        console.log('Start Rest length is ', this.projectReports.length);
        console.log('Start Rest length is ', this.reportObj.status);

        if (this.projectReports.length <= 1) {
          if (!this.viewMode || this.projectReports.length === 0) {
            this.plannedProjectWarningMessage = null;
          }

        }
      } else {
        this.plannedProjectWarningMessage = null;
        this.startRestDayFieldError = false;
        this.startRestDayErrorString = '';
        const restDayAllowedDate = moment(this.fromDate, 'YYYY-MM-DD').subtract(
          'days',
          1
        );
        this.restDayAllowedDate1 = moment(restDayAllowedDate).format(
          'YYYY-MM-DD'
        );

        if (this.startRestDayDate) {
          this.hideToDate = true;
          if (!this.formSubmitted) {
            this.pastReport = this.reportDataService.getLatestReportBeforePastDay(
              this.teamName, this.formId, this.projectReports, moment(this.startRestDayDate).valueOf());
            if (this.pastReport) {
              this.totalPlannedProjectDays = this.pastReport.totalPlannedProjectDays;
              this.totalPlannedProjectUnits = this.pastReport.totalPlannedProjectUnits;
              this.unitsRemaining = this.pastReport.unitsRemaining;
              console.log(' this.calDayCount() is ', this.daysToComplete);
              this.calDayCount();
            }
          }
          if (this.startRestDayDate >= this.restDayAllowedDate1) {
            const day = moment(this.startRestDayDate, 'YYYY-MM-DD').add(7, 'days');
            console.log('Consecutive 7 days is  ', day);
            const day1 = moment(day).format('YYYY-MM-DD');
            const endDate =
              new Date(day1).getFullYear() +
              '-' +
              ('0' + (new Date(day1).getMonth() + 1)).slice(-2) +
              '-' +
              ('0' + new Date(day1).getDate());
            this.toMaxDate = moment(endDate).format('YYYY-MM-DD');
            this.hideToDate = false;
          }
        }
        if (this.startRestDayDate) {
          this.isReportExist(this.addWorkDayandReportCode, this.startRestDayDate);
        }
      }
    } else {
      this.startRestDayErrorString = '';
    }
  }

  convertEpochToDate(epoch: number) {
    if (epoch == null) {
      return null;
    }
    let date = new Date(epoch).toLocaleDateString().replace(/\//g, '-');
    const dateArray = date.split('-');
    date =
      dateArray[2] +
      '-' +
      this.convertSingleDigitToDouble(dateArray[0]) +
      '-' +
      this.convertSingleDigitToDouble(dateArray[1]);
    return date;
  }

  convertSingleDigitToDouble(value: string): string {
    if (value.length === 1) {
      return '0' + value;
    } else {
      return value;
    }
  }

  validateEndDate() {
    if (this.createMode) {
      this.isValidationFailure = false;
      console.log('End Rest Day is ', this.endRestDayDate);
      if (this.startRestDayDate < this.restDayAllowedDate1) {
        this.endRestDayFieldError = false;
        this.endRestDayErrorString = '';
      } else if (
        this.endRestDayDate === undefined ||
        this.endRestDayDate === null ||
        this.endRestDayDate === ''
      ) {
        this.endRestDayFieldError = true;
        this.endRestDayErrorString = END_REST_DAY_ERROR;
      } else {
        this.endRestDayFieldError = false;
        this.endRestDayErrorString = '';
        this.calculateProjectUnitsAndDays();
        if (this.startRestDayDate && this.endRestDayDate) {
          this.isReportExistInRange(this.addWorkDayandReportCode);
        }
      }
    } else {
      this.endRestDayErrorString = '';
    }
  }

  async calculateProjectUnitsAndDays() {
    await this.localStorage
      .getProjectJobs(Number(this.projectId))
      .then((jobList: Array<JobTable>) => {
        console.log('job data ', jobList);
        console.log(jobList);
        jobList.forEach((app, index) => {
          this.localStorage
            .getJobReports(app.jobId)
            .then((reportList: Array<ReportTable>) => {
              if (reportList.length > 0) {
                for (const p of reportList) {
                  const js: any = JSON.parse(JSON.stringify(p.reportObj));
                  // ******* by using reportstatus we are checking all reports submitted or not
                  if (
                    js.status === JOB_SUBMITTED ||
                    (js.formId > 0 && this.teamName === js.teamName)
                  ) {
                    if (js.workday > 1) {
                      // tslint:disable-next-line:no-unused-expression
                      if (js.noProductionCode !== 'RST') {
                        this.totalProjectDays.push(js.workday);
                      }
                      this.addWorkDayandReportCode.push({
                        workday: js.workday,
                        noprod: js.noProductionCode
                      });
                      console.log('total Dates', this.totalProjectDays);
                      console.log(
                        'Work day and No Production code',
                        this.addWorkDayandReportCode
                      );
                    }
                  }
                }
                this.calDayCount();
                this.totalDaysFieldError = false;
                this.totalDaysErrorString = '';
              }
            });
        });
      });
  }

  // Calculate Project Days based on submitted reports
  calDayCount() {
    const uDates = [];
    for (let i = 0; i < this.totalProjectDays.length; i++) {
      if (!this.isDateInArray(new Date(this.totalProjectDays[i]), uDates) &&
        this.totalProjectDays[i] < moment(this.startRestDayDate).valueOf()) {
          uDates.push(new Date(this.totalProjectDays[i]));
      }
    }
    this.uniquesDates = uDates.length;
    this.calDaysToComplete();
  }

  isReportExist(workDayAndCode: any, date1: any) {
    let productionReportCount = 0;
    let noProductionReportCount = 0;

    let restDayReportCount = 0;

    for (let i = 0; i < workDayAndCode.length; i++) {
      console.log(
        '(workDayAndCode[i].workday.getDate()',
        moment(workDayAndCode[i].workday).format('YYYY-MM-DD')
      );
      if (moment(workDayAndCode[i].workday).format('YYYY-MM-DD') === date1) {
        if (workDayAndCode[i].noprod === null) {
          productionReportCount++;
          this.reportExist++;
        } else if (
          workDayAndCode[i].noprod !== null &&
          workDayAndCode[i].noprod !== 'RST'
        ) {
          noProductionReportCount++;
          this.reportExist++;
        } else if (
          workDayAndCode[i].noprod !== null &&
          workDayAndCode[i].noprod === 'RST'
        ) {
          restDayReportCount++;
          this.reportExist++;
        }
      }
    }

    if (productionReportCount > 0 && noProductionReportCount > 0) {
      this.startRestDayErrorString = 'Production / No Production report exist';
      this.startRestDayFieldError = true;
    } else if (productionReportCount > 0 && noProductionReportCount === 0) {
      this.startRestDayErrorString = 'Production report exist';
      this.startRestDayFieldError = true;
    } else if (productionReportCount === 0 && noProductionReportCount > 0) {
      this.startRestDayErrorString = 'No Prod Non rest report exist';
      this.startRestDayFieldError = true;
    } else if (
      productionReportCount === 0 &&
      noProductionReportCount === 0 &&
      restDayReportCount > 0
    ) {
      this.startRestDayErrorString = 'Rest Day report exist';
      this.startRestDayFieldError = true;
    } else {
      this.startRestDayErrorString = '';
      this.startRestDayFieldError = false;
    }
  }

  isReportExistInRange(workDayAndCode: any) {
    this.setConsecutiveDaysReports();
    this.reportExist = 0;
    for (let i = 0; i <= this.dateRange.length; i++) {
      this.isReportExist(workDayAndCode, this.dateRange[i]);
    }
    if (this.reportExist > 0) {
      this.endRestDayErrorString =
        ' Report Overlap between ( ' +
        this.startRestDayDate +
        ' - ' +
        this.endRestDayDate +
        ')';
      this.endRestDayFieldError = true;
    } else {
      this.endRestDayErrorString = '';
      this.endRestDayFieldError = false;
    }
  }

  async formSubmit($event) {
    this.formSubmitted = true;
    if (!this.validateAll()) {
      if (!this.isValidationFailure) {
        this.handleFormSubmission();
      } else {
        this.isValidationFailure = true;
      }
    } else {
      this.isValidationFailure = true;
      this.formSubmitted = false;
    }
  }

  protected handleFormSubmission() {
    this.setConsecutiveDaysReports();
    this.getFormIDs();
    this.parentSubject.next('form valid');
  }

  validateAll() {
    this.validateType();
    this.validateStartDate();
    this.validateEndDate();
    this.restDayRemarksCount(this.noProductionDesc);
    this.calculateUnitRemaining();
    this.calDayCount();
    if (
      this.totalDaysErrorString === '' &&
      this.startRestDayErrorString === '' &&
      this.endRestDayErrorString === '' &&
      this.totalUnitsErrorString === ''
    ) {
      return false;
    } else {
      return true;
    }
  }

  async getFormIDs() {
    // Remove Draft record from index DB.
    if (this.reportObj.status === 'D') {
      this.dataService.deleteReport(Number(this.formId)).then(response => {
        console.log('deleting this row from indexdb', this.formId);
      });
    }
    this.dataService.getDraftFormIdForRestDay().then(async value => {
      let flag = false;
      let formId: number;
      for (let i = 0; i < this.dateRange.length; i++) {
        console.log(value);
        if (value.length === 0 && !flag) {
          formId = -1;
          console.log('formid ::-1');
          flag = true;
        } else if (flag) {
          --formId;
        } else {
          formId = --value[0].formId;
          console.log('formid::', formId);
        }
        this.restDaysFormIdsRange.push(-Math.abs(formId));
        this.reportObj['formId'] = -Math.abs(formId);
        this.reportObj['workday'] = moment(this.dateRange[i]).valueOf();
        this.setReportDataAndSubmit();
        await this.submitReportData(this.reportObj);
      }
    });
  }

  protected setReportDataAndSubmit() {
    this.reportObj['jobId'] = Number(this.jobId);
    this.reportObj['projectId'] = this.projectId;
    this.reportObj['totalPlannedProjectUnits'] = this.totalPlannedProjectUnits;
    this.reportObj['totalPlannedProjectDays'] = this.totalPlannedProjectDays;
    this.reportObj['daysToComplete'] = this.daysToComplete;
    this.reportObj['noProductionDesc'] = this.noProductionDesc;
    this.reportObj['noProductionCode'] = 'RST';
    this.reportObj['teamName'] = this.teamName;
    this.reportObj['jobType'] = this.jobInfo.jobTypeCode;
    this.reportObj['divisionCode'] = this.jobInfo.division;
    this.reportObj['subdivCode'] = this.jobInfo.subdivision;
    this.reportObj['workTypeCode'] = this.workTypeCode;
    this.reportObj['startRestDayDate'] = this.startRestDayDate;
    this.reportObj['endRestDayDate'] = this.endRestDayDate;
    this.reportObj['unitsRemaining'] = this.unitsRemaining;
    if (this.isPastDayReport()) {
      if (this.pastReport) {
        this.reportObj['plannedCrossingCount'] = this.pastReport.plannedCrossingCount;
      } else {
        this.reportObj['plannedCrossingCount'] = this.projectInfo.initialPlannedCrossing;
      }
    } else {
      this.reportObj['plannedCrossingCount'] = this.projectInfo.plannedCrossingCount;
    }
    if (this.workTypeCode === WorkType.TIEANDSURFACE || this.workTypeCode === WorkType.SWITCHTIE) {
      this.reportObj['totalUnitInstalled'] = 0;
    } else if (this.workTypeCode === WorkType.SURFACE) {
      this.reportObj['totalSurfacedFeet'] = 0;
    } else {
      this.reportObj['railLength'] = 0;
    }
  }

  setConsecutiveDaysReports() {
    this.dateRange = [];
    let current;
    if (this.startRestDayDate && this.endRestDayDate) {
      current = moment(this.startRestDayDate);
      while (current <= moment(this.endRestDayDate)) {
        this.dateRange.push(moment(current).format('YYYY-MM-DD'));
        current = moment(current).add(1, 'days');
      }
      console.log('Date are', this.dateRange);
    } else {
      current = moment(this.startRestDayDate);
      this.dateRange.push(moment(current).format('YYYY-MM-DD'));
    }
  }

  submitReportData(reportObj: any) {
    console.log('Report Data', JSON.stringify(reportObj));
    if (!this.isPastDayReport() || !this.reportExistsAfterWorkDay()) {
      reportObj = this.utilService.updateProjectPlannedValues(
        reportObj,
        this.projectId,
        true
      );
    }
    console.log('reports ******', reportObj);
    this.dataService.submitReport(JSON.stringify(reportObj))
      .then(response => {
        console.log(response);
      })
      .catch(reason => {
        console.log(reason);
      });
  }

  private isPastDayReport(): boolean {
    const yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    yesterdayDate.setHours(23, 59, 59, 999);
    return moment(this.startRestDayDate).valueOf() <= yesterdayDate.getTime();
  }

  private reportExistsAfterWorkDay(): boolean {
    let futureDayReportExist = false;
    this.projectReports.forEach(report => {
      if (report.teamName === this.teamName &&
        report.status !== 'D' &&
        report.formId.toString() !== this.formId.toString()
      ) {
        if (this.convertEpochToDate(report.workday) !== this.convertEpochToDate(moment(this.startRestDayDate).valueOf()) &&
          report.workday > moment(this.startRestDayDate).valueOf()) {
          futureDayReportExist = true;
          return;
        }
      }
    });
    console.log('future report exist : ' + futureDayReportExist);
    return futureDayReportExist;
  }

  backClick(event) {
    if (this.viewMode) {
      this.router.navigate([`/job_view/${this.projectId}`]);
    } else {
      const reportObj: any = {};
      this.setDraftValues(reportObj);
      this.dataService
        .saveDraftReport(JSON.stringify(reportObj))
        .then(response => {
          this.router.navigate([`/job_view/${this.projectId}`]);
        })
        .catch(reason => {
          console.log(reason);
        });
    }
  }

  setDraftValues(reportObj: any) {
    reportObj['formId'] = Number(this.formId);
    reportObj['jobId'] = Number(this.jobId);
    reportObj['projectId'] = this.projectId;
    reportObj['noProductionCode'] = 'RST';
    reportObj['workday'] = moment(this.startRestDayDate).valueOf();
    reportObj['startRestDayDate'] = this.startRestDayDate;
    reportObj['endRestDayDate'] = this.endRestDayDate;
    reportObj['totalPlannedProjectUnits'] = this.totalPlannedProjectUnits;
    reportObj['totalPlannedProjectDays'] = this.totalPlannedProjectDays;
    reportObj['daysToComplete'] = this.daysToComplete;
    reportObj['noProductionDesc'] = this.noProductionDesc;
    reportObj['teamName'] = this.teamName;
    reportObj['unitsRemaining'] = this.unitsRemaining;
    if (this.workTypeCode === WorkType.TIEANDSURFACE || this.workTypeCode === WorkType.SWITCHTIE) {
      reportObj['totalUnitInstalled'] = 0;
    } else if (this.workTypeCode === WorkType.SURFACE) {
      reportObj['totalSurfacedFeet'] = 0;
    } else {
      reportObj['railLength'] = 0;
    }
    reportObj['toMaxDate'] = this.toMaxDate;
  }

  validateType() {
    if (this.reportType === null || this.reportType === undefined) {
      this.restDayReportInvalid = true;
    } else {
      this.restDayReportInvalid = false;
    }
  }

  restDayRemarksCount(text): void {
    this.isValidationFailure = false;
    if (text) {
      this.restDayRemarksLength = 200 - text.length;
    }
  }

  isDateInArray(list, key): boolean {
    console.log('isDateInArray ', list, key);
    for (let i = 0; i < key.length; i++) {
      if (
        list.getDate() === key[i].getDate() &&
        list.getMonth() === key[i].getMonth()
      ) {
        return true;
      }
    }
    return false;
  }

  passData() {
    console.log('this.restDaysFormIdsRange', this.restDaysFormIdsRange);
    return this.restDaysFormIdsRange;
  }
}
