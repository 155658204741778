import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { ReportDataService } from '../../shared/services/report-data.service';
import { UtilService } from '../../util/util.service';
import { SurfacingInfoComponent } from '../surfacing-info.component';
import { BaseReportEditMixin } from '../../base-report/base-report-edit-mixin';
import { ConfirmationService } from 'primeng/primeng';
import { ReportNavigationService } from './../../shared/services/report-navigation.service';
import { OperationMode } from '../../shared/enums/operation-mode.enum';

@Component({
  selector: 'app-surfacing-report-edit',
  templateUrl: '../surfacing-info.component.html',
  styleUrls: ['../surfacing-info.component.scss']
})
export class SurfacingReportEditComponent extends BaseReportEditMixin(SurfacingInfoComponent) {
  constructor(
    public formBuilder: FormBuilder,
    public ds: DataService,
    public ls: LocalStorageService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    public utilService: UtilService,
    public reportDataService: ReportDataService,
    public acknowledgeService: ConfirmationService,
    public reportNavigationService: ReportNavigationService
  ) {
    super(formBuilder, ds, ls, router, activeRoute, utilService, reportDataService, acknowledgeService, reportNavigationService);
  }
  get editMode() {
    return this.operationMode === OperationMode.EDIT;
  }
  protected getTdValue() {
    return this.tdFormGroup.get('speedRestrictions').getRawValue();
  }
}
