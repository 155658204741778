import { Component, OnInit, ViewChild } from '@angular/core';
import { RoadCrossingListComponent } from '../road-crossing-list/road-crossing-list.component';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  JobTable,
  LocalStorageService,
  ReferenceCode,
  ReportTable,
  RoadCrossingTable
} from '../../services/local-storage.service';
import { SelectItem } from 'primeng/primeng';
import { RoadCrossingTrack } from '../../model/road-crossing-track.model';
import { AppConstant } from '../../shared/constant/constants';
import { OperationMode } from '../../shared/enums/operation-mode.enum';

@Component({
  selector: 'app-road-crossing',
  templateUrl: './road-crossing.component.html',
  styleUrls: ['./road-crossing.component.scss']
})
export class RoadCrossingComponent implements OnInit {
  @ViewChild(RoadCrossingListComponent)
  listComponent: RoadCrossingListComponent;
  operationMode: OperationMode = OperationMode.CREATE;
  errmsg: string;
  workTypeCode: string;
  formId: number;
  projectId: number;
  jobId: number;
  reportData: any;
  Rail = [];
  crossingSurfacedOptions = [];
  trackType = [];
  Material = [];
  surfaceType = [];
  isRailInstalled = true;
  isMaterialReUsed = false;
  crossLengthMsg = [];
  trackErrMsg = [];
  errmsgTs: string;
  specificationMsg = [];
  isDialogOpen = false;
  selectedList: any[] = [];
  isFormInValid: boolean;
  reportObj: object = {};
  CrossingData: any[] = [];
  crossingSurfacedFlag: boolean;
  crossingTimberedFlag: boolean;
  tieErrMsg = [];
  tieMessage: Array<string[]> = [];
  crossingLength: boolean;
  surfacedErrMsg = [];
  surfaceErrMessage = [];
  tieCount: number;
  surfaceCount: number;
  tsCrossing: boolean;
  numCrossingTiesInstalled: number;
  isCrossingSurfaced: boolean;
  isNoTiesInstalledVisible: boolean;
  isSurfacedVisible: boolean;
  isRailInstalledVisible: boolean;
  trackMap: Map<String, String[]> = new Map<String, String[]>();
  crossingLengthMessage: Array<string[]> = [];
  trackSelectedErrMsg: Array<boolean[]> = [];
  surfaceYesError: boolean;

  constructor(
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected dataService: DataService,
    protected ls: LocalStorageService
  ) {
    this.Rail = [{ label: 'Yes', value: true }, { label: 'No', value: false }];

    this.Material = [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ];

    this.crossingSurfacedOptions = [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ];
  }

  ngOnInit() {
    this.activeRoute.params.subscribe(params => {
      this.projectId = Number(params.project);
      this.jobId = Number(params.job);
      this.formId = Number(params.form);
    });

    if (this.projectId !== null && this.projectId !== 0) {
      this.ls
        .getJobRoadCrossings(this.projectId)
        .then((cList: Array<RoadCrossingTable>) => {
          this.CrossingData = cList;
          this.trackList(this.CrossingData);
        });

        this.getReportMethod().then(
        (res: ReportTable) => {
          this.reportData = res.reportObj;

          this.checkReportOperationMode();

          this.workTypeCode = this.reportData.workTypeCode;
          if (this.workTypeCode === AppConstant.WORKTYPECODE.TIE_SURFACING
            || this.workTypeCode === AppConstant.WORKTYPECODE.SWITCHTIES) {
            this.isNoTiesInstalledVisible = true;
          }
          if (this.workTypeCode === AppConstant.WORKTYPECODE.TIE_SURFACING
            || this.workTypeCode === AppConstant.WORKTYPECODE.SURFACING
            || this.workTypeCode === AppConstant.WORKTYPECODE.SWITCHTIES) {
            this.isSurfacedVisible = true;
          } else {
            this.isRailInstalledVisible = true;
          }
          if (
            this.reportData &&
            this.reportData.reportRoadCrossingList &&
            this.reportData.reportRoadCrossingList.length
          ) {
            for (const rl of this.reportData.reportRoadCrossingList) {
              rl.rxObj = {};
              this.ls
                .getRoadCrossingById([rl.dotId, this.projectId])
                .then((rs: RoadCrossingTable) => {
                  rl.rxObj = rs.rxObj;
                  this.trackList(rl);
                });
              this.addErrorArrays();
            }
            this.tieAndSurfaceCheck();
            if (this.tieCount > 0) {
              this.crossingTimberedFlag = true;
            }
            if (this.surfaceCount > 0) {
              this.crossingSurfacedFlag = true;
            }
            this.selectedList = this.reportData.reportRoadCrossingList;

            this.crossingSurfacedFlag = this.reportData.crossingSurfaced;
            this.crossingTimberedFlag = this.reportData.crossingTiesTimbered;
            this.listComponent.crossingData.map(obj => {
              this.selectedList.map(sel => {
                obj['dotNumber'] === sel['dotNumber']
                  ? (sel['selected'] = ['val1'])
                  : (sel['selected'] = undefined);
              });
            });
          }
        },
        error => {
          console.log('Error while getting report new', error);
        }
      );
      // this.dataService.getRefData('XSR, TRK')
      this.ls.getAllRefereces().then((refs: Array<ReferenceCode>) => {
        const refList = refs;
        for (const p of refList) {
          if (p.refType === 'XSR') {
            this.surfaceType = this.hack(p.refObj);
            this.surfaceType.unshift({ label: 'None', value: '0' });
            const otherOptionIndex = this.surfaceType.findIndex(obj => obj.label === 'Other');
            if (otherOptionIndex > -1) {
              const otherOptionObject = this.surfaceType.splice(otherOptionIndex, 1);
              this.surfaceType = this.surfaceType.concat(otherOptionObject);
            }
            if (!this.viewMode) {
              this.surfaceType = this.surfaceType.filter(surfaceType => {
                if (surfaceType.label !== AppConstant.SURFACE_TYPE.TIMBER &&
                  surfaceType.label !== AppConstant.SURFACE_TYPE.RUBBER) {
                  return surfaceType;
                }
              });
            }
          }
        }
      });
    }
  }

  protected getReportMethod() {
    return this.dataService.getReport(Number(this.formId));
  }


  get viewMode() {
    return this.operationMode === OperationMode.VIEW;
  }

  protected checkReportOperationMode() {
    if (this.reportData.status === 'S' || this.reportData.formId > 0) {
       this.operationMode = OperationMode.VIEW;
    } else if (this.reportData.status === 'D') {
      this.operationMode = OperationMode.CREATE;
    }
  }


  tieAndSurfaceCheck() {
    if (this.workTypeCode === AppConstant.WORKTYPECODE.TIE_SURFACING) {
      const tieProgress = Object.keys(this.reportData.tsProgress.tieProgress).map(
        i => this.reportData.tsProgress.tieProgress[i]
      );
      const surfaceProgress = Object.keys(
        this.reportData.tsProgress.surfaceProgress
      ).map(i => this.reportData.tsProgress.surfaceProgress[i]);
      this.tieCount = 0;
      this.surfaceCount = 0;
      for (let i = 0; i < tieProgress.length; i++) {
        if (tieProgress[i]['crossingTiesTimbered'] === true) {
          this.tieCount++;
          console.log('count', this.tieCount);
        }
      }
      for (let i = 0; i < surfaceProgress.length; i++) {
        if (surfaceProgress[i]['crossingSurfaced'] === true) {
          this.surfaceCount++;
          console.log('count', this.surfaceCount);
        }
      }
    }
  }
  switchTieAndSurfaceCheck() {
    if (this.workTypeCode === AppConstant.WORKTYPECODE.SWITCHTIES) {
      this.surfaceCount = 0;
      const surfacing = Object.keys(this.reportData.surfacing).map(i => this.reportData.surfacing);
      surfacing.forEach((surface) => {
        surface.forEach(s => {
          if (s.crossingSurfaced === true) {
            this.surfaceCount++;
          }
        });
      });
    }
  }

  hack(json: any): SelectItem[] {
    const returnSel: SelectItem[] = [];
    Object.keys(json).forEach(key => {
      const r: SelectItem = {
        label: json[key],
        value: key
      };
      returnSel.push(r);
    });
    return returnSel;
  }

  public validateCrossingSurface(surfaced: boolean, i: number, j: number) {
    this.tieAndSurfaceCheck();
    this.switchTieAndSurfaceCheck();
    if (this.surfaceCount > 0) {
      if (surfaced == null) {
        this.surfacedErrMsg[i][j] = true;
        this.surfaceErrMessage[i][j] = 'Make a Selection';
      } else {
        this.surfacedErrMsg[i][j] = false;
      }
    } else {
      this.surfacedErrMsg[i][j] = false;
    }
    this.formValidation();
  }

  public validateTiesInstalled(ties: any, i: number, j: number) {
    this.tieAndSurfaceCheck();
    if (this.tieCount > 0) {
      if (
        // tslint:disable-next-line:radix
        (!ties && ties === null && isNaN(Number.parseInt(ties))) ||
        ties <= 0
      ) {
        this.tieErrMsg[i][j] = true;
        this.tieMessage[i][j] = 'Enter Ties Installed';
      } else if (ties.toString().includes('.')) {
        this.tieErrMsg[i][j] = true;
        this.tieMessage[i][j] = 'Enter Numeric Value';
      } else {
        this.tieErrMsg[i][j] = false;
      }
      this.formValidation();
    }
  }

  public validateTrack(track: any, i: number, j: number) {
    if (!track || track === null || track === '') {
      this.trackErrMsg[i][j] = true;
    } else {
      this.trackErrMsg[i][j] = false;
    }
    for (let index = this.selectedList[i].roadCrossingTrackList.length - 1; index >= 0; index--) {
      const selectedTrack: RoadCrossingTrack = this.selectedList[i].roadCrossingTrackList[index];
      if (index !== j && selectedTrack.trackType && selectedTrack.trackType === track) {
        this.trackSelectedErrMsg[i][j] = true;
      } else {
        this.trackSelectedErrMsg[i][j] = false;
      }
    }

    this.formValidation();
  }

  public validateLength(crosslen: any, i: number, j: number) {
    if (crosslen == null) {
      this.crossLengthMsg[i][j] = true;
      this.crossingLengthMessage[i][j] = 'Enter Crossing Length';
    } else if (crosslen.toString().includes('.')) {
      this.crossLengthMsg[i][j] = true;
      this.crossingLengthMessage[i][j] = 'Enter Numeric Value';
    } else if (crosslen > 2500) {
      this.crossLengthMsg[i][j] = true;
      this.crossingLengthMessage[i][j] = 'Cannot be > 2500 ft';
    } else if (crosslen <= 0) {
      this.crossLengthMsg[i][j] = true;
      this.crossingLengthMessage[i][j] = 'Should be > 0';
    } else {
      this.crossLengthMsg[i][j] = false;
    }
    this.formValidation();
  }

  public validateSpecification(surface, specification, i) {
    if (surface) {
      if (Number(surface) === this.surfaceType.length - 1) {
        if (!specification) {
          this.specificationMsg[i] = true;
        } else {
          this.specificationMsg[i] = false;
        }
      } else {
        this.specificationMsg[i] = false;
      }
    } else {
      this.specificationMsg[i] = true;
    }
    this.formValidation();
  }

  resetFilter() {
    this.ls.getProjectJobs(this.projectId).then((jobList: Array<JobTable>) => {
      jobList.map((obj, i) => {
        if (obj.jobId === this.jobId) {
          this.listComponent.searchPrefix = obj.jobObj['prefix'];
          this.listComponent.searchStartMP = obj.jobObj['startMP'];
          this.listComponent.searchEndMP = obj.jobObj['endMP'];
        }
      });
    });
    this.listComponent.filterData();
    this.listComponent.startErrMsg = '';
    this.listComponent.endErrMsg = '';
  }

  toggleDialog() {
    this.resetFilter();
    this.isDialogOpen = !this.isDialogOpen;
    this.listComponent.roadcrossingSelected = false;
    this.listComponent.crossingData.map(item1 => {
      item1.selected = false;
    });
  }

  enableApply() {
    return this.listComponent.roadcrossingSelected;
  }

  trackList(object) {
    if (object.rxObj) {
      const tracksLength = Object.keys(object.rxObj.tracks).length;
      object.roadCrossingId = object.roadCrossingId !== undefined ? object.roadCrossingId : object.dotId;
      if (tracksLength > 0 && tracksLength !== null && tracksLength !== undefined) {
        this.trackType = [];
        for (let i = 0; i < tracksLength; i++) {
          this.trackType.push({
            label: object.rxObj.tracks[i].track,
            value: object.rxObj.tracks[i].track
          });
          this.trackMap.set(object.roadCrossingId, this.trackType);
        }
      }
    }
  }

  addRoadCrossingTrackRow(roadCrossingTrackList: RoadCrossingTrack[], roadCrossingId?: string): void {
    const roadCrossingTrack = {} as RoadCrossingTrack;
    if (roadCrossingId && this.trackMap.get(roadCrossingId).length === 1) {
      roadCrossingTrack.trackType = (this.trackMap.get(roadCrossingId)[0]['value']).toString();
    }
    roadCrossingTrack.crossingSurfaceType = this.surfaceType[0].value;
    roadCrossingTrack.isMaterialReUsed = false;
    roadCrossingTrack.crossingLength = null;
    if (this.workTypeCode === AppConstant.WORKTYPECODE.TIE_SURFACING
      || this.workTypeCode === AppConstant.WORKTYPECODE.SWITCHTIES) {
      roadCrossingTrack.isCrossingSurfaced = null;
      roadCrossingTrack.numCrossingTiesInstalled = null;
    } else if (this.workTypeCode === AppConstant.WORKTYPECODE.SURFACING) {
      roadCrossingTrack.isCrossingSurfaced = null;
    } else {
      roadCrossingTrack.isRailInstalled = true;
    }
    roadCrossingTrackList.push(roadCrossingTrack);
  }

  applyList() {
    this.tsCrossing = false;
    const selectItems = this.selectedList;
    this.selectedList = [];
    this.listComponent.roadcrossingSelected = false;
    this.listComponent.crossingData.map(item => {
      if (item.selected) {
        this.trackList(item);
        item.roadCrossingTrackList = [] as RoadCrossingTrack[];
        this.addRoadCrossingTrackRow(item.roadCrossingTrackList, item.roadCrossingId);
        this.addErrorArrays();
        item.otherSpecification = '';
        this.selectedList.push(item);
        this.selectedList.map(obj => {
          return (obj.trackType = '');
        });
        this.specificationMsg = [];
        this.isFormInValid = false;
      }
    });
    this.listComponent.crossingData = this.listComponent.crossingData.filter(
      obj => !obj.selected
    );
    this.selectedList = this.selectedList.concat(selectItems);
    this.toggleDialog();
    this.selectedList.map(obj => {
      this.trackList(obj);
    });
  }

  removeItem(index) {
    this.selectedList[index].selected = false;
    this.listComponent.crossingData.push(this.selectedList[index]);
    this.selectedList.splice(index, 1);
    this.dataService
      .saveDraftReport(JSON.stringify(this.reportData))
      .then(response => {
        delete this.reportData['actualCrossingCount'];
        this.reportData.reportRoadCrossingList = this.selectedList.map(
          (item: any, index1: number) => {
            const res = { ...item };
            delete res.dotId;
          }
        );
      })
      .catch(reason => {
        console.log(reason);
      });
    this.specificationMsg.splice(index, 1);
    this.crossLengthMsg.splice(index, 1);
    this.crossingLengthMessage.splice(index, 1);
    if (this.workTypeCode === AppConstant.WORKTYPECODE.TIE_SURFACING ||
      this.workTypeCode === AppConstant.WORKTYPECODE.SWITCHTIES) {
      this.surfacedErrMsg.splice(index, 1);
      this.surfaceErrMessage.splice(index, 1);
      this.tieErrMsg.splice(index, 1);
      this.tieMessage.splice(index, 1);
    } else if (this.workTypeCode === AppConstant.WORKTYPECODE.SURFACING) {
      this.surfacedErrMsg.splice(index, 1);
      this.surfaceErrMessage.splice(index, 1);
    }
    this.trackErrMsg.splice(index, 1);
    this.trackSelectedErrMsg.splice(index, 1);
    this.isFormInValid = false;
  }

  deleteObj(Data: any) {
    if (
      this.selectedList.length > 0 &&
      this.selectedList.length !== null &&
      this.selectedList.length !== undefined
    ) {
      this.reportData.actualCrossingCount = this.selectedList.length;
    } else {
      this.reportData.actualCrossingCount = 0;
    }
    this.reportData.reportRoadCrossingList = this.selectedList.map(
      (item: any, index: number) => {
        const res = { ...item };
        delete res.rxObj;
        delete res.selected;
        res.dotId = res.roadCrossingId || res.dotId;
        res.roadCrossingMP = res.milePost;
        res.assetEntryNumber = index;
        return res;
      }
    );
  }

  async submitData(event) {
    if (this.viewMode) {
      if (this.workTypeCode === AppConstant.WORKTYPECODE.TIE_SURFACING
        || this.workTypeCode === AppConstant.WORKTYPECODE.SURFACING) {
        this.navigateToNextPage('turnout');
      } else {
        this.navigateToNextPage('track_utilization');
      }
      // if (this.workTypeCode === AppConstant.WORKTYPECODE.TIE_SURFACING) {
      //     this.tieAndSurfaceCheck();
      //     if (this.tieCount > 0 || this.surfaceCount > 0) {
      //         if (this.selectedList.length <= 0) {
      //             this.tsCrossing = true;
      //             this.errmsgTs = 'At least one crossing should be selected';
      //         } else {
      //             this.router.navigate([`/track_utilization/${this.projectId}/${this.jobId}/${this.formId}`]);
      //         }
      //     } else {
      //         this.router.navigate([`/track_utilization/${this.projectId}/${this.jobId}/${this.formId}`]);
      //     }
      // } else {
      //     this.router.navigate([`/track_utilization/${this.projectId}/${this.jobId}/${this.formId}`]);
      // }
    } else {
      if (this.selectedList === null || this.selectedList.length === 0) {
        this.isFormInValid = false;
      } else {
        let surfaceYesCount = 0;
        let installedTies = 0;
        let surfacedCount = 0;
        let switchTieErr = 0;
        this.surfaceYesError = false;
        this.selectedList.forEach((obj, i) => {
          this.specificationMsg[i] = false;
          obj.roadCrossingTrackList.forEach((roadCrossingTrack: RoadCrossingTrack, j: number) => {
            this.validateLength(roadCrossingTrack.crossingLength, i, j);
            if (!this.specificationMsg[i]) {
              this.validateSpecification(
                roadCrossingTrack.crossingSurfaceType,
                obj.otherSpecification,
                i
              );
            }
            this.validateTrack(roadCrossingTrack.trackType, i, j);
            if (this.workTypeCode === AppConstant.WORKTYPECODE.TIE_SURFACING) {
              this.validateTiesInstalled(roadCrossingTrack.numCrossingTiesInstalled, i, j);
              this.validateCrossingSurface(roadCrossingTrack.isCrossingSurfaced, i, j);
            } else if (this.workTypeCode === AppConstant.WORKTYPECODE.SWITCHTIES) {
              this.switchTieAndSurfaceCheck();
              let eachCrossingSurface = 0;
              let eachCrossingTie = 0;
              if (roadCrossingTrack.isCrossingSurfaced === undefined
                || roadCrossingTrack.isCrossingSurfaced === null) {
                surfacedCount++;
                eachCrossingSurface++;
              }

              if (
                roadCrossingTrack.numCrossingTiesInstalled > 0) {
                installedTies++;
                eachCrossingTie++;
              }

              this.validateSwitchTieInstalledTies(roadCrossingTrack, i, j);
              if (this.surfaceCount < 1 || this.surfaceCount === undefined) {
                if ((eachCrossingSurface > 0 && eachCrossingTie < 1
                  && !this.isSurfaceMandatoryErrorMessage())) {
                  switchTieErr++;
                }
              }
            }

            if (roadCrossingTrack.isCrossingSurfaced) {
              surfaceYesCount++;
            }

          });
        });
        this.formValidation();
        if (this.surfaceCount > 0 && surfaceYesCount < 1 && !this.isSurfaceMandatoryErrorMessage()) {
          if (this.workTypeCode === AppConstant.WORKTYPECODE.SWITCHTIES) {
            this.errmsg = AppConstant.CROSSING_SURFACE_YES_ERROR_MSG_ST;
          } else {
            this.errmsg = AppConstant.CROSSING_SURFACE_YES_ERROR_MSG_TS;
          }
          this.surfaceYesError = true;
          this.isFormInValid = true;
        }

        if (this.workTypeCode === AppConstant.WORKTYPECODE.SWITCHTIES) {
          if (switchTieErr > 0) {
            this.errmsg = AppConstant.CROSSING_INSTALLED_TIES_OR_SURFACING_ERROR_MSG;
            this.surfaceYesError = true;
            this.isFormInValid = true;
          }
        }
      }
      if (!this.isFormInValid) {
        this.deleteObj(this.reportData);
        this.saveReportToIndexDbMethod(this.reportData)
          .then(response => {
            if (this.workTypeCode === AppConstant.WORKTYPECODE.TIE_SURFACING) {
              this.tieAndSurfaceCheck();
              if (this.tieCount > 0 || this.surfaceCount > 0) {
                if (this.selectedList.length <= 0) {
                  this.tsCrossing = true;
                  this.errmsgTs = AppConstant.CROSSING_SELECTION_ERROR_MSG;
                } else {
                  this.navigateToNextPage('turnout');
                }
              } else {
                this.navigateToNextPage('turnout');
              }
            } else if (this.workTypeCode === AppConstant.WORKTYPECODE.SWITCHTIES) {
              this.switchTieAndSurfaceCheck();
              if (this.surfaceCount > 0) {
                if (this.selectedList.length <= 0) {
                  this.tsCrossing = true;
                  this.errmsgTs = AppConstant.CROSSING_SELECTION_ERROR_MSG;
                } else {
                  this.navigateToNextPage('track_utilization');
                }
              } else {
                this.navigateToNextPage('track_utilization');
              }
            } else if (this.workTypeCode === AppConstant.WORKTYPECODE.SURFACING) {
              this.navigateToNextPage('turnout');
            } else {
              this.navigateToNextPage('track_utilization');
            }
          })
          .catch(reason => {
            console.log(reason);
          });
      }
    }
  }

  protected saveReportToIndexDbMethod(repObj: any) {
   return this.dataService.saveDraftReport(JSON.stringify(repObj));
  }

  protected navigateToNextPage(navigateTo: string) {
    this.router.navigate([`/${navigateTo}/${this.projectId}/${this.jobId}/${this.formId}`]);
  }

  validateSwitchTieInstalledTies(roadCrossingTrack: RoadCrossingTrack, i: number, j: number) {
    if (this.workTypeCode === AppConstant.WORKTYPECODE.SWITCHTIES &&
      (this.surfaceCount < 1 || this.surfaceCount === undefined)) {
      if (!roadCrossingTrack.isCrossingSurfaced &&
        roadCrossingTrack.isCrossingSurfaced !== undefined &&
        roadCrossingTrack.isCrossingSurfaced !== null &&
        !this.isSurfaceMandatoryErrorMessage()) {
        const ties = roadCrossingTrack.numCrossingTiesInstalled;
        if ((!ties && ties === null && isNaN(ties)) || ties <= 0) {
          this.tieErrMsg[i][j] = true;
          this.tieMessage[i][j] = 'Enter Ties Installed';
        } else if (ties.toString().includes('.')) {
          this.tieErrMsg[i][j] = true;
          this.tieMessage[i][j] = 'Enter Numeric Value';
        } else {
          this.tieErrMsg[i][j] = false;
        }
      } else {
        this.tieErrMsg[i][j] = false;
      }
      this.formValidation();
    }
  }


  protected backNavigate(event) {
    if (this.viewMode) {
      this.router.navigate([
        `/planned_actual/${this.projectId}/${this.jobId}/${this.formId}`
      ]);
    } else {
      this.deleteObj(this.reportData);
      this.dataService
        .saveDraftReport(JSON.stringify(this.reportData))
        .then(response => {
          this.router.navigate(['/planned_actual/' + this.projectId + '/' + this.jobId + '/' + this.formId]);
        })
        .catch(reason => {
          console.log(reason);
        });
    }
  }

  backIcon(event) {
    if (this.viewMode) {
      this.router.navigate([`/job_view/${this.projectId}`]);
    } else {
      this.deleteObj(this.reportData);
      this.dataService
        .saveDraftReport(JSON.stringify(this.reportData))
        .then(response => {
          this.router.navigate([`/job_view/${this.projectId}`]);
        })
        .catch(reason => {
          console.log(reason);
        });
    }
  }

  formValidation() {
    this.isFormInValid = false;
    this.surfacedErrMsg.forEach(val => {
      if (val && val.length > 0) {
        val.forEach(msg => {
          if (msg) {
            this.errmsg = 'Please resolve errors';
            this.isFormInValid = true;
          }
        });
      }
    });
    this.tieErrMsg.forEach(val => {
      if (val && val.length > 0) {
        val.forEach(msg => {
          if (msg) {
            if (this.workTypeCode !== AppConstant.WORKTYPECODE.SWITCHTIES) {
              this.errmsg = 'Please resolve errors';
            } else {
              this.errmsg = AppConstant.CROSSING_INSTALLED_TIES_OR_SURFACING_ERROR_MSG;
            }
            this.isFormInValid = true;
          }
        });
      }
    });
    this.specificationMsg.forEach(val => {
      if (val) {
        this.errmsg = 'Please resolve errors';
        this.isFormInValid = true;
      }
    });
    this.crossLengthMsg.forEach(val => {
      if (val && val.length > 0) {
        val.forEach(msg => {
          if (msg) {
            this.errmsg = 'Please resolve errors';
            this.isFormInValid = true;
          }
        });
      }
    });
    this.trackErrMsg.forEach(val => {
      if (val && val.length > 0) {
        val.forEach(msg => {
          if (msg) {
            this.errmsg = 'Please resolve errors';
            this.isFormInValid = true;
          }
        });
      }
    });
    this.trackSelectedErrMsg.forEach(val => {
      if (val && val.length > 0) {
        val.forEach(msg => {
          if (msg) {
            this.errmsg = 'Please resolve errors';
            this.isFormInValid = true;
          }
        });
      }
    });
  }

  removeTrack(roadCrossingTrackList: RoadCrossingTrack[], index: number, roadCrossingIndex: number): void {
    if (roadCrossingTrackList.length > 1) {
      roadCrossingTrackList.splice(index, 1);

      this.crossLengthMsg[roadCrossingIndex].splice(index, 1);
      this.crossingLengthMessage[roadCrossingIndex].splice(index, 1);
      if (this.workTypeCode === AppConstant.WORKTYPECODE.TIE_SURFACING) {
        this.surfacedErrMsg[roadCrossingIndex].splice(index, 1);
        this.surfaceErrMessage[roadCrossingIndex].splice(index, 1);
        this.tieErrMsg[roadCrossingIndex].splice(index, 1);
        this.tieMessage[roadCrossingIndex].splice(index, 1);
      } else if (this.workTypeCode === AppConstant.WORKTYPECODE.SURFACING) {
        this.surfacedErrMsg[roadCrossingIndex].splice(index, 1);
        this.surfaceErrMessage[roadCrossingIndex].splice(index, 1);
      }
      this.trackErrMsg[roadCrossingIndex].splice(index, 1);
      this.trackSelectedErrMsg[roadCrossingIndex].splice(index, 1);
    }
  }

  private isSurfaceMandatoryErrorMessage(): boolean {
    this.surfacedErrMsg.forEach(errorArray => {
      if (errorArray.includes(true)) {
        return true;
      }
    });
    return false;
  }

  private addErrorArrays(): void {
    this.crossLengthMsg.push([]);
    this.crossingLengthMessage.push([]);
    this.trackErrMsg.push([]);
    this.trackSelectedErrMsg.push([]);
    if (this.workTypeCode === AppConstant.WORKTYPECODE.TIE_SURFACING
      || this.workTypeCode === AppConstant.WORKTYPECODE.SWITCHTIES) {
      this.surfacedErrMsg.push([]);
      this.surfaceErrMessage.push([]);
      this.tieErrMsg.push([]);
      this.tieMessage.push([]);
    } else if (this.workTypeCode === AppConstant.WORKTYPECODE.SURFACING) {
      this.surfacedErrMsg.push([]);
      this.surfaceErrMessage.push([]);
    }
  }
}
