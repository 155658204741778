import { FormControl, Validators } from '@angular/forms';
import { Turnout } from '../../../model/turnout.model';

export class TurnoutForm {
  feetPerPass = new FormControl();
  noOfPasses = new FormControl();
  isSurfaced = new FormControl();
  turnoutId: number;
  prefix: string;
  milepost: number;
  trackType: string;
  trackName: string;
  direction: string;
  railWeight: number;
  turnoutLength: number;
  f_t: string;
  assetEntryNumber: number;
  turnoutSize?: number;
  endMp?: number;
  switchName?: string;
  workProgressId?: number;

  constructor(turnout: Turnout, assetEntryNumber: number, edit?: boolean) {
    const numberPattern = '[0-9][0-9]*';
    console.log('edit is ', edit);
    if (edit) {
      this.feetPerPass.disable;
      this.noOfPasses.disable;
      this.isSurfaced.disable;
    }
    this.feetPerPass.setValue(turnout.feetPerPass);
    this.feetPerPass.setValidators([Validators.required, Validators.pattern(numberPattern)]);
    this.noOfPasses.setValue(turnout.noOfPasses);
    this.noOfPasses.setValidators([Validators.required, Validators.pattern(numberPattern)]);
    this.isSurfaced.setValue(turnout.isSurfaced);
    this.isSurfaced.setValidators([Validators.required]);
    this.turnoutId = turnout.turnoutId;
    this.prefix = turnout.prefix;
    this.milepost = turnout.milepost;
    this.trackType = turnout.trackType;
    this.trackName = turnout.trackName;
    this.direction = turnout.direction;
    this.railWeight = turnout.railWeight;
    this.turnoutLength = turnout.turnoutLength;
    this.f_t = turnout.f_t;
    this.assetEntryNumber = assetEntryNumber;
    this.turnoutSize = turnout.turnoutSize;
    this.endMp = turnout.endMp;
    this.switchName = turnout.switchName;
    this.workProgressId = turnout.workProgressId;
  }
}
