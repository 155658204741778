import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TrackUtilizationComponent } from '../../track-utilization/track-utilization.component';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '../../../../node_modules/@angular/forms';
import { ConditionallyValidateService } from '../../../../node_modules/ng-conditionally-validate';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { DataService } from '../../services/data.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { UtilService } from '../../util/util.service';
import { NotesModel } from '../../model/notes.model';

@Component({
  selector: 'app-track-util-notes',
  templateUrl: './track-util-notes.component.html',
  styleUrls: ['./track-util-notes.component.scss']
})
export class TrackUtilNotesComponent extends TrackUtilizationComponent implements OnInit, OnChanges {

  @Input()
  formGroup: FormGroup;
  @Input()
  clearValidation: boolean;
  notesData: NotesModel;

  constructor(
    public fb: FormBuilder,
    public cv: ConditionallyValidateService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    public dataService: DataService,
    public localStorage: LocalStorageService,
    public utilService: UtilService
  ) {
    super(fb, cv, router, activeRoute, dataService, localStorage, utilService);
  }

  ngOnInit() {
    if (!this.clearValidation) {
      this.localStorage.getCurrentUser('SYSTEM').then(data => {
        this.teamName = data ? data.teamName : null;
        this.localStorage.getNotes(this.teamName).then(notesData => {
          this.notesData = notesData && notesData.notesObj ?
            JSON.parse(JSON.stringify(notesData ? notesData.notesObj : null)) : null;
          if (this.notesData && this.notesData.reportWindows.length > 0) {
            this.setReportWindows(this.notesData);
          } else {
            this.formGroup.addControl('reportWindows', new FormArray([this.trackUtilWindowGroup(this.reportData)]));
          }
        });
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clearValidation.currentValue) {
      this.clearValidation = true;
      this.trackReceivedFlag = [];
      this.trackReceivedError = [];
      this.trackClearedFlag = [];
      this.trackClearedError = [];
      this.checkOnDutyPlannedStartTime = false;
      this.plannedEndInvalid = false;
      const reportWindowsDelete: any = this.formGroup.get('reportWindows') as FormArray;
      const windowsLength = reportWindowsDelete.controls.length;
      for (let i = windowsLength; i > 1; i--) {
        reportWindowsDelete.removeAt(i - 1);
      }
    }
    console.log(changes);
  }

  convertNotesTrackRecieved(i, windowStarts, windowEnds: any) {
    if (this.formGroup.get('onDutyTIme').value && windowEnds) {
      this.reportData.workday = this.formGroup.get('onDutyTime').value;
    }
      this.reportData.reportWindows = this.formGroup.get('reportWindows');
      this.convertTrackReceived(i, windowStarts);
  }

  convertNotesTrackCleared(i, windowEnds, windowStarts: any) {
    if (this.formGroup.get('onDutyTime').value && windowStarts) {
      this.reportData.workday = this.formGroup.get('onDutyTime').value;
    }
      this.reportData.reportWindows = this.formGroup.get('reportWindows');
      this.convertTrackCleared(i, windowEnds);
  }

  convertTrackReceived(index: any, text: string): void {
    this.trackWindowValidation(index, text);
  }

  convertTrackCleared(index: any, text: string): void {
    this.trackWindowValidation(index, text);
  }

  trackWindowValidation(index: any, text: string) {
    this.formSubmitAttempt = false;
    this.addWindowError = false;
    if (!this.clearValidation) {
      this.trackWindow(index, text);
      this.validateTrackWindow();
      this.validateTrackCleared(index);
    }
  }

  fillTrackReceivedNotes(index: number, form: FormGroup, windowEnds: any ) {
    if (this.formGroup.get('onDutyTime').value && windowEnds) {
      this.fillTrackReceived(index, form);
    }
  }

  fillTrackClearedNotes(index: number, form: FormGroup, windowStarts: any ) {
    if (this.formGroup.get('onDutyTime').value && windowStarts) {
      this.fillTrackCleared(index, form);
    }
  }

  protected trackUtilWindowGroup(reportData?: any): FormGroup {
    const form: any = {
      windowNumber: [reportData.windowNumber, Validators.required],
      windowStarts: [reportData.windowStarts, Validators.required],
      windowEnds: [reportData.windowEnds, Validators.required],
      windowStart: [reportData.windowStarts],
      windowEnd: [reportData.windowEnds],
      unitsInstalled: [reportData.unitsInstalled],
      unitPerHour: [reportData.unitPerHour]
    };
    return this.fb.group(form);
  }

  trackWindow(index, text): void {
    this.reportData.reportWindows = this.formGroup.controls['reportWindows'] as FormArray;
    this.reportData.reportWindows.controls.forEach((formGroup: FormGroup, i: any) => {
      const windowNum = i + 1;
      const windowStarts = formGroup.controls['windowStarts'].value;
      const windowEnds = formGroup.controls['windowEnds'].value;
      console.log('Window start', windowStarts);
      console.log('Window end', windowEnds);
      // On Duty Date and Time Check.
      if (
        windowStarts < this.todayDate ||
        windowEnds < this.todayDate ||
        windowStarts > this.getCurrentTime() ||
        windowEnds > this.getCurrentTime()
      ) {
        this.formGroup.markAsPending();
      }
      formGroup.controls['windowNumber'].patchValue(windowNum);
    });

  }
  validateTrackWindow() {
    if (this.formGroup.get('onDutyTime').value) {
      this.setTrackWindowDate(this.formGroup.get('onDutyTime').value);
    }
    this.validateWindow();
  }

  setTrackWindowDate(workday: any): void {
    const timestampdate = new Date(workday).toISOString();
    this.setWindowValidation(timestampdate);
  }

  validateWindow() {
    this.validCount = 0;
    this.reportData.reportWindows.controls.forEach((formGroup: FormGroup, i: any) => {
      const iWindowStart = formGroup.controls['windowStarts'].value;
      const iWindowEnd = formGroup.controls['windowEnds'].value;
      this.minDate = iWindowEnd;
      if ((iWindowEnd === undefined || iWindowEnd === null)
        || iWindowStart === undefined || iWindowStart === null) {
        this.trackWindowValid = false;
        this.trackWindowValidIndex[i] = false;
      } else if (
        iWindowStart &&
        iWindowEnd &&
        iWindowStart < iWindowEnd &&
        iWindowStart < this.nextDay &&
        iWindowEnd <= this.nextDay &&
        iWindowEnd > iWindowStart
      ) {
        this.trackWindowValid = true;
        this.trackWindowValidIndex[i] = true;
      }  else {
        if (this.formGroup.get('onDutyTime').value &&  iWindowStart >= this.formGroup.get('onDutyTime').value &&
        iWindowEnd > this.formGroup.get('onDutyTime').value) {
          this.trackWindowValid = true;
          this.trackWindowValidIndex[i] = true;
        }
      }
    });
  }


  setReportWindows(notesData: any): void {
    const formGroups = notesData.reportWindows.map(rw => {
      return this.trackUtilWindowGroup(rw);
    });
    this.formGroup.addControl('reportWindows', new FormArray(formGroups));
    notesData.reportWindows = this.formGroup.controls['reportWindows'] as FormArray;
    notesData.reportWindows.controls.forEach((formGroup: FormArray, i: any) => {
      formGroup.controls['windowStart'].patchValue(Date.parse(formGroup.controls['windowStarts'].value));
      formGroup.controls['windowEnd'].patchValue(Date.parse(formGroup.controls['windowEnds'].value));
    });
  }

  addTrackUtilWindow(): void {
    this.addWindowError = false;
    this.formSubmitAttempt = false;
    this.reportData.reportWindows = this.formGroup.get('reportWindows') as FormArray;
    console.log('reportWindows' + this.reportData.reportWindows.value);
    this.trackWindowValid = true;
    this.validateTrackWindow();
    this.validateSequence();

    if (this.trackWindowValid && this.validCount === 0) {
      this.minWindowStart = this.minDate;
      this.reportData.reportWindows.push(this.trackUtilWindowGroup({}));
    } else {
      this.addWindowError = false;
    }
  }

}
