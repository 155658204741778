import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder,  Validators } from '@angular/forms';

@Component({
  selector: 'app-track-util-windows',
  templateUrl: './track-util-windows.component.html',
  styleUrls: ['./track-util-windows.component.scss']
})
export class TrackUtilWindowsComponent implements OnInit {

  trackReceivedTime: Date;
  trackClearedTime: Date;
  trackReceivedDate: Date;
  trackClearedDate: Date;
  unitInstalled: number;
  unitPerHour: Date;

      myForm: FormGroup;

    constructor(private _fb: FormBuilder) {

}

  ngOnInit() {this.myForm = this._fb.group({
        //  name: ['', [Validators.required, Validators.minLength(5)]],
          windowComp: this._fb.array([
              this.initWindow(),
          ])
      });
  }

    initWindow() {
    return this._fb.group({
        street: ['', Validators.required],
        postcode: ['']
    });
  }

    addwindow() {
        const control = <FormArray>this.myForm.controls['windowComp'];
        control.push(this.initWindow());
    }

    removeAddress(i: number) {
        const control = <FormArray>this.myForm.controls['windowComp'];
        control.removeAt(i);
    }


   /* save(model: Customer) {
        // call API to save
        // ...
        console.log(model);
    }*/
}

//^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$

/*validateHhMm(inputField)
{
    let isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(inputField.value);

    if (isValid) {
        inputField.style.backgroundColor = '#bfa';
    } else {
        inputField.style.backgroundColor = '#fba';
    }
    return isValid;
}*/
