import { LocationDetail } from './location.model';
export class MasterTrack extends LocationDetail {
  trackType: string;
  trackStatus: string;
  constructor(
    prefix: string,
    lowMp: number,
    highMp: number,
    trackType: string,
    trackStatus: string
  ) {
    super(prefix, lowMp, highMp);
    this.trackStatus = trackStatus;
    this.trackType = trackType;
  }
}
