import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { DexieService } from '../core/dexie.service';

@Injectable()
export class LocalStorageService {
  projectTable: Dexie.Table<ProjectTable, number>;
  jobTable: Dexie.Table<JobTable, number>;
  reportTable: Dexie.Table<ReportTable, number>;
  roadCrossingTable: Dexie.Table<RoadCrossingTable, number>;
  turnoutTable: Dexie.Table<TurnoutTable, number>;
  equipmentTable: Dexie.Table<EquipmentTable, number>;
  reftable: Dexie.Table<ReferenceCode, number>;
  usertable: Dexie.Table<UserTable, string>;
  synctable: Dexie.Table<SyncTable, string>;
  currentUserTable: Dexie.Table<CurrentUserTable, string>;
  editedReportsTable: Dexie.Table<ReportTable, number>;
  notesTable: Dexie.Table<NotesTable, string>;
  supervisorRacfsTable: Dexie.Table<SupervisorRacfsTable, string>;

  constructor(private dexieService: DexieService) {
    this.reftable = this.dexieService.table('references');
    this.projectTable = this.dexieService.table('projects');
    this.jobTable = this.dexieService.table('jobs');
    this.reportTable = this.dexieService.table('reports');
    this.roadCrossingTable = this.dexieService.table('roadCrossings');
    this.turnoutTable = this.dexieService.table('turnouts');
    this.equipmentTable = this.dexieService.table('equipments');
    this.usertable = this.dexieService.table('user');
    this.synctable = this.dexieService.table('sync');
    this.currentUserTable = this.dexieService.table('currentUser');
    this.editedReportsTable = this.dexieService.table('editedReports');
    this.notesTable = this.dexieService.table('notes');
    this.supervisorRacfsTable =  this.dexieService.table('supervisorRacfs');
  }

  clearStores() {
    this.dexieService.delete();
    return Dexie.delete('__dbnames');
  }

  getAllProjects() {
    return this.projectTable.toArray();
  }

  addProject(data) {
    return this.projectTable.put(data);
  }

  addBulkProjects(data) {
    return this.projectTable.bulkPut(data);
  }

  updateProject(project, data) {
    return this.projectTable.update(project, data);
  }

  removeProject(project) {
    return this.projectTable.delete(project);
  }

  getProject(project) {
    return this.projectTable.get(project);
  }

  // Job functions

  getAlJobs() {
    return this.jobTable.toArray();
  }

  getProjectJobs(projectId) {
    return this.jobTable
      .where('projectId')
      .equals(projectId)
      .toArray();
  }

  addJob(data) {
    return this.jobTable.put(data);
  }

  addBulkJobs(data) {
    return this.jobTable.bulkPut(data);
  }

  updateJob(jobId, data) {
    return this.jobTable.update(jobId, data);
  }

  removeJob(jobId) {
    return this.jobTable.delete(jobId);
  }

  getJob(jobId) {
    return this.jobTable.get(jobId);
  }

  // Report functions
  getAllReports() {
    return this.reportTable.toArray();
  }

  getProjectReports(projectId) {
    return this.reportTable
      .where('projectId')
      .anyOf(projectId, projectId.toString())
      .toArray();
  }

  getJobReports(jobId) {
    return this.reportTable
      .where('jobId')
      .equals(jobId)
      .toArray();
  }

  addReport(data) {
    return this.reportTable.put(data);
  }

  addBulkReports(data) {
    return this.reportTable.bulkPut(data);
  }

  updateReport(reportId, data) {
    return this.reportTable.update(reportId, data);
  }

  removeReport(reportId) {
    return this.reportTable.delete(reportId);
  }

  removeBulkReport(reportIds) {
    return this.reportTable.bulkDelete(reportIds);
  }

  getReport(reportId) {
    return this.reportTable.get(reportId);
  }

  getReportsForSync() {
    return this.reportTable
      .where('formId')
      .below(0)
      .toArray();
  }

  // Road Crossing functions
  getAllRoadCrossings() {
    return this.roadCrossingTable.toArray();
  }

  getJobRoadCrossings(projectId) {
    return this.roadCrossingTable
      .where('projectId')
      .equals(projectId)
      .toArray();
  }

  addRoadCrossing(data) {
    return this.roadCrossingTable.put(data);
  }

  addBulkRoadCrossings(data) {
    return this.roadCrossingTable.bulkPut(data);
  }

  addBulkTurnouts(data) {
    return this.turnoutTable.bulkPut(data);
  }

  getRoadCrossingById(rxId) {
    return this.roadCrossingTable.get(rxId);
  }

  updateRoadCrossing(roadCrossingId, data) {
    return this.roadCrossingTable.update(roadCrossingId, data);
  }

  removeRoadCrossing(roadCrossingId) {
    return this.roadCrossingTable.delete(roadCrossingId);
  }

  // Turnout functions
  getAllTurnouts() {
    return this.turnoutTable.toArray();
  }

  getTurnout(turnoutId: number, projectId) {
    return this.turnoutTable.get({
      turnoutId: turnoutId,
      projectId: projectId
    });
  }
  getJobTurnouts(projectId) {
    return this.turnoutTable
      .where('projectId')
      .equals(projectId)
      .toArray();
  }

  addTurnout(data) {
    return this.turnoutTable.put(data);
  }

  updateTurnout(turnoutId, data) {
    return this.turnoutTable.update(turnoutId, data);
  }

  removeTurnout(turnoutId) {
    return this.turnoutTable.delete(turnoutId);
  }

  // equipement functions
  getAllEquipments() {
    return this.equipmentTable.toArray();
  }

  getEquipmentsForDivison(division) {
    return this.equipmentTable
      .where('division')
      .equals(division)
      .toArray();
  }

  addEquipment(data) {
    return this.equipmentTable.put(data);
  }

  updateEquipment(equipmentId, data) {
    return this.equipmentTable.update(equipmentId, data);
  }

  removeEquipment(equipmentId) {
    return this.equipmentTable.delete(equipmentId);
  }

  // Reference functions
  getAllRefereces() {
    return this.reftable.toArray();
  }

  addReference(data) {
    return this.reftable.put(data);
  }

  addBulkReferences(data) {
    return this.reftable.bulkPut(data);
  }

  updateReference(refType, data) {
    return this.reftable.update(refType, data);
  }

  removeReference(refType) {
    return this.reftable.delete(refType);
  }

  getReference(refType) {
    return this.reftable.get(refType);
  }

  addUser(data) {
    return this.usertable.put(data);
  }

  addBulkUser(data) {
    return this.usertable.bulkPut(data);
  }

  removeUser(userId) {
    return this.usertable.delete(userId);
  }

  getUser(racId) {
    return this.usertable.get(racId);
  }

  getUsers() {
    return this.usertable.toArray();
  }

  addSyncInfo(data) {
    return this.synctable.put(data);
  }

  removeSyncInfo(racId) {
    return this.synctable.delete(racId);
  }

  getSyncInfo(racId) {
    return this.synctable.get(racId);
  }

  addEditedReport(reportData) {
    return this.editedReportsTable.put(reportData);
  }

  getEditedReportByFormId(formId) {
    return this.editedReportsTable.get(formId);
  }

  deleteEditedReportByFormId(formId) {
    return this.editedReportsTable.delete(formId);
  }

  deleteAllEditDraftReports() {
    return this.editedReportsTable.clear();
  }

  addNotes(notesData) {
    return this.notesTable.put(notesData);
  }

  getNotes(teamName) {
    return this.notesTable.get(teamName);
  }

  deleteNotes(teamName) {
    return this.notesTable.delete(teamName);
  }

  deleteAllNotes() {
    return this.notesTable.clear();
  }

  updateNotes(teamName, data) {
    return this.notesTable.update(teamName, data);
  }

  clearAllData() {
    this.reftable.clear();
    this.projectTable.clear();
    this.jobTable.clear();
    this.reportTable.clear();
    this.roadCrossingTable.clear();
    this.turnoutTable.clear();
    this.equipmentTable.clear();
    this.synctable.clear();
    this.editedReportsTable.clear();
    this.notesTable.clear();
  }

  getCurrentUser(racId) {
    return this.currentUserTable.get(racId);
  }

  addCurrentUser(data) {
    return this.currentUserTable.put(data);
  }

  getSupervisorUser(rlCode) {
    return this.supervisorRacfsTable.get(rlCode);
  }

  addSupervisorData(data) {
    return this.supervisorRacfsTable.put(data);
  }

  addBulkSupervisorydata(data) {
    return this.supervisorRacfsTable.bulkPut(data);
  }

  getAllSupervisorData() {
    return this.supervisorRacfsTable.toArray();
  }
}

export interface ProjectTable {
  project: number;
  projectObj: string;
}

export interface JobTable {
  jobId: number;
  projectId: number;
  jobObj: string;
}

export interface ReportTable {
  formId: number;
  jobId: number;
  projectId: number;
  reportObj: any;
}

export interface RoadCrossingTable {
  roadCrossingId: string;
  jobId: number;
  projectId: number;
  prefix: string;
  milePost: number;
  rxObj: string;
}

export interface TurnoutTable {
  turnoutId: string;
  jobId: number;
  projectId: number;
  turnoutObj: string;
}

export interface EquipmentTable {
  equipmentId: string;
  division: string;
  team: string;
  equipmentObj: string;
}

export interface ReferenceCode {
  refType: string; // Reference Type
  refObj: string;
}

export interface UserTable {
  racf: string;
  userObj: string;
}

export interface SyncTable {
  racf: string;
  syncObj: string;
}

export interface CurrentUserTable {
  racf: string;
  teamName: string;
  teamReason: string;
  deviceSpecificLastSync: number;
}

export interface NotesTable {
  teamName: string;
  notesObj: String;
}

export interface SupervisorRacfsTable {
  rlCode: string;
  userObj: any;
}
