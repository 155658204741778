import { KeyValue } from '@angular/common';
import * as moment from 'moment';

import { LocalStorageService, ProjectTable, ReportTable, SupervisorRacfsTable } from '../services/local-storage.service';

import { DataService } from '../services/data.service';
import { Injectable } from '@angular/core';
import { JobTable } from '../services/local-storage.service';
import { SelectItem } from 'primeng/primeng';
import { MasterTrack } from '../model/track.model';
import { TDWorkType } from '../td-speed-restriction/enums/td-work-type.enum';
import { TDSpeedType } from '../td-speed-restriction/enums/td-speed-types.enum';

@Injectable()
export class UtilService {
  jobData: any;
  unitsBoundary: any;
  ss: any;
  EndMp: any;
  dExpansionInches: any | number;
  slash: number;
  projectStartMP: number;
  projectEndMP: number;
  projectData: string;
  projectId: number;
  jobId: number;
  formId: number;
  noProduction = 'No Production';
  production = 'Production';
  rest = 'RestDay';

  constructor(public dataService: DataService, public localStorage: LocalStorageService) { }

  async getEndMilePost(jobid: any, startMp: any, installed: number, direction) {
    let eligible = false;
    let unitsInstalled = 0;
    this.unitsBoundary = 0;
    let count = 0;

    while (!eligible) {
      const splitaednum = startMp.toString().split('.');
      let startMilePostNumber = Number(splitaednum[0]);
      let installedPercentage = Number(splitaednum[1]);
      if (count === 0 && direction === 'D') {
        if (!installedPercentage) {
          --startMilePostNumber;
        }
      }
      count++;
      await this.dataService.getJobInfo(Number(jobid)).then((jobid1: JobTable) => {
        this.jobData = JSON.parse(JSON.stringify(jobid1.jobObj));
      });
      const ss = this.jobData.chainFeet;
      let validMilepost = false;
      for (const prop in ss) {
        if (ss[prop].milepost === startMilePostNumber) {
          this.unitsBoundary = ss[prop].feet;
          console.log('works', this.unitsBoundary);
          validMilepost = true;
        }
      }
      if (!validMilepost) {
        return true;
      }
      if (direction === 'I') {
        if (installedPercentage) {
          if (splitaednum[1].length === 1) {
            installedPercentage = installedPercentage * 10;
          }
          unitsInstalled = this.unitsBoundary * (installedPercentage / 100);
        } else {
          unitsInstalled = 0;
        }
        // installed feet for the milepost  //
        const totalInstalled = installed + unitsInstalled;
        if (this.unitsBoundary >= totalInstalled) {
          let totalInstalledPercentage: any = Math.round((totalInstalled / this.unitsBoundary) * 100);
          if (totalInstalledPercentage < 10) {
            totalInstalledPercentage = '0' + totalInstalledPercentage;
          }
          if (totalInstalledPercentage === 100) {
            totalInstalledPercentage = 0;
            ++startMilePostNumber;
          }
          const endMP = startMilePostNumber + '.' + totalInstalledPercentage;
          console.log('$$$$$$$$$$$ end Mp $$$$$$$$$$$', endMP);
          if (Number(endMP) > this.jobData.endMP + 0.2) {
            return true;
          } else {
            eligible = true;
            return endMP;
          }
        } else {
          installed = totalInstalled - this.unitsBoundary;
          startMp = ++startMilePostNumber;
        }
      } else {
        if (installedPercentage) {
          if (splitaednum[1].length === 1) {
            installedPercentage = installedPercentage * 10;
          }
          installedPercentage = 100 - installedPercentage;
          unitsInstalled = this.unitsBoundary * (installedPercentage / 100);
        } else {
          unitsInstalled = 0;
        }
        const totalInstalled = installed + unitsInstalled;
        if (this.unitsBoundary >= totalInstalled) {
          let totalInstalledPercentage: any = (totalInstalled / this.unitsBoundary) * 100;
          totalInstalledPercentage = 100 - totalInstalledPercentage;
          totalInstalledPercentage = Math.round(totalInstalledPercentage);
          // if (totalInstalledPercentage === 100) {
          //   --totalInstalledPercentage;
          // }
          if (totalInstalledPercentage === 100) {
            totalInstalledPercentage = 0;
            ++startMilePostNumber;
          }
          if (totalInstalledPercentage < 10) {
            totalInstalledPercentage = '0' + totalInstalledPercentage;
          }
          const endMP = startMilePostNumber + '.' + totalInstalledPercentage;
          console.log('$$$$$$$$$$$ end Mp $$$$$$$$$$$', endMP);
          if (Number(endMP) < this.jobData.startMP - 0.2) {
            return true;
          } else {
            eligible = true;
            return endMP;
          }
        } else {
          installed = totalInstalled - this.unitsBoundary;
          startMp = --startMilePostNumber;
        }
      }
    }
  }

  async getSurfacedFeet(beginMP: number, endMP: number, jobID: Number) {
    let result = 0;
    let beginMPPercent = 0;
    let endMPPercent = 0;
    const chainFeetArray = await this.getChainFeet(jobID);
    const beginMPArray = beginMP.toString().split('.');
    const beginMPWhole = Number(beginMPArray[0]);
    if (beginMPArray[1]) {
      beginMPPercent = this.convertStringToDecimalPercent(beginMPArray[1]);
    }
    const endMPArray = endMP.toString().split('.');
    const endMPWhole = Number(endMPArray[0]);
    if (endMPArray[1]) {
      endMPPercent = this.convertStringToDecimalPercent(endMPArray[1]);
    }
    let chainFeet: number;

    let beginMPInvalid = true;
    let endMPInvalid = true;

    // tslint:disable-next-line:forin
    for (const prop in chainFeetArray) {
      if (chainFeetArray[prop].milepost === beginMPWhole) {
        beginMPInvalid = false;
      }
      if (chainFeetArray[prop].milepost === endMPWhole) {
        endMPInvalid = false;
      }
    }

    if (endMP > beginMP) {
      if (beginMPWhole === endMPWhole) {
        for (const prop in chainFeetArray) {
          if (chainFeetArray[prop].milepost === beginMPWhole) {
            chainFeet = chainFeetArray[prop].feet;
          }
        }
        result = chainFeet * (endMPPercent - beginMPPercent);
      } else {
        for (let i = 0; i < endMPWhole - beginMPWhole + 1; i++) {
          for (const prop in chainFeetArray) {
            if (chainFeetArray[prop].milepost === beginMPWhole + i) {
              chainFeet = chainFeetArray[prop].feet;
            }
          }
          if (beginMPWhole === beginMPWhole + i) {
            result += chainFeet * (1 - beginMPPercent);
          } else if (endMPWhole === beginMPWhole + i) {
            result += chainFeet * endMPPercent;
          } else {
            result += chainFeet;
          }
        }
      }
    } else {
      if (beginMPWhole === endMPWhole) {
        for (const prop in chainFeetArray) {
          if (chainFeetArray[prop].milepost === beginMPWhole) {
            chainFeet = chainFeetArray[prop].feet;
          }
        }
        result = chainFeet * (beginMPPercent - endMPPercent);
      } else {
        for (let i = 0; i < beginMPWhole - endMPWhole + 1; i++) {
          for (const prop in chainFeetArray) {
            if (chainFeetArray[prop].milepost === endMPWhole + i) {
              chainFeet = chainFeetArray[prop].feet;
            }
          }
          if (beginMPWhole === endMPWhole + i) {
            result += chainFeet * beginMPPercent;
          } else if (endMPWhole === endMPWhole + i) {
            result += chainFeet * (1 - endMPPercent);
          } else {
            result += chainFeet;
          }
        }
      }
    }
    const resultArray = [Math.round(result), beginMPInvalid, endMPInvalid];
    return resultArray;
  }

  isMilePostValid(mp: number, jobID: Number): Promise<boolean> {
    let mpInvalid = false;
    return new Promise((resolve, reject) => {
      this.getMasterTrack(jobID).then((masterTrackList: MasterTrack[]) => {
        let count = 0;
        console.log('master track list', masterTrackList);
        masterTrackList.forEach((track: MasterTrack) => {
          count++;
          console.log('isMilePostvalid --track', mp, track);
          if (mp >= track.lowMp && mp <= track.highMp) {
            console.log('isMilePostvalid --true', mp, track);
            mpInvalid = true;
            resolve(mpInvalid);
          }

          if (masterTrackList.length === count && !mpInvalid) {
            console.log('isMilePostvalid-- false', mp, track);
            resolve(mpInvalid);
          }
        });
      });
    });
  }

  isMilePostWithinMasterTrack(mp: number, masterTrackList: MasterTrack[]): boolean {
    let mpInvalid = false;
    let count = 0;
    console.log('master track list', masterTrackList);
    masterTrackList.forEach((track: MasterTrack) => {
      count++;
      console.log('isMilePostvalid --track', mp, track);
      if (mp >= track.lowMp && mp <= track.highMp) {
        console.log('isMilePostvalid --true', mp, track);
        mpInvalid = true;
      }
      if (masterTrackList.length === count && !mpInvalid) {
        console.log('isMilePostvalid-- false', mp, track);
      }
    });

    return mpInvalid;
  }

  isMilePostWithInChainFeet(mp: number, jobID: Number, trackType: string, trackName: string) {
    const chainFeetArray = this.getChainFeet(jobID);
    const jobTrackType = this.getTrackType(jobID);
    const MPArray = mp.toString().split('.');
    const MPWhole = Number(MPArray[0]);
    let mpInvalid = false;

    for (const prop in chainFeetArray) {
      //  if (jobTrackType === trackType) {
      if (chainFeetArray[prop].milepost === MPWhole) {
        if (Number(prop) === 0) {
          if (chainFeetArray[prop].milepost + chainFeetArray[prop].offset <= mp) {
            mpInvalid = true;
          } else {
            mpInvalid = false;
          }
        } else if (Number(prop) === chainFeetArray.length - 1) {
          if (chainFeetArray[prop].milepost + chainFeetArray[prop].offset >= mp) {
            mpInvalid = true;
          } else {
            mpInvalid = false;
          }
        } else {
          mpInvalid = true;
        }
      }
    }
    return mpInvalid;
  }

  validStartEndMp(direction: string, jobID: Number) {
    const chainFeetArray = this.getChainFeet(jobID);
    if (direction === 'I') {
      return chainFeetArray[chainFeetArray.length - 1].milepost + chainFeetArray[chainFeetArray.length - 1].offset;
    } else {
      return chainFeetArray[0].milepost + chainFeetArray[0].offset;
    }
  }

  getChainFeet(jobID: Number) {
    this.dataService.getJobInfo(Number(jobID)).then((jobid1: JobTable) => {
      this.jobData = JSON.parse(JSON.stringify(jobid1.jobObj));
    });
    if (this.jobData) {
      return this.jobData.chainFeet;
    }
  }

  getMasterTrack(jobID: Number): Promise<MasterTrack[]> {
    return new Promise((resolve, reject) => {
      const trackList: MasterTrack[] = [];
      this.dataService.getJobInfo(Number(jobID)).then((jobid1: JobTable) => {
        this.jobData = JSON.parse(JSON.stringify(jobid1.jobObj));
        this.jobData.masterTrackList.forEach(track => {
          trackList.push(new MasterTrack(track.prefix, track.beginMp, track.endMp, track.trackType, track.trackStatus));
          if (this.jobData.masterTrackList.length === trackList.length) {
            resolve(trackList);
          }
        });

        if (this.jobData.masterTrackList.length === 0) {
          resolve(trackList);
        }
      });
    });
  }

  getTrackType(jobID: Number) {
    this.dataService.getJobInfo(Number(jobID)).then((jobid1: JobTable) => {
      this.jobData = JSON.parse(JSON.stringify(jobid1.jobObj));
    });
    return this.jobData.trackType;
  }

  convertStringToDecimalPercent(num: string) {
    let result: number;
    if (num.charAt(0) === '0') {
      result = Number(num) / 10;
      for (let i = 0; i < num.length - 1; i++) {
        result = result / 10;
      }
    } else {
      result = Number(num);
      for (let i = 0; i < num.length; i++) {
        result = result / 10;
      }
    }
    return result;
  }

  differenceOf2Arrays(a1, a2) {
    const a = [],
      diff = [];
    for (let i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }

    for (let i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }
    // tslint:disable-next-line:forin
    for (const k in a) {
      diff.push(k);
    }
    return diff;
  }

  hack(json: any, str: string): SelectItem[] {
    const returnSel: SelectItem[] = [];
    console.log(json);
    Object.keys(json).forEach(key => {
      if (
        str === 'railGrade' ||
        str === 'railSideDisc' ||
        str === 'tiePlateType' ||
        str === 'designedRailTemp' ||
        str === 'designedTunnelTemp' ||
        str === 'railType' ||
        str === 'surfacingRailType' ||
        str === 'TeamName' ||
        str === 'TeamChange'
      ) {
        const r: SelectItem = {
          label: json[key],
          value: key
        };
        returnSel.push(r);
      } else {
        const r: SelectItem = {
          label: key.valueOf(),
          value: key
        };
        returnSel.push(r);
      }
    });
    return returnSel;
  }

  isXYZ(str: any) {
    const flo = parseFloat(str);
    const n = '' + flo;
    return str.trim().length === n.trim().length;
  }

  public getGcd(x, y) {
    if (typeof x !== 'number' || typeof y !== 'number') {
      return false;
    } else {
      x = Math.abs(x);
      y = Math.abs(y);
      while (y) {
        const t = y;
        y = x % y;
        x = t;
      }
    }
    return x;
  }

  public verifyMp(startMP: number, endMp: number, beginMilepost: number): boolean {
    const jobLimitHigh = endMp + 0.2;
    const jobLimitLow = startMP - 0.2;
    if (jobLimitHigh < jobLimitLow) {
      return beginMilepost < jobLimitHigh || beginMilepost > jobLimitLow;
    } else {
      return beginMilepost < jobLimitLow || beginMilepost > jobLimitHigh;
    }
  }

  /**
   * @public
   * @memberof TrackUtilizationHelper
   */
  public updateProjectPlannedValues(reportData: any, projectId: number, skipCrossingUpdate?: boolean): any {
    this.dataService.getProjectInfo(projectId).then((p: ProjectTable) => {
      const pObj = JSON.parse(JSON.stringify(p.projectObj));
      console.log('project', pObj);
      let plannedProjectValChanged = false;
      if (pObj.totalPlannedProjectUnits !== reportData.totalPlannedProjectUnits) {
        plannedProjectValChanged = true;
        pObj.totalPlannedProjectUnits = reportData.totalPlannedProjectUnits;
      }
      if (pObj.totalPlannedProjectDays !== reportData.totalPlannedProjectDays) {
        plannedProjectValChanged = true;
        pObj.totalPlannedProjectDays = reportData.totalPlannedProjectDays;
      }
      if (!skipCrossingUpdate && pObj.plannedCrossingCount !== reportData.plannedCrossingCount) {
        plannedProjectValChanged = true;
        pObj.plannedCrossingCount = reportData.plannedCrossingCount;
      }
      if (plannedProjectValChanged) {
        const projectTableObj: ProjectTable = {
          project: pObj.project,
          projectObj: pObj
        };
        this.localStorage.addProject(projectTableObj);
      }
    });
    return reportData;
  }

  /**
   * this method provide time difference between 2 dates
   * @param {*} fieldTime
   * @param {*} workDayTime
   * @returns {boolean}
   * @memberof TrackUtilizationHelper
   */
  timeDifferenceGreaterThan18Hours(fieldTime: any, workDayTime: any): boolean {
    const durations = moment.duration(fieldTime.diff(workDayTime));
    console.log('***** TIME DIFFERENCE =====> ' + durations.asHours());
    return durations.asHours() <= 18;
  }

  /**
   * This method provide boolean value by comparing 2 dates time differece.
   * @param {*} fieldTime
   * @param {*} workDayTime
   * @returns {boolean}
   * @memberof TrackUtilizationHelper
   */
  timeDifferenceGreaterThan14Hours(fieldTime: any, workDayTime: any): boolean {
    const durations = moment.duration(fieldTime.diff(workDayTime));
    console.log('***** PLANNED START/END TIME DIFFERENCE =====> ' + durations.hours() + ', ' + durations.minutes());
    return durations.hours() >= 14 && durations.minutes() > 0;
  }

  /**
   * Is roll over candidate or not?
   * @param {*} textFieldTime
   * @returns {boolean}
   * @memberof TrackUtilizationHelper
   */
  checkRolloverCanidate(textFieldTime: any): boolean {
    let isCandidate = false;
    const rollovercandidate = 13;
    const hourOfTheDay = +moment(textFieldTime, 'HH').format('HH');
    console.log('HOURS_OF_THE_DAY: ', moment(textFieldTime, 'HH').format('HH'));
    if (hourOfTheDay < rollovercandidate) {
      isCandidate = true;
    } else {
      isCandidate = false;
    }
    return isCandidate;
  }

  getCurrentTime() {
    const currentTime = new Date();
    return currentTime;
  }

  public convertTimeStampTOdate(timeStamp) {
    if (timeStamp !== null || timeStamp !== 0) {
      const timestamp = new Date(new Date(timeStamp).toISOString());
      const date =
        timestamp.getFullYear() +
        '-' +
        ('0' + (timestamp.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + timestamp.getDate()).slice(-2) +
        'T' +
        ('0' + timestamp.getHours()).slice(-2) +
        ':' +
        ('0' + timestamp.getMinutes()).slice(-2);

      return date;
    }
  }

  public addHours(timeStamp, hours: number) {
    const currentD = new Date();
    const maxD = new Date(timeStamp);
    maxD.setHours(maxD.getHours() + hours);
    let returnDate;
    if (currentD < maxD) {
      returnDate = this.convertTimeStampTOdate(currentD.getTime());
    } else {
      returnDate = this.convertTimeStampTOdate(maxD.getTime());
    }
    return returnDate;
  }
  public plannedStartMaxDateTime(timeStamp) {
    const maxD = new Date(timeStamp);
    maxD.setHours(maxD.getHours() + 23);
    maxD.setMinutes(maxD.getMinutes() + 59);
    return this.convertTimeStampTOdate(maxD.getTime());
  }

  public plannedEndMaxDateTime(timeStamp) {
    const maxD = new Date(timeStamp);
    maxD.setHours(maxD.getHours() + 13);
    maxD.setMinutes(maxD.getMinutes() + 59);
    return this.convertTimeStampTOdate(maxD.getTime());
  }

  public getCurrentDateTime() {
    return this.getFormattedDateTime(new Date());
  }

  public getFormattedDateTime(date: Date) {
    return (
      date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2) +
      'T' +
      ('0' + date.getHours()).slice(-2) +
      ':' +
      ('0' + date.getMinutes()).slice(-2)
    );
  }

  public getFormattedDate(date: Date) {
    return (
      date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2)
    );
  }

  public getMinDateTime() {
    const firstDay = new Date().getFullYear() + '-01-01T00:00';
    return firstDay;
  }

  public getWorkDay(reportData: any): any {
    console.log('Work Day is ', reportData.workday);
    return reportData.workday;
  }

  getIndexId(prefix: string, index: number) {
    return prefix + index;
  }

  public verifyMPOutSideJobLimit(startMP: number, endMp: number, beginMilepost: number, direction: string, isSiding: boolean): boolean {
    let jobLimitLow = startMP;
    let jobLimitHigh = endMp;
    if (!isSiding) {
      jobLimitLow = startMP - 0.2;
      jobLimitHigh = endMp + 0.2;
    }

    if (jobLimitHigh < jobLimitLow) {
      return beginMilepost < jobLimitHigh || beginMilepost > jobLimitLow;
    } else {
      return beginMilepost < jobLimitLow || beginMilepost > jobLimitHigh;
    }
  }

  public verifyMPNextWholeMpJobLimit(beginMilepost: number, endMilepost: number, direction: string): boolean {
    const wholeBeginMilepost = Math.trunc(beginMilepost);
    let nextWholeMilepost = 0;
    if (direction === 'I') {
      nextWholeMilepost = wholeBeginMilepost + 1;
      if (endMilepost <= nextWholeMilepost) {
        return false;
      } else {
        return true;
      }
    } else if (direction === 'D') {
      if (wholeBeginMilepost !== beginMilepost) {
        nextWholeMilepost = wholeBeginMilepost;
      } else {
        nextWholeMilepost = wholeBeginMilepost - 1;
      }
      if (endMilepost >= nextWholeMilepost) {
        return false;
      } else {
        return true;
      }
    }
  }

  public fieldLimit(Text, length: number) {
    Text = Text.toString();
    const Textlength = length - Text.length;
    if (Textlength < 0) {
      return true;
    } else {
      return false;
    }
  }

  public isFloat(value) {
    return parseInt(value, 10) === value;
  }

  public emptyCheck(value: any) {
    if (value === null || value === '' || value === 'undefined') {
      console.log('empty true', value);
      return true;
    } else {
      console.log('empty false', value);
      return false;
    }
  }

  public onKeydown(event: KeyboardEvent) {
    console.log(event);
    const focusableElements: Element[] = [].slice.call(document.querySelectorAll('input,select,button,textarea'));
    const currentInput = event.srcElement;
    const currentIndex = focusableElements.indexOf(currentInput);
    if (event.keyCode === 13) {
      this.focus(focusableElements, currentIndex + 1);
    }
  }

  public focus(elements, index) {
    if (elements[index]) {
      elements[index].focus();
    }
  }

  public determineReportType(report: any): string {
    //   if (report.reportObj['noProductionCode'] === null) {
    //     return this.production;
    //   } else {
    //     if (report.reportObj['noProductionCode'] === 'RST') {
    //       return this.rest;
    //     } else {
    //       return this.noProduction;
    //     }
    //   }
    // }

    if (report.noProductionCode === null) {
      return this.production;
    } else {
      if (report.noProductionCode === 'RST') {
        return this.rest;
      } else {
        return this.noProduction;
      }
    }
  }

  async checkNoProductionReport(formId, jobId) {
    let reportOBJ: any;
    await this.localStorage.getJobReports(Number(jobId)).then((reportList: Array<ReportTable>) => {
      // *******  this list is to get the previous report
      if (reportList.length > 0) {
        reportList.sort((x, y) => {
          return x.reportObj['workday'] < y.reportObj['workday'] ? -1 : 1;
        });
        let reportcount = reportList.length;
        let previousReport = false;
        while (!previousReport) {
          reportOBJ = JSON.parse(JSON.stringify(reportList[reportcount - 1].reportObj));
          if (reportOBJ.formId !== formId && reportOBJ.noProductionCode === null) {
            previousReport = true;
            return reportOBJ;
          } else {
            reportcount--;
          }
        }
      }
    });
    return null;
  }

  getWorkAndSpeedRestrictionTypes(
    referenceObject: any,
    speedType: TDSpeedType
  ): Array<KeyValue<TDWorkType, Array<KeyValue<number, string>>>> {
    const workTypes: Array<KeyValue<TDWorkType, Array<KeyValue<number, string>>>> = [];
    const speedTypes: Array<KeyValue<number, string>> = Object.keys(referenceObject).map((key: string) => {
      return {
        key: Number(key),
        value: String(referenceObject[key])
      };
    });
    workTypes.push({
      key: speedType === TDSpeedType.SURFACING ? TDWorkType.SURFACING : TDWorkType.TIES_INSTALLED,
      value: speedTypes
    });
    return workTypes;
  }

  async mapRoadMasterDetails(roadMasterRl: string) {
    let returnvalue = null;
    await this.localStorage.getAllSupervisorData().
      then((supervisors: SupervisorRacfsTable[]) => {
        const supervisor = supervisors.find(item => item.userObj.rlCode.includes(roadMasterRl));
        if (supervisor) {
          if (!supervisor.userObj.racf.match(/XX.*/)) {
            returnvalue = supervisor;
          } else {
            const positionSupervisor = supervisor.userObj.positionSupervisorRacfId;
            const supervisorDOT = supervisors.find(item => item.userObj.racf === positionSupervisor);
            if (supervisorDOT) {
              returnvalue = supervisorDOT;
            }
          }
        }
      });
    return new Promise((resolve, reject) => {
      resolve(returnvalue);
    });
  }

}
