import { Pipe, PipeTransform } from '@angular/core';
import { LocalStorageService, ReferenceCode } from '../services/local-storage.service';

@Pipe({
  name: 'reference'
})
export class ReferencePipe implements PipeTransform {

  private refList: Array<ReferenceCode>;

  constructor(private ls: LocalStorageService) {
    this.ls.getAllRefereces().then((refs: Array<ReferenceCode>) => {
      this.refList = refs;
    });
  }

  reloadRef() {
    this.ls.getAllRefereces().then((refs: Array<ReferenceCode>) => {
      this.refList = refs;
    });
  }

  transform(refCode: any, refType: string): any {
    let refLabel;
    if (this.refList) {
      for (const p of this.refList) {
        if (p.refType === refType) {
          refLabel = p.refObj[refCode];
          return refLabel;
        }
      }
    } else {
      console.log('refList variable undefined in ReferencePipe');
    }
    return refCode;
  }

}
