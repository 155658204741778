import { Component, OnInit } from '@angular/core';
import { ReferenceCode, LocalStorageService } from '../services/local-storage.service';
import { DataService } from '../services/data.service';

@Component({
    selector: 'app-reference',
    templateUrl: './reference.component.html',
    styleUrls: ['./reference.component.scss'],
})

export class ReferenceComponent implements OnInit {

    referenceList: Array<ReferenceCode> = [];

    constructor(private dataService: DataService, private referenceService: LocalStorageService) {
    }

    ngOnInit() {

        const refList = 'DIV,RWT,SRT,SPT,RMT,REQ,RGR,TPG,TNR,TBD,TZZ,TRK,XSR,DTP,RSE,STW,RTT';
        this.dataService.getRefData(refList).then((refs: Array<ReferenceCode>) => {
            this.referenceList = refs;
        });
    }

    onAddReference(ref: any) {
        const reference: ReferenceCode = {
            refType: ref.refType,
            refObj: ref.refObj
        };
        this.referenceService.addReference(reference)
            .then(() => {
                this.referenceList = [...this.referenceList, reference];
            });
    }


    onDeleteReference(refType: string) {
        this.referenceService
            .removeReference(refType)
            .then(() => {
                this.referenceList = this.referenceList.filter((referenceCode) => referenceCode.refType !== refType);
            });
    }


}
