import { Observable, Observer } from 'rxjs';
import { DataService } from './../../services/data.service';
import { ConfirmationService } from 'primeng/primeng';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppConstant } from '../constant/constants';

@Injectable({
  providedIn: 'root'
})
export class ReportNavigationService {
  leavingPage: Boolean = false;

  constructor(
    private confirmationService: ConfirmationService,
    private router: Router,
    private dataService: DataService
  ) { }

  confirmBeforeLeavingPage() {
    return Observable.create((observer: Observer<boolean>) => {
      if (this.leavingPage) {
        this.confirmationService.confirm({
          key: 'navigateBackConfirmation',
          header: 'Alert',
          message: AppConstant.BACK_BUTTON_CONFIRMATION_MESSAGE,
          accept: () => {
            observer.next(true);
            observer.complete();
          },
          reject: () => {
            observer.next(false);
            observer.complete();
          }
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  navigateToJobView(projectId: any, formId: number) {
    this.leavingPage = true;
    this.router.navigate([`/job_view/${projectId}`]).then((navigationComplete: boolean) => {
      this.leavingPage = false;
      if (navigationComplete) {
        this.dataService.deleteEditedReportByFormId(formId).then(() => {
          console.log('Deleting edited report. Form Id: ' + formId + '.');
        }).catch(error => {
          console.log('Error while deleting edited report. Form Id: ' + formId + '. Error: ' + error);
        });
      }
    });
  }
}
