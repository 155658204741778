/*
Menu Items for application
*/

export class Menu {
    menuItems: any[] = [
        { label: 'Home', routerLink: ['/main'] },
        { label: 'View Adapter', routerLink: ['/adapter'] }
    ];
}
