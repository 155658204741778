import { Turnout } from './../../model/turnout.model';
import { SwitchTieDetailsModel, SwitchTieReportDetailsModel } from './../../switch-tie-report/models/switch-tie-report-details.model';
import { Observable } from 'rxjs/Observable';
import { LocalStorageService, ReportTable } from './../../services/local-storage.service';
import { Injectable } from '@angular/core';
import { DataService } from './../../services/data.service';
import { AppConstant } from '../constant/constants';
import { UtilService } from '../../util/util.service';

@Injectable()
export class ReportDataService {
  constructor(private localStorageService: LocalStorageService, private dataService: DataService, private util: UtilService) { }

  getLatestUnsyncedReport(projectId: number): Observable<any> {
    return Observable.create((observer: any) => {
      let latestReport;
      this.localStorageService.getProjectReports(projectId).then((reportList: Array<ReportTable>) => {
        reportList.forEach(report => {
          if (report.reportObj && report.reportObj.formId < 0 && report.reportObj.status === 'D') {
            if (!latestReport || report.reportObj.submittedReportDate > latestReport.submittedReportDate) {
              latestReport = report.reportObj;
            }
          }
        });
        observer.next(latestReport);
        observer.complete();
      });
    });
  }

  getLatestSyncedReport(projectId: number): Observable<any> {
    return Observable.create((observer: any) => {
      let latestSyncedReport: any;
      this.localStorageService.getProjectReports(projectId).then((reportList: Array<ReportTable>) => {
        reportList.forEach(report => {
          if (
            report.reportObj &&
            (report.formId > 0 || report.reportObj.status === 'S') &&
            report.reportObj.teamName === this.dataService.team
          ) {
            if (!latestSyncedReport || report.reportObj.workday > latestSyncedReport.workday) {
              latestSyncedReport = report.reportObj;
            } else if (
              report.reportObj.workday === latestSyncedReport.workday &&
              report.reportObj.submittedReportDate > latestSyncedReport.submittedReportDate
            ) {
              latestSyncedReport = report.reportObj;
            }
          }
        });
        observer.next(latestSyncedReport);
        observer.complete();
      });
    });
  }

  getLatestReportBeforePastDay(teamName: string, formId: number, projectReports: any[], workday: any) {
    let latestReportBeforePastDay = null;
    for (let i = 0; i < projectReports.length; i++) {
      if (projectReports[i].teamName === teamName &&
        projectReports[i].status !== 'D' &&
        projectReports[i].formId.toString() !== formId.toString() &&
        (projectReports[i].workday < workday ||
          this.formatDate(projectReports[i].workday) === this.formatDate(workday))
      ) {
        if (!latestReportBeforePastDay) {
          latestReportBeforePastDay = projectReports[i];
        } else {
          if (this.formatDate(projectReports[i].workday) === this.formatDate(latestReportBeforePastDay.workday)) {
            if (Number(projectReports[i].formId) < Number(latestReportBeforePastDay.formId)) {
              latestReportBeforePastDay = projectReports[i];
            }
          } else if (projectReports[i].workday > latestReportBeforePastDay.workday) {
            latestReportBeforePastDay = projectReports[i];
          }
        }
      }
    }
    return latestReportBeforePastDay;
  }

  formatDate(milliseconds: number) {
    const date = new Date(milliseconds);
    return date.getMonth() + '-' + date.getDate() + '-' + date.getFullYear();
  }

  getLatestProdReportFromAllProjects(team: string, workday: any): Observable<any> {
    return Observable.create((observer: any) => {
      let latestReport;
      let currentDay = new Date().getTime();
      if (this.formatDate(currentDay) === this.formatDate(workday)) {
        this.localStorageService.getAllReports().then((reportList: Array<ReportTable>) => {
          reportList.forEach(report => {
            if (report.reportObj && report.reportObj.status !== 'D' && report.reportObj.teamName === team) {
              if (this.formatDate(report.reportObj.workday) === this.formatDate(workday) && report.reportObj.noProductionCode === null) {
                if (!latestReport || report.reportObj.submittedReportDate > latestReport.submittedReportDate) {
                  latestReport = report.reportObj;
                }
              }
            }
          });
          observer.next(latestReport);
          observer.complete();
        });
      } else {
        observer.next(latestReport);
        observer.complete();
      }
    });
  }

  validateDuplicateReports(newReport: any): Promise<boolean> {
    const reportData = { ...newReport };
    if (reportData.direction && reportData.direction === 'D' && reportData.railSide) {
      reportData.railSide = reportData.railSide === 'L' ? 'R' : 'L';
    } else if (reportData.direction && reportData.direction === 'I' && reportData.railSide) {
      reportData.railSide = reportData.railSide;
    } else {
      reportData.railSide = null;
    }
    return new Promise((resolve) => {
      this.localStorageService.getAllReports().then((reportList: Array<ReportTable>) => {
        const duplicateReports = reportList.filter((report) => {
          if (report.reportObj.direction && report.reportObj.direction === 'D' && report.reportObj.railSide) {
            report.reportObj.railSide = report.reportObj.railSide === 'L' ? 'R' : 'L';
          } else if (report.reportObj.direction && report.reportObj.direction === 'I' && report.reportObj.railSide) {
            report.reportObj.railSide = report.reportObj.railSide;
          } else {
            report.reportObj.railSide = null;
          }
          if (
            Number(reportData.formId) !== Number(report.reportObj.formId) &&
            reportData.teamName === report.reportObj.teamName &&
            reportData.trackType === report.reportObj.trackType &&
            reportData.railSide === report.reportObj.railSide &&
            reportData.jobType === report.reportObj.jobType &&
            reportData.prefix === report.reportObj.prefix &&
            this.formatDate(reportData.workday) === this.formatDate(report.reportObj.workday) &&
            this.isMilepostOverlapsInWorkBreakDown(reportData, report.reportObj)
          ) {
            return report;
          }
        });
        resolve(duplicateReports.length > 0);
      });
    });
  }

  private isCutRailMilepostOverlap(newCutRailList: any, existingCutRailList: any): boolean {
    for (const newCutRail of newCutRailList) {
      if (newCutRail.isWorkSkipped === false) {
        for (const existingCutRail of existingCutRailList) {
          if (existingCutRail.isWorkSkipped === false &&
            this.isMilePostOverlap(newCutRail.cutRailBeginMp, newCutRail.cutRailEndMp, existingCutRail.cutRailBeginMp,
              existingCutRail.cutRailEndMp)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  private isSurfacingDetailsOverlap(newSurfacingDetails: any, existingSurfacingDetails: any): boolean {
    for (const newSurfacingDetail of newSurfacingDetails) {
      for (const existingSurfacingDetail of existingSurfacingDetails) {
        if (this.isMilePostOverlap(newSurfacingDetail.beginMP, newSurfacingDetail.endMP, existingSurfacingDetail.beginMP,
          existingSurfacingDetail.endMP)) {
          return true;
        }
      }
    }
    return false;
  }

  private isTieProgressOverlap(newTieProgress: any, existingTieProgress: any): boolean {
    // tslint:disable-next-line:forin
    for (const newTieKey in newTieProgress) {
      for (const existingTieKey in existingTieProgress) {
        if (this.isMilePostOverlap(newTieProgress[newTieKey].beginMilepost, newTieProgress[newTieKey].endMilepost,
          existingTieProgress[existingTieKey].beginMilepost, existingTieProgress[existingTieKey].endMilepost)) {
          return true;
        }
      }
    }
    return false;
  }

  private isSurfaceProgressOverlap(newSurfaceProgress, existingSurfaceProgress): boolean {
    // tslint:disable-next-line:forin
    for (const newSurfaceKey in newSurfaceProgress) {
      for (const existingSurfaceKey in existingSurfaceProgress) {
        if (this.isMilePostOverlap(newSurfaceProgress[newSurfaceKey].beginMP, newSurfaceProgress[newSurfaceKey].endMP,
          existingSurfaceProgress[existingSurfaceKey].beginMP, existingSurfaceProgress[existingSurfaceKey].endMP)) {
          return true;
        }
      }
    }
    return false;
  }

  private isMilepostOverlapsInWorkBreakDown(newReport: any, existingReport: any): boolean {
    let isMilePostOverlap: boolean;
    const workTypeCode = AppConstant.WORKTYPECODE;
    if (newReport.workTypeCode === workTypeCode.RAIL && existingReport.workTypeCode === workTypeCode.RAIL) {
      isMilePostOverlap = this.isCutRailMilepostOverlap(newReport.reportCutRail, existingReport.reportCutRail);
    } else if (newReport.workTypeCode === workTypeCode.SURFACING && existingReport.workTypeCode === workTypeCode.SURFACING) {
      isMilePostOverlap = this.isSurfacingDetailsOverlap(newReport.surfacingDetails, existingReport.surfacingDetails);
    } else if (newReport.workTypeCode === workTypeCode.TIE_SURFACING && existingReport.workTypeCode === workTypeCode.TIE_SURFACING) {
      isMilePostOverlap = this.isTieProgressOverlap(newReport.tsProgress.tieProgress, existingReport.tsProgress.tieProgress);
      if (!isMilePostOverlap) {
        isMilePostOverlap = this.isSurfaceProgressOverlap(newReport.tsProgress.surfaceProgress, existingReport.tsProgress.surfaceProgress);
      }
    } else if (newReport.workTypeCode === workTypeCode.SWITCHTIES && existingReport.workTypeCode === workTypeCode.SWITCHTIES) {
      if (this.isSwitchTieSurfacingDuplicate(newReport, existingReport) || this.isSwitchTieTurnoutDuplicate(newReport, existingReport)) {
        isMilePostOverlap = true;
      }
    }
    return isMilePostOverlap;
  }

  private isSwitchTieTurnoutDuplicate(newReport: SwitchTieReportDetailsModel, existingReport: SwitchTieReportDetailsModel) {
    if (newReport.switchTies && newReport.switchTies.length > 0 && existingReport.switchTies && existingReport.switchTies.length > 0) {
      const newReportTurnouts = newReport.switchTies.map((trunoutDetails: SwitchTieDetailsModel) => trunoutDetails.turnout);
      const existingReportTurnouts = existingReport.switchTies.map((trunoutDetails: SwitchTieDetailsModel) => trunoutDetails.turnout);
      return this.isTurnoutAlreadySelected(newReportTurnouts, existingReportTurnouts);
    }
    return false;
  }

  private isSwitchTieSurfacingDuplicate(newReport: SwitchTieReportDetailsModel, existingReport: SwitchTieReportDetailsModel) {
    if (newReport.surfacing && newReport.surfacing.length > 0 && existingReport.surfacing && existingReport.surfacing.length > 0) {
      for (const newSurfacing of newReport.surfacing) {
        for (const existingSurfacing of existingReport.surfacing) {
          if (newSurfacing.trackDetails.trackType === existingSurfacing.trackDetails.trackType &&
            newSurfacing.trackDetails.trackName === existingSurfacing.trackDetails.trackName) {
            if (this.isSurfacingDetailsOverlap(newSurfacing.surfaceDetails, existingSurfacing.surfaceDetails)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  private isTurnoutAlreadySelected(newReportTurnouts: Array<Turnout>, existingReportTurnouts: Array<Turnout>) {
    for (const newTurnout of newReportTurnouts) {
      for (const existingTurnout of existingReportTurnouts) {
        if (existingTurnout.turnoutId === newTurnout.turnoutId) {
          return true;
        }
      }
    }
    return false;
  }

  private isMilePostOverlap(newLowMp: number, newHighMp: number, existingLowMp: number, existingHighMp: number): boolean {
    if (newLowMp > newHighMp) {
      const tempMp = newLowMp;
      newLowMp = newHighMp;
      newHighMp = tempMp;
    }

    if (existingLowMp > existingHighMp) {
      const tempMp = existingLowMp;
      existingLowMp = existingHighMp;
      existingHighMp = tempMp;
    }
    return ((existingLowMp < newLowMp && newLowMp < existingHighMp)
      || (existingLowMp < newHighMp && newHighMp < existingHighMp)
      || (newLowMp < existingLowMp && existingLowMp < newHighMp)
      || (newLowMp < existingHighMp && existingHighMp < newHighMp)
      || existingLowMp === newLowMp || newHighMp === existingHighMp);
  }
}
