import {Component, Input, Output, EventEmitter} from '@angular/core';
import {ReferenceCode} from '../services/local-storage.service';

@Component({
    selector: 'reference-list',
    templateUrl: './reference-list.component.html'
})

export class ReferenceListComponent {
    @Input() references: Array<ReferenceCode>;
    @Output() deleteReference = new EventEmitter();
    public test: RefCode[];

    constructor() {
        this.test = [new RefCode(1, 2), new RefCode(2, 3)];
    }

    onRefDelete(refType) {
        this.deleteReference.emit(refType);
    }

    hack(json: any) {
        let a: RefCode[] = [];
        console.log(json);
        Object.keys(json).forEach(key => {
                let r: RefCode = {
                    code: key,
                    name: json[key]
                };
                a.push(r);
            }
        );
        console.log(a);
        return a;
    }
}

export class RefCode {
    name: string;
    code: string;

    constructor(name, code) {
        this.name = name;
        this.code = code;
    }
}