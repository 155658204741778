import { SpeedRestrictionModel } from './models/speed-restriction.model';
import { LocalStorageService, ReferenceCode } from './../services/local-storage.service';
import { JobModel } from './../model/job.model';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { KeyValue } from '@angular/common';
import { TrackDisturbanceConstants } from './constants/track-disturbance.constants';
import { TDWorkType } from './enums/td-work-type.enum';
import { LocationModel } from './models/location.model';
import { AppConstant } from '../shared/constant/constants';
import { Turnout } from '../model/turnout.model';
import { OperationMode } from '../shared/enums/operation-mode.enum';

@Component({
  selector: 'app-td-speed-restriction',
  templateUrl: './td-speed-restriction.component.html',
  styleUrls: ['./td-speed-restriction.component.scss']
})
export class TdSpeedRestrictionComponent implements OnInit, OnChanges {
  @Input() tdFormGroup: FormGroup;
  @Input() tdSpeedRestrictionTypes: Array<KeyValue<TDWorkType, Array<KeyValue<number, string>>>>;
  @Input() jobData: JobModel;
  @Input() trackDisturbances: Array<SpeedRestrictionModel>;
  @Input() isFormSubmitted: boolean;
  @Input() disabledOnEdit: boolean;
  @Input() operationMode: OperationMode = OperationMode.CREATE;
  @Input() tracksWhereWorkIsDone?: Array<String>;
  @Input() selectedTurnouts?: Turnout[];

  TrackDisturbanceConstants = TrackDisturbanceConstants;
  workTypes: Array<KeyValue<string, string>> = [];
  trackTypes: Array<KeyValue<string, string>> = [];
  speedValues: Array<KeyValue<number, string>> = [];
  previousRowInvalid: boolean;
  private tracksList: Array<LocationModel> = [];
  private trackTypesListForTD: Array<String> = [];
  private trackTypesForTurnouts: Array<String> = [];
  private isFormGroupEnabled: Boolean = false;

  constructor(private formBuilder: FormBuilder, private localStorageService: LocalStorageService) { }

  ngOnInit() {
    this.initializeData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.trackDisturbances && !changes.trackDisturbances.firstChange) {
      while (this.speedRestrictionsFormArray.length !== 0) {
        this.speedRestrictionsFormArray.removeAt(0);
      }
      this.initializeTDFormGroup();
    }
    if (changes && changes.selectedTurnouts) {
      this.updateTdTrackTypes();
    }
    if (changes && changes.tracksWhereWorkIsDone) {
      this.checkIfTrackDisturbanceRequired();
    }
  }

  updateTdTrackTypes() {
    this.removeTurnoutTracksFromList();
    if (this.selectedTurnouts && this.selectedTurnouts.length > 0) {
      this.selectedTurnouts.forEach((turnout: Turnout) => {
        if (turnout.trackName !== 'O' && turnout.trackName !== 'YD' && turnout.trackName !== 'Y'
          && turnout.trackName !== 'I' && turnout.trackName !== 'F' && turnout.trackName.slice(0, 2) !== 'XO') {
          const turnoutTrackIdentifier = turnout.trackName + '-' + turnout.milepost;
          const trackTypeKeyValue: KeyValue<string, string> = {
            key: turnoutTrackIdentifier,
            value: turnout.trackName + ' | ' + turnout.milepost
          };
          if (this.trackTypes.length === 0 && this.speedRestrictionsFormArray.controls.length === 0
            && this.trackDisturbances.length === 0) {
            this.speedRestrictionsFormArray.push(this.getSpeedRestrictionFormGroup());
          }
          this.trackTypes.push(trackTypeKeyValue);
          this.trackTypesListForTD.push(turnoutTrackIdentifier);
          this.trackTypesForTurnouts.push(turnoutTrackIdentifier);
        }
      });
    }
    // Update control validity if any turnout gets removed.
    this.speedRestrictionsFormArray.controls.forEach((restriction: FormGroup) => {
      restriction.controls.beginMP.updateValueAndValidity();
      restriction.controls.endMP.updateValueAndValidity();
      restriction.controls.trackTypeCode.updateValueAndValidity();
    });
  }

  private removeTurnoutTracksFromList() {
    if (this.trackTypesForTurnouts && this.trackTypesForTurnouts.length > 0) {
      this.trackTypes = this.trackTypes.filter((track: KeyValue<string, string>) => this.trackTypesForTurnouts.indexOf(track.key) === -1);
      this.trackTypesListForTD = this.trackTypesListForTD.filter((track: string) => this.trackTypesForTurnouts.indexOf(track) === -1);
      this.trackTypesForTurnouts = [];
    }
  }

  checkIfTrackDisturbanceRequired() {
    if (this.tracksWhereWorkIsDone.length === 0) {
      this.tdFormGroup.reset();
      this.tdFormGroup.disable();
      this.isFormGroupEnabled = false;
    } else {
      let isTDRequired = false;
      this.trackTypesListForTD.forEach((trackType: String) => {
        if (this.tracksWhereWorkIsDone.indexOf(trackType) !== -1) {
          isTDRequired = true;
        }
      });
      if (isTDRequired) {
        if (!this.isFormGroupEnabled) {
          if (!this.editMode) {
            if (this.viewMode) {
              this.tdFormGroup.disable();
              this.isFormGroupEnabled = false;
            } else {
              this.tdFormGroup.enable();
              this.isFormGroupEnabled = true;
            }
          } else {
            this.isFormGroupEnabled = !this.disabledOnEdit;
          }
        }
      } else {
        this.tdFormGroup.reset();
        for (let i = 0; i < this.speedRestrictionsFormArray.controls.length; i++) {
          if (i !== 0) {
            this.removeSpeedRestrictions(i);
          }
        }
        this.tdFormGroup.disable();
        this.isFormGroupEnabled = false;
      }
    }
  }

  get speedRestrictionsFormArray() {
    return this.tdFormGroup.get('speedRestrictions') as FormArray;
  }

  addNewTDRow() {
    if (this.speedRestrictionsFormArray.valid) {
      this.speedRestrictionsFormArray.push(this.getSpeedRestrictionFormGroup());
      this.previousRowInvalid = false;
    } else {
      this.previousRowInvalid = true;
    }
    this.tdFormGroup.updateValueAndValidity();
  }

  removeSpeedRestrictions(index: number) {
    this.speedRestrictionsFormArray.removeAt(index);
    this.tdFormGroup.updateValueAndValidity();
  }

  onTrackTypeChange(currentRow: FormGroup) {
    let selectedTrack: LocationModel;
    this.tracksList.forEach((track: LocationModel) => {
      if (currentRow.controls.trackTypeCode.value.split('-')[0] === 'SD'
        && track.trackName === currentRow.controls.trackTypeCode.value.split('-').slice(1).join('-')) {
        selectedTrack = track;
      } else if (track.trackType === currentRow.controls.trackTypeCode.value) {
        selectedTrack = track;
      } else if (track.trackType + '-' + track.trackName === currentRow.controls.trackTypeCode.value) {
        selectedTrack = track;
      }
    });
    currentRow.controls.prefix.setValue(selectedTrack && selectedTrack.prefix ? selectedTrack.prefix : this.jobData.prefix);
    this.updateMilepostsValidity();
    this.tdFormGroup.updateValueAndValidity();
  }

  updateMilepostsValidity() {
    this.speedRestrictionsFormArray.controls.forEach((restriction: FormGroup) => {
      restriction.controls.beginMP.updateValueAndValidity();
      restriction.controls.endMP.updateValueAndValidity();
    });
  }

  getSpeedValues(currentTrackType: string, beginMP: number, endMP: number) {
    if (currentTrackType) {
      const currentTrack = this.getCurrentSpeedTrack(currentTrackType, beginMP, endMP);
      if (currentTrack && currentTrack.length && this.speedValues.findIndex(item => item.value === 'N/A') === -1) {
        this.speedValues.unshift({ key: 0, value: 'N/A' });
      } else {
        if ((!currentTrack || !currentTrack.length) && this.speedValues.findIndex(item => item.value === 'N/A') !== -1) {
          this.speedValues.splice(0, 1);
        }
      }
    }
    return this.speedValues;
  }

  getRestrictionsTypes(workType: TDWorkType, currentTrackType: string, beginMP: number, endMP: number) {
    const restrictionTypes = [];
    if (currentTrackType) {
      const currentTrack = this.getCurrentSpeedTrack(currentTrackType, beginMP, endMP);
      if (currentTrack && currentTrack.length && restrictionTypes.findIndex(item => item.value === 'N/A') === -1) {
        restrictionTypes.push({ key: 0, value: 'N/A' });
      }
    }
    if (workType) {
      // Get work type and restriction type from Input property+++
      const currentRestrictions: KeyValue<TDWorkType, Array<KeyValue<number, string>>> =
        this.tdSpeedRestrictionTypes.filter((item: KeyValue<TDWorkType, Array<KeyValue<number, string>>>) => item.key === workType)[0];
      restrictionTypes.push(...currentRestrictions.value);
      return restrictionTypes;
    }
  }

  private initializeData() {
    this.localStorageService.getAllRefereces().then((references: Array<ReferenceCode>) => {
      // Get speed values
      for (const ref of references) {
        if (ref.refType === 'TDS') {
          this.speedValues = Object.keys(ref.refObj).map((key: string) => {
            return {
              key: Number(key),
              value: String(key)
            };
          });
        }
      }
      // Get work type and restriction type from Input property
      for (let i = 0; i < this.tdSpeedRestrictionTypes.length; i++) {
        this.workTypes.push(TrackDisturbanceConstants.WORK_TYPES[i]);
      }

      // For surfacing jobs, there will be only one track type
      if (
        this.jobData.trackType !== 'INT'
      ) {
        this.trackTypes.push({
          key: this.jobData.trackType,
          value: this.jobData.trackType
        });
        this.trackTypesListForTD.push(this.jobData.trackType);
      }
      // For Tie jobs, there can be other siding tracks as well
      if (this.jobData.tieTrackList && this.jobData.tieTrackList.length > 0) {
        this.jobData.tieTrackList.forEach(tieTrack => {
          if (tieTrack.trackType !== 'O' && tieTrack.trackType !== 'YD' && tieTrack.trackType !== 'Y'
            && tieTrack.trackType !== 'I' && tieTrack.trackType !== 'F' && tieTrack.trackType.slice(0, 2) !== 'XO') {
            const identifier = tieTrack.trackName ? tieTrack.trackName : tieTrack.trackType;
            if (this.trackTypes.findIndex((track: KeyValue<string, string>) => track.key === identifier) === -1) {
              this.trackTypes.push({
                key: tieTrack.trackType + '-' + identifier,
                value: identifier
              });
              this.trackTypesListForTD.push(tieTrack.trackType);
            }
          }
        });
      }
      // Set tracks list based on master track list and tie tracks list
      this.tracksList = [...this.jobData.masterTrackList];
      if (this.jobData.tieTrackList && this.jobData.tieTrackList.length > 0) {
        this.jobData.tieTrackList.forEach((track: any) => {
          const location: LocationModel = new LocationModel();
          location.prefix = track.prefix;
          location.beginMp = track.beginMp;
          location.endMp = track.endMP;
          location.trackType = track.trackType;
          location.trackName = track.trackName;
          this.tracksList.push(location);
        });
      }
      this.initializeTDFormGroup();
    });
  }

  private initializeTDFormGroup() {
    if (this.trackTypes && this.trackTypes.length > 0) {
      if (this.trackDisturbances && this.trackDisturbances.length > 0) {
        this.trackDisturbances.forEach((disturbance: SpeedRestrictionModel) => {
          this.speedRestrictionsFormArray.push(this.getSpeedRestrictionFormGroup(disturbance));
        });
      } else {
        this.speedRestrictionsFormArray.push(this.getSpeedRestrictionFormGroup());
      }
      this.tdFormGroup.updateValueAndValidity();
    }
    this.tdFormGroup.updateValueAndValidity();
    if (this.disabledOnEdit) {
      this.tdFormGroup.disable();
    }
  }

  private getSpeedRestrictionFormGroup(dataModel?: SpeedRestrictionModel) {
    const fg =  this.formBuilder.group({
      'workTypeCode': new FormControl(dataModel ? dataModel.workTypeCode : null, [Validators.required]),
      'beginMP': new FormControl(dataModel ? dataModel.beginMP : '',
        [Validators.required, Validators.pattern('(^(0{0,1}|([1-9][0-9]*))(.[0-9]{1,2})?$)'), this.milepostValidator('endMP')]),
      'endMP': new FormControl(dataModel ? dataModel.endMP : '',
        [Validators.required, Validators.pattern('(^(0{0,1}|([1-9][0-9]*))(.[0-9]{1,2})?$)'), this.milepostValidator('beginMP')]),
      'trackTypeCode': new FormControl(dataModel ? dataModel.trackTypeCode : null, [Validators.required]),
      'railTypeCode': new FormControl(dataModel ? dataModel.railTypeCode : null, [Validators.required]),
      'ballastSufficient': new FormControl(dataModel ? dataModel.ballastSufficient : null, [Validators.required]),
      'speedType': new FormControl(dataModel ? Number(dataModel.speedType) : null, [Validators.required]),
      'speedValue': new FormControl(dataModel ? dataModel.speedValue : null, [Validators.required]),
      'sequenceId': new FormControl(dataModel ? dataModel.sequenceId : this.speedRestrictionsFormArray.length + 1),
      'prefix': new FormControl(dataModel ? dataModel.prefix : null)
    });
    if (this.editMode) {
      fg.get('workTypeCode').disable();
    }
    return fg;
  }

  private milepostValidator(fieldToCompare: string) {
    return (control: AbstractControl) => {
      if ((control.value === null || control.value === undefined) || control.invalid ||
        !control.parent || !control.parent.get('trackTypeCode').value) {
        return null;
      } else {
        const fieldCompare = control.parent.controls[fieldToCompare].value;
        const currentControlValue = parseFloat(control.value);
        let beginMP, endMP;
        if (fieldToCompare === 'endMP') {
          beginMP = currentControlValue;
          endMP = parseFloat(fieldCompare);
        } else {
          endMP = currentControlValue;
          beginMP = parseFloat(fieldCompare);
        }
        let currentTrackType: string = control.parent.get('trackTypeCode').value;
        if (this.trackTypesForTurnouts.indexOf(currentTrackType) !== -1) {
          return this.validateTurnoutTrackMileposts(beginMP, endMP, currentTrackType, control);
        } else {
          // Begin MP should not be equal to end MP and should be less than end MP
          if (beginMP === endMP || beginMP > endMP) {
            return { invalid: true };
          } else {
            if (this.tracksList && this.tracksList.length > 0) {
              // Valid track as per master track data
              let valid = false;
              if (currentTrackType.indexOf('-') !== -1) {
                currentTrackType = currentTrackType.split('-').slice(1).join('-');
              }
              for (const track of this.tracksList) {
                if (track && (track.trackName ? track.trackName : track.trackType) === currentTrackType
                  && (parseFloat(fieldCompare) >= track.beginMp && parseFloat(fieldCompare) <= track.endMp)
                  && (parseFloat(control.value) >= track.beginMp && parseFloat(control.value) <= track.endMp)) {
                  valid = true;
                  break;
                }
              }
              if (!valid) {
                return { invalid: true };
              } else {
                return this.isMilepostOverlap(control, beginMP, endMP) ? { overlap: true } : null;
              }
            } else {
              // Current MP should not overlap with milepost range in other TD rows
              return this.isMilepostOverlap(control, beginMP, endMP) ? { overlap: true } : null;
            }
          }
        }
      }
    };
  }

  protected getSpeedRestrictionValue() {
    return this.editMode ? this.speedRestrictionsFormArray.getRawValue() : this.speedRestrictionsFormArray.value;
  }

  private validateTurnoutTrackMileposts(beginMP: number, endMP: number, currentTrackType: string, control: AbstractControl) {
    const turnoutMilepost = currentTrackType.split('-')[1];
    if ((beginMP !== endMP) || (beginMP !== Number(turnoutMilepost))) {
      return { invalid: true };
    }
    const currentTurnout = this.getCurrentTurnout(currentTrackType);
    if (currentTurnout && currentTurnout.trackClass === 'CLASS 1' && currentTurnout.isPassengerFlag === 'N') {
      control.parent.controls['speedValue'].disable();
      control.parent.controls['speedType'].disable();
      control.parent.controls['speedValue'].reset();
      control.parent.controls['speedType'].reset();
    } else {
      control.parent.controls['speedValue'].enable();
      control.parent.controls['speedType'].enable();
    }
    return null;
  }

  private isMilepostOverlap(control: AbstractControl, beginMP, endMP) {
    const currentRowTrackType = control.parent.controls['trackTypeCode'].value;
    const currentRowWorkType = control.parent.controls['workTypeCode'].value;
    if (currentRowTrackType && currentRowWorkType) {
      const overlappingRow = this.speedRestrictionsFormArray.value.find((disturbance: SpeedRestrictionModel) => {
        return control.parent.controls['sequenceId'].value !== disturbance.sequenceId &&
          (currentRowWorkType === disturbance.workTypeCode && currentRowTrackType === disturbance.trackTypeCode &&
            ((disturbance.beginMP < beginMP && disturbance.endMP > endMP) || (disturbance.beginMP < endMP && disturbance.endMP > beginMP))
          );
      });
      if (overlappingRow) {
        return true;
      } else {
        this.validateTrackClass(control, beginMP, endMP);
        return false;
      }
    } else {
      return false;
    }
  }

  private validateTrackClass(control: AbstractControl, beginMP, endMP) {
    let currentRowTrackType = control.parent.controls['trackTypeCode'].value;
    if (currentRowTrackType.indexOf('-') !== -1) {
      currentRowTrackType = currentRowTrackType.split('-')[0];
    }
    if (currentRowTrackType &&
      this.jobData.speedTrackClassList &&
      this.jobData.speedTrackClassList.findIndex(item => item.trackClass === 'CLASS 1' && currentRowTrackType === item.trackType &&
        item.isPassengerFlag === 'N' && beginMP >= item.beginMp && endMP <= item.endMp) > -1) {
      control.parent.controls['speedValue'].disable();
      control.parent.controls['speedType'].disable();
      control.parent.controls['speedValue'].reset();
      control.parent.controls['speedType'].reset();
    } else {
      control.parent.controls['speedValue'].enable();
      control.parent.controls['speedType'].enable();
    }
  }

  private getCurrentSpeedTrack(currentTrackType: string, beginMP: number, endMP: number): Array<LocationModel> {
    if (this.trackTypesForTurnouts.indexOf(currentTrackType) !== -1) {
      const currentTurnout = this.getCurrentTurnout(currentTrackType);
      return !currentTurnout.trackClass ? [new LocationModel()] : [];
    } else {
      if (currentTrackType.indexOf('-') !== -1) {
        currentTrackType = currentTrackType.split('-')[0];
      }
      return this.jobData.speedTrackClassList ?
        this.jobData.speedTrackClassList.filter((track: LocationModel) => track.trackType === currentTrackType
          && track.trackClass === 'UNKNOWN' && beginMP >= track.beginMp && endMP <= track.endMp) : [];
    }
  }

  private getCurrentTurnout(currentTrackType: string) {
    return this.selectedTurnouts.filter((turnout: Turnout) => (turnout.trackName + '-' + turnout.milepost) === currentTrackType)[0];
  }

  
  get viewMode() {
    return this.operationMode === OperationMode.VIEW;
  }

  get editMode() {
    return this.operationMode === OperationMode.EDIT;
  }
}