import { Component, OnInit } from '@angular/core';
import { RoadCrossingComponent } from '../road-crossing.component';
import { Router, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { DataService } from '../../../services/data.service';
import { LocalStorageService, ReportTable } from '../../../services/local-storage.service';
import { OperationMode } from '../../../shared/enums/operation-mode.enum';
import { ReportNavigationService } from '../../../shared/services/report-navigation.service';

@Component({
  selector: 'app-road-crossing-edit',
  templateUrl: '../road-crossing.component.html',
  styleUrls: ['../road-crossing.component.scss']
})
export class RoadCrossingEditComponent extends RoadCrossingComponent implements OnInit {
  isTDEditable: Boolean;

  constructor(
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected dataService: DataService,
    protected ls: LocalStorageService,
    public reportNavigationService: ReportNavigationService
  ) {
    super(router, activeRoute, dataService, ls);
  }

  ngOnInit() {
    this.isTDEditable = this.activeRoute.snapshot.queryParams.isTdEditable === 'true' ? true : false;
    this.operationMode = OperationMode.EDIT;
    super.ngOnInit();
  }

  get editMode() {
    return this.operationMode === OperationMode.EDIT;
  }

  protected checkReportOperationMode() {
    this.operationMode = OperationMode.EDIT;
  }

  protected getReportMethod() {
    return this.dataService.getEditedReportByFormId(this.formId);
  }

  protected saveReportToIndexDbMethod(reportObject: any) {
    const report: ReportTable = {
      formId: Number(reportObject.formId),
      jobId: Number(reportObject.jobId),
      projectId: Number(reportObject.projectId),
      reportObj: reportObject
    };
    return this.dataService.addEditedReport(report);
  }
  protected navigateToNextPage(navigateTo: string) {
    this.router.navigate([`/${navigateTo}/edit/${this.projectId}/${this.jobId}/${this.formId}`], {
      queryParams: {
        isTdEditable: this.isTDEditable
      }
    });
  }

  backIcon() {
    this.reportNavigationService.navigateToJobView(this.projectId, this.formId);
  }

  protected backNavigate(event) {
    this.deleteObj(this.reportData);
    this.saveReportToIndexDbMethod(this.reportData).then(() => {
      const path = '/planned_actual/edit/' + this.projectId + '/' + this.jobId + '/' + this.formId;
      this.router.navigate([path], this.getqueryParams());
    })
      .catch(reason => {
        console.log(reason);
      });
  }


  private getqueryParams() {
    return {
      queryParams: {
        isTdEditable: this.isTDEditable
      }
    };
  }

}
