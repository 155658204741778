import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Turnout } from '../../model/turnout.model';
import { TurnoutForm } from '../turnout-card/model/turnout-form.model';
import { TurnoutTable, LocalStorageService } from '../../services/local-storage.service';

@Injectable()
export class TurnoutFormService {
  private turnoutsContainerForm: BehaviorSubject<FormGroup> = new BehaviorSubject(this.fb.group({ turnoutForms: this.fb.array([]) }));

  turnoutsContainerForm$: Observable<FormGroup> = this.turnoutsContainerForm.asObservable();

  constructor(private fb: FormBuilder, private ls: LocalStorageService) {}

  async addTurnout(to: Turnout, projectId, edit?: boolean) {
    const currentTurnoutContainter: FormGroup = this.turnoutsContainerForm.value;
    const currentTurnouts: FormArray = currentTurnoutContainter.get('turnoutForms') as FormArray;
    if (edit) {
      console.log('turnout id', to.turnoutId);
      await this.ls.getTurnout(to.turnoutId, projectId).then((t1: TurnoutTable) => {
        console.log('t1', t1);
        let tobj = JSON.parse(JSON.stringify(t1.turnoutObj));
        to.trackName = tobj.trackName;
        to.direction = tobj.direction;
        to.turnoutSize = tobj.turnoutSize;
        to.railWeight = tobj.railWeight;
        to.milepost = tobj.milepost;
        to.endMp = tobj.endMp;
        to.switchName = tobj.switchName;
        to.prefix = tobj.prefix;
        to.f_t = tobj.f_t;
        to.trackType = tobj.trackType;
        currentTurnouts.push(this.fb.group(new TurnoutForm(to, currentTurnouts.length, edit)));
      });
    } else {
      currentTurnouts.push(this.fb.group(new TurnoutForm(to, currentTurnouts.length, edit)));
    }
    this.turnoutsContainerForm.next(currentTurnoutContainter);
  }

  deleteTurnout(i: number) {
    const currentTurnoutContainter = this.turnoutsContainerForm.getValue();
    const currentTurnouts = currentTurnoutContainter.get('turnoutForms') as FormArray;
    currentTurnouts.removeAt(i);
    this.turnoutsContainerForm.next(currentTurnoutContainter);
  }

  deleteTurnoutById(i: number) {
    const currentTurnoutContainter = this.turnoutsContainerForm.getValue();
    const currentTurnouts = currentTurnoutContainter.get('turnoutForms') as FormArray;
    let index = 0;
    let removedIndex;
    currentTurnouts.controls.forEach(element => {
      if (element instanceof FormGroup) {
        let t = element.get('turnoutId').value;
        if (t === i) {
          removedIndex = index;
        }
      }
      index++;
    });
    console.log('index', index);
    console.log('removeindex', removedIndex);
    currentTurnouts.removeAt(removedIndex);
    this.turnoutsContainerForm.next(currentTurnoutContainter);
  }

  clearData() {
    console.log('clear data');
    this.turnoutsContainerForm = new BehaviorSubject(this.fb.group({ turnoutForms: this.fb.array([]) }));
  }
}
