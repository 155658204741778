import {Component, OnInit, Input, Output, OnDestroy, EventEmitter} from '@angular/core';
import { ErrorDialog } from '../model/error-dialog.model';

@Component({
    selector: 'app-error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit, OnDestroy {

    @Input() display: boolean;
    @Input() dialogData: ErrorDialog;
    @Output() displayChange = new EventEmitter();
    dialogHeader = 'TEST';
    dialogBody = 'TEST';

    onShow() {
        if (!navigator.onLine) {
            this.dialogHeader = 'Network Error';
            this.dialogBody = 'Network connection not found. Check your connection and try again.';
        }
    }

    onClose() {
        this.displayChange.emit(false);
    }

    ngOnDestroy() {
        this.displayChange.unsubscribe();
    }

    constructor() {
    }

    ngOnInit() {
        if (this.dialogData) {
            this.dialogHeader = this.dialogData.dialogHeader;
            this.dialogBody = this.dialogData.dialogBody;
        } else if (!navigator.onLine) {
            this.dialogHeader = 'Network Error';
            this.dialogBody = 'Network connection not found. Check your connection and try again.';
        }
    }
}
