import { Component, OnInit } from '@angular/core';
import { BaseReportEditMixin } from '../../../base-report/base-report-edit-mixin';
import { TsInfoComponent } from '../ts-info.component';
import { FormBuilder } from '../../../../../node_modules/@angular/forms';
import { DataService } from '../../../services/data.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { Router, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { UtilService } from '../../../util/util.service';
import { ReportDataService } from '../../../shared/services/report-data.service';
import { ConfirmationService } from '../../../../../node_modules/primeng/primeng';
import { ReportNavigationService } from '../../../shared/services/report-navigation.service';

@Component({
  selector: 'app-ts-info-edit',
  templateUrl: '../ts-info.component.html',
  styleUrls: ['../ts-info.component.scss']
})
export class TsInfoEditComponent  extends BaseReportEditMixin(TsInfoComponent) implements OnInit {

  constructor(
    public fb: FormBuilder,
      public dataService: DataService,
      public ls: LocalStorageService,
      public router: Router,
      public Activateroute: ActivatedRoute,
      public utilService: UtilService,
      public reportDataServcie: ReportDataService,
      public acknowledgeService: ConfirmationService,
      public reportNavigationService: ReportNavigationService
  ) {
    super(fb, dataService, ls, router, Activateroute, utilService, reportDataServcie, acknowledgeService, reportNavigationService);
  }

  protected getTdValue() {
    return this.tdFormGroup.get('speedRestrictions').getRawValue().filter(data => data.workTypeCode !== null );
  }
}
