import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/primeng';
import { BaseRailReportEditMixin } from '../../base-report/base-rail-report-edit-mixin';
import { BaseReportEditMixin } from '../../base-report/base-report-edit-mixin';
import { DataService } from '../../services/data.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { ReportDataService } from '../../shared/services/report-data.service';
import { UtilService } from '../../util/util.service';
import { RailInfoComponent } from '../rail-info.component';
import { ReportNavigationService } from './../../shared/services/report-navigation.service';

const BaseRailReportEdit = BaseRailReportEditMixin(BaseReportEditMixin(RailInfoComponent));
@Component({
  selector: 'app-rail-report-edit',
  templateUrl: '../rail-info.component.html',
  styleUrls: ['../rail-info.component.scss']
})
export class RailReportEditComponent extends BaseRailReportEdit {
  constructor(
    public fb: FormBuilder,
    public dataService: DataService,
    public ls: LocalStorageService,
    public router: Router,
    public Activateroute: ActivatedRoute,
    public utilService: UtilService,
    public reportDataServcie: ReportDataService,
    public acknowledgeService: ConfirmationService,
    public reportNavigationService: ReportNavigationService
  ) {
    super(fb, dataService, ls, router, Activateroute, utilService, reportDataServcie, acknowledgeService, reportNavigationService);
  }

  protected disableReportFields() {
    super.disableReportFields();
    if (!this.isTDEditable) {
      this.formGroup.controls.railType.disable();
      this.formGroup.controls.isTunnel.disable();
      this.formGroup.controls.railType.clearValidators();
    }
  }

}
