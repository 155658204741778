import { WorkType } from './../../enums/work-type.enum';
import { DataService } from './../../services/data.service';
import { Observable } from 'rxjs/Observable';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService, ReportTable } from './../../services/local-storage.service';
import { Component, OnInit } from '@angular/core';
import { IProjectStats } from './models/project-stats.interface';
@Component({
  selector: 'app-job-container',
  templateUrl: './job-container.component.html',
  styleUrls: ['./job-container.component.scss']
})
export class JobContainerComponent implements OnInit {
  projectStats: IProjectStats = {} as IProjectStats;
  constructor(private route: ActivatedRoute, private localStorage: LocalStorageService, private dataService: DataService) { }

  ngOnInit() {
    this.initializeProjectAggregateData();
  }

  setProjectStatsFromReports(projectReports: Array<any>) {
    const reportDates = [];
    let totalWindowTime = 0;
    this.projectStats.unitsInstalled = 0;
    projectReports.forEach(report => {
      if (report.status !== 'D' && report.noProductionCode !== 'RST' && report.teamName === this.dataService.team) {
        // add to units installed based on report type
        if (report['workTypeCode'] === WorkType.SWITCHTIE) {
          if (report['totalTiesInstalled']) {
            this.projectStats.unitsInstalled += Number(report['totalTiesInstalled']);
          }
        } else {
          if (report['jobType'] === 'TS') {
            if (report['totalTiesInstalled']) {
              this.projectStats.unitsInstalled += Number(report['totalTiesInstalled']);
            }
          } else if (report['jobType'] === 'SO') {
            if (report['totalSurfacedFeet']) {
              this.projectStats.unitsInstalled += Number(report['totalSurfacedFeet']);
            }
          } else {
            if (report['railLength']) {
              this.projectStats.unitsInstalled += Number(report['railLength']);
            }
          }
        }
        // add window time from all reports
        if (report.reportWindows && report.reportWindows.length > 0) {
          report.reportWindows.forEach(window => {
            if (window.windowEnd && window.windowStart) {
              totalWindowTime += window.windowEnd - window.windowStart;
            }
          });
        }
        // push unique dates to temp array for days worked calculation
        const formattedDate = this.formatDate(report.workday);
        if (reportDates.indexOf(formattedDate) === -1) {
          reportDates.push(formattedDate);
        }
      }
    });
    this.projectStats.unitsRemaining = this.projectStats.plannedUnits - this.projectStats.unitsInstalled;
    this.projectStats.unitsRemaining = this.projectStats.unitsRemaining > 0 ? this.projectStats.unitsRemaining : 0;
    this.projectStats.daysWorked = reportDates.length;
    this.projectStats.daysRemaining = this.projectStats.plannedDays - this.projectStats.daysWorked;
    this.projectStats.daysRemaining = this.projectStats.daysRemaining > 0 ? this.projectStats.daysRemaining : 0;
    this.projectStats.averageUnitsToComplete =
      this.projectStats.unitsRemaining && this.projectStats.daysRemaining
        ? Math.round(this.projectStats.unitsRemaining / this.projectStats.daysRemaining)
        : 0;
    this.projectStats.averageTrackWindowHours =
      totalWindowTime && this.projectStats.daysWorked ? Math.round(totalWindowTime / (1000 * 60 * 60) / this.projectStats.daysWorked) : 0;
  }

  private formatDate(milliseconds: number) {
    const date = new Date(milliseconds);
    return date.getMonth() + '-' + date.getDate() + '-' + date.getFullYear();
  }

  initializeProjectAggregateData() {
    const projectId = Number(this.route.snapshot.params.project);
    Observable.forkJoin(this.localStorage.getProject(projectId), this.localStorage.getProjectReports(projectId)).subscribe(response => {
      // Set stats from project object fetched from index db
      const projectInfo = response[0].projectObj;
      this.projectStats.plannedUnits = Number(projectInfo['totalPlannedProjectUnits']);
      this.projectStats.plannedDays = Number(projectInfo['totalPlannedProjectDays']);
      this.projectStats.plannedDailyProduction = Math.round(this.projectStats.plannedUnits / this.projectStats.plannedDays);

      // Set stats from project reports fetched from index db
      const projectReports = response[1].map((report: ReportTable) => report.reportObj);
      this.setProjectStatsFromReports(projectReports);
    });
  }
}
