import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Router, NavigationEnd} from '@angular/router';
import 'rxjs/add/operator/filter';
import {Menu} from './shared/menu';

declare const Flex;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    private menu = new Menu();
    counter = 0;
    menuItems = this.menu.menuItems;
    appLongName = 'System Track Works';
    appShortName = 'STW';
    needsBack = true;

    constructor(private _router: Router, private location: Location) {
        _router.events
            .filter(event => event instanceof NavigationEnd).subscribe((val) => {
            const path = this.location.path();
            if (path === '/main') {
                this.needsBack = false;
            } else {
                this.needsBack = true;
            }
        });

    }

    ngOnInit() {

    }
}
