import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JobTable, LocalStorageService, ProjectTable, ReportTable } from '../services/local-storage.service';

import { DataService } from '../services/data.service';
import { NoProductionRestdayComponent } from '../no-production-restday/no-production-restday.component';
import { Observable } from 'rxjs/Observable';
import { ReferencePipe } from '../pipes/reference.pipe';
import { Subject } from 'rxjs/Subject';
import { UtilService } from '../util/util.service';

@Component({
  selector: 'app-submit-confirmation',
  templateUrl: './submit-confirmation.component.html',
  styleUrls: ['./submit-confirmation.component.scss']
})
export class SubmitConfirmationComponent implements OnInit {
  @Input() viewMode?: boolean;
  dateRange: any[];
  reportstatus: boolean;
  reportObj: string;
  jobFormId: number;
  display = false;
  formId: number;
  newformId: number;
  isDialogOpenSubmit = false;
  isDialogDelete = false;
  deleteconfirmation = false;
  reportSucess = true;
  jobData: any = [];
  @Input()
  reportData: any = null;
  reportType: string;
  projectData: string = null;
  job: number = null;
  jobName: string;
  mpData: String = null;
  divisionData: string = null;
  projectId: number;
  jobType: string;
  @Input()
  parentSubject: Subject<any>;
  @Output()
  submitclick = new EventEmitter();
  @Output()
  backclick = new EventEmitter();
  @Input()
  restDaysFormIdsRange;
  isTDEditable: boolean;
  constructor(
    public dataService: DataService,
    public activeRoute: ActivatedRoute,
    private reference: ReferencePipe,
    public router: Router,
    public Util: UtilService,
    public localStorage: LocalStorageService,
    public rest: NoProductionRestdayComponent
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.parentSubject.subscribe(event => {
        this.getReportData();
      });
    }, 300);
    this.activeRoute.params.subscribe((params: any) => {
      this.projectData = params.project;
      this.jobData = params.job;
      this.formId = params.form;
      if (this.projectData) {
        this.projectId = Number(this.projectData.split('-')[0]);
      }
      if (this.jobData) {
        this.job = Number(this.jobData.split('-')[0]);
      }
      this.dataService.getJobInfo(this.job).then((jobId1: JobTable) => {
        const jo = JSON.parse(JSON.stringify(jobId1.jobObj));
        this.jobType = jo.jobTypeCode;
        this.jobName = this.reference.transform(jo.jobTypeCode, 'REQ');
        this.mpData = jo.prefix + ' ' + jo.startMP + '-' + jo.endMP;
        this.divisionData = this.reference.transform(jo.division, 'DIV') + ',' + this.reference.transform(jo.subdivision, 'SUB');
      });
    });
    this.newformId = Number(this.dataService.getFormIdForSubmit());
    this.getReport();
  }

  getReport() {
    this.dataService.getReport(Number(this.formId)).then(response => {
      if (response) {
        this.reportData = response.reportObj;
        this.setReportType();
      }
    });
  }

  setReportType() {
    this.reportType = this.Util.determineReportType(this.reportData);
    console.log('this.reportType', this.reportType);
  }

  editNav() {
    if (this.jobType === 'PR' || this.jobType === 'OF') {
      this.router.navigate([`/rail_info/${this.projectId}/${this.job}/${this.formId}`]);
    } else if (this.jobType === 'GA' || this.jobType === 'CP') {
      this.router.navigate([`/gauging_info/${this.projectId}/${this.job}/${this.formId}`]);
    }
  }

  OnChanges(changes: any) {
    // only run when property "data" changed
    if (changes['submitpopup']) {
      // this.groupPosts = this.groupByCategory(this.data);
    }
    if (changes['backButtonPopup']) {
      // this.groupPosts = this.groupByCategory(this.data);
    }
  }

  backButtonPopup() {
    this.backclick.emit('true');
  }

  submitpopup() {
    this.submitclick.emit('true');
    if (this.reportData === null || this.reportData === undefined) {
      this.getReport();
    }
  }

  toggleDialogSubmit() {
    this.isDialogOpenSubmit = !this.isDialogOpenSubmit;
  }

  toggleconfirmation() {
    this.restDaysFormIdsRange = this.rest.passData();
    console.log('this.restDaysFormIdsRange1', this.restDaysFormIdsRange);
    this.deleteconfirmation = !this.deleteconfirmation;
    this.isDialogDelete = !this.isDialogDelete;
    if (this.reportData.noProductionCode === 'RST') {
      for (let i = 0; i < this.restDaysFormIdsRange.length; i++) {
        this.dataService.deleteReport(Number(this.restDaysFormIdsRange[i])).then(response => {
          console.log('Successfully Deleted Rest day', this.restDaysFormIdsRange[i], 'report from indexdb');
        });
      }
      console.log('deleting multiple reports from indexdb');
    } else {
      this.dataService.deleteReport(Number(this.formId)).then(response => {
        console.log('deleting this row from indexdb', this.formId);
      });
    }
  }

  toggledelete() {
    this.isDialogDelete = !this.isDialogDelete;
  }

  getReportData() {
    let stopCondition = false;
    Observable.interval(300)
      .takeWhile(() => !stopCondition)
      .subscribe(i => {
        console.log(' FORM ID ####', Number(this.formId));
        this.dataService.getReport(Number(this.formId)).then(response => {
          if (response) {
            this.reportData = JSON.parse(JSON.stringify(response.reportObj));
            console.log('Report data is', this.reportData);
          }
          if (this.reportData && Number(this.formId) < 0) {
            this.isDialogOpenSubmit = !this.isDialogOpenSubmit;
          }
          stopCondition = true;
        });
      });
  }

  loadLastJobReports(): any {
    this.localStorage.getJobReports(Number(this.job)).then((reportList: Array<ReportTable>) => {
      // *******  this list is to get the previous report
      if (reportList.length > 0) {
        for (const p of reportList) {
          const js: any = JSON.parse(JSON.stringify(p.reportObj));
          console.log('Submit confirmation', reportList);
          this.reportObj = reportList[reportList.length - 1].reportObj;
        }
        return this.reportObj;
      }
    });
  }
  OnDestroy() {
    // needed if child gets re-created (eg on some model changes)
    // note that subsequent subscriptions on the same subject will fail
    // so the parent has to re-create parentSubject on changes
    this.parentSubject.unsubscribe();
  }

}
