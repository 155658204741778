import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/from';

interface STWEvent {
  name: EventType;
  data: STWEventData;
}

@Injectable()
export class EventBusService {
  private events: Subject<STWEvent>;

  constructor() {
    this.events = new Subject<STWEvent>();
  }

  public fireEvent(event: EventType, eventData: STWEventData): void {
    this.events.next({ name: event, data: eventData });
  }

  public getEventBus(event: EventType): Observable<STWEventData> {
    return Observable.from(this.events)
      .filter(m => m.name === event)
      .map(m => m.data);
  }
}

export class STWEventData {
  constructor(public data: any, public getString?: Function) {}
}

export enum EventType {
  PROXY_CHANGE_EVENT,
  NOTIFY_FAILURE_EVENT,
  NOTIFY_SUCCESS_EVENT,
  PROJECTS_UPDATED_EVENT,
  TURNOUT_DIALOG_OPEN_EVENT,
  TURNOUT_DIALOG_APPLY_EVENT
}
