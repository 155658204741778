import { SurfacingSegmentModel } from './../../models/switch-tie-report-details.model';
import { KeyValue } from '@angular/common';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { UtilService } from '../../../util/util.service';
import { JobModel } from '../../../model/job.model';
import { WorkDirection } from '../../../enums/work-direction.enum';
import { LocationModel } from '../../../td-speed-restriction/models/location.model';
import { SurfacingDetailsModel } from '../../models/switch-tie-report-details.model';
import { AppConstant } from '../../../shared/constant/constants';
import { OperationMode } from '../../../shared/enums/operation-mode.enum';

@Component({
  selector: 'app-switch-tie-surfacing',
  templateUrl: './switch-tie-surfacing.component.html',
  styleUrls: ['./switch-tie-surfacing.component.scss']
})
export class SwitchTieSurfacingComponent implements OnInit, OnChanges {
  @Input() formGroup: FormGroup;
  @Input() isFormSubmitted: boolean;
  @Input() jobData: JobModel;
  @Input() direction: WorkDirection;
  @Input() surfacingList: Array<SurfacingDetailsModel>;
  @Input() operationMode: OperationMode = OperationMode.CREATE;
  crossingSurfacedOptions: KeyValue<string, boolean>[] = [
    { key: 'Yes', value: true },
    { key: 'No', value: false }
  ];
  calculatedSurface: number;
  selectedSidingIndex: number;
  private tracksList: Array<LocationModel> = [];
  private milepostPattern = '(^(0{0,1}|([1-9][0-9]*))(.[0-9]{1,2})?$)';
  private mainlineTitle = 'Mainline';
  private sidingTitle = 'Siding';
  private yardTitle = 'Yard';
  private otherTitle = 'Other';

  constructor(public utilService: UtilService) {}

  ngOnInit() {
    this.calculateFeetSurfaced();
    const mainline = this.jobData.masterTrackList[0] ? JSON.parse(JSON.stringify(this.jobData.masterTrackList[0])) : null;
    if (mainline) {
      if (this.jobData.trackType === 'SD') {
        mainline.trackName = this.sidingTitle;
      } else if (this.jobData.trackType === 'YD') {
        mainline.trackName = this.yardTitle;
      } else if (this.jobData.trackType === 'O') {
        mainline.trackName = this.otherTitle;
      } else {
        mainline.trackName = this.mainlineTitle;
      }
      mainline.title = mainline.trackName;
    }
    if (
      (this.jobData.trackType !== 'YD' &&
        (this.jobData.jobTypeCode === AppConstant.JOB_TYPE.TIE_SURFACING || this.jobData.jobTypeCode === AppConstant.JOB_TYPE.SURFACING)) ||
      this.jobData.jobTypeCode === AppConstant.JOB_TYPE.SWITCH_TIE_YARDS
    ) {
      this.tracksList.push(mainline);
    }
    if (this.jobData.tieTrackList && this.jobData.tieTrackList.length > 0) {
      this.jobData.tieTrackList.forEach((track: any) => {
        const location: LocationModel = new LocationModel();
        location.prefix = track.prefix;
        location.beginMp = track.beginMp;
        location.endMp = track.endMP;
        location.trackType = track.trackType;
        location.trackName = track.trackName;
        if (location.trackName.indexOf(location.beginMp.toString().replace(/^[0.]+/, '')) !== -1 &&
           location.trackName.indexOf(location.endMp.toString().replace(/^[0.]+/, '')) !== -1) {
          location.title = location.trackName;
        } else {
          location.title = location.trackName + ' | ' + location.beginMp + ' - ' + location.endMp;
        }
        this.tracksList.push(location);
      });
    }
    setTimeout(() => {
      this.formGroup.controls.surfacing = new FormArray([], this.validateMinimumSurfacingRequired(1));
      this.initializeFormGroup();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['direction'] && !changes['direction'].firstChange) {
      this.formGroup.controls.surfacing = new FormArray([], this.validateMinimumSurfacingRequired(1));
      this.initializeFormGroup(true);
    }
  }

  get surfaceFormArray() {
    return this.formGroup.controls.surfacing as FormArray;
  }

  addSurfacingDetail(surfaceFormGroup: FormGroup) {
    const surfaceDetailsArray: FormArray = surfaceFormGroup.controls.surfaceDetails as FormArray;
    if (surfaceDetailsArray.valid) {
      surfaceDetailsArray.push(this.getSwitchTieSurfacingFormGroup(new SurfacingSegmentModel(), true));
    }
  }

  onMilepostChanges(surfaceDetails: FormGroup, segments: Array<FormGroup>) {
    segments.forEach((group: FormGroup) => {
      group.controls.beginMP.updateValueAndValidity();
      group.controls.endMP.updateValueAndValidity();
    });
    if (
      surfaceDetails.controls.beginMP.valid &&
      surfaceDetails.controls.beginMP.value !== null &&
      surfaceDetails.controls.endMP.valid &&
      surfaceDetails.controls.endMP.value !== null
    ) {
      this.calculateFeetSurfaced(surfaceDetails);
      surfaceDetails.parent.parent.controls['crossingSurfaced'].setValidators([Validators.required]);
    } else {
      surfaceDetails.parent.parent.controls['crossingSurfaced'].setValidators([]);
    }
    surfaceDetails.parent.parent.controls['crossingSurfaced'].updateValueAndValidity();
  }

  toggleSiding(index: number) {
    if (this.selectedSidingIndex === index) {
      this.selectedSidingIndex = null;
    } else {
      this.selectedSidingIndex = index;
    }
  }

  onCrossingSurfacedChange(surfaceFormGroup: FormGroup) {
    // Add validations on mileposts field only if Crossing Surfaced is selected
    if (surfaceFormGroup.controls.crossingSurfaced.value === null) {
      this.handleSurfacingDetailsValidations(surfaceFormGroup.controls.surfaceDetails as FormArray, false);
    } else {
      this.handleSurfacingDetailsValidations(surfaceFormGroup.controls.surfaceDetails as FormArray, true);
    }
    surfaceFormGroup.updateValueAndValidity();
  }

  getAddTitle(): string {
    const jobDetails = this.jobData;
    if (jobDetails.trackType === 'YD') {
      return '+Add Another Surfacing - Yard Track Detail';
    } else if (jobDetails.trackType === 'SD') {
      return '+Add Another Surfacing - Siding Detail';
    } else {
      return '+Add Another Surfacing - Mainline Detail';
    }
  }

  async calculateFeetSurfaced(surfaceDetails?: FormGroup) {
    if (surfaceDetails) {
      await this.utilService
        .getSurfacedFeet(surfaceDetails.controls.beginMP.value, surfaceDetails.controls.endMP.value, Number(this.jobData.job))
        .then((result: number[]) => {
          surfaceDetails.controls.surfaced.setValue(result[0]);
        });
    } else {
      await this.utilService.getSurfacedFeet(-1, -1, Number(this.jobData.job));
    }
  }

  private initializeFormGroup(reset?: boolean) {
    this.tracksList.forEach((track: LocationModel) => {
      let surfacingData: SurfacingDetailsModel = new SurfacingDetailsModel();
      let currentTrackSurfacingData = null;
      if (!reset) {
        currentTrackSurfacingData = this.surfacingList.filter(
          (surface: SurfacingDetailsModel) => surface.trackDetails.trackName === track.trackName
        );
        surfacingData = new SurfacingDetailsModel(currentTrackSurfacingData[0]);
      }
      const surfacingFormGroup = new FormGroup({
        crossingSurfaced: new FormControl(surfacingData.crossingSurfaced),
        surfaceDetails: this.getSwitchTieSurfacingFormArray(surfacingData.surfaceDetails),
        trackDetails: new FormControl(track)
      });
      this.surfaceFormArray.push(surfacingFormGroup);
      if (this.editMode) {
        if(currentTrackSurfacingData && currentTrackSurfacingData.length) {
          surfacingFormGroup.controls.crossingSurfaced.disable();
        } else {
          surfacingFormGroup.disable();
        }
      }
    });
    if (this.viewMode) {
      this.formGroup.disable();
    }
  }

  private getSwitchTieSurfacingFormArray(surfacingSegments: Array<SurfacingSegmentModel>) {
    const formArray = new FormArray([]);
    if (surfacingSegments.length > 0) {
      surfacingSegments.forEach((segment: SurfacingSegmentModel) => {
        formArray.push(this.getSwitchTieSurfacingFormGroup(segment, true));
      });
    } else {
      formArray.push(this.getSwitchTieSurfacingFormGroup(new SurfacingSegmentModel()));
    }
    return formArray;
  }

  private getSwitchTieSurfacingFormGroup(segmentData: SurfacingSegmentModel, addRequiredValidation?: boolean) {
    return new FormGroup({
      beginMP: new FormControl(segmentData.beginMP, this.getMilepostValidations(addRequiredValidation, 'endMP')),
      endMP: new FormControl(segmentData.endMP, this.getMilepostValidations(addRequiredValidation, 'beginMP')),
      surfaced: new FormControl(segmentData.surfaced)
    });
  }

  private handleSurfacingDetailsValidations(formArray: FormArray, addRequiredValidation: boolean) {
    formArray.controls.forEach((formGroup: FormGroup) => {
      formGroup.controls.beginMP.setValidators(this.getMilepostValidations(addRequiredValidation, 'endMP'));
      formGroup.controls.endMP.setValidators(this.getMilepostValidations(addRequiredValidation, 'beginMP'));
      formGroup.controls.beginMP.updateValueAndValidity();
      formGroup.controls.endMP.updateValueAndValidity();
    });
  }

  private getMilepostValidations(addRequiredValidation: boolean, fieldToCompare: string) {
    return addRequiredValidation
      ? [Validators.required, Validators.pattern(this.milepostPattern), this.milepostValidator(fieldToCompare)]
      : [Validators.pattern(this.milepostPattern), this.milepostValidator(fieldToCompare)];
  }

  private validateMinimumSurfacingRequired(min: number) {
    return (control: AbstractControl) => {
      if (!(control instanceof FormArray)) {
        return;
      }
      let count = 0;
      for (const formGroup of control.controls) {
        if (
          formGroup.valid &&
          (<FormGroup>formGroup).controls.crossingSurfaced &&
          (<FormGroup>formGroup).controls.crossingSurfaced.value !== null
        ) {
          count++;
        }
      }
      return count >= min ? null : { atleastOneRequired: true };
    };
  }

  private milepostValidator(fieldToCompare: string) {
    return (control: AbstractControl) => {
      if (this.isFormReadyForValidation(control, fieldToCompare)) {
        let jobStartLimit, jobEndLimit;
        if (this.jobData.jobTypeCode === AppConstant.JOB_TYPE.SWITCH_TIE_YARDS) {
          jobStartLimit = Number(this.jobData.startMP) - 3;
          jobEndLimit = Number(this.jobData.endMP) + 3;
        } else if (this.jobData.jobTypeCode === AppConstant.JOB_TYPE.SWITCH_TIE_INTERLOCKING) {
          jobStartLimit = Number(this.jobData.startMP) - 5;
          jobEndLimit = Number(this.jobData.endMP) + 5;
        } else {
          jobStartLimit = Number(this.jobData.startMP) - 2;
          jobEndLimit = Number(this.jobData.endMP) + 2;
        }
        const fieldCompare = control.parent.controls[fieldToCompare].value;
        const currentControlValue = parseFloat(control.value);
        const currentTrackTitle: string = (<LocationModel>control.parent.parent.parent.get('trackDetails').value).title;

        let beginMP, endMP;
        if (fieldToCompare === 'endMP') {
          beginMP = currentControlValue;
          endMP = parseFloat(fieldCompare);
        } else {
          endMP = currentControlValue;
          beginMP = parseFloat(fieldCompare);
        }

        if (this.isMilepostInvalidForDirection(beginMP, endMP)) {
          return { invalidDirection: true };
        } else if (this.isMilepostInvalidForJob(fieldCompare, jobStartLimit, jobEndLimit, control)) {
          return { invalidJobRange: true };
        } else {
          if (this.tracksList && this.tracksList.length > 0) {
            if (!this.isMilepostValidAsPerMT(currentTrackTitle, fieldCompare, control)) {
              return { invalidMTRange: true };
            } else {
              return this.isMilepostOverlap(control, beginMP, endMP) ? { overlap: true } : null;
            }
          } else {
            return this.isMilepostOverlap(control, beginMP, endMP) ? { overlap: true } : null;
          }
        }
      } else {
        return null;
      }
    };
  }

  private isFormReadyForValidation(control: AbstractControl, fieldToCompare: string) {
    return (
      control.value !== null &&
      control.value !== undefined &&
      control.valid &&
      control.parent &&
      control.parent.parent &&
      control.parent.parent.parent &&
      control.parent.controls[fieldToCompare].value !== null &&
      control.parent.parent.parent.get('trackDetails') &&
      control.parent.parent.parent.get('trackDetails').value
    );
  }

  private isMilepostInvalidForDirection(beginMP: any, endMP: any) {
    // Begin MP should not be equal to end MP and should be less/greater than end MP based on Direction
    return (
      (beginMP === endMP && this.jobData.jobTypeCode !== AppConstant.JOB_TYPE.SWITCH_TIE_YARDS) ||
      (this.direction === WorkDirection.INCREASING && beginMP > endMP) || (this.direction === WorkDirection.DECREASING && beginMP < endMP)
    );
  }

  private isMilepostInvalidForJob(fieldCompare: any, jobStartLimit: number, jobEndLimit: number, control: AbstractControl) {
    // MP should be within 2 miles of Job limits
    return !(
      parseFloat(fieldCompare) >= jobStartLimit &&
      parseFloat(fieldCompare) <= jobEndLimit &&
      parseFloat(control.value) >= jobStartLimit && parseFloat(control.value) <= jobEndLimit
    );
  }

  private isMilepostValidAsPerMT(currentTrackTitle, fieldCompare, control) {
    // Valid track as per master track data
    let valid = false;

    if (currentTrackTitle === this.mainlineTitle) {
      let field1Valid = false;
      let field2Valid = false;
      for (const track of this.jobData.masterTrackList) {
        if (parseFloat(fieldCompare) >= track.beginMp && parseFloat(fieldCompare) <= track.endMp) {
          field1Valid = true;
        }
        if (parseFloat(control.value) >= track.beginMp && parseFloat(control.value) <= track.endMp) {
          field2Valid = true;
        }
        if (field1Valid && field2Valid) {
          valid = true;
          break;
        }
      }
    } else {
      for (const track of this.tracksList) {
        if (
          track.title === currentTrackTitle &&
          parseFloat(fieldCompare) >= track.beginMp && parseFloat(fieldCompare) <= track.endMp &&
          parseFloat(control.value) >= track.beginMp && parseFloat(control.value) <= track.endMp
        ) {
          valid = true;
          break;
        }
      }
    }
    return valid;
  }

  private isMilepostOverlap(control: AbstractControl, beginMP, endMP) {
    // Current MP should not overlap with milepost range in other rows
    const formArray = control.parent.parent.parent.controls['surfaceDetails'] as FormArray;
    let matches = 0;
    formArray.value.forEach((segment: SurfacingSegmentModel) => {
      if (
        (this.direction === WorkDirection.INCREASING && endMP > segment.beginMP && beginMP < segment.endMP) ||
        (this.direction === WorkDirection.DECREASING && beginMP > segment.endMP && endMP < segment.beginMP)
      ) {
        matches++;
      }
    });
    if (matches > 1) {
      return true;
    } else {
      return false;
    }
  }

  get viewMode() {
    return this.operationMode === OperationMode.VIEW;
  }

  get editMode() {
    return this.operationMode === OperationMode.EDIT;
  }
}
