export class Turnout {
  turnoutId: number;
  prefix: string;
  milepost: number;
  trackType: string;
  trackName: string;
  direction: string;
  railWeight: number;
  f_t: string;
  feetPerPass?: number;
  noOfPasses?: number;
  isSurfaced?: boolean;
  turnoutLength?: number;
  isSelected?: boolean;
  turnoutSize?: number;
  endMp?: number;
  switchName?: string;
  trackClass?: string;
  isPassengerFlag?: string;
  workProgressId?: number;

  constructor(
    turnoutId?: number,
    prefix?: string,
    milepost?: number,
    trackType?: string,
    trackName?: string,
    direction?: string,
    railWeight?: number,
    f_t?: string,
    length?: number,
    size?: number,
    endMp?: number,
    switchName?: string,
    trackClass?: string,
    isPassengerFlag?: string,
    isSelected?: boolean,
    workProgressId?: number
  ) {
    this.turnoutId = turnoutId;
    this.prefix = prefix;
    this.milepost = milepost;
    this.trackType = trackType;
    this.trackName = trackName;
    this.direction = direction;
    this.railWeight = railWeight;
    this.f_t = f_t;
    this.turnoutLength = length;
    this.turnoutSize = size;
    this.endMp = endMp;
    this.switchName = switchName;
    this.trackClass = trackClass;
    this.isPassengerFlag = isPassengerFlag;
    this.isSelected = isSelected;
    this.workProgressId = workProgressId;
  }
}
