import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { DataService } from '../services/data.service';
import { LocalStorageService } from '../services/local-storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilService } from '../util/util.service';
import { ReportDataService } from '../shared/services/report-data.service';
import { ConfirmationService } from 'primeng/primeng';
import { ReportNavigationService } from '../shared/services/report-navigation.service';

export function BaseRailReportEditMixin(BaseClass) {
  return class extends BaseClass {
    constructor(
      public fb: FormBuilder,
      public dataService: DataService,
      public ls: LocalStorageService,
      public router: Router,
      public Activateroute: ActivatedRoute,
      public utilService: UtilService,
      public reportDataServcie: ReportDataService,
      public acknowledgeService: ConfirmationService,
      public reportNavigationService: ReportNavigationService
    ) {
      super(fb, dataService, ls, router, Activateroute, utilService, reportDataServcie, acknowledgeService, reportNavigationService);
    }

    isBallastSufficentIsvalid(i: number): boolean {
      return (
        this.formGroup['controls'].cutRailArray['controls'][i]['controls']['isBallastSufficient'].enabled &&
        this.formGroup['controls'].cutRailArray['controls'][i]['controls']['isBallastSufficient'].invalid &&
        this.formSubmitAttempt
      );
    }

    protected disableReportFields() {
      this.formGroup.controls.direction.disable();
      this.formGroup.controls.direction.clearValidators();
      this.formGroup.controls.date.disable();
      this.formGroup.controls.date.clearValidators();

      if (!this.isTDEditable) {
        this.formGroup.controls.railWeight.disable();
        this.formGroup.controls.railWeight.clearValidators();
        this.formGroup.controls.railSide.disable();
        this.formGroup.controls.railSide.clearValidators();
        this.formGroup.controls.beginMp.disable();
        this.formGroup.controls.beginMp.clearValidators();
      }

      this.handleCutRailFields();
    }

    protected handleCutRailFields() {
      (<FormArray>this.formGroup.controls.cutRailArray).controls.forEach((cutRailFormGroup: FormGroup) => {
        this.disableCutRailFields(cutRailFormGroup);
        if (this.isTDEditable) {
          // Cut rail fields are getting enabled on change event of below fields in rail-info component
          // So disabling these on every value change
          cutRailFormGroup.controls.cutRailLength.valueChanges.subscribe(() => {
            this.disableCutRailFields(cutRailFormGroup);
          });
          cutRailFormGroup.controls.openJoints.valueChanges.subscribe(() => {
            this.disableCutRailFields(cutRailFormGroup);
          });
        }
      });
    }

    protected disableCutRailFields(cutRailFormGroup: FormGroup) {
      setTimeout(() => {
        cutRailFormGroup.controls.isWorkSkipped.disable();
        cutRailFormGroup.controls.stringId.disable();
        cutRailFormGroup.controls.stringId.clearValidators();

        if (!this.isTDEditable) {
          cutRailFormGroup.controls.cutRailLength.disable();
          cutRailFormGroup.controls.cutRailLength.clearValidators();
          cutRailFormGroup.controls.openJoints.disable();
          cutRailFormGroup.controls.isTunnel.disable();
          cutRailFormGroup.controls.openJoints.clearValidators();
          cutRailFormGroup.controls.designedLayTemp.disable();
          cutRailFormGroup.controls.designedLayTemp.clearValidators();
          cutRailFormGroup.controls.coldRailTemp.disable();
          cutRailFormGroup.controls.coldRailTemp.clearValidators();
          cutRailFormGroup.controls.isBallastSufficient.disable();
          cutRailFormGroup.controls.isBallastSufficient.clearValidators();
        }
      });
    }
  };
}
