export class LocationDetail {
  prefix: string;
  lowMp: number;
  highMp: number;
  constructor(prefix: string, lowMp: number, highMp: number) {
    this.prefix = prefix;
    this.lowMp = lowMp;
    this.highMp = highMp;
  }
}
