import {
  JobTable,
  LocalStorageService,
  ProjectTable,
  ReferenceCode,
  ReportTable,
  RoadCrossingTable,
  UserTable
} from './local-storage.service';

import { Injectable } from '@angular/core';
import { RestURLBuilder } from 'rest-url-builder';
import { SyncTable, TurnoutTable, CurrentUserTable } from './local-storage.service';

@Injectable()
export class Configuration {
  get reportData(): any[] {
    return this._reportData;
  }

  set reportData(value: any[]) {
    this._reportData = value;
  }

  get reportEditData(): any[] {
    return this._reportEditData;
  }

  set reportEditData(value: any[]) {
    this._reportEditData = value;
  }

  private userUrl = '/userSTW?mac=:mac&racf=:racf';
  private lastSyncUrl = '/lastSyncData?mac=:mac&racf=:racf&sync=:sync&team=:team';
  private projectsUrl = '/projects?mac=:mac&racf=:racf&team=:team&project=:project';
  private jobsUrl = '/jobs?mac=:mac&racf=:racf&team=:team&project=:project';
  private reportsUrl = '/report?mac=:mac&racf=:racf&team=:team&projectid=:projectid';
  private submitReportUrl = '/submitReport?mac=:mac&racf=:racf';
  private updateReportUrl = '/editReport?mac=:mac&racf=:racf&isTdEditable=:isTdEditable&editreason=:editreason';
  private refCodeUrl = '/refData?mac=:mac&racf=:racf&ref=:ref';
  private locCodeUrl = '/locationData?mac=:mac&racf=:racf&ref=:ref';
  private roadCrossingUrl =
    '/roadCrossings?mac=:mac&racf=:racf&team=:team&prefix=:prefix&lowMP=:lowMP' + '&highMP=:highMP&dotNum=:dotNum&project=:project';
  private turnoutsUrl = '/turnouts?mac=:mac&racf=:racf&team=:team&project=:project';

  private trackDisturbanceUrl = '/submitTrackDisturbance?mac=:mac&racf=:racf';
  private logChangeUrl = '/log?mac=:mac&racf=:racf';
  private deleteSyncedReportUrl =
    '/deleteReport?mac=:mac&racf=:racf&form_i:form_i&noProductionCode:noProductionCode&trackType:trackType&deleteReason:deleteReason';
  private manualTrackDisturbanceUrl = '/submitTrackDisturbance/regenerateTD?mac=:mac&racf=:racf';
  private tdEditableCheckUrl = '/submitTrackDisturbance/validate/edit?mac=:mac&racf=:racf&form_i:form_i';
  // private equipmentsUrl = '/equipment?machine=:machine&team=:team&division=:division';
  private supervisorRacfUrl = '/supervisorracfs';

  public jobData: any[] = [
    {
      project: 361,
      prefix: '0LB',
      startMP: 388.34,
      endMP: 404.1,
      division: 'AT',
      subdivision: 'YB',
      jobTypeCode: 'TS',
      materialDelivered: '-1',
      materialTotal: '-1',
      formattedStartDate: '03/19/2018',
      formattedEndDate: '04/14/2018',
      installed: 102,
      reports: 42,
      railSide: null,
      job: 158074,
      trackType: 'SG',
      remarks: '2007 ties with several roadcrossings with joints in them.',
      railWeight: null,
      railType: null,
      railGrade: null,
      blueBookRailside: null,
      railsideChanged: true,
      isSwitchTieJob: true,
      roadmasters: [
        {
          job: '158074',
          rl_c: 'R47',
          rl_name: 'Baldwin,Ronald A'
        }
      ],
      chainFeet: [
        {
          milepost: 388,
          feet: 5280,
          offset: null
        },
        {
          milepost: 391,
          feet: 5280,
          offset: null
        },
        {
          milepost: 394,
          feet: 5293,
          offset: null
        },
        {
          milepost: 396,
          feet: 5247,
          offset: null
        },
        {
          milepost: 399,
          feet: 5298,
          offset: null
        },
        {
          milepost: 402,
          feet: 5070,
          offset: null
        },
        {
          milepost: 405,
          feet: 5280,
          offset: null
        },
        {
          milepost: 389,
          feet: 5161,
          offset: null
        },
        {
          milepost: 390,
          feet: 5269,
          offset: null
        },
        {
          milepost: 392,
          feet: 5280,
          offset: null
        },
        {
          milepost: 393,
          feet: 5310,
          offset: null
        },
        {
          milepost: 395,
          feet: 5273,
          offset: null
        },
        {
          milepost: 397,
          feet: 5278,
          offset: null
        },
        {
          milepost: 398,
          feet: 5254,
          offset: null
        },
        {
          milepost: 400,
          feet: 5212,
          offset: null
        },
        {
          milepost: 401,
          feet: 5268,
          offset: null
        },
        {
          milepost: 403,
          feet: 5459,
          offset: null
        },
        {
          milepost: 404,
          feet: 5098,
          offset: null
        },
        {
          milepost: 406,
          feet: 5280,
          offset: null
        }
      ],
      plannedCrossingCount: 78,
      plannedUnits: 20358,
      reportedUnits: 20860,
      tieTrackList: [
        {
          trackId: 13068,
          trackName: 'JIM WALKER LEAD',
          beginMp: 389.4,
          endMP: 389.5,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13071,
          trackName: '#1 ENSLEY TRK',
          beginMp: 395.2,
          endMP: 395.3,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13072,
          trackName: '#2 ENSLEY TRK',
          beginMp: 395.21,
          endMP: 395.3,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13073,
          trackName: '#3 ENSLEY TRK',
          beginMp: 395.3,
          endMP: 395.7,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13074,
          trackName: '#4 ENSLEY TRK',
          beginMp: 395.31,
          endMP: 395.7,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13079,
          trackName: '#12 ENSLEY TRK',
          beginMp: 396.1,
          endMP: 396.4,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13080,
          trackName: '#11 ENSLEY TRK',
          beginMp: 396.1,
          endMP: 396.4,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13081,
          trackName: '#10 ENSLEY TRK',
          beginMp: 396.2,
          endMP: 396.4,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13082,
          trackName: '#1 WOODWARD TRK',
          beginMp: 401.1,
          endMP: 401.6,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13087,
          trackName: 'MARY LEE N.LEG WYE TRK',
          beginMp: 388.4,
          endMP: 388.5,
          tieType: 'M',
          trackType: 'SD'
        },
        {
          trackId: 13088,
          trackName: 'MARY LEE S LEG WYE TRK',
          beginMp: 388.41,
          endMP: 388.5,
          tieType: 'M',
          trackType: 'SD'
        },
        {
          trackId: 13089,
          trackName: 'BN CONNECTION TRK',
          beginMp: 388.42,
          endMP: 388.5,
          tieType: 'M',
          trackType: 'SD'
        },
        {
          trackId: 13097,
          trackName: 'HARBINSON WALKER TRK',
          beginMp: 398,
          endMP: 398.1,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13099,
          trackName: 'BESSMER N. LEG WYE TRK',
          beginMp: 403.9,
          endMP: 404,
          tieType: 'M',
          trackType: 'SD'
        }
      ],
      masterTrackList: [
        {
          trackStatus: 'O',
          trackType: 'SG',
          prefix: '0LB',
          beginMp: 358.51,
          endMp: 393.01
        },
        {
          trackStatus: 'O',
          trackType: 'SG',
          prefix: '0LB',
          beginMp: 393.01,
          endMp: 393.07
        },
        {
          trackStatus: 'O',
          trackType: 'SG',
          prefix: '0LB',
          beginMp: 393.07,
          endMp: 454.1
        },
        {
          trackStatus: 'O',
          trackType: 'SG',
          prefix: '0LB',
          beginMp: 388.34,
          endMp: 388.51
        }
      ],
      speedTrackClassList: [
        {
          trackType: 'SG',
          prefix: 'YYG',
          beginMp: 425,
          endMp: 427,
          trackName: 'SG',
          trackClass: 'CLASS 1',
          isPassengerFlag: 'N'
        },
        {
          trackType: 'SG',
          prefix: 'YYG',
          beginMp: 118.12,
          endMp: 120.04,
          trackName: 'SG',
          trackClass: 'SOCIAL CIRCLE',
          isPassengerFlag: 'N'
        },
        {
          trackType: 'SG',
          prefix: 'YYG',
          beginMp: 428.1,
          endMp: 429,
          trackName: 'SD',
          trackClass: 'CLASS 1',
          isPassengerFlag: 'Y'
        }
      ],
    },
    {
      project: 361,
      prefix: '0LB',
      startMP: 388.34,
      endMP: 404.1,
      division: 'AT',
      subdivision: 'YB',
      jobTypeCode: 'TS',
      materialDelivered: '-1',
      materialTotal: '-1',
      formattedStartDate: '03/19/2018',
      formattedEndDate: '04/14/2018',
      installed: 102,
      reports: 1,
      railSide: 'R',
      job: 158074,
      trackType: 'SG',
      remarks: '2007 ties with several roadcrossings with joints in them.',
      railWeight: null,
      railType: null,
      railGrade: null,
      blueBookRailside: null,
      railsideChanged: true,
      isSwitchTieJob: true,
      roadmasters: [
        {
          job: '158074',
          rl_c: 'R47',
          rl_name: 'Baldwin,Ronald A'
        }
      ],
      chainFeet: [
        {
          milepost: 388,
          feet: 5280,
          offset: null
        },
        {
          milepost: 391,
          feet: 5280,
          offset: null
        },
        {
          milepost: 394,
          feet: 5293,
          offset: null
        },
        {
          milepost: 396,
          feet: 5247,
          offset: null
        },
        {
          milepost: 399,
          feet: 5298,
          offset: null
        },
        {
          milepost: 402,
          feet: 5070,
          offset: null
        },
        {
          milepost: 405,
          feet: 5280,
          offset: null
        },
        {
          milepost: 389,
          feet: 5161,
          offset: null
        },
        {
          milepost: 390,
          feet: 5269,
          offset: null
        },
        {
          milepost: 392,
          feet: 5280,
          offset: null
        },
        {
          milepost: 393,
          feet: 5310,
          offset: null
        },
        {
          milepost: 395,
          feet: 5273,
          offset: null
        },
        {
          milepost: 397,
          feet: 5278,
          offset: null
        },
        {
          milepost: 398,
          feet: 5254,
          offset: null
        },
        {
          milepost: 400,
          feet: 5212,
          offset: null
        },
        {
          milepost: 401,
          feet: 5268,
          offset: null
        },
        {
          milepost: 403,
          feet: 5459,
          offset: null
        },
        {
          milepost: 404,
          feet: 5098,
          offset: null
        },
        {
          milepost: 406,
          feet: 5280,
          offset: null
        }
      ],
      plannedCrossingCount: 78,
      plannedUnits: 20358,
      reportedUnits: 20860,
      tieTrackList: [
        {
          trackId: 13068,
          trackName: 'JIM WALKER LEAD',
          beginMp: 389.4,
          endMP: 389.5,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13071,
          trackName: '#1 ENSLEY TRK',
          beginMp: 395.2,
          endMP: 395.3,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13072,
          trackName: '#2 ENSLEY TRK',
          beginMp: 395.21,
          endMP: 395.3,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13073,
          trackName: '#3 ENSLEY TRK',
          beginMp: 395.3,
          endMP: 395.7,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13074,
          trackName: '#4 ENSLEY TRK',
          beginMp: 395.31,
          endMP: 395.7,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13079,
          trackName: '#12 ENSLEY TRK',
          beginMp: 396.1,
          endMP: 396.4,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13080,
          trackName: '#11 ENSLEY TRK',
          beginMp: 396.1,
          endMP: 396.4,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13081,
          trackName: '#10 ENSLEY TRK',
          beginMp: 396.2,
          endMP: 396.4,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13082,
          trackName: '#1 WOODWARD TRK',
          beginMp: 401.1,
          endMP: 401.6,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13087,
          trackName: 'MARY LEE N.LEG WYE TRK',
          beginMp: 388.4,
          endMP: 388.5,
          tieType: 'M',
          trackType: 'SD'
        },
        {
          trackId: 13088,
          trackName: 'MARY LEE S LEG WYE TRK',
          beginMp: 388.41,
          endMP: 388.5,
          tieType: 'M',
          trackType: 'SD'
        },
        {
          trackId: 13089,
          trackName: 'BN CONNECTION TRK',
          beginMp: 388.42,
          endMP: 388.5,
          tieType: 'M',
          trackType: 'SD'
        },
        {
          trackId: 13097,
          trackName: 'HARBINSON WALKER TRK',
          beginMp: 398,
          endMP: 398.1,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13099,
          trackName: 'BESSMER N. LEG WYE TRK',
          beginMp: 403.9,
          endMP: 404,
          tieType: 'M',
          trackType: 'SD'
        }
      ],
      masterTrackList: [
        {
          trackStatus: 'O',
          trackType: 'SG',
          prefix: '0LB',
          beginMp: 358.51,
          endMp: 393.01
        },
        {
          trackStatus: 'O',
          trackType: 'SG',
          prefix: '0LB',
          beginMp: 393.01,
          endMp: 393.07
        },
        {
          trackStatus: 'O',
          trackType: 'SG',
          prefix: '0LB',
          beginMp: 393.07,
          endMp: 454.1
        },
        {
          trackStatus: 'O',
          trackType: 'SG',
          prefix: '0LB',
          beginMp: 388.34,
          endMp: 388.51
        }
      ]
    },
    {
      project: 361,
      prefix: '0LK',
      startMP: 421.4,
      endMP: 429,
      division: 'AT',
      subdivision: 'YB',
      jobTypeCode: 'TS',
      materialDelivered: '-1',
      materialTotal: '-1',
      formattedStartDate: '03/19/2018',
      formattedEndDate: '04/14/2018',
      installed: 117,
      reports: 13,
      railSide: null,
      job: 158179,
      trackType: 'SG',
      remarks: 'WEAK TIE CONDITIONS ON HIGH TONNAGE CURVE PER ADE',
      railWeight: null,
      railType: null,
      railGrade: null,
      blueBookRailside: null,
      railsideChanged: true,
      roadmasters: [
        {
          job: '158179',
          rl_c: 'R47',
          rl_name: 'Baldwin,Ronald A'
        }
      ],
      speedTrackClassList: [
        {
          trackType: 'SG',
          prefix: 'YYG',
          beginMp: 425,
          endMp: 427,
          trackName: 'SG',
          trackClass: 'CLASS 1',
          isPassengerFlag: 'N'
        },
        {
          trackType: 'SG',
          prefix: 'YYG',
          beginMp: 118.12,
          endMp: 120.04,
          trackName: 'SG',
          trackClass: 'SOCIAL CIRCLE',
          isPassengerFlag: 'N'
        },
        {
          trackType: 'SG',
          prefix: 'YYG',
          beginMp: 428.1,
          endMp: 429,
          trackName: 'SG',
          trackClass: 'UNKNOWN',
          isPassengerFlag: 'Y'
        }
      ],
      chainFeet: [
        {
          milepost: 421,
          feet: 5280,
          offset: null
        },
        {
          milepost: 422,
          feet: 5370,
          offset: null
        },
        {
          milepost: 423,
          feet: 5307,
          offset: null
        },
        {
          milepost: 424,
          feet: 5297,
          offset: null
        },
        {
          milepost: 425,
          feet: 5309,
          offset: null
        },
        {
          milepost: 426,
          feet: 3833,
          offset: null
        },
        {
          milepost: 427,
          feet: 5258,
          offset: null
        },
        {
          milepost: 428,
          feet: 5223,
          offset: null
        },
        {
          milepost: 429,
          feet: 5280,
          offset: null
        },
        {
          milepost: 430,
          feet: 5280,
          offset: null
        }
      ],
      plannedCrossingCount: 78,
      plannedUnits: 10804,
      reportedUnits: 12639,
      tieTrackList: [
        {
          trackId: 4510,
          trackName: 'DUDLEY SIDING',
          beginMp: 426.7,
          endMP: 428,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13118,
          trackName: 'DAVIS CREEK LEAD TRK',
          beginMp: 422.8,
          endMP: 424,
          tieType: 'B',
          trackType: 'SD'
        },
        {
          trackId: 13120,
          trackName: 'DUDLEY MINE LEAD TRK',
          beginMp: 428.1,
          endMP: 429,
          tieType: 'B',
          trackType: 'SD'
        }
      ],
      masterTrackList: [
        {
          trackStatus: 'O',
          trackType: 'SG',
          prefix: '0LK',
          beginMp: 421.31,
          endMp: 429.2
        },
        {
          trackStatus: 'S',
          trackType: 'SG',
          prefix: '0LK',
          beginMp: 429.2,
          endMp: 429.68
        }
      ]
    },
    {
      project: 516,
      prefix: 'S',
      startMP: 808,
      endMP: 822.7,
      division: 'JX',
      subdivision: 'YE',
      jobTypeCode: 'SO',
      materialDelivered: '-1',
      materialTotal: '-1',
      formattedStartDate: '01/15/2018',
      formattedEndDate: '01/27/2018',
      installed: 67,
      reports: 30,
      railSide: null,
      job: 197614,
      trackType: 'SG',
      remarks: 'Concrete ties',
      railWeight: null,
      railType: null,
      railGrade: null,
      blueBookRailside: null,
      railsideChanged: true,
      roadmasters: [
        {
          job: '197614',
          rl_c: 'P16',
          rl_name: 'Bell,Leon'
        }
      ],
      chainFeet: [
        {
          milepost: 807,
          feet: 5280,
          offset: null
        },
        {
          milepost: 808,
          feet: 4468,
          offset: null
        },
        {
          milepost: 810,
          feet: 5325,
          offset: null
        },
        {
          milepost: 811,
          feet: 5295,
          offset: null
        },
        {
          milepost: 812,
          feet: 5286,
          offset: null
        },
        {
          milepost: 814,
          feet: 5211,
          offset: null
        },
        {
          milepost: 815,
          feet: 5196,
          offset: null
        },
        {
          milepost: 817,
          feet: 5298,
          offset: null
        },
        {
          milepost: 818,
          feet: 5248,
          offset: null
        },
        {
          milepost: 820,
          feet: 5329,
          offset: null
        },
        {
          milepost: 821,
          feet: 5312,
          offset: null
        },
        {
          milepost: 822,
          feet: 5359,
          offset: null
        },
        {
          milepost: 824,
          feet: 5287,
          offset: null
        },
        {
          milepost: 806,
          feet: 5280,
          offset: null
        },
        {
          milepost: 809,
          feet: 5284,
          offset: null
        },
        {
          milepost: 813,
          feet: 5263,
          offset: null
        },
        {
          milepost: 816,
          feet: 5209,
          offset: null
        },
        {
          milepost: 819,
          feet: 5305,
          offset: null
        },
        {
          milepost: 823,
          feet: 5283,
          offset: null
        }
      ],
      plannedCrossingCount: 147,
      plannedUnits: 76771,
      reportedUnits: 51137,
      tieTrackList: null,
      masterTrackList: [
        {
          trackStatus: 'O',
          trackType: 'SG',
          prefix: 'S',
          beginMp: 823.2,
          endMp: 842.05
        },
        {
          trackStatus: 'S',
          trackType: 'SG',
          prefix: 'S',
          beginMp: 792.45,
          endMp: 808
        },
        {
          trackStatus: 'O',
          trackType: 'SG',
          prefix: 'S',
          beginMp: 808,
          endMp: 822.7
        }
      ]
    },

    {
      project: 447,
      prefix: '00J',
      startMP: 92.3,
      endMP: 92.36,
      division: 'NS',
      subdivision: 'C8',
      jobTypeCode: 'PR',
      materialDelivered: '-1',
      materialTotal: '-1',
      formattedStartDate: '03/05/2018',
      formattedEndDate: '03/31/2018',
      installed: 0,
      reports: 0,
      railSide: 'R',
      job: 200788,
      trackType: 'SG',
      remarks: 'Derailment',
      railWeight: 136,
      railType: 'N',
      railGrade: 'P',
      blueBookRailside: 'R',
      railsideChanged: false,
      desiredNeutralTemp: 110,
      roadmasters: [
        {
          job: '200788',
          rl_c: 'R89',
          rl_name: 'Adamson,Michael W'
        }
      ],
      chainFeet: [
        {
          milepost: 92,
          feet: 4782,
          offset: null
        },
        {
          milepost: 90,
          feet: 5493,
          offset: null
        },
        {
          milepost: 91,
          feet: 5365,
          offset: null
        },
        {
          milepost: 93,
          feet: 5392,
          offset: null
        },
        {
          milepost: 94,
          feet: 5541,
          offset: null
        }
      ],
      plannedCrossingCount: 60,
      plannedUnits: 300,
      reportedUnits: 0,
      tieTrackList: null,
      masterTrackList: [
        {
          trackStatus: 'O',
          trackType: 'SG',
          prefix: '00J',
          beginMp: 86.8,
          endMp: 94.3
        }
      ]
    },
    {
      project: 447,
      prefix: '00J',
      startMP: 122.15,
      endMP: 122.36,
      division: 'NS',
      subdivision: 'C8',
      jobTypeCode: 'GA',
      materialDelivered: '-1',
      materialTotal: '-1',
      formattedStartDate: '03/05/2018',
      formattedEndDate: '03/31/2018',
      installed: 90,
      reports: 1,
      railSide: null,
      job: 195242,
      trackType: '1',
      remarks: null,
      railWeight: null,
      railType: null,
      railGrade: null,
      blueBookRailside: null,
      railsideChanged: true,
      roadmasters: [
        {
          job: '195242',
          rl_c: 'R87',
          rl_name: 'Mcgraw,Michael E'
        }
      ],
      chainFeet: [
        {
          milepost: 121,
          feet: 5607,
          offset: null
        },
        {
          milepost: 123,
          feet: 5336,
          offset: null
        },
        {
          milepost: 120,
          feet: 5658,
          offset: null
        },
        {
          milepost: 122,
          feet: 5081,
          offset: null
        },
        {
          milepost: 124,
          feet: 5329,
          offset: null
        }
      ],
      plannedCrossingCount: 60,
      plannedUnits: 1050,
      reportedUnits: 950,
      tieTrackList: null,
      masterTrackList: [
        {
          trackStatus: 'O',
          trackType: '1',
          prefix: '00J',
          beginMp: 112.7,
          endMp: 122.4
        },
        {
          trackStatus: 'O',
          trackType: '1',
          prefix: '00J',
          beginMp: 123.7,
          endMp: 127.4
        }
      ]
    },
    {
      project: 447,
      prefix: '00J',
      startMP: 123.7,
      endMP: 127.4,
      division: 'NS',
      subdivision: 'C8',
      jobTypeCode: 'CP',
      materialDelivered: '-1',
      materialTotal: '-1',
      formattedStartDate: '03/05/2018',
      formattedEndDate: '03/31/2018',
      installed: 99,
      reports: 4,
      railSide: 'L',
      job: 44593,
      trackType: '2',
      remarks: 'New request replaces #641 initiated in 2006 to replace jointed rail on #2 main.',
      railWeight: 136,
      railType: 'N',
      railGrade: 'I',
      blueBookRailside: null,
      railsideChanged: true,
      roadmasters: [
        {
          job: '44593',
          rl_c: 'R87',
          rl_name: 'Mcgraw,Michael E'
        }
      ],
      chainFeet: [
        {
          milepost: 121,
          feet: 5607,
          offset: null
        },
        {
          milepost: 123,
          feet: 5336,
          offset: null
        },
        {
          milepost: 126,
          feet: 5311,
          offset: null
        },
        {
          milepost: 129,
          feet: 5276,
          offset: null
        },
        {
          milepost: 122,
          feet: 5081,
          offset: null
        },
        {
          milepost: 124,
          feet: 5329,
          offset: null
        },
        {
          milepost: 125,
          feet: 5385,
          offset: null
        },
        {
          milepost: 127,
          feet: 5291,
          offset: null
        },
        {
          milepost: 128,
          feet: 5283,
          offset: null
        }
      ],
      plannedCrossingCount: 60,
      plannedUnits: 32314,
      reportedUnits: 31963,
      tieTrackList: null,
      masterTrackList: [
        {
          trackStatus: 'O',
          trackType: '2',
          prefix: '00J',
          beginMp: 112.7,
          endMp: 122.4
        },
        {
          trackStatus: 'O',
          trackType: '2',
          prefix: '00J',
          beginMp: 123.7,
          endMp: 127.4
        }
      ]
    }
  ];

  public userData1 = [
    {
      mac: null,
      teamName: '5XT0',
      employeeID: 248915,
      firstName: 'Dale',
      lastName: 'Richer',
      racf: 'S4690',
      fullName: 'Dale Richer',
      emailAddress: 'Not Yet Impelemented',
      userRole: 'OTM Tracker',
      lastSyncDate: 1525117367000,
      projects: '2, 3'
    }
  ];

  public syncData = [
    {
      racf: 'S4690',
      teamName: '5XC2',
      lastSyncDate: 1525117367000,
      projectList: '2, 3',
      jobList: '1,2,3',
      deletedReportsList: '22,33',
      jobRoadCrossings: '22,22'
    }
  ];

  private _reportEditData: any[] = [
    {
      workTypeCode: 'RA',
      rl: 'R89',
      divisionCode: 'NS',
      subdivCode: 'C8',
      trackType: 'SG',
      isTdEditable: 'Yes',
      reportRoadCrossingList: [],
      formId: -10,
      teamName: null,
      projectId: 447,
      jobId: 200789,
      jobType: 'PR',
      prefix: '00J',
      noProductionCode: null,
      beginMp: 92.3,
      endMp: 92.32,
      lowMp: 92.3,
      highMp: 92.32,
      totalOpenJoints: 0,
      direction: 'I',
      railGrade: 'P',
      railType: 'N',
      tiePlateType: 'P',
      roadMaster: 'Adamson,Michael W',
      railSide: 'R',
      workday: 1544940000000,
      railLength: 100,
      welds: 0,
      iJoints: 0,
      transitions: 0,
      railTemp1: 2,
      railTemp2: 2,
      railTemp3: 2,
      remarks: null,
      railWeight: 136,
      reportCutRail: [
        {
          cutRailLength: 100,
          openJoints: 0,
          isWorkSkipped: false,
          stringId: '33',
          designedLayTemp: '100',
          coldRailTemp: 3,
          changeTempMeasure: 97,
          requiredExpandMeasure: 0.875,
          actualExpandMeasure: 1,
          tempChangeMeasure: 128,
          railNeutralTemp: 131,
          cutRailIndex: 0,
          tempDiff: -31.210000000000008,
          cutRailBeginMp: 92.3,
          cutRailEndMp: 92.32,
          showTempBg: true
        }
      ],
      status: 'D',
      totalActualProjectUnits: 59898,
      daysToComplete: 19,
      unitsRemaining: 0,
      totalPlannedProjectUnits: 59526,
      totalPlannedProjectDays: 20,
      plannedCrossingCount: 5,
      pickupRemainCount: null,
      sawCutsTime: 1544940060000,
      firstWeldTime: 1544947200000,
      firstSpikedPulled: 1544943600000,
      firstSpikeDrivenTime: 1544950800000,
      lastSpikeDriven: 1544954400000,
      actualCrossingCount: 0,
      totalUnitInstalled: 100,
      reportWindows: [
        {
          unitsInstalled: 100,
          unitPerHour: '50.00',
          windowNumber: 1,
          windowStarts: '2018-12-16T01:00',
          windowEnds: '2018-12-16T03:00',
          windowStart: 1544940000000,
          windowEnd: 1544947200000
        }
      ],
      plannedStart: 1544940000000,
      plannedEnd: 1544943600000,
      greenSignalTime: 1544943600000,
      delayIssuesRemark: null,
      issuesDesc: null,
      machineFailureSeq: 'N',
      transOfficerAval: 'N',
      transOfficerName: null,
      trackReturnLate: 'Y',
      trackReturnLateRemarks: null,
      trainsPassedCount: null,
      workAtYard: 'N',
      yardName: null
    }
  ];

  private _reportData: any[] = [
    {
      workTypeCode: 'TS',
      railTemp1: 1,
      railTemp2: 1,
      railTemp3: 1,
      curvesStaked: null,
      workday: 1544508000000,
      teamInTime: 1544508000000,
      surfacingMilesBehind: 0,
      surfacingSchedulePlan: null,
      jobId: 158185,
      projectId: 361,
      formId: 3,
      rl: 'R47',
      roadMaster: 'Baldwin,Ronald A',
      tsProgress: {
        crossingTiesTimbered: true,
        crossingSurfaced: true,
        sidingCrossingTimbered: null,
        sidingCrossingSurfaced: null,
        tieProgress: {
          '0': {
            beginMilepost: 421.4,
            endMilepost: 422,
            tiePlateType: 'W',
            tiePlateCount: 4,
            trackName: 'MAINLINE TRACKS',
            trackType: 'SG',
            crossingTiesTimbered: true,
            direction: 'I',
            workedOn: 'TS',
            railType: 'J'
          }
        },
        surfaceProgress: {
          '0': {
            trackType: 'SG',
            trackName: 'MAINLINE TRACKS',
            beginMP: 421.4,
            endMP: 422,
            surfaced: 3168,
            crossingSurfaced: true,
            direction: 'I',
            workedOn: 'TS',
            railType: 'C'
          }
        },
        direction: 'I',
        workedOn: 'TS'
      },
      jobType: 'TS',
      prefix: '0LK',
      trackType: 'SG',
      divisionCode: 'AT',
      subdivCode: 'YB',
      totalTiesInstalled: 4,
      lowMp: 421.4,
      highMp: 422,
      direction: 'I',
      wpRange: [
        {
          track: 'MAINLINE TRACKS',
          lowMp: 421.4,
          highMp: 422,
          direction: 'I'
        }
      ],
      teamName: '5XT0',
      noProductionCode: null,
      status: null,
      totalActualProjectUnits: 0,
      daysToComplete: 99,
      unitsRemaining: 2996,
      totalPlannedProjectUnits: 3000,
      totalPlannedProjectDays: 100,
      plannedCrossingCount: 1,
      pickupRemainCount: null,
      sawCutsTime: 0,
      firstWeldTime: 0,
      firstSpikedPulled: 1544508060000,
      firstSpikeDrivenTime: 1544511600000,
      lastSpikeDriven: 1544515200000,
      actualCrossingCount: 1,
      reportRoadCrossingList: [
        {
          roadCrossingId: '351962G',
          jobId: -1,
          projectId: 361,
          prefix: '0LK',
          milePost: 425.62,
          crossingSurfaceType: '0',
          isMaterialReUsed: false,
          crossingLength: 2,
          numCrossingTiesInstalled: 2,
          isCrossingSurfaced: true,
          otherSpecification: '',
          trackType: 'SG',
          dotId: '351962G',
          roadCrossingMP: 425.62,
          assetEntryNumber: 0
        }
      ],
      reportTurnoutList: [
        {
          feetPerPass: '4',
          noOfPasses: '4',
          isSurfaced: true,
          turnoutId: 41840,
          prefix: '0LK',
          milepost: 422.8,
          trackType: 'C',
          trackName: 'SG',
          direction: 'L',
          railWeight: 0,
          turnoutLength: null,
          f_t: null,
          assetEntryNumber: 0,
          turnoutSize: 0,
          endMp: 0,
          switchName: 'Davis Creek Mine Lead  TO'
        }
      ],
      totalUnitInstalled: 4,
      reportWindows: [
        {
          unitsInstalled: 4,
          unitPerHour: '4.07',
          windowNumber: 1,
          windowStarts: '2018-12-11T09:12',
          windowEnds: '2018-12-11T10:11',
          windowStart: 1544537520000,
          windowEnd: 1544541060000
        }
      ],
      plannedStart: 1544511600000,
      plannedEnd: 1544515200000,
      greenSignalTime: 1544515200000,
      delayIssuesRemark: null,
      issuesDesc: null,
      machineFailureSeq: 'N',
      transOfficerAval: 'N',
      transOfficerName: null,
      trackReturnLate: 'Y',
      trackReturnLateRemarks: null,
      trainsPassedCount: null,
      workAtYard: 'N',
      yardName: null
    },
    {
      status: null,
      formId: 972316,
      workday: 1422963000000,
      workTypeCode: 'RA',
      teamName: '6XC5',
      projectId: 361,
      jobId: 158074,
      reportWindows: [
        {
          unitsInstalled: 200.0,
          windowEnd: 1521658059097,
          windowStart: 1521649000,
          windowNumber: '1'
        },
        {
          unitsInstalled: 256.0,
          windowEnd: 1521658037000,
          windowStart: 1521658026000,
          windowNumber: '2'
        },
        {
          unitsInstalled: 510.0,
          windowEnd: 152165801000,
          windowStart: 152165800000,
          windowNumber: '3'
        }
      ],
      reportMachineFailure: [],
      reportAssetList: [
        {
          assetEntryNumber: 300,
          otherSpecification: 'sdf dsf',
          crossingSurfaceType: null,
          trackName: '1',
          crossingLength: null,
          isRailInstalled: null,
          isMaterialReUsed: null,
          dot_Id: '525998Y'
        },
        {
          assetEntryNumber: 301,
          otherSpecification: 'asdf asdf',
          crossingSurfaceType: null,
          trackName: '2',
          crossingLength: null,
          isRailInstalled: null,
          isMaterialReUsed: null,
          dot_Id: '525997S'
        }
      ],
      reportTransportation: null,
      plannedStartTime: 1522863022533,
      plannedEndTime: 1522863022533,
      workAtYard: 'Y',
      yardName: 'John Smith',
      greenSignalTime: 1522863022533,
      issuesDesc: null,
      workDescription: null,
      machineFailureSeq: 'No',
      transOfficerAval: 'Yes',
      transOfficerName: 'John Doe',
      trackReturnLate: 'No',
      trackReturnLateRemarks: 'Comments - Late Return Remarks',
      trainsPassedCount: 10,
      delayTime: 1522863022533,
      actualCrossingCount: 2,
      daysToComplete: 2,
      firstSpikeDrivenTime: null,
      firstSpikedPulled: 1422969600000,
      firstWeldTime: null,
      lastSpikeDriven: 1422998400000,
      pickupRemainCount: null,
      plannedCrossingCount: 0,
      sawCutsTime: null,
      totalPlannedProjectDays: 100,
      totalPlannedProjectUnits: 3500,
      unitsRemaining: 2475,
      actualProjectUnits: null,
      actualProjectDays: null,
      prefix: '0LB',
      railWeight: 115,
      railGrade: 'P',
      railType: 'N',
      welds: 2,
      iJoints: 0,
      transitions: 0,
      tiePlateType: 'S',
      highMp: 280.48,
      lowMp: 280.3,
      railTemp1: null,
      railTemp2: null,
      railTemp3: null,
      remarks: null,
      direction: 'Increasing',
      railSide: 'H',
      railLength: null,
      totalOpenJoints: null,
      roadMaster: ' Baldwin,Ronald A ',
      railSideDisc: null,
      supervisor: 'SILVESTER HILL',
      reportAsset: [],
      reportRoadCrossingList: [
        {
          assetEntryNumber: 300,
          otherSpecification: 'sdf dsf',
          crossingSurfaceType: null,
          trackName: '1',
          crossingLength: null,
          isRailInstalled: null,
          isMaterialReUsed: null,
          dot_Id: '525998Y'
        },
        {
          assetEntryNumber: 300,
          otherSpecification: 'sdf dsf',
          crossingSurfaceType: null,
          trackName: '1',
          crossingLength: null,
          isRailInstalled: null,
          isMaterialReUsed: null,
          dot_Id: '525998Y'
        },
        {
          assetEntryNumber: 300,
          otherSpecification: 'sdf dsf',
          crossingSurfaceType: null,
          trackName: '1',
          crossingLength: null,
          isRailInstalled: null,
          isMaterialReUsed: null,
          dot_Id: '525998Y'
        },
        {
          assetEntryNumber: 300,
          otherSpecification: 'sdf dsf',
          crossingSurfaceType: null,
          trackName: '1',
          crossingLength: null,
          isRailInstalled: null,
          isMaterialReUsed: null,
          dot_Id: '525998Y'
        }
      ],
      cutRail: [
        {
          actualExpandMeasure: 4.375,
          changeTempMeasure: 113,
          coldRailTempMeasure: 55,
          designedLayTemp: 105,
          openJoints: null,
          tempChangeMeasure: null,
          railNeutralTemp: null,
          cutRailIndex: 0,
          cutRailLength: 957.0,
          requiredExpandMeasure: 3.75,
          stringId: 'rs146652',
          workSkipCode: false,
          taskTypeCode: null
        }
      ]
    },
    {
      status: null,
      formId: 970685,
      workday: 1422531000000,
      workTypeCode: 'RA',
      teamName: '6XC5',
      projectId: 361,
      jobId: 158074,
      reportWindows: [
        {
          delaysIssues: null,
          unitsInstalled: 200.0,
          windowEnd: 1521658059097,
          windowStart: 1521649000,
          windowNumber: '1'
        },
        {
          delaysIssues: null,
          unitsInstalled: 256.0,
          windowEnd: 1521658037000,
          windowStart: 1521658026000,
          windowNumber: '2'
        },
        {
          delaysIssues: null,
          unitsInstalled: 510.0,
          windowEnd: 152165801000,
          windowStart: 152165800000,
          windowNumber: '3'
        }
      ],
      reportMachineFailure: [],
      reportAssetList: [
        {
          assetEntryNumber: 300,
          otherSpecification: 'sdf dsf',
          crossingSurfaceType: null,
          trackName: '1',
          crossingLength: null,
          isRailInstalled: null,
          isMaterialReUsed: null,
          dot_Id: '525998Y'
        },
        {
          assetEntryNumber: 301,
          otherSpecification: 'asdf asdf',
          crossingSurfaceType: null,
          trackName: '2',
          crossingLength: null,
          isRailInstalled: null,
          isMaterialReUsed: null,
          dot_Id: '525997S'
        }
      ],
      reportTransportation: null,
      plannedStartTime: 1522863022533,
      plannedEndTime: 1522863022533,
      workAtYard: 'Y',
      yardName: 'John Smith',
      greenSignalTime: 1522863022533,
      issuesDesc: null,
      workDescription: null,
      machineFailureSeq: 'No',
      transOfficerAval: 'Yes',
      transOfficerName: 'John Doe',
      trackReturnLate: 'No',
      trackReturnLateRemarks: 'Comments - Late Return Remarks',
      trainsPassedCount: 10,
      delayTime: 1522863022533,
      actualCrossingCount: 2,
      daysToComplete: 4,
      firstSpikeDrivenTime: null,
      firstSpikedPulled: 1422538380000,
      firstWeldTime: null,
      lastSpikeDriven: 1422565200000,
      pickupRemainCount: null,
      plannedCrossingCount: 0,
      sawCutsTime: null,
      totalPlannedProjectDays: 100,
      totalPlannedProjectUnits: 4000,
      unitsRemaining: 7140,
      actualProjectUnits: null,
      actualProjectDays: null,
      prefix: '0LB',
      railWeight: 115,
      railGrade: 'P',
      railType: 'N',
      welds: 3,
      iJoints: 0,
      transitions: 0,
      tiePlateType: 'S',
      highMp: 302.66,
      lowMp: 302.46,
      railTemp1: null,
      railTemp2: null,
      railTemp3: null,
      remarks: null,
      direction: 'Increasing',
      railSide: 'H',
      railLength: null,
      totalOpenJoints: null,
      roadMaster: 'Baldwin,Ronald A',
      railSideDisc: null,
      supervisor: 'SILVESTER HILL',
      reportAsset: [],
      cutRail: [],
      reportRoadCrossingList: [
        {
          assetEntryNumber: 300,
          otherSpecification: 'sdf dsf',
          crossingSurfaceType: null,
          trackName: '1',
          crossingLength: null,
          isRailInstalled: null,
          isMaterialReUsed: null,
          dot_Id: '525998Y'
        },
        {
          assetEntryNumber: 300,
          otherSpecification: 'sdf dsf',
          crossingSurfaceType: null,
          trackName: '1',
          crossingLength: null,
          isRailInstalled: null,
          isMaterialReUsed: null,
          dot_Id: '525998Y'
        },
        {
          assetEntryNumber: 300,
          otherSpecification: 'sdf dsf',
          crossingSurfaceType: null,
          trackName: '1',
          crossingLength: null,
          isRailInstalled: null,
          isMaterialReUsed: null,
          dot_Id: '525998Y'
        },
        {
          assetEntryNumber: 300,
          otherSpecification: 'sdf dsf',
          crossingSurfaceType: null,
          trackName: '1',
          crossingLength: null,
          isRailInstalled: null,
          isMaterialReUsed: null,
          dot_Id: '525998Y'
        }
      ]
    },
    {
      status: null,
      formId: 12324,
      workday: 1422963000000,
      workTypeCode: 'RA',
      teamName: '6XC4',
      projectId: 361,
      jobId: 158074,
      reportWindows: [
        {
          unitsInstalled: 200.0,
          windowEnd: 1521658059097,
          windowStart: 1521649000,
          windowNumber: '1'
        },
        {
          unitsInstalled: 256.0,
          windowEnd: 1521658037000,
          windowStart: 1521658026000,
          windowNumber: '2'
        },
        {
          unitsInstalled: 510.0,
          windowEnd: 152165801000,
          windowStart: 152165800000,
          windowNumber: '3'
        }
      ],
      actualCrossingCount: null,
      daysToComplete: 2,
      firstSpikeDrivenTime: null,
      firstSpikedPulled: 1422972540000,
      firstWeldTime: null,
      lastSpikeDriven: 1422993600000,
      pickupRemainCount: null,
      plannedCrossingCount: 0,
      sawCutsTime: null,
      totalPlannedProjectDays: 100,
      totalPlannedProjectUnits: 1500,
      unitsRemaining: 0,
      actualProjectUnits: null,
      actualProjectDays: null,
      prefix: '0LB',
      delayTime: null,
      middayClearsDelay: null,
      middayClearsCount: 0,
      trainsPassedCount: 0,
      transOfficerAval: 'N',
      transOfficerName: 'ERIC BETTS',
      trackRecvdLateRemarks: null,
      trackRecvdLate: null,
      trackReturnLate: null,
      trackReturnLateRemarks: null,
      weatherDelaysCount: 0,
      weatherDelaysTime: null,
      weatherDelaysRemarks: null,
      divisionCode: 'FL',
      subdivCode: 'WM',
      trackRecvdTime: 1422967620000,
      trackClearedTime: 1423000500000,
      yardName: null,
      teamInTime: null,
      timeOnTrack: '0551',
      injuriesNumber: 0,
      safetyStatusCode: null,
      slowOrderRemovalPlan: null,
      machineFailureSeq: 'N',
      machineFailureCount: 0,
      issuesDesc: null,
      workonSchedule: 'Y',
      delayIssuesRemark: null,
      safeDaysNumber: null,
      greenSignalTime: null,
      switchesBehindCount: null,
      removePickupLowMP: null,
      removePickupHighMP: null,
      toorCount: null,
      plannedOTHours: 10,
      plannedSTHours: 10,
      plannedTurnoutCount: 0,
      plannedUnitsHour: 207.9,
      plannedUnitsCount: 2156,
      lastOilChangeTime: null,
      pickupCount: null,
      plannedEmployeesCount: 27,
      actualTurnoutsCount: 0,
      actualUnitsHours: 270.5,
      actualUnits: 2705,
      crossingsBehind: null,
      actualEmployeesCount: 27,
      atualOTHours: null,
      actualSTHours: 10,
      railWeight: null,
      railGrade: null,
      railType: null,
      welds: null,
      iJoints: null,
      transitions: null,
      tiePlateType: null,
      highMp: null,
      lowMp: null,
      railTemp1: null,
      railTemp2: null,
      railTemp3: null,
      remarks: null,
      direction: null,
      railSide: null,
      railLength: null,
      totalOpenJoints: null,
      roadMaster: null,
      railSideDisc: null,
      supervisor: 'TODD DOVE',
      reportRoadCrossingList: [],
      jobComplete: null,
      jobType: null,
      cutRail: [
        {
          actualExpandMeasure: 1.5,
          changeTempMeasure: 122,
          coldRailTempMeasure: 50,
          designedLayTemp: 105,
          openJoints: null,
          tempChangeMeasure: null,
          railNeutralTemp: null,
          cutRailIndex: 0,
          cutRailLength: 264,
          requiredExpandMeasure: 1.25,
          stringId: 'nw142810',
          isWorkSkipped: false,
          taskTypeCode: null,
          railNeuturalTemp: null
        },
        {
          actualExpandMeasure: 6.875,
          changeTempMeasure: 111,
          coldRailTempMeasure: 50,
          designedLayTemp: 105,
          openJoints: null,
          tempChangeMeasure: null,
          railNeutralTemp: null,
          cutRailIndex: 1,
          cutRailLength: 1440,
          requiredExpandMeasure: 6.25,
          stringId: 'nw142809',
          isWorkSkipped: false,
          taskTypeCode: null,
          railNeuturalTemp: null
        },
        {
          actualExpandMeasure: 4.25,
          changeTempMeasure: 119,
          coldRailTempMeasure: 65,
          designedLayTemp: 105,
          openJoints: null,
          tempChangeMeasure: null,
          railNeutralTemp: null,
          cutRailIndex: 2,
          cutRailLength: 1001,
          requiredExpandMeasure: 3.125,
          stringId: 'nw142808',
          isWorkSkipped: false,
          taskTypeCode: null,
          railNeuturalTemp: null
        }
      ]
    },
    {
      status: null,
      formId: 15353,
      workday: 1421929800000,
      workTypeCode: 'RA',
      teamName: '6XC4',
      projectId: 361,
      jobId: 158074,
      reportWindows: [
        {
          unitsInstalled: 200.0,
          windowEnd: 1521658059097,
          windowStart: 1521649000,
          windowNumber: '1'
        },
        {
          unitsInstalled: 256.0,
          windowEnd: 1521658037000,
          windowStart: 1521658026000,
          windowNumber: '2'
        },
        {
          unitsInstalled: 510.0,
          windowEnd: 152165801000,
          windowStart: 152165800000,
          windowNumber: '3'
        }
      ],
      actualCrossingCount: null,
      daysToComplete: 0,
      firstSpikeDrivenTime: null,
      firstSpikedPulled: 1421946000000,
      firstWeldTime: null,
      lastSpikeDriven: 1421955000000,
      pickupRemainCount: null,
      plannedCrossingCount: 0,
      sawCutsTime: null,
      totalPlannedProjectDays: 100,
      totalPlannedProjectUnits: 1500,
      unitsRemaining: 0,
      actualProjectUnits: null,
      actualProjectDays: null,
      prefix: '0LB',
      delayTime: '0050',
      middayClearsDelay: null,
      middayClearsCount: 0,
      trainsPassedCount: 5,
      transOfficerAval: 'N',
      transOfficerName: 'BREET MOORE',
      trackRecvdLateRemarks: '  AFTER WO70 ENGINE 3033',
      trackRecvdLate: 'Y',
      trackReturnLate: null,
      trackReturnLateRemarks: null,
      weatherDelaysCount: 0,
      weatherDelaysTime: null,
      weatherDelaysRemarks: null,
      divisionCode: 'FL',
      subdivCode: 'HT',
      trackRecvdTime: 1421943900000,
      trackClearedTime: 1421962500000,
      yardName: null,
      teamInTime: null,
      timeOnTrack: '0230',
      injuriesNumber: 0,
      safetyStatusCode: null,
      slowOrderRemovalPlan: null,
      machineFailureSeq: 'N',
      machineFailureCount: 0,
      issuesDesc: null,
      workonSchedule: 'Y',
      delayIssuesRemark: null,
      safeDaysNumber: null,
      greenSignalTime: null,
      switchesBehindCount: null,
      removePickupLowMP: null,
      removePickupHighMP: null,
      toorCount: null,
      plannedOTHours: 10,
      plannedSTHours: 10,
      plannedTurnoutCount: 0,
      plannedUnitsHour: 227.16,
      plannedUnitsCount: 2359,
      lastOilChangeTime: null,
      pickupCount: null,
      plannedEmployeesCount: 26,
      actualTurnoutsCount: 0,
      actualUnitsHours: 119.6,
      actualUnits: 1196,
      crossingsBehind: null,
      actualEmployeesCount: 26,
      atualOTHours: null,
      actualSTHours: 10,
      railWeight: null,
      railGrade: null,
      railType: null,
      welds: null,
      iJoints: null,
      transitions: null,
      tiePlateType: null,
      highMp: null,
      lowMp: null,
      railTemp1: null,
      railTemp2: null,
      railTemp3: null,
      remarks: null,
      direction: null,
      railSide: null,
      railLength: null,
      totalOpenJoints: null,
      roadMaster: null,
      railSideDisc: null,
      supervisor: 'TODD DOVE',
      reportRoadCrossingList: [],
      jobComplete: null,
      jobType: null,
      cutRail: [
        {
          actualExpandMeasure: 2.5,
          changeTempMeasure: 124,
          coldRailTempMeasure: 60,
          designedLayTemp: 105,
          openJoints: null,
          tempChangeMeasure: null,
          railNeutralTemp: null,
          cutRailIndex: 0,
          cutRailLength: 500,
          requiredExpandMeasure: 1.875,
          stringId: 'NW146211',
          isWorkSkipped: false,
          taskTypeCode: null,
          railNeuturalTemp: null
        },
        {
          actualExpandMeasure: 2.5,
          changeTempMeasure: 116,
          coldRailTempMeasure: 70,
          designedLayTemp: 105,
          openJoints: null,
          tempChangeMeasure: null,
          railNeutralTemp: null,
          cutRailIndex: 1,
          cutRailLength: 696,
          requiredExpandMeasure: 2,
          stringId: 'NW146210',
          isWorkSkipped: false,
          taskTypeCode: null,
          railNeuturalTemp: null
        }
      ]
    },
    {
      status: null,
      formId: 16543,
      workday: 1422531000000,
      workTypeCode: 'RA',
      teamName: '6XC5',
      projectId: 361,
      jobId: 158074,
      reportWindows: [
        {
          unitsInstalled: 200.0,
          windowEnd: 1521658059097,
          windowStart: 1521649000,
          windowNumber: '1'
        },
        {
          unitsInstalled: 256.0,
          windowEnd: 1521658037000,
          windowStart: 1521658026000,
          windowNumber: '2'
        },
        {
          unitsInstalled: 510.0,
          windowEnd: 152165801000,
          windowStart: 152165800000,
          windowNumber: '3'
        }
      ],
      actualCrossingCount: null,
      daysToComplete: 4,
      firstSpikeDrivenTime: null,
      firstSpikedPulled: 1422538380000,
      firstWeldTime: null,
      lastSpikeDriven: 1422565200000,
      pickupRemainCount: null,
      plannedCrossingCount: 0,
      sawCutsTime: null,
      totalPlannedProjectDays: 100,
      totalPlannedProjectUnits: 2000,
      unitsRemaining: 7140,
      actualProjectUnits: null,
      actualProjectDays: null,
      prefix: '0LB',
      delayTime: null,
      middayClearsDelay: null,
      middayClearsCount: 0,
      trainsPassedCount: 0,
      transOfficerAval: 'N',
      transOfficerName: 'ERIC BETTS',
      trackRecvdLateRemarks: null,
      trackRecvdLate: null,
      trackReturnLate: null,
      trackReturnLateRemarks: null,
      weatherDelaysCount: 0,
      weatherDelaysTime: null,
      weatherDelaysRemarks: null,
      divisionCode: 'FL',
      subdivCode: 'HA',
      trackRecvdTime: 1422532800000,
      trackClearedTime: 1422568800000,
      yardName: null,
      teamInTime: null,
      timeOnTrack: '0723',
      injuriesNumber: 0,
      safetyStatusCode: null,
      slowOrderRemovalPlan: null,
      machineFailureSeq: 'N',
      machineFailureCount: 0,
      issuesDesc: null,
      workonSchedule: 'Y',
      delayIssuesRemark: null,
      safeDaysNumber: null,
      greenSignalTime: null,
      switchesBehindCount: null,
      removePickupLowMP: null,
      removePickupHighMP: null,
      toorCount: null,
      plannedOTHours: 10,
      plannedSTHours: 10,
      plannedTurnoutCount: 0,
      plannedUnitsHour: 207.9,
      plannedUnitsCount: 2156,
      lastOilChangeTime: null,
      pickupCount: null,
      plannedEmployeesCount: 27,
      actualTurnoutsCount: 0,
      actualUnitsHours: 225.7,
      actualUnits: 2257,
      crossingsBehind: null,
      actualEmployeesCount: 27,
      atualOTHours: null,
      actualSTHours: 10,
      railWeight: null,
      railGrade: null,
      railType: null,
      welds: null,
      iJoints: null,
      transitions: null,
      tiePlateType: null,
      highMp: null,
      lowMp: null,
      railTemp1: null,
      railTemp2: null,
      railTemp3: null,
      remarks: null,
      direction: null,
      railSide: null,
      railLength: null,
      totalOpenJoints: null,
      roadMaster: null,
      railSideDisc: null,
      supervisor: 'SILVESTER HILL',
      reportRoadCrossingList: [],
      jobComplete: null,
      jobType: null,
      cutRail: [
        {
          actualExpandMeasure: null,
          changeTempMeasure: null,
          coldRailTempMeasure: null,
          designedLayTemp: null,
          openJoints: null,
          tempChangeMeasure: null,
          railNeutralTemp: null,
          cutRailIndex: 1,
          cutRailLength: 1046,
          requiredExpandMeasure: null,
          stringId: null,
          isWorkSkipped: false,
          taskTypeCode: null,
          railNeuturalTemp: null
        },
        {
          actualExpandMeasure: 5,
          changeTempMeasure: 107,
          coldRailTempMeasure: 55,
          designedLayTemp: 105,
          openJoints: null,
          tempChangeMeasure: null,
          railNeutralTemp: null,
          cutRailIndex: 0,
          cutRailLength: 1211,
          requiredExpandMeasure: 4.75,
          stringId: 'RS146639',
          isWorkSkipped: false,
          taskTypeCode: null,
          railNeuturalTemp: null
        }
      ]
    },
    {
      workTypeCode: 'RA',
      rl: 'R89',
      divisionCode: 'NS',
      subdivCode: 'C8',
      trackType: 'SG',
      reportRoadCrossingList: [],
      formId: -5,
      teamName: null,
      projectId: 447,
      jobId: 200788,
      jobType: 'PR',
      prefix: '00J',
      noProductionCode: null,
      beginMp: 92.3,
      endMp: 92.32,
      lowMp: 92.3,
      highMp: 92.32,
      totalOpenJoints: 0,
      direction: 'I',
      railGrade: 'P',
      railType: 'N',
      tiePlateType: 'P',
      roadMaster: 'Adamson,Michael W',
      railSide: 'R',
      workday: 1544940000000,
      railLength: 100,
      welds: 0,
      iJoints: 0,
      transitions: 0,
      railTemp1: 2,
      railTemp2: 2,
      railTemp3: 2,
      remarks: null,
      railWeight: 136,
      reportCutRail: [
        {
          cutRailLength: 100,
          openJoints: 0,
          isWorkSkipped: false,
          stringId: '33',
          designedLayTemp: '100',
          coldRailTemp: 3,
          changeTempMeasure: 97,
          requiredExpandMeasure: 0.875,
          actualExpandMeasure: 1,
          tempChangeMeasure: 128,
          railNeutralTemp: 131,
          cutRailIndex: 0,
          tempDiff: -31.210000000000008,
          cutRailBeginMp: 92.3,
          cutRailEndMp: 92.32,
          showTempBg: true
        }
      ],
      status: 'D',
      totalActualProjectUnits: 59898,
      daysToComplete: 19,
      unitsRemaining: 0,
      totalPlannedProjectUnits: 59526,
      totalPlannedProjectDays: 20,
      plannedCrossingCount: 5,
      pickupRemainCount: null,
      sawCutsTime: 1544940060000,
      firstWeldTime: 1544947200000,
      firstSpikedPulled: 1544943600000,
      firstSpikeDrivenTime: 1544950800000,
      lastSpikeDriven: 1544954400000,
      actualCrossingCount: 0,
      totalUnitInstalled: 100,
      desiredNeutralTemp: 110,
      reportWindows: [
        {
          unitsInstalled: 100,
          unitPerHour: '50.00',
          windowNumber: 1,
          windowStarts: '2018-12-16T01:00',
          windowEnds: '2018-12-16T03:00',
          windowStart: 1544940000000,
          windowEnd: 1544947200000
        }
      ],
      plannedStart: 1544940000000,
      plannedEnd: 1544943600000,
      greenSignalTime: 1544943600000,
      delayIssuesRemark: null,
      issuesDesc: null,
      machineFailureSeq: 'N',
      transOfficerAval: 'N',
      transOfficerName: null,
      trackReturnLate: 'Y',
      trackReturnLateRemarks: null,
      trainsPassedCount: null,
      workAtYard: 'N',
      yardName: null
    }
  ];

  public rowData: any[] = [
    {
      project: 447,
      jobCount: 8,
      startDate: 1520226000000,
      endDate: 1522468800000,
      projectType: 'RAIL',
      prefix: '00J',
      startMP: 71.3,
      endMP: 127.4,
      preTrip: null,
      preWork: null,
      postWork: null,
      projectTypeCode: 'RL',
      materialDelivered: '-1',
      materialTotal: '-1',
      formattedStartDate: '03/05/2018',
      formattedEndDate: '03/31/2018',
      percentWorkComplete: 100.62,
      totalPlannedProjectDays: 20,
      totalPlannedProjectUnits: 59526,
      initialPlannedCrossing: 5,
      initialPlannedDays: 20,
      initialPlannedProjectUnits: 59526,
      plannedCrossingCount: 5,
      projectLocation: [
        {
          division: 'NS',
          subdivision: 'C8',
          divisionX: 'NASHVILLE',
          subdivisionX: 'CHATTANOOGA'
        }
      ],
      totalActualProjectUnits: 59898,
      daysToComplete: 20,
      unitsRemaining: -372
    },
    {
      project: 361,
      jobCount: '2',
      startDate: 1520226000000,
      endDate: 1520571600000,
      projectType: 'TIE AND SURFACE',
      prefix: '0LB',
      daysToComplete: 10,
      startMP: '388.34',
      endMP: '404.1',
      // 'division': 'AT',
      // 'subdivision': 'BIRMINGHAM MINERAL',
      projectLocation: [
        {
          division: 'JX',
          subdivision: 'JESUP'
        },
        {
          division: 'HU',
          subdivision: 'BONE VALLEY'
        },
        {
          division: 'JX',
          subdivision: 'JESUP'
        },
        {
          division: 'HU',
          subdivision: 'BONE VALLEY'
        },
        {
          division: 'HU',
          subdivision: 'JESUP'
        },
        {
          division: 'JX',
          subdivision: 'BONE VALLEY'
        }
      ],
      preTrip: '-1',
      preWork: '-1',
      postWork: '-1',
      projectTypeCode: 'TS',
      materialDelivered: '-1',
      materialTotal: '-1',
      formattedStartDate: '03/05/2018',
      formattedEndDate: '03/09/2018',
      percentWorkComplete: '50',
      totalPlannedProjectUnits: 3000,
      totalPlannedProjectDays: 100,
      plannedCrossingCount: 2,
      totalActualProjectUnits: 0,
      unitsRemaining: 3000
    },
    {
      project: 318,
      jobCount: 1,
      startDate: 1534737600000,
      endDate: 1535169600000,
      projectType: 'TIE AND SURFACE',
      prefix: 'BED',
      startMP: 2.5,
      endMP: 13.04,
      preTrip: null,
      preWork: null,
      postWork: null,
      projectTypeCode: 'TS',
      materialDelivered: '-1',
      materialTotal: '-1',
      formattedStartDate: '08/20/2018',
      formattedEndDate: '08/25/2018',
      percentWorkComplete: 0.0,
      totalPlannedProjectDays: 5,
      totalPlannedProjectUnits: 11397,
      plannedCrossingCount: 16,
      projectLocation: [
        {
          division: 'LO',
          subdivision: 'M7',
          divisionX: 'LOUISVILLE',
          subdivisionX: 'MIDDLETOWN'
        }
      ],
      totalActualProjectUnits: 0,
      daysToComplete: 5,
      unitsRemaining: 11397
    },
    {
      project: 516,
      jobCount: 1,
      startDate: 1515992400000,
      endDate: 1517029200000,
      projectType: 'SURFACING',
      prefix: 'S',
      startMP: 808.0,
      endMP: 822.7,
      preTrip: null,
      preWork: null,
      postWork: null,
      projectTypeCode: 'SU',
      materialDelivered: '-1',
      materialTotal: '-1',
      formattedStartDate: '01/15/2018',
      formattedEndDate: '01/27/2018',
      percentWorkComplete: 0.0,
      totalPlannedProjectDays: 10,
      totalPlannedProjectUnits: 76771,
      plannedCrossingCount: 29,
      projectLocation: [
        {
          division: 'JX',
          subdivision: 'YE',
          divisionX: 'JACKSONVILLE',
          subdivisionX: 'YEOMAN'
        }
      ],
      totalActualProjectUnits: 0,
      daysToComplete: 10,
      unitsRemaining: 76771
    }
  ];

  public ref_d: any[] = [
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'DIV',
      refList: {
        AT: 'ATLANTA',
        CG: 'CHICAGO',
        LO: 'LOUISVILLE',
        JX: 'JACKSONVILLE',
        NS: 'NASHVILLE',
        GL: 'GREAT LAKES',
        FL: 'FLORENCE',
        AY: 'ALBANY',
        BA: 'BALTIMORE',
        AP: 'APPALACHIAN',
        HU: 'C&O'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'RWT',
      refList: {
        '70': '70  # Rail',
        '75': '75  # Rail',
        '78': '78  # Rail',
        '80': '80  # Rail',
        '85': '85  # Rail',
        '90': '90  # Rail',
        '100': '100  # Rail',
        '105': '105  # Rail',
        '107': '107  # Rail',
        '110': '110  # Rail',
        '112': '112  # Rail',
        '114': '114  # Rail',
        '115': '115  # Rail',
        '119': '119  # Rail',
        '120': '120  # Rail',
        '122': '122  # Rail',
        '125': '125  # Rail',
        '127': '127  # Rail',
        '130': '130  # Rail',
        '131': '131  # Rail',
        '132': '132  # Rail',
        '133': '133  # Rail',
        '136': '136  # Rail',
        '140': '140  # Rail',
        '141': '141  # Rail',
        '152': '152  # Rail',
        '155': '155  # Rail'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'SRT',
      refList: {
        UT: 'Undercut',
        ST: 'Straight'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'SPT',
      refList: {
        UT: 'Undercut',
        ST: 'Straight'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'RMT',
      refList: {
        UC: 'Undercutting',
        BC: 'Brushcutting',
        BD: 'Bulldozing',
        OT: ' Others',
        YC: 'Yard Cleaning',
        DI: 'Ditching'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'REQ',
      refList: {
        SC: 'Switch Component',
        IJ: 'Insulated Joints',
        RG: 'Rail Grinding',
        RC: 'Road Crossing',
        GA: 'Gauging',
        SI: 'Surfacing - Main Line Interlockings',
        BT: 'Bridge Ties',
        TO: 'Turnout',
        OT: 'Other Requests Non-Capital',
        TN: 'Tunnels',
        RP: 'Division Program Ties',
        CB: 'Bridges',
        RM: 'Roadway Machine ',
        TS: 'Tie and Surface',
        RL: 'Rail Lubricators',
        SO: 'Surfacing - Out of Face',
        SY: 'Surfacing - Yard with Switch Ties',
        PR: 'Patch Rail',
        CD: 'Crossing Diamonds',
        OF: 'Out of Face Rail',
        CP: 'Concrete Pad',
        TU: 'Track Undercutting',
        SB: 'Shoulder Ballast Cleaning'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'RGR',
      refList: {
        MH: 'Medium Hardness',
        P: 'Premium Rail',
        IH: 'Intermediate Hardness'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'TPG',
      refList: {
        F: 'Pandrol',
        P: '18 inch',
        S: 'Standard',
        C: 'Concrete'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'TNR',
      refList: {
        R: 'Relay',
        N: 'New'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'TBD',
      refList: {
        CLS: 'Changed by Local Supervision',
        BIR: 'Bluebook Indicated Railside Already Replaced',
        CSS: 'Changed by System Supervision',
        CCO: 'Changed by Change Order',
        ICD: 'Bluebook Indicated Curve Direction is Incorrect'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'TZZ',
      refList: {
        FPE: 'Finished Project Early',
        TRA: 'Tramming',
        ZOT: 'Assisting Another Team',
        STX: 'Safety Training',
        NOI: 'No Orders Issued',
        SSD: 'Safety Stand Down',
        TTR: 'Prep Work',
        WEW: 'Waiting on Equipment Work Train',
        NTT: 'No Track Time Received',
        WXX: 'Weather',
        MWM: 'Mid-week Move',
        WOA: 'Work Other Than Assigned',
        UPE: 'Unable to Position Equipment (EQ Failure)',
        RST: 'Rest Day'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'TRK',
      refList: {
        '1': 'Track 1',
        '2': 'Track 2',
        '3': 'Track 3',
        '4': 'Track 4',
        '5': 'Track 5',
        SD: 'Siding',
        W1: 'Wye 1',
        W2: 'Wye 2',
        SG: 'Single',
        S2: 'S2',
        S1: 'S1',
        YD: 'Yard',
        ALL: 'All',
        CON: 'Connection',
        S3: 'S3',
        S4: 'S4',
        S5: 'S5',
        S6: 'S6',
        S7: 'S7',
        INT: 'Interlocking',
        WY: 'Wye Track',
        MUL: 'Multiple'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'XSR',
      refList: {
        '1': 'Timber',
        '2': 'Asphalt',
        '3': 'Asphalt Flange',
        '4': 'Concrete',
        '5': 'Concrete and Rubber',
        '6': 'Rubber',
        '7': 'Metal',
        '8': 'Unconsolidated',
        '9': 'Other - specify'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'DTP',
      refList: {
        '95': '95',
        '100': '100',
        '105': '105',
        '110': '110'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'RTT',
      refList: {
        '75': '75',
        '80': '80',
        '85': '85',
        '90': '90'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'RSE',
      refList: {
        W: 'Low',
        R: 'Right',
        L: 'Left',
        H: 'High'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'TEAM',
      refList: {
        '5XT0': '5XT0',
        '5XC1': '5XC1',
        '5XTA': '5XTA',
        '5XC3': '5XC3',
        '5XC2': '5XC2',
        '5XC5': '5XC5',
        '5XC4': '5XC4',
        '5XT9': '5XT9',
        '5XT2': '5XT2',
        '5XT1': '5XT1',
        '5XR2': '5XR2',
        '5XT4': '5XT4',
        '5XR1': '5XR1',
        '5XT3': '5XT3',
        '5XT6': '5XT6',
        '5XR3': '5XR3',
        '5XT5': '5XT5',
        '5XT8': '5XT8',
        '5XT7': '5XT7'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'TCR',
      refList: {
        SNB: 'Seniority Bump',
        SPV: 'Supervisor',
        TWA: 'Temporary Work Assignment'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'STW',
      refList: {
        SU: 'Surfacing',
        RA: 'Rail',
        TS: 'Tie and Surface'
      }
    },

    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'TTT',
      refList: {
        W: 'Wood',
        P: 'Composite (Plastic)',
        B: 'Branchline Wood',
        C: 'Concrete',
        M: 'Mainline Wood'
      }
    },

    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'STT',
      refList: {
        W: 'Wood',
        S: 'Steel',
        C: 'Concrete'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'SFT',
      refList: {
        RG: 'Regulate Only',
        OF: 'Out of Face',
        LN: 'Line Only',
        SM: 'Smoothing',
        SP: 'Spot',
        JT: 'Joint'
      }
    },

    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'INCHES',
      refList: {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '10',
        '11': '11'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'FRACTIONS',
      refList: {
        '0': '0/0',
        '0.0625': '1/16',
        '0.625': '5/8',
        '0.875': '7/8',
        '0.1875': '3/16',
        '0.375': '3/8',
        '0.75': '3/4',
        '0.5625': '9/16',
        '0.125': '1/8',
        '0.8125': '13/16',
        '0.4375': '7/16',
        '0.6875': '11/16',
        '0.25': '1/4',
        '0.3125': '5/16',
        '0.5': '1/2',
        '0.9375': '15/16'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'TDS',
      refList: {
        10: '10',
        15: '15',
        20: '20',
        25: '25',
        30: '30',
        35: '35',
        40: '40',
        45: '45',
        50: '50',
        55: '55',
        60: '60',
        65: '65'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'SST',
      refList: {
        140: '140 - Surfacing',
        141: '141 - Running time Ballast compaction'
      }
    },
    {
      errorName: 'SUCCESS',
      errorNumber: 0,
      refType: 'TSS',
      refList: {
        130: '130 - Timbering',
        131: '131 - Timbering Not Surfaced'
      }
    }
  ];

  private turnoutList: any[] = [
    {
      turnoutId: 41789,
      prefix: '0LB',
      milepost: 396.4,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'Y',
      trackName: 'South Lead',
      direction: 'R',
      railWeight: 0,
      f_t: null,
      switchName: 'S. E. 11 & 12 Ensley TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41790,
      prefix: '0LB',
      milepost: 396.41,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'Y',
      trackName: 'South Lead',
      direction: 'R',
      railWeight: 0,
      f_t: null,
      switchName: 'S.E. 10 & 11 Ensley TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41791,
      prefix: '0LB',
      milepost: 401.6,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'Y',
      trackName: 'North lead',
      direction: 'R',
      railWeight: 0,
      f_t: null,
      switchName: 'S.E. 1 & 2 Woodward',
      turnoutSize: 8,
      turnoutLength: null
    },
    {
      turnoutId: 41792,
      prefix: '0LB',
      milepost: 401.1,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'Y',
      trackName: 'South Lead',
      direction: 'L',
      railWeight: 0,
      f_t: null,
      switchName: 'N.E. 1 & 2 Woodward TO',
      turnoutSize: 8,
      turnoutLength: null
    },
    {
      turnoutId: 41793,
      prefix: '0LB',
      milepost: 401.6,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'L',
      railWeight: 0,
      f_t: null,
      switchName: 'X-Over to Main line TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41794,
      prefix: '0LB',
      milepost: 401.7,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'R',
      railWeight: 0,
      f_t: null,
      switchName: 'N.E. #1 & 2 Collins TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41795,
      prefix: '0LB',
      milepost: 402.1,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'R',
      railWeight: 0,
      f_t: null,
      switchName: 'S.E. #1 & 2 Collins TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41796,
      prefix: '0LB',
      milepost: 403.9,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'R',
      railWeight: 0,
      f_t: null,
      switchName: 'Bessemer Engine Trk TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41797,
      prefix: '0LB',
      milepost: 403.9,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'L',
      railWeight: 0,
      f_t: null,
      switchName: '#1 & 2 Coil Tech TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41798,
      prefix: '0LB',
      milepost: 404,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'O',
      trackName: '#1 Coil Tech',
      direction: 'R',
      railWeight: 0,
      f_t: null,
      switchName: '#2 & 3 Coil Tech Trk TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41799,
      prefix: '0LB',
      milepost: 389.4,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'R',
      railWeight: 0,
      f_t: null,
      switchName: 'Jim Walter/BN Conn TO',
      turnoutSize: 8,
      turnoutLength: null
    },
    {
      turnoutId: 41800,
      prefix: '0LB',
      milepost: 389.41,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'R',
      railWeight: 0,
      f_t: null,
      switchName: 'Jim Walter Plant TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41801,
      prefix: '0LB',
      milepost: 389.42,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'R',
      railWeight: 0,
      f_t: null,
      switchName: 'N.E. Jim Walter Storage Trk  TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41802,
      prefix: '0LB',
      milepost: 389.43,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'R',
      railWeight: 0,
      f_t: null,
      switchName: 'Coke Plant TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41803,
      prefix: '0LB',
      milepost: 389.44,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'R',
      railWeight: 0,
      f_t: null,
      switchName: 'S.E. X-Over to J.W. TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41804,
      prefix: '0LB',
      milepost: 389.45,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'L',
      railWeight: 0,
      f_t: null,
      switchName: 'N.E. #1 & 2 Storage TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41805,
      prefix: '0LB',
      milepost: 395.2,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'L',
      railWeight: 0,
      f_t: null,
      switchName: 'N.E. #1 & 2 Ensley TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41806,
      prefix: '0LB',
      milepost: 395.3,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'L',
      railWeight: 0,
      f_t: null,
      switchName: 'N.E. #2 & 3 Ensley TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41807,
      prefix: '0LB',
      milepost: 395.7,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'L',
      railWeight: 0,
      f_t: null,
      switchName: 'S.E.#3 & 4 Ensley TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41808,
      prefix: '0LB',
      milepost: 395.71,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'L',
      railWeight: 0,
      f_t: null,
      switchName: 'S.E.#2 & 3 Ensley TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41809,
      prefix: '0LB',
      milepost: 395.72,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'L',
      railWeight: 0,
      f_t: null,
      switchName: 'S.E. #1 & 2  Ensley TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41810,
      prefix: '0LB',
      milepost: 395.3,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'O',
      trackName: 'North Lead',
      direction: 'R',
      railWeight: 0,
      f_t: null,
      switchName: 'N.E. #3 & 4 Ensley TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41811,
      prefix: '0LB',
      milepost: 395.8,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'R',
      railWeight: 0,
      f_t: null,
      switchName: 'B.S. Connection TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41812,
      prefix: '0LB',
      milepost: 395.8,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'R',
      railWeight: 0,
      f_t: null,
      switchName: 'X-Over from #1 to Main Line  TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41840,
      prefix: '0LK',
      milepost: 422.8,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'L',
      railWeight: 0,
      f_t: null,
      switchName: 'Davis Creek Mine Lead  TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41841,
      prefix: '0LK',
      milepost: 426.7,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'L',
      railWeight: 0,
      f_t: null,
      switchName: 'N.E. Dudley Siding  TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41842,
      prefix: '0LK',
      milepost: 428,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'R',
      railWeight: 0,
      f_t: null,
      switchName: 'S.E. Dudley Siding   TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41843,
      prefix: '0LK',
      milepost: 428.1,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'R',
      railWeight: 0,
      f_t: null,
      switchName: 'Dudley Mine Lead  TO',
      turnoutSize: 0,
      turnoutLength: null
    },
    {
      turnoutId: 41844,
      prefix: '0LK',
      milepost: 428.5,
      endMp: 0,
      division: 'ATLANTA',
      trackType: 'C',
      trackName: 'SG',
      direction: 'R',
      railWeight: 0,
      f_t: null,
      switchName: 'Brookwood Mine Lead  TO',
      turnoutSize: 0,
      turnoutLength: null
    }
  ];

  private roadCrossingList: any[] = [
    {
      prefix: '0LB',
      milepost: 393.93,
      dotNumber: '728091X',
      division: 'Atlanta',
      subdivision: 'Birmingham Mineral',
      highway: 'CITY',
      street: 'AVE V',
      city: 'BIRMINGHAM',
      state: 'ALABAMA',
      position: 'At Grade',
      status: 'Open',
      trackList: 'SG',
      tracks: [
        {
          track: 'SG',
          dotnumber: '728091X'
        }
      ]
    },
    {
      prefix: '0LB',
      milepost: 394.01,
      dotNumber: '728092E',
      division: 'Atlanta',
      subdivision: 'Birmingham Mineral',
      highway: 'CITY',
      street: 'AVE U',
      city: 'BIRMINGHAM',
      state: 'ALABAMA',
      position: 'At Grade',
      status: 'Open',
      trackList: 'SG',
      tracks: [
        {
          track: 'SG',
          dotnumber: '728092E'
        }
      ]
    },
    {
      prefix: '0LB',
      milepost: 394.1,
      dotNumber: '728094T',
      division: 'Atlanta',
      subdivision: 'Birmingham Mineral',
      highway: 'CITY',
      street: 'AVE T',
      city: 'BIRMINGHAM',
      state: 'ALABAMA',
      position: 'At Grade',
      status: 'Open',
      trackList: 'SG',
      tracks: [
        {
          track: 'SG',
          dotnumber: '728094T'
        }
      ]
    },
    {
      prefix: '0LB',
      milepost: 394.17,
      dotNumber: '728095A',
      division: 'Atlanta',
      subdivision: 'Birmingham Mineral',
      highway: 'CITY',
      street: 'AVE G',
      city: 'BIRMINGHAM',
      state: 'ALABAMA',
      position: 'At Grade',
      status: 'Open',
      trackList: 'SG',
      tracks: [
        {
          track: 'SG',
          dotnumber: '728095A'
        }
      ]
    },
    {
      prefix: '0LB',
      milepost: 394.27,
      dotNumber: '728096G',
      division: 'Atlanta',
      subdivision: 'Birmingham Mineral',
      highway: 'CITY',
      street: 'AVE F',
      city: 'BIRMINGHAM',
      state: 'ALABAMA',
      position: 'At Grade',
      status: 'Open',
      trackList: 'SG',
      tracks: [
        {
          track: 'SG',
          dotnumber: '728096G'
        }
      ]
    },
    {
      prefix: '0LK',
      milepost: 425.62,
      dotNumber: '351962G',
      division: 'Atlanta',
      subdivision: 'Birmingham Mineral',
      highway: 'CR 99',
      street: 'MILLDALE CROSSING',
      city: 'ABERNANT',
      state: 'ALABAMA',
      position: 'At Grade',
      status: 'Open',
      trackList: 'SG',
      tracks: [
        {
          track: 'SG',
          dotnumber: '351962G'
        }
      ]
    },
    {
      prefix: '0LK',
      milepost: 428.03,
      dotNumber: '351964V',
      division: 'Atlanta',
      subdivision: 'Birmingham Mineral',
      highway: 'LS',
      street: 'ALABAMA JUNCTION ROAD',
      city: 'ABERNANT',
      state: 'ALABAMA',
      position: 'At Grade',
      status: 'Open',
      trackList: 'SG',
      tracks: [
        {
          track: 'SG',
          dotnumber: '351964V'
        }
      ]
    }
  ];

  constructor(private rs: LocalStorageService) { }

  loadOfflineDataForDevelopers() {
    // Reference Data
    const ref_Data = this.ref_d;
    Object.keys(ref_Data).forEach(key => {
      const refVar = ref_Data[key];
      const ref: ReferenceCode = {
        refType: refVar.refType,
        refObj: refVar.refList
      };
      this.rs.addReference(ref);
    });
    // Dummy submitted reports for sync

    // Add Dummy user
    const userData1 = this.userData1;
    Object.keys(userData1).forEach(key => {
      const userVar = userData1[key];
      const userTableObj: UserTable = {
        racf: userVar.racf,
        userObj: userVar
      };
      this.rs.addUser(userTableObj);
    });

    const syncData = this.syncData;
    Object.keys(syncData).forEach(key => {
      const sVar = syncData[key];
      console.log('SYNC', sVar);
      const syncTableObj: SyncTable = {
        racf: sVar.racf,
        syncObj: sVar
      };
      this.rs.addSyncInfo(syncTableObj);
    });

    const currentTableObj: CurrentUserTable = {
      racf: 'SYSTEM',
      teamName: '6XC5',
      teamReason: '',
      deviceSpecificLastSync: new Date().getTime()
    };
    this.rs.addCurrentUser(currentTableObj);

    //  Project Data
    const resultProjectList = this.rowData;
    Object.keys(resultProjectList).forEach(key => {
      const projectVar = resultProjectList[key];
      const projectTableObj: ProjectTable = {
        project: projectVar.project,
        projectObj: projectVar
      };
      this.rs.addProject(projectTableObj);
    });

    // Job Data
    const resultJobList1 = this.jobData;
    Object.keys(resultJobList1).forEach(key => {
      const jobVar = resultJobList1[key];
      const jobVarTableObj: JobTable = {
        jobId: jobVar.job,
        projectId: jobVar.project,
        jobObj: jobVar
      };
      this.rs.addJob(jobVarTableObj);
      console.log('step 3.1');
    });
    // Reports
    const resultRepList = this._reportData;
    Object.keys(resultRepList).forEach(key => {
      const repVar = resultRepList[key];
      const varTableObj: ReportTable = {
        formId: repVar.formId,
        jobId: repVar.jobId,
        projectId: repVar.projectId,
        reportObj: repVar
      };
      this.rs.addReport(varTableObj);
    });

    // Load edited reports
    const resultEditRepList = this._reportEditData;
    Object.keys(resultEditRepList).forEach(key => {
      const repVar = resultEditRepList[key];
      const varEditTableObj: ReportTable = {
        formId: repVar.formId,
        jobId: repVar.jobId,
        projectId: repVar.projectId,
        reportObj: repVar
      };
      this.rs.addEditedReport(varEditTableObj);
    });

    // load road roadcrossings

    Object.keys(this.roadCrossingList).forEach(key => {
      const repVar = this.roadCrossingList[key];
      const varTableObj: RoadCrossingTable = {
        roadCrossingId: repVar.dotNumber,
        jobId: -1,
        projectId: 361, // repVar.projectId,
        prefix: repVar.prefix,
        milePost: repVar.milepost,
        rxObj: repVar
      };
      this.rs.addRoadCrossing(varTableObj);
    });

    Object.keys(this.roadCrossingList).forEach(key => {
      const repVar = this.roadCrossingList[key];
      const varTableObj: RoadCrossingTable = {
        roadCrossingId: repVar.dotNumber,
        jobId: -1,
        projectId: 570, // repVar.projectId,
        prefix: repVar.prefix,
        milePost: repVar.milepost,
        rxObj: repVar
      };
      this.rs.addRoadCrossing(varTableObj);
    });

    // load road roadcrossings

    Object.keys(this.turnoutList).forEach(key => {
      const repVar = this.turnoutList[key];
      const varTableObj: TurnoutTable = {
        turnoutId: repVar.turnoutId,
        jobId: -1,
        projectId: 361, // repVar.projectId,
        turnoutObj: repVar
      };
      this.rs.addTurnout(varTableObj);
    });
  }

  createUserURL(mac: string, racf: string): string {
    const urlBuilder = new RestURLBuilder();
    urlBuilder.buildRestURL(this.userUrl);
    urlBuilder.setQueryParameter('mac', mac);
    urlBuilder.setQueryParameter('racf', racf);
    return urlBuilder.get();
  }

  createLastSyncURL(mac: string, racf: string, sync: string, team: string): string {
    const urlBuilder = new RestURLBuilder();
    urlBuilder.buildRestURL(this.lastSyncUrl);
    urlBuilder.setQueryParameter('mac', mac);
    urlBuilder.setQueryParameter('racf', racf);
    urlBuilder.setQueryParameter('sync', sync);
    urlBuilder.setQueryParameter('team', team);
    return urlBuilder.get();
  }

  createProjectsURL(mac: string, racf: string, project: string, team: string): string {
    const urlBuilder = new RestURLBuilder();
    urlBuilder.buildRestURL(this.projectsUrl);
    urlBuilder.setQueryParameter('mac', mac);
    urlBuilder.setQueryParameter('racf', racf);
    urlBuilder.setQueryParameter('project', project);
    urlBuilder.setQueryParameter('team', team);
    return urlBuilder.get();
  }

  createJobsURL(mac: string, racf: string, project: string, team: string): string {
    const urlBuilder = new RestURLBuilder();
    urlBuilder.buildRestURL(this.jobsUrl);
    urlBuilder.setQueryParameter('mac', mac);
    urlBuilder.setQueryParameter('racf', racf);
    urlBuilder.setQueryParameter('project', project);
    urlBuilder.setQueryParameter('team', team);
    return urlBuilder.get();
  }

  createReportsUrl(mac: string, racf: string, projectid: string, team: string): string {
    const urlBuilder = new RestURLBuilder();
    urlBuilder.buildRestURL(this.reportsUrl);
    urlBuilder.setQueryParameter('mac', mac);
    urlBuilder.setQueryParameter('racf', racf);
    urlBuilder.setQueryParameter('projectid', projectid);
    urlBuilder.setQueryParameter('team', team);
    return urlBuilder.get();
  }

  createRoadCrossingURL(
    mac: string,
    racf: string,
    project: number,
    team: string,
    prefix: string,
    lowMP: string,
    highMP: string,
    dotNum: string
  ): string {
    const urlBuilder = new RestURLBuilder();
    urlBuilder.buildRestURL(this.roadCrossingUrl);
    urlBuilder.setQueryParameter('mac', mac);
    urlBuilder.setQueryParameter('racf', racf);
    urlBuilder.setQueryParameter('team', team);
    urlBuilder.setQueryParameter('lowMP', lowMP);
    urlBuilder.setQueryParameter('highMP', highMP);
    urlBuilder.setQueryParameter('dotNum', dotNum);
    urlBuilder.setQueryParameter('project', project + '');
    return urlBuilder.get();
  }

  createTurnoutsURL(mac: string, racf: string, project: number, team: string): string {
    const urlBuilder = new RestURLBuilder();
    urlBuilder.buildRestURL(this.turnoutsUrl);
    urlBuilder.setQueryParameter('mac', mac);
    urlBuilder.setQueryParameter('racf', racf);
    urlBuilder.setQueryParameter('team', team);
    urlBuilder.setQueryParameter('project', project + '');
    return urlBuilder.get();
  }

  createEquipmentURL(machine: string, team: string, division: string): string {
    const urlBuilder = new RestURLBuilder();
    urlBuilder.buildRestURL(this.refCodeUrl);
    urlBuilder.setQueryParameter('machine', machine);
    urlBuilder.setQueryParameter('team', team);
    urlBuilder.setQueryParameter('division', division);
    return urlBuilder.get();
  }

  createRefURL(mac: string, racf: string, ref: string): string {
    const urlBuilder = new RestURLBuilder();
    urlBuilder.buildRestURL(this.refCodeUrl);
    urlBuilder.setQueryParameter('mac', mac);
    urlBuilder.setQueryParameter('racf', racf);
    urlBuilder.setQueryParameter('ref', ref);
    return urlBuilder.get();
  }

  createLocationURL(mac: string, racf: string, ref: string): string {
    const urlBuilder = new RestURLBuilder();
    urlBuilder.buildRestURL(this.locCodeUrl);
    urlBuilder.setQueryParameter('mac', mac);
    urlBuilder.setQueryParameter('racf', racf);
    urlBuilder.setQueryParameter('ref', ref);
    return urlBuilder.get();
  }

  createSubmitReportsUrl(mac: string, racf: string): string {
    const urlBuilder = new RestURLBuilder();
    urlBuilder.buildRestURL(this.submitReportUrl);
    urlBuilder.setQueryParameter('mac', mac);
    urlBuilder.setQueryParameter('racf', racf);
    return urlBuilder.get();
  }

  createUpdateReportsUrl(mac: string, racf: string, isTdEditable: string, editreason: string): string {
    const urlBuilder = new RestURLBuilder();
    urlBuilder.buildRestURL(this.updateReportUrl);
    urlBuilder.setQueryParameter('mac', mac);
    urlBuilder.setQueryParameter('racf', racf);
    urlBuilder.setQueryParameter('isTdEditable', isTdEditable);
    urlBuilder.setQueryParameter('editreason', editreason);
    return urlBuilder.get();
  }

  createLogChangeUrl(mac: string, racf: string): string {
    const urlBuilder = new RestURLBuilder();
    urlBuilder.buildRestURL(this.logChangeUrl);
    urlBuilder.setQueryParameter('mac', mac);
    urlBuilder.setQueryParameter('racf', racf);
    return urlBuilder.get();
  }

  createTrackDisturbanceUrl(mac: string, racf: string): string {
    const urlBuilder = new RestURLBuilder();
    urlBuilder.buildRestURL(this.trackDisturbanceUrl);
    urlBuilder.setQueryParameter('mac', mac);
    urlBuilder.setQueryParameter('racf', racf);
    return urlBuilder.get();
  }

  createManualTrackDisturbanceUrl(mac: string, racf: string): string {
    const urlBuilder = new RestURLBuilder();
    urlBuilder.buildRestURL(this.manualTrackDisturbanceUrl);
    urlBuilder.setQueryParameter('mac', mac);
    urlBuilder.setQueryParameter('racf', racf);
    return urlBuilder.get();
  }

  createDeleteSyncedReportUrl(mac: string, racf: string, form_i: string,
    noProductionCode: string, trackType: string, deleteReason: string): string {
    const urlBuilder = new RestURLBuilder();
    urlBuilder.buildRestURL(this.deleteSyncedReportUrl);
    urlBuilder.setQueryParameter('mac', mac);
    urlBuilder.setQueryParameter('racf', racf);
    urlBuilder.setQueryParameter('form_i', form_i);
    urlBuilder.setQueryParameter('noProductionCode', noProductionCode);
    urlBuilder.setQueryParameter('trackType', trackType);
    urlBuilder.setQueryParameter('deleteReason', deleteReason);
    return urlBuilder.get();
  }

  createTDEditableCheckUrl(mac: string, racf: string, form_i: string): string {
    const urlBuilder = new RestURLBuilder();
    urlBuilder.buildRestURL(this.tdEditableCheckUrl);
    urlBuilder.setQueryParameter('mac', mac);
    urlBuilder.setQueryParameter('racf', racf);
    urlBuilder.setQueryParameter('form_i', form_i);
    return urlBuilder.get();
  }

  createSupervisorDataUrl() {
    const urlBuilder = new RestURLBuilder();
    urlBuilder.buildRestURL(this.supervisorRacfUrl);
    return urlBuilder.get();
  }
}
