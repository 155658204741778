import { OperationMode } from '../shared/enums/operation-mode.enum';
import { ReportTable, LocalStorageService } from '../services/local-storage.service';
import { FormBuilder } from '@angular/forms';
import { DataService } from '../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilService } from '../util/util.service';
import { ReportDataService } from '../shared/services/report-data.service';
import { ConfirmationService } from 'primeng/primeng';
import { ReportNavigationService } from '../shared/services/report-navigation.service';

export function BaseReportEditMixin(BaseClass) {
  return class extends BaseClass {
    isTDEditable: boolean;
    constructor(
      public fb: FormBuilder,
      public dataService: DataService,
      public ls: LocalStorageService,
      public router: Router,
      public Activateroute: ActivatedRoute,
      public utilService: UtilService,
      public reportDataServcie: ReportDataService,
      public acknowledgeService: ConfirmationService,
      public reportNavigationService: ReportNavigationService
    ) {
      super(fb, dataService, ls, router, Activateroute, utilService, reportDataServcie, acknowledgeService);
    }

    ngOnInit() {
      this.isTDEditable = this.Activateroute.snapshot.queryParams.isTdEditable === 'true' ? true : false;

      // For first time on the edit screen, set current report from Reports table in index db as default values for edited report
      // If coming back from planned vs actual page, use report from Edited Reports table in index DB
      const formId = Number(this.Activateroute.snapshot.params.form);
      this.dataService.getEditedReportByFormId(formId).then(editedReport => {
        if (!editedReport) {
          this.dataService.getReport(formId).then((report: any) => {
            this.dataService
              .addEditedReport(report)
              .then(() => console.log('Form ID ' + formId + ' added to edited report table'))
              .catch(error => console.log(error));
            super.ngOnInit();
          });
        } else {
          console.log('Form ID ' + formId + ' already exists in edited report table');
          super.ngOnInit();
        }
      });
    }

    get editMode() {
      return this.operationMode === OperationMode.EDIT;
    }

    protected checkReportOperationMode(reportData: any) {
      this.operationMode = OperationMode.EDIT;
    }

    protected reportfill(response: any) {
      super.reportfill(response);
      this.disableReportFields();
    }

    protected getReportMethod() {
      return this.dataService.getEditedReportByFormId(Number(this.formId));
    }

    protected navigateToPlannedActual() {
      this.router.navigate([`/planned_actual/edit/${this.projectId}/${this.jobId}/${this.formId}`], {
        queryParams: {
          isTdEditable: this.isTDEditable
        }
      });
    }

    protected disableReportFields() {}

    protected saveReportToIndexDbMethod(reportObject: any) {
      const report: ReportTable = {
        formId: Number(reportObject.formId),
        jobId: Number(reportObject.jobId),
        projectId: Number(reportObject.projectId),
        reportObj: reportObject
      };
      return this.dataService.addEditedReport(report);
    }

    protected getFormValues() {
      return this.formGroup.getRawValue();
    }

    backClick() {
      this.reportNavigationService.navigateToJobView(this.projectId, this.formId);
    }
  };
}
