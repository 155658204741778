import { Router } from '@angular/router';
import { DataService } from './../../services/data.service';
import { ConfirmationService } from 'primeng/primeng';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { TrackDisturbanceModel } from './models/track-disturbance.model';

@Component({
  selector: 'app-generate-track-disturbance',
  templateUrl: './generate-track-disturbance.component.html',
  styleUrls: ['./generate-track-disturbance.component.scss']
})
export class GenerateTrackDisturbanceComponent implements OnInit {
  tdFormGroup: FormGroup;
  isFormInvalid: Boolean = false;
  isLoading: Boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private dateService: DataService,
    private router: Router
  ) { }

  ngOnInit() {
    this.initializeTDForm();
  }

  initializeTDForm(): any {
    this.tdFormGroup = this.formBuilder.group({
      formId: new FormControl(null, Validators.required),
      jobType: new FormControl(null, Validators.required),
      workDate: new FormControl(null, Validators.required),
      prefix: new FormControl(null, Validators.required),
      beginMP: new FormControl(null, Validators.required),
      endMP: new FormControl(null, Validators.required),
      trackType: new FormControl(null, Validators.required),
      trackName: new FormControl(null),
      createdBy: new FormControl(null, Validators.required),
      teamName: new FormControl(null, Validators.required),
      trackClearedTime: new FormControl(null, Validators.required),
      railSide: new FormControl(null, Validators.required),
      railWeight: new FormControl(null, Validators.required),
      railType: new FormControl(null, Validators.required),
      ballastSufficient: new FormControl(null, Validators.required),
      neaturalTemp: new FormControl(null),
      railTemp: new FormControl(null),
      adjustedNeautralTemp: new FormControl(null),
      openJoints: new FormControl(null),
      totalRailToRemove: new FormControl(null),
      railRemoved: new FormControl(null),
      railLength: new FormControl(null),
      speedRestrictionType: new FormControl(null),
      speedValue: new FormControl(null),
      comment: new FormControl(null, Validators.required)
    });
  }

  transformData(): TrackDisturbanceModel {
    const tdData: TrackDisturbanceModel = new TrackDisturbanceModel();
    tdData.formId = this.tdFormGroup.controls.formId.value;
    tdData.jobType = this.tdFormGroup.controls.jobType.value;
    tdData.createdBy = this.tdFormGroup.controls.createdBy.value ? this.tdFormGroup.controls.createdBy.value.toUpperCase() : null;
    tdData.railWeight = this.tdFormGroup.controls.railWeight.value;
    tdData.railType = this.tdFormGroup.controls.railType.value;
    tdData.ballastSufficient = this.tdFormGroup.controls.ballastSufficient.value;
    tdData.teamName = this.tdFormGroup.controls.teamName.value ? this.tdFormGroup.controls.teamName.value.toUpperCase() : null;
    tdData.comment = this.tdFormGroup.controls.comment.value;
    tdData.speedRestrictionType = this.tdFormGroup.controls.speedRestrictionType.value;
    tdData.speedValue = this.tdFormGroup.controls.speedValue.value;
    tdData.neaturalTemp = this.tdFormGroup.controls.neaturalTemp.value;
    tdData.railTemp = this.tdFormGroup.controls.railTemp.value;
    tdData.adjustedNeautralTemp = this.tdFormGroup.controls.adjustedNeautralTemp.value;
    tdData.openJoints = this.tdFormGroup.controls.openJoints.value;
    tdData.totalRailToRemove = this.tdFormGroup.controls.totalRailToRemove.value;
    tdData.railRemoved = this.tdFormGroup.controls.railRemoved.value;
    tdData.railLength = this.tdFormGroup.controls.railLength.value;
    tdData.railSide = this.tdFormGroup.controls.railSide.value;
    tdData.trackType = this.tdFormGroup.controls.trackType.value;
    tdData.trackName = this.tdFormGroup.controls.trackName.value;
    tdData.prefix = this.tdFormGroup.controls.prefix.value;
    tdData.beginMP = this.tdFormGroup.controls.beginMP.value;
    tdData.endMP = this.tdFormGroup.controls.endMP.value;
    tdData.workDate = this.formatDate(this.tdFormGroup.controls.workDate.value);
    tdData.trackClearedTime = this.formatDate(this.tdFormGroup.controls.trackClearedTime.value);
    return tdData;
  }

  createTD() {
    console.log(this.transformData());
    if (this.tdFormGroup.invalid) {
      this.isFormInvalid = true;
      this.isLoading = false;
    } else {
      this.isFormInvalid = false;
      this.confirmationService.confirm({
        header: 'TD Generation Confirmation',
        message: 'Are you sure you want to create this Track Disturbance?',
        accept: () => {
          this.isLoading = true;
          this.dateService.createManualTrackDisturbance(this.transformData()).then((response) => {
            this.isLoading = false;
            this.tdFormGroup.reset();
            if (response === 200) {
              alert('Track Disturbance generated successfully.');
            } else {
              alert('Track Disturbance generation failed.');
            }
          });
        }
      });
    }
  }

  onJobTypeChange() {
    const jobType = this.tdFormGroup.controls.jobType.value;
    this.tdFormGroup.reset();
    this.tdFormGroup.enable();
    this.tdFormGroup.controls.jobType.setValue(jobType);

    if (jobType === 'RA') {
      this.tdFormGroup.controls.speedRestrictionType.disable();
      this.tdFormGroup.controls.speedValue.disable();

      this.tdFormGroup.controls.neaturalTemp.setValidators(Validators.required);
      this.tdFormGroup.controls.railTemp.setValidators(Validators.required);
      this.tdFormGroup.controls.adjustedNeautralTemp.setValidators(Validators.required);
      this.tdFormGroup.controls.openJoints.setValidators(Validators.required);
      this.tdFormGroup.controls.totalRailToRemove.setValidators(Validators.required);
      this.tdFormGroup.controls.railRemoved.setValidators(Validators.required);
      this.tdFormGroup.controls.railLength.setValidators(Validators.required);
    } else if (jobType === 'SU' || jobType === 'TI') {
      this.tdFormGroup.controls.neaturalTemp.disable();
      this.tdFormGroup.controls.railTemp.disable();
      this.tdFormGroup.controls.adjustedNeautralTemp.disable();
      this.tdFormGroup.controls.openJoints.disable();
      this.tdFormGroup.controls.totalRailToRemove.disable();
      this.tdFormGroup.controls.railRemoved.disable();
      this.tdFormGroup.controls.railLength.disable();

      this.tdFormGroup.controls.speedRestrictionType.setValidators(Validators.required);
      this.tdFormGroup.controls.speedValue.setValidators(Validators.required);
    }
    Object.keys(this.tdFormGroup.controls).forEach((key: string) => {
      this.tdFormGroup.controls[key].updateValueAndValidity();
    });
  }

  navigateToHome() {
    this.router.navigate(['project_view']);
  }

  private formatDate(reportDate) {
    if (!reportDate) {
      return null;
    }
    const date = new Date(reportDate);
    return (
      date.getFullYear() +
      '-' +
      this.pad(date.getMonth() + 1) +
      '-' +
      this.pad(date.getDate()) +
      'T' +
      this.pad(date.getHours()) +
      ':' +
      this.pad(date.getMinutes()) +
      ':' +
      this.pad(date.getSeconds())
    );
  }

  private pad(number) {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  }

}
