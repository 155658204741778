export const AppConstant = {
  REPORT: {
    UNSYNCED: 'unsynced',
    SYNCED: 'synced'
  },
  DELETE_KEY: 'deleteConfirmation',
  EDIT_KEY: 'editConfirmation',
  UNSYNCED_REPORT_DELETE_MSG: 'Are you sure want to Delete the report?',
  SYNCED_REPORT_DELETE_MSG: 'Are you sure want to Delete the synced report?',
  SYNCED_REPORT_DRAFT_DELETE_MSG:
    'Deleting synced report will trigger sync which will delete the unsubmitted-draft report(s). ' +
    'Are you sure you want to Delete the Synced Report?',
  REPORT_UPDATE_DRAFT_DELETE_MSG:
    'Editing report will delete the unsubmitted-draft report(s). ' +
    'Are you sure you want to continue to edit?',
  DUPLICATE_REPORT_TITLE: 'Duplicate Report',
  DUPLICATE_REPORT_MSG: 'Production Report has already been submitted for this location. Please update the location.',
  // tslint:disable-next-line:max-line-length
  MILEPOST_WARNING_MSG: 'Are you sure this is the begin milepost you want to enter? You may be going in wrong direction.Please verify it.',
  ERROR_HEADING: 'Error',
  CROSSING_SURFACE_YES_ERROR_MSG_TS:
    'Crossings Surfaced has been selected as Yes on T&S info screen and Surfaced? has been selected as No. Please resolve errors',
  CROSSING_SURFACE_YES_ERROR_MSG_ST:
    'Crossings Surfaced has been selected as Yes on Switch Tie & surfaing info screen and Surfaced? has been selected as No.' +
    ' Please resolve errors',
  CROSSING_SELECTION_ERROR_MSG: 'At least one crossing should be selected',
  CROSSING_INSTALLED_TIES_OR_SURFACING_ERROR_MSG: 'No.Ties Installed should be > 0 or Surfaced? should be selected as Yes',
  WORKTYPECODE: {
    RAIL: 'RA',
    SURFACING: 'SU',
    TIE_SURFACING: 'TS',
    SWITCHTIES: 'XX'
  },
  PROJECT_TYPE_CODE: {
    RAIL: 'RA',
    SURFACING: 'SU',
    TIE_SURFACING: 'TS',
    SWITCHTIES: 'XX'
  },
  JOB_TYPE: {
    GAUGING: 'GA',
    PATCH_RAIL: 'PR',
    OUT_OF_FACE_RAIL: 'OF',
    CONCRETE_PAD: 'CP',
    SURFACING: 'SO',
    TIE_SURFACING: 'TS',
    SWITCH_TIE_YARDS: 'SY',
    SWITCH_TIE_INTERLOCKING: 'SI'
  },
  SURFACE_TYPE: {
    TIMBER: 'Timber',
    RUBBER: 'Rubber'
  },
  RAIL_SIDE_LABEL: {
    INCREASING: '( Inc MP )',
    DECREASING: '( Dec MP )'
  },
  DIRECTION_CHANGE_CONFIRM_MSG: 'Are you sure you want to change the Direction of your work?',
  SIDE_CHANGE_CONFIRM_MSG: 'Are you sure you want to change the Rail Side?',
  TIE_PLATE_TYPE_VALUE: {
    COMPOSITE: 'P',
    BRANCHLINE_WOOD: 'B',
    CONCRETE: 'C',
    WOOD: 'W',
    MAINLINE_WOOD: 'M'
  },
  NEW_RELAY: {
    NEW: 'N',
    RELAY: 'R'
  },
  BACK_BUTTON_CONFIRMATION_MESSAGE: 'Unsaved Edits will be deleted. Do you want to continue?',
  YES: 'Y',
  NO: 'N'
};
