import {Component, Input, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {JobTable, LocalStorageService, RoadCrossingTable} from '../../services/local-storage.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-road-crossing-list',
    templateUrl: './road-crossing-list.component.html',
    styleUrls: ['./road-crossing-list.component.scss']
})
export class RoadCrossingListComponent implements OnInit {
    @Input() selected: any[] = [];
    serviceData: any[] = [];
    crossingData: any[] = [];
    prefixes: any[] = [];
    jobRowData: any;
    projectRowData: any;
    prefixError: string;
    startErrMsg: string;
    endErrMsg: string;
    searchPrefix: string;
    searchStartMP: any;
    searchEndMP: any;
    projectStartMP: number;
    projectEndMP: number;
    projectId: any;
    jobId: any;
    formId: any;
    roadcrossingSelected = false;
    searchMilePost = [];


    constructor(public router: Router, public activeRoute: ActivatedRoute,
                public dataService: DataService, private ls: LocalStorageService) {
    }

    ngOnInit() {
        this.activeRoute.params.subscribe((params: any) => {
            this.projectId = Number(params.project);
            this.jobId = Number(params.job);
            this.formId = Number(params.form);
        });
        this.ls.getProjectJobs(this.projectId).then((jobList: Array<JobTable>) => {
            this.jobRowData = jobList;
            jobList.map((obj, i) => {
                if (jobList.indexOf(obj) === i) {
                    let pre = {'label': obj.jobObj['prefix'], 'value': obj.jobObj['prefix']};
                    if (!this.checkPrefixDups(pre)) {
                        this.prefixes.push(pre);
                        console.log('orerfdsf', this.prefixes);
                        this.prefixes.sort((a, b) => a.value < b.value ? -1 : 1);
                    }
                }
                let groups = {};
                for (let p in this.jobRowData) {
                    if (this.jobRowData) {
                        let prefix = this.jobRowData[p].jobObj['prefix'];
                        if (!groups[prefix]) {
                            groups[prefix] = [];
                        }
                        groups[prefix].push(this.jobRowData[p].jobObj['startMP'], this.jobRowData[p].jobObj['endMP']);
                    }
                }
                this.searchMilePost = [];
                for (let prefix in groups) {
                    if (groups) {
                        this.searchMilePost.push({prefix: prefix, milepost: groups[prefix]});
                    }
                }
                if (obj.jobId === this.jobId) {
                    this.searchPrefix = obj.jobObj['prefix'];
                    this.searchStartMP = obj.jobObj['startMP'];
                    this.searchEndMP = obj.jobObj['endMP'];
                }
            });
        }).then(() => {
            this.ls.getJobRoadCrossings(this.projectId).then((cList: Array<RoadCrossingTable>) => {
                this.serviceData = this.crossingData = cList;
                this.crossingData.sort((x, y) => x.rxObj['prefix'] < y.rxObj['prefix'] ? -1 :
                    x.rxObj['prefix'] > y.rxObj['prefix'] ? 1 : x.rxObj['milepost'] < y.rxObj['milepost'] ? -1 : 1);
                this.selFilter();
                this.filterData();
            });
        });
    }

    validatePrefix(prefix) {
        if (!prefix) {
            this.prefixError = 'Please Enter Prefix';
        } else {
            this.prefixError = '';
        }
    }

    applyRoadCrossing() {
        this.roadcrossingSelected = false;
        this.crossingData.map(item => {
            if (item.selected) {
                this.roadcrossingSelected = true;
            }
        });

    }

    selFilter() {
        this.dataService.getReport(this.formId).then((res: any) => {
            if (res.reportObj && res.reportObj.reportRoadCrossingList !== null && res.reportObj.reportRoadCrossingList !== undefined &&
                res.reportObj.reportRoadCrossingList.length !== null) {
                let selectedList = res.reportObj.reportRoadCrossingList;
                this.crossingData = this.crossingData.filter(obj => {
                    return !selectedList.find(sel => {
                        if (sel) {
                            return obj['roadCrossingId'] === sel['roadCrossingId'];
                        }
                    });
                });
            }
        }, (error) => {
            console.log('Error while getting report new', error);
        });
    }

    selectedFilter() {
        this.selFilter();
        this.crossingData = this.crossingData.filter(obj1 => {
            return !this.selected.find(select => {
                return obj1.roadCrossingId === select.roadCrossingId && obj1.milePost === select.milePost
                    && obj1.milePost <= this.searchEndMP !== select.milePost;
            });
        });
    }

    checkPrefixDups(obj) {
        let res = false;
        this.prefixes.forEach(item => {
            if (obj.label === item.label) {
                res = true;
            }
        });
        return res;
    }

    clearFilter() {
        this.searchStartMP = null;
        this.searchEndMP = null;
        this.startErrMsg = '';
        this.endErrMsg = '';
        this.crossingData = [];
    }

    validateSearch(startVal: any, endVal: any) {
        this.searchMilePost.forEach(item => {
            if (item['prefix'] === this.searchPrefix) {
                this.projectStartMP = Math.min(...item['milepost']) - 5;
                this.projectEndMP = Math.round((Math.max(...item['milepost']) + 5) * 100) / 100;
            }
        });
        if ((startVal < this.projectStartMP || startVal > this.projectEndMP) && startVal !== null) {
            this.startErrMsg = 'Outside of Job Limits';
        } else if (startVal === null) {
            this.startErrMsg = 'Please Enter Low MP';
        } else {
            this.startErrMsg = '';
        }
        if ((endVal > this.projectEndMP || endVal < this.projectStartMP) && endVal !== null) {
            this.endErrMsg = 'Outside of Job Limits';
        } else if (endVal <= startVal && startVal !== null && startVal !== undefined && endVal !== null) {
            this.endErrMsg = 'Should be Greater than Low MP';
        } else if (endVal === null) {
            this.endErrMsg = 'Please Enter High MP';
        } else {
            this.endErrMsg = '';
        }
    }

    filterData() {
        this.crossingData = this.serviceData;
        this.selectedFilter();
        this.crossingData = this.crossingData.filter(obj => {
            return obj.prefix === this.searchPrefix && obj.milePost >= this.searchStartMP && obj.milePost <= this.searchEndMP;
        });
    }
}
