import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '../../../../node_modules/@angular/forms';
import { DataService } from '../../services/data.service';
import { JobTable, LocalStorageService } from '../../services/local-storage.service';
import { ReportDataService } from '../../shared/services/report-data.service';
import { UtilService } from '../../util/util.service';
import { NotesModel } from '../../model/notes.model';
import { PlannedActual2Component } from '../../planned-actual2/planned-actual2.component';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { ReferencePipe } from '../../pipes/reference.pipe';
import * as moment from 'moment';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent extends PlannedActual2Component implements OnInit, OnDestroy {
  @Input() jobId: any;
  @Input() onDutyDate: any;
  @Output()
  syncCompleted: EventEmitter<boolean> = new EventEmitter();
  @Output()
  displayChange = new EventEmitter();
  showConfirmation: boolean;
  todayDate: any;
  firstDay: any;
  maxdate = new Date();
  dataModel = new NotesModel();
  jobDetails: String;
  teamName: string;
  formGroup: FormGroup;
  hideFields: boolean;
  clearValidation: boolean;

  constructor(
    protected fb: FormBuilder,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected dataService: DataService,
    protected localStorage: LocalStorageService,
    public utilService: UtilService,
    protected reportDataService: ReportDataService,
    private reference: ReferencePipe
    ) {
    super(fb, router, activeRoute, dataService, localStorage, utilService, reportDataService);
  }

  ngOnInit() {
    this.clearValidation = false;
    this.localStorage.getCurrentUser('SYSTEM').then(data => {
      this.teamName = data ? data.teamName : null;
      this.localStorage.getNotes(this.teamName).then(notesData => {
        this.dataModel = notesData && notesData.notesObj ?
          JSON.parse(JSON.stringify(notesData ? notesData.notesObj : null)) : new NotesModel();
        this.formData(this.dataModel);
      });
    });
    console.log('jobobjectId', this.jobId);
    setTimeout(() => {
      this.dataService.getJobInfo(this.jobId).then((jobId1: JobTable) => {
        const job = JSON.parse(JSON.stringify(jobId1.jobObj));
        if (job.jobTypeCode === 'TS' || job.jobTypeCode === 'SU' || job.jobTypeCode === 'SO' ||
          job.jobTypeCode === 'SY') {
          this.hideFields = true;
        }
        console.log('jobobject', job);
        const jobType = this.reference.transform(job.jobTypeCode, 'REQ');
        const railSide = job.railSide !== null ? this.reference.transform(job.railSide, 'RSE') : '';
        this.jobDetails = job.job + ' - ' + jobType + '  ' + job.prefix + ' ' + job.startMP + '-' +
          job.endMP + ' ' + job.trackType + '  ' + railSide;
      });
    }, 10);

    this.showConfirmation = true;
    this.todayDate =
      this.maxdate.getFullYear() +
      '-' +
      ('0' + (this.maxdate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + this.maxdate.getDate()).slice(-2) +
      'T' +
      ('0' + this.maxdate.getHours()).slice(-2) +
      ':' +
      ('0' + this.maxdate.getMinutes()).slice(-2);
    this.firstDay = this.utilService.convertTimeStampTOdate(new Date().setDate(new Date().getDate() - 1));
  }

  formData(dataModel?: NotesModel) {
    this.formGroup = this.fb.group({
      onDutyTime: [dataModel.onDutyTime],
      sawCutsTime: [dataModel.sawCutsTime],
      firstWeldTime: [dataModel.firstWeldTime],
      firstSpikedPulled: [dataModel.firstSpikedPulled],
      firstSpikeDrivenTime: [dataModel.firstSpikeDrivenTime],
      lastSpikeDriven: [dataModel.lastSpikeDriven],
      plannedStart: [dataModel.plannedStart],
      plannedEnd: [dataModel.plannedEnd],
      greenSignalTime: [dataModel.greenSignalTime],
      workday: [dataModel.onDutyTime]
    });
      this.formGroup.valueChanges.subscribe((value: any) => {
        this.clearValidation = false;
      });
    this.formGroup.updateValueAndValidity();
}

updateValidationOnDutyTimeChange() {
  this.validateTimeFileds(null, this.formGroup && this.formGroup.value.onDutyTime);
  this.formGroup.updateValueAndValidity();
}


onCnfmDialogClose(event) {
  this.showConfirmation = event;
  this.displayChange.emit(false);
}

onClose(event) {
  this.showConfirmation = event;
  this.displayChange.emit(false);
}

resetNotes() {
  this.clearValidation = true;
  this.formGroup.clearValidators();
  this.formGroup.reset();
}

saveNotes() {
  if (!this.clearValidation) {
    if (this.formGroup.get('onDutyTime').value && (this.showInvalidfirstSpikedPulledError ||
      this.showInvalidfirstWeldTimeError || this.showInvalidSawCutsTimeError ||
      this.showInvalidLastSpikeTimeError || this.showInvalidSpikeDrivenTimeError)) {
      return;
    }
    this.saveAndSubmit();
  } else if (this.clearValidation) {
    this.dataService.deleteAllNotes();
    this.showConfirmation = false;
    this.displayChange.emit(false);
  }
}

saveAndSubmit() {
  this.formGroup.controls['workday'].setValue(moment(this.formGroup.controls['onDutyTime'].value).valueOf());
  this.setWindowTime();
  const data = {...this.formGroup.value, teamName: this.teamName};
  this.dataService.submitNotes(JSON.stringify(data));
  this.showConfirmation = false;
  this.displayChange.emit(false);
}

setWindowTime() {
  const reportwindow = this.formGroup.controls['reportWindows'] as FormArray;
  reportwindow.controls.forEach((formGroup: FormArray, i: any) => {
    formGroup.controls['windowStart'].patchValue(Date.parse(formGroup.controls['windowStarts'].value));
    formGroup.controls['windowEnd'].patchValue(Date.parse(formGroup.controls['windowEnds'].value));
  });
}
ngOnDestroy() {
  if (this.displayChange) {
    this.displayChange.unsubscribe();
  }
}


}
