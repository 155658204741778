import { KeyValue } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Turnout } from '../../model/turnout.model';
import { EventBusService, EventType, STWEventData } from '../../services/eventbus.service';
import { SwitchTieDetailsModel } from '../models/switch-tie-report-details.model';
import { OperationMode } from '../../shared/enums/operation-mode.enum';

@Component({
  selector: 'app-switch-tie',
  templateUrl: './switch-tie.component.html',
  styleUrls: ['./switch-tie.component.scss']
})
export class SwitchTieComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup;
  @Input() isFormSubmitted: boolean;
  @Input() newRelayOptions: Array<KeyValue<string, string>>;
  @Input() tieTypeOptions: Array<KeyValue<string, string>>;
  @Input() switchTieDetailsList: Array<SwitchTieDetailsModel>;
  @Input() operationMode: OperationMode = OperationMode.CREATE;
  subscriptions: Array<Subscription> = [];
  turnoutsList: Turnout[] = [];
  selectedTurnoutIndex: Number;

  constructor(private eventBus: EventBusService) { }

  ngOnInit() {
    if (this.switchTieDetailsList) {
      this.turnoutsList = this.switchTieDetailsList.map(switchTie => switchTie.turnout);
      for (const switchTieDetail of this.switchTieDetailsList) {
        (<FormArray>this.formGroup.controls.switchTies).push(new FormGroup({ turnout: new FormControl(switchTieDetail.turnout), workProgressId: new FormControl(switchTieDetail.workProgressId) }));
      }
    }
    const eventSubscription = this.eventBus.getEventBus(EventType.TURNOUT_DIALOG_APPLY_EVENT).subscribe((event: STWEventData) => {
      if (event.data && event.data.length > 0) {
        event.data.forEach((turnout: Turnout) => {
          if (this.turnoutsList.findIndex((selectedTurnout: Turnout) => selectedTurnout.turnoutId === turnout.turnoutId) === -1) {
            (<FormArray>this.formGroup.controls.switchTies).push(new FormGroup({ turnout: new FormControl(turnout) }));
            this.turnoutsList.push(turnout);
          }
        });
      }
    });
    this.subscriptions.push(eventSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  openDialog() {
    this.eventBus.fireEvent(EventType.TURNOUT_DIALOG_OPEN_EVENT, new STWEventData(JSON.parse(JSON.stringify(this.turnoutsList))));
  }

  removeTurnout(index: number) {
    this.turnoutsList.splice(index, 1);
    (<FormArray>this.formGroup.controls.switchTies).removeAt(index);
    this.formGroup.controls.switchTies.updateValueAndValidity();
  }

  toggleTurnout(index: number) {
    if (this.selectedTurnoutIndex === index) {
      this.selectedTurnoutIndex = null;
    } else {
      this.selectedTurnoutIndex = index;
    }
  }

  get viewMode() {
    return this.operationMode === OperationMode.VIEW;
  }

  get editMode() {
    return this.operationMode === OperationMode.EDIT;
  }
}
