import { KeyValue } from '../../../../node_modules/@angular/common';
import { TDWorkType } from '../enums/td-work-type.enum';

export class TrackDisturbanceConstants {

  public static readonly WORK_TYPES: Array<KeyValue<string, string>> = [
    { key: TDWorkType.SURFACING, value: 'Surfacing' },
    { key: TDWorkType.TIES_INSTALLED, value: 'Installed Ties' }
  ];

  public static readonly RAIL_TYPES: Array<KeyValue<string, string>> = [
    { key: 'C', value: 'CWR' },
    { key: 'J', value: 'Jointed Rail' }
  ];

  public static readonly BALLAST_SUFFICIENT_OPTIONS: Array<KeyValue<boolean, string>> = [
    { key: true, value: 'Yes' },
    { key: false, value: 'No' }
  ];
}
