import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalToFraction'
})
export class DecimalToFractionPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return this.formattedFraction(value.toString());
  }

  private formattedFraction(expansionDecimalValue: string): string {
    if (expansionDecimalValue.indexOf('.') !== -1) {
      const expansionDecimalValueSplit = expansionDecimalValue.split('.');
      // Check if we have a decimal number as 3.0 or 3.00 or 3.000 etc.
      if (Number('0.' + expansionDecimalValueSplit[1]) === 0) {
        return expansionDecimalValueSplit[0] + '-0/0';
      }
      let denominator = Math.pow(10, expansionDecimalValueSplit[1].length);
      let numerator = Number(expansionDecimalValueSplit[1]);
      let divisor = this.greatestCommonDivisor(numerator, denominator);
      numerator /= divisor;
      denominator /= divisor;

      return expansionDecimalValueSplit[0] + '-' + numerator.toFixed() + '/' + denominator.toFixed();
    } else {
      return expansionDecimalValue + '-0/0';
    }
  }

  private greatestCommonDivisor(numerator: number, denominator: number) {
    if (!denominator) {
      return numerator;
    }
    numerator = parseInt(numerator.toString());
    denominator = parseInt(denominator.toString());
    return this.greatestCommonDivisor(denominator, numerator % denominator);
  }
}
