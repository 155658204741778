import { Directive, EventEmitter, HostListener, Input, Output, ElementRef } from '@angular/core';




@Directive({
  selector: '[stw-max-length]'
})

export class StwMaxLengthDirective {
  @Input('stw-max-length') cMaxLength: number;

  constructor() {
  }

  @HostListener('keyup', ['$event']) onKeyup(event: KeyboardEvent, milePost: any) {
    const element = event.target as HTMLInputElement;
    const value = element.value.substr(0, this.cMaxLength);
    if (element.validationMessage !== 'Please enter a number.') {
      if (value.length <= this.cMaxLength && element.value === value) {
        element.value = value;
      } else {
        element.value = value;
        //   element.value = value.substr(0, this.cMaxLength - 1);
      }
    }
  }

}