import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { DataService } from './../../services/data.service';
import { FormGroup } from '@angular/forms';
import { UtilService } from './../../util/util.service';
import { SelectItem } from 'primeng/primeng';
import { MasterTrack } from '../../model/track.model';
import { OperationMode } from '../../shared/enums/operation-mode.enum';

@Component({
  selector: 'app-surface-progress',
  templateUrl: './surface-progress.component.html',
  styleUrls: ['./surface-progress.component.scss']
})
export class SurfaceProgressComponent implements OnInit {
  sidingTrackNameFieldError: boolean[] = [];
  sidingTrackNameError: string;
  isSiding: boolean;
  @Input()
  jobBeginMp: number;
  @Input()
  jobEndMp: number;
  @Input()
  trackType: string;
  @Input()
  direction: string;
  @Input()
  sidingObject: Siding[];
  @Input()
  trackName: string;
  @Input()
  workedOn: string;
  @Input()
  prepopulateSurface: object[];
  @Output()
  surfaceData: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  reportLoad: boolean;
  @Input()
  subSequentRepor: boolean;
  @Input() operationMode: OperationMode;
  crossingSurfaced: boolean;
  details: SurfacingDetail[] = [];
  crossingSurfacedOptions: CrossingSurfaced[];
  trackNameOptions: TrackName[] = [];
  projectId: Number;
  jobId: Number;
  formId: Number;
  addString: string;
  isYard: boolean;
  formGroup: FormGroup;
  crossingSurfacedError: string;
  trackNameError: string[] = [];
  beginMPError: string[] = [];
  endMPError: string[] = [];
  detailsInvalid: boolean;
  crossingSurfacedInvalid: boolean;
  trackNameInvalid: boolean[] = [];
  validationErrors: boolean;
  beginMPOutOfLimitError: boolean[] = [];
  EndMPOutOfLimitError: boolean[] = [];
  endMPNextWholeMpError: boolean[] = [];
  rowExists: boolean;
  railTypeOptions: SelectItem[];
  railTypeFieldError: boolean[] = [];
  railTypeError: string[] = [];
  masterTrackList: MasterTrack[] = [];
  twoDecimalPattern = new RegExp('(^(0{0,1}|([1-9][0-9]*))(.[0-9]{1,2})?$)');

  constructor(
    public utilService: UtilService,
    public Activateroute: ActivatedRoute,
    public ds: DataService
  ) {
    this.Activateroute.params.subscribe(params => {
      this.projectId = Number(params.project);
      this.jobId = Number(params.job);
      this.formId = Number(params.form);
    });
    this.utilService
      .getMasterTrack(this.jobId)
      .then((masterTrackList: MasterTrack[]) => {
        this.masterTrackList = masterTrackList;
        console.log('master track list', masterTrackList);
      });
    this.crossingSurfacedOptions = [
      { label: 'Yes', code: true },
      { label: 'No', code: false }
    ];
    this.railTypeOptions = [];
    this.railTypeOptions.push({ label: 'CWR', value: 'C' });
    this.railTypeOptions.push({ label: '	Jointed Rail', value: 'J' });
    this.crossingSurfaced = null;

    setTimeout(() => {
      this.prepopulateSurfaceData();
      this.getTrack();
    }, 500);

    this.loadReport();
  }

  ngOnInit() {
    // setTimeout(() => {
    //
    // }, 1500);
  }

  get viewMode() {
    return this.operationMode === OperationMode.VIEW;
  }

  get editMode() {
    return this.operationMode === OperationMode.EDIT;
  }

  crossingSurface() {
    if (this.crossingSurfaced !== null) {
      this.crossingSurfaced = JSON.parse(this.crossingSurfaced.toString());
      if (this.details) {
        for (let i = 0; i < this.details.length; i++) {
          this.details[i]['crossingSurfaced'] = this.crossingSurfaced;
        }
        // this.passData();
      }
    }
    this.passData();
    this.validateCrossingSurfaced(this.crossingSurfaced);
  }

  async prepopulateSurfaceData() {
    this.rowExists = false;
    if (this.prepopulateSurface[0] !== undefined) {
      for (let i = 0; this.prepopulateSurface[i] !== undefined; i++) {
        if (this.trackType === 'YD') {
          const prepopulateTrackName =
            this.prepopulateSurface[i]['trackName'] + ',' + this.trackType;
          this.crossingSurfaced = this.prepopulateSurface[i][
            'crossingSurfaced'
          ];
          this.rowExists = true;
          this.details.push(
            new SurfacingDetail(
              this.trackType,
              prepopulateTrackName,
              this.prepopulateSurface[i]['beginMP'],
              this.prepopulateSurface[i]['endMP'],
              this.prepopulateSurface[i]['surfaced'],
              this.prepopulateSurface[i]['crossingSurfaced'],
              this.prepopulateSurface[i]['direction'],
              this.prepopulateSurface[i]['workedOn'],
              this.prepopulateSurface[i]['railType']
            )
          );
        } else {
          if (
            this.trackName === this.prepopulateSurface[i]['trackName'] ||
            (this.trackType === 'SD' && !this.sidingObject)
          ) {
            this.crossingSurfaced = this.prepopulateSurface[i][
              'crossingSurfaced'
            ];
            this.rowExists = true;
            await this.details.push(
              new SurfacingDetail(
                this.prepopulateSurface[i]['trackType'],
                this.prepopulateSurface[i]['trackName'],
                this.prepopulateSurface[i]['beginMP'],
                this.prepopulateSurface[i]['endMP'],
                this.prepopulateSurface[i]['surfaced'],
                this.prepopulateSurface[i]['crossingSurfaced'],
                this.prepopulateSurface[i]['direction'],
                this.prepopulateSurface[i]['workedOn'],
                this.prepopulateSurface[i]['railType']
              )
            );
          }
        }
      }
      this.passData();
      if (!this.rowExists) {
        this.prepopulateData();
      }
    } else if (this.reportLoad) {
      // setTimeout(() => {
      this.prepopulateData();
      // }, 200);
    }
  }

  prepopulateData() {
    if (this.trackType === 'YD') {
      this.details.push(
        new SurfacingDetail(
          this.trackType,
          null,
          null,
          null,
          null,
          this.crossingSurfaced,
          this.direction,
          this.workedOn,
          null
        )
      );
    } else {
      this.details.push(
        new SurfacingDetail(
          this.trackType,
          this.trackName,
          null,
          null,
          null,
          this.crossingSurfaced,
          this.direction,
          this.workedOn,
          null
        )
      );
    }
  }

  addSurfacingDetail(index: number) {
    this.validateAll();
    if (
      this.details[index].beginMP !== null &&
      this.details[index].endMP !== null &&
      this.details[index].surfaced !== null
    ) {
      this.detailsInvalid = false;
      setTimeout(() => {
        if (this.trackType === 'YD') {
          if (this.trackNameInvalid[index] === false) {
            this.details.push(
              new SurfacingDetail(
                this.trackType,
                null,
                null,
                null,
                null,
                this.crossingSurfaced,
                this.direction,
                this.workedOn,
                null
              )
            );
          }
        } else if (this.details[index].railType !== null) {
          this.details.push(
            new SurfacingDetail(
              this.trackType,
              this.trackName,
              null,
              null,
              null,
              this.crossingSurfaced,
              this.direction,
              this.workedOn,
              null
            )
          );
        } else {
          this.detailsInvalid = true;
        }
      }, 200);
    } else {
      this.detailsInvalid = true;
    }
    // this.validateAll();
  }

  railType(index) {
    this.validateRailType(index);
    this.passData();
  }

  validateRailType(index) {
    if (
      this.details[index].railType === undefined ||
      this.details[index].railType === null
    ) {
      this.railTypeFieldError[index] = true;
      this.railTypeError[index] = 'Make a selection';
    } else {
      this.railTypeFieldError[index] = false;
      this.railTypeError[index] = '';
    }
  }

  removeSurfacingDetail(index: number) {
    if (this.details.length > 1) {
      if (index > -1) {
        this.details.splice(index, 1);
        this.beginMPOutOfLimitError.splice(index, 1);
        this.EndMPOutOfLimitError.splice(index, 1);
        this.endMPNextWholeMpError.splice(index, 1);
        this.removeValidationAtindex(index);
      }
    }
    this.passData();
  }

  removeValidation() {
    for (let i = 0; i < this.details.length; i++) {
      this.removeValidationAtindex(i);
    }
  }

  removeValidationAtindex(i) {
    this.crossingSurfacedInvalid = false;
    this.crossingSurfacedError = '';
    this.trackNameInvalid[i] = false;
    this.sidingTrackNameError = '';
    this.sidingTrackNameFieldError[i] = false;
    this.beginMPOutOfLimitError[i] = false;
    this.beginMPError[i] = '';
    this.EndMPOutOfLimitError[i] = false;
    this.sidingTrackNameFieldError[i] = false;
    this.railTypeFieldError[i] = false;
    this.railTypeError[i] = '';
  }

  async caculateSurfacedFeet(beginMP: number, endMP: number, index: number) {
    if (
      beginMP !== null &&
      endMP !== null &&
      !this.beginMPOutOfLimitError[index] &&
      !this.EndMPOutOfLimitError[index] &&
      !this.endMPNextWholeMpError[index] &&
      this.details[index]
    ) {
      const surfaced = await this.utilService.getSurfacedFeet(
        beginMP,
        endMP,
        this.jobId
      );
      if (surfaced[1]) {
        this.beginMPOutOfLimitError[index] = true;
        this.beginMPError[index] = 'Not a valid milepost.';
      }
      if (surfaced[2]) {
        this.EndMPOutOfLimitError[index] = true;
        this.endMPError[index] = 'Not a valid milepost.';
      }
      if (surfaced[0].toString() !== 'NaN') {
        this.beginMPOutOfLimitError[index] = false;
        this.details[index].surfaced = Number(surfaced[0]);
        this.passData();
      }
    } else if (beginMP === -1 && endMP === -1 && index === -1) {
      const temp = await this.utilService.getSurfacedFeet(
        beginMP,
        endMP,
        this.jobId
      );
    }
  }

  validateSidingTrackName(trackName: string, index: number) {
    if (trackName) {
      this.sidingTrackNameError = '';
      this.sidingTrackNameFieldError[index] = false;
    } else {
      this.sidingTrackNameError = 'Enter Track Name';
      this.sidingTrackNameFieldError[index] = true;
    }
  }

  sidingTrackName(trackName: string, index: number) {
    this.validateSidingTrackName(trackName, index);
    this.passData();
  }

  passData() {
    if (this.trackType === 'YD') {
      for (let i = 0; i < this.details.length; i++) {
        if (this.details[i].trackName) {
          this.details[i].trackName = this.details[i].trackName.substr(
            0,
            this.details[i].trackName.indexOf(',')
          );
        }
      }
      this.surfaceData.emit(this.details);
      for (let i = 0; i < this.details.length; i++) {
        this.details[i].trackName =
          this.details[i].trackName + ',' + this.details[i].trackType;
      }
    } else {
      this.surfaceData.emit(this.details);
    }
  }

  getTrack() {
    if (this.trackType === 'YD' && this.sidingObject) {
      this.isYard = true;
      this.addString = '+ Add another Surfacing - Yard Track Detail';
      for (let i = 0; i < this.sidingObject.length; i++) {
        this.trackNameOptions.push({
          label:
            this.sidingObject[i].trackName +
            ':' +
            this.sidingObject[i].beginMp +
            '-' +
            this.sidingObject[i].endMP,
          code: this.sidingObject[i].trackName + ',' + this.trackType
        });
      }
    } else {
      this.isYard = false;
      if (this.sidingObject || this.trackType === 'SD') {
        this.addString = '+ Add another Surfacing - Siding Detail ';
      } else {
        this.addString = '+ Add another Surfacing - Mainline Detail';
      }
    }
    this.caculateSurfacedFeet(-1, -1, -1);
  }

  tryBeginMP(beginMP: number, endMP: number, trackName: string, index: number) {
    this.detailsInvalid = false;
    if (this.trackType === 'YD') {
      this.validateTrackName(trackName, index);
      if (!this.trackNameInvalid[index]) {
        this.validateBeginMilePost(index, beginMP, trackName);
        if (this.details[index].beginMP != null) {
          this.validateEndMilePost(index, beginMP, endMP, trackName);
        }
        this.caculateSurfacedFeet(beginMP, endMP, index);
      } else {
        this.details[index].beginMP = null;
      }
    } else {
      this.validateBeginMilePost(index, beginMP, trackName);
      this.validateEndMilePost(index, beginMP, endMP, trackName);
      this.caculateSurfacedFeet(beginMP, endMP, index);
    }
  }

  tryEndMP(beginMP: number, endMP: number, trackName: string, index: number) {
    this.detailsInvalid = false;
    if (this.trackType === 'YD') {
      this.validateTrackName(trackName, index);
      if (!this.trackNameInvalid[index]) {
        this.validateEndMilePost(index, beginMP, endMP, trackName);
        this.caculateSurfacedFeet(beginMP, endMP, index);
      } else {
        this.details[index].endMP = null;
      }
    } else {
      this.validateEndMilePost(index, beginMP, endMP, trackName);
      this.caculateSurfacedFeet(beginMP, endMP, index);
    }
  }

  tryTrackName(trackName: string, index: number) {
    this.validateTrackName(trackName, index);
    this.passData();
  }

  validateCrossingSurfaced(crossingTiesTimbered: boolean) {
    if (crossingTiesTimbered === null) {
      this.crossingSurfacedError = 'Make a selection.';
      this.crossingSurfacedInvalid = true;
    } else {
      this.crossingSurfacedInvalid = false;
    }
  }

  validateTrackName(trackName: string, index: number) {
    this.trackNameInvalid[index] = false;
    this.trackNameError[index] = '';
    if (trackName === 'null,null') {
      this.trackNameError[index] = 'Make a selection.';
      this.trackNameInvalid[index] = true;
    } else if (trackName === null) {
      this.trackNameError[index] = 'Make a selection.';
      this.trackNameInvalid[index] = true;
    } else if (this.compareSelectedTracknames(trackName, index)) {
      this.trackNameError[index] = 'Select a different track.';
      this.trackNameInvalid[index] = true;
    } else {
      const str = trackName.split(',');
      this.details[index].trackType = str[1];
      this.trackNameInvalid[index] = false;
    }
    this.checkValidationOverlapTieTrack();
  }

  checkValidationOverlapTieTrack() {
    for (let i = 0; i < this.trackNameInvalid.length; i++) {
      if (this.trackNameInvalid[i] === true) {
        for (let j = i + 1; j < this.trackNameInvalid.length; j++) {
          if (this.details[i].trackName === this.details[j].trackName) {
            this.trackNameError[i] = '';
            this.trackNameInvalid[i] = false;
            this.trackNameError[j] = 'Select a different track.';
            this.trackNameInvalid[j] = true;
          }
        }
      }
    }
  }

  compareSelectedTracknames(trackname: string, index: number): boolean {
    for (let i = 0; i < this.details.length; i++) {
      if (i !== index && this.details[i].trackName === trackname) {
        return true;
      }
    }
    return false;
  }

  clearDetail(index: number) {
    this.details[index].beginMP = null;
    this.details[index].endMP = null;
    this.details[index].surfaced = null;
  }

  /**
   *  Get Surfacing details - beginMilepost and validate
   * @param {number} index
   * @param {number} beginMp
   * @memberof SurfaceProgressComponent
   */
  validateBeginMilePost(index: number, beginMp: number, trackName: string) {
    if (beginMp === null) {
      this.beginMPError[index] = 'Enter MP';
      this.details[index].surfaced = null;
      this.beginMPOutOfLimitError[index] = true;
    } else if (!this.twoDecimalPattern.test(beginMp.toString())) {
      this.beginMPOutOfLimitError[index] = true;
      this.beginMPError[index] = '2 decimal places allowed';
    } else if (this.trackType === 'YD') {
      this.beginMPOutOfLimitError[index] = this.verifyMpWithinYard(
        beginMp,
        trackName
      );
      if (this.beginMPOutOfLimitError[index]) {
        this.beginMPError[index] = 'Milepost is outside of yard limits.';
        this.details[index].surfaced = null;
      }
    } else {
      this.isSiding = false;
      if (this.isYard === false && this.sidingObject) {
        this.isSiding = true;
      }
      this.beginMPOutOfLimitError[
        index
      ] = this.utilService.verifyMPOutSideJobLimit(
        this.jobBeginMp,
        this.jobEndMp,
        beginMp,
        this.direction,
        this.isSiding
      );
      const isValid = this.utilService.isMilePostWithinMasterTrack(
        beginMp,
        this.masterTrackList
      );

      if (!isValid) {
        this.beginMPOutOfLimitError[index] = true;
        this.beginMPError[index] = 'Invalid Mile post';
      } else {
        if (this.beginMPOutOfLimitError[index]) {
          if (this.isSiding) {
            this.beginMPError[index] = 'Outside of SD limits';
          } else {
            this.beginMPError[index] = 'Milepost is outside of job limits.';
          }
          this.details[index].surfaced = null;
        } else {
          this.details.forEach((details, surfacingIndex) => {
            if (
              index !== surfacingIndex
              && ((details.beginMP < beginMp && beginMp < details.endMP) || details.beginMP === beginMp)
            ) {
              this.beginMPOutOfLimitError[index] = true;
              this.beginMPError[index] = 'Mile post Overlap';
            }
          });
        }
      }
    }
  }

  /**
   *  Get Surfacing details - endMilepost and validate
   * @param {number} index
   * @param {number} endMp
   * @memberof SurfaceProgressComponent
   */
  validateEndMilePost(index: number, beginMp: number, endMp: number, trackName: string) {
    if (endMp === null) {
      this.endMPError[index] = 'Enter MP';
      this.details[index].surfaced = null;
      this.EndMPOutOfLimitError[index] = true;
      this.endMPNextWholeMpError[index] = false;
    } else if (!this.twoDecimalPattern.test(endMp.toString())) {
      this.EndMPOutOfLimitError[index] = true;
      this.endMPError[index] = '2 decimal places allowed';
    } else if (
      (this.direction === 'I' && beginMp > endMp) ||
      (this.direction === 'D' && beginMp < endMp)
    ) {
      this.endMPError[index] = 'Invalid milepost based on work direction.';
      this.details[index].surfaced = null;
      this.EndMPOutOfLimitError[index] = true;
      this.endMPNextWholeMpError[index] = false;
    } else if (this.trackType === 'YD') {
      this.EndMPOutOfLimitError[index] = this.verifyMpWithinYard(
        endMp,
        trackName
      );
      if (
        this.EndMPOutOfLimitError[index] ||
        this.endMPNextWholeMpError[index]
      ) {
        this.endMPError[index] = 'Milepost is outside of yard limits.';
        this.details[index].surfaced = null;
      }
    } else {
      let isSiding = false;
      if (this.isYard === false && this.sidingObject) {
        isSiding = true;
      }
      this.EndMPOutOfLimitError[
        index
      ] = this.utilService.verifyMPOutSideJobLimit(
        this.jobBeginMp,
        this.jobEndMp,
        endMp,
        this.direction,
        isSiding
      );

      const isValid = this.utilService.isMilePostWithinMasterTrack(
        endMp,
        this.masterTrackList
      );

      if (!isValid) {
        this.EndMPOutOfLimitError[index] = true;
        this.endMPError[index] = 'Invalid Milepost';
      } else {
        if (this.EndMPOutOfLimitError[index]) {
          if (this.isSiding) {
            this.endMPError[index] = 'Outside of SD limits';
          } else {
            this.endMPError[index] = 'Milepost is outside of job limits.';
          }
          this.details[index].surfaced = null;
        } else {
          this.details.forEach((details, surfacingIndex) => {
            if (
              index !== surfacingIndex
              && ((details.beginMP < endMp && endMp < details.endMP) || details.endMP === endMp)
            ) {
              this.EndMPOutOfLimitError[index] = true;
              this.endMPError[index] = 'Mile post Overlap';
            }
          });
        }
      }
    }
    if (this.details[index].beginMP && this.details[index].beginMP === endMp) {
      this.EndMPOutOfLimitError[index] = true;
      if (this.direction === 'I') {
        this.endMPError[index] = 'should be > beginMp';
      } else {
        this.endMPError[index] = 'should be < beginMp';
      }
    }
  }

  loadReport() {
    this.ds.getReport(Number(this.formId)).then(response => {
      if (response && response.reportObj.surfaceProgress) {
        const res = response.reportObj.surfaceProgress[0]['trackName'];
        if (this.trackName === res) {
          this.details = response.reportObj.surfaceProgress;
        }
      }
    });
  }

  validateAll(): boolean {
    this.validateCrossingSurfaced(this.crossingSurfaced);
    for (let i = 0; i < this.details.length; i++) {
      if (this.trackType === 'YD') {
        this.validateTrackName(this.details[i].trackName, i);
      } else {
        this.validateRailType(i);
      }
      this.validateSidingTrackName(this.details[i].trackName, i);
      this.validateBeginMilePost(
        i,
        this.details[i].beginMP,
        this.details[i].trackName
      );
      this.validateEndMilePost(
        i,
        this.details[i].beginMP,
        this.details[i].endMP,
        this.details[i].trackName
      );
    }
    if (this.trackType === 'SD' && !this.sidingObject) {
      if (
        !this.crossingSurfacedInvalid &&
        !(this.beginMPOutOfLimitError.indexOf(true) > -1) &&
        !(this.EndMPOutOfLimitError.indexOf(true) > -1) &&
        !(this.sidingTrackNameFieldError.indexOf(true) > -1) &&
        !(this.railTypeFieldError.indexOf(true) > -1)
      ) {
        this.validationErrors = true;
      } else {
        this.validationErrors = false;
      }
    } else {
      if (
        !this.crossingSurfacedInvalid &&
        !(this.beginMPOutOfLimitError.indexOf(true) > -1) &&
        !(this.EndMPOutOfLimitError.indexOf(true) > -1) &&
        !(this.endMPNextWholeMpError.indexOf(true) > -1) &&
        !(this.railTypeFieldError.indexOf(true) > -1)
      ) {
        if (
          this.trackType === 'YD' &&
          this.trackNameInvalid.indexOf(true) > -1
        ) {
          this.validationErrors = false;
        }
        this.validationErrors = true;
      } else {
        this.validationErrors = false;
      }
    }
    return this.validationErrors;
  }

  verifyMpWithinYard(milepost: number, trackName: string): boolean {
    const str = trackName.split(',');
    const track = str[0];
    const siding = this.sidingObject.find(i => i.trackName === track);
    if (milepost >= siding.beginMp && milepost <= siding.endMP) {
      return false;
    } else {
      return true;
    }
  }
}

interface CrossingSurfaced {
  label: string;
  code: boolean;
}

interface TrackName {
  label: string;
  code: string;
}

export class SurfacingDetail {
  trackType: string;
  trackName: string;
  beginMP: number;
  endMP: number;
  surfaced: number;
  crossingSurfaced: boolean;
  direction: string;
  workedOn: string;
  railType: string;

  constructor(
    trackType: string,
    trackName: string,
    beginMP: number,
    endMP: number,
    surfaced: number,
    crossingSurfaced: boolean,
    direction: string,
    workedOn: string,
    railType: string
  ) {
    this.trackType = trackType;
    this.trackName = trackName;
    this.beginMP = beginMP;
    this.endMP = endMP;
    this.surfaced = surfaced;
    this.crossingSurfaced = crossingSurfaced;
    this.direction = direction;
    this.workedOn = workedOn;
    this.railType = railType;
  }
}

export class Siding {
  trackId: number;
  trackName: string;
  beginMp: number;
  endMP: number;
  tieType: string;
  trackType: string;

  constructor(
    trackId: number,
    trackName: string,
    beginMp: number,
    endMP: number,
    tieType: string,
    trackType: string
  ) {
    this.trackId = trackId;
    this.trackName = trackName;
    this.beginMp = beginMp;
    this.endMP = endMP;
    this.tieType = tieType;
    this.trackType = trackType;
  }
}
