export class Project {
  projectId: number;
  lowMp: number;
  highMp: number;
  prefix: string;
  division: string;
  subdivision: string;

  totalPlannedProjectUnits: number;
  totalActualProjectUnits: number;
  totalPlannedProjectDays: number;
  daysToComplete: number;
  unitsRemaining: number;
  initialPlannedCrossing: number;
  initialPlannedDays: number;
  initialPlannedProjectUnits: number;

  constructor(
    projectId: number,
    lowMp: number,
    highMp: number,
    prefix: string,
    division: string,
    subdivision: string,
    totalPlannedProjectUnits: number,
    totalActualProjectUnits: number,
    totalPlannedProjectDays: number,
    daysToComplete: number,
    unitsRemaining: number,
    initialPlannedCrossing: number,
    initialPlannedDays: number,
    initialPlannedProjectUnits: number
  ) {
    projectId = projectId;
    this.lowMp = lowMp;
    this.highMp = highMp;
    this.prefix = prefix;
    this.division = division;
    this.subdivision = subdivision;
    this.totalPlannedProjectUnits = totalPlannedProjectUnits;
    this.totalActualProjectUnits = totalActualProjectUnits;
    this.totalPlannedProjectDays = totalPlannedProjectDays;
    this.daysToComplete = daysToComplete;
    this.unitsRemaining = unitsRemaining;
    this.initialPlannedCrossing = initialPlannedCrossing;
    this.initialPlannedDays = initialPlannedDays;
    this.initialPlannedProjectUnits = initialPlannedProjectUnits;
  }
}
