import { Observable } from 'rxjs/Observable';
import { FormControl } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LocalStorageService, ReferenceCode } from '../../../services/local-storage.service';
import { SelectItem } from 'primeng/primeng';

@Component({
  selector: 'app-expansion-measure',
  templateUrl: './expansion-measure.component.html',
  styleUrls: ['./expansion-measure.component.scss']
})
export class ExpansionMeasureComponent implements OnInit {
  @Input() expansionControl: FormControl;
  @Input() disabledOnEdit?: Boolean;
  @Output() onExpansionChange = new EventEmitter<any>();
  public fractionValues: Array<SelectItem>;
  public inchesValues: Array<SelectItem>;
  public inchSelected: string;
  public fractionSelected: string;

  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit() {
    // get fraction and inches values from reference table
    Observable.forkJoin(this.localStorageService.getReference('INCHES'), this.localStorageService.getReference('FRACTIONS')).subscribe(
      (referenceCodes: Array<ReferenceCode>) => {
        this.inchesValues = this.populateDropdownValues(referenceCodes[0].refObj);
        this.fractionValues = this.populateDropdownValues(referenceCodes[1].refObj);
        if (this.expansionControl.valid) {
          if (this.expansionControl.value.toString().indexOf('.') !== -1) {
            const expansionSplit = this.expansionControl.value.toString().split('.');
            this.inchSelected = expansionSplit[0];
            this.fractionSelected = '0.' + expansionSplit[1];
          } else {
            this.inchSelected = this.expansionControl.value.toString();
            this.fractionSelected = '0';
          }
        }
      }
    );
  }

  onInchOrFractionValueChange() {
    if (this.isInchAndFractionValid()) {
      this.expansionControl.patchValue(Number(this.inchSelected) + Number(this.fractionSelected));
      this.onExpansionChange.emit(Number(this.inchSelected) + Number(this.fractionSelected));
    } else {
      this.expansionControl.setValue(null);
      this.markExpansionControlTouched();
      this.onExpansionChange.emit(null);
    }
  }

  markExpansionControlTouched() {
    if (!this.isInchAndFractionValid()) {
      this.expansionControl.markAsTouched();
    }
  }

  private isInchAndFractionValid(): boolean {
    return (
      this.inchSelected !== undefined &&
      this.inchSelected !== 'null' &&
      this.fractionSelected !== undefined &&
      this.fractionSelected !== 'null'
    );
  }

  populateDropdownValues(referenceObject): Array<SelectItem> {
    const dropdownField: Array<SelectItem> = [];
    for (const prop in referenceObject) {
      if (referenceObject.hasOwnProperty(prop)) {
        dropdownField.push({ label: referenceObject[prop], value: prop });
      }
    }
    dropdownField.sort((a, b) => {
      return Number(a.value) > Number(b.value) ? 1 : Number(a.value) < Number(b.value) ? -1 : 0;
    });
    dropdownField.unshift({ label: '', value: null });
    return dropdownField;
  }
}
