export const TrackUtilization = Object.freeze({
    ENTER_START_TIME: 'Enter Start Time.',
    ENTER_END_TIME: 'Enter End Time.',
    SHOULD_BE_ON_DUTY_TIME: 'Should be > On-Duty time.',
    SHOULD_BE_ON_START_TIME: 'Should be > Start time.',
    TRACK_RECEIVED_TIME_REQUIRED: 'Track Received is required.',
    TRACK_CLEARED_TIME_REQUIRED: 'Track Cleared is required.',
    SHOULD_BE_TRACK_RECEIVED: 'Should be > Track Received.',
    TRACK_RECEIVED_DATE_REQUIRED: 'Track Received is required.',
    SHOULD_BE_ON_DUTY_DATE: 'Should be > On-Duty Date.',
    TRACK_CLEARED_DATE_REQUIRED: 'Track Cleared is required.',
    UNIT_INSTALLED_REQUIRED: 'Unit Installed is required.',
    UNIT_PER_HOUR_REQUIRED: 'Unit per hour is required.',
    SELECT_OFFICER_ON_SITE: 'Select Officer on Site.',
    SELECT_TRACKED_RETURN_ONTIME: 'Select Tracked  returned on Time.',
    COMMENTS_REQUIRED: 'Enter Late return remarks',
    ENTER_NAME_OF_YARD: 'Enter Name of Yard.',
    ENTER_NAME_OF_OFFICER: 'Enter Name of Officer.',
    OFFICER_AT_ONSITE_REQUIRED: 'Select Officer at Onsite.',
    TRACKED_ON_TIME_REQUIRED: 'Select Tracked on time.'
});
